/* -----------------------------------------------------------------------------------
 * COMPONENTE di ACCESSO
 * Utilizza anche AuthService per lavorare con l' Observable oggetto.
 * Oltre a ciò, chiama i TokenStorageService metodi per controllare lo stato di accesso
 * e salvare il token, le informazioni dell'utente nella memoria della sessione.
 * ----------------------------------------------------------------------------------- */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'
  ]
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null,
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage: string = '';
  roles: any = null;
  showPassword = false;
  show = true;
  constructor(private authService: AuthService, private tokenStorage: TokenStorageService, private router: Router) { }

  ngOnInit(): void {

    /* if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
      this.router.navigate(['funzioni']);
    } */
  }

  onSubmit(): void {
    const { username, password } = this.form;
    this.tokenStorage.signOut()// mi assicuro che la sessione sia vuota prima di loggare
    this.authService.login(username, password).subscribe(
      data => {
        this.tokenStorage.saveToken(data.token);
        this.tokenStorage.saveUser(data);
        this.roles = this.tokenStorage.getUser().roles;
        //this.reloadPage();
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.tokenStorage.saveLoggedIn(this.isLoggedIn)
        this.router.navigate(['funzioni']);
      },
      error => {
        this.errorMessage = error.message;
        this.isLoginFailed = true;
        this.isLoggedIn = false;
        this.tokenStorage.saveLoggedIn(this.isLoggedIn)
      }
    );
  }

  reloadPage(): void {
    window.location.reload();
  }
}
