
  <div class="row row-cols-6 " >
    <div class="col nomeRic">
      <!--(click)="openDialog()"-->
      <div class=" row nomeRic" >
        Informazioni aggregate di settore per i codici ateco:  <p style="width:max(3vw,10px);" *ngFor="let cod of listaCodiciAteco;index as i">{{this.listaCodiciAteco[i].descrizione + "  "}}  </p>
        <mat-icon class="search pointer">search</mat-icon>
    </div>
   
    </div>
   
   

    <div class=" col merc sx ">
      <div class=" year">Anno</div>
      <div class="">Ricavi </div>
      <div class="">Ebitda  </div>
      <div class="">Occupati </div>
      <div class="">Costi Personale </div>
      <div class="">Aziende </div>
      <div *ngIf="listaCodiciAteco[0].descrizione == '45.11.01'" class="">Immatricolazioni Mercato</div>
      <div *ngIf="listaCodiciAteco[0].descrizione == '45.11.01'" class="">Auto usate Mercato</div>
    </div>
    <div *ngFor="let anni of fatturatoMercato; index as i ;" class=" col merc ">
      <div class=" year  d-flex">
        
        <div class="col-8 text-center">
          {{anniDisplay[i]}}
        </div>
        <div class="col-4 " *ngIf="i < 4" style="text-align: right;">
           Trend
        </div>
      </div>
      <div class="dt d-flex"> 
        
        <div class="col-8">
          {{fatturatoMercato[i] != null ? (fatturatoMercato[i] | number:'1.0-0':'it')+" €" : 'N.d.' }}
        </div>
        <div class="col-4"
        [ngStyle]="{'color': fatturatoMercato[i] != null ?  (trends[0][i]< 0  ? 'rgb(255,0,0)':'rgb(19, 194, 112)'):'rgb(0,0,0)'}">

          {{  i < 4 ? ( fatturatoMercato[i] != null ? (trends[0][i] | number:'1.0-1':'it')+" %" : 'N.d') : ''}}
        </div>
       
      </div>


      <div class="dt d-flex"> 
        
        <div class="col-8">
          {{ ebitdaMercato[i] != null ? (ebitdaMercato[i] | number:'1.0-0':'it')+" €" : 'N.d.'}}
        </div>
        <div class="col-4"
        [ngStyle]="{'color': ebitdaMercato[i] != null ? ((trends[2][i]>=0) ? 'rgb(19, 194, 112)' : 'rgb(255, 0,0)'): 'rgb(0,0,0)'}">
          {{ i < 4? (ebitdaMercato[i] != null ?  (trends[2][i] | number:'1.0-1':'it')+" %" :'N.d.') : ''}}
        </div>
       
      </div>


      <div class="dt d-flex"> 
        
        <div class="col-8">
          {{ occupatiMercato[i] != null ? (occupatiMercato[i] | number:'1.0-0':'it') : 'N.d.' }}
        </div>
        <div class="col-4"
        [ngStyle]="{'color': occupatiMercato[i] != null ? ((trends[1][i]>=0) ? 'rgb(19, 194, 112)' : 'rgb(255, 0,0)') : 'rgb(0,0,0)'}">
          {{ i < 4?( occupatiMercato[i] != null ?  (trends[1][i] | number:'1.0-1':'it')+" %" :'N.d.' ) : ''}}
        </div>
       
      </div>

     


      <div class="dt d-flex"> 
        
        <div class="col-8">
          {{costiPersonaleMercato[i] != null ? (costiPersonaleMercato[i] | number:'1.0-0':'it')+" €": 'N.d.' }}
        </div>
        <div class="col-4"
        [ngStyle]="{'color':costiPersonaleMercato[i] != null?  ((trends[3][i]>=0) ? 'rgb(19, 194, 112)' : 'rgb(255, 0,0)') : 'rgb(0,0,0)'}">
          {{ i < 4?( costiPersonaleMercato[i] != null ?  (trends[3][i] | number:'1.0-1':'it')+" %" :'N.d.') : ''}}
        </div>
       
      </div>
     
      <div  class="dt d-flex">  
        <div class="col-8">
          {{numeroAziendeMercato[i] != null ? (numeroAziendeMercato[i] | number:'1.0-0':'it'): 'N.d.' }}
        </div>
        <div class="col-4"
        [ngStyle]="{'color':  numeroAziendeMercato[i] != null ? (  (trends[4][i]>=0) ? 'rgb(19, 194, 112)' : 'rgb(255, 0,0)') : 'rgb(0,0,0)'}">
          {{ i < 4?  ( numeroAziendeMercato[i] != null ?  (trends[4][i] | number:'1.0-1':'it')+" %" : 'N.d.') : ''}}
        </div>
      </div>


      
      <div *ngIf="listaCodiciAteco[0].descrizione == '45.11.01'" class="dt d-flex">  
        <div class="col-8">
          {{(immatricolazioni[i] | number:'1.0-0':'it') }}
        </div>
        <div class="col-4"
        [ngStyle]="{'color': (trends[5][i]>=0) ? 'rgb(19, 194, 112)' : 'rgb(255, 0,0)'}">
          {{  i < 2? (trends[5][i] | number:'1.0-1':'it')+" %" : ''}}
        </div>
      </div>
      <div  *ngIf="listaCodiciAteco[0].descrizione == '45.11.01'" class="dt d-flex">
        <div class="col-8">
          {{(autoUsate[i] | number:'1.0-0':'it') }}
        </div>
        <div class="col-4"  
        [ngStyle]="{'color': (trends[6][i]>=0) ? 'rgb(19, 194, 112)' : 'rgb(255, 0,0)'}">
          {{  i < 2? (trends[6][i] | number:'1.0-1':'it')+" %" : ''}}
        </div>
      </div>



    </div>
  

   


  </div>

  
  

