import { HttpClient , HttpClientModule} from '@angular/common/http';
import { identifierName } from '@angular/compiler';
import { Component, OnInit, ViewChild ,AfterViewInit} from '@angular/core';
import { UntypedFormGroup, FormsModule, UntypedFormControl,ReactiveFormsModule } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { ToolTipService } from '../_services/tool-tip.service';
import { ToolTip , TipTable } from '../interface/toolTip';
import { SectionDb, SectionSet } from '../interface/sezione';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';
import {ElementRef,Renderer2} from '@angular/core';
import { ClienteService } from '../_services/cliente.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { Tooltip } from 'chart.js';


@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})



export class TooltipComponent implements OnInit {
  displayedColumns: string[] = ['CI','descrizione','azioni']; /* Array che descrive le colonne della tabella visualizzata */
  dataSource: MatTableDataSource<ToolTip>; /* Qui viene descritto il tipo di elemento che viene inserito nella tabella */


  myform!: UntypedFormGroup; /* Istanza del form  */

  sez!: UntypedFormControl; /* Istanza del campo sezione del form */
  descr!: UntypedFormControl; /* Istanza del campo descrizione del form  */
  num!: UntypedFormControl; /* Istanza del campo numero del form  */


  @ViewChild(MatPaginator) paginator!: MatPaginator; /* definizione del paginator della tabella*/
  @ViewChild(MatSort) sort!: MatSort; /* definizione del paginator della tabella*/
  


  public dataValues:ToolTip ={ idTip: 0, sezione: "" , numero: 0, descrizione:""}; /* definizione dell'array in cui verranno inseriti i valori passati alla query */
  public TableValues: TipTable =  { rows: [] }; /* definizione dell'array dei valori della tabella */
  public SezSet: SectionSet = { rows: [] };   /* definizione dell'array delle sezioni per popolare il select  */


  showForm: boolean = false; /* serve a far visualizzare il form di Inserimento/Modifica*/
  new: boolean = true; /* serve a far visualizzare il bottone di Modifica  */
  add: boolean = false; /* serve a far visualizzare il bottone di Inserimento  */
  admin: boolean = true; /* booleano per la tipologia di utente */
  inserted: boolean = false; /* booleano per mostrare l'alert di inserimento */ 
  almod:boolean=true; /* modalità alert */ 
  
  constructor(private http: HttpClient, private serviceTooltip: ToolTipService, private tokenStorageService: TokenStorageService){
    this.dataSource = new MatTableDataSource(this.TableValues.rows);
  }

  

  ngOnInit(){
     /* Creazione del form  */
    this.myform = new UntypedFormGroup({
      sez: new UntypedFormControl(),
      descr: new UntypedFormControl(),
      num: new UntypedFormControl()  
    });
   
    /* qui viene inserita la richiesta al servizio per capire la tipologia di utente */ 
   
      
  
    

    /* metodo per il popolamento del select delle sezioni */
    this.serviceTooltip.prepareSection().subscribe(data=>{
      data.forEach(el => {
        this.SezSet.rows.push(el);
      });
     });

      /* metodo per la selezione di tutti i valori sul db e inserimento nella sorgente dati della tabella + aggiornamento valori tabella*/
     this.serviceTooltip.takeAll().subscribe(data => {
      data.forEach(element => {
        this.TableValues.rows.push(element);
        });
        this.TableValues.rows.sort((n1,n2)=> n1.numero- n2.numero)
        this.dataSource.data = this.TableValues.rows; 
     }); 

  }


  /* creazione paginator e sorter */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort; 

  }



  /* metodo per inserimento tips */
  submitTip(){

     /* svuoto sorgente tabella  + settaggio dei valori presi dal form*/
    this.TableValues.rows = [];
    this.dataValues.sezione = this.myform.controls['sez'].value;
    this.dataValues.descrizione = this.myform.controls['descr'].value;
    this.dataValues.numero = this.myform.controls['num'].value;
    
    if(this.new){
        this.almod = true;
      
          /* metodo che richiama il servizio della query inserimento nuuovo  + visualizzazione tabella aggiornata della sezione in cui hai inserito il tip*/
          this.serviceTooltip._submitTip( this.dataValues ).subscribe(()=> {
            this.serviceTooltip._onChange(this.myform).subscribe(data => {
              data.forEach(element => {
              this.TableValues.rows.push(element);
              });
              this.TableValues.rows.sort((n1,n2)=> n1.numero - n2.numero)
              this.dataSource.data = this.TableValues.rows;       
            });
          }); 
          this.inserted= true;

    }else{
      
      this.almod = false;
      this.inserted= true;
      /*metodo che richiama il servizio della query modifica gia esistente + visualizzazione tabella aggiornata della sezione in cui hai inserito il tip*/
            this.serviceTooltip._modifyTip( this.dataValues ).subscribe(()=>{
                this.serviceTooltip._onChange(this.myform).subscribe(data => {
                data.forEach(element => {
                  this.TableValues.rows.push(element);
                  });
                this.TableValues.rows.sort((n1,n2)=> n1.numero- n2.numero)
                this.dataSource.data = this.TableValues.rows;       
                });
            }); 

    } 
    this.new = false;
    
  }
   /* metodo che richiama il servizio della query visualizzazione tabella per sezione */
      onChange (){
       
        this.TableValues.rows = [];
        this.showForm = false;
        this.add = true;


        this.serviceTooltip._onChange(this.myform).subscribe(data => {
          this.dataValues.numero = data.length;
          this.myform.controls['num'].setValue(data.length);
         
         data.forEach(element => {
          this.TableValues.rows.push(element);
         });
         this.TableValues.rows.sort((n1,n2)=> n1.numero- n2.numero)
         this.dataSource.data = this.TableValues.rows;
        });
      }


      /* metodo che fa visualizzare il form di inserimento*/
      onInsert(sec: string, num: number){
        
        this.TableValues.rows = [];
        this.showForm = true;
        this.new = true;
        

        this.serviceTooltip._onChange(this.myform).subscribe(data => {
          this.myform.controls['num'].setValue(data.length+1);
          this.myform.controls['descr'].setValue(sec+'-'+ (data.length+1) );
           
         data.forEach(element => {
          this.TableValues.rows.push(element);
         });
         this.TableValues.rows.sort((n1,n2)=> n1.numero- n2.numero)
         this.dataSource.data = this.TableValues.rows;

        });
      }

  /* metodo che fa visualizzare il form di modifica */
      onModify( sec: string, num: number, des: string){
        
        this.myform.controls['num'].setValue(num);
        this.myform.controls['descr'].setValue(des);
        this.myform.controls['sez'].setValue(sec)
        this.showForm = true;
        this.new = false;
        this.add = true;

      }

      onCloseAlert(){
        this.inserted = false; 
      }
     

}
