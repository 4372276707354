import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faTrashAlt, faPlus, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';
import { CreditoBp, SituazioneCreditizia, suddivisioneCreditoBp } from 'src/app/interface/situazione-debitoria';
import { ToolTip } from 'src/app/interface/toolTip';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-situazione-creditizia',
  templateUrl: './situazione-creditizia.component.html',
  styleUrls: ['./situazione-creditizia.component.css']
})
export class SituazioneCreditiziaComponent implements OnInit {
  @Input() businessPlanTemp: PraticheNuova
  @Input() submittedCrediti: boolean
  @Input() situazioneCreditiziaSaved: boolean
  @Input() situazioneCreditiziaVolatile: SituazioneCreditizia
  @Input() creditiformGroup: UntypedFormGroup;

  @Output() emitCreditiziaFormValid = new EventEmitter<any>()
  risultatiVolatileSubscription: Subscription
  isRisultatiVolatile: boolean = false

  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;


  tipsSubscription: Subscription

  erroreSommaTotale: boolean = false //per bilancio abbreviato dove non ho i campi già impostati
  erroreSommaParziale: boolean[];
  situazioneCreditiziaIsValid: boolean = true

  tipoCreditoList: number[] = [];
  situazioneCreditizia: SituazioneCreditizia
  totaleCrediti = 0;
  anniBilancio = 0;
  creditiDirty: boolean = false
  openCheckBoxComponent = false
  listaMesi: Date[] = [];
  listaTipoCreditoBean: BeanStatico[] = []
  currentAnno = new Date().getFullYear()
  bilancioAbbreviato: boolean = false //da sistemare servono chiamate di aggiornamento ogni volt CHE inserisce un nuovo bilancio
  listaTips: ToolTip[] = [];
  nomeCredito: string[] = []

  valoreMin: any = {};

  mesiList: Date[]
  listaNumeroRate: number[]
  tipoRateList: BeanStatico[]
  inizioDurataSubscription: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private confService: ConfigurazioneBusinessPlanService,
    private modalService: NgbModal,
    private toolTipService: ToolTipService,
    private tokenService: TokenStorageService) {
  }

  get f() { return this.creditiformGroup.controls }
  get creditiArray(): UntypedFormArray { return <UntypedFormArray> this.f.arrayCreditizia; }
  get creditiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.creditiArray.controls; }


  ngOnInit(): void {
    this.creditiformGroup = this.formBuilder.group({
      arrayCreditizia: this.formBuilder.array([]),
    })
    this.listaNumeroRate = []
    for (let i = 0; i < 500; i++) {
      this.listaNumeroRate.push(i + 1)
    }
    if (this.businessPlanTemp != null && this.businessPlanTemp.bilancioAbbreviato) {
      //importo la lista crediti vergine dal serivice
      this.confService.getListaCrediti().subscribe(list => {
        list.forEach(item => {
          this.listaTipoCreditoBean.push({ id: item.id, descrizione: item.descrizione, checked: false })
        })
      })
    }
    if(this.businessPlanTemp != null){
      this.inizioDurataSubscription = this.confService.currentInizioDurata.subscribe(element => {
        if (element != null) {
          this.currentAnno = element.annoIniziale
          
        }
      })
    }
    this.listaMesi = []
    for (let i = 0; i < 12; i++) {
      this.listaMesi.push(new Date(this.currentAnno, i))
    }
    this.risultatiVolatileSubscription = this.confService.currentRisultatiVolatile.subscribe(siVolatile => {
      this.isRisultatiVolatile = siVolatile
    })
      ;
    this.configurazioneAmbienteService.getValoriMax('situazione debitoria').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMin[element.nomeCampo] = element.valoreMax;
        }

      })
    })
    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })
    this.configurazioneAmbienteService.getListaTipoRate().subscribe(list => {
      this.tipoRateList = list
      this.getSituazioneCreditizia();
    })
  }

  getSituazioneCreditizia() {
    if (this.businessPlanTemp) {
      this.nomeCredito = [];
      this.creditiArray.clear()
      this.tipoCreditoList = []
      this.erroreSommaParziale = []
      if (this.isRisultatiVolatile && this.situazioneCreditiziaVolatile != null) {
        this.anniBilancio = this.situazioneCreditiziaVolatile.anno
        this.situazioneCreditizia = this.situazioneCreditiziaVolatile;
        this.totaleCrediti = this.situazioneCreditiziaVolatile.creditoTot;
        if (this.totaleCrediti > 0) {
          if (this.situazioneCreditiziaVolatile.credito.length > 0) {
            this.situazioneCreditiziaVolatile.credito.forEach((credito, j) => {
              this.nomeCredito.push(credito.nome)
              this.tipoCreditoList.push(credito.idTipoCredito)
              let suddivisioneCreditoForm: UntypedFormGroup[] = [];
              this.erroreSommaParziale.push(false)
              if (credito.creditiBpList && credito.creditiBpList.length > 0) {
                credito.creditiBpList.forEach(dettaglio => {
                  //gestire tutte le select
                  let tipoRata: BeanStatico;
                  this.tipoRateList.forEach(tipo => {
                    if (tipo.id == dettaglio.idTipoRata) {
                      tipoRata = tipo
                    }
                  })
                  suddivisioneCreditoForm.push(this.formBuilder.group({
                    descrizione: [{value: dettaglio.descrizione,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)},Validators.required], //anche a scandenza
                    importo: [{value: dettaglio.importo,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                    importoRinegoziato: [{value: dettaglio.importoRinegoziato,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required],
                    numeroRate: [{value: dettaglio.numeroRate,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                    annoInizioRimborso: [{value: dettaglio.annoInizioRimborso,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required],
                    meseInizioRimborso: [{value: dettaglio.idMeseInizioRimborso,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                    tipologiaRata: [{value: tipoRata,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                    //banche
                  }))
                })
              }
              this.creditiArray.push(this.formBuilder.group({
                totale: [{ value: credito.totale, disabled: true }, Validators.required],
                suddivisioneCrediti: this.formBuilder.array(suddivisioneCreditoForm),

              }))
            });
          }
        }
      } else {
        this.confService.getSituazioneCreditiziaNew(this.businessPlanTemp.id, this.businessPlanTemp.idAziendaRichiedente).subscribe(response => {
          if (response.error == null) {
            this.situazioneCreditizia = response;
            this.anniBilancio = this.situazioneCreditizia.anno
            this.totaleCrediti = this.situazioneCreditizia.creditoTot;
            if (this.totaleCrediti != 0) {
              if (this.situazioneCreditizia.credito.length > 0) {
                this.situazioneCreditizia.credito.forEach((credito, j) => {
                  this.nomeCredito.push(credito.nome)
                  this.tipoCreditoList.push(credito.idTipoCredito)
                  let suddivisioneCreditoForm: UntypedFormGroup[] = [];
                  this.erroreSommaParziale.push(false)
                  if (credito.creditiBpList && credito.creditiBpList.length > 0) {
                    credito.creditiBpList.forEach(dettaglio => {
                      //gestire tutte le select
                      let tipoRata: BeanStatico;
                      this.tipoRateList.forEach(tipo => {
                        if (tipo.id == dettaglio.idTipoRata) {
                          tipoRata = tipo
                        }
                      })
                      suddivisioneCreditoForm.push(this.formBuilder.group({
                        descrizione: [{value: dettaglio.descrizione,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                        importo: [{value: dettaglio.importo,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                        importoRinegoziato: [{value: dettaglio.importoRinegoziato,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required],
                        numeroRate: [{value: dettaglio.numeroRate,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                        annoInizioRimborso: [{value: dettaglio.annoInizioRimborso,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required],
                        meseInizioRimborso: [{value: dettaglio.idMeseInizioRimborso,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                        tipologiaRata: [{value: tipoRata,disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)}, Validators.required], //anche a scandenza
                        //banche
                      }))
                    })
                  }
                  this.creditiArray.push(this.formBuilder.group({
                    totale: [{ value: credito.totale, disabled: true }, Validators.required],
                    suddivisioneCrediti: this.formBuilder.array(suddivisioneCreditoForm),

                  }))
                })

              }
            }
          }
        })
      }
    }
  }

  aggiornaListaCheckBox() {
    //serve a tenere sempre aggiornata la lista dei crediti che l'utente inserisce
    if (this.openCheckBoxComponent == false) {
      for (let i = 0; i < this.listaTipoCreditoBean.length; i++) {
        this.listaTipoCreditoBean[i].checked = false;// setto sempre il checked della lista a false all 'inizio poi porto a true solo quelli che
        //soddisfano la cond (mi evita di mettere l'else a riga 393 che rompe la logica)
        if (this.tipoCreditoList.length > 0) {
          for (let j = 0; j < this.tipoCreditoList.length; j++) {
            if (this.listaTipoCreditoBean[i].id == this.tipoCreditoList[j]) {
              this.listaTipoCreditoBean[i].checked = true;
            }
          }
        }
      }
    }
    this.openCheckBoxComponent = !this.openCheckBoxComponent
  }

  ModificaListaCrediti(listaCreditiNew: BeanStatico[]) {// se cambio il numero di crediti devo aggiornare l'array
    this.creditiformGroup.markAsDirty()
    let suddivisioneCreditoForm: UntypedFormGroup[] = []

    suddivisioneCreditoForm.push(this.formBuilder.group({
      descrizione: ['', Validators.required], //anche a scandenza
      importo: ['', Validators.required], //anche a scandenza
      importoRinegoziato: ['', Validators.required],
      numeroRate: ['', Validators.required], //anche a scandenza
      annoInizioRimborso: ['', Validators.required],
      meseInizioRimborso: ['', Validators.required], //anche a scandenza
      tipologiaRata: ['', Validators.required], //anche a scandenza
      //banche
    }))
    if (this.creditiArray.length > 0) {
      for (let i = 0; i < listaCreditiNew.length; i++) {
        if (listaCreditiNew[i].checked) {
          //includes controlla se è presente un elemento di listaCreditiNew[i] senza ciclare su tipoCreditoList
          if (!this.tipoCreditoList.includes(listaCreditiNew[i].id)) {
            this.nomeCredito.push(listaCreditiNew[i].descrizione)
            this.erroreSommaParziale.push(false)
            this.creditiArray.push(this.formBuilder.group({
              totale: ['0', Validators.required],
              suddivisioneCrediti: this.formBuilder.array(suddivisioneCreditoForm),
            }))
          }
          this.tipoCreditoList.push(listaCreditiNew[i].id)
          //eliminare pezzi
        } else {
          let arr = []
          //arr contiene gli elementi dell'Array che soddisfano la condizione del filter
          //in questo caso avrà sempre solo un elmento perchè la condizione è su un id
          arr = this.tipoCreditoList.filter(obj => { return obj == listaCreditiNew[i].id })
          if (arr.length > 0) {
            this.nomeCredito.splice(this.tipoCreditoList.indexOf(arr[0]), 1)
            /*  this.arrayErroriCrediti.sommaParziale.splice(this.tipoCreditoList.indexOf(arr[0]), 1)
             this.abilitaModificaCreditoOltre.splice(this.tipoCreditoList.indexOf(arr[0]), 1)
             this.abilitaModificaCreditoEntro.splice(this.tipoCreditoList.indexOf(arr[0]), 1)
             this.suddivisioneCreditiEntro.splice(this.tipoCreditoList.indexOf(arr[0]), 1);
             this.isConfermatoCreditoEntro.splice(this.tipoCreditoList.indexOf(arr[0]), 1)
             this.suddivisioneCreditiOltre.splice(this.tipoCreditoList.indexOf(arr[0]), 1);
             this.isConfermatoCreditoOltre.splice(this.tipoCreditoList.indexOf(arr[0]), 1) */
            this.creditiArray.removeAt(this.tipoCreditoList.indexOf(arr[0]));
            this.creditiArray.updateValueAndValidity()
            this.tipoCreditoList.splice(this.tipoCreditoList.indexOf(arr[0]), 1)
          }
        }
      }
    } else {
      //caso t=0  form vuoto (devo pushare tutto da 0)
      listaCreditiNew.forEach(credito => {
        if (credito.checked) {
          this.nomeCredito.push(credito.descrizione)
          this.tipoCreditoList.push(credito.id)
          //errori liste
          this.creditiArray.push(this.formBuilder.group({
            totale: ['0', Validators.required],
            suddivisioneCrediti: this.formBuilder.array(suddivisioneCreditoForm),
          })
          )
        }
      })
    }
    this.openCheckBoxComponent = false;
    //alla fine bisogna ri aggiornare la lista delle checkbox (forse anche l'idTipoCredito) e tutti gli array di errore

  }

  isCreditiValid(): boolean {
    let sommaTot = 0
    let sommaParzialeIsValid = true;
    this.situazioneCreditiziaIsValid = true
    let creditiFormGroupValid = true;
    for (let i = 0; i < this.creditiFormArray.length; i++) {
      sommaTot += +this.creditiFormArray[i].controls.totale.value
      let suddivisioneCreditiGroup = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[i].controls.suddivisioneCrediti).controls
      let sommaParziale = 0
      //controllo somma parziale
      suddivisioneCreditiGroup.forEach(creditoForm => {
        sommaParziale += +creditoForm.controls.importo.value
        creditoForm.updateValueAndValidity()

        creditiFormGroupValid = creditiFormGroupValid && creditoForm.valid
      })
      if (sommaParziale != +this.creditiFormArray[i].controls.totale.value) {
        this.erroreSommaParziale.splice(i, 1, true)
      } else {
        this.erroreSommaParziale.splice(i, 1, false)
      }
      this.creditiFormArray[i].updateValueAndValidity()
      sommaParzialeIsValid = sommaParzialeIsValid && !this.erroreSommaParziale[i]

    }
    //somma importo credit==totale (per crediti bilancio abbreviato dove li inserisce lui manualmente)
    if (sommaTot != this.totaleCrediti) {
      this.erroreSommaTotale = true
    } else {
      this.erroreSommaTotale = false
    }

    return this.situazioneCreditiziaIsValid = !this.erroreSommaTotale && sommaParzialeIsValid && creditiFormGroupValid
  }

  onSubmitCrediti() {
    if (this.submittedCrediti) {//this.submittedCrediti
      let request: SituazioneCreditizia;
      let listCrediti: CreditoBp[] = []
      for (let index = 0; index < this.creditiFormArray.length; index++) {
        let suddivisioneCreditoForm = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[index].controls.suddivisioneCrediti).controls;
        let listaDettaglioCredito: suddivisioneCreditoBp[] = []

        for (let j = 0; j < suddivisioneCreditoForm.length; j++) {
          listaDettaglioCredito.push({
            id: 0,
            idBusinessPlan: this.businessPlanTemp.id,
            idTipoCredito: this.tipoCreditoList[index],
            idTipoRata: suddivisioneCreditoForm[j].controls.tipologiaRata.value.id,
            descrizione: suddivisioneCreditoForm[j].controls.descrizione.value,
            importo: suddivisioneCreditoForm[j].controls.importo.value,
            importoRinegoziato: suddivisioneCreditoForm[j].controls.importoRinegoziato.value,
            numeroRate: suddivisioneCreditoForm[j].controls.numeroRate.value,
            idMeseInizioRimborso: suddivisioneCreditoForm[j].controls.meseInizioRimborso.value,
            annoInizioRimborso: suddivisioneCreditoForm[j].controls.annoInizioRimborso.value,
          })
        }

        listCrediti.push({
          id: 0,
          idTipoCredito: this.tipoCreditoList[index],
          nome: this.situazioneCreditizia.credito[index].nome,
          totale: this.totaleCrediti,
          creditiBpList: listaDettaglioCredito,

        })
      }
      request = {
        id: this.situazioneCreditizia.id,
        idRichiedente: this.businessPlanTemp.idAziendaRichiedente,
        credito: listCrediti,
        creditoTot: this.situazioneCreditizia.creditoTot,
        anno: this.situazioneCreditizia.anno,
        idBusinessPlan: this.businessPlanTemp.id,
        error: null,
      }
      this.markFormCreditoPristine()
      return request
      /* } */
    } else {
      return null
    }

  }

  elaboraSituazioneCreditizia() {
    if (this.situazioneCreditiziaIsValid) {

      let request: SituazioneCreditizia;
      let listCrediti: CreditoBp[] = []

      for (let index = 0; index < this.creditiFormArray.length; index++) {
        let suddivisioneCreditoForm = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[index].controls.suddivisioneCrediti).controls;
        let listaDettaglioCredito: suddivisioneCreditoBp[] = []
        for (let j = 0; j < suddivisioneCreditoForm.length; j++) {
          listaDettaglioCredito.push({
            id: 0,
            idBusinessPlan: this.businessPlanTemp.id,
            idTipoCredito: this.tipoCreditoList[index],
            idTipoRata: suddivisioneCreditoForm[j].controls.tipologiaRata.value.id,
            descrizione: suddivisioneCreditoForm[j].controls.descrizione.value,
            importo: suddivisioneCreditoForm[j].controls.importo.value,
            importoRinegoziato: suddivisioneCreditoForm[j].controls.importoRinegoziato.value,
            numeroRate: suddivisioneCreditoForm[j].controls.numeroRate.value,
            idMeseInizioRimborso: suddivisioneCreditoForm[j].controls.meseInizioRimborso.value,
            annoInizioRimborso: suddivisioneCreditoForm[j].controls.annoInizioRimborso.value,
          })
        }
        listCrediti.push({
          id: 0,
          idTipoCredito: this.tipoCreditoList[index],
          nome: this.situazioneCreditizia.credito[index].nome,
          totale: this.situazioneCreditizia.credito[index].totale,
          creditiBpList: listaDettaglioCredito,

        })

      }
      request = {
        id: 0,
        idRichiedente: this.businessPlanTemp.idAziendaRichiedente,
        credito: listCrediti,
        creditoTot: this.situazioneCreditizia.creditoTot,
        anno: this.situazioneCreditizia.anno,
        idBusinessPlan: this.businessPlanTemp.id,
        error: null,
      }
      this.confService.inviaAnnoBilancio(this.anniBilancio);
      return request
    }
  }

  /* molto superfluo ma meglio che andare a toccare */
  isFormCreditoDirty() {
    this.submittedCrediti = this.creditiformGroup.dirty
    return this.submittedCrediti;
  }
  annullaCrediti() {
    if (this.creditiformGroup.dirty) {
      this.submittedCrediti = false;
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Cliccando su 'Annulla' tutte le modifiche non salvate su questa pagina andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.situazioneCreditiziaIsValid = true
          this.openCheckBoxComponent = false;
          this.creditiformGroup.reset();
          this.getSituazioneCreditizia();
          this.markFormCreditoPristine()
        } else { this.submittedCrediti = true }
      })
    }
  }
  markFormCreditoPristine() {
    this.creditiformGroup.markAsPristine()
    this.creditiDirty = false
  }


  //modifica importo rinegoziato
  setValoreRinegoziatoCredito(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[i].controls.suddivisioneCrediti).controls
    if (formgroup[j].controls.importo.value != null) {
      formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
    } else {
      formgroup[j].controls.importoRinegoziato.setValue('0')
    }
  }

  onChangeAnno(i: number, j: number){
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[i].controls.suddivisioneCrediti).controls
    if (formgroup[j].controls.annoInizioRimborso.value != null && formgroup[j].controls.annoInizioRimborso.value != "" && formgroup[j].controls.annoInizioRimborso.value <= this.currentAnno) {
      formgroup[j].controls.annoInizioRimborso.setValue(this.currentAnno)
    }
  }
  
  aggiungiCredito(index: number) {
    this.creditiformGroup.markAsDirty();
    (<UntypedFormArray> this.creditiFormArray[index].controls.suddivisioneCrediti).controls.push(this.formBuilder.group({
      descrizione: ['', Validators.required], //anche a scandenza
      importo: ['0', Validators.required], //anche a scandenza
      importoRinegoziato: ['0', Validators.required],
      numeroRate: [1, Validators.required], //anche a scandenza
      annoInizioRimborso: ['', Validators.required],
      meseInizioRimborso: ['', Validators.required], //anche a scandenza
      tipologiaRata: ['', Validators.required],
    }))
  }



  eliminaCredito(index: number, index2: number) {
    this.creditiformGroup.markAsDirty();
    if ((<UntypedFormArray> this.creditiFormArray[index].controls.suddivisioneCrediti).length > 1) {
      (<UntypedFormArray> this.creditiFormArray[index].controls.suddivisioneCrediti).removeAt(index2)
    }
  }



  valoreRinegoziatoMax(i: number, j: number) {
    let formgroup = <UntypedFormGroup[]> (<UntypedFormArray> this.creditiFormArray[i].controls.suddivisioneCrediti).controls
    if (formgroup[j].controls.importo.value != null) {
      if (formgroup[j].controls.importoRinegoziato.value > formgroup[j].controls.importo.value)
        formgroup[j].controls.importoRinegoziato.setValue(formgroup[j].controls.importo.value)
    } else {
      formgroup[j].controls.importoRinegoziato.setValue('0')
    }

  }


  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    this.tipsSubscription.unsubscribe()
    if(this.inizioDurataSubscription != undefined){
      this.inizioDurataSubscription.unsubscribe();
    }
  }
}
