import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuPulsantieraService } from 'src/app/header/services/menu-pulsantiera.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { PDFserviceService } from 'src/app/_services/pdfservice.service';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';

@Component({
  selector: 'app-crea-dossier',
  templateUrl: './crea-dossier.component.html',
  styleUrls: ['./crea-dossier.component.css']
})
export class CreaDossierComponent implements OnInit {
  id_bp: number
  indiciPagine: Boolean[] = []
  listaPagine: string[] = []
  idBpSubscription: Subscription;
  riferimentoBp: PraticheNuova;
  carica = false;

  constructor(private PdfService: PDFserviceService,
    private menuPulsantieraService: MenuPulsantieraService, private configurazioneBpService: ConfigurazioneBusinessPlanService) { }

  ngOnInit(): void {

    this.idBpSubscription = this.configurazioneBpService.currentBusinessPlan.subscribe(bp => {
      if (bp != null) {
        this.riferimentoBp = bp;
        this.id_bp = this.riferimentoBp.id;
      }
    })

    this.indiciPagine = [true, true, true, true, true, true, true, true, true, true, true, true, true];
    this.listaPagine = ["Front", "Splash Page", "Stato Patrimoniale", "Stituazione debitoria", "Indicatori Economici", "Conto Economico", "Cash Flow", "Debito Finanziario", "Valutazione MCC", "Indici Di Bilancio", "Istogramma Bilancio", "Crisi d'impresa", "Cash flow prospettico"];
    this.compilePDF();
    this.menuPulsantieraService.CONTROLLA_CONFIGURAZIONE$.subscribe(
      controllo => {
        console.log(controllo);
        console.log("check controllo");
        this.carica = controllo;
        if (this.carica) {

          this.compilePDF();
          this.carica = false;
        }
      })
  }

  compilePDF() {

    this.PdfService.invioDatiPdf(this.riferimentoBp.idAziendaRichiedente, this.id_bp, this.indiciPagine).subscribe(
      (data: Blob) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style.visibility = 'hidden';
        let url = URL.createObjectURL(data);
        //a.href =  url+"#toolbar=0";
        a.href = url;
        a.target = "_blank";
        a.click();
        URL.revokeObjectURL(a.href)
        a.remove();
      }
    )
  }

  ngOnDestroy() {
    this.idBpSubscription.unsubscribe();
  }
}
