<div class="container text-center">
    <header>
        <h3>
            <strong>Società :</strong> nome società
        </h3>
    </header>
    <p>
        <strong>partita Iva</strong> 53446165
    </p>
    <p>
        <!-- da continuare -->
        <strong>Email:</strong>

    </p>
    <strong>Ruoli:</strong>
    <ul class="list-group list-unstyled">

    </ul>
</div>