import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class InterazioneRisultatiQuestionarioService {

  /*potenziometri*/
  private _DSCRFocusSource: Subject<string> = new Subject<string>();
  private _DSCRScrollSource: Subject<boolean> = new Subject<boolean>();

  public readonly DSCRFocus$: Observable<string> = this._DSCRFocusSource.asObservable();
  public readonly DSCRScroll$: Observable<boolean> = this._DSCRScrollSource.asObservable();

  /*time line*/
  private _FocusColYear: Subject<string> = new Subject<string>();
  private _YearLeftShift: Subject<number> = new Subject<number>();
  private _YearRightShift: Subject<number> = new Subject<number>();
  public readonly yearFocus$: Observable<string> = this._FocusColYear.asObservable();
  public readonly yearLeftShift$: Observable<number> = this._YearLeftShift.asObservable();
  public readonly yearRightShift$: Observable<number> = this._YearRightShift.asObservable();

  /*right-left menu*/
  private _DilatateDataContainer: Subject<string[]> = new Subject<string[]>();
  private _ObserveLeftBehaviour: Subject<boolean> = new Subject<boolean>();
  private _ObserveRightBehaviour: Subject<boolean> = new Subject<boolean>();
  public readonly dilatateDataContainer$: Observable<string[]> = this._DilatateDataContainer.asObservable();
  public readonly observeLeftBehaviour$: Observable<boolean> = this._ObserveLeftBehaviour.asObservable();
  public readonly observeRightBehaviour$: Observable<boolean> = this._ObserveRightBehaviour.asObservable();

  private listaMenu: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(['Riepilogo', 'Indicatori Economici e Finanziari', 'Situazione Debitoria',
    'Conto Economico', 'Cash Flow',
    'Stato Patrimoniale', 'Debito Finanziario', 'Valutazione MCC Prospettica',
    'Garanzie', 'Indici di Bilancio', 'Valutazione Crisi di Impresa']);
  public readonly currentListaMenu: Observable<string[]> = this.listaMenu.asObservable();

  constructor() {

  }

  /*time line*/
  clickOnYear(year_position: string): void {
    this._FocusColYear.next(year_position);
  }

  clickOnLeftArrowYear(shift: number): void {
    this._YearLeftShift.next(shift);
  }

  clickOnRightArrowYear(shift: number): void {
    this._YearRightShift.next(shift);
  }

  /*potenziometri*/
  focusOnDSCR(): void {
    this._DSCRFocusSource.next('DSCRfocus');
    this.scrollOnDSCR();
  }

  scrollOnDSCR(): void {
    this._DSCRScrollSource.next(true);
  }

  /*right-left menu*/
  dilatateContainer(regoleCSS: string[]): void {
    this._DilatateDataContainer.next(regoleCSS);
  }

  checkLeftSide(left: boolean) {
    this._ObserveLeftBehaviour.next(left);
  }

  checkRightSide(right: boolean) {
    this._ObserveRightBehaviour.next(right);
  }

  updateListaMenu(lista: string[]) {
    this.listaMenu.next(lista);
  }
}
