<div class="margin" [formGroup]="modalForm">
    <div class="row" formArrayName="arrayFormCheckBox">
        <div class="col-6 col-xl-4 margin-top-10" *ngFor="let item of FormControlArray;let i = index">
            <input type="checkbox" class="checkbox-m"
                [formControlName]="i" />&nbsp;&nbsp;{{listaCheckBox[i].descrizione}}
        </div>

    </div>
    <div class="row d-flex justify-content-end">
        <div class="col-2">
            <div class="btn btn-success padding-right-10 paddin-left-10" (click)="onChangeCheckBox()">Seleziona voci
            </div>
        </div>
    </div>
</div>