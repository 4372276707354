import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { faTrashAlt, faInfo, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { Disinvestimenti, Investimenti, InvestimentiDisinvestimenti } from 'src/app/interface/InvestimentiDisinvestimenti';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';
import { ToolTip } from 'src/app/interface/toolTip';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { Subscription } from 'rxjs';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { BilancioService } from 'src/app/_services/bilancio.service';

@Component({
  selector: 'app-investimenti-disinvestimenti-bp',
  templateUrl: './investimenti-disinvestimenti-bp.component.html',
  styleUrls: ['./investimenti-disinvestimenti-bp.component.css']
})
export class InvestimentiDisinvestimentiBpComponent implements OnInit {

  @Input() submitted: boolean
  @Input() investimentiDisinvestimentiVolatile: InvestimentiDisinvestimenti
  @Input() investimentiForm: UntypedFormGroup;
  @Input() businessPlanTemp: PraticheNuova
  @Input() bilancioPresente: boolean;
  @Output() emitInvestimentiFormValid = new EventEmitter<any>();

  investimentiDirty: boolean = false;
  disinvestimentiDirty: boolean = false;
  investimentiValid: boolean = true;
  tipiInvestimento = []
  tipiDisinvestimento = []
  listaModalitaFinanziamento: any[] = []
  listaGaranzie: any[] = []
  investimentiTempId: number[] = []
  disinvestimentiTempId: number[] = []
  tipoInv: any = {};
  investimentiOrdinati: Investimenti[] = []
  disinvestimentiOrdinati: Disinvestimenti[] = []
  liquiditaCassaBilancio: number = 0
  immobImmatBilancio: number = 0
  immobMatBilancio: number = 0
  costiPersonaleBilancio: number = 0
  costiTfrBilancio: number = 0
  costiFornitoriBilancio: number = 0
  valoreMagazBilancio: number = 0
  beneImmobilBilancio: number = 0
  ammortamentoBilancio; number = 0

  ateco: string = ""
  modalitaFin: any = {}
  trimestreInv: any = {}
  trimestreBen: any = "";
  trimestreDis: any = {}
  garanzieFin: BeanStatico
  garanzieNuovoInv: BeanStatico
  tipoDisinv = {};
  isRisultatiVolatile: boolean = false
  risultatiVolatileSubscription: Subscription
  debitoFornitoriSubscription: Subscription
  trimestreList: any[] = [{ id: 1, descrizione: "1° trimestre" }, { id: 2, descrizione: "2° trimestre" }, { id: 3, descrizione: "3° trimestre" }, { id: 4, descrizione: "4° trimestre" }]
  faTrashAlt = faTrashAlt;
  faInfo = faInfo;
  faPlus = faPlus;

  tipsSubscription: Subscription
  presenzaErroriInv = false;
  arrayErroriInv: boolean[] = [];
  arrayErroriLiquidita: boolean[] = []
  presenzaErroriDis = false;
  arrayErroriCostiPersonale: boolean[] = [];
  arrayErroriCostiTfr: boolean[] = [];
  arrayErroriImmobilMat: boolean[] = [];
  arrayErroriImmobilImmat: boolean[] = [];
  arrayErroriValoreMagaz: boolean[] = [];
  arrayErroriAmmortamento: boolean[] = []
  arrayErroriDebitoFornitori: boolean[] = [];
  arrayErroriImportoDis: boolean[] = [];

  listaTips: ToolTip[] = [];
  currentData = new Date().getFullYear()
  listaLdb = [];
  inizioDurataSubscription: Subscription;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurazioneBusinessPlanService: ConfigurazioneBusinessPlanService,
    private modalService: NgbModal,
    private toolTipService: ToolTipService,
    private bilancioService: BilancioService) { }

  get f() { return this.investimentiForm.controls; }
  get investimentiArray(): UntypedFormArray { return <UntypedFormArray> this.f.investimenti; }
  get investimentiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.investimentiArray.controls; };
  get disinvestimentiArray(): UntypedFormArray { return <UntypedFormArray> this.f.disinvestimenti; }
  get disinvestimentiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.disinvestimentiArray.controls; };

  ngOnInit(): void {

    if (this.businessPlanTemp != null && this.businessPlanTemp.bilancioAbbreviato) {
      this.debitoFornitoriSubscription = this.bilancioService.currentDebitoFornitoriDebitoria.subscribe(response => {
        this.costiFornitoriBilancio = response
        if (this.disinvestimentiArray) {
          for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
            if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5) {
              this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.markAsDirty()
              /* this.isFormValid()
              this.emitInvestimentiFormValid.emit({ investimentiValid: this.investimentiValid, investimentiDisinvestimentiDirty: true }) */
            }
          }
        }
      })
    }
    if (this.businessPlanTemp != null) {
      this.configurazioneBusinessPlanService.getListaTipoInvDis(this.businessPlanTemp.idAziendaRichiedente, 'Generale').subscribe((response) => {
        this.ateco = response.tipoInvestimento[0].settore
        response.tipoInvestimento.forEach(element => {
          this.tipiInvestimento.push(element)
        });
        response.tipoDisinvestimento.forEach(element => {
          this.tipiDisinvestimento.push(element)
        });

        this.configurazioneBusinessPlanService.getListaModalitaFinanziamentoEGaranzie().subscribe((lista) => {
          this.listaModalitaFinanziamento = lista.modalitaFinanziamento
          this.listaGaranzie = lista.garanzie
          this.configurazioneBusinessPlanService.getLineeBusinessBean(this.businessPlanTemp.id).subscribe(response => {
            this.listaLdb = response;
            this.getInvestimentiDisinvestimenti()

          });
        })
      })

      this.inizioDurataSubscription = this.configurazioneBusinessPlanService.currentInizioDurata.subscribe(element => {
        if (element != null) {
          this.currentData = element.annoIniziale
          
        }
      })

    }
    this.risultatiVolatileSubscription = this.configurazioneBusinessPlanService.currentRisultatiVolatile.subscribe(siVolatile => {
      this.isRisultatiVolatile = siVolatile
    })
    this.investimentiForm = this.formBuilder.group({
      investimenti: this.formBuilder.array([]),
      disinvestimenti: this.formBuilder.array([]),
    })
    this.getVociBilancioCheckInv();



    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })
  }

  getVociBilancioCheckInv() {
    if (this.bilancioPresente) {
      this.bilancioService.getVociBilancioCheckInv(this.businessPlanTemp.idStatoPatrimonialeAttivo, this.businessPlanTemp.idStatoPatrimonialePassivo, this.businessPlanTemp.idContoEconomico).subscribe(response => {
        this.liquiditaCassaBilancio = response.liquiditaCassa
        this.immobImmatBilancio = response.immobImmat
        this.immobMatBilancio = response.immobMat
        this.costiPersonaleBilancio = response.costiPersonale
        this.costiTfrBilancio = response.costiTfr
        this.costiFornitoriBilancio = response.costiFornitori
        this.valoreMagazBilancio = response.valoreMagaz
        this.ammortamentoBilancio = response.ammortamento
        /* se il bilancio è abbreviato devo verificare il valore in situazione debitoria */
        if (!this.businessPlanTemp.bilancioAbbreviato) {
          this.beneImmobilBilancio = response.beneImmobil
        }
      })
    }

  }
  getInvestimentiDisinvestimenti() {
    this.investimentiOrdinati = [];
    this.disinvestimentiOrdinati = [];
    if (this.businessPlanTemp) {
      if (this.isRisultatiVolatile && this.investimentiDisinvestimentiVolatile != null) {
        this.investimentiTempId = []
        this.disinvestimentiTempId = []
        this.disinvestimentiArray.clear();
        this.investimentiArray.clear();
        this.presenzaErroriInv = false;
        this.presenzaErroriDis = false;
        this.arrayErroriInv = [];
        this.arrayErroriLiquidita = []
        this.arrayErroriDebitoFornitori = []
        this.arrayErroriCostiPersonale = [];
        this.arrayErroriCostiTfr = [];
        this.arrayErroriImmobilMat = [];
        this.arrayErroriImmobilImmat = [];
        this.arrayErroriImportoDis = []
        this.arrayErroriValoreMagaz = [];
        this.arrayErroriAmmortamento = []

        //bisogna salvare tutti gli id degli investimenti e disinvestimenti anche nell'add nuov inv div
        if (this.investimentiDisinvestimentiVolatile.investimenti != null && this.investimentiDisinvestimentiVolatile.investimenti.length > 0) {
          this.investimentiOrdinati = this.riordinaArray(this.investimentiDisinvestimentiVolatile.investimenti)

          for (let i = 0; i < this.investimentiOrdinati.length; i++) {
            this.investimentiTempId.push(this.investimentiOrdinati[i].id)
            this.arrayErroriInv.push(false)
            this.arrayErroriLiquidita.push(false)
            this.tipoInv = null
            this.modalitaFin = null
            this.garanzieFin = null
            this.garanzieNuovoInv = null;
            this.trimestreBen = "";
            let effettiLdb = this.formBuilder.array([]);

            for (let j = 0; j < this.tipiInvestimento.length; j++) {
              if (this.investimentiOrdinati[i].idTipoInvestimento == this.tipiInvestimento[j].id) {
                this.tipoInv = this.tipiInvestimento[j]
              }
            }
            for (let j = 0; j < this.trimestreList.length; j++) {
              if (this.investimentiOrdinati[i].trimestreInvestimento == this.trimestreList[j].id) {
                this.trimestreInv = this.trimestreList[j]
              }
              if (this.investimentiOrdinati[i].lineeBusinessAssociate.length > 0 && this.investimentiOrdinati[i].trimestreBeneficio== this.trimestreList[j].id) {
                this.trimestreBen = this.trimestreList[j]
              }
            }
            for (let j = 0; j < this.listaModalitaFinanziamento.length; j++) {
              if (this.investimentiOrdinati[i].idModalitaFinanziamento == this.listaModalitaFinanziamento[j].id) {
                this.modalitaFin = this.listaModalitaFinanziamento[j]
              }
            }

            for (let j = 0; j < this.listaGaranzie.length; j++) {
              if (this.investimentiOrdinati[i].idDescrizioneGaranziaFinanziamento == this.listaGaranzie[j].id) {
                this.garanzieFin = this.listaGaranzie[j]
              }
            }
            for(let j = 0; j < this.investimentiOrdinati[i].lineeBusinessAssociate.length; j++){
              effettiLdb.push(this.formBuilder.group({
                idLdb: [{value: this.investimentiOrdinati[i].lineeBusinessAssociate[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                varRicavi: [{value: this.investimentiOrdinati[i].varRicavi[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },Validators.required],
                varCostiMaterie: [{value: this.investimentiOrdinati[i].varCostiMaterie[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },Validators.required],
                varCostiServizi: [{value: this.investimentiOrdinati[i].varCostiServizi[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },Validators.required],
                varCostiFissi: [{value: this.investimentiOrdinati[i].varCostiFissi[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },Validators.required],
              }));
            } 
            /* trimestre disabilitato. investimenti a trim 2 3 4 vanno gestiti */
            this.investimentiFormArray.push(this.formBuilder.group({
              tipoInvestimento: [{ value: this.tipoInv, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              descrizioneInvestimento: [this.investimentiOrdinati[i].descrizione == null ? { value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) } :
                { value: this.investimentiOrdinati[i].descrizione, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              importoInvestimento: [{ value: this.investimentiOrdinati[i].importo, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              annoInvestimento: [{ value: this.investimentiOrdinati[i].annoInvestimento == 0 ? '' : this.investimentiOrdinati[i].annoInvestimento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              trimestreInvestimento: [{ value: this.trimestreInv, disabled: true || this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              modalitaFinanziamento: [{ value: this.modalitaFin != null ? this.modalitaFin : '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              importoFinanziamento: [{ value: this.investimentiOrdinati[i].importoFinanziamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              mesiRimborsoFinanziamento: [{ value: this.investimentiOrdinati[i].mesiRimborsoFinanziamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              preammortamentoFinanziamento: [{ value: this.investimentiOrdinati[i].preammortamentoFinanziamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              descrizioneGaranziaFinanziamento: [{ value: this.garanzieFin != null ? this.garanzieFin : '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              importoGaranziaFinanziamento: [{ value: this.investimentiOrdinati[i].importoGaranziaFinanziamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              importoNuovoInvestitore: [{ value: this.investimentiOrdinati[i].importoNuovoInvestitore, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              importoLiquiditaCassa: [{ value: this.investimentiOrdinati[i].importoLiquiditaCassa, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              importoMezziPropri: [{ value: this.investimentiOrdinati[i].importoMezziPropri, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              ldbAssociate: [{ value: this.investimentiOrdinati[i].lineeBusinessAssociate, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              annoBeneficio: [{ value: this.investimentiOrdinati[i].lineeBusinessAssociate.length > 0 ? this.investimentiOrdinati[i].annoBeneficio : '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              trimestreBeneficio: [{ value: this.investimentiOrdinati[i].lineeBusinessAssociate.length > 0 ? this.trimestreBen : '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
              effettiLdb: effettiLdb,
            }))

            if(!(this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4))) this.investimentiFormArray[i].controls.modalitaFinanziamento.enable();
            this.investimentiFormArray[i].controls.tipoInvestimento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.descrizioneInvestimento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.importoInvestimento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.annoInvestimento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.trimestreInvestimento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.modalitaFinanziamento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.importoFinanziamento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.preammortamentoFinanziamento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.updateValueAndValidity();
            this.investimentiFormArray[i].controls.importoNuovoInvestitore.updateValueAndValidity();
            this.investimentiFormArray[i].controls.importoLiquiditaCassa.updateValueAndValidity();
            this.investimentiFormArray[i].controls.importoMezziPropri.updateValueAndValidity();
            this.investimentiFormArray[i].controls.ldbAssociate.updateValueAndValidity();
            this.investimentiFormArray[i].controls.annoBeneficio.updateValueAndValidity();
            this.investimentiFormArray[i].controls.trimestreBeneficio.updateValueAndValidity();
            this.investimentiFormArray[i].controls.effettiLdb.updateValueAndValidity();
            this.investimentiFormArray[i].updateValueAndValidity();

            this.onChangeInvestimento(i)
          };
        }
        if (this.investimentiDisinvestimentiVolatile.disinvestimenti != null && this.investimentiDisinvestimentiVolatile.disinvestimenti.length > 0) {
          this.disinvestimentiOrdinati = this.riordinaArray(this.investimentiDisinvestimentiVolatile.disinvestimenti)
          for (let i = 0; i < this.disinvestimentiOrdinati.length; i++) {
            this.disinvestimentiTempId.push(this.disinvestimentiOrdinati[i].id)
            for (let j = 0; j < this.tipiDisinvestimento.length; j++) {
              if (this.disinvestimentiOrdinati[i].idTipoDisinvestimento == this.tipiDisinvestimento[j].id) {
                this.tipoDisinv = this.tipiDisinvestimento[j]
              }
            }
            for (let j = 0; j < this.trimestreList.length; j++) {
              if (this.disinvestimentiOrdinati[i].trimestreDisinvestimento == this.trimestreList[j].id) {
                this.trimestreDis = this.trimestreList[j]
              }
            }
            this.arrayErroriDebitoFornitori.push(false)
            this.arrayErroriCostiPersonale.push(false);
            this.arrayErroriCostiTfr.push(false);
            this.arrayErroriImmobilMat.push(false);
            this.arrayErroriImmobilImmat.push(false);
            this.arrayErroriImportoDis.push(false)
            this.arrayErroriValoreMagaz.push(false);
            this.arrayErroriAmmortamento.push(false)

            this.disinvestimentiFormArray.push(this.formBuilder.group({
              tipoDisinvestimento: [{ value: this.tipoDisinv, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              descrizioneDisinvestimento: [this.disinvestimentiOrdinati[i].descrizione == null ? { value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) } :
                { value: this.disinvestimentiOrdinati[i].descrizione, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }], //capire come impostare il required
              importoDisinvestimento: [{ value: this.disinvestimentiOrdinati[i].importo, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              annoDisinvestimento: [{ value: this.disinvestimentiOrdinati[i].annoDisinvestimento, disabled: true || this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              trimestreDisinvestimento: [{ value: this.trimestreDis, disabled: true || this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              importoCostiRetribuzioniPersonale: [{ value: this.disinvestimentiOrdinati[i].importoCostiRetribuzioniPersonale, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              importoCostiTfrPersonale: [{ value: this.disinvestimentiOrdinati[i].importoCostiTfrPersonale, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              importoDebitoFornitori: [{ value: this.disinvestimentiOrdinati[i].importoDebitoFornitori, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              importoImmobilizzazioniMateriali: [{ value: this.disinvestimentiOrdinati[i].importoImmobilizzazioniMateriali, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              importoImmobilizzazioniImmateriali: [{ value: this.disinvestimentiOrdinati[i].importoImmobilizzazioniImmateriali, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              importoValoreMagazzino: [{ value: this.disinvestimentiOrdinati[i].importoValoreMagazzino, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
              importoAmmortamento: [{ value: this.disinvestimentiOrdinati[i].importoAmmortamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
            }))
            this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.updateValueAndValidity();
            this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.updateValueAndValidity();
            this.disinvestimentiFormArray[i].controls.importoDisinvestimento.updateValueAndValidity();
            this.disinvestimentiFormArray[i].controls.annoDisinvestimento.updateValueAndValidity();
            this.disinvestimentiFormArray[i].controls.trimestreDisinvestimento.updateValueAndValidity();

            this.disinvestimentiFormArray[i].updateValueAndValidity();

            this.onChangeDisinvestimento(i);
          }
        }
      } else {
        this.configurazioneBusinessPlanService.getInvestimentiDisinvestimenti(this.businessPlanTemp.id).subscribe((response) => {
          this.investimentiTempId = []
          this.disinvestimentiTempId = []
          this.disinvestimentiArray.clear();
          this.investimentiArray.clear();
          this.presenzaErroriInv = false;
          this.presenzaErroriDis = false;

          //bisogna salvare tutti gli id degli investimenti e disinvestimenti anche nell'add nuov inv div
          if (response.investimenti != null && response.investimenti.length > 0) {

            this.investimentiOrdinati = this.riordinaArray(response.investimenti);
            for (let i = 0; i < this.investimentiOrdinati.length; i++) {
              this.arrayErroriInv.push(false)
              this.arrayErroriLiquidita.push(false)
              this.tipoInv = 0
              this.modalitaFin = null
              this.garanzieFin = null
              this.garanzieNuovoInv = null
              this.investimentiTempId.push(this.investimentiOrdinati[i].id)
              this.trimestreBen = "";
              let effettiLdb = this.formBuilder.array([]);
  
              for (let j = 0; j < this.tipiInvestimento.length; j++) {
                if (this.investimentiOrdinati[i].idTipoInvestimento == this.tipiInvestimento[j].id) {
                  this.tipoInv = this.tipiInvestimento[j]
                }
              }
              for (let j = 0; j < this.trimestreList.length; j++) {
                if (this.investimentiOrdinati[i].trimestreInvestimento == this.trimestreList[j].id) {
                  this.trimestreInv = this.trimestreList[j]
                }
                if (this.investimentiOrdinati[i].lineeBusinessAssociate.length > 0 && this.investimentiOrdinati[i].trimestreBeneficio== this.trimestreList[j].id) {
                  this.trimestreBen = this.trimestreList[j]
                }
              }
              for (let j = 0; j < this.listaModalitaFinanziamento.length; j++) {
                if (this.investimentiOrdinati[i].idModalitaFinanziamento == this.listaModalitaFinanziamento[j].id) {
                  this.modalitaFin = this.listaModalitaFinanziamento[j]
                }
              }
              for (let j = 0; j < this.listaGaranzie.length; j++) {
                //if (this.modalitaFin.id == 1 || this.modalitaFin.id == 5 || this.modalitaFin.id == 6 || this.modalitaFin.id == 10) {
                if (this.investimentiOrdinati[i].idDescrizioneGaranziaFinanziamento == this.listaGaranzie[j].id) {
                  this.garanzieFin = this.listaGaranzie[j]
                }
                //if (this.modalitaFin.id == 4 || this.modalitaFin.id == 8 || this.modalitaFin.id == 9 || this.modalitaFin.id == 11) {
              }

              for(let j = 0; j < this.investimentiOrdinati[i].lineeBusinessAssociate.length; j++){
                effettiLdb.push(this.formBuilder.group({
                  idLdb: [{value: this.investimentiOrdinati[i].lineeBusinessAssociate[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                  varRicavi: [{value: this.investimentiOrdinati[i].varRicavi[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },Validators.required],
                  varCostiMaterie: [{value: this.investimentiOrdinati[i].varCostiMaterie[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },Validators.required],
                  varCostiServizi: [{value: this.investimentiOrdinati[i].varCostiServizi[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },Validators.required],
                  varCostiFissi: [{value: this.investimentiOrdinati[i].varCostiFissi[j], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) },Validators.required],
                }));
              } 

              this.investimentiFormArray.push(this.formBuilder.group({
                tipoInvestimento: [{ value: this.tipoInv, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                descrizioneInvestimento: [this.investimentiOrdinati[i].descrizione == null ? { value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) } :
                  { value: this.investimentiOrdinati[i].descrizione, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                importoInvestimento: [{ value: this.investimentiOrdinati[i].importo, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                annoInvestimento: [{ value: this.investimentiOrdinati[i].annoInvestimento == 0 ? '' : this.investimentiOrdinati[i].annoInvestimento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                trimestreInvestimento: [{ value: this.trimestreInv, disabled: true || this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                modalitaFinanziamento: [{ value: this.modalitaFin, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                importoFinanziamento: [{ value: this.investimentiOrdinati[i].importoFinanziamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                mesiRimborsoFinanziamento: [{ value: this.investimentiOrdinati[i].mesiRimborsoFinanziamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                preammortamentoFinanziamento: [{ value: this.investimentiOrdinati[i].preammortamentoFinanziamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                descrizioneGaranziaFinanziamento: [{ value: this.garanzieFin, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                importoGaranziaFinanziamento: [{ value: this.investimentiOrdinati[i].importoGaranziaFinanziamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                importoNuovoInvestitore: [{ value: this.investimentiOrdinati[i].importoNuovoInvestitore, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                importoLiquiditaCassa: [{ value: this.investimentiOrdinati[i].importoLiquiditaCassa, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                importoMezziPropri: [{ value: this.investimentiOrdinati[i].importoMezziPropri, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                ldbAssociate: [{ value: this.investimentiOrdinati[i].lineeBusinessAssociate, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                annoBeneficio: [{ value: this.investimentiOrdinati[i].lineeBusinessAssociate.length > 0 ? this.investimentiOrdinati[i].annoBeneficio : '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                trimestreBeneficio: [{ value: this.investimentiOrdinati[i].lineeBusinessAssociate.length > 0 ? this.trimestreBen : '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
                effettiLdb: effettiLdb,

              }))
              if(!(this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4))) this.investimentiFormArray[i].controls.modalitaFinanziamento.enable();
              this.investimentiFormArray[i].controls.tipoInvestimento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.descrizioneInvestimento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.importoInvestimento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.annoInvestimento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.trimestreInvestimento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.modalitaFinanziamento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.importoFinanziamento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.preammortamentoFinanziamento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.updateValueAndValidity();
              this.investimentiFormArray[i].controls.importoNuovoInvestitore.updateValueAndValidity();
              this.investimentiFormArray[i].controls.importoLiquiditaCassa.updateValueAndValidity();
              this.investimentiFormArray[i].controls.importoMezziPropri.updateValueAndValidity();
              this.investimentiFormArray[i].controls.ldbAssociate.updateValueAndValidity();
              this.investimentiFormArray[i].controls.annoBeneficio.updateValueAndValidity();
              this.investimentiFormArray[i].controls.trimestreBeneficio.updateValueAndValidity();
              this.investimentiFormArray[i].controls.effettiLdb.updateValueAndValidity();
              this.investimentiFormArray[i].updateValueAndValidity();

              this.onChangeInvestimento(i)
            };
          }
          if (response.disinvestimenti != null && response.disinvestimenti.length > 0) {
            this.disinvestimentiOrdinati = this.riordinaArray(response.disinvestimenti);
            for (let i = 0; i < this.disinvestimentiOrdinati.length; i++) {
              this.arrayErroriCostiPersonale.push(false);
              this.arrayErroriCostiTfr.push(false);
              this.arrayErroriImmobilMat.push(false);
              this.arrayErroriImmobilImmat.push(false);
              this.arrayErroriImportoDis.push(false)
              this.arrayErroriValoreMagaz.push(false);
              this.arrayErroriAmmortamento.push(false)

              this.arrayErroriDebitoFornitori.push(false)
              this.disinvestimentiTempId.push(this.disinvestimentiOrdinati[i].id)
              for (let j = 0; j < this.tipiDisinvestimento.length; j++) {
                if (this.disinvestimentiOrdinati[i].idTipoDisinvestimento == this.tipiDisinvestimento[j].id) {
                  this.tipoDisinv = this.tipiDisinvestimento[j]
                }
              }
              for (let j = 0; j < this.trimestreList.length; j++) {
                if (this.disinvestimentiOrdinati[i].trimestreDisinvestimento == this.trimestreList[j].id) {
                  this.trimestreDis = this.trimestreList[j]
                }
              }
              this.disinvestimentiFormArray.push(this.formBuilder.group({
                tipoDisinvestimento: [{ value: this.tipoDisinv, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                descrizioneDisinvestimento: [this.disinvestimentiOrdinati[i].descrizione == null ? { value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) } :
                  { value: this.disinvestimentiOrdinati[i].descrizione, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }], //capire come impostare il required
                importoDisinvestimento: [{ value: this.disinvestimentiOrdinati[i].importo, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                annoDisinvestimento: [{ value: this.disinvestimentiOrdinati[i].annoDisinvestimento, disabled: true || this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                trimestreDisinvestimento: [{ value: this.trimestreDis, disabled: true || this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                importoCostiRetribuzioniPersonale: [{ value: this.disinvestimentiOrdinati[i].importoCostiRetribuzioniPersonale, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                importoCostiTfrPersonale: [{ value: this.disinvestimentiOrdinati[i].importoCostiTfrPersonale, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                importoDebitoFornitori: [{ value: this.disinvestimentiOrdinati[i].importoDebitoFornitori, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                importoImmobilizzazioniMateriali: [{ value: this.disinvestimentiOrdinati[i].importoImmobilizzazioniMateriali, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                importoImmobilizzazioniImmateriali: [{ value: this.disinvestimentiOrdinati[i].importoImmobilizzazioniImmateriali, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                importoValoreMagazzino: [{ value: this.disinvestimentiOrdinati[i].importoValoreMagazzino, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
                importoAmmortamento: [{ value: this.disinvestimentiOrdinati[i].importoAmmortamento, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],


              }))
              this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.updateValueAndValidity();
              this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.updateValueAndValidity();
              this.disinvestimentiFormArray[i].controls.importoDisinvestimento.updateValueAndValidity();
              this.disinvestimentiFormArray[i].controls.annoDisinvestimento.updateValueAndValidity();
              this.disinvestimentiFormArray[i].controls.trimestreDisinvestimento.updateValueAndValidity();
              this.disinvestimentiFormArray[i].updateValueAndValidity();

              this.onChangeDisinvestimento(i);
            }
          }
        })
      }
    }
  }
  /* modificare. dovrà resettare la suddivisione */
  onchangeImportoInvestimento(i: number) {
    if (this.investimentiFormArray[i].controls.importoInvestimento.value != null && this.investimentiFormArray[i].controls.modalitaFinanziamento.value != null) {
      /* questo mi serve se cambia importo investimento dopo aver selezionato la modalità */
      if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 4) {
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 3) {
        this.investimentiFormArray[i].controls.importoMezziPropri.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 2) {
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1) {
        this.investimentiFormArray[i].controls.importoFinanziamento.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
      } else {
        this.investimentiFormArray[i].controls.importoFinanziamento.setValue(0)
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValue(0)
        this.investimentiFormArray[i].controls.importoMezziPropri.setValue(0)
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValue(0)
        this.investimentiFormArray[i].controls.importoFinanziamento.setValue(0)

      }

    }
    this.investimentiFormArray[i].controls.importoFinanziamento.updateValueAndValidity();
    this.investimentiFormArray[i].controls.importoNuovoInvestitore.updateValueAndValidity();
    this.investimentiFormArray[i].controls.importoLiquiditaCassa.updateValueAndValidity();
    this.investimentiFormArray[i].controls.importoMezziPropri.updateValueAndValidity();
    this.arrayErroriInv.splice(i, 1, false)
    this.arrayErroriLiquidita.splice(i, 1, false)
  }


  onchangeImportoDisinvestimento(i: number) {
    /* solo se seleziono vendita ramo d'azienda */
    if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value != null && this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 &&
      this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value != null && this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value == 0) {
      this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.setValue(0);
      this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.setValue(0);
      this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.setValue(0);
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.setValue(0);
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.setValue(0);
      this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.setValue(0);
      this.disinvestimentiFormArray[i].controls.importoAmmortamento.setValue(0);

      this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.updateValueAndValidity();
      this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.updateValueAndValidity();
      this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.updateValueAndValidity();
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.updateValueAndValidity();
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.updateValueAndValidity();
      this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.updateValueAndValidity();
      this.disinvestimentiFormArray[i].controls.importoAmmortamento.updateValueAndValidity()
      this.arrayErroriImportoDis.splice(i, 1, false)
      this.arrayErroriCostiPersonale.splice(i, 1, false);
      this.arrayErroriCostiTfr.splice(i, 1, false);
      this.arrayErroriImmobilMat.splice(i, 1, false);
      this.arrayErroriImmobilImmat.splice(i, 1, false);
      this.arrayErroriValoreMagaz.splice(i, 1, false);
      this.arrayErroriAmmortamento.splice(i, 1, false);
      this.arrayErroriDebitoFornitori.splice(i, 1, false);
    }
  }

  onChangeInvestimento(i) {
    if (this.investimentiFormArray[i].controls.tipoInvestimento.value) {
      if (this.investimentiFormArray[i].controls.tipoInvestimento.value.id == 8) {
        this.investimentiFormArray[i].controls.descrizioneInvestimento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneInvestimento.updateValueAndValidity();
      } else {
        this.investimentiFormArray[i].controls.descrizioneInvestimento.clearValidators()
        this.investimentiFormArray[i].controls.descrizioneInvestimento.updateValueAndValidity();
      }

      if (this.investimentiFormArray[i].controls.tipoInvestimento.value.id == 9) {
        if(!(this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4))) this.investimentiFormArray[i].controls.trimestreInvestimento.enable();
        this.investimentiFormArray[i].controls.trimestreInvestimento.updateValueAndValidity();
      } else {
        this.investimentiFormArray[i].controls.trimestreInvestimento.disable();
        this.investimentiFormArray[i].controls.trimestreInvestimento.updateValueAndValidity();
      }
    }

  }

  aggiungiInvestimento() {
    this.investimentiTempId.push(0)
    this.arrayErroriInv.push(false)
    this.arrayErroriLiquidita.push(false)
    this.investimentiDirty = true
    this.investimentiFormArray.push(this.formBuilder.group({
      tipoInvestimento: ['', Validators.required],
      descrizioneInvestimento: [''], //capire come impostare il required
      importoInvestimento: ['0', Validators.required],
      annoInvestimento: ['', Validators.required],
      trimestreInvestimento: [{ value: this.trimestreList[0], disabled: true }, Validators.required],
      modalitaFinanziamento: [{ value: '', disabled: true }, Validators.required],
      importoFinanziamento: ['0'],
      mesiRimborsoFinanziamento: [''],
      preammortamentoFinanziamento: [''],
      descrizioneGaranziaFinanziamento: [''],
      importoGaranziaFinanziamento: ['0'],
      importoNuovoInvestitore: ['0'],
      importoLiquiditaCassa: ['0'],
      importoMezziPropri: ['0'],
      ldbAssociate: [''],
      annoBeneficio: [''],
      trimestreBeneficio: [''],
      effettiLdb: this.formBuilder.array([])
    })
    )
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.tipoInvestimento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.descrizioneInvestimento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoInvestimento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.annoInvestimento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.trimestreInvestimento.updateValueAndValidity();

    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.modalitaFinanziamento.updateValueAndValidity();

    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoFinanziamento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.mesiRimborsoFinanziamento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.preammortamentoFinanziamento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.descrizioneGaranziaFinanziamento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoGaranziaFinanziamento.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoNuovoInvestitore.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoLiquiditaCassa.updateValueAndValidity();
    this.investimentiFormArray[this.investimentiFormArray.length - 1].controls.importoMezziPropri.updateValueAndValidity();

    this.investimentiFormArray[this.investimentiFormArray.length - 1].updateValueAndValidity();
    this.investimentiArray.updateValueAndValidity();
  }


  /* delirio */
  onChangeModalitaFinanziamento(i: number) {
    if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value) {
      this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValue(0);
      this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValue(0);
      this.investimentiFormArray[i].controls.importoMezziPropri.setValue(0);
      this.investimentiFormArray[i].controls.importoFinanziamento.setValue(0);
      this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValue(0);
      this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValue(0);
      this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValue('');
      this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValue(0);
      if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15) {
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14) {
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);

        this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators()

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13) {
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);

        this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators()

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12) {
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);

        this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators()
        this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators()

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 11) {
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
        /* pulisci finanziamento */
        this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10) {
        this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
        /* pulisci nuovo investitore */
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 9) {
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
        /* pulisci finanziamento e liquidita */
        this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 8) {
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
        /* pulisci finanziamento e mezzi propri */
        this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();


      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 7) {
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);

        this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();


      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6) {
        this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);

        this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5) {
        this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);

        this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
        this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 4) {
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
        this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
        this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 3) {
        this.investimentiFormArray[i].controls.importoMezziPropri.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoMezziPropri.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
        this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 2) {
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)
        this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
        this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();

      } else if (this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1) {
        this.investimentiFormArray[i].controls.importoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.setValidators(Validators.required);
        this.investimentiFormArray[i].controls.importoFinanziamento.setValue(this.investimentiFormArray[i].controls.importoInvestimento.value)

        this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
        this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();
      } else {
        this.investimentiFormArray[i].controls.importoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.preammortamentoFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.clearValidators();
        this.investimentiFormArray[i].controls.importoNuovoInvestitore.clearValidators();
        this.investimentiFormArray[i].controls.importoMezziPropri.clearValidators();
        this.investimentiFormArray[i].controls.importoLiquiditaCassa.clearValidators();
      }
      this.investimentiFormArray[i].controls.importoFinanziamento.updateValueAndValidity();
      this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.updateValueAndValidity();
      this.investimentiFormArray[i].controls.preammortamentoFinanziamento.updateValueAndValidity();
      this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.updateValueAndValidity();
      this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.updateValueAndValidity();
      this.investimentiFormArray[i].controls.importoNuovoInvestitore.updateValueAndValidity();
      this.investimentiFormArray[i].controls.importoLiquiditaCassa.updateValueAndValidity();
      this.investimentiFormArray[i].controls.importoMezziPropri.updateValueAndValidity();
    }
  }
  eliminaInvestimento(i: number) {
    this.investimentiDirty = true
    this.investimentiTempId.splice(i, 1)
    this.arrayErroriInv.splice(i, 1)
    this.arrayErroriLiquidita.splice(i, 1, false)
    this.investimentiArray.removeAt(i)
    this.investimentiArray.updateValueAndValidity()

  }

  /* FUNZIONE USATA PER DISABILITARE le voci della select */
  checkCurrentAnno(i: number, presenzaCassa: boolean) {
    /* se l'azienda è start up non ho liquidità di caasa */
    if (this.investimentiFormArray[i].controls.annoInvestimento.value != '') {
      //this.liquiditaCassaBilancio == 0 vuol dire o che non ho bilancio (start up) o che non ho liquidità a bilancio
      if ((this.liquiditaCassaBilancio == 0 || this.investimentiFormArray[i].controls.annoInvestimento.value > this.currentData) && presenzaCassa) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  onChangeAnnoDisinvestimento(i: number) {
    /* if sulla cessione ramo d'azienda */
    if (this.disinvestimentiFormArray[i].controls.annoDisinvestimento.value != '') {
      this.disinvestimentiFormArray[i].controls.annoDisinvestimento.updateValueAndValidity()
      if (this.disinvestimentiFormArray[i].controls.annoDisinvestimento.value < this.currentData) {
        this.disinvestimentiFormArray[i].controls.annoDisinvestimento.setValue(this.currentData)
      }
    }
  }

  /* attiva modifica la select delle modalita finanziamento */
  onChangeAnnoInvestimento(i: number) {
    this.investimentiFormArray[i].controls.annoInvestimento.updateValueAndValidity()
    if (this.investimentiFormArray[i].controls.annoInvestimento.value != '') {
      this.investimentiFormArray[i].controls.modalitaFinanziamento.setValue('');
      if (this.investimentiFormArray[i].controls.annoInvestimento.value < this.currentData) {
        this.investimentiFormArray[i].controls.annoInvestimento.setValue(this.currentData)
      }
      if(!(this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4))) this.investimentiFormArray[i].controls.modalitaFinanziamento.enable();
      /* da aggiungere la parte che prende l'anno direttamente da be */
      this.investimentiFormArray[i].controls.modalitaFinanziamento.updateValueAndValidity();
    }
    this.onChangeModalitaFinanziamento(i)
  }

  onChangeAnnoBeneficio(i: number){
    this.investimentiFormArray[i].controls.annoBeneficio.updateValueAndValidity()
    if (this.investimentiFormArray[i].controls.annoBeneficio.value != '' && this.investimentiFormArray[i].controls.annoBeneficio.value < this.investimentiFormArray[i].controls.annoInvestimento.value) {
        this.investimentiFormArray[i].controls.annoBeneficio.setValue(this.investimentiFormArray[i].controls.annoInvestimento.value);
      }

  }

  onChangeDisinvestimento(i: number) {
    /* altro */
    if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 6) {
      /* pulir tutti i validatori e gli errori della suddivisione */
      this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.setValidators(Validators.required);
      this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.clearValidators();
      /* pulir tutti i validatori e gli errori della suddivisione */

      this.arrayErroriDebitoFornitori.splice(i, 1, false);
      this.arrayErroriCostiPersonale.splice(i, 1, false);
      this.arrayErroriCostiTfr.splice(i, 1, false);
      this.arrayErroriImmobilMat.splice(i, 1, false);
      this.arrayErroriImmobilImmat.splice(i, 1, false);
      this.arrayErroriValoreMagaz.splice(i, 1, false);
      this.arrayErroriAmmortamento.splice(i, 1, false);
      this.arrayErroriImportoDis.splice(i, 1, false);

      /* vendita ramo d'azienda */
    } else if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5) {

      this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.setValidators(Validators.required);
      this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.setValidators(Validators.required);
      this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.setValidators(Validators.required);
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.setValidators(Validators.required);
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.setValidators(Validators.required);
      this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.setValidators(Validators.required);
      this.disinvestimentiFormArray[i].controls.importoAmmortamento.setValidators(Validators.required);

      this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.clearValidators();
    } else {
      this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.clearValidators();
      this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.clearValidators();
      this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.clearValidators();
      /* pulir tutti i validatori e gli errori della suddivisione */
      this.arrayErroriDebitoFornitori.splice(i, 1, false);
      this.arrayErroriCostiPersonale.splice(i, 1, false);
      this.arrayErroriCostiTfr.splice(i, 1, false);
      this.arrayErroriImmobilMat.splice(i, 1, false);
      this.arrayErroriImmobilImmat.splice(i, 1, false);
      this.arrayErroriValoreMagaz.splice(i, 1, false);
      this.arrayErroriAmmortamento.splice(i, 1, false);
      this.arrayErroriImportoDis.splice(i, 1, false);

    }
    this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.updateValueAndValidity();
    this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.updateValueAndValidity();
    this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.updateValueAndValidity();
    this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.updateValueAndValidity();
    this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.updateValueAndValidity();
    this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.updateValueAndValidity();
    this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.updateValueAndValidity();
    this.disinvestimentiFormArray[i].controls.importoAmmortamento.updateValueAndValidity();
  }

  aggiungiDisinvestimento() {
    this.disinvestimentiTempId.push(0)
    this.arrayErroriDebitoFornitori.push(false);
    this.arrayErroriCostiPersonale.push(false);
    this.arrayErroriCostiTfr.push(false);
    this.arrayErroriImmobilMat.push(false);
    this.arrayErroriImmobilImmat.push(false);
    this.arrayErroriImportoDis.push(false)
    this.arrayErroriValoreMagaz.push(false);
    this.arrayErroriAmmortamento.push(false);

    this.disinvestimentiDirty = true
    this.disinvestimentiFormArray.push(this.formBuilder.group({
      tipoDisinvestimento: ['', Validators.required],
      importoDisinvestimento: ['0', Validators.required],
      descrizioneDisinvestimento: [''],
      annoDisinvestimento: [{ value: this.currentData, disabled: true }, Validators.required],
      trimestreDisinvestimento: [{ value: this.trimestreList[0], disabled: true }, Validators.required],
      importoCostiRetribuzioniPersonale: ['0'],
      importoCostiTfrPersonale: ['0'],
      importoDebitoFornitori: ['0'],
      importoImmobilizzazioniMateriali: ['0'],
      importoImmobilizzazioniImmateriali: ['0'],
      importoValoreMagazzino: ['0'],
      importoAmmortamento: ['0'],
    })
    )
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.tipoDisinvestimento.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.descrizioneDisinvestimento.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoDisinvestimento.updateValueAndValidity();
    //this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.anniIncasso.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.annoDisinvestimento.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.trimestreDisinvestimento.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoCostiRetribuzioniPersonale.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoCostiTfrPersonale.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoDebitoFornitori.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoImmobilizzazioniMateriali.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoImmobilizzazioniImmateriali.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoValoreMagazzino.updateValueAndValidity();
    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].controls.importoAmmortamento.updateValueAndValidity();

    this.disinvestimentiFormArray[this.disinvestimentiFormArray.length - 1].updateValueAndValidity();
    this.disinvestimentiArray.updateValueAndValidity();

  }

  eliminaDisinvestimento(i) {
    this.disinvestimentiDirty = true
    this.disinvestimentiTempId.splice(i, 1)
    this.arrayErroriDebitoFornitori.splice(i, 1, false);
    this.arrayErroriCostiPersonale.splice(i, 1, false);
    this.arrayErroriCostiTfr.splice(i, 1, false);
    this.arrayErroriImmobilMat.splice(i, 1, false);
    this.arrayErroriImmobilImmat.splice(i, 1, false);
    this.arrayErroriImportoDis.splice(i, 1, false)
    this.arrayErroriValoreMagaz.splice(i, 1, false);
    this.arrayErroriAmmortamento.splice(i, 1, false);
    this.disinvestimentiArray.removeAt(i)
    this.disinvestimentiArray.updateValueAndValidity()
  }

  Annulla() {
    if (this.isFormDirty()) {
      this.submitted = false; // lo metto qui altrimenti i campi diventano rossi mentre cè la modale attiva
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Cliccando su 'Annulla' tutte le modifiche non salvate su questa pagina andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.disinvestimentiTempId = []
          this.investimentiTempId = []
          this.investimentiForm.reset()
          this.investimentiValid = true;
          this.emitInvestimentiFormValid.emit({ investimentiValid: this.investimentiValid, investimentiDisinvestimentiDirty: this.submitted })
          this.getInvestimentiDisinvestimenti();
          this.markFormPristine()
        }
      })
    }
  }

  markFormPristine() {
    this.investimentiForm.markAsPristine()
    this.investimentiDirty = false;
    this.disinvestimentiDirty = false;
  }

  elaboraInvestimentiDisinvestimenti() {
    if (this.investimentiValid) {
      let investimentiList: Investimenti[] = [];
      let disinvestimentiList: Disinvestimenti[] = [];

      for (let i = 0; i < this.investimentiFormArray.length; i++) {
        let lineeBusinessAssociate = [];
        let varRicavi = [];
        let varCostiMaterie = [];
        let varCostiServizi = [];
        let varCostiFissi = [];

        for(let j = 0; j < (<UntypedFormArray> this.investimentiFormArray[i].controls.effettiLdb).length; j++){
          const formLineaContr = (<UntypedFormGroup> (<UntypedFormArray> this.investimentiFormArray[i].controls.effettiLdb).controls[j]).controls;
          lineeBusinessAssociate.push(formLineaContr.idLdb.value);
          varRicavi.push(formLineaContr.varRicavi.value);
          varCostiMaterie.push(formLineaContr.varCostiMaterie.value);
          varCostiServizi.push(formLineaContr.varCostiServizi.value);4
          varCostiFissi.push(formLineaContr.varCostiFissi.value)

        }       
        investimentiList.push({
          id: this.investimentiTempId[i],
          idTipoInvestimento: this.investimentiFormArray[i].controls.tipoInvestimento.value.id,
          descrizione: this.investimentiFormArray[i].controls.tipoInvestimento.value.id == 8 ? this.investimentiFormArray[i].controls.descrizioneInvestimento.value : '',
          importo: this.investimentiFormArray[i].controls.importoInvestimento.value != '' ? parseFloat(this.investimentiFormArray[i].controls.importoInvestimento.value) : 0,
          annoInvestimento: this.investimentiFormArray[i].controls.annoInvestimento.value,
          trimestreInvestimento: this.investimentiFormArray[i].controls.trimestreInvestimento.value.id,
          idModalitaFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id,

          importoFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoFinanziamento.value) : 0,
          mesiRimborsoFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.value : 0,
          preammortamentoFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? this.investimentiFormArray[i].controls.preammortamentoFinanziamento.value : 0,
          importoGaranziaFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.value) : 0,
          idDescrizioneGaranziaFinanziamento: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 1 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.value.id : 0,

          importoNuovoInvestitore: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 4 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 8 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 9 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 11 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 12 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoNuovoInvestitore.value) : 0,
          importoMezziPropri: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 3 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 6 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 7 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 9 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 11 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 14 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoMezziPropri.value) : 0,
          importoLiquiditaCassa: this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 2 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 5 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 7 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 8 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 10 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 11 || this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 13 ||
            this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoLiquiditaCassa.value) : 0,
            lineeBusinessAssociate: lineeBusinessAssociate,
            annoBeneficio: lineeBusinessAssociate.length > 0 ? this.investimentiFormArray[i].controls.annoBeneficio.value : 0,
            trimestreBeneficio: lineeBusinessAssociate.length > 0 ? this.investimentiFormArray[i].controls.trimestreBeneficio.value.id : 0,
            varRicavi: varRicavi,
            varCostiMaterie: varCostiMaterie,
            varCostiServizi: varCostiServizi,
            varCostiFissi: varCostiFissi
        })
      }
      for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
        disinvestimentiList.push({
          id: this.disinvestimentiTempId[i],
          idTipoDisinvestimento: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id,
          importo: this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value) : 0,
          descrizione: this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.value ? this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.value : '',
          annoDisinvestimento: this.disinvestimentiFormArray[i].controls.annoDisinvestimento.value,
          trimestreDisinvestimento: this.disinvestimentiFormArray[i].controls.trimestreDisinvestimento.value.id,

          importoCostiRetribuzioniPersonale: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value) : 0,
          importoCostiTfrPersonale: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value) : 0,
          importoDebitoFornitori: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value) : 0,
          importoImmobilizzazioniMateriali: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value) : 0,
          importoImmobilizzazioniImmateriali: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value) : 0,
          importoValoreMagazzino: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value) : 0,
          importoAmmortamento: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoAmmortamento.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoAmmortamento.value) : 0,
        })
      }
      let request: InvestimentiDisinvestimenti = {
        idBusinessPlan: this.businessPlanTemp.id,
        investimenti: investimentiList,
        disinvestimenti: disinvestimentiList,
      }
      //this.configurazioneBusinessPlanService.elaboraInvestimentiDisinvestimenti(request)
      return request
    }

  }

  isFormDirty() {
    this.submitted = false;
    for (let i = 0; i < this.investimentiFormArray.length; i++) {
      this.investimentiFormArray[i].updateValueAndValidity();
      if (this.investimentiFormArray[i].dirty) {
        this.investimentiDirty = true;
      }
    }
    for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
      this.disinvestimentiFormArray[i].updateValueAndValidity();
      if (this.disinvestimentiFormArray[i].dirty) {
        this.disinvestimentiDirty = true;
      }
    }
    this.investimentiArray.updateValueAndValidity();
    this.disinvestimentiArray.updateValueAndValidity();
    this.investimentiForm.updateValueAndValidity();
    if (this.disinvestimentiDirty || this.investimentiDirty) {
      this.investimentiForm.markAsDirty()
    }
    this.submitted = this.investimentiForm.dirty
    /* }*/
    return this.submitted;
  }

  isFormValid() {
    this.presenzaErroriInv = false
    this.presenzaErroriDis = false;
    if (this.investimentiFormArray.length > 0) {
      for (let i = 0; i < this.investimentiFormArray.length; i++) {
        this.investimentiFormArray[i].updateValueAndValidity()

        this.arrayErroriInv.splice(i, 1, parseFloat(this.investimentiFormArray[i].controls.importoInvestimento.value) != parseFloat(this.investimentiFormArray[i].controls.importoFinanziamento.value) + parseFloat(this.investimentiFormArray[i].controls.importoNuovoInvestitore.value) +
          parseFloat(this.investimentiFormArray[i].controls.importoLiquiditaCassa.value) + parseFloat(this.investimentiFormArray[i].controls.importoMezziPropri.value) ? true : false)
        /* aggiungo il check su liquidita di cassa */
        this.arrayErroriLiquidita.splice(i, 1, this.investimentiFormArray[i].controls.importoLiquiditaCassa.value > this.liquiditaCassaBilancio ? true : false)
        this.presenzaErroriInv = this.presenzaErroriInv || this.arrayErroriInv[i] || this.arrayErroriLiquidita[i];
      }
    }
    if (this.disinvestimentiFormArray.length > 0) {
      /* devo fare losplice dei controlli condizionalmente */
      for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
        if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5) {
          /* controllo sulla somma non serve piu per il ramo di azienda */
          /* let sommaParzialiDis = parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value) + parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value) +
            parseFloat(this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value) + parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value) +
            parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value) + parseFloat(this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value)
          this.disinvestimentiFormArray[i].updateValueAndValidity()
          this.arrayErroriImportoDis.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value) != sommaParzialiDis ? true : false) */
          /* controllo delle singole voci rispetto al bilancio */
          this.arrayErroriCostiPersonale.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value) > this.costiPersonaleBilancio ? true : false);
          this.arrayErroriCostiTfr.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value) > this.costiTfrBilancio ? true : false);
          this.arrayErroriDebitoFornitori.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value) > this.costiFornitoriBilancio ? true : false);
          this.arrayErroriImmobilMat.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value) > this.immobMatBilancio ? true : false);
          this.arrayErroriImmobilImmat.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value) > this.immobImmatBilancio ? true : false);
          this.arrayErroriValoreMagaz.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value) > this.valoreMagazBilancio ? true : false);
          this.arrayErroriAmmortamento.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoAmmortamento.value) > this.ammortamentoBilancio ? true : false);

        }
        if (this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 4) {
          /* errore sull'importo controllato con bilancio a BE. forse bisognerà agigungere altri id */
          this.arrayErroriImportoDis.splice(i, 1, parseFloat(this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value) > this.beneImmobilBilancio ? true : false)

        }
        this.presenzaErroriDis = this.presenzaErroriDis || this.arrayErroriImportoDis[i] || this.arrayErroriCostiPersonale[i] || this.arrayErroriDebitoFornitori[i] ||
          this.arrayErroriCostiTfr[i] || this.arrayErroriImmobilMat[i] || this.arrayErroriImmobilImmat[i] || this.arrayErroriValoreMagaz[i] || this.arrayErroriAmmortamento[i];
      }
    }
    this.investimentiArray.updateValueAndValidity();
    this.disinvestimentiArray.updateValueAndValidity();
    this.investimentiForm.updateValueAndValidity()
    this.investimentiValid = this.investimentiForm.valid && this.presenzaErroriInv == false && this.presenzaErroriDis === false

    return this.investimentiValid
  }

  onSubmit() {
    if (this.submitted) {
      //eliminare
      /* unificare il volatile */
      let investimentiList: Investimenti[] = [];
      let disinvestimentiList: Disinvestimenti[] = [];
      for (let i = 0; i < this.investimentiFormArray.length; i++) {
        let idModalitaFin = this.investimentiFormArray[i].controls.modalitaFinanziamento.value ? this.investimentiFormArray[i].controls.modalitaFinanziamento.value.id : 0
        let lineeBusinessAssociate = [];
        let varRicavi = [];
        let varCostiMaterie = [];
        let varCostiServizi = [];
        let varCostiFissi = [];

        for(let j = 0; j < (<UntypedFormArray> this.investimentiFormArray[i].controls.effettiLdb).length; j++){
          const formLineaContr = (<UntypedFormGroup> (<UntypedFormArray> this.investimentiFormArray[i].controls.effettiLdb).controls[j]).controls;
          lineeBusinessAssociate.push(formLineaContr.idLdb.value);
          varRicavi.push(formLineaContr.varRicavi.value);
          varCostiMaterie.push(formLineaContr.varCostiMaterie.value);
          varCostiServizi.push(formLineaContr.varCostiServizi.value);4
          varCostiFissi.push(formLineaContr.varCostiFissi.value)

        }
        investimentiList.push({
          id: this.isRisultatiVolatile ? this.investimentiTempId[i] : 0,
          idTipoInvestimento: this.investimentiFormArray[i].controls.tipoInvestimento.value.id,
          descrizione: this.investimentiFormArray[i].controls.tipoInvestimento.value.id == 8 ? this.investimentiFormArray[i].controls.descrizioneInvestimento.value : '',
          importo: this.investimentiFormArray[i].controls.importoInvestimento.value != '' ? parseFloat(this.investimentiFormArray[i].controls.importoInvestimento.value) : 0,
          annoInvestimento: this.investimentiFormArray[i].controls.annoInvestimento.value,
          trimestreInvestimento: this.investimentiFormArray[i].controls.trimestreInvestimento.value.id,
          idModalitaFinanziamento: idModalitaFin,

          importoFinanziamento: idModalitaFin == 1 ||
            idModalitaFin == 5 || idModalitaFin == 6 ||
            idModalitaFin == 10 || idModalitaFin == 12 ||
            idModalitaFin == 13 || idModalitaFin == 14 ||
            idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoFinanziamento.value) : 0,
          mesiRimborsoFinanziamento: idModalitaFin == 1 ||
            idModalitaFin == 5 || idModalitaFin == 6 ||
            idModalitaFin == 10 || idModalitaFin == 12 ||
            idModalitaFin == 13 || idModalitaFin == 14 ||
            idModalitaFin == 15 ? this.investimentiFormArray[i].controls.mesiRimborsoFinanziamento.value : 0,
          preammortamentoFinanziamento: idModalitaFin == 1 ||
            idModalitaFin == 5 || idModalitaFin == 6 ||
            idModalitaFin == 10 || idModalitaFin == 12 ||
            idModalitaFin == 13 || idModalitaFin == 14 ||
            idModalitaFin == 15 ? this.investimentiFormArray[i].controls.preammortamentoFinanziamento.value : 0,
          importoGaranziaFinanziamento: idModalitaFin == 1 ||
            idModalitaFin == 5 || idModalitaFin == 6 ||
            idModalitaFin == 10 || idModalitaFin == 12 ||
            idModalitaFin == 13 || idModalitaFin == 14 ||
            idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoGaranziaFinanziamento.value) : 0,
          idDescrizioneGaranziaFinanziamento: idModalitaFin == 1 ||
            idModalitaFin == 5 || idModalitaFin == 6 ||
            idModalitaFin == 10 || idModalitaFin == 12 ||
            idModalitaFin == 13 || idModalitaFin == 14 ||
            idModalitaFin == 15 ? (this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.value ? this.investimentiFormArray[i].controls.descrizioneGaranziaFinanziamento.value.id : 0) : 0,

          importoNuovoInvestitore: idModalitaFin == 4 ||
            idModalitaFin == 8 || idModalitaFin == 9 ||
            idModalitaFin == 11 || idModalitaFin == 12 ||
            idModalitaFin == 13 || idModalitaFin == 14 ||
            idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoNuovoInvestitore.value) : 0,
          importoMezziPropri: idModalitaFin == 3 ||
            idModalitaFin == 6 || idModalitaFin == 7 ||
            idModalitaFin == 9 || idModalitaFin == 10 ||
            idModalitaFin == 11 || idModalitaFin == 14 ||
            idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoMezziPropri.value) : 0,
          importoLiquiditaCassa: idModalitaFin == 2 ||
            idModalitaFin == 5 || idModalitaFin == 7 ||
            idModalitaFin == 8 || idModalitaFin == 10 ||
            idModalitaFin == 11 || idModalitaFin == 13 ||
            idModalitaFin == 15 ? parseFloat(this.investimentiFormArray[i].controls.importoLiquiditaCassa.value) : 0,
lineeBusinessAssociate: lineeBusinessAssociate,
annoBeneficio: lineeBusinessAssociate.length > 0 ? this.investimentiFormArray[i].controls.annoBeneficio.value : 0,
trimestreBeneficio: lineeBusinessAssociate.length > 0 ? this.investimentiFormArray[i].controls.trimestreBeneficio.value.id : 0,
varRicavi: varRicavi,
varCostiMaterie: varCostiMaterie,
varCostiServizi: varCostiServizi,
varCostiFissi: varCostiFissi
        })
      }
      for (let i = 0; i < this.disinvestimentiFormArray.length; i++) {
        disinvestimentiList.push({
          id: this.disinvestimentiTempId[i],
          idTipoDisinvestimento: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id,
          importo: this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoDisinvestimento.value) : 0,
          descrizione: this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.value ? this.disinvestimentiFormArray[i].controls.descrizioneDisinvestimento.value : '',
          annoDisinvestimento: this.disinvestimentiFormArray[i].controls.annoDisinvestimento.value,
          trimestreDisinvestimento: this.disinvestimentiFormArray[i].controls.trimestreDisinvestimento.value.id,

          importoCostiRetribuzioniPersonale: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiRetribuzioniPersonale.value) : 0,
          importoCostiTfrPersonale: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoCostiTfrPersonale.value) : 0,
          importoDebitoFornitori: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoDebitoFornitori.value) : 0,
          importoImmobilizzazioniMateriali: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniMateriali.value) : 0,
          importoImmobilizzazioniImmateriali: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoImmobilizzazioniImmateriali.value) : 0,
          importoValoreMagazzino: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoValoreMagazzino.value) : 0,
          importoAmmortamento: this.disinvestimentiFormArray[i].controls.tipoDisinvestimento.value.id == 5 && this.disinvestimentiFormArray[i].controls.importoAmmortamento.value != '' ? parseFloat(this.disinvestimentiFormArray[i].controls.importoAmmortamento.value) : 0,

          rimborsoEquity: 0,
        })
      }
      let request: InvestimentiDisinvestimenti = {
        idBusinessPlan: this.businessPlanTemp.id,
        investimenti: investimentiList,
        disinvestimenti: disinvestimentiList,
      }
      this.markFormPristine()
      return request
    } else {
      return null
    }

  }

  riordinaArray(arr: any[]): any[] {
    arr.sort((a, b) => a.annoInvestimento - b.annoInvestimento)
    return arr;
  }
  ngOnDestroy() {
    if (this.risultatiVolatileSubscription != undefined) {
      this.risultatiVolatileSubscription.unsubscribe()
    }
    if (this.businessPlanTemp != null && this.businessPlanTemp.bilancioAbbreviato) {
      this.debitoFornitoriSubscription.unsubscribe()
    }
    if(this.inizioDurataSubscription != undefined){
      this.inizioDurataSubscription.unsubscribe();
    }
    this.tipsSubscription.unsubscribe()
  }

  getNomeLdb(id: number){
  let ldbFiltered = this.listaLdb.find(ldb => ldb.id == id);
  return ldbFiltered.descrizione;
  }

  onChangeLdb(index : number){
    for(let i = 0; i < this.listaLdb.length; i++){
      let presenteOld = (<UntypedFormGroup[]> (<UntypedFormArray> this.investimentiFormArray[index].controls.effettiLdb).controls).findIndex(elem => elem.controls.idLdb.value == this.listaLdb[i].id); 
      let presenteNew = this.investimentiFormArray[index].controls.ldbAssociate.value.findIndex(elem => elem == this.listaLdb[i].id);
      if(presenteOld != -1 && presenteNew == -1){
        (<UntypedFormArray> this.investimentiFormArray[index].controls.effettiLdb).removeAt(presenteOld);
      }else if(presenteOld == -1  && presenteNew != -1){
        (<UntypedFormArray> this.investimentiFormArray[index].controls.effettiLdb).insert(presenteNew, this.formBuilder.group({
          idLdb: [this.listaLdb[i].id],
          varRicavi: ['',Validators.required],
          varCostiMaterie: ['',Validators.required],
          varCostiServizi: ['',Validators.required],
          varCostiFissi: ['',Validators.required],
        }))
      }
      //al change confrontiamo gli id della vecchia lista con quelli della nuova
      //se è presente sulla nuova ma non sulla vecchia andrà aggiunto, viceversa eliminato
    }

    if(this.investimentiFormArray[index].controls.ldbAssociate.value.length > 0){
      this.investimentiFormArray[index].controls.annoBeneficio.setValidators(Validators.required);
      this.investimentiFormArray[index].controls.trimestreBeneficio.setValidators(Validators.required);
    }else{
      this.investimentiFormArray[index].controls.annoBeneficio.clearValidators();
      this.investimentiFormArray[index].controls.trimestreBeneficio.clearValidators();
    }
    this.investimentiFormArray[index].controls.annoBeneficio.updateValueAndValidity();
    this.investimentiFormArray[index].controls.trimestreBeneficio.updateValueAndValidity();
  }

}
