import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { ConfigurazioneAmbienteService } from '../_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from '../_services/configurazione-business-plan.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { PraticheNuova } from './../interface/Pratiche-nuova';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { DettaglioRisultatiService } from '../_services/dettaglio-risultati.service';
import { SplashpageServiceService } from '../_services/splashpage-service.service';
import { MenuPulsantieraService } from '../header/services/menu-pulsantiera.service';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { BeanStatico } from '../condivisi/beanStatico';
import { SmartBpService } from '../_services/smart-bp.service';
import { Richiedente } from '../interface/richiedente';

export interface SmartBpElement {
  idAziendaRichiedente: number,
  nomeAzienda: string,
  codiceAteco: string,
  azioni: string[]
}

export interface SmartBpList {
  rows: SmartBpElement[];
}

@Component({
  selector: 'app-lista-smart-bp',
  templateUrl: './lista-smart-bp.component.html',
  styleUrls: ['./lista-smart-bp.component.css']
})

export class ListaSmartBpComponent implements OnInit {

  displayedColumns: string[] = ['nomeAzienda', 'codiceAteco','azioni'];
  @ViewChild(MatSort) sort: MatSort;//per sortare la tabella
  @ViewChild(MatPaginator) paginator!: MatPaginator; /* definizione del paginator della tabella*/
  listaRichiedenti: any[] = [];
  listaSmartBp: SmartBpList = { rows: [] }; //serve solo per tab material
  dataSource: MatTableDataSource<SmartBpElement>;
  annoSmartBp = 2023;

  currentUser: any;
  constructor(
    //Vedere se serve service per passare i dati a risultati
    private tokenService: TokenStorageService,
    private router: Router,
    private modalService: NgbModal,
    private configurazioneBpService: ConfigurazioneBusinessPlanService,
    private smartBpService: SmartBpService,
    private serviceBp: ConfigurazioneBusinessPlanService,
    private dettService: DettaglioRisultatiService,
    private splashService: SplashpageServiceService, private pulsantieraService: MenuPulsantieraService
  ) {
    this.dataSource = new MatTableDataSource(this.listaSmartBp.rows);
  }


  ngOnInit(): void {

    this.currentUser = this.tokenService.getUser();
    this.configurazioneBpService.cancellaTutto();
    this.dettService.cancellaTutto();
    this.dettService.aggiornaCommento("");
    this.splashService.cancellaTutto();
    this.pulsantieraService.clickOnSintesi();
    this.smartBpService.cancellaTutto();
    this.listaSmartBp.rows = [];


      /* restituisce la lista di tutti i richiedenti per cui si può fare lo smart bp se ce ne sono */
      this.smartBpService.getListaRichiedenti(this.currentUser.idUtente).subscribe(response => {
        this.listaRichiedenti = response;
        if ( this.listaRichiedenti.length > 0) {
          this.listaRichiedenti.forEach((richiedente) => {
            let record: SmartBpElement = {
              idAziendaRichiedente: richiedente.id,
              nomeAzienda: richiedente.descrizione,
              codiceAteco: richiedente.descrizioneCompleta,
              azioni: ['Elabora']
            }
            this.listaSmartBp.rows.push(record)
            this.dataSource = new MatTableDataSource(this.listaSmartBp.rows);

            this.sort.sort(({ id: 'nomeAzienda', start: 'asc', disableClear: true }) as MatSortable); // sorting by nome azienda, comincia asc e non sparisce la
            this.dataSource.sort = this.sort
            this.paginator.pageSize = 10;
            this.dataSource.paginator = this.paginator;
          })
        } else {
          this.router.navigate(['/funzioni']);
        }
      })
  }

  visualizzaSmartBp(idRichiedente: number, nomeAzienda: string){
    //ho aggiunto l'anno come anno costituzione, non è corretto, è un workaround per non creare una nuova variabile
    let richiedente: Richiedente = {id: idRichiedente, ragioneSociale: nomeAzienda,annoCostituzione: this.annoSmartBp }
    //Serve per l'id richiedente e per distinguere smart bp da bp completo
this.smartBpService.inviaRichiedenteSmartBp(richiedente);
//Serve per il nome in alto
this.configurazioneBpService.inviaRichiedenteBp(richiedente);
this.serviceBp.isRisultatiVolatile(true);
this.router.navigate(['/preview']);

  }

  situazioneFinanziaria(idRichiedente: number, nomeAzienda: string){
    let richiedente: Richiedente = {id: idRichiedente, ragioneSociale: nomeAzienda}
    this.smartBpService.inviaRichiedenteSmartBp(richiedente);
    this.configurazioneBpService.inviaRichiedenteBp(richiedente);
    this.router.navigate(['/situazioneFinanziariaSmart']);
    
      }


}
