<div class="container-fluid">
    <table class="table table-striped table-sm">
        <thead>
            <tr class="border-top">
                <th scope="col">#</th>
                <th class="info align-middle" scope="col">partita Iva</th>
                <th class="info align-middle" scope="col">Azienda Osservata</th>
                <th class="info align-middle" scope="col">Stato
                </th>
                <th class="action align-middle" scope="col">Azioni &nbsp;&nbsp;<i id="icon"
                        class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                        [ngbTooltip]="listaTips[5]?.descrizione"></i></th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let richiedente of listaRichiedenti | paginate: {itemsPerPage: 5, currentPage: page}; let i=index">
                <!--  | filterBy: userFilter -->
                <td scope="row align-middle" class="
            align-middle">{{i+1}}</td>
                <td class="info align-middle">{{richiedente.partitaIva}}</td>
                <td class="info align-middle">{{richiedente.ragioneSociale}}</td>
                <td class="stato align-middle">{{richiedente.stato}}</td>
                <td class="action align-middle">
                    <button type="button" class="btn btn-primary"
                        (click)="modificaRichiedente(richiedente)">modifica</button>
                    <button type="button" class="btn btn-danger" [disabled]="bpIsPresent[richiedente.id]"
                        (click)="eliminaRichiedente(i,richiedente)">Elimina</button>
                </td>
            </tr>

        </tbody>
    </table>
    <!-- navigatore di pag per piu pratiche salvate -->

    <div *ngIf="listaRichiedenti.length > 5" class="no-margin d-flex justify-content-start">
        <pagination-controls nextLabel="Prossima" previousLabel="Precedente" (pageChange)="page=$event">
        </pagination-controls>
    </div>
</div>