import { Component, OnInit, Inject, AfterViewInit, AfterContentInit, AfterViewChecked } from '@angular/core';
import { User, Users, DialogData } from '../interfaces/user';
import { GestioneUtentiService } from '../_services/gestioneUtenti.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDialogFormComponent } from '../user-dialog-form/user-dialog-form.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Ruolo, Ruoli } from '../interfaces/ruoli';
import { GestionePivaDialogComponent } from '../gestione-piva-dialog/gestione-piva-dialog.component';
import { RippleState } from '@angular/material/core';
import { DefaultUrlSerializer } from '@angular/router';
import { TokenStorageService } from '../_services/token-storage.service';



/*

MANCA LA PARTE DI GESTIONE DELL'EMAIL E PASSWORD DELL'UTENTE IN FASE DI ISCRIZIONE // DA FARE 
MANCA L'ID DEL MASTER(cliente) CHE STA USANDO L'APP // OK 
MANCA LA DISATTIVAZIONE DELL'UTENTE DA PARTE DEL MASTER // OK 

*/


@Component({
  selector: 'app-lista-utenti',
  templateUrl: './lista-utenti.component.html',
  styleUrls: ['./lista-utenti.component.css']
})
export class ListaUtentiComponent implements OnInit {


  public Utente: User = { id: 0, username: '', email: '', jruolo: { id: 0, nome: '' }, idCapoArea: 0, Piva: '', idCliente: 0, isCa: false };
  public Utenti: Users = { rows: [] };

  public Role: Ruolo = { id: 0, nome: '' };
  public Roles: Ruoli = { rows: [] }


  nome!: string;
  email!: string;
  ruolo!: string;


  idut!: number;
  idclie!: number;
  idresp!: number;
  idRuo!: number;


  panelOpenState = false;
  start = true;
  errname = false;
  errmail = false


  MaxUt!: number;
  ActUt!: number;
  DispUt!: number;

  initialRole!: number;
  initialSuper!: number;


  UserModData!: UntypedFormGroup;
  UserName!: UntypedFormControl;
  UserEmail!: UntypedFormControl;
  UserRole!: UntypedFormControl;
  UserSuper!: UntypedFormControl;
  UserId!: UntypedFormControl;
  UserClient!: UntypedFormControl;

  Bind = { id: 0, idAziendaRichiedente: 0, idCliente: 0, idUtente: 0, idRuolo: 0 }

  get f() { return this.UserModData; }

  constructor(private http: HttpClient, private serviceUser: GestioneUtentiService, public dialog: MatDialog, private token: TokenStorageService) { }




  ngOnInit(): void {

    // Controllo nel database le utenze massime disponibili da contratto per il cliente.

    this.UserModData = new UntypedFormGroup({

      UserId: new UntypedFormControl('', Validators.required),
      UserName: new UntypedFormControl('', Validators.required),
      UserEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
      UserRole: new UntypedFormControl('', Validators.required),
      UserSuper: new UntypedFormControl(),
      UserClient: new UntypedFormControl('', Validators.required)

    });
    // prendo tutte le utenze relative ad un cliente
    this.serviceUser.takeAll(this.token.getUser().cliente.idCliente).subscribe(data => {
      this.Utenti.rows = []
      this.ActUt = 0;
      if (data != null) {
        this.ActUt = data.length;
        data.forEach(el => {
          if (el.jruolo.id == 3)
            el.isCa = true;
          this.Utenti.rows.push(el);
        });
        this.Utenti.rows.sort((n1, n2) => n1.id - n2.id)
      }
      this.serviceUser.UtenzeMassime(this.token.getUser().cliente.idCliente).subscribe(res => {
        this.MaxUt = res;
        this.DispUt = (this.MaxUt - this.ActUt)
      });

    });
    // prendo i ruoli da database per le select dei ruoli 
    this.serviceUser.takeRoles().subscribe(data => {
      this.Roles.rows = [];
      data.forEach(el => {
        this.Roles.rows.push(el);
      });

    });
  }// FINE OnInit




  // apertura della modale dell'inserimento utente 
  openDialog(): void {
    const dialogRef = this.dialog.open(UserDialogFormComponent, {
      width: '30vw',
      data: { username: this.nome, email: this.email, ruolo: this.ruolo, idclie: this.token.getUser().cliente.idCliente }
    });
    dialogRef.afterClosed().subscribe(() => {

      this.serviceUser.takeAll(this.token.getUser().cliente.idCliente).subscribe(data => {
        this.ActUt = 0;
        if (data != null) this.ActUt = data.length;
        this.serviceUser.updateUtenzeAttive(this.ActUt + 1, this.token.getUser().cliente.idCliente).subscribe(() => {
          this.ngOnInit();
        });
      });

    })
  }

  // qui apro la modale per la gestione della partita iva, passando i dati: id utente, id cliente , id ruolo , id responsabile area 
  openDialog2(idus: number, idcli: number, idra: number, idruo: number): void {
    const dialogRef2 = this.dialog.open(GestionePivaDialogComponent, {
      width: '80vw',
      height: '80vh',
      data: { idut: idus, idresp: idra, idclie: idcli, idRuo: idruo, username: this.f.controls['UserName'].value }
    });
  }

  // apertura del form di modifica dell'utente singolo 
  onOpenTab(idUtente: number) {

    this.serviceUser.takeById(idUtente).subscribe(ris => {
      this.initialRole = ris.jruolo.id;
      this.initialSuper = ris.idCapoArea;
      this.UserModData.controls['UserId'].setValue(ris.id)
      this.UserModData.controls['UserClient'].setValue(ris.idCliente)
      this.UserModData.controls['UserName'].setValue(ris.username);
      this.UserModData.controls['UserEmail'].setValue(ris.email);
      this.UserModData.controls['UserRole'].setValue(ris.jruolo.id);
      if (ris.idCapoArea == 0) {
        this.UserModData.controls['UserSuper'].setValue(0);
      } else {
        this.UserModData.controls['UserSuper'].setValue(ris.idCapoArea);
      }
    });
  }

  // inserimento della modifica dell'utente 
  submitMod(Mod: any, Id: number) {

    (document.querySelector('#mail') as HTMLElement).style.border = '1px solid #ccc';
    (document.querySelector('#mail') as HTMLElement).style.boxShadow = '1px 1px 0px 0px black';

    (document.querySelector('#nome') as HTMLElement).style.border = '1px solid #ccc';
    (document.querySelector('#nome') as HTMLElement).style.boxShadow = '1px 1px 0px 0px black';

    this.errmail = false;
    this.errname = false;

    // controllo che non ci sia nessun utente attivo che ha lo stesso nome
    this.serviceUser.takeByNomeUtente(Mod.controls['UserClient'].value, Mod.controls['UserName'].value).subscribe(result => {

      if (result == null || result.id == Id) {
        // stesso controllo per le email
        this.serviceUser.takeByEmailUtente(Mod.controls['UserClient'].value, Mod.controls['UserEmail'].value).subscribe(result2 => {
          if (result2 == null || result2.id == Id) {
            this.Utente.username = Mod.controls['UserName'].value;
            this.Utente.id = Id;
            this.Utente.email = Mod.controls['UserEmail'].value;
            this.Utente.jruolo.id = Mod.controls['UserRole'].value;
            this.Roles.rows.forEach(element => {
              if (Mod.controls['UserRole'].value == element.id) this.Utente.jruolo.nome = element.nome;
            });
            this.Utente.idCliente = this.UserModData.controls['UserClient'].value;
            this.Utente.idCapoArea = Mod.controls['UserSuper'].value;
            if (this.UserModData.controls['UserRole'].value == 3) this.Utente.idCapoArea = 0;

            this.serviceUser.modifyUser(this.Utente).subscribe(() => {

              if (this.Utente.jruolo.id != this.initialRole) this.serviceUser.updateUserBind(this.Utente).subscribe();

              this.ngOnInit();
            });

            (document.querySelector('#mail') as HTMLElement).style.boxShadow = '1px 1px 0px 0px black';
          } else {

            this.errmail = true;
            (document.querySelector('#mail') as HTMLElement).style.border = '1px solid red';
          }
        });
      } else {
        this.errname = true;
        (document.querySelector('#nome') as HTMLElement).style.border = '1px solid red';
      }
    });
  }


  // disattivazione utente 
  cancUser(Ute: User) {

    this.serviceUser.disattivaUtente(Ute).subscribe(() => {
      this.serviceUser.deleteAllUserBind(Ute).subscribe(() => {

        this.serviceUser.takeAll(this.token.getUser().cliente.idCliente).subscribe(data => {
          this.ActUt = 0;
          if (data != null) this.ActUt = data.length;
          this.serviceUser.updateUtenzeAttive(this.ActUt + 1, this.token.getUser().cliente.idCliente).subscribe(() => { this.ngOnInit(); });
        });
      });



    });

  }



}


