import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'qs-energia-gas',
  template: `
   <div class="container-fluid" style="margin-top: 20px">
    <div class="loading-container"  *ngIf="isLoading">
      <div class="spinner-border text-primary" style="width: 50px; height: 50px" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <form [formGroup]="formEnergiaGas" (ngSubmit)="onSubmit()">
      <div class="col-12 nopadding">
        <div class="card nopadding">
          <div class="card-header text-center font-big">Modello Energia Gas</div>
          <div class="card-body">
            <div class="form-row">

              <div class="col-4 col-lg-4 form-group">
                <label>Drift energia (%)</label>
                <input required type="number" formControlName="driftEnergia" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.driftEnergia.errors }" />
                <div *ngIf="submitted && f.driftEnergia.errors" class="invalid-feedback">
                  <div *ngIf="f.driftEnergia.errors.required">Campo obbligatorio</div>
                </div>
              </div>

              <div class="col-4 col-lg-4 form-group">
                <label>Drift gas (%)</label>
                <input required type="number" formControlName="driftGas" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.driftGas.errors }" />
                <div *ngIf="submitted && f.driftGas.errors" class="invalid-feedback">
                  <div *ngIf="f.driftGas.errors.required">Campo obbligatorio</div>
                </div>
              </div>
            </div>
            
            <div class="form-row">

              <div class="col-4 col-lg-4 form-group">
                <label>Mean reversion energia (%)</label>
                <input required type="number" formControlName="meanRevEnergia" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.meanRevEnergia.errors }" />
                <div *ngIf="submitted && f.meanRevEnergia.errors" class="invalid-feedback">
                  <div *ngIf="f.meanRevEnergia.errors.required">Campo obbligatorio</div>
                </div>
              </div>

              <div class="col-4 col-lg-4 form-group">
              <label>Mean reversion gas</label>
                <input required type="number" formControlName="meanRevGas" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.meanRevGas.errors }" />
                <div *ngIf="submitted && f.meanRevGas.errors" class="invalid-feedback">
                  <div *ngIf="f.meanRevGas.errors.required">Campo obbligatorio</div>
                </div>
              </div>


            </div>

            <div class="form-row">
            <div class="col-12 font-bold">Correlazione calcolata: {{correlazioneCalcolata | number: '1.0-3'}}</div>


              <div class="col-5 col-lg-4 form-group">
                <label>Correlazione</label>
                <input required type="number" formControlName="correlazione" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.correlazione.errors }" />
                <div *ngIf="submitted && f.correlazione.errors" class="invalid-feedback">
                  <div *ngIf="f.correlazione.errors.required">Campo obbligatorio</div>
                </div>
              </div>

            </div>

            <div class="form-row">

              <div class="col-5 col-lg-4 form-group">
                <label>Percentuale opzioni</label>
                <input required  min="0" type="number" formControlName="percOptions" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.percOptions.errors }" />
                <div *ngIf="submitted && f.percOptions.errors" class="invalid-feedback">
                  <div *ngIf="f.percOptions.errors.required">Campo obbligatorio</div>
                </div>
              </div>

              <div class="col-5 col-lg-4 form-group">
                <label>Percentuale futures</label>
                <input type="number" min="0" formControlName="percFutures" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.percFutures.errors }" />
                <div *ngIf="submitted && f.percFutures.errors" class="invalid-feedback">
                  <div *ngIf="f.percFutures.errors.required">Campo obbligatorio</div>
                </div>
              </div>

            </div>

            <div class="form-row">

              <div class="col-5 col-lg-4 form-group">
                <label>Percentuale di finanziamento</label>
                <input required  min="0" type="number" formControlName="percFinanziamento" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.percFinanziamento.errors }" />
                <div *ngIf="submitted && f.percFinanziamento.errors" class="invalid-feedback">
                  <div *ngIf="f.percFinanziamento.errors.required">Campo obbligatorio</div>
                </div>
              </div>

              <div class="col-5 col-lg-4 form-group">
                <label>Costo del capitale - tasso equity</label>
                <input type="number" min="0" formControlName="tassoEquity" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tassoEquity.errors }" />
                <div *ngIf="submitted && f.tassoEquity.errors" class="invalid-feedback">
                  <div *ngIf="f.tassoEquity.errors.required">Campo obbligatorio</div>
                </div>
              </div>

            </div>

          </div>
        </div>

        
        <div class="col-12 text-center" style="padding-top:20px;padding-bottom:20px;">
          <button class="btn btn-primary mr-1">Calcola</button>
          <button class="btn btn-secondary" type="button" (click)="onReset()">Annulla</button>
        </div>

      </div>
    </form>

    <div class="col-12 padding-top-bottom-10 padding-left-right-15 pointer borderAll text-center font-big margin-top-20" *ngIf="boolRisultati" (click)="boolParametri = !boolParametri" [ngStyle]="{'background-color': coloreProgetto}">
              Parametri della simulazione
              <span class="pull-right pointer" style="margin-top: -15px" ><fa-icon style="font-size: 45px" [icon]="boolParametri? faCaretUp : faCaretDown"></fa-icon></span>
            </div>
            <div class="col-12 padding-top-bottom-10 borderR borderL borderB" style="background-color: white" *ngIf="boolParametri">
              <div class="row margin-0">
                <div class="col-12">
                </div>

                <div class="col-4">
                  <div class="row margin-0 text-center">

                    <div class="col-4 borderRBlack borderBBlack min-height-25 margin-top-10 font-bold"></div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Energia</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Gas</div>
                    <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Prezzo iniziale</div>
                    <div class="col-4 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.prezzoIn[0] | number: '1.0-4':'it'}}</div>
                    <div class="col-4 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.prezzoIn[1] | number: '1.0-4':'it'}}</div>
                    <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Volatilit&agrave;</div>
                    <div class="col-4 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-4 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Drift</div>
                    <div class="col-4 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-4 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}} %</div>

                    <div class="col-4 borderTBlack borderLBlack borderRBlack borderBBlack min-height-25 font-bold margin-top-20 flex-justify-center">Correlazione</div>
                    <div class="col-4 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">{{risultatiEnergiaGas.correlazione | number: '1.0-3':'it'}}</div>
                    <div class="col-4 min-height-25 margin-top-20"></div>

                    <div class="col-3 borderTBlack borderLBlack borderRBlack borderBBlack min-height-25 font-bold margin-top-20 flex-justify-center">Mean Reversion Energia</div>
                    <div class="col-3 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">{{risultatiEnergiaGas.arrayRevEner[0]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-3 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">{{risultatiEnergiaGas.arrayRevEner[1]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-3 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">{{risultatiEnergiaGas.arrayRevEner[2]*100 | number: '1.0-2':'it'}} %</div>
                  
                    <div class="col-3 borderTBlack borderLBlack borderRBlack borderBBlack min-height-25 font-bold margin-top-20 flex-justify-center">Mean Reversion Gas</div>
                    <div class="col-3 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">{{risultatiEnergiaGas.arrayRevGas[0]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-3 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">{{risultatiEnergiaGas.arrayRevGas[1]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-3 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">{{risultatiEnergiaGas.arrayRevGas[2]*100 | number: '1.0-2':'it'}} %</div>
                  
                  </div>

                </div>

                <div class="col-8">
                  <div class="col-12 font-bold text-center">Percentili prezzi energia simulati</div>

                  <div class="row margin-0 margin-top-10 text-center">

                    <div class="tabella8 borderAllBlack min-height-25 margin-top-10 flex-justify-center">Anno</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{1}}</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{2.5}}</div>
                    <div class="tabella8 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{5}}</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{7.5}}</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{10}}</div>
                    <div class="tabella8 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{12.5}}</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{15}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{99}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[0][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[0][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[0][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[0][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[0][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[0][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[0][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{90}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[1][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[1][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[1][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[1][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[1][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[1][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[1][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{80}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[2][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[2][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[2][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[2][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[2][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[2][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[2][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{70}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[3][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[3][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[3][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[3][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[3][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[3][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[3][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{60}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[4][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[4][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[4][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[4][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[4][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[4][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[4][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{50}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[5][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[5][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[5][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[5][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[5][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[5][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[5][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{40}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[6][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[6][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[6][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[6][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[6][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[6][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[6][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{30}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[7][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[7][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[7][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[7][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[7][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[7][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[7][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{20}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[8][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[8][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[8][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[8][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[8][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[8][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[8][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{10}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[9][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[9][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[9][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[9][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[9][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[9][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[9][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{1}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[10][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[10][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[10][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[10][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[10][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[10][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percEnergia[10][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">Media</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrEner[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrEner[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrEner[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrEner[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrEner[4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrEner[5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrEner[6] | number: '1.0-4':'it'}}</div>

                    

                  </div>

                  <div class="col-12 font-bold text-center margin-top-20">Percentili prezzi gas simulati</div>

                  <div class="row margin-0 margin-top-10 text-center">

                    <div class="tabella8 borderAllBlack min-height-25 margin-top-10 flex-justify-center">Anno</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{1}}</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{2.5}}</div>
                    <div class="tabella8 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{5}}</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{7.5}}</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{10}}</div>
                    <div class="tabella8 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{12.5}}</div>
                    <div class="tabella8 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 flex-justify-center">{{15}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{99}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[0][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[0][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[0][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[0][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[0][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[0][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[0][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{90}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[1][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[1][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[1][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[1][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[1][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[1][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[1][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{80}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[2][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[2][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[2][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[2][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[2][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[2][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[2][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{70}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[3][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[3][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[3][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[3][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[3][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[3][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[3][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{60}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[4][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[4][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[4][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[4][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[4][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[4][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[4][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{50}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[5][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[5][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[5][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[5][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[5][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[5][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[5][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{40}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[6][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[6][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[6][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[6][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[6][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[6][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[6][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{30}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[7][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[7][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[7][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[7][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[7][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[7][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[7][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{20}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[8][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[8][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[8][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[8][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[8][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[8][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[8][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{10}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[9][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[9][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[9][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[9][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[9][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[9][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[9][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">{{1}}%</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[10][0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[10][1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[10][2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[10][3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[10][4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[10][5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percGas[10][6] | number: '1.0-4':'it'}}</div>

                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">Media</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrGas[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrGas[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrGas[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrGas[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrGas[4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrGas[5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.medPrGas[6] | number: '1.0-4':'it'}}</div>

                    

                  </div>

                </div>

              </div>
            </div>


    <div class="col-12 padding-top-bottom-10 padding-left-right-15 pointer borderAll text-center font-big margin-top-20" *ngIf="boolRisultati" (click)="boolIndicatori = !boolIndicatori" [ngStyle]="{'background-color': coloreProgetto}">
      Indicatori economici-finanziari
      <span class="pull-right pointer" style="margin-top: -15px" ><fa-icon style="font-size: 45px" [icon]="boolIndicatori? faCaretUp : faCaretDown"></fa-icon></span>
    </div>
            <div class="col-12 padding-top-bottom-10 borderR borderL borderB" style="background-color: white" *ngIf="boolIndicatori">
              <div class="row margin-0">
                <div class="col-12 margin-bottom-20">
                <div class="row margin-0">

                  <div class="col-3">
                    <label>Indicatore</label>
                    <select id="indicatore" [(ngModel)]="indicatoreEconometrico" (change)="onChangeIndicatore()" class="form-control">
                      <option [ngValue]="'IRR Equity'" selected>IRR Equity</option>
                      <option [ngValue]="'IRR Progetto'">IRR Progetto</option>
                      <option [ngValue]="'Break even'">Anno di break even</option>
                      <option [ngValue]="'Valore compagnia'">Somma surplus cash flow equity</option>
                      <option [ngValue]="'Dscr'">DSCR</option>
                    </select>
                  </div>

                  <div class="col-3 text-center" *ngIf="indicatoreEconometrico == 'Valore compagnia'">
                    <span class="font-bold">Tasso equity:</span>
                    <br/>
                    {{risultatiEnergiaGas.tassoEquity}} %
                  </div>
                  </div>
                </div>


                <div class="col-6">
                <div class="col-12 font-bold text-center">Con derivati di copertura</div>

                  <div class="row margin-0 text-center">
                    <div class="col-4 borderAllBlack min-height-25 font-bold" *ngIf="indicatoreEconometrico != 'Break even'">Range</div>
                    <div class="col-4 borderAllBlack min-height-25 font-bold" *ngIf="indicatoreEconometrico == 'Break even'">Anno</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Frequenza</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Probabilit&agrave;</div>
                    <div class="col-12 nopadding">
                      <div class="row margin-0 text-center" *ngFor="let item of frequenze; let i = index">
                        <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{range[i]}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{frequenze[i]}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{(frequenze[i]/1000)*100 | number: '1.0-2':'it'}} %</div>
                      </div>
                    </div>
                    <div class="col-4 borderAllBlack min-height-25 margin-top-10 font-bold">Minimo</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Massimo</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Media</div>

                    <div class="col-12 nopadding" *ngIf="indicatoreEconometrico == 'IRR Equity' || indicatoreEconometrico == 'IRR Progetto' || indicatoreEconometrico == 'Dscr'">
                      <div class="row margin-0">
                        <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatore >= 0 ? (minimoIndicatore | number: '1.0-2':'it') : 'Negativo'}} {{minimoIndicatore >=0 ? '%' : ''}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatore >= 0 ? (massimoIndicatore | number: '1.0-2':'it') : 'Negativo'}} {{massimoIndicatore >=0 ? '%' : ''}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatore >=0 ? (mediaIndicatore | number: '1.0-2':'it') : 'Negativo'}} {{mediaIndicatore >=0 ? '%' : ''}}</div>
                      </div>
                    </div>

                    <div class="col-12 nopadding" *ngIf="indicatoreEconometrico == 'Break even'">
                      <div class="row margin-0">
                        <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatore >= 0 ? (minimoIndicatore | number: '1.0-0':'it') : 'Negativo'}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatore >= 0 ? (massimoIndicatore | number: '1.0-0':'it') : 'Negativo'}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatore >=0 ? (mediaIndicatore | number: '1.0-0':'it') : 'Negativo'}}</div>
                      </div>
                    </div>

                    <div class="col-12 nopadding" *ngIf="indicatoreEconometrico == 'Valore compagnia'">
                      <div class="row margin-0">
                        <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatore | number: '1.0-0':'it'}} €</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatore | number: '1.0-0':'it'}} €</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatore | number: '1.0-0':'it'}} €</div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-6">
                <div class="col-12 font-bold text-center">Senza derivati di copertura</div>

                  <div class="row margin-0 text-center">
                    <div class="col-4 borderAllBlack min-height-25 font-bold" *ngIf="indicatoreEconometrico != 'Break even'">Range</div>
                    <div class="col-4 borderAllBlack min-height-25 font-bold" *ngIf="indicatoreEconometrico == 'Break even'">Anno</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Frequenza</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Probabilit&agrave;</div>
                    <div class="col-12 nopadding">
                      <div class="row margin-0 text-center" *ngFor="let item of frequenze; let i = index">
                        <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{range[i]}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{frequenzeNoOpts[i]}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{(frequenzeNoOpts[i]/1000)*100 | number: '1.0-2':'it'}} %</div>
                      </div>
                    </div>
                    <div class="col-4 borderAllBlack min-height-25 margin-top-10 font-bold">Minimo</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Massimo</div>
                    <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Media</div>

                    <div class="col-12 nopadding" *ngIf="indicatoreEconometrico == 'IRR Equity' || indicatoreEconometrico == 'IRR Progetto' || indicatoreEconometrico == 'Dscr'">
                      <div class="row margin-0">
                        <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatoreNoOpts >= 0 ? (minimoIndicatoreNoOpts | number: '1.0-2':'it') : 'Negativo'}} {{minimoIndicatoreNoOpts >=0 ? '%' : ''}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatoreNoOpts >= 0 ? (massimoIndicatoreNoOpts | number: '1.0-2':'it') : 'Negativo'}} {{massimoIndicatoreNoOpts >=0 ? '%' : ''}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatoreNoOpts >=0 ? (mediaIndicatoreNoOpts | number: '1.0-2':'it') : 'Negativo'}} {{mediaIndicatoreNoOpts >=0 ? '%' : ''}}</div>
                      </div>
                    </div>

                    <div class="col-12 nopadding" *ngIf="indicatoreEconometrico == 'Break even'">
                      <div class="row margin-0">
                        <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatoreNoOpts >= 0 ? (minimoIndicatoreNoOpts | number: '1.0-0':'it') : 'Negativo'}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatoreNoOpts >= 0 ? (massimoIndicatoreNoOpts | number: '1.0-0':'it') : 'Negativo'}}</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatoreNoOpts >=0 ? (mediaIndicatoreNoOpts | number: '1.0-0':'it') : 'Negativo'}}</div>
                      </div>
                    </div>

                    <div class="col-12 nopadding" *ngIf="indicatoreEconometrico == 'Valore compagnia'">
                      <div class="row margin-0">
                        <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatoreNoOpts | number: '1.0-0':'it'}} €</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatoreNoOpts | number: '1.0-0':'it'}} €</div>
                        <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatoreNoOpts | number: '1.0-0':'it'}} €</div>
                      </div>
                    </div>


                  </div>

                </div>

              </div>
            </div>

            <div class="col-12 padding-top-bottom-10 padding-left-right-15 pointer borderAll text-center font-big margin-top-20" *ngIf="boolRisultati" (click)="boolBP = !boolBP" [ngStyle]="{'background-color': coloreProgetto}">
              Business Plan Medio
              <span class="pull-right pointer" style="margin-top: -15px" ><fa-icon style="font-size: 45px" [icon]="boolBP? faCaretUp : faCaretDown"></fa-icon></span>
            </div>
            <div class="col-12 padding-top-bottom-10 borderR borderL borderB" style="background-color: white" *ngIf="boolBP">
              <div class="row margin-0">
                <div class="col-12">

                  <div class="row margin-0 text-center">

                    <div class="col-3 borderAllBlack min-height-25 margin-top-10 font-bold flex-justify-center"> Anni</div>
                    <div class="col-3 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold flex-justify-center">{{'0 - 5'}}</div>
                    <div class="col-3 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold flex-justify-center">{{'5 - 10'}}</div>
                    <div class="col-3 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-10 font-bold flex-justify-center">{{'10 - 15'}}</div>

                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Prezzo Energia</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[0][0] | number: '1.0-4':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[0][1] | number: '1.0-4':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[0][2] | number: '1.0-4':'it'}}</div>

                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Quantit&agrave; venduta</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[1][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[1][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[1][2] | number: '1.0-0':'it'}}</div>

                    
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Ricavi dalle vendite</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[2][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[2][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[2][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Prezzo Gas</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[3][0] | number: '1.0-4':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[3][1] | number: '1.0-4':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[3][2] | number: '1.0-4':'it'}}</div>

                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Quantit&agrave; consumata</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[4][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[4][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[4][2] | number: '1.0-0':'it'}}</div>
                    
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Costo del gas</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[5][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[5][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[5][2] | number: '1.0-0':'it'}}</div>
                      
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Costi mantenimento</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[6][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[6][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[6][2] | number: '1.0-0':'it'}}</div>
                      
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Costi del personale, amministrativi e legali</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[7][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[7][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[7][2] | number: '1.0-0':'it'}}</div>
                      
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Costi di assicurazione e sicurezza</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[8][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[8][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[8][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Costi di rete</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[9][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[9][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[9][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Royalties annuali</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[10][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[10][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[10][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">EBITDA</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[11][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[11][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[11][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Ammortamento</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[12][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[12][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[12][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">EBIT</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[13][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[13][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[13][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Commissioni di brokerage</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[14][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[14][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[14][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Costo del denaro</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[15][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[15][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[15][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Tasse</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[16][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[16][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[16][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Reddito netto</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[17][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[17][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[17][2] | number: '1.0-0':'it'}}</div>
                    
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Costo dell'energia per Kwh</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[18][0] | number: '1.0-4':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[18][1] | number: '1.0-4':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bpMedio[18][2] | number: '1.0-4':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Ripagamento debito</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[1][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[1][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[1][2] | number: '1.0-0':'it'}}</div>
  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Cash flow (Reddito netto + ammortamento)</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[2][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[2][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[2][2] | number: '1.0-0':'it'}}</div>

                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Cash flow (al netto del ripagamento debito)</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[3][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[3][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[3][2] | number: '1.0-0':'it'}}</div>

                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Dividendo</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[4][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[4][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[4][2] | number: '1.0-0':'it'}}</div>

                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Cash stock</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[5][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[5][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[5][2] | number: '1.0-0':'it'}}</div>
                 
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Cash flow equity</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[7][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[7][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[7][2] | number: '1.0-0':'it'}}</div>
                 
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Equity iniziale</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[8][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                 
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">IRR Equity</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[9][0]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                 
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Cash flow progetto</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[10][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[10][1] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[10][2] | number: '1.0-0':'it'}}</div>
                 
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Capex iniziale</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[11][0] | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                 
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">IRR Progetto</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.cashFlowMedio[12][0]*100 | number: '1.0-2':'it'}} %</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                  
                    <div class="col-3 borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">Debito iniziale</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center">{{risultatiEnergiaGas.bankingFinance | number: '1.0-0':'it'}}</div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col-3 borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                  </div>

                </div>

                

              </div>
            </div>

            <div class="col-12 padding-top-bottom-10 padding-left-right-15 pointer borderAll text-center font-big margin-top-20" *ngIf="boolRisultati" (click)="boolOptions = !boolOptions" [ngStyle]="{'background-color': coloreProgetto}">
              Options & Futures
              <span class="pull-right pointer" style="margin-top: -15px" ><fa-icon style="font-size: 45px" [icon]="boolOptions? faCaretUp : faCaretDown"></fa-icon></span>
            </div>
            <div class="col-12 padding-top-bottom-10 borderR borderL borderB" style="background-color: white" *ngIf="boolOptions">
              <div class="row margin-0">
                <div class="col-12">

                <div class="col-12 font-bold text-center ">Options energia</div>

                  <div class="row margin-0 text-center">
                    <div class="tabella10 borderAllBlack min-height-25 margin-top-20 flex-justify-center">Buy/Sell</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Call/Put</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">% della produzione</div>
                    <div class="tabella10 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Quantit&agrave;</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Maturity</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Spot</div>
                    <div class="tabella10 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Strike</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Volatilit&agrave;</div>
                    <div class="tabella10 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Drift</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Prezzo</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">PUT</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountEnergia[0] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[0] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeEnergia[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergia[0] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">PUT</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountEnergia[1] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[1] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeEnergia[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergia[1] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">PUT</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountEnergia[2] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[2] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeEnergia[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergia[2] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">PUT</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountEnergia[3] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[3] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeEnergia[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergia[3] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">PUT</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountEnergia[4] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[4] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeEnergia[4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergia[4] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">PUT</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountEnergia[5] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[5] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeEnergia[5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergia[5] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">PUT</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountEnergia[6] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[6] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[6] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeEnergia[6] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergia[6] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">PUT</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountEnergia[7] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[7] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[7] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeEnergia[7] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergia[7] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">TOTALE</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceEnergiaTot | number: '1.0-0':'it'}}</div>

                  </div>

                  <div class="col-12 font-bold text-center margin-top-20">Futures energia</div>


                  <div class="row margin-0 text-center">

                    <div class="margin-top-20 tabella8 borderLBlack borderTBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">Buy/Sell</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Future</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">% della produzione</div>
                    <div class="margin-top-20 tabella8  borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Quantit&agrave;</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Maturity</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Spot</div>
                    <div class="margin-top-20 tabella8  borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Drift</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">FWD</div>
                  
                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">FUTURE</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percFutures | number: '1.0-0':'it'}} %</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountFuturesEnergia[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturityFutures[0] | number: '1.0-0':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-4':'it'}} %</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.fwdEnergia[0] | number: '1.0-4':'it'}}</div>
                  
                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">FUTURE</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percFutures | number: '1.0-0':'it'}} %</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountFuturesEnergia[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturityFutures[1] | number: '1.0-0':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-4':'it'}} %</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.fwdEnergia[1] | number: '1.0-4':'it'}}</div>
                  
                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">FUTURE</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percFutures | number: '1.0-0':'it'}} %</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountFuturesEnergia[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturityFutures[2] | number: '1.0-0':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-4':'it'}} %</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.fwdEnergia[2] | number: '1.0-4':'it'}}</div>
                  
                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">FUTURE</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percFutures | number: '1.0-0':'it'}} %</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountFuturesEnergia[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturityFutures[3] | number: '1.0-0':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotEnergia[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[0]*100 | number: '1.0-4':'it'}} %</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.fwdEnergia[3] | number: '1.0-4':'it'}}</div>
                  
                  </div>

                  <div class="col-12 font-bold text-center margin-top-20">Options gas</div>

                  <div class="row margin-0 text-center">
                    <div class="tabella10 borderAllBlack min-height-25 margin-top-20 flex-justify-center">Buy/Sell</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Call/Put</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">% della produzione</div>
                    <div class="tabella10 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Quantit&agrave;</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Maturity</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Spot</div>
                    <div class="tabella10 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Strike</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Volatilit&agrave;</div>
                    <div class="tabella10 borderTBlack borderRBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Drift</div>
                    <div class="tabella10 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-20 flex-justify-center">Prezzo</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">CALL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountGas[0] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[0] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeGas[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGas[0] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">CALL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountGas[1] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[1] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeGas[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGas[1] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">CALL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountGas[2] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[2] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeGas[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGas[2] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">CALL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountGas[3] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[3] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeGas[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGas[3] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">CALL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountGas[4] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[4] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeGas[4] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGas[4] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">CALL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountGas[5] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[5] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeGas[5] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGas[5] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">CALL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountGas[6] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[6] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[6] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeGas[6] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGas[6] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">SELL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">CALL</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percOptions | number: '1.0-4':'it'}} %</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountGas[7] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturity[7] | number: '1.0-0':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[7] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.strikeGas[7] | number: '1.0-4':'it'}}</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.volatilita[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-2':'it'}}%</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGas[7] | number: '1.0-0':'it'}}</div>

                    <div class="tabella10 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">TOTALE</div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10  borderRBlack borderBBlack min-height-25  flex-justify-center"></div>
                    <div class="tabella10 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.priceGasTot | number: '1.0-0':'it'}}</div>

                  </div>

                  <div class="col-12 font-bold text-center margin-top-20">Futures gas</div>


                  <div class="row margin-0 text-center ">

                    <div class="margin-top-20 tabella8 borderLBlack borderTBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">Buy/Sell</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Future</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">% della produzione</div>
                    <div class="margin-top-20 tabella8  borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Quantit&agrave;</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Maturity</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Spot</div>
                    <div class="margin-top-20 tabella8  borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">Drift</div>
                    <div class="margin-top-20 tabella8 borderRBlack borderTBlack borderBBlack min-height-25  flex-justify-center">FWD</div>
                  
                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">FUTURE</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percFutures | number: '1.0-0':'it'}} %</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountFuturesGas[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturityFutures[0] | number: '1.0-0':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[0] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-4':'it'}} %</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.fwdGas[0] | number: '1.0-4':'it'}}</div>
                  
                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">FUTURE</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percFutures | number: '1.0-0':'it'}} %</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountFuturesGas[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturityFutures[1] | number: '1.0-0':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[1] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-4':'it'}} %</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.fwdGas[1] | number: '1.0-4':'it'}}</div>
                  
                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">FUTURE</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percFutures | number: '1.0-0':'it'}} %</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountFuturesGas[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturityFutures[2] | number: '1.0-0':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[2] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-4':'it'}} %</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.fwdGas[2] | number: '1.0-4':'it'}}</div>
                  
                    <div class="tabella8 borderLBlack borderRBlack  borderBBlack min-height-25  flex-justify-center">BUY</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">FUTURE</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.percFutures | number: '1.0-0':'it'}} %</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.amountFuturesGas[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.maturityFutures[3] | number: '1.0-0':'it'}}</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.spotGas[3] | number: '1.0-4':'it'}}</div>
                    <div class="tabella8  borderRBlack borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.drift[1]*100 | number: '1.0-4':'it'}} %</div>
                    <div class="tabella8 borderRBlack  borderBBlack min-height-25  flex-justify-center">{{risultatiEnergiaGas.fwdGas[3] | number: '1.0-4':'it'}}</div>
                  
                  </div>

                    
                </div>

                

              </div>
            </div>

  </div>
  `,
  styles: [`
    .tabella8 {
      flex: 0 0 12.5%;
      max-width: 12.5%;
  }

  .tabella10 {
      flex: 0 0 10%;
      max-width: 10%;
  }
  `
  ]
})
export class EnergiaGasComponent implements OnInit {

  coloreProgetto = '#92a8d1';
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  formEnergiaGas: UntypedFormGroup;
  initialValues: any;
  isLoading: boolean = false;
  submitted = false;
  correlazioneCalcolata = 0;
  request:any = {};
  risultatiEnergiaGas:any = {};
  boolIndicatori = false;
  boolRisultati = false;
  boolParametri = false;
  boolBP = false;
  boolOptions = false;
  indicatoreEconometrico = 'IRR Equity';
  frequenze = [0];
  minimoIndicatore = 0;
  massimoIndicatore = 0;
  mediaIndicatore = 0;
  frequenzeNoOpts = [0];
  minimoIndicatoreNoOpts = 0;
  massimoIndicatoreNoOpts = 0;
  mediaIndicatoreNoOpts = 0;
  range = ['<=0%','0%-5%','5%-10%','10%-15%','15%-20%','20%-25%','25%-30%','30%-35%','35%-40%','40%-45%','45%-50%','>50%'];

  constructor(private formBuilder: UntypedFormBuilder, private httpClient: HttpClient, private router: Router, private loading: LoadingService) {
    this.formEnergiaGas = this.formBuilder.group({
        driftEnergia: ['', Validators.required],
        driftGas: ['', Validators.required],
        meanRevEnergia: ['', Validators.required],
        meanRevGas: ['', Validators.required],
        percOptions: ['', Validators.required],
        percFutures: ['', Validators.required],
        correlazione: ['', Validators.required],
        percFinanziamento: [80, Validators.required],
        tassoEquity: [12, Validators.required],
        
        
    })
   }


  ngOnInit(): void {
    
    this.listenToLoading();
    this.initialValues = this.formEnergiaGas.value;
    this.httpClient.get<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getCorrelazione').subscribe(data => {
      this.correlazioneCalcolata = data;
    });
  }

  get f() { return this.formEnergiaGas.controls; }

        /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
         listenToLoading(): void {
          this.loading.loadingSub
            .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
            .subscribe((loading) => {
              this.isLoading = loading;
            });
        }


        onSubmit() {
          this.submitted = true;
          if (this.formEnergiaGas.valid) {
            this.request = {
              driftEnergia: this.formEnergiaGas.controls.driftEnergia.value,
              driftGas: this.formEnergiaGas.controls.driftGas.value,
              revEnergia: this.formEnergiaGas.controls.meanRevEnergia.value,
              revGas: this.formEnergiaGas.controls.meanRevGas.value,
              percOptions: this.formEnergiaGas.controls.percOptions.value,
              percFutures: this.formEnergiaGas.controls.percFutures.value,
              correlazione: this.formEnergiaGas.controls.correlazione.value,
              percFinanziamento: this.formEnergiaGas.controls.percFinanziamento.value,
              tassoEquity: this.formEnergiaGas.controls.tassoEquity.value,
              
            }
    

   
        this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/calcolaEnergiaGas', this.request).subscribe(data => {
          this.risultatiEnergiaGas = data
          this.boolRisultati = true;
          this.indicatoreEconometrico = 'IRR Equity';
          this.  range = ['<=0%','0%-5%','5%-10%','10%-15%','15%-20%','20%-25%','25%-30%','30%-35%','35%-40%','40%-45%','45%-50%','>50%'];
          this.frequenze = this.risultatiEnergiaGas.freqIrrEq;
          this.minimoIndicatore = this.risultatiEnergiaGas.minMaxMedIrrEq[0];
          this.massimoIndicatore = this.risultatiEnergiaGas.minMaxMedIrrEq[1];
          this.mediaIndicatore = this.risultatiEnergiaGas.minMaxMedIrrEq[2];
          this.frequenzeNoOpts = this.risultatiEnergiaGas.freqIrrEqNoOpts;
          this.minimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrEqNoOpts[0];
          this.massimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrEqNoOpts[1];
          this.mediaIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrEqNoOpts[2];
         
          });
    
          }

        }
      
        onReset() {
          this.formEnergiaGas.reset(this.initialValues);
          this.submitted = false;
          this.boolRisultati = false;

        }
  
        onChangeIndicatore(){
          if (this.indicatoreEconometrico == 'IRR Equity'){
            this.range= ['<=0%','0%-5%','5%-10%','10%-15%','15%-20%','20%-25%','25%-30%','30%-35%','35%-40%','40%-45%','45%-50%','>50%'];
            this.frequenze= this.risultatiEnergiaGas.freqIrrEq;
            this.minimoIndicatore = this.risultatiEnergiaGas.minMaxMedIrrEq[0];
            this.massimoIndicatore = this.risultatiEnergiaGas.minMaxMedIrrEq[1];
            this.mediaIndicatore = this.risultatiEnergiaGas.minMaxMedIrrEq[2];
            this.frequenzeNoOpts = this.risultatiEnergiaGas.freqIrrEqNoOpts;
            this.minimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrEqNoOpts[0];
            this.massimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrEqNoOpts[1];
            this.mediaIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrEqNoOpts[2];
          }
          else if (this.indicatoreEconometrico == 'IRR Progetto'){
            this.range= ['<=0%','0%-2,5%','2,5%-5%','5%-7,5%','7,5%-10%','10%-12,5%','12,5%-15%','15%-17,5%','17,5%-20%','20%-22,5%','22,5%-25%','>25%'];
            this.frequenze= this.risultatiEnergiaGas.freqIrrPr;
            this.minimoIndicatore = this.risultatiEnergiaGas.minMaxMedIrrPr[0];
            this.massimoIndicatore = this.risultatiEnergiaGas.minMaxMedIrrPr[1];
            this.mediaIndicatore = this.risultatiEnergiaGas.minMaxMedIrrPr[2];
            this.frequenzeNoOpts= this.risultatiEnergiaGas.freqIrrPrNoOpts;
            this.minimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrPrNoOpts[0];
            this.massimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrPrNoOpts[1];
            this.mediaIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedIrrPrNoOpts[2];
          }
          else if (this.indicatoreEconometrico == 'Break even'){
            this.range= ['1','2','3','4','5','6','7','8','9','10','11','>11'];
            this.frequenze= this.risultatiEnergiaGas.freqBreakEven;
            this.minimoIndicatore = this.risultatiEnergiaGas.minMaxMedBreakEven[0];
            this.massimoIndicatore = this.risultatiEnergiaGas.minMaxMedBreakEven[1];
            this.mediaIndicatore = this.risultatiEnergiaGas.minMaxMedBreakEven[2];
            this.frequenzeNoOpts= this.risultatiEnergiaGas.freqBreakEvenNoOpts;
            this.minimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedBreakEvenNoOpts[0];
            this.massimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedBreakEvenNoOpts[1];
            this.mediaIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedBreakEvenNoOpts[2];
          }
          else if (this.indicatoreEconometrico == 'Valore compagnia'){
            this.range= ['<10 MLN','10-20 MLN','20-30 MLN','30-40 MLN','40-50 MLN','50-60 MLN','60-70 MLN','70-80 MLN','80-90 MLN','90-100 MLN','100-110 MLN','>110 MLN'];
            this.frequenze= this.risultatiEnergiaGas.freqValCompany;
            this.minimoIndicatore = this.risultatiEnergiaGas.minMaxMedValCompany[0];
            this.massimoIndicatore = this.risultatiEnergiaGas.minMaxMedValCompany[1];
            this.mediaIndicatore = this.risultatiEnergiaGas.minMaxMedValCompany[2];
            this.frequenzeNoOpts= this.risultatiEnergiaGas.freqValCompanyNoOpts;
            this.minimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedValCompanyNoOpts[0];
            this.massimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedValCompanyNoOpts[1];
            this.mediaIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedValCompanyNoOpts[2];
          }
          else if (this.indicatoreEconometrico == 'Dscr'){
            this.range = ['<=100%','100%-110%','110%-120%','120%-130%','130%-140%','140%-150%','150%-160%','160%-170%','170%-180%','180%-190%','190%-200%','>200%'];
            this.frequenze= this.risultatiEnergiaGas.freqDscr;
            this.minimoIndicatore = this.risultatiEnergiaGas.minMaxMedDscr[0];
            this.massimoIndicatore = this.risultatiEnergiaGas.minMaxMedDscr[1];
            this.mediaIndicatore = this.risultatiEnergiaGas.minMaxMedDscr[2];
            this.frequenzeNoOpts= this.risultatiEnergiaGas.freqDscrNoOpts;
            this.minimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedDscrNoOpts[0];
            this.massimoIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedDscrNoOpts[1];
            this.mediaIndicatoreNoOpts = this.risultatiEnergiaGas.minMaxMedDscrNoOpts[2];
        }
      }

}
