import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControlDirective, UntypedFormGroup, Validators } from '@angular/forms';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { HttpClient } from '@angular/common/http';
import {BeanStatico} from '../condivisi/beanStatico'
import { faInfo} from '@fortawesome/free-solid-svg-icons';
import { DecimalPipe } from '@angular/common';



@Component({
  selector: 'qs-garanzie',
  template: `
  <div class="col-12" [formGroup]="garanzieForm" >
    <div class="card margin-bottom-10">
      <div class="card-header text-center font-big">Finanziamento</div>
      <div class="card-body">

        <div class="row">
          <div class="margin-bottom-10 col-4 col-lg-3">
            <div class="col-12 nopadding">
              Totale investimenti
              <fa-icon class="cursor pull-right" style="font-size: 15px" [icon]="faInfo" placement="top" ngbTooltip="Valore complessivo degli investimenti in €"></fa-icon>
            </div>
            <input type="text" [(ngModel)]="importoInvestimentoFE" class="form-control" [ngModelOptions]="{standalone: true}" readonly/>
          </div>
        </div>

        <div class="row">

          <div class="margin-bottom-10 col-4 col-lg-3">
            <div class="col-12 nopadding">
              Finanziabilit&agrave;
              <fa-icon class="cursor pull-right" style="font-size: 15px" [icon]="faInfo" placement="top" ngbTooltip="Valore del finanziamento in €. Massimo 80% del totale"></fa-icon>
            </div>
            <input type="text" formControlName="contributoFinanziamento" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contributoFinanziamento.errors }" currencyInput (change)="onChangeContributoFinanziamento()" #inputFinanziabilita/>
            <div *ngIf="submitted && f.contributoFinanziamento.errors" class="invalid-feedback">
              <div *ngIf="f.contributoFinanziamento.errors.required">Campo obbligatorio</div>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="margin-bottom-10 col-4 col-lg-3">
            <div class="col-12 nopadding">
              Contributo mezzi propri
             <fa-icon class="cursor pull-right" style="font-size: 15px" [icon]="faInfo" placement="top" ngbTooltip="Valore del contributo personale in €. Minimo 20% del totale"></fa-icon>
            </div>
            <input type="text" formControlName="contributoPersonale" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contributoPersonale.errors }" currencyInput (change)="onChangeContributoPersonale()" #inputMezziPropri/>
            <div *ngIf="submitted && f.contributoPersonale.errors" class="invalid-feedback">
              <div *ngIf="f.contributoPersonale.errors.required">Campo obbligatorio</div>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="margin-bottom-10 col-4 col-lg-3">
            <label>Anni di rimborso</label>
            <input type="number" formControlName="durataFinanziamentoAnni" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.durataFinanziamentoAnni.errors }" />
            <div *ngIf="submitted && f.durataFinanziamentoAnni.errors" class="invalid-feedback">
              <div *ngIf="f.durataFinanziamentoAnni.errors.required">Campo obbligatorio</div>
            </div>
          </div>

          <div class="margin-bottom-10 col-4 col-lg-3">
            <label>Anni di preammortamento</label>
            <input type="number" formControlName="durataAmmortamentoAnni" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.durataAmmortamentoAnni.errors }" />
            <div *ngIf="submitted && f.durataAmmortamentoAnni.errors" class="invalid-feedback">
              <div *ngIf="f.durataAmmortamentoAnni.errors.required">Campo obbligatorio</div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="card nopadding">
      <div class="card-header text-center font-big">Garanzie</div>
      <div class="card-body">

        <div class="row">

          <div class="margin-bottom-10 col-4">
            <label>Sei disposto a fornire ulteriori garanzie al finanziamento?</label>

          </div>
          <div class="margin-bottom-10 col-2 col-lg-1">
            <select required formControlName="presenzaGaranzie" class="form-control" (change)="onChangePresenzaGaranzie()" [ngClass]="{ 'is-invalid': submitted && f.presenzaGaranzie.errors }">
              <option [ngValue]="false">No</option>
              <option [ngValue]="true">Si</option>
            </select>
            <div *ngIf="submitted && f.presenzaGaranzie.errors" class="invalid-feedback">
              <div *ngIf="f.presenzaGaranzie.errors.required">Campo obbligatorio</div>
            </div>

          </div>
          <div *ngIf="f.presenzaGaranzie.value === false" style="width: 100%; font-size: 80%; color: #dc3545;">
              Attenzione! Con garanzie inferiori al 20%, si ha una bassa probabilità di accedere al credito.
          </div>
        </div>
        <div class="row" [hidden]="!(f.presenzaGaranzie.value && f.presenzaGaranzie.value == true)">

          <div class="col-4 col-lg-3 margin-bottom-10">
            <label>Percentuale di garanzia</label>
            <select formControlName="percentualeGaranzia" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.percentualeGaranzia.errors }">
              <option [value]="((i+2)*5)/100" *ngFor="let item of [].constructor(9); let i = index">{{(i+2)*5}}%</option>
            </select>
            <div *ngIf="submitted && f.percentualeGaranzia.errors" class="invalid-feedback">
              <div *ngIf="f.percentualeGaranzia.errors.required">Campo obbligatorio</div>
            </div>
            <div *ngIf="f.percentualeGaranzia.value < 0.2 && f.percentualeGaranzia.value != ''" style="width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;">
              Attenzione! Con garanzie inferiori al 20%, si ha una bassa probabilità di accedere al credito.
            </div>
          </div>

          <div class="col-4 col-lg-3 margin-bottom-10">
            <label>Tipologia garanzia</label>
            <select formControlName="tipologiaGaranzia" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tipologiaGaranzia.errors }">
              <option value="liquidi">Liquidità</option>
              <option value="ipoteca">Ipoteca immobile</option>
            </select>
            <div *ngIf="submitted && f.tipologiaGaranzia.errors" class="invalid-feedback">
              <div *ngIf="f.tipologiaGaranzia.errors.required">Campo obbligatorio</div>
            </div>
          </div>
          <div class="col-12 font-bold" *ngIf="f.percentualeGaranzia.value != '' &&  f.contributoFinanziamento.value != '' ">Importo garanzie personali: {{ f.contributoFinanziamento.value*(f.percentualeGaranzia.value) | number: '1.0-0': 'it'}} €</div>

        </div>

      </div>
    </div>
  </div>

`,
styles: [`
label{
  display: inline-block;
  margin-bottom: 0rem !important;
}
`]
})
export class GaranzieComponent implements OnInit {
@Input('formGroup') garanzieForm!: UntypedFormGroup;
@ViewChild('inputFinanziabilita') inputFinanziabilita!: ElementRef;
@ViewChild('inputMezziPropri') inputMezziPropri!: ElementRef;


submitted = false;
importoInvestimento = 0;
importoInvestimentoFE = '';
faInfo = faInfo;

constructor(private datiAnagrafica: DatiQuestionarioService, private httpClient: HttpClient, private decimalPipe: DecimalPipe) {

}

ngOnInit() {

this.datiAnagrafica.currentSubmitted.subscribe(submitted => this.submitted = submitted)
this.datiAnagrafica.currentImportoInvestimento.subscribe(importoInvestimento => {
  this.importoInvestimentoFE = this.decimalPipe.transform(importoInvestimento, '1.0-0', 'it') + ' €';

  if(this.importoInvestimento != importoInvestimento){
    this.f.contributoFinanziamento.setValue((0.8*importoInvestimento).toString());
    this.f.contributoPersonale.setValue((0.2*importoInvestimento).toString());
    if(typeof this.inputFinanziabilita !== 'undefined' && typeof this.inputMezziPropri !== 'undefined'){
    this.f.contributoFinanziamento.value != '' ? this.inputFinanziabilita.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.contributoFinanziamento.value), '1.0-0', 'it') + ' €' : '';
    this.f.contributoPersonale.value != '' ? this.inputMezziPropri.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.contributoPersonale.value), '1.0-0', 'it') + ' €' : '';
    }
  }
  this.importoInvestimento = importoInvestimento;
})
}

/*
ngAfterContentChecked(){
  if(typeof this.inputFinanziabilita !== 'undefined' && typeof this.inputMezziPropri !== 'undefined' && this.count == 0){
    this.inputFinanziabilita.nativeElement.focus();
    this.inputFinanziabilita.nativeElement.blur();
    this.inputMezziPropri.nativeElement.focus();
    this.inputMezziPropri.nativeElement.blur();
    this.count++;
  }

}*/

ngAfterViewInit(){
  if(this.f.contributoFinanziamento.value != '' && this.f.contributoPersonale.value != ''){
    if(typeof this.inputFinanziabilita !== 'undefined' && typeof this.inputMezziPropri !== 'undefined'){
      this.f.contributoFinanziamento.value != '' ? this.inputFinanziabilita.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.contributoFinanziamento.value), '1.0-0', 'it') + ' €' : '';
      this.f.contributoPersonale.value != '' ? this.inputMezziPropri.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.contributoPersonale.value), '1.0-0', 'it') + ' €' : '';
    }

  }

}

// convenience getter for easy access to form fields
get f() { return this.garanzieForm.controls; }

onChangePresenzaGaranzie(){
  if(this.f.presenzaGaranzie.value == true){
    this.f.percentualeGaranzia.setValidators(Validators.required);
    this.f.tipologiaGaranzia.setValidators(Validators.required);
    this.f.percentualeGaranzia.setValue(0.2);
  }
  else {
    this.f.percentualeGaranzia.clearValidators();
    this.f.tipologiaGaranzia.clearValidators();
    this.f.percentualeGaranzia.setValue('');
    this.f.tipologiaGaranzia.setValue('');
  }
  this.f.percentualeGaranzia.updateValueAndValidity();
  this.f.tipologiaGaranzia.updateValueAndValidity();

}

onChangeContributoPersonale(){
  if(this.f.contributoPersonale.value && this.f.contributoPersonale.value != '' && this.importoInvestimento){
    if(parseFloat(this.f.contributoPersonale.value.replace(/\,/g, '.')) < this.importoInvestimento*0.2 || parseFloat(this.f.contributoPersonale.value.replace(/\,/g, '.')) > this.importoInvestimento){
      this.f.contributoPersonale.setValue((this.importoInvestimento*0.2).toString())
    }
    this.f.contributoFinanziamento.setValue((this.importoInvestimento - parseFloat(this.f.contributoPersonale.value.replace(/\,/g, '.'))).toString())
    if(typeof this.inputFinanziabilita !== 'undefined' && typeof this.inputMezziPropri !== 'undefined'){
      this.f.contributoFinanziamento.value != '' ? this.inputFinanziabilita.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.contributoFinanziamento.value), '1.0-0', 'it') + ' €' : '';
      this.f.contributoPersonale.value != '' ? this.inputMezziPropri.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.contributoPersonale.value), '1.0-0', 'it') + ' €' : '';
    }
  }

}

onChangeContributoFinanziamento(){

  if(this.f.contributoFinanziamento.value && this.f.contributoFinanziamento.value != '' && this.importoInvestimento){
    if(parseFloat(this.f.contributoFinanziamento.value.replace(/\,/g, '.')) > this.importoInvestimento*0.8 || parseFloat(this.f.contributoFinanziamento.value.replace(/\,/g, '.')) < 0){
      this.f.contributoFinanziamento.setValue((this.importoInvestimento*0.8).toString())
    }
    this.f.contributoPersonale.setValue((this.importoInvestimento - parseFloat(this.f.contributoFinanziamento.value.replace(/\,/g, '.'))).toString())
    if(typeof this.inputFinanziabilita !== 'undefined' && typeof this.inputMezziPropri !== 'undefined'){
      this.f.contributoFinanziamento.value != '' ? this.inputFinanziabilita.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.contributoFinanziamento.value), '1.0-0', 'it') + ' €' : '';
      this.f.contributoPersonale.value != '' ? this.inputMezziPropri.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.contributoPersonale.value), '1.0-0', 'it') + ' €' : '';
    }
  }

}

}
