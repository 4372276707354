<div class="container-fluid" style="padding-top: 10px;">
  <!--sticky-bp conf-bp-container id="container-bpconfiguation" -->
  <div class="header">
    <div class="row margin-right-20">

      <div class="col-12 d-flex justify-content-center">
        <h2 class="text-center margin-bottom-10" style="font-family: var(--UI-font);margin: 0;">Configurazione
          Business
          Plan per:
          <strong>{{businessPlanTemp?
            businessPlanTemp.nomeAziendaRichiedente : ''}}</strong>
        </h2>
      </div>
      <div class="col-2"></div>
      <div class="col-8 d-flex justify-content-center" style="height:30px;">
        <h2 class="text-center"> <strong>{{businessPlanTemp?
            businessPlanTemp.riferimentoBp : ''}}</strong></h2>
      </div>
      <div class="col-2 d-flex align-items-end justify-content-end">
        <button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
          class=" btn btn-success" type="submit" (click)="onSubmitComplessivo()">Salva</button>
        <button class="btn btn-dark" style="margin-left: 20px" (click)="Elabora()">{{businessPlanTemp!= null &&
          (businessPlanTemp.idStato==3
          ||businessPlanTemp.idStato==4) ? 'Risultati':'Elabora'}}</button>
      </div>
    </div>
  </div>
  <!-- selectedIndex proprietà delle mat tab app-dossier-amministrativi-->
  <div id="container-mat-tab-bp">
    <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex" class="d-flex justify-content-around"
      (selectedTabChange)="resetCountCostiEricavi();onChangeTab();" [formGroup]="configurazioneBpForm">
      <mat-tab label="Situazione finanziaria" *ngIf="businessPlanTemp!= null && businessPlanTemp.idContoEconomico!=0"
        id="situazioneFinanziaria" [labelClass]="{'label-error':(!situazioneDebitoriaValid && situazioneDebitoriaDirty) || (!situazioneCreditiziaValid && situazioneCreditiziaDirty),
                'label-correct':situazioneDebitoriaSaved && situazioneCreditiziaSaved}">
        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndexFin">
          <mat-tab label="Situazione creditizia"
            [labelClass]="{'label-error': !situazioneCreditiziaValid && situazioneCreditiziaDirty, 'label-correct': situazioneCreditiziaSaved}">
            <app-situazione-creditizia [situazioneCreditiziaSaved]="situazioneCreditiziaSaved"
              [submittedCrediti]="situazioneCreditiziaDirty" [businessPlanTemp]="businessPlanTemp"
              [creditiformGroup]="f.situazioneCreditizia" [situazioneCreditiziaVolatile]="currentSituazioneCreditizia"
              (emitCreditiziaFormValid)="saveCreditiziaValid($event)"></app-situazione-creditizia>
          </mat-tab>
          <mat-tab label="Situazione debitoria"
            [labelClass]="{'label-error': !situazioneDebitoriaValid && situazioneDebitoriaDirty, 'label-correct': situazioneDebitoriaSaved}">
            <app-situazione-debitoria-nuovo [businessPlanTemp]="businessPlanTemp"
              [submittedDebiti]="situazioneDebitoriaDirty" [debitiformGroup]="f.situazioneDebitoria"
              [situazioneDebitoriaSaved]="situazioneDebitoriaSaved"
              [situazioneDebitoriaVolatile]="currentSituazioneDebitoria"
              (emitDebitoriaFormValid)="saveDebitoriaValid($event)">
            </app-situazione-debitoria-nuovo>

          </mat-tab>
        </mat-tab-group>
        <!-- submittedCrediti && submittedDebiti && situazioneDebitoriaValid && situazioneCreditiziaValid -->

      </mat-tab>
      <mat-tab label="Investimenti/Disinvestimenti"
        [labelClass]="{'label-error':!investimentiDisinvestimentiValid && investimentiDisinvestimentiDirty, 'label-correct':investimentiDisinvestimentiSaved}">
        <!-- investimentiDisinvestimentiValid && investimentiDisinvestimentiDirty -->
        <app-investimenti-disinvestimenti-bp [businessPlanTemp]="businessPlanTemp"
          [submitted]="investimentiDisinvestimentiDirty" [investimentiForm]="f.investimenti"
          [bilancioPresente]="businessPlanTemp && businessPlanTemp.idContoEconomico!=0"
          [investimentiDisinvestimentiVolatile]="currentInvestimentiDisinvestimenti"
          (emitInvestimentiFormValid)="saveInvestimentiValid($event)">
        </app-investimenti-disinvestimenti-bp>
      </mat-tab>
      <mat-tab label="Indicatori guida"
        [labelClass]="{'label-error':!indicatoriGuidaBpValid && indicatoriGuidaBpDirty, 'label-correct':indicatoriGuidaBpSaved}">
        <!-- indicatoriGuidaBpValid && submittedIndicatoriGuida -->
        <app-indicatori-guida-bp [businessPlanTemp]="businessPlanTemp" [indicatoriGuidaForm]="f.indicatoriGuida"
          [submitted]="indicatoriGuidaBpDirty" [indicatoriGuidaVolatile]="currentIndicatoriGuida"
          (emitIndicatoriFormValid)="saveIndicatoriGuidaValid($event)">
        </app-indicatori-guida-bp>
      </mat-tab>
      <mat-tab label="Pianificazione ricavi/costi"
        [labelClass]="{'label-error':!ricaviCostiTotValid && ricaviCostiTotDirty, 'label-correct':ricaviCostiTotSaved}">
        <app-inserimento-ricavi-costi [businessPlanTemp]="businessPlanTemp" [ricaviEcostiForm]="f.ricaviEcosti"
          [tabIndex]="selectedIndex" [ricaviCostiVolatile]="currentRicaviCosti"
          [lineaBusinessArrayValid]="ricaviCostiValid" [lineaBpSaved]="ricaviCostiSaved"
          [lineeBpDirty]="ricaviCostiDirty" [ricaviCostiDirty]="ricaviCostiTotDirty"
          (emitRicaviCostiValid)="saveRicaviCostiValid($event)">
          <!--  -->

        </app-inserimento-ricavi-costi>
      </mat-tab>
      <mat-tab label="Magazzino" *ngIf="presenzaMagazzino"
        [labelClass]="{'label-error':!magazzinoValid && magazzinoDirty, 'label-correct': magazzinoSaved}">
        <!-- submittedMagazzino && magazzinoValid -->
        <app-configuarazione-magazzino [businessPlanTemp]="businessPlanTemp" [magazzino]="f.magazzino"
          [submitted]="magazzinoDirty" [magazzinoVolatile]="currentMagazzino"
          (emitMagazzinoFormValid)="saveMagazzinoValid($event)">
        </app-configuarazione-magazzino>
      </mat-tab>


    </mat-tab-group>
  </div>
</div>
<div class="footer">

</div>