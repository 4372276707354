import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatiQuestionarioService {

  private anagraficaCompleted = new BehaviorSubject(false);
  private dossierAmministrativiCompleted = new BehaviorSubject(false);
  private finanziamentoCompleted = new BehaviorSubject(false);
  private datiEconomiciCompleted = new BehaviorSubject(false);
  private garanzieCompleted = new BehaviorSubject(false);
  private nomeAzienda = new BehaviorSubject("");
  private stepIndex = new BehaviorSubject(1);
  private submitted = new BehaviorSubject(false);
  private caricato = new BehaviorSubject(false);
  private datiQuestionario: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private importoInvestimento = new BehaviorSubject(0);
  private filePresentazione = new BehaviorSubject<any>(null);


  currentAnagrafica: Observable<boolean> = this.anagraficaCompleted.asObservable();
  currentDossierAmministrativi: Observable<boolean> = this.dossierAmministrativiCompleted.asObservable();
  currentFinanziamento: Observable<boolean> = this.finanziamentoCompleted.asObservable();
  currentDatiEconomici: Observable<boolean> = this.datiEconomiciCompleted.asObservable();
  currentNomeAzienda: Observable<string> = this.nomeAzienda.asObservable();
  currentGaranzie: Observable<boolean> = this.garanzieCompleted.asObservable();
  currentStepIndex: Observable<number> = this.stepIndex.asObservable();
  currentSubmitted: Observable<boolean> = this.submitted.asObservable();
  currentCaricato: Observable<boolean> = this.caricato.asObservable();
  currentDatiQuestionario: Observable<any> = this.datiQuestionario.asObservable();
  currentImportoInvestimento: Observable<number> = this.importoInvestimento.asObservable();
  currentFilePresentazione: Observable<any> = this.filePresentazione.asObservable();

  private formTouched: BehaviorSubject<boolean> = new BehaviorSubject(true);
  checkFormTouched: Observable<boolean> = this.formTouched.asObservable();

  private questionarioSalvato: BehaviorSubject<boolean> = new BehaviorSubject(true);
  checkQuestionarioSalvato: Observable<boolean> = this.questionarioSalvato.asObservable();

  constructor() { }

  changeFormTouched(value: boolean) {
    this.formTouched.next(value);
  }

  changeAnagraficaCompleted(valore: boolean) {
    this.anagraficaCompleted.next(valore)
  }
  changeDossierAmministrativiCompleted(valore: boolean) {
    this.dossierAmministrativiCompleted.next(valore)
  }

  changeFinanziamentoCompleted(valore: boolean) {
    this.finanziamentoCompleted.next(valore)
  }

  changeDatiEconomiciCompleted(valore: boolean) {
    this.datiEconomiciCompleted.next(valore)
  }

  changeGaranzieCompleted(valore: boolean) {
    this.garanzieCompleted.next(valore)
  }

  changeNomeAzienda(valore: string) {
    this.nomeAzienda.next(valore)
  }

  changeSubmitted(valore: boolean) {
    this.submitted.next(valore)
  }

  changeCaricato(valore: boolean) {
    this.caricato.next(valore)
  }

  changeStepIndex(valore: number) {
    this.stepIndex.next(valore)
  }

  changeDatiQuestionario(valore: Object) {
    this.datiQuestionario.next(valore)
  }

  changeImportoInvestimento(valore: number) {
    this.importoInvestimento.next(valore)
  }

  changeFilePresentazione(valore: Object) {
    this.filePresentazione.next(valore)
  }

  changeQuestionarioSalvato(valore: boolean) {
    this.questionarioSalvato.next(valore)
  }

  cancellaTutto() {
    this.stepIndex.next(1);
    this.submitted.next(false);
    this.nomeAzienda.next("");
    this.anagraficaCompleted.next(false);
    this.dossierAmministrativiCompleted.next(false);
    this.garanzieCompleted.next(false);
    this.finanziamentoCompleted.next(false);
    this.importoInvestimento.next(0);
    this.datiQuestionario.next(null);
    this.filePresentazione.next(null);
  }
}
