<h1 mat-dialog-title>Inserimento Utente</h1>
<div mat-dialog-content>
  <div class="row" [formGroup]="UserForm">
    <h4>Nome Utente:</h4>
    <input class="form-control nome" placeholder="Nome Utente" formControlName="NomeUtente">
    <div class="ers"
      *ngIf="this.UserForm.controls['NomeUtente'].errors?.['required'] && this.UserForm.controls['NomeUtente']?.touched">
      Inserisci un nome utente valido.
    </div>
    <div class="ers" *ngIf="copyname">
      E' gia presente un utente con questo nome.
    </div>
    <h4>Email Utente:</h4>
    <input class="form-control mail" formControlName="EmailUtente" placeholder="Email Utente">
    <div class="ers"
      *ngIf="this.UserForm.controls['EmailUtente'].errors?.['email'] || this.UserForm.controls['EmailUtente'].errors?.['required'] && this.UserForm.controls['EmailUtente']?.touched">
      Inserisci un indirizzo email valido.
    </div>
    <div class="ers" *ngIf="copymail">
      E' gia presente un utente con questa e-mail.
    </div>
    <h4>Ruolo Utente:</h4>

    <select formControlName="RuoloUtente" class="form-control form-select">
      <option value="3"> Responsabile di area </option>
      <option value="4"> Operatore </option>
    </select>

  </div>
</div>
<div mat-dialog-actions>
  <button class="btn btn-danger" (click)="onNoClick()">Annulla</button>
  <button id="insert" class="btn btn-primary" (click)="onInsert()">Inserisci</button>
</div>