import { Component, OnInit, ViewChild } from '@angular/core';
import { RichiedenteService } from '../../_services/richiedente.service';
import { TokenStorageService } from '../../_services/token-storage.service';
import { Validators, FormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Richiedente } from '../../interface/richiedente';
import { ConfigurazioneAmbienteService } from '../../_services/configurazione-ambiente.service';
import { ModalcontentComponent } from '../../modalcontent/modalcontent/modalcontent.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BilancioService } from '../../_services/bilancio.service';
import { Router } from '@angular/router';
import { ToolTipService } from '../../_services/tool-tip.service';
import { SituazioneDebitoriaSmartComponent } from './situazione-debitoria-smart/situazione-debitoria-smart.component';
import { SituazioneCreditiziaSmartComponent } from './situazione-creditizia-smart/situazione-creditizia-smart.component';
import { SmartBpService } from 'src/app/_services/smart-bp.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-situazione-finanziaria',
  templateUrl: './situazione-finanziaria.component.html',
  styleUrls: ['./situazione-finanziaria.component.css']
})
export class SituazioneFinanziariaComponent implements OnInit {

  @ViewChild(SituazioneCreditiziaSmartComponent, { static: false }) SituazioneCreditiziaSmartComponent: SituazioneCreditiziaSmartComponent;
  @ViewChild(SituazioneDebitoriaSmartComponent, { static: false }) SituazioneDebitoriaSmartComponent: SituazioneDebitoriaSmartComponent;

  submitted = false;
  /* non mi importa il valore di queste variabili. basta che cambino */
  isDirtyParametri: boolean = false;
  isDirtyForm: boolean[] = null;
  configurazioneSmartForm: UntypedFormGroup
  currentAnno = new Date().getFullYear()
idRichiedente = 0;
richiedenteSmartSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tokenStorageService: TokenStorageService,
    private smartBpService: SmartBpService,
    private toolTipService: ToolTipService,
    private router: Router) {
    /* form per nome azienda */

    this.configurazioneSmartForm = this.formBuilder.group({
      situazioneDebitoria: this.formBuilder.group({}),
      situazioneCreditizia: this.formBuilder.group({})
    })
  }
  get currentUser() { return this.tokenStorageService.getUser() }
  get f() { return this.configurazioneSmartForm.controls }
 
  ngOnInit(): void {
    this.toolTipService.getAllTipsContent('CAM')//carico tutti i tip di GBP sul service
    /* cambiare chiamata inserendo il check sul bilancio */

    this.richiedenteSmartSubscription = this.smartBpService.currentRichiedenteSmartBp.subscribe(richiedente => {
      if(richiedente != null && richiedente.id != 0){
  this.idRichiedente = richiedente.id;
}else{
  this.router.navigate(['/smartBp'])
}
    });
    
  }


  ngOnDestroy(): void {
    this.richiedenteSmartSubscription.unsubscribe()
  }


}
