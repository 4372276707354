<div class="container-fluid" style="margin: 2vh 0;">
  <div class="row justify-content-center" style="border-bottom: 1px solid grey;">
    <h1>Sezione report pdf</h1>
    <h2> Seleziona le pagine che vuoi escludere dal report, altrimentri stampa tutto.</h2>
  </div>
  <div class="row lista-pagine" style="margin: 4vh">
    <div class="col-1" *ngFor=" let nome of listaPagine; let ind = index;">
      <button type="button" id={{nome}} class="btn btn-square-md"
        [ngClass]="indiciPagine[ind] ? 'btn-success' : 'btn-secondary'"
        (click)="indiciPagine[ind] = !indiciPagine[ind]">{{nome}}</button>
    </div>
  </div>
  <div class="row" style="margin: 9vh;">
    <div class="col-1">
      <button type="button" style="width: 100px;" class="btn btn-primary" (click)=compilePDF()><i
          class="bi bi-search">Genera pdf</i></button>
    </div>
  </div>
</div>