import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const ApiUrl =  environment.apiUrlNode  + '/api/azienda/';
@Injectable({
  providedIn: 'root'
})

export class AziendaService {

  constructor(private http: HttpClient) { }

  getAllAziende(): Observable<any> {
    return this.http.get(ApiUrl + 'allDatiAzienda/', { responseType: 'text' });
  }

}
