<div class="container-fluid">
    <div class="card nopadding">
        <div class="card-header text-center font-big">
            <h3>Lista clienti</h3>
            <div class="row  justify-content-between">
                <div class="col-2 col-md-2 d-flex justify-content-center justify-content-middle">
                    <span style="font-family: FontAwesome;">
                        <input type="text" [(ngModel)]="userFilter.riferimento" autofocus
                            placeholder="&#xf002; Cerca cliente">
                    </span>
                </div>
                <div class="col-2 d-flex justify-content-end align-items-end">
                    <a href="#!" routerLink="/censimentocliente" role="button" class="btn">Nuovo cliente</a>
                </div>
            </div>

        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-7">
                    <div class="row" style="padding-left: 20px;">
                        <div class="col-1 d-flex justify-content-center">
                            Index
                        </div>
                        <div class="col-4 d-flex justify-content-center">
                            <h3>nome cliente</h3>
                        </div>
                        <div class="col-4 d-flex justify-content-center">
                            <h3>partit iva</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <!-- <app-cliente *ngFor="let clienteEl of clienti" [cliente]="clienteEl"></app-cliente> -->
                            <app-cliente></app-cliente>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            Dettagli
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <app-dettagli-cliente></app-dettagli-cliente>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

</div>