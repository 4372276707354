import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { identity, Observable,  BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
const ApiUrl =  environment.apiUrlNode + '/api/upload/cr';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class CrService {
  private paramCr = new BehaviorSubject<any>(null);
  currentParamCr = this.paramCr.asObservable();


  constructor(private http: HttpClient) { }

  leggiCr(formData): Observable<any> {
        return this.http.post(ApiUrl, formData, httpOptions);
  }
  changeParamCr(valore: Object) {
         this.paramCr.next(valore)
  }

}
