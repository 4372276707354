import { Router } from '@angular/router';
import { TokenStorageService } from './../_services/token-storage.service';
import { CrService } from './../_services/cr.services';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadFilesService } from '../_services/upload-files.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

@Component({
  selector: 'mv-presaincaricocr',
  template: `
    <div class="container-fluid center">
      <h3 class="text-center mb-3">Presa in carico della CR</h3>

      <div class="row">
        <div class="col-1"></div>
        <div class="col-8">
          <label class="btn btn-default p-0">
            <input type="file" accept=".pdf" (change)="selectFiles($event)" />
          </label>
        </div>
        <div class="col-2 text-right bordo-sx">
          <button class="btn btn-success btn-sm"  [disabled]="!selectedFiles" (click)="uploadFiles()" >
            Carica CR
          </button>
        </div>
        <div class="col-1"></div>
      </div>

      <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
          <div *ngFor="let progressInfo of progressInfos" class="mb-2">
            <span>{{ progressInfo.fileName }}</span>
            <div class="progress">
              <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                attr.aria-valuenow="{{ progressInfo.value }}" aria-valuemin="0" aria-valuemax="100"
                [ngStyle]="{ width: progressInfo.value + '%' }">{{ progressInfo.value }}%
              </div>
            </div>
          </div>
        </div>
        <div class="col-1"></div>
      </div>

      <div class="row mb-0">
        <div class="col-1"></div>
        <div class="col-10">
          <div *ngIf="message.length" class="alert alert-secondary my-3" role="alert">
            <ul *ngFor="let msg of message; let i = index">
              <li>{{ msg }}</li>
            </ul>
          </div>
        </div>
        <div class="col-1"></div>
      </div>

      <div class="row form-group" *ngIf="risultato">
        <div class="col-1"></div>
        <div class="col-10">
          <div class="row">
            <div class="col-12"><label>Dati della CR di {{risultato.cr}} REGISTRATI sul DB</label></div>
          </div>
          <div class="row">
            <div class="col-3 text-right"><label>Provenienza:</label></div>
            <div class="col-9 bordo-sx">{{risultato.provenienza}}</div>
          </div>
          <div class="row">
            <div class="col-3 text-right"><label>Intestatari:</label></div>
            <div class="col-9 bordo-sx">{{risultato.intestatari}}</div>
          </div>
          <div class="row">
            <div class="col-3 text-right"><label>Intermediari:</label></div>
            <div class="col-9 bordo-sx">{{risultato.intermediari}}</div>
          </div>
          <div class="row">
            <div class="col-3 text-right"><label>Garanti:</label></div>
            <div class="col-9 bordo-sx">{{risultato.garanti}}</div>
          </div>
          <div class="row">
            <div class="col-3 text-right"><label>Debitori Ceduti:</label></div>
            <div class="col-9 bordo-sx">{{risultato.debitori}}</div>
          </div>
          <div class="row ">
            <div class="col-12">
              <button type="button" class="btn btn-light" (click)="toggleLog()">Visualizza log</button>
            </div>
          </div>
          <ng-container *ngIf="showLog">
              <div class="row">
                <div class="col-sm-12"><hr /></div>
              </div>
              <div class="row mt-1">
                <div class="col-12">
                  <ul class="stile-ul" *ngFor="let riga of fileLog">
                    <li>{{riga.log}}</li>
                  </ul>
                </div>
              </div>
          </ng-container>
        </div>
      </div>

      <div class="row form-group mt-n1">
        <div class="col-1"></div>
        <div class="col-sm-10"><hr /></div>
        <div class="col-1"></div>
      </div>

      <div class="row mt-n1">
        <div class="col-1"></div>
        <div class="col-10">
          <div class="card">
            <div class="card-header">Elenco dei file</div>
            <ul class="list-group list-group-flush" *ngFor="let file of fileInfos | async">
              <li class="list-group-item">
                <a href="{{ file.url }}">{{ file.name }}</a>
              </li>
            </ul>
          </div>
          <div class="col-12 text-center" style="padding-top:20px">
              <button class="btn btn-secondary" type="button" (click)="tornaNoParam()">Torna indietro</button>
          </div>
        </div>
        <div class="col-1"></div>
      </div>

      <mv-spinner *ngIf='spinner'></mv-spinner>
   </div>
   <ng-template #messaggio let-c="close" let-d="dismiss">
          <div class="modal-header bg-success">
            <h4 class="modal-title text-center" id="modal-basic-title">
            </h4>
          </div>
          <div class="modal-body">
          <h5>
            <p class="text-center">
              {{errMsg}}
            </p>
            </h5>
            <div class="modal-footer text-center">
              <button
                type="button"
                class="btn btn-outline-dark"
                (click)=" tornaIndietro()">
                <i class="fa fa-check fa-fw" aria-hidden="true"></i>
                Ok
              </button>
            </div>
          </div>
        </ng-template>
  `,
  styles: [`
    hr {
      background-color: #059b5b;
      height: 1px;
      border: 0;
    }
    .bordo-sx {
      border-left: 1px solid #059b5b;
    }
    .stile-ul {
      list-style-type: none;
      margin-bottom: 0px;
    }
  `],
})

export class PresaIncaricoCrComponent implements OnInit {
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  message: string[] = [];
  fileInfos?: Observable<any>;
  errMsg = '';
  spinner = false;
  risultato: any;
  fileLog = [];
  showLog = false;
  id_intestatario: number = 0;
  data_ins: String;
  @ViewChild('messaggio') messaggio: any;

  constructor(private uploadService: UploadFilesService,
              private crService: CrService,
              private tokenStorageService: TokenStorageService,
              private router: Router,
              private modalService: NgbModal,
              private location: Location
              ) { }

  ngOnInit(): void {
    this.fileInfos = this.uploadService.getFiles();
  }

  toggleLog(): void {
    this.showLog = !this.showLog;
  }

  selectFiles(event): void {
    this.message = [];
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  uploadFiles(): void {
    this.message = [];

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };

    if (file) {
      this.uploadService.upload(file).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            const msg = 'Caricato il file con successo: ' + file.name;
            this.message.push(msg);
            this.fileInfos = this.uploadService.getFiles();
            this.elabora();
          }
        },
        (err: any) => {
          this.progressInfos[idx].value = 0;
          const msg = 'Impossibile caricare il file: ' + file.name;
          this.message.push(msg);
          this.fileInfos = this.uploadService.getFiles();
        }
      );
    }
  }

  elabora(): void {
    console.log(this.selectedFiles[0].name);
    const body = {name: this.selectedFiles[0].name, user: this.tokenStorageService.getUser().username };
    this.spinner = true;
    this.crService.leggiCr(body).
    subscribe(
      (res: any) => {
        console.log(res);
        console.log(res.data);
        this.message.push(res.message);
        this.spinner = false;
        this.risultato = res.data;
        this.fileLog = res.data.log;
        this.id_intestatario = res.codice_intestatario;
        this.data_ins = res.data_inserimento;
        this.errMsg = 'Centrale Rischi caricata con successo ';
        setInterval(() => {
      }, 3000);
      this.modalService.open(this.messaggio, { centered: true,  backdrop:'static',
      backdropClass:'customBackdrop',});
      },
      (error) => {
        console.log('Errore: ', error);
        this.spinner = false;
      }
    );
  }
  tornaNoParam(): void {
    this.location.back();
  }
    tornaIndietro(): void {
      this.modalService.dismissAll();
      this.crService.changeParamCr({
        id_intestatario: this.id_intestatario,
        data_inserimento: this.data_ins.slice(0,10)
      });
      this.location.back();
    }
}
