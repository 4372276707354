<div class="container-fluid">
    <ng-container *ngIf="idRichiedenteTemp==undefined">
        <div class="row">
            <div class="col-6">
                <h2>Parametri generali</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex align-items-center margin-top-10">
                <h3> Inserisci l'azienda di cui vuoi configurare l'ambiente.</h3>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="idRichiedenteTemp!=undefined">
        <div class="row">
            <div class="col-6">
                <h2>Parametri generali</h2>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-end" id="saveParametriGenerali">
                <button class="btn btn-success" (click)="onSubmit()" [disabled]="disabledSalva">Salva</button>
                <button class="btn btn-secondary" style="margin-left: 20px" type="button" (click)="annulla()">
                    Annulla
                </button>
            </div>
        </div>
        <hr>
        <!-- ateco -->
        <div class="subscroll-ambiente-conf">
            <div class="row margin-top-10">
                <div class="col-4 col-xl-4 d-flex align-items-center">
                    <h3 class="align-middle">Codice ATECO prevalente:</h3>&nbsp;&nbsp;
                    <i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                        ngbTooltip={{listaTips[3]?.descrizione}}></i>
                </div>
                <div class="col-3 col-xl-2 d-flex align-items-center">
                    <h3 class="margin-0">{{codiceAtecoPrimario}}</h3>
                </div>
            </div>
            <hr>
            <div id="container-parametri-generali-ambiente-body" [formGroup]="parametriGeneraliForm">
                <!-- anni BP -->
                <div class="row d-flex flex-column margin-top-10">
                    <div class="col-12 d-flex align-items-center">
                        <h3>Anni di sviluppo Business plan:</h3>

                    </div>
                    <div class="col-12  margin-left-10">
                        <div class="row margin-left-10">
                            <div class="col-2">
                                <label>Anni:</label>
                                <input formControlName="anniSviluppoBP" type="number" class="form-control"
                                    [ngClass]="{ 'is-invalid':submitted && f.anniSviluppoBP.errors }" min="0"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                <div *ngIf=" submitted && f.anniSviluppoBP.errors" class="invalid-feedback">
                                    <div *ngIf="f.anniSviluppoBP.errors.required">Campo obbligatorio</div>
                                    <div *ngIf="f.anniSviluppoBP.errors.max">Numero massimo di anni consentito:
                                        {{valoreMax.anniSviluppoBp}} anni</div>
                                </div>

                            </div>
                            <div class="col-3 col-xl-2">
                                <label>Anno iniziale:</label>
                                <select formControlName="annoIniziale" type="number" class="form-control form-select"
                                    style="max-width:100%;" (change)="onChangeAnnoIniziale()"
                                    [ngClass]="{ 'is-invalid':submitted && f.annoIniziale.errors }">
                                    <option [ngValue]="annoIniziale" *ngFor="let annoIniziale of listaAnnoIniziale">
                                        {{annoIniziale.descrizione}}
                                    </option>
                                </select>
                                <div *ngIf=" submitted && f.annoIniziale.errors" class="invalid-feedback">
                                    <div *ngIf="f.annoIniziale.errors.required">Campo obbligatorio</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <!-- inflazione -->
                <div class="row margin-top-10">
                    <div class="col-12 d-flex align-items-center">
                        <h3 class="align-middle">Inflazione: </h3>&nbsp;&nbsp;
                        <i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                            [ngbTooltip]="listaTips[4]?.descrizione"></i>

                    </div>
                    <div class="col-12  margin-left-20">
                        <div class="row margin-left-20">
                            <div class="col-3 col-xl-3 .margin-right-20" *ngIf="idRuolo == 0">
                                <label class="align-middle ">Evoluzione:</label>
                                <select formControlName="inflazione" type="text" value=""
                                    class="form-control form-select"
                                    [ngClass]="{ 'is-invalid':submitted && f.inflazione.errors }"
                                    style="max-width:100%;" (change)="onChangeInflazione()">
                                    <option [ngValue]="inflazione" *ngFor="let inflazione of listaEvoluzioneInflazione">
                                        {{inflazione.descrizione}}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f.inflazione.errors" class="invalid-feedback">
                                    <div *ngIf="f.inflazione.errors.required">Campo obbligatorio</div>
                                </div>
                            </div>
                            <div *ngIf="f.inflazione.value && f.inflazione.value.id==1"
                                class="col-6 margin-0 d-flex align-items-center  warning-label"
                                style="padding-left:40px;">NB: nella sezione business plan
                                sarà necessario configurare i valori
                                percentuali di inflazione per singolo anno in base al numero di anni di
                                sviluppo business plan inseriti.
                            </div>
                            <div *ngIf="f.inflazione.value && f.inflazione.value.id==2" class="col-9"
                                style="padding-top:2.5px;" [formGroup]="evoluzioneInflazione">
                                <div class="row d-flex justify-content-start">
                                    <div class="col-3" style="padding-left: 20px;">
                                        <label class="responsive-md-gestione-label">Inflazione
                                            iniziale(%)&nbsp;<i id="icon" container="body"
                                                class="fa fa-1x fa-info-circle margin-left-20 " aria-hidden="true"
                                                placement="top" [ngbTooltip]="listaTips[1]?.descrizione"></i></label>
                                        <input formControlName="inflazioneIniziale" type="number" class="form-control"
                                            min="0"
                                            [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.inflazioneIniziale.errors}"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                        <div *ngIf="submitted && evoluzioneInflazione.controls.inflazioneIniziale.errors"
                                            class="invalid-feedback">
                                            <div
                                                *ngIf="evoluzioneInflazione.controls.inflazioneIniziale.errors.required">
                                                Campo
                                                obbligatorio</div>
                                            <div *ngIf="evoluzioneInflazione.controls.inflazioneIniziale.errors.max">
                                                Parametro
                                                fuori
                                                scala. Inserire
                                                un valore compreso tra 0 e {{valoreMax.valoreInflazione}}%</div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <label class="responsive-md-gestione-label"> Ribasso
                                            (%)
                                        </label>
                                        <input formControlName="ribasso" type="number" class="form-control inflazione"
                                            min="0"
                                            [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.ribasso.errors }"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                        <div *ngIf="submitted && evoluzioneInflazione.controls.ribasso.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="evoluzioneInflazione.controls.ribasso.errors.required">Campo
                                                obbligatorio
                                            </div>
                                            <div *ngIf="evoluzioneInflazione.controls.ribasso.errors.max">Parametro
                                                fuori
                                                scala. Inserire un valore compreso tra 0 e
                                                {{valoreMax.ribassoInflazione}}%
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <label class="responsive-md-gestione-label">Rialzo
                                            (%)
                                        </label>
                                        <input formControlName="rialzo" type="number" class="form-control" min="0"
                                            [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.rialzo.errors }"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                        <div *ngIf="submitted && evoluzioneInflazione.controls.rialzo.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="evoluzioneInflazione.controls.rialzo.errors.required">Campo
                                                obbligatorio
                                            </div>
                                            <div *ngIf="evoluzioneInflazione.controls.rialzo.errors.max">Parametro fuori
                                                scala. Inserire un valore compreso tra 0 e
                                                {{valoreMax.rialzoInflazione}}%
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2" *ngIf="idRuolo == 0">
                                        <label class="responsive-md-gestione-label"> Scarto
                                            (%) &nbsp;<i id="icon" class="fa fa-1x fa-info-circle margin-left-20"
                                                aria-hidden="true" container="body" placement="top"
                                                [ngbTooltip]="listaTips[5]?.descrizione"></i>
                                        </label>
                                        <input formControlName="scarto" type="number" class="form-control" min="0.1"
                                            [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.scarto.errors }"
                                            onkeyup="if(this.value<0){this.value= this.value *-1}" />
                                        <div *ngIf="submitted && evoluzioneInflazione.controls.scarto.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="evoluzioneInflazione.controls.scarto.errors.required">Campo
                                                obbligatorio
                                            </div>
                                            <div *ngIf="evoluzioneInflazione.controls.scarto.errors.min">
                                                Il valore deve essere maggiore di 0</div>
                                            <div *ngIf="evoluzioneInflazione.controls.scarto.errors.max">Parametro fuori
                                                scala. Inserire un valore compreso tra 0 e
                                                {{valoreMax.scartoInflazione}}%
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <label class="responsive-md-gestione-label"> Drift
                                            (%)&nbsp;<i id="icon" class="fa fa-1x fa-info-circle margin-left-20"
                                                aria-hidden="true" container="body" placement="top"
                                                [ngbTooltip]="listaTips[6]?.descrizione"></i>
                                        </label>
                                        <input formControlName="drift" type="number" class="form-control" min="0"
                                            [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.drift.errors }" />
                                        <div *ngIf="submitted && evoluzioneInflazione.controls.drift.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="evoluzioneInflazione.controls.drift.errors.required">Campo
                                                obbligatorio
                                            </div>
                                            <div *ngIf="evoluzioneInflazione.controls.drift.errors.max">Parametro fuori
                                                scala. Inserire un valore compreso tra 0 e
                                                {{valoreMax.driftInflazione}}%
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr>
                <!-- costi di business -->
                <div class="row d-flex flex-column margin-top-10">
                    <div class="col-12 d-flex align-items-center">
                        <h3>Costi di business:</h3>&nbsp;&nbsp;
                        <i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                            ngbTooltip={{listaTips[7]?.descrizione}}></i>

                    </div>
                    <div class="col-12  margin-left-20">
                        <div class="row margin-left-10">
                            <div class="col-4 col-xl-3"><label>Costi sul fatturato (%)</label>
                                <input formControlName="costiSulFatturato" type="number" class="form-control" min="0"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && f.costiSulFatturato.errors }" />
                                <div *ngIf=" submitted && f.costiSulFatturato.errors" class="invalid-feedback">
                                    <div *ngIf="f.costiSulFatturato.errors.required">Campo obbligatorio</div>
                                    <div *ngIf="f.costiSulFatturato.errors.max">Parametro fuori scala. Inserire un
                                        valore
                                        compreso
                                        tra 0 e {{valoreMax.costiSulFatturato}}% </div>

                                </div>
                            </div>
                            <div class="col-4 col-xl-3">
                                <label>Variazione su anno precedente
                                    (%)</label>
                                <input formControlName="costiFatturatoVariazioneAnnoPrecedente" type="number"
                                    class="form-control" min=0 onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && f.costiFatturatoVariazioneAnnoPrecedente.errors }" />
                                <div *ngIf=" submitted && f.costiFatturatoVariazioneAnnoPrecedente.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="f.costiFatturatoVariazioneAnnoPrecedente.errors.required">Campo
                                        obbligatorio
                                    </div>
                                    <div *ngIf="f.costiFatturatoVariazioneAnnoPrecedente.errors.max">Parametro fuori
                                        scala.
                                        Inserire
                                        un valore compreso tra 0 e {{valoreMax.costiFatturatoVariazioneAnnoPrecedente}}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <!-- pianificazione crediti/pagamenti -->
                <div class="row d-flex flex-column margin-top-10">
                    <div class="col-12 d-flex align-items-center">
                        <h3>Pianificazione incassi/pagamenti per arco temporale:</h3>&nbsp;&nbsp;
                        <i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                            ngbTooltip={{listaTips[8]?.descrizione}}></i>
                    </div>
                </div>
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">% a meno di 30 giorni</th>
                            <th scope="col">% tra 30 e 60 giorni</th>
                            <th scope="col">% tra 60 e 90 giorni</th>
                            <th scope="col">% tra 90 e 120 giorni</th>
                            <th scope="col">% tra 120 e 150 giorni</th>
                            <th scope="col">% a più di 150 giorni</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr [formGroup]="giorniIncassoCrediti"
                            [ngClass]="{'border': !sommaCentoCrediti,'border-danger': !sommaCentoCrediti}">
                            <th scope="row" class="align-middle text-center" style="width: 15%">Giorni incasso
                                crediti
                            </th>
                            <td><input formControlName="a30Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a30Giorni.errors }" />
                                <div *ngIf="submitted && giorniIncassoCrediti.controls.a30Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniIncassoCrediti.controls.a30Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="a60Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a60Giorni.errors }" />
                                <div *ngIf="submitted && giorniIncassoCrediti.controls.a60Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniIncassoCrediti.controls.a60Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="a90Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a90Giorni.errors }" />
                                <div *ngIf="submitted && giorniIncassoCrediti.controls.a90Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniIncassoCrediti.controls.a90Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="a120Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a120Giorni.errors }" />
                                <div *ngIf="submitted && giorniIncassoCrediti.controls.a120Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniIncassoCrediti.controls.a120Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="a150Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a150Giorni.errors }" />
                                <div *ngIf="submitted && giorniIncassoCrediti.controls.a150Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniIncassoCrediti.controls.a150Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="oltre150Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.oltre150Giorni.errors }" />
                                <div *ngIf="submitted && giorniIncassoCrediti.controls.oltre150Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniIncassoCrediti.controls.oltre150Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div *ngIf="!sommaCentoCrediti" class="erroreCrediti">
                        La somma delle percentuali dei crediti deve essere 100%
                    </div>
                </div>
                <table class="table table-borderless">
                    <tbody>
                        <tr [formGroup]="giorniPagamentoFornitori"
                            [ngClass]="{'border': !sommaCentoPagamenti,'border-danger': !sommaCentoPagamenti}">
                            <th scope="row" class="align-middle text-center" style="width: 15%">Giorni
                                pagamento
                                fornitori
                            </th>
                            <td><input formControlName="a30Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a30Giorni.errors}" />
                                <div *ngIf="submitted && giorniPagamentoFornitori.controls.a30Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniPagamentoFornitori.controls.a30Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="a60Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a60Giorni.errors}" />
                                <div *ngIf="submitted && giorniPagamentoFornitori.controls.a60Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniPagamentoFornitori.controls.a60Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="a90Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a90Giorni.errors}" />
                                <div *ngIf="submitted && giorniPagamentoFornitori.controls.a90Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniPagamentoFornitori.controls.a90Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="a120Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a120Giorni.errors }" />
                                <div *ngIf="submitted && giorniPagamentoFornitori.controls.a120Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniPagamentoFornitori.controls.a120Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="a150Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a150Giorni.errors }" />
                                <div *ngIf="submitted && giorniPagamentoFornitori.controls.a150Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniPagamentoFornitori.controls.a150Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>
                            <td><input formControlName="oltre150Giorni" type="number" class="form-control" min=0
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.oltre150Giorni.errors }" />
                                <div *ngIf="submitted && giorniPagamentoFornitori.controls.oltre150Giorni.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="giorniPagamentoFornitori.controls.oltre150Giorni.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div *ngIf="!sommaCentoPagamenti" class="errorePagamenti">
                        La somma delle percentuali dei pagamenti deve essere 100%
                    </div>
                </div>
            </div>
            <hr>
        </div>
    </ng-container>

</div>