import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { HttpClient } from '@angular/common/http';
import { BeanStatico } from '../condivisi/beanStatico'
import { Provincia } from '../condivisi/provincia';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, merge } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VarietaProdotto } from '../condivisi/prodotto';
import { faEdit, faTrashAlt, faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';
import { DecimalPipe } from '@angular/common';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'qs-prodotto-progetto',
  template: `
      <div [formGroup]="investimentoForm">
            <div class="row margin-0">
              <div class="col-9 col-lg-10 nopadding">
                <div class="row row-cols-3 row-cols-lg-5">

                  <div class="margin-bottom-10 col">
                    <!--<label *ngIf="nascondiBoolean === false || (booleanFirst && nascondiBoolean) || f.tipoProgetto.value == 4">Tipo investimento</label>-->
                    <label>Tipo investimento</label>
                    <select formControlName="tipoProgetto" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tipoProgetto.errors }" (change)="onChangeInvestimento();">
                      <option [ngValue]="investimento.id" *ngFor="let investimento of listaTipiInvestimento">{{investimento.descrizione}}</option>
                    </select>
                    <div *ngIf="submitted && f.tipoProgetto.errors" class="invalid-feedback" style="display:block">
                      <div *ngIf="f.tipoProgetto.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>

                  <div class="margin-bottom-10 col" *ngIf="f.tipoProgetto.value && f.tipoProgetto.value == 4">
                    <label>Specifica attrezzature</label>
                    <select formControlName="macchinari" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.macchinari.errors }">
                      <option [ngValue]="macchinari.id" *ngFor="let macchinari of listaMacchinari">{{macchinari.descrizione}}</option>
                    </select>
                    <div *ngIf="submitted && f.macchinari.errors" class="invalid-feedback">
                      <div *ngIf="f.macchinari.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>

                  <div class="margin-bottom-10 col" *ngIf="f.tipoProgetto.value && f.tipoProgetto.value == 4 && f.macchinari.value && f.macchinari.value >= 8">
                    <label>Dimensioni serra (mq)</label>
                    <input min="0" type="number" formControlName="mqSerra" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mqSerra.errors }" />
                    <div *ngIf="submitted && f.mqSerra.errors" class="invalid-feedback">
                      <div *ngIf="f.mqSerra.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>

                  <div class="margin-bottom-10 col" *ngIf="f.tipoProgetto.value && f.tipoProgetto.value != 4">
                    <!--<label *ngIf="nascondiBoolean === false || (booleanFirst && nascondiBoolean)">Provincia</label>-->
                    <label>Provincia</label>
                    <input type="text" class="form-control" formControlName="provincia" [ngbTypeahead]="searchProvincia"
                      (focus)="focusProvincia$.next($any($event).target.value)" (click)="clickProvincia$.next($any($event).target.value)"
                      #instanceProvincia="ngbTypeahead" [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]='false'
                      [ngClass]="{ 'is-invalid': submitted && f.provincia.errors }"
                    />
                    <div *ngIf="submitted && f.provincia.errors" class="invalid-feedback">
                      <div *ngIf="f.provincia.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>

                  <div class="margin-bottom-10 col" *ngIf="f.tipoProgetto.value && f.tipoProgetto.value != 4">
                    <!--<label *ngIf="nascondiBoolean === false || (booleanFirst && nascondiBoolean)">Comune</label>-->
                    <label>Comune</label>
                    <input type="text" formControlName="comune" class="form-control" [ngbTypeahead]="searchComune"
                      (focus)="focusComune$.next($any($event).target.value)" (click)="clickComune$.next($any($event).target.value)"
                      #instanceComune="ngbTypeahead" [inputFormatter]="formatterComune" [resultFormatter]="formatterComune"
                      [ngClass]="{ 'is-invalid': submitted && f.comune.errors }" [editable]='false'
                    />
                    <div *ngIf="submitted && f.comune.errors" class="invalid-feedback">
                      <div *ngIf="f.comune.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>

                  <!-- div vuoti per fare in modo che l'importo sia sempre alla stessa altezza
                  <div class="margin-bottom-10 col hidden-xs-sm-md" *ngIf="f.tipoProgetto.value && f.tipoProgetto.value == 4 && !(f.macchinari.value && f.macchinari.value >= 8)"></div>

                  <div class="margin-bottom-10 col hidden-xs-sm-md" *ngIf="f.tipoProgetto.value && f.tipoProgetto.value == 4 && !(f.macchinari.value && f.macchinari.value >= 8)"></div>
                  -->
                  <div class="margin-bottom-10 col" *ngIf="f.tipoProgetto.value && f.tipoProgetto.value != 4">
                    <!--<label *ngIf="nascondiBoolean === false || (booleanFirst && nascondiBoolean)">Ettari da acquistare</label>-->
                    <label>
                      Ettari da acquistare
                    </label>
                    <!--<fa-icon class="cursor pull-right" [icon]="faInfo" placement="top" ngbTooltip="Il numero di ettari è legato alla coltivazione, per inserire più coltivazioni aggiungere una nuova riga dopo aver completato quella corrente"></fa-icon>-->

                    <input min="0" type="number" formControlName="ettari" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ettari.errors }" (change)="onChangeEttari()"/>
                    <div *ngIf="submitted && f.ettari.errors" class="invalid-feedback">
                      <div *ngIf="f.ettari.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>

                  <div class="margin-bottom-10 col" *ngIf="f.tipoProgetto.value">
                    <!--<label *ngIf="nascondiBoolean === false || (booleanFirst && nascondiBoolean) || f.tipoProgetto.value == 4">Importo</label>-->
                    <label>Importo</label>
                    <input type="text" formControlName="importo" class="form-control" [ngClass]="[ submitted && f.importo.errors ? 'is-invalid' : '']" currencyInput #importo/>
                    <div *ngIf="submitted && f.importo.errors" class="invalid-feedback">
                      <div *ngIf="f.importo.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>

                </div>

                <div class="col-12 font-bold nopadding" *ngIf="f.tipoProgetto.value && (f.macchinari.value && f.macchinari.value >= 8) && nascondiBoolean === false">Luogo di produzione</div>

                <div class="row row-cols-3 row-cols-lg-5"  *ngIf="f.tipoProgetto.value && (f.macchinari.value && f.macchinari.value >= 8) && nascondiBoolean === false">

                  <div class="margin-bottom-10 col">
                    <!--<label *ngIf="nascondiBoolean === false || (booleanFirst && nascondiBoolean)">Provincia</label>-->
                    <label>Provincia</label>
                    <input type="text" class="form-control" formControlName="provincia" [ngbTypeahead]="searchProvincia"
                      (focus)="focusProvincia$.next($any($event).target.value)" (click)="clickProvincia$.next($any($event).target.value)"
                      #instanceProvincia="ngbTypeahead" [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]='false'
                      [ngClass]="{ 'is-invalid': submitted && f.provincia.errors }"
                    />
                    <div *ngIf="submitted && f.provincia.errors" class="invalid-feedback">
                      <div *ngIf="f.provincia.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>

                  <div class="margin-bottom-10 col">
                    <!--<label *ngIf="nascondiBoolean === false || (booleanFirst && nascondiBoolean)">Comune</label>-->
                    <label>Comune</label>
                    <input type="text" formControlName="comune" class="form-control" [ngbTypeahead]="searchComune"
                      (focus)="focusComune$.next($any($event).target.value)" (click)="clickComune$.next($any($event).target.value)"
                      #instanceComune="ngbTypeahead" [inputFormatter]="formatterComune" [resultFormatter]="formatterComune"
                      [ngClass]="{ 'is-invalid': submitted && f.comune.errors }" [editable]='false'
                    />
                    <div *ngIf="submitted && f.comune.errors" class="invalid-feedback">
                      <div *ngIf="f.comune.errors.required">Campo obbligatorio</div>
                    </div>
                  </div>
                </div>

                <div class="row row-cols-3 row-cols-lg-5"  *ngIf="f.tipoProgetto.value && f.tipoProgetto.value == 3 && nascondiBoolean === false">

                  <div class="margin-bottom-10 col">
                    <label>Affitto terreno (per ha)</label>
                    <input min="0" type="number" formControlName="affittoTerreno" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.affittoTerreno.errors }" />
                    <div *ngIf="submitted && f.affittoTerreno.errors" class="invalid-feedback">
                      <div *ngIf="f.affittoTerreno.errors.required">Campo obbligatorio</div>
                    </div>
                    <div class="form-check">
                      <input type="checkbox" formControlName="isPropietario" id="isPropietario" class="form-check-input" (change)="onChangeIsPropietario()"/>
                      <label for="isPropietario" class="form-check-label">Terreno di propriet&agrave;?</label>
                    </div>
                  </div>
                </div>

                <div class="col-12 font-bold nopadding" *ngIf="f.tipoProgetto.value && (f.tipoProgetto.value != 4 || (f.macchinari.value && f.macchinari.value >= 8)) && nascondiBoolean === false">Tipologia coltura</div>

                <div class="col-12 nopadding" [hidden]="!((f.tipoProgetto.value && (f.tipoProgetto.value != 4 || (f.macchinari.value && f.macchinari.value >= 8)) && nascondiBoolean === false) && coltureDivisioneArray.length > 0)">

                  <div  *ngFor="let item of coltureDivisioneArray; let i = index">
                    <qs-colture-aggiuntive [formGroup]="item" [provincia]="f.provincia.value && f.provincia.value.id ? f.provincia.value : ''" [idTipoProgetto]="f.tipoProgetto.value ? f.tipoProgetto.value : ''" [idProdotto]="0" [submitted]="submitted" (eventoOutput)="onChangeEttariColtura()" (caricamentoOutput)="updateForm()"></qs-colture-aggiuntive>

                  </div> 

                </div>

              </div>

              <div class="col-3 col-lg-2 nopadding">

                <div class="row margin-0">
                  <div class="margin-bottom-10 col-6 text-center nopadding" *ngIf="f.tipoProgetto.value && nascondiBoolean && !modificaBoolean">
                    <div class="col-12 nopadding" *ngIf="booleanFirst">Modifica</div>
                    <fa-icon class="pointer" style="font-size: 30px" [icon]="faEdit" (click)="modificaInvestimento()"></fa-icon>
                  </div>

                  <div class="margin-bottom-10 col-6 text-center nopadding" *ngIf="f.tipoProgetto.value && nascondiBoolean === false && modificaBoolean">
                    <div class="col-12 nopadding">Salva</div>
                    <fa-icon class="pointer" style="font-size: 30px" [icon]="faCheck" (click)="salvaInvestimento()"></fa-icon>
                  </div>

                  <div class="margin-bottom-10 col-6 text-center nopadding" *ngIf="f.tipoProgetto.value && nascondiBoolean || (nascondiBoolean === false && modificaBoolean)">
                    <div class="col-12 nopadding" *ngIf="booleanFirst  || modificaBoolean">Elimina</div>
                    <fa-icon class="pointer" style="font-size: 30px" [icon]="faTrashAlt" (click)="rimuoviInvestimento()"></fa-icon>
                  </div>
                </div>

              </div>
            </div>



            <div class="row" *ngIf="f.tipoProgetto.value && nascondiBoolean === false && !modificaBoolean">
              <div class="col-12 text-center margin-bottom-20">
                <button class="btn btn-primary mr-1" type="button" (click)="confermaInvestimento()">Conferma</button>
                <button class="btn btn-secondary" style="margin-left: 5px" type="button" (click)="annullaInvestimento()">Annulla</button>
              </div>
            </div>

            <div class="row" *ngIf="(nascondiBoolean || (nascondiBoolean === false && modificaBoolean)) && booleanLast">
              <div class="col-12 text-center margin-bottom-20">
                <button class="btn btn-primary mr-1 pull-right" type="button" (click)="aggiungiInvestimento()">Aggiungi nuovo investimento</button>
              </div>
            </div>
      </div>


  `,
  styles: [`
  label{
    display: inline-block;
    margin-bottom: 0rem !important;
  }
  `]
})
export class ProdottoProgettoComponent implements OnInit {
  @Input('formGroup') public investimentoForm!: UntypedFormGroup;
  @Input('booleanFirst') booleanFirst!: boolean;
  @Input('booleanLast') booleanLast!: boolean;
  @Output() addProdottoProgetto = new EventEmitter();
  @Output() removeProdottoProgetto = new EventEmitter();
  @ViewChild('importo') importo!: ElementRef;


  submitted: boolean = false;
  //isAziendaEsistente = false;
  listaProvince: Provincia[] = [];
  listaTipiInvestimento: BeanStatico[] = [];
  listaTerritori: BeanStatico[] = [];
  listaMacchinari: BeanStatico[] = [];
  tempIdComune: number = -1;
  idPrecedenteProvincia: number = -1;
  nascondiBoolean: boolean = false;
  modificaBoolean: boolean = false;
  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faCheck = faCheck;
  faInfo = faInfo;

  constructor(private datiFinanziamento: DatiQuestionarioService, private httpClient: HttpClient, private formBuilder: UntypedFormBuilder, private decimalPipe: DecimalPipe) { }

  ngOnInit() {

    if (this.f.comune.value && this.f.comune.value.id) {

      this.tempIdComune = this.f.comune.value.id;
    }


    this.httpClient.get<Provincia[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProvince').subscribe(data => {
      this.listaProvince = data;
      if (this.f.provincia.value != '') {
        this.listaProvince.forEach(element => {
          if (element.id == this.f.provincia.value.id) this.f.provincia.setValue(element);
        });
      }
    });

    this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipoInvestimentoBySettore', { settore: "Agricoltura" }).subscribe(data => {
      this.listaTipiInvestimento = [];
      data.forEach(elemento => {
        this.listaTipiInvestimento.push({ id: elemento.id, descrizione: elemento.descrizione });
      });
    });

    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getMacchinariAttrezzature').subscribe(data => {
      this.listaMacchinari = data;
    });







    this.datiFinanziamento.currentSubmitted.subscribe(submitted => {
      this.submitted = submitted
    })

    //ascolta il change della provincia e agisce di conseguenza
    this.investimentoForm.controls.provincia.valueChanges.subscribe(selectedValue => {
      if (typeof selectedValue === 'undefined' || (selectedValue && this.idPrecedenteProvincia != selectedValue.id)) {
        this.listaTerritori = [];
        if (this.tempIdComune == -1) {
          this.f.comune.setValue('');
          this.coltureDivisioneFormArray.clear();
          this.coltureDivisioneArray.push(this.initColtura());
          this.coltureDivisioneArray[0].controls.categoria.disable();
          this.f.ettari.setValue('');
          this.onChangeEttari();
        }
        if (selectedValue) {
          this.idPrecedenteProvincia = this.f.provincia.value.id;

          this.f.comune.enable();
          this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTerritoriByIdProvincia', selectedValue.id).subscribe(data => {
            data.forEach(element => {
              const elementoLista = { id: element.id, descrizione: element.comune };
              this.listaTerritori.push(elementoLista)
              if (element.id == this.tempIdComune) {
                this.f.comune.setValue(elementoLista);
                this.f.comune.disable();
                this.tempIdComune = -1;
              }
            });


          });
          this.coltureDivisioneArray[0].controls.categoria.enable();

        }
        else {
          this.idPrecedenteProvincia = -1;
          this.f.comune.disable();

        }
      }
      this.investimentoForm.updateValueAndValidity();

    })

    if ((this.investimentoForm.valid || this.investimentoForm.disabled) && this.f.tipoProgetto.value != '') {
      this.nascondiBoolean = true;
      this.modificaBoolean = false;
      this.investimentoForm.disable();
      for (let i = 0; i < this.coltureDivisioneArray.length; i++) {
        this.coltureDivisioneArray[i].controls.contratto.enable();
        this.coltureDivisioneArray[i].controls.qualitaProduzione.enable();
        let coltureRotazioneArray = <UntypedFormGroup[]>(<UntypedFormArray> this.coltureDivisioneArray[i].controls.coltureRotazioneArray).controls;
        for (let j = 0; j < coltureRotazioneArray.length; j++) {
          coltureRotazioneArray[j].controls.contratto.enable();
          coltureRotazioneArray[j].controls.qualitaProduzione.enable();

        }
      }
    }



  }

  ngAfterViewInit() {
    if (this.f.tipoProgetto.value != '' && typeof this.importo !== 'undefined' && this.f.importo.value != '') {
      this.importo.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.importo.value), '1.0-0', 'it') + ' €';
    }


  }

  // convenience getter for easy access to form fields
  get f() { return this.investimentoForm.controls; };
  get coltureDivisioneFormArray(): UntypedFormArray { return <UntypedFormArray> this.investimentoForm.controls.coltureDivisioneArray; };
  get coltureDivisioneArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.coltureDivisioneFormArray.controls; };
  formatterBeanStatico = (bean: BeanStatico) => bean.descrizione;

  //funzioni dei typeahead di provincia, comune e specifica prodotto
  formatter = (provincia: Provincia) => provincia.provincia;

  @ViewChild('instanceProvincia', { static: true }) instanceProvincia!: NgbTypeahead;
  focusProvincia$ = new Subject<string>();
  clickProvincia$ = new Subject<string>();


  searchProvincia = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickProvincia$.pipe(filter(() => this.instanceProvincia && !this.instanceProvincia.isPopupOpen()));
    const inputFocus$ = this.focusProvincia$;


    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.listaProvince
        : this.listaProvince.filter(provincia => new RegExp(term, 'mi').test(provincia.provincia))))
    );
  }

  formatterComune = (comune: BeanStatico) => comune.descrizione;

  @ViewChild('instanceComune', { static: true }) instanceComune!: NgbTypeahead;
  focusComune$ = new Subject<string>();
  clickComune$ = new Subject<string>();

  searchComune = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickComune$.pipe(filter(() => this.instanceComune && !this.instanceComune.isPopupOpen()));
    const inputFocus$ = this.focusComune$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.listaTerritori
        : this.listaTerritori.filter(comune => new RegExp(term, 'mi').test(comune.descrizione))))
    );

  }

  onChangeIsPropietario() {
    if (this.f.isPropietario.value == true) {
      this.f.affittoTerreno.setValue('');
      this.f.affittoTerreno.disable();
      this.f.affittoTerreno.clearValidators();
    }
    if (this.f.isPropietario.value == false) {
      this.f.affittoTerreno.enable();
      this.f.affittoTerreno.setValidators(Validators.required);

    }
    this.f.affittoTerreno.updateValueAndValidity();
  }

  onChangeInvestimento() {

    if (this.f.tipoProgetto.value == 1) {
      //this.f.etaPiante.setValidators(Validators.required);
    }
    else {
      this.coltureDivisioneArray[0].controls.etaPiante.setValue('');
      this.coltureDivisioneArray[0].controls.etaPiante.clearValidators();
    }

    if (this.f.tipoProgetto.value == 3) {
      this.f.affittoTerreno.setValidators(Validators.required);
    }
    else {
      this.f.affittoTerreno.setValue('');
      this.f.affittoTerreno.clearValidators();
    }

    if (this.f.tipoProgetto.value != 4) {
      this.f.provincia.setValidators(Validators.required);
      this.f.comune.setValidators(Validators.required);
      this.f.ettari.setValidators(Validators.required);
      this.coltureDivisioneArray[0].controls.categoria.setValidators(Validators.required);
      this.coltureDivisioneArray[0].controls.prodotto.setValidators(Validators.required);
      this.coltureDivisioneArray[0].controls.varieta.setValidators(Validators.required);
      //this.f.contratto.setValidators(Validators.required);
      this.f.macchinari.setValue('');
      this.f.macchinari.clearValidators();
    }
    else {
      this.coltureDivisioneArray[0].controls.contratto.setValue('');
      this.coltureDivisioneArray[0].controls.qualitaProduzione.setValue('');
      this.f.provincia.setValue('');
      this.f.comune.setValue('');
      this.f.ettari.setValue('');
      this.coltureDivisioneArray[0].controls.categoria.setValue('');
      this.coltureDivisioneArray[0].controls.prodotto.setValue('');
      this.coltureDivisioneArray[0].controls.varieta.setValue('');
      this.coltureDivisioneArray[0].controls.contratto.clearValidators();
      this.coltureDivisioneArray[0].controls.qualitaProduzione.clearValidators();
      this.f.provincia.clearValidators();
      this.f.comune.clearValidators();
      this.f.ettari.clearValidators();
      this.coltureDivisioneArray[0].controls.categoria.clearValidators();
      this.coltureDivisioneArray[0].controls.prodotto.clearValidators();
      this.coltureDivisioneArray[0].controls.varieta.clearValidators();
      this.f.macchinari.setValidators(Validators.required);
    }

    this.f.importo.setValidators(Validators.required);
    this.f.macchinari.updateValueAndValidity();
    this.coltureDivisioneArray[0].controls.contratto.updateValueAndValidity();
    this.coltureDivisioneArray[0].controls.qualitaProduzione.updateValueAndValidity();
    this.f.importo.updateValueAndValidity();
    this.coltureDivisioneArray[0].controls.etaPiante.updateValueAndValidity();
    this.f.affittoTerreno.updateValueAndValidity();
    this.f.provincia.updateValueAndValidity();
    this.f.comune.updateValueAndValidity();
    this.f.ettari.updateValueAndValidity();
    this.coltureDivisioneArray[0].controls.categoria.updateValueAndValidity();
    this.coltureDivisioneArray[0].controls.prodotto.updateValueAndValidity();
    this.coltureDivisioneArray[0].controls.varieta.updateValueAndValidity();
  }

  confermaInvestimento() {
    this.submitted = true;
    this.investimentoForm.controls.coltureDivisioneArray.updateValueAndValidity();
    if (this.investimentoForm.valid) {
      this.nascondiBoolean = true;
      this.modificaBoolean = false;
      this.submitted = false;
      this.investimentoForm.disable();
      for (let i = 0; i < this.coltureDivisioneArray.length; i++) {
        this.coltureDivisioneArray[i].controls.contratto.enable();
        this.coltureDivisioneArray[i].controls.qualitaProduzione.enable();
        let coltureRotazioneArray = <UntypedFormGroup[]>(<UntypedFormArray> this.coltureDivisioneArray[i].controls.coltureRotazioneArray).controls;
        for (let j = 0; j < coltureRotazioneArray.length; j++) {
          coltureRotazioneArray[j].controls.contratto.enable();
          coltureRotazioneArray[j].controls.qualitaProduzione.enable();

        }
      }
    }
  }

  modificaInvestimento() {
    this.nascondiBoolean = false;
    this.modificaBoolean = true;
    this.investimentoForm.enable();
    for (let i = 0; i < this.coltureDivisioneArray.length; i++) {
      let coltureRotazioneArray = <UntypedFormGroup[]>(<UntypedFormArray> this.coltureDivisioneArray[i].controls.coltureRotazioneArray).controls;
      for (let j = 0; j < coltureRotazioneArray.length; j++) {
        coltureRotazioneArray[j].valid && coltureRotazioneArray[j].controls.varieta.value ? coltureRotazioneArray[j].disable() : '';
      }
    }
  }

  salvaInvestimento() {
    this.submitted = true;
    this.f.coltureDivisioneArray.updateValueAndValidity();
    if (this.investimentoForm.valid) {
      this.nascondiBoolean = true;
      this.modificaBoolean = false;
      this.submitted = false;
      this.investimentoForm.disable();
      for (let i = 0; i < this.coltureDivisioneArray.length; i++) {
        this.coltureDivisioneArray[i].controls.contratto.enable();
        this.coltureDivisioneArray[i].controls.qualitaProduzione.enable();
        let coltureRotazioneArray = <UntypedFormGroup[]>(<UntypedFormArray> this.coltureDivisioneArray[i].controls.coltureRotazioneArray).controls;
        for (let j = 0; j < coltureRotazioneArray.length; j++) {
          coltureRotazioneArray[j].controls.contratto.enable();
          coltureRotazioneArray[j].controls.qualitaProduzione.enable();

        }
      }
    }
  }

  rimuoviInvestimento() {
    this.removeProdottoProgetto.next(null);
  }

  aggiungiInvestimento() {
    this.addProdottoProgetto.next(null);
  }

  initColtura() {
    return this.formBuilder.group({
      bio: [false],
      categoria: ['', Validators.required],
      prodotto: [{ value: '', disabled: true }, Validators.required],
      varieta: [{ value: '', disabled: true }, Validators.required],
      produzione: [''],
      ettariColtura: [''],
      etaPiante: [''],
      contratto: [''],
      qualitaProduzione: [''],
      coltureRotazioneArray: this.formBuilder.array([])
    })

  }

  annullaInvestimento() {
    this.investimentoForm.reset(this.formBuilder.group({
      tipoProgetto: [''],
      comune: [''],
      provincia: [''],
      importo: [''],
      macchinari: [''],
      mqSerra: [''],
      //Colture aggiuntive per la spartizione del terreno
      coltureDivisioneArray: this.formBuilder.array([
        this.formBuilder.group({
          bio: [false],
          categoria: [{ value: '', disabled: true }, Validators.required],
          prodotto: [{ value: '', disabled: true }, Validators.required],
          varieta: [{ value: '', disabled: true }, Validators.required],
          produzione: [''],
          ettariColtura: [{ value: '', disabled: true }, Validators.required],
          etaPiante: [''],
          contratto: [''],
          qualitaProduzione: [''],
          coltureRotazioneArray: this.formBuilder.array([])
        })
      ]),
    }).value);

    this.idPrecedenteProvincia = -1;

    this.coltureDivisioneArray[0].controls.categoria.disable();
    this.coltureDivisioneArray[0].controls.prodotto.disable();
    this.coltureDivisioneArray[0].controls.varieta.disable();

    this.f.comune.disable();

    this.f.provincia.clearValidators();
    this.f.provincia.updateValueAndValidity();
    this.f.comune.clearValidators();
    this.f.comune.updateValueAndValidity();
    this.f.ettari.clearValidators();
    this.f.ettari.updateValueAndValidity();
    this.f.importo.clearValidators();
    this.f.importo.updateValueAndValidity();
    this.f.affittoTerreno.clearValidators();
    this.f.affittoTerreno.updateValueAndValidity();

    this.investimentoForm.updateValueAndValidity();

  }

  onChangeEttariColtura() {
    if (this.coltureDivisioneArray[0].controls.ettariColtura.value >= this.f.ettari.value) {
      this.coltureDivisioneArray[0].controls.ettariColtura.setValue(this.f.ettari.value);
      for (let i = this.coltureDivisioneArray.length - 1; i > 0; i--) {
        this.coltureDivisioneFormArray.removeAt(i);
      }
    }
    else {
      let ettariDivisioneTotali = 0;
      let vuoto = false;

      for (let i = this.coltureDivisioneArray.length - 1; i > 0; i--) {
        if (this.coltureDivisioneArray[i].controls.ettariColtura.value == 0) {
          this.coltureDivisioneFormArray.removeAt(i);

          if (this.coltureDivisioneArray.length == 1) {
            this.coltureDivisioneArray[0].controls.ettariColtura.setValue(this.f.ettari.value);

          }

        }
      }

      for (let i = 0; i < this.coltureDivisioneArray.length; i++) {
        ettariDivisioneTotali += this.coltureDivisioneArray[i].controls.ettariColtura.value ? this.coltureDivisioneArray[i].controls.ettariColtura.value : 0;
        vuoto = this.coltureDivisioneArray[i].controls.ettariColtura.value != '' ? vuoto : true;
        if (ettariDivisioneTotali > this.f.ettari.value) {
          ettariDivisioneTotali -= this.coltureDivisioneArray[i].controls.ettariColtura.value;
          for (let j = this.coltureDivisioneArray.length - 1; j >= i; j--) {
            this.coltureDivisioneFormArray.removeAt(j);
          }
          break;
        }
      }

      if (ettariDivisioneTotali < this.f.ettari.value && vuoto === false) {
        this.coltureDivisioneArray.push(this.initColtura());
        this.coltureDivisioneArray[this.coltureDivisioneArray.length - 1].controls.ettariColtura.setValue(this.f.ettari.value - ettariDivisioneTotali);
      }
    }
    this.investimentoForm.updateValueAndValidity();

  }

  onChangeEttari() {
    if (this.f.ettari.value) {
      this.coltureDivisioneArray[0].controls.ettariColtura.enable();
      if (this.coltureDivisioneArray[0].controls.ettariColtura.value == '') {
        this.coltureDivisioneArray[0].controls.ettariColtura.setValue(this.f.ettari.value);
      }
      this.onChangeEttariColtura();
    } else {
      this.coltureDivisioneArray[0].controls.ettariColtura.setValue('');
      this.coltureDivisioneArray[0].controls.ettariColtura.disable();
    }
    this.investimentoForm.updateValueAndValidity();

  }

  updateForm() {
    this.investimentoForm.controls.coltureDivisioneArray.updateValueAndValidity();
    this.investimentoForm.updateValueAndValidity();
  }
}
