<div class="container-fluid">
    <div class="row d-flex justify-content-start">
        <div class="col-3">
            <h3 class="text-left">Partite Iva attive : {{numeroPivaUtilizzate}}/{{maxPiva}} <span class=" pointer"
                    (click)="mostraAziende = !mostraAziende">
                    <fa-icon style="font-size: 25px; vertical-align: middle;"
                        [icon]="!mostraAziende? faCaretUp : faCaretDown">
                    </fa-icon>
                </span></h3>
        </div>
    </div>
    <app-lista-richiedenti *ngIf="mostraAziende==true" (selectedAzienda)="modificaRichiedente($event)"
        (mostraAziende)="mostraLista($event)">
    </app-lista-richiedenti>
    <hr>
    <div class="col-12 d-flex justify-content-end" style="margin: 5px 0;">
        <button class="btn btn-success" (click)="submit()" [disabled]="submitDisabled">Salva</button>
        <button class="btn btn-secondary" style="margin-left: 5px" type="button" (click)="onReset()">
            Annulla
        </button>
    </div>
    <div class="card nopadding" [formGroup]="anagraficaForm">
        <div class="card-header text-center font-big">Censimento anagrafico azienda osservata</div>
        <div class="card-body">
            <div class="row">
                <div class="col-4 margin-bottom-10">
                    <label for="partitaIva">Partita IVA*</label>
                    <input type="text" formControlName="partitaIva" placeholder="partita IVA" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.partitaIva.errors }" (focusout)="checkPartitaIva()" />
                    <div *ngIf="submitted && f.partitaIva.errors" class="invalid-feedback">
                        <div *ngIf="f.partitaIva.errors.required">Campo obbligatorio</div>
                        <div *ngIf="f.partitaIva.errors.maxlength ||f.partitaIva.errors.minlength">Lunghezza partita IVA
                            non
                            corrispode</div>
                    </div>
                </div>
                <div class="col-3 margin-bottom-10">
                    <label for="nomeAzienda">Nome azienda</label>
                    <input type="text" formControlName="nomeAzienda" class="form-control" placeholder="Nome Azienda"
                        [ngClass]="{ 'is-invalid': submitted && f.nomeAzienda.errors }" />
                    <div *ngIf="submitted && f.nomeAzienda.errors" class="invalid-feedback">
                        <div *ngIf="f.nomeAzienda.errors.required">Campo obbligatorio</div>
                    </div>
                </div>

                <div class="col-3 margin-bottom-10">
                    <label for="tipoAzienda">Forma Giuridica*</label>
                    <select formControlName="tipoAzienda" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.tipoAzienda.errors }">
                        <option [ngValue]="tipoAzienda" *ngFor="let tipoAzienda of listaTipiAzienda">
                            {{tipoAzienda.descrizione}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.tipoAzienda.errors" class="invalid-feedback">
                        <div *ngIf="f.tipoAzienda.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-2 margin-bottom-10">
                    <label for="annoCostituzione">Anno di costituzione*</label>
                    <select formControlName="annoCostituzione" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.annoCostituzione.errors }">
                        <option [ngValue]="annoCostituzione" *ngFor="let annoCostituzione of listaAnni">
                            {{annoCostituzione}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.annoCostituzione.errors" class="invalid-feedback">
                        <div *ngIf="f.annoCostituzione.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-lg-4 margin-bottom-10">
                    <label for="sedeLegale">Sede Legale*</label>
                    <input type="text" formControlName="sedeLegale" placeholder="Indirizzo" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.sedeLegale.errors }" />
                    <div *ngIf="submitted && f.sedeLegale.errors" class="invalid-feedback">
                        <div *ngIf="f.sedeLegale.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 margin-bottom-10">
                    <label for="provincia">Provincia*</label>
                    <select formControlName="provincia" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.provincia.errors }">

                        <option [ngValue]="provincia" *ngFor="let provincia of listaProvince">{{provincia.provincia}} -
                            ({{provincia.sigla}})</option>
                    </select>
                    <div *ngIf="submitted && f.provincia.errors" class="invalid-feedback">
                        <div *ngIf="f.provincia.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 margin-bottom-10">
                    <label for="comune">Comune*

                    </label>
                    <select type="text" formControlName="comune" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.comune.errors }">
                        <option [ngValue]="comune" *ngFor="let comune of listaComuni">{{comune.descrizione}}</option>
                    </select>
                    <div *ngIf="submitted && f.comune.errors" class="invalid-feedback">
                        <div *ngIf="f.comune.errors.required">Campo obbligatorio</div>
                    </div>
                </div>

                <div class="col-2 col-lg-2 margin-bottom-10">
                    <label for="CAP">CAP*</label>
                    <input type="text" formControlName="cap" placeholder="CAP" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.cap.errors }" />
                    <div *ngIf="submitted && f.cap.errors" class="invalid-feedback">
                        <div *ngIf="f.cap.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2 col-lg-2 margin-bottom-10">
                    <label for="telefonoRichiedente">Telefono*</label>
                    <input type="text" formControlName="telefonoRichiedente" class="form-control" placeholder="Telefono"
                        [ngClass]="{ 'is-invalid': submitted && f.telefonoRichiedente.errors }" />
                    <div *ngIf="submitted && f.telefonoRichiedente.errors" class="invalid-feedback">
                        <div *ngIf="f.telefonoRichiedente.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-4 col-lg-4 margin-bottom-10">
                    <label for="emailRichiedente">Email*</label>
                    <input type="text" formControlName="emailRichiedente" class="form-control" placeholder="Email"
                        [ngClass]="{ 'is-invalid': submitted && f.emailRichiedente.errors }" />
                    <div *ngIf="submitted && f.emailRichiedente.errors" class="invalid-feedback">
                        <div *ngIf="f.emailRichiedente.errors.required">Campo obbligatorio</div>
                        <div *ngIf="f.emailRichiedente.errors.email">Inserire una mail valida</div>
                    </div>
                </div>
                <!-- <div class="col-3 col-xl-4 d-flex align-items-center justify-content-center margin-left-20">
                    <label>Azienda di nuova formazione <i style="margin-top:-10px" id="icon"
                            class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                            [ngbTooltip]="listaTips[3]?.descrizione"></i></label>
                    <input class="margin-left-20" type="checkbox" formControlName="startUp">
                </div> -->
            </div>
            <div class="row">
                <h4>Codice ATECO prevalente*</h4>
                <div class=" col-2 margin-bottom-10">
                    <label>Sezione</label>
                    <select formControlName="atecoPrimarioSezione" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.atecoPrimarioSezione.errors }">
                        <option [ngValue]="atecoPrimarioSezione" style="width: 200px"
                            *ngFor="let atecoPrimarioSezione of listaAtecoPrimarioSezione">
                            {{atecoPrimarioSezione.descrizione}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.atecoPrimarioSezione.errors" class="invalid-feedback">
                        <div *ngIf="f.atecoPrimarioSezione.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 col-xl-3 margin-bottom-10">
                    <label>Divisione</label>
                    <select formControlName="atecoPrimarioDivisione" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.atecoPrimarioDivisione.errors}">
                        <option [ngValue]="atecoPrimarioDivisione"
                            *ngFor="let atecoPrimarioDivisione of listaAtecoPrimarioDivisione">
                            {{atecoPrimarioDivisione.descrizione}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.atecoPrimarioDivisione.errors" class="invalid-feedback">
                        <div *ngIf="f.atecoPrimarioDivisione.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 col-xl-3 margin-bottom-10">
                    <label>Classe</label>
                    <select formControlName="atecoPrimarioClasse" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.atecoPrimarioClasse.errors }">
                        <option [ngValue]="atecoPrimarioClasse"
                            *ngFor="let atecoPrimarioClasse of listaAtecoPrimarioClasse">
                            {{atecoPrimarioClasse.descrizione}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.atecoPrimarioClasse.errors" class="invalid-feedback">
                        <div *ngIf="f.atecoPrimarioClasse.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 col-xl-3 margin-bottom-10">
                    <label>Sottocategoria</label>
                    <select formControlName="atecoPrimarioSottocategoria" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.atecoPrimarioSottocategoria.errors }">
                        <option [ngValue]="atecoPrimarioSottocategoria"
                            *ngFor="let atecoPrimarioSottocategoria of listaAtecoPrimarioSottocategoria">
                            {{atecoPrimarioSottocategoria.descrizione}}
                        </option>
                    </select>
                    <!--                     <div *ngIf="submitted && f.atecoPrimarioSottocategoria.errors" class="invalid-feedback">
                        <div *ngIf="f.atecoPrimarioSottocategoria.errors.required">Campo obbligatorio</div>
                    </div> -->
                </div>
                <div class="col-1 margin-bottom-10 d-flex justify-content-center align-items-end">
                    <button class=" d-flex justify-content-center btn fa fa-plus fa-lg pointer" aria-hidden="true"
                        type="button" (click)="addAteco()" [disabled]="addAtecoDisabled" placement="top"
                        [ngbTooltip]="listaTips[2]?.descrizione">
                    </button>
                </div>
            </div>
            <ng-container formArrayName="atecoSecondariArray">
                <ng-container *ngFor="let atecoForm of atecoSecondariArray.controls;let i=index">
                    <app-censimento-ateco-secondari [formGroup]="atecoForm" [i]="i"
                        [atecoSecondario]="atecoSecondariTemp[i]" (selectedAteco)="eliminaAteco($event)"
                        [submitted]="submitted">
                    </app-censimento-ateco-secondari>
                </ng-container>
            </ng-container>

        </div>
    </div>
</div>