import { TokenStorageService } from './../_services/token-storage.service';
import { Router } from '@angular/router';
import { BilancioIntestatarioService } from './../_services/bilancio_intestatario.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, } from 'rxjs/operators';
import { NgbTypeahead, NgbActiveModal, NgbModal,} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { AnagraficaIntestatarioService } from '../_services/anagrafica_intestatario.service';
import { AziendaService } from './../_services/azienda.service';
@Component({
  selector: 'app-intestatario',
  template: `
    <div class="container">
      <!--h3 class="text-center mb-1">Intestatario Bilancio</!--h3 -->

      <div class="card mt-4">
        <div class="sfondo-header">
          <h3 class="text-center mb-1">Intestatario Bilancio</h3>
        </div>
        <form
          #f="ngForm"
          class="p-4"
          name="form"
          (ngSubmit)="onSubmit()"
          novalidate
        >
          <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-2 mt-2">
              <label for="typeahead-basic" class="control-label text-right">
                <strong>Ricerca Intestatario:</strong>
              </label>
            </div>
            <div class="col-sm-5">
              <input
                id="typeahead-basic"
                type="text"
                class="btn dropdown-toggle border text-left max"
                [(ngModel)]="forms.model"
                name="model"
                [ngbTypeahead]="search"
                (focus)="focus$.next($any($event).target.value)"
                (click)="click$.next($any($event).target.value)"
                (selectItem)="selectItem($event)"
                [resultFormatter]="formatter"
                [inputFormatter]="formatter"
                #instance="ngbTypeahead"
                ngbTooltip="Inserisci Intestatario"
                placeholder="Inizia a digitare 2-3 lettere"
              />
            </div>
            <div class="col-sm-3">
              <button type="button" class="btn btn-success" (click)="newIntestatario()">
                {{ !this.cliente ? 'Nuovo Intestatario' : 'Reset Ricerca' }}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="row mt-3" *ngIf="this.cliente">
        <div class="col-sm">
          <li>
            {{ this.cliente }}&nbsp;&nbsp;
            <button type="button" class="btn btn-success" (click)="updateIntestatario()">
              <i class="fa fa-pencil fa-fw" aria-hidden="true"></i>Aggiorna Anagrafica
            </button>
          </li>
        </div>
      </div>
      <app-spinner *ngIf="!bilancio"></app-spinner>
      <ng-container *ngIf="bilancio.length">
        <div class="sfondo-header mt-3">
          <strong>Elenco</strong>
          <span class="badge rounded-pill bg-secondary float-sm-end"
            >Totale Bilanci : {{ bilancio.length }}</span
          >
        </div>
        <table
          class="table table-striped table-sm table-responsive table-bordered"
          *ngIf="bilancio.length"
          style="overflow: scroll;"
        >
          <thead>
            <tr>
              <th class="text-center" scope="col"></th>
              <th class="text-center" scope="col">Utimo Esercizio</th>
              <th class="text-center" scope="col">Penultimo Esercizio</th>
              <th class="text-center" scope="col">Data Inserimento</th>
              <th class="text-center" scope="col">Data modifica</th>
              <th class="text-center" scope="col"></th>
            </tr>
          </thead>
          <tbody
            *ngFor="let record of bilancio; let i = index"
            style="height: 10px !important; overflow: scroll;"
          >
            <tr>
              <td style="text-align: center;">
                <i class="fa fa-balance-scale fa-fw" aria-hidden="true"></i>
                - {{ i + 1 }}
              </td>
              <td>{{ record.anno_ultimo_esercizio }}</td>
              <td>{{ record.anno_penultimo_esercizio }}</td>
              <td>{{ record.data_inserimento | date: 'dd/MM/yyyy' }}</td>
              <td>{{ record.data_ultima_modifica | date: 'dd/MM/yyyy' }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-dark"
                  (click)="selBilancio(record)"
                >
                  <i class="fa fa-eye fa-fw" aria-hidden="true"></i>&nbsp;Visualizza
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-sm">
            <button
              *ngIf="bilancio.length"
              type="button"
              class="btn btn-dark"
              (click)="nuovoBilancio()"
            >
              <i class="fa fa-plus fa-fw" aria-hidden="true"></i>Nuovo
            </button>
          </div>
        </div>
      </ng-container>
      <!-- modale -->
      <ng-template #content let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Dati di Bilancio</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>
            <li>
              La <em>{{ this.cliente | uppercase }}</em> non ha bilanci, vuoi
              inserirne uno?
            </li>
          </p>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="c('Close click')"
            >
              Chiudi
            </button>
            <button
              type="button"
              ngbAutofocus
              class="btn btn-success"
              (click)="entraBilancio(c)"
            >
              Inserisci
            </button>
            <!--
              <button
                type="button"
                class="btn btn-outline-dark"
                (click)="c('Close click')"
              >
                Close
              </button>
              -->
          </div>
        </div>
      </ng-template>

      <!-- Modale Intestatario -->
      <ng-template #insert let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title text-center" id="modal-basic-title">
            {{ this.funzione }} Intestatario Bilancio
          </h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onInsert()"
            #f="ngForm" novalidate>
            <div class="row form-group mt-3">
              <div class="col-sm-4">
                <label for="nome">Ragione Sociale :</label>
              </div>
              <div class="col-sm-7 ps-1">
                <input type="text" class="form-control cap" name="ragione_sociale"
                  [(ngModel)]="form.ragione_sociale"
                  text-transform: capitalize required
                  minlength="4" maxlength="50" #ragione_sociale="ngModel"/>
                <div class="alert-danger" *ngIf="ragione_sociale.errors && f.submitted">
                  <div *ngIf="ragione_sociale.errors.required">
                    La Ragione Sociale è richiesta
                  </div>
                  <div *ngIf="ragione_sociale.errors.minlength">
                    La Ragione Sociale deve contenere almeno 4 caratteri
                  </div>
                  <div *ngIf="ragione_sociale.errors.maxlength">
                    La Ragione Sociale deve contenere al massimo 50 caratteri
                  </div>
                </div>
              </div>
              <div class="col-sm-1"></div>
            </div>

            <div class="row form-group mt-2">
              <div class="col-sm-4">
                <label for="cognome">Tipo Azienda :</label>
              </div>
              <div class="col-sm-7 ps-1">
                <select class="btn dropdown-toggle border" name="id_tipo_azienda_mcc"
                style="width: 270px"
                  [(ngModel)]="form.id_tipo_azienda_mcc" required #id_tipo_azienda_mcc="ngModel">
                  <option [ngValue]="undefined" disabled selected>seleziona il tipo azienda</option>
                  <option *ngFor="let n of tipi" [ngValue]="n.id">
                    {{ n.descrizione }}
                  </option>
                </select>
                <div
                  class="alert-danger" *ngIf="id_tipo_azienda_mcc.errors && f.submitted" >
                  <div *ngIf="id_tipo_azienda_mcc.errors.required">
                    Il Tipo Azienda è richiesto
                  </div>
                </div>
              </div>
              <div class="col-sm-1"></div>
            </div>

            <div class="row form-group mt-2">
              <div class="col-sm-4">
                <label for="piva">Partita Iva :</label>
              </div>
              <div class="col-sm-7 ps-1">
                <input
                  type="text"
                  class="form-control"
                  name="partita_iva"
                  [(ngModel)]="form.partita_iva"
                  required
                  minlength="16"
                  maxlength="16"
                  #partita_iva="ngModel"
                />
                <div
                  class="alert-danger"
                  *ngIf="partita_iva.errors && f.submitted"
                >
                  <div *ngIf="partita_iva.errors.required">
                    La Partita Iva è richiesta
                  </div>
                  <div *ngIf="partita_iva.errors.minlength">
                    La Partita Iva deve contenere almeno 16 caratteri
                  </div>
                  <div *ngIf="partita_iva.errors.maxlength">
                    La Partita Iva deve contenere al massimo 16 caratteri
                  </div>
                </div>
              </div>
              <div class="col-sm-1"></div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-sm-4">
                <label for="email">Sede Legale :</label>
              </div>
              <div class="col-sm-7 ps-1">
                <input
                  type="email"
                  class="form-control"
                  name="sede_legale"
                  [(ngModel)]="form.sede_legale"
                  #sede_legale="ngModel"
                />
              </div>
              <div class="col-sm-1"></div>
            </div>
            <div class="row form-group mt-3">
              <div class="col-sm-4"></div>
              <div class="col-sm-8 ps-1">
                <button type="submit" class="btn btn-primary me-2 rounded">
                  {{ this.funzione == 'Modifica' ? 'Modifica' : 'Registra' }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary me-2 rounded"
                  (click)="c('Close click')"
                >
                  Annulla
                </button>
              </div>
            </div>

            <div
              class="alert alert-warning"
              *ngIf="f.submitted && isSignUpFailed"
            >
              Accesso fallito!<br />{{ errorMessage }}
            </div>
          </form>
          <div class="modal-footer">
            <p
              class="alert alert-success col-md-12 text-center"
              [style.color]="'red'"
              [hidden]="!errorMessage"
            >
              {{ errorMessage }}
            </p>
            <!--
              <button
                type="button"
                class="btn btn-outline-dark"
                (click)="c('Close click')"
              >
                Close
              </button>
                -->
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .cap {
        text-transform: capitalize;
      }
      td,
      th {
        text-align: center !important;
      }

      .max {
        width: 100%;
      }
      .modal-xl .modal-lg {
        max-width: 90%;
      }
      .my-class .modal-dialog {
        max-width: 80%;
        width: 80%;
      }
      .grigio {
        background: #808080;
      }
    `,
  ],
})
export class IntestatarioComponent implements OnInit {
  @ViewChild('instance', { static: true }) instance?: NgbTypeahead;
  @ViewChild('content') content: any;
  @ViewChild('insert') insert: any;
  public model?: Intestatario;
  intestatario: Intestatario[] = [];
  intestatarioIns: Intestatario[] = [];
  id_intestatario = 0;
  id_tipo_azienda = 0;
  bilancio: Bilancio[] = [];

  forms: any = {};
  errorMessage = '';
  cliente = '';
  isSignUpFailed = false;
  isSuccessful = false;
  funzione = '';
  //form: any = {ragione_sociale: null, id_tipo_azienda: null, partita_iva: null, sede_legale: null};
  form: Intestatario = {};
  tipi: any[] = [];
  /*
  tipi = [
    { id: 1, descrizione: 'Società di Capitali', sp: 'scapitali' },
    {
      id: 2,
      descrizione: 'Società di Pers. Ditte Ind. in Cont. Ord.',
      sp: 'spcord',
    },
    { id: 3, descrizione: 'Ditta Invividuale', sp: 'spcord' },
  ];
  */
  constructor(
    private anagraficaService: AnagraficaIntestatarioService,
    private bilancioService: BilancioIntestatarioService,
    private aziendaService: AziendaService,
    private modalService: NgbModal,
    private router: Router,
    private tokenService: TokenStorageService
  ) {}

  ngOnInit(): void {
    this.listaUtenti();
    this.listaAziende();
  }
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  formatter = (x: { ragione_sociale: string }) =>
    x.ragione_sociale;
  search: OperatorFunction<string, readonly Intestatario[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? this.intestatario
          : this.intestatario.filter(
              (v) =>
                v.ragione_sociale!.toLowerCase().indexOf(term.toLowerCase()) >
                -1
            )
        ).slice(0, 10)
      )
    );
  };
  listaAziende(): void {
    this.aziendaService.getAllAziende().subscribe(
      (risultato) => {this.tipi = JSON.parse(risultato).risultato;},(error) => {console.log(error);}
    );
  }
  listaUtenti(): void {
    this.anagraficaService.getAllUsers(this.tokenService.getUser().username).subscribe(
      (risultato) => {
        this.intestatario = JSON.parse(risultato).risultato;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  listaBilanci(): void {
    this.bilancioService.readDatiBilancio(this.id_intestatario).subscribe(
      (risultato) => {
        this.bilancio = JSON.parse(risultato).risultato;
        if (!this.bilancio.length) {
          this.openModal();
        }
      },
      (error) => {
        if (error.status === 404) {
          this.openModal();
        }

        console.log(error);
      }
    );
  }
  selectItem(item: any): void {
    this.form = item.item;
    this.bilancio = [];
    this.cliente = item.item.ragione_sociale;
    this.id_intestatario = item.item.id;
    this.id_tipo_azienda = item.item.id_tipo_azienda_mcc;
    this.listaBilanci();
  }
  entraBilancio(c: any): void {
    c('close modal');
    this.bilancioService.changeParamBilancio({
      id: this.id_intestatario,
      nome: this.cliente,
      azienda: this.id_tipo_azienda,
      bilancio: 'nuovo',
    });
    this.router.navigate(['/bilancio']);
  }
  newIntestatario(): void {
    if (this.cliente) {
      this.forms.model = null;
      this.cliente = '';
      this.bilancio = [];
    } else {
      this.forms.model = null; //resetta select intestatario
      this.form = {};
      this.isSignUpFailed = false;
      this.isSuccessful = false;
      this.bilancio = [];
      this.cliente = '';
      this.errorMessage = '';
      this.funzione = 'Inserimento';
      this.modalService.open(this.insert, { centered: true });
    }
  }
  nuovoBilancio(): void {
    this.bilancio = [];
    this.bilancioService.changeParamBilancio({
      id: this.id_intestatario,
      nome: this.cliente,
      azienda: this.id_tipo_azienda,
      bilancio: 'nuovo',
    });
    this.router.navigate(['/bilancio']);
  }
  selBilancio(elemento: Bilancio): void {
    this.bilancioService.changeParamBilancio({
      id: this.id_intestatario,
      nome: this.cliente,
      azienda: this.id_tipo_azienda,
      data: elemento.data_inserimento,
      bilancio: 'modifica',
    });
    this.router.navigate(['/bilancio']);
  }
  updateIntestatario(): void {
    console.log('Form --> ', this.form);
    this.forms.model = null;
    this.cliente = '';
    this.isSignUpFailed = false;
    this.isSuccessful = false;
    this.bilancio = [];
    this.errorMessage = '';
    this.funzione = 'Modifica';
    this.modalService.open(this.insert, { centered: true });
  }
  onSubmit(): void {}
  onInsert(): void {
    if (this.funzione == 'Inserimento') this.inserimentoDb();
    else if (this.funzione == 'Modifica') this.modificaDb();
  }
  modificaDb(): void {
    this.anagraficaService.updateUsers(this.form).subscribe(
      (data) => {
        this.isSuccessful = true;
        this.isSignUpFailed = false;
        this.intestatarioIns = [];
        this.errorMessage = 'Anagrafica modificata con successo';
      },
      (err) => {
        console.log(err.error);
        this.errorMessage = err.error;
        this.isSignUpFailed = true;
      }
    );
  }
  inserimentoDb(): void {
    this.form.utente = this.tokenService.getUser().username;
    this.anagraficaService.insertUsers(this.form).subscribe(
      (data) => {
        this.isSuccessful = true;
        this.isSignUpFailed = false;
        this.intestatarioIns = [];
        this.intestatarioIns = JSON.parse(data).risultato;
        this.cliente = this.intestatarioIns[0].ragione_sociale!;
        this.id_intestatario = this.intestatarioIns[0].id!;
        this.id_tipo_azienda = this.intestatarioIns[0].id_tipo_azienda_mcc!;
        this.errorMessage = 'Anagrafica Inserita con successo';
        this.bilancioService.changeParamBilancio({
          id: this.id_intestatario,
          nome: this.cliente,
          azienda: this.id_tipo_azienda,
          bilancio: 'nuovo',
        });
        this.router.navigate(['/bilancio']);
      },
      (err) => {
        console.log(err.error);
        this.errorMessage = err.error;
        this.isSignUpFailed = true;
      }
    );
  }
  openModal(): void {
    this.modalService.dismissAll();
    this.modalService.open(this.content, { centered: true });
  }
}
interface Bilancio {
  id?: number;
  anno_ultimo_esercizio: string;
  anno_penultimo_esercizio: string;
  data_inserimento: Date;
}
interface Intestatario {
  id?: number;
  id_tipo_azienda_mcc?: number;
  ragione_sociale?: string;
  partita_iva?: string;
  sede_legale?: string;
  utente?: string;
}
