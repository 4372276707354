import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

const ApiUrl = environment.apiUrlNode + '/api/pratiche/';
const idBusinessPlan = '0';

@Injectable({
  providedIn: 'root'
})

export class PraticheService {

  private paramPratiche: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentParamPratiche: Observable<any> = this.paramPratiche.asObservable();

  constructor(private http: HttpClient) { }

  getAllPratiche(id: String): Observable<any> {
    return this.http.get(ApiUrl + 'allDatiPratiche/' + id, { responseType: 'text' });
  }

  getBusinessPlan(id_business_plan: number): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/loadBP', id_business_plan)
  }

  eliminaPratica(id_business_plan: number): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/deleteBP', id_business_plan)
  }

  changeParamPratiche(valore: Object) {
    this.paramPratiche.next(valore)/* sostituisco a param il valore next */
  }

  checkRisultati(request: any): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/checkResults', request)
  }

  public savePratica(id: string): void {
    window.sessionStorage.removeItem(idBusinessPlan);
    window.sessionStorage.setItem(idBusinessPlan, id);
  }

  public getPraticaSessione(): string | null {
    return window.sessionStorage.getItem(idBusinessPlan);
  }

}
