<div class="row d-flex justify-content-center" id="container-top-pot">
    <div class="col-2 d-flex justify-content-center">
        <app-doughnut-chart  style="align-self: center;"
        [labelBlack]="true"
        [zoomActive]="true"
        [viewportDevice]='this.device[1]'
        [valore_tip_dscr]="valore_tip_dscr" 
        [name]="name_potenziometro_one " 
        [lancetta]='lancetta_one' 
        [rotate_principale]="rotate_dscr" 
        [tips_type]="tips_types[0]">
        </app-doughnut-chart>  
    </div>
    <div class="col-2 d-flex justify-content-center">
        <app-doughnut-chart  style="align-self: center;"
        [labelBlack]="true"
        [zoomActive]="true"
        [viewportDevice]='this.device[1]' 
        [viewportDevice]="2"
        [valore_tip_debitoria]="valore_tip_debitoria"
        [name]="name_potenziometro_two"
        [lancetta]='lancetta_two'
        [rotate_debitoria]="rotate_debitoria" 
        [tips_type]="tips_types[1]">
        </app-doughnut-chart>
    </div>
    <div class="col-2 d-flex justify-content-center">
        <app-doughnut-chart  style="align-self: center;"
        [labelBlack]="true"
        [zoomActive]="true"
        [viewportDevice]='this.device[1]'
        [valore_tip_mcc]="valore_tip_mcc" 
        [name]="name_potenziometro_three"
        [lancetta]='lancetta_three'
        [rotate_mcc]="rotate_mcc" 
        [tips_type]="tips_types[2]">
        </app-doughnut-chart>
    </div>
   <!-- <div class="col-2 d-flex justify-content-center">
        <app-doughnut-chart  style="align-self: center;"
        [labelBlack]="true"
        [zoomActive]="true"
        [viewportDevice]='this.device[1]'
        [valore_tip_garanzia]="valore_tip_garanzia"
        [name]="name_potenziometro_four" 
        [lancetta]='lancetta_four' 
        [rotate_garanzia]="rotate_garanzia" 
        [tips_type]="tips_types[3]">
        </app-doughnut-chart>  
    </div>
    <div class="col-2 d-flex justify-content-center">
        <app-doughnut-chart style="align-self: center;"
        [labelBlack]="true"
        [zoomActive]="true"
        [viewportDevice]='this.device[1]'
        [valore_tip_dscr]="valore_tip_dscr" 
        [name]="name_potenziometro_one "
        [lancetta]='lancetta_one' 
        [rotate_principale]="rotate_dscr" 
        [tips_type]="tips_types[0]">
        </app-doughnut-chart>  
    </div>
    <div class="col-2 d-flex justify-content-center">
        <app-doughnut-chart  style="align-self: center;"
        [labelBlack]="true"
        [zoomActive]="true"
        [viewportDevice]='this.device[1]'
        [valore_tip_dscr]="valore_tip_dscr" 
        [name]="name_potenziometro_one " 
        [lancetta]='lancetta_one' 
        [rotate_principale]="rotate_dscr" 
        [tips_type]="tips_types[0]">
        </app-doughnut-chart>  
    </div>-->
</div>