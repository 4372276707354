<div id="mySidenav-right" class="sidenav-right"
    style="border-top-right-radius:0 !important;border-bottom-right-radius:0 !important;">
    <!--href="javascript:void(0)"-->
    <a class="closebtn-right arrow" id="close-right" (click)="navRight()">
        <mat-icon class="material-icons-outlined arrow-left-button">arrow_left</mat-icon>
    </a>
    <hr style="margin: 0  10px;">
    <div class="container-fluid">
        <div class="row">
            <!--START COMULUM WITH timeline-navmenu-->
            <!--<div class="col-3">
                <div class="timeline">
                    <div *ngFor="let pallocchio of utenteAdminView" class="container">
                      
                    </div>
                </div>
            </div>-->
            <!--END COMULUM WITH timeline-navmenu-->
            <!--START COMULUM WITH navigation section-->
            <div class="col-12" style="padding-left: 0; padding-right: 0;">
                <div class="section-tab-nav" class='ancora-sezione' *ngFor="let view of utenteAdminView; let i = index"
                    (click)="( i == 0 ) ? this.sendShowTable(view,true) : this.sendShowTable(view,false)">

                    <a *ngIf="i==0" id="ancora-sezione-dscr" class='ancora-sezione-font'>
                        {{view}}
                    </a>

                    <a *ngIf="i!=0" class='ancora-sezione-font'>
                        {{view}}
                    </a>

                </div>
                <!--END COMULUM WITH navigation section-->
            </div>
        </div>
    </div>
    <hr style="margin: 0  10px;">
</div>