import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SintesiTopSection } from '../interface/sintesi-top-section';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SplashpageService {

  baseUrl = environment.apiUrlQuestionario + '/fasGoAlgo';

  constructor(private http: HttpClient) { }

  postAsIsData(idBP: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}` + `/risultatiSplashPageTopTable`, { idBusinessPlan: idBP });
  }

  postAsIsDataTable(idBP: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}` + `/risultatiSplashPageIsAsTable`, { idBusinessPlan: idBP });
  }

  postEndStrokeDataTable(idBP: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}` + `/risultatiSplashPageEndStrokeTable`, { idBusinessPlan: idBP });
  }



}
