import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntypedFormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProdottoLineaBusiness } from 'src/app/condivisi/ProdottoLineaBusiness';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { TokenStorageService } from './../../../_services/token-storage.service';
import { Subscription } from 'rxjs';
import { LineaBusiness } from 'src/app/condivisi/linea-business';
import { Provincia } from './../../../condivisi/provincia';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { VarietaProdotto } from 'src/app/condivisi/prodotto';
import { ProdottiDropdown } from 'src/app/condivisi/prodotti-dropdown';

@Component({
  selector: 'app-linea-business',
  templateUrl: './linea-business.component.html',
  styleUrls: ['./linea-business.component.css']
})
export class LineaBusinessComponent implements OnInit {
  @Input() lineaBusiness: LineaBusiness;
  @Input() submitted: boolean
  notSaved: boolean = true;

  idProdottiTempArray: number[] = [];
  idCurrentUser: number;
  prodottiForm: UntypedFormGroup;
  initialValues: any;
  valoreMax: any = {};
  count: number = 1;
  resetCountSub: Subscription;
  dropsDown: ProdottiDropdown = {
    listaProvince: [],
    listaProdotti: [],
    listaVarieta: [],
    listaMetodiColtivazione: [],
  }
  constructor(
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private tokenStorageService: TokenStorageService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,

  ) {
    this.prodottiForm = this.formBuilder.group({
      prodotti: this.formBuilder.array([])
    })
  }

  get f() { return this.prodottiForm.controls; }
  get prodottiArray(): UntypedFormArray { return <UntypedFormArray> this.prodottiForm.controls.prodotti }
  get prodottiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.prodottiArray.controls }

  ngOnDestroy(): void {
    this.resetCountSub.unsubscribe()
  }

  /* ngAfterViewChecked() {
    if (document.querySelectorAll('#ConfigurazioneDistintaBaseProdotti [tabindex="-1"][aria-posinset="1"]')[0] != undefined && this.count == 0) {
      const btn = document.querySelectorAll('#ConfigurazioneDistintaBaseProdotti [tabindex="-1"][aria-posinset="1"]')[0] as HTMLElement;
      btn?.addEventListener('click', () => { });
      btn?.click();
      const btn2 = document.querySelectorAll('#ConfigurazioneDistintaBaseProdotti [tabindex="-1"][aria-posinset="2"]')[0] as HTMLElement;
      btn2?.addEventListener('click', () => { });
      btn2?.click();
      this.count++;
    };
  } */
  ngOnInit(): void {
    this.resetCountSub = this.configurazioneAmbienteService._resetCountDistintaBase$.subscribe(
      resetCount => {
        this.count = resetCount;
      })


    this.idCurrentUser = this.tokenStorageService.getUser().idUtente;
    this.configurazioneAmbienteService.getValoriMax('distinta base').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMax[element.nomeCampo] = element.valoreMax;
        }
      });
      //console.log(this.valoreMax);

    })
    if (this.lineaBusiness.idTipologiaAtt == 1) {
      //faccio le get su prodotti.service e salvo le liste complete sul service
      this.configurazioneAmbienteService.getProdottiDropDown(this.lineaBusiness.idCategoriaProd, this.lineaBusiness.idRegione).subscribe(obj => {
        this.dropsDown.listaProvince = obj.listaProvince;
        this.dropsDown.listaProdotti = [];
        this.dropsDown.listaVarieta = []
        this.dropsDown.listaMetodiColtivazione = obj.listaMetodiColtivazione;
        obj.listaProdotti.forEach(prodotto => {
          this.dropsDown.listaProdotti.push({
            id: prodotto.id,
            descrizione: prodotto.descrizione
          })
        })
        obj.listaVarieta.forEach(element => {
          const elementoLista = {
            id: element.id,
            descrizione: element.descrizione,
            latenza: element.anniLatenzaProduttiva,
            idProdotto: element.idProdotto
          };
          this.dropsDown.listaVarieta.push(elementoLista)
        })
        this.onLoadProdotti();
      })
    } else {
      this.onLoadProdotti();
    }
  }

  onLoadProdotti() { //tornerà null se non sono nell'agricoltura
    if (this.lineaBusiness) {
      this.configurazioneAmbienteService.getProdotti(this.lineaBusiness.id, this.idCurrentUser).subscribe(response => {
        this.idProdottiTempArray = [];
        this.prodottiArray.clear();
        for (let i = 0; i < response.length; i++) {
          //confrontare gli idprovincia e varietà delle liste conb quelli arrivati da on load
          if (response[i].error == null) {
            let colturaTemp: BeanStatico = null
            let varietaTemp: VarietaProdotto = null
            let provinciaTemp: Provincia = null
            let metodoColtTemp: BeanStatico = null
            if (this.dropsDown != null) {
              this.dropsDown.listaProdotti.forEach(coltura => {
                if (coltura.id == response[i].idColtura)
                  colturaTemp = coltura
              });
              this.dropsDown.listaProvince.forEach(provincia => {
                if (provincia.id == response[i].idProvincia)
                  provinciaTemp = provincia
              });
              this.dropsDown.listaVarieta.forEach(varieta => {
                if (varieta.id == response[i].idVarieta)
                  varietaTemp = varieta
              });
              this.dropsDown.listaMetodiColtivazione.forEach(metodo => {
                if (metodo.id == response[i].idMetodoColtivazione)
                  metodoColtTemp = metodo
              });
            }
            this.idProdottiTempArray.push(response[i].id);
            const prodottoForm = this.formBuilder.group({
              nomeProdotto: [response[i].descrizione, this.lineaBusiness.idTipologiaAtt != 1 ? Validators.required : null],
              presenzaFidelizzazione: [response[i].presenzaFidelizzazione],//, Validators.required
              fidelizzazioneAnnuale: [response[i].tassoFidelizzazione],//[Validators.required, Validators.max(this.valoreMax.fidelizzazione)]
              coltura: [colturaTemp, this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
              provincia: [provinciaTemp, this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
              metodoColtivazione: [metodoColtTemp, this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
              varieta: [varietaTemp, this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
              etaPiante: [response[i].etaPiante, this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
              ettariColtura: [response[i].ettariColtura, this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
            })
            prodottoForm.controls.nomeProdotto.updateValueAndValidity();
            prodottoForm.controls.presenzaFidelizzazione.updateValueAndValidity();
            prodottoForm.controls.fidelizzazioneAnnuale.updateValueAndValidity();
            this.prodottiFormArray.push(prodottoForm)
          }
          this.prodottiArray.updateValueAndValidity();
        }
      })
    }
  }

  addProdotto() {
    this.prodottiForm.markAsDirty()
    const prodottoForm = this.formBuilder.group({
      nomeProdotto: ['', this.lineaBusiness.idTipologiaAtt != 1 ? Validators.required : null],
      presenzaFidelizzazione: [''],
      fidelizzazioneAnnuale: [''],
      coltura: ['', this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
      provincia: ['', this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
      varieta: [{ value: '', disabled: true }, this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
      etaPiante: ['', this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
      ettariColtura: ['', this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
      metodoColtivazione: ['', this.lineaBusiness.idTipologiaAtt == 1 ? Validators.required : null],
    })
    this.idProdottiTempArray.push(0)
    prodottoForm.controls.nomeProdotto.updateValueAndValidity();
    prodottoForm.controls.presenzaFidelizzazione.updateValueAndValidity();
    prodottoForm.controls.fidelizzazioneAnnuale.updateValueAndValidity();

    this.prodottiFormArray.push(prodottoForm)
    this.prodottiArray.updateValueAndValidity();
  }

  /* onChangeFidelizzazione(par: any) {
    if (par.presenzaFidelizzazione == true) {
      this.prodottiFormArray[par.index].controls.fidelizzazioneAnnuale.setValidators([Validators.required, Validators.max(this.valoreMax.fidelizzazione)]);
    } else {
      this.prodottiFormArray[par.index].controls.fidelizzazioneAnnuale.clearValidators();
    }
    this.prodottiFormArray[par.index].controls.fidelizzazioneAnnuale.updateValueAndValidity();
  } */

  eliminaProdotto(index: number) {
    /* da vedere se eliminare subito il prodotto o eliminarlo solo al salva */
    this.prodottiForm.markAsDirty()
    if (this.idProdottiTempArray[index] != 0) {
      //this.prodottiForm.markAsDirty()
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "elimina",
        title: "Eliminare il prodotto " + this.prodottiFormArray[index].controls.nomeProdotto.value + " ?",
        description: "NOTA BENE: Se si elimina il prodotto sarà necessario ricompilare i Business Plan associati.",
        undoButtonText: "Annulla",
        actionButtonText: "Elimina",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.configurazioneAmbienteService.deleteProdotti(this.idProdottiTempArray[index], this.lineaBusiness.id).subscribe(response => {
            this.idProdottiTempArray.splice(index, 1);
            this.prodottiArray.removeAt(index);
            const modalRef = this.modalService.open(ModalcontentComponent, {
              backdrop: 'static',
              backdropClass: 'customBackdrop',
            });
            modalRef.componentInstance.modalPar = {
              name: "messaggioelimina",
              description: response ? "Prodotto eliminato con successo" : "Errore",
              actionButtonText: "",
              undoButtonText: "Ok",
            }
          })
        }
      })
    } else {
      this.idProdottiTempArray.splice(index, 1);
      this.prodottiArray.removeAt(index);
    }
  }

  isFormDirty() {
    for (let i = 0; i < this.prodottiFormArray.length; i++) {
      if (this.prodottiFormArray[i].dirty) {
        this.prodottiForm.markAsDirty()
      }
    }
    this.submitted = this.prodottiForm.dirty
    return this.submitted
  }

  annulla() {
    /* devo fare il ciclo perche il DirtyformDirty si vede solo al livello prodottiFormArray */
    if (this.isFormDirty()) {
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Così facendo, tutte le modifiche non salvate in " + this.lineaBusiness.nomeLineaBusiness + " andranno persi.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.prodottiArray.clear();
          this.idProdottiTempArray = [];
          this.submitted = false;
          this.onLoadProdotti()
        }
      })
    }
  }
  onSubmit() {
    if (this.isFormDirty()) {
      this.prodottiArray.updateValueAndValidity();
      this.prodottiForm.updateValueAndValidity();

      if (this.prodottiArray.length > 0) {
        if (this.prodottiForm.valid) {
          const modalRef = this.modalService.open(ModalcontentComponent);
          modalRef.componentInstance.modalPar = {
            name: "salvamodifiche",
            description: "Salvare i prodotti inseriti nella linea di business " + this.lineaBusiness.nomeLineaBusiness + "?",
            actionButtonText: "Salva",
            undoButtonText: "Indietro",
          }
          modalRef.result.then((r) => {
            if (r) {
              let prodotti: ProdottoLineaBusiness[] = [];
              for (let i = 0; i < this.prodottiFormArray.length; i++) {
                let prodotto: ProdottoLineaBusiness = {
                  idUtente: this.idCurrentUser,
                  id: this.idProdottiTempArray[i],
                  idLineeBusiness: this.lineaBusiness.id,
                  descrizione: this.lineaBusiness.idTipologiaAtt != 1 ? this.prodottiFormArray[i].controls.nomeProdotto.value : '',
                  presenzaFidelizzazione: this.prodottiFormArray[i].controls.presenzaFidelizzazione.value,
                  tassoFidelizzazione: this.prodottiFormArray[i].controls.fidelizzazioneAnnuale.value ? this.prodottiFormArray[i].controls.fidelizzazioneAnnuale.value : 0,
                  idColtura: this.lineaBusiness.idTipologiaAtt == 1 ? this.prodottiFormArray[i].controls.coltura.value.id : 0,
                  idProvincia: this.lineaBusiness.idTipologiaAtt == 1 ? this.prodottiFormArray[i].controls.provincia.value.id : 0,
                  idVarieta: this.lineaBusiness.idTipologiaAtt == 1 ? this.prodottiFormArray[i].controls.varieta.value.id : 0,
                  idMetodoColtivazione: this.lineaBusiness.idTipologiaAtt == 1 ? this.prodottiFormArray[i].controls.metodoColtivazione.value.id : 0,
                  etaPiante: this.prodottiFormArray[i].controls.etaPiante.value,
                  ettariColtura: this.prodottiFormArray[i].controls.ettariColtura.value,
                }
                prodotti.push(prodotto);
              }
              //console.log(prodotti)
              this.configurazioneAmbienteService.saveProdotti(prodotti).subscribe(response => {
                const modalRef = this.modalService.open(ModalcontentComponent);
                modalRef.componentInstance.modalPar = {
                  name: response ? "messaggiosalva" : "messaggioerrore",
                  description: response ? "Salvato con successo" : "Errore",
                  actionButtonText: "",
                  undoButtonText: "Ok",
                }

                modalRef.result.then(r => {
                  if (!r) {
                    /* ricaricare i prodotti della linea */
                    //this.configurazioneAmbienteService.LoadProdottiDirty(false)
                    this.onLoadProdotti();
                    this.notSaved = false;
                  }
                })
              })
            } else {
              this.submitted = false
            }
          })
        }
      } else {
        const modalRef = this.modalService.open(ModalcontentComponent);
        modalRef.componentInstance.modalPar = {
          name: "errore",
          title: "Errore",
          description: "Salvare almeno un prodotto nella linea di business " + this.lineaBusiness.nomeLineaBusiness + ". Se non ci sono prodotti da censire puoi inserire il nome della linea business di appartenenza.",
          undoButtonText: "Ok",
        }
      }
    }
  }
}
