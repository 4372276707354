<div *ngIf="isFirst" class="container_bench">
   <div class="row">
    


    <div style="text-align: left !important; " class="col-sm-4">
        
    </div>

    <div style="text-align: left !important;" class="col-sm-2 ">
    
    </div>



    <div class="col-sm-4 ">

        <div class="years">{{valoriCella.anno}}</div> 
       
    </div> 

    <div class="col-sm-2 ">
        Trend
       
    </div>


</div> 

    <div style="padding: 1px;" class="row" *ngFor="let variable of listaVariabili; index as i;">

        <div style="text-align: left !important; " class="col-sm-4">
            {{variable.variabileLabel}} 
        </div>

        <div style="text-align: left !important;" class="col-sm-2 ">
        
        </div>



        <div class="col-sm-4 nopadding">

            {{ getType(valoriCella[variable.variabile]) === 'number' ?  (valoriCella[variable.variabile] | number:'1.0-2':'it') + (variable.variabileLabel == 'Ricavi' || variable.variabileLabel == 'Ebitda' ? ' €' : '')  : 'N.d.' }}  
           
        </div> 

        <div class="col-sm-2 " [ngStyle]="{'color':  getType(valoriCella['trend'+variable.variabile]) === 'number' ? ((valoriCella['trend'+variable.variabile]>=0) ? 'rgb(19, 194, 112)' : 'rgb(255, 0,0)') : 'rgb(0,0,0)'}">
            {{ getType(valoriCella['trend'+variable.variabile]) === 'number' ?  (valoriCella['trend'+variable.variabile] | number:'1.0-1':'it') +"%" : 'N.d.' }}  
           
        </div>
         

    </div>
</div>

<div *ngIf="!isFirst && !isLast"   class="container_bench">
    <div class="row">

        <div class="col-sm-8 ">

            <div class="years">{{valoriCella.anno}}</div> 
           
        </div> 
    
        <div class="col-sm-4 ">
            Trend
           
        </div>
       
    </div> 
    <div style="padding: 1px;" class="row" *ngFor="let variable of listaVariabili; index as i;">



        <div class="col-sm-8 nopadding">

            {{ getType(valoriCella[variable.variabile]) === 'number' ? (valoriCella[variable.variabile] | number:'1.0-2':'it') + (variable.variabileLabel == 'Ricavi' || variable.variabileLabel == 'Ebitda' ? ' €' : '') : 'N.d.' }}  
           
        </div> 

        <div class="col-sm-4 "  [ngStyle]="{'color':  getType(valoriCella['trend'+variable.variabile]) === 'number' ? ((valoriCella['trend'+variable.variabile]>=0) ? 'rgb(19, 194, 112)' : 'rgb(255, 0,0)') : 'rgb(0,0,0)'}">
            {{ getType(valoriCella['trend'+variable.variabile]) === 'number' ?  (valoriCella['trend'+variable.variabile] | number:'1.0-1':'it') +"%" : 'N.d.' }}  
           
        </div>
         
        
      

    </div>
</div>

<div *ngIf="isLast"   class="container_bench">
    <div class="row">

        <div class="col-sm-12 ">

            <div class="years">{{valoriCella.anno}}</div> 
           
        </div> 
    
      
       
    </div> 
    <div style="padding: 1px;" class="row" *ngFor="let variable of listaVariabili; index as i;">



        <div class="col-sm-8 nopadding"  style="margin-left: -0.57vw">

            {{ getType(valoriCella[variable.variabile]) === 'number' ?  (valoriCella[variable.variabile] | number:'1.0-2':'it') + (variable.variabileLabel == 'Ricavi' || variable.variabileLabel == 'Ebitda' ? ' €' : '') : 'N.d.' }}  
           
        </div> 

        <div class="col-sm-4 ">
           
        </div>
         
        
      

    </div>
</div>