import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCaretDown, faCaretUp, faInfo } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../loading.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { BilancioIntestatarioService } from '../_services/bilancio_intestatario.service';
import { Router } from '@angular/router';
import { BeanStatico } from '../condivisi/beanStatico';
import { ModelloUniversaleService } from '../_services/modello-universale.service';
import { Subscription, identity } from 'rxjs';
//Costi fissi annuali botteghelle 10900649.36
@Component({
  selector: 'app-modello-commerciale',
  templateUrl: './modello-commerciale.component.html',
  styleUrls: ['./modello-commerciale.component.css']
})
export class ModelloCommercialeComponent implements OnInit {


  coloreProgetto = '#92a8d1';
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  formModelloCommerciale: UntypedFormGroup;
  listaTipiAzienda: BeanStatico[] = [];
  initialValues: any;
  isLoading: boolean = false;
  submitted = false;
  submitParBilancio = false;
  annoCorrente = (new Date()).getFullYear();
  boolRisultati = false;
  boolBP = true;
  boolIndicatori = true;
  boolCE = false;
  boolCF = false;
  boolSP = false;
  boolDeb = false;
  boolIndiceBil = false;
  boolAziendaEsistente = false;
  boolContesto = false;
  anniRisultati = [];
  listaBilanci: any[] = [];
  arrayNomeProdotti: any[];
  indicatoreEconometrico = 'IRR Equity';
  frequenze = [0];
  maxFrequenze = 0;
  minimoIndicatore = 0;
  massimoIndicatore = 0;
  mediaIndicatore = 0;
  range = [];
  valutazioneMcc = 'Tot';
  anniMcc = 0;
  frequenzeMcc = [[0]];
  prefissoMcc = '';
  anniIndice = 0;
  freqDscrAnnuo = [[0]];
  indiceDscrAnnuo = 'cumul';
  anniDscrAnnuo = 0;
  indiceBil = 'capCirc';
  freqIndiceBil = [[0]];
  bordiIndiceBil = [0];
  anniBP = 0;
  anniCE = 0;
  anniCF = 0;
  anniSP = 0;
  anniDeb = 0;
  anniIndici = 0;
  anniSimulazioneRisultati = 0;
  numProdottiRisultati = 0;
  colonneRisultatiBP = 5;
  risultati: any = {};
  profiloBP = 4;
  profiloCE = 4;
  profiloCF = 4;
  profiloSP = 4;
  profiloDeb = 4;
  profiloIndici = 4;
  colonneRisultatiCE = 5;
  colonneRisultatiCF = 5;
  colonneRisultatiSP = 5;
  colonneRisultatiDeb = 5;
  colonneRisultatiDscr = 5;
  colonneRisultatiMcc = 5;
  colonneIndici = 5;
  numProdottiIniziale: number = 1;
  perditaAttesaAnnuaRadice = 0;
  perditaInattesaAnnuaRadice = 0;
  anniSimulazioneIniziale = 0;
  listaModalitaEvoluzione = [{ id: 1, descrizione: 'Evoluzione libera' }, { id: 2, descrizione: 'Reset annuale' }, { id: 3, descrizione: 'Input valori annuali' }];
  /* ho creato 
  ho importato  private tokenService: TokenStorageService per far funzionare onChangeBilancio */
  constructor(private formBuilder: UntypedFormBuilder, private httpClient: HttpClient, private loading: LoadingService, private tokenService: TokenStorageService,
    private bilancioService: BilancioIntestatarioService, private router: Router, private datiModelloUniversaleService: ModelloUniversaleService) {

    this.formModelloCommerciale = this.formBuilder.group({
      anniSimulazione: ['', Validators.required], /* i campi sono inizializzati vuoti ['']*/
      tipoSimulazione: ['', Validators.required],
      numProdotti: ['', Validators.required], /* Validators.required */
      datiProdotti: this.formBuilder.array([ /* creo un array perch� ho diversi campi uguali che si ripetono in base al numero diprodotti */
        this.formBuilder.group({
          /* form controls */
          nomeProdotto: [''],
          selectVarPrezzo: [''],
          modEvolPrezzo: [''],
          prezzoIniziale: ['', Validators.required],
          prezzoMax: [''],
          prezzoMin: [''],
          driftPrezzo: [''],
          scartoPrezzo: [''],
          anniPrezzo: [''],
          selectVarCosto: [''],
          modEvolCosto: [''],
          costoIniziale: ['', Validators.required],
          costoMax: [''],
          costoMin: [''],
          driftCosto: [''],
          scartoCosto: [''],
          anniCosto: [''],
          selectVarClientiFid: [''],
          modEvolClientiFid: [''],
          clientiFidIniziale: ['', Validators.required],
          clientiFidMax: [''],
          clientiFidMin: [''],
          driftClientiFid: [''],
          scartoClientiFid: [''],
          anniClientiFid: [''],
          selectVarClientiSpot: [''],
          modEvolClientiSpot: [''],
          clientiSpotIniziale: ['', Validators.required],
          clientiSpotMax: [''],
          clientiSpotMin: [''],
          driftClientiSpot: [''],
          scartoClientiSpot: [''],
          anniClientiSpot: [''],
          selectVarIndiceMag: [''],
          modEvolIndiceMag: [''],
          indiceMagIniziale: ['', Validators.required],
          indiceMagMax: [''],
          indiceMagMin: [''],
          driftIndiceMag: [''],
          scartoIndiceMag: [''],
          anniIndiceMag: [''],

          valoriAnnuali: this.formBuilder.array([
          ]),
          venditaFid: ['', Validators.required],
          percFideliz: ['', Validators.required],
          magazzinoIn: ['', Validators.required],
        })
      ]),
      costiFissi: this.formBuilder.array([]),
      costoFatturato: [''],
      giorniCrediti: ['', Validators.required],
      giorniDebiti: ['', Validators.required],
      capex: [''],
      rimanenzeIn: [0],
      capexEquity: [''],
      importoFinanziamento: ['', Validators.required],
      durataFinanziamento: ['', Validators.required],
      durataAmmortamentoAnni: ['', Validators.required],
      valoreGaranzie: ['', Validators.required],
      inflazIniziale: [''],
      inflazMax: [''],
      inflazMin: [''],
      driftInflaz: [''],
      scartoInflaz: [''],
      anniInflaz: [''],
      /* campi non obbligatori */
      presenzaBilancio: ['', Validators.required],
      bilancio: [''],
      nomeAzienda: [''],
      tipoAzienda: [''],
      partitaIva: [''],
    })
  }


  ngOnInit(): void {

    this.listenToLoading();
    this.initialValues = this.formModelloCommerciale.value;
    /* Questa parte serve a non far scomparire i dati dal form salvandoli in datiModelloUniversaleService.datiModellouniversale di cui currentDatiModelloUniversale � l'observer */
    /* il salvataggio in datiModellouniversale viene richiamato da gotoBilancio e campi vengono fillati al reloading della pag. modelloUniversale*/
    this.datiModelloUniversaleService.currentDatiModelloUniversale.subscribe((result) => {
      if (result != null) {
        this.formModelloCommerciale = result;
      }
    });
    /* per settare il nome del nuovo bilancio nel form */
    /* richiamo l'osservabile che punta all'oggetto parambilancio il cui sono salvati i dati*/
    this.getLastBilancio()


    /* si prende il nome dei possibili tipi di aziende al caricamento della pag */
    this.getTipoAzienda()

    this.onChangeBilancio()

  }
  get f() { return this.formModelloCommerciale.controls; } /* sostitusce tutti i get di formModelloCommerciale.controls con f */
  get prodottiFormArray(): UntypedFormArray { return <UntypedFormArray>this.formModelloCommerciale.controls.datiProdotti; }; /* <casting> */
  get prodottiArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.prodottiFormArray.controls; };
  get costiFormArray(): UntypedFormArray { return <UntypedFormArray>this.formModelloCommerciale.controls.costiFissi; }; /* <casting> */
  get costiArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.costiFormArray.controls; };
  /**
* Listen to the loadingSub property in the LoadingService class. This drives the
* display of the loading spinner.
*/
  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }

  /* variabili riempite al submit */
  onSubmit() {
    this.submitted = true;
    /* this.submitParBilancio = true; */
    this.prodottiFormArray.updateValueAndValidity();
    this.formModelloCommerciale.updateValueAndValidity();
    if (this.formModelloCommerciale.valid) {

      this.salvaNomeProdotti();

      this.boolAziendaEsistente = this.formModelloCommerciale.controls.presenzaBilancio.value;
      let request = {
        anniSimulazione: this.formModelloCommerciale.controls.anniSimulazione.value,
        numProdotti: this.formModelloCommerciale.controls.numProdotti.value,
        costoFatturato: this.formModelloCommerciale.controls.costoFatturato.value,
        capex: this.formModelloCommerciale.controls.capex.value,
        rimanenzeIn: this.formModelloCommerciale.controls.rimanenzeIn.value,
        capexEquity: this.formModelloCommerciale.controls.capexEquity.value,
        importoFinanziamento: this.formModelloCommerciale.controls.importoFinanziamento.value,
        durataFinanziamento: this.formModelloCommerciale.controls.durataFinanziamento.value,
        giorniCrediti: this.formModelloCommerciale.controls.giorniCrediti.value,
        giorniDebiti: this.formModelloCommerciale.controls.giorniDebiti.value,
        bilancioPresente: this.formModelloCommerciale.controls.presenzaBilancio.value,
        idIntestatario: this.formModelloCommerciale.controls.bilancio.value == '' ? 0 : this.formModelloCommerciale.controls.bilancio.value.idIntestatario,
        dataBilancio: this.formModelloCommerciale.controls.bilancio.value == '' ? '' : this.formModelloCommerciale.controls.bilancio.value.data,
        valoreGaranzie: this.formModelloCommerciale.controls.valoreGaranzie.value,
        durataAmmortamentoAnni: this.formModelloCommerciale.controls.durataAmmortamentoAnni.value,
        tipoSimulazione: this.formModelloCommerciale.controls.tipoSimulazione.value,
        /*  */
        /* presenzaBilancio: this.f.presenzaBilancio.value,
        bilancio: this.f.bilancio.value,
        nomeAzienda: this.f.nomeAzienda.value,
        tipoAzienda: this.f.tipoAzienda.value,
        partitaIva: this.f.partitaIva.value, */
        /*varibili nel form array vengono riempite dopo nel for*/
        /* nomeProdotto: [], */
        anniPrezzo: [],
        anniCosto: [],
        scartoCosto: [],
        scartoPrezzo: [],
        driftCosto: [],
        driftPrezzo: [],
        prezzoIniziale: [],
        prezzoMin: [],
        prezzoMax: [],
        costoIniziale: [],
        costoMin: [],
        costoMax: [],
        anniClientiFid: [],
        anniClientiSpot: [],
        scartoClientiFid: [],
        scartoClientiSpot: [],
        driftClientiFid: [],
        driftClientiSpot: [],
        clientiFidIniziale: [],
        clientiFidMin: [],
        clientiFidMax: [],
        clientiSpotIniziale: [],
        clientiSpotMin: [],
        clientiSpotMax: [],
        anniIndiceMag: [],
        scartoIndiceMag: [],
        driftIndiceMag: [],
        indiceMagIniziale: [],
        indiceMagMin: [],
        indiceMagMax: [],
        venditaFid: [],
        percFideliz: [],
        magazzinoIn: [],
        costiFissi: [],
        matricePrezzoAnnuale: [],
        matriceCostoAnnuale: [],
        matriceClientiFidAnnuali: [],
        matriceClientiSpotAnnuali: [],
        matriceIndiceMagAnnuale: [],
        tipoVariabile: [],
      }

      for (let i = 0; i < this.formModelloCommerciale.controls.numProdotti.value; i++) {
        /* request.nomeProdotto.push(this.prodottiArray[i].controls.nomeProdotto.value) */
        //SISTEMARE ORDINE IN MATLAB
        request.tipoVariabile.push(this.prodottiArray[i].controls.modEvolPrezzo.value.id);
        request.tipoVariabile.push(this.prodottiArray[i].controls.modEvolCosto.value.id);
        request.tipoVariabile.push(this.prodottiArray[i].controls.modEvolIndiceMag.value.id);
        request.tipoVariabile.push(this.prodottiArray[i].controls.modEvolClientiFid.value.id);
        request.tipoVariabile.push(this.prodottiArray[i].controls.modEvolClientiSpot.value.id);

        request.prezzoIniziale.push(this.prodottiArray[i].controls.prezzoIniziale.value);
        request.driftPrezzo.push(this.prodottiArray[i].controls.driftPrezzo.value);

        if (this.prodottiArray[i].controls.selectVarPrezzo.value) {
          request.prezzoMax.push(this.prodottiArray[i].controls.prezzoMax.value);
          request.prezzoMin.push(this.prodottiArray[i].controls.prezzoMin.value);
          request.scartoPrezzo.push(this.prodottiArray[i].controls.scartoPrezzo.value);
          request.anniPrezzo.push(this.prodottiArray[i].controls.anniPrezzo.value);

        } else {
          request.prezzoMax.push(this.prodottiArray[i].controls.prezzoIniziale.value);
          request.prezzoMin.push(this.prodottiArray[i].controls.prezzoIniziale.value);
          request.scartoPrezzo.push(0);
          request.anniPrezzo.push(0);
        }

        request.costoIniziale.push(this.prodottiArray[i].controls.costoIniziale.value);
        request.driftCosto.push(this.prodottiArray[i].controls.driftCosto.value);

        if (this.prodottiArray[i].controls.selectVarCosto.value) {
          request.costoMax.push(this.prodottiArray[i].controls.costoMax.value);
          request.costoMin.push(this.prodottiArray[i].controls.costoMin.value);
          request.anniCosto.push(this.prodottiArray[i].controls.anniCosto.value);
          request.scartoCosto.push(this.prodottiArray[i].controls.scartoCosto.value);

        } else {
          request.costoMax.push(this.prodottiArray[i].controls.costoIniziale.value);
          request.costoMin.push(this.prodottiArray[i].controls.costoIniziale.value);
          request.anniCosto.push(0);
          request.scartoCosto.push(0);
        }

        request.clientiFidIniziale.push(this.prodottiArray[i].controls.clientiFidIniziale.value);
        request.driftClientiFid.push(this.prodottiArray[i].controls.driftClientiFid.value);

        if (this.prodottiArray[i].controls.selectVarClientiFid.value) {
          request.clientiFidMax.push(this.prodottiArray[i].controls.clientiFidMax.value);
          request.clientiFidMin.push(this.prodottiArray[i].controls.clientiFidMin.value);
          request.anniClientiFid.push(this.prodottiArray[i].controls.anniClientiFid.value);
          request.scartoClientiFid.push(this.prodottiArray[i].controls.scartoClientiFid.value);

        } else {
          request.clientiFidMax.push(this.prodottiArray[i].controls.clientiFidIniziale.value);
          request.clientiFidMin.push(this.prodottiArray[i].controls.clientiFidIniziale.value);
          request.anniClientiFid.push(0);
          request.scartoClientiFid.push(0);
        }

        request.clientiSpotIniziale.push(this.prodottiArray[i].controls.clientiSpotIniziale.value);
        request.driftClientiSpot.push(this.prodottiArray[i].controls.driftClientiSpot.value);

        if (this.prodottiArray[i].controls.selectVarClientiSpot.value) {
          request.clientiSpotMax.push(this.prodottiArray[i].controls.clientiSpotMax.value);
          request.clientiSpotMin.push(this.prodottiArray[i].controls.clientiSpotMin.value);
          request.anniClientiSpot.push(this.prodottiArray[i].controls.anniClientiSpot.value);
          request.scartoClientiSpot.push(this.prodottiArray[i].controls.scartoClientiSpot.value);

        } else {
          request.clientiSpotMax.push(this.prodottiArray[i].controls.clientiSpotIniziale.value);
          request.clientiSpotMin.push(this.prodottiArray[i].controls.clientiSpotIniziale.value);
          request.anniClientiSpot.push(0);
          request.scartoClientiSpot.push(0);
        }

        request.indiceMagIniziale.push(this.prodottiArray[i].controls.indiceMagIniziale.value);
        request.driftIndiceMag.push(this.prodottiArray[i].controls.driftIndiceMag.value);

        if (this.prodottiArray[i].controls.selectVarIndiceMag.value) {
          request.indiceMagMax.push(this.prodottiArray[i].controls.indiceMagMax.value);
          request.indiceMagMin.push(this.prodottiArray[i].controls.indiceMagMin.value);
          request.anniIndiceMag.push(this.prodottiArray[i].controls.anniIndiceMag.value);
          request.scartoIndiceMag.push(this.prodottiArray[i].controls.scartoIndiceMag.value);

        } else {
          request.indiceMagMax.push(this.prodottiArray[i].controls.indiceMagIniziale.value);
          request.indiceMagMin.push(this.prodottiArray[i].controls.indiceMagIniziale.value);
          request.anniIndiceMag.push(0);
          request.scartoIndiceMag.push(0);
        }



        request.venditaFid.push(this.prodottiArray[i].controls.venditaFid.value);
        request.percFideliz.push(this.prodottiArray[i].controls.percFideliz.value);
        request.magazzinoIn.push(this.prodottiArray[i].controls.magazzinoIn.value);

      }

      let prezzoAnnuale = [];
      let costoAnnuale = [];
      let clientiFidAnnuali = [];
      let clientiSpotAnnuali = [];
      let indiceMagAnnuale = [];

      for (let j = 0; j < this.formModelloCommerciale.controls.anniSimulazione.value; j++) {
        request.costiFissi.push(this.costiArray[j].controls.valoreAnnuo.value);
        prezzoAnnuale = [];
        costoAnnuale = [];
        clientiFidAnnuali = [];
        clientiSpotAnnuali = [];
        indiceMagAnnuale = [];
        for (let i = 0; i < this.formModelloCommerciale.controls.numProdotti.value; i++) {
          if (this.prodottiArray[i].controls.modEvolPrezzo.value && this.prodottiArray[i].controls.modEvolPrezzo.value.id == 3) {
            prezzoAnnuale.push((<UntypedFormGroup[]>(<UntypedFormArray>this.prodottiArray[i].controls.valoriAnnuali).controls)[j].controls.prezzoAnnuale.value);
          } else {
            prezzoAnnuale.push(0);
          }

          if (this.prodottiArray[i].controls.modEvolCosto.value && this.prodottiArray[i].controls.modEvolCosto.value.id == 3) {
            costoAnnuale.push((<UntypedFormGroup[]>(<UntypedFormArray>this.prodottiArray[i].controls.valoriAnnuali).controls)[j].controls.costoAnnuale.value);
          } else {
            costoAnnuale.push(0);
          }

          if (this.prodottiArray[i].controls.modEvolClientiFid.value && this.prodottiArray[i].controls.modEvolClientiFid.value.id == 3) {
            clientiFidAnnuali.push((<UntypedFormGroup[]>(<UntypedFormArray>this.prodottiArray[i].controls.valoriAnnuali).controls)[j].controls.clientiFidAnnuali.value);
          } else {
            clientiFidAnnuali.push(0);
          }

          if (this.prodottiArray[i].controls.modEvolClientiSpot.value && this.prodottiArray[i].controls.modEvolClientiSpot.value.id == 3) {
            clientiSpotAnnuali.push((<UntypedFormGroup[]>(<UntypedFormArray>this.prodottiArray[i].controls.valoriAnnuali).controls)[j].controls.clientiSpotAnnuali.value);
          } else {
            clientiSpotAnnuali.push(0);
          }

          if (this.prodottiArray[i].controls.modEvolIndiceMag.value && this.prodottiArray[i].controls.modEvolIndiceMag.value.id == 3) {
            indiceMagAnnuale.push((<UntypedFormGroup[]>(<UntypedFormArray>this.prodottiArray[i].controls.valoriAnnuali).controls)[j].controls.indiceMagAnnuale.value);
          } else {
            indiceMagAnnuale.push(0);
          }


        }
        request.matricePrezzoAnnuale.push(prezzoAnnuale);
        request.matriceCostoAnnuale.push(costoAnnuale);
        request.matriceClientiFidAnnuali.push(clientiFidAnnuali);
        request.matriceClientiSpotAnnuali.push(clientiSpotAnnuali);
        request.matriceIndiceMagAnnuale.push(indiceMagAnnuale);

      }

      this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/calcolaCommerciale', request).subscribe(data => {
        this.risultati = data

        this.valutazioneMcc = 'Tot';
        this.anniMcc = 0;
        this.frequenzeMcc = this.risultati.freqMccTot;
        this.prefissoMcc = '';

        this.numProdottiRisultati = this.formModelloCommerciale.controls.numProdotti.value;
        this.anniSimulazioneRisultati = this.f.anniSimulazione.value;
        this.anniRisultati = [];
        let lustri = 1 + Math.floor((this.f.anniSimulazione.value - 1.) / 5.);
        for (let i = 0; i < lustri; i++) {
          this.anniRisultati.push(
            {
              valore: i * 5,
              label: (i * 5 + 1) + "-" + Math.min((i + 1) * 5, this.f.anniSimulazione.value),
            })
        }
        this.onChangeAnniBP();
        this.onChangeAnniCE();
        this.onChangeAnniCF();
        this.onChangeAnniSP();
        this.onChangeAnniDeb();
        this.onChangeAnniDscr();
        this.onChangeAnniMcc();
        this.onChangeAnniIndici();
        this.indicatoreEconometrico = 'IRR Equity';
        this.range = this.risultati.bordiIrrEqString;
        this.frequenze = this.risultati.freqIrrEq;
        this.minimoIndicatore = this.risultati.minMaxMedIrrEq[0];
        this.massimoIndicatore = this.risultati.minMaxMedIrrEq[1];
        this.mediaIndicatore = this.risultati.minMaxMedIrrEq[2];

        this.freqDscrAnnuo = this.risultati.freqDscrCumul;
        this.freqIndiceBil = this.risultati.freqCapCirc;
        this.bordiIndiceBil = this.risultati.bordiCapCircString;

        this.perditaAttesaAnnuaRadice = this.risultati.perditaAttesaAnnuale / this.f.anniSimulazione.value;
        this.perditaInattesaAnnuaRadice = this.risultati.perditaInattesa / this.f.anniSimulazione.value;
        this.boolRisultati = true;
      });


    }

  }

  onReset() {
    this.formModelloCommerciale.reset(this.initialValues);
    this.submitted = false;
    this.boolRisultati = false;

  }

  onChangeAnniSimulazione() {
    if (this.f.anniSimulazione.value && this.f.anniSimulazione.value > 0) {

      let arrayCosti = <UntypedFormGroup[]>(<UntypedFormArray>this.formModelloCommerciale.controls.costiFissi).controls;
      for (let j = 0; j < Math.abs(this.f.anniSimulazione.value - this.anniSimulazioneIniziale); j++) {
        if (this.f.anniSimulazione.value - this.anniSimulazioneIniziale > 0) {
          arrayCosti.push(this.formBuilder.group({
            valoreAnnuo: [0]
          })
          );
        } else if (this.f.anniSimulazione.value - this.anniSimulazioneIniziale < 0) {
          arrayCosti.splice(arrayCosti.length - 1) //basta cambiare il valore di splice se si volesse eliminare uno specifico prodotto 
        }
        if (this.prodottiArray.length && this.prodottiArray.length > 0) {

          for (let i = 0; i < this.prodottiArray.length; i++) {
            let arrayValori = <UntypedFormGroup[]>(<UntypedFormArray>this.prodottiArray[i].controls.valoriAnnuali).controls;

            if (this.f.anniSimulazione.value - this.anniSimulazioneIniziale > 0) {
              arrayValori.push(this.formBuilder.group({
                prezzoAnnuale: [''],
                costoAnnuale: [''],
                clientiFidAnnuali: [''],
                clientiSpotAnnuali: [''],
                indiceMagAnnuale: ['']
              })
              );
            } else if (this.f.anniSimulazione.value - this.anniSimulazioneIniziale < 0) {
              arrayValori.splice(arrayValori.length - 1) //basta cambiare il valore di splice se si volesse eliminare uno specifico prodotto 
            }
          }
        }
      }
    }
    this.anniSimulazioneIniziale = this.f.anniSimulazione.value;
  }
  /* gestisce i lnumero di form per i prodotti in base al numero inserito */
  onChangeNumeroProdotti() {
    for (let i = 0; i < Math.abs(this.f.numProdotti.value - this.numProdottiIniziale); i++) {
      if (this.f.numProdotti.value - this.numProdottiIniziale > 0) {
        let arrayValori: UntypedFormGroup[] = [];
        if (this.f.anniSimulazione.value && this.f.anniSimulazione.value > 0) {
          for (let j = 0; j < this.f.anniSimulazione.value; j++) {
            arrayValori.push(this.formBuilder.group({
              prezzoAnnuale: [''],
              costoAnnuale: [''],
              clientiFidAnnuali: [''],
              clientiSpotAnnuali: [''],
              indiceMagAnnuale: ['']
            }));
          }
        }
        this.prodottiArray.push(
          this.formBuilder.group({
            nomeProdotto: [''],
            selectVarPrezzo: [''],
            modEvolPrezzo: [''],
            prezzoIniziale: ['', Validators.required],
            prezzoMax: [''],
            prezzoMin: [''],
            driftPrezzo: [''],
            scartoPrezzo: [''],
            anniPrezzo: [''],
            selectVarCosto: [''],
            modEvolCosto: [''],
            costoIniziale: ['', Validators.required],
            costoMax: [''],
            costoMin: [''],
            driftCosto: [''],
            scartoCosto: [''],
            anniCosto: [''],
            selectVarClientiFid: [''],
            modEvolClientiFid: [''],
            clientiFidIniziale: ['', Validators.required],
            clientiFidMax: [''],
            clientiFidMin: [''],
            driftClientiFid: [''],
            scartoClientiFid: [''],
            anniClientiFid: [''],
            selectVarClientiSpot: [''],
            modEvolClientiSpot: [''],
            clientiSpotIniziale: ['', Validators.required],
            clientiSpotMax: [''],
            clientiSpotMin: [''],
            driftClientiSpot: [''],
            scartoClientiSpot: [''],
            anniClientiSpot: [''],
            selectVarIndiceMag: [''],
            modEvolIndiceMag: [''],
            indiceMagIniziale: ['', Validators.required],
            indiceMagMax: [''],
            indiceMagMin: [''],
            driftIndiceMag: [''],
            scartoIndiceMag: [''],
            anniIndiceMag: [''],

            valoriAnnuali: this.formBuilder.array(arrayValori),

            venditaFid: ['', Validators.required],
            percFideliz: ['', Validators.required],
            magazzinoIn: ['', Validators.required],
          })
        )

      } else if (this.f.numProdotti.value - this.numProdottiIniziale < 0) {
        this.prodottiArray.splice(this.prodottiArray.length - 1) //basta cambiare il valore di splice se si volesse eliminare uno specifico prodotto 
      }
    }
    this.numProdottiIniziale = this.f.numProdotti.value;

  }


  onChangeAnniBP() {
    this.colonneRisultatiBP = Math.min(5, this.anniSimulazioneRisultati - this.anniBP);
  }

  onChangeAnniCE() {
    this.colonneRisultatiCE = Math.min(5, this.anniSimulazioneRisultati - this.anniCE);
  }

  onChangeAnniCF() {
    this.colonneRisultatiCF = Math.min(5, this.anniSimulazioneRisultati - this.anniCF);
  }

  onChangeAnniSP() {
    this.colonneRisultatiSP = Math.min(5, this.anniSimulazioneRisultati - this.anniSP);
  }

  onChangeAnniDeb() {
    this.colonneRisultatiDeb = Math.min(5, this.anniSimulazioneRisultati - this.anniDeb);
  }

  onChangeAnniDscr() {
    this.colonneRisultatiDscr = Math.min(5, this.anniSimulazioneRisultati - this.anniDscrAnnuo);
  }

  onChangeAnniMcc() {
    this.colonneRisultatiMcc = Math.min(5, this.anniSimulazioneRisultati - this.anniMcc);
  }

  onChangeAnniIndici() {
    this.colonneIndici = Math.min(5, this.anniSimulazioneRisultati - this.anniIndici);
  }

  onChangeIndicatore() {
    if (this.indicatoreEconometrico == 'IRR Equity') {
      this.range = this.risultati.bordiIrrEqString;
      this.frequenze = this.risultati.freqIrrEq;
      this.minimoIndicatore = this.risultati.minMaxMedIrrEq[0];
      this.massimoIndicatore = this.risultati.minMaxMedIrrEq[1];
      this.mediaIndicatore = this.risultati.minMaxMedIrrEq[2];
    }
    else if (this.indicatoreEconometrico == 'IRR Progetto') {
      this.range = this.risultati.bordiIrrPrString;
      this.frequenze = this.risultati.freqIrrPr;
      this.minimoIndicatore = this.risultati.minMaxMedIrrPr[0];
      this.massimoIndicatore = this.risultati.minMaxMedIrrPr[1];
      this.mediaIndicatore = this.risultati.minMaxMedIrrPr[2];
    }
    else if (this.indicatoreEconometrico == 'Break even') {
      this.range = this.risultati.bordiBreakEvenString;
      this.frequenze = this.risultati.freqBreakEven;
      this.minimoIndicatore = this.risultati.minMaxMedBreakEven[0];
      this.massimoIndicatore = this.risultati.minMaxMedBreakEven[1];
      this.mediaIndicatore = this.risultati.minMaxMedBreakEven[2];
    }
    else if (this.indicatoreEconometrico == 'Somma CF') {
      this.range = this.risultati.bordiValCompanyString;
      this.frequenze = this.risultati.freqValCompany;
      this.minimoIndicatore = this.risultati.minMaxMedValCompany[0];
      this.massimoIndicatore = this.risultati.minMaxMedValCompany[1];
      this.mediaIndicatore = this.risultati.minMaxMedValCompany[2];
    }
    else if (this.indicatoreEconometrico == 'Dscr') {
      this.range = this.risultati.bordiDscrString;
      this.frequenze = this.risultati.freqDscr;
      this.minimoIndicatore = this.risultati.minMaxMedDscr[0];
      this.massimoIndicatore = this.risultati.minMaxMedDscr[1];
      this.mediaIndicatore = this.risultati.minMaxMedDscr[2];
    }
    else if (this.indicatoreEconometrico == 'Valore azienda') {
      this.range = this.risultati.bordiValoreAziendaString;
      this.frequenze = this.risultati.freqValoreAzienda;
      this.minimoIndicatore = this.risultati.minMaxMedValoreAzienda[0];
      this.massimoIndicatore = this.risultati.minMaxMedValoreAzienda[1];
      this.mediaIndicatore = this.risultati.minMaxMedValoreAzienda[2];
    }
  }

  salvaNomeProdotti() {
    this.arrayNomeProdotti = [];
    for (let i = 0; i < this.formModelloCommerciale.controls.numProdotti.value; i++) {
      this.arrayNomeProdotti.push(this.prodottiArray[i].controls.nomeProdotto.value)
    }
    /* this.arrayNomeProdotti.forEach(element => console.log(element))
    console.log("\n========") */
  }
  onChangeValutazioneMcc() {
    if (this.valutazioneMcc == 'Tot') {
      this.frequenzeMcc = this.risultati.freqMccTot;
      this.prefissoMcc = '';
    }
    else if (this.valutazioneMcc == 'Bil') {
      this.frequenzeMcc = this.risultati.freqBil;
      this.prefissoMcc = 'F';
    }
    else if (this.valutazioneMcc == 'Cr') {
      this.frequenzeMcc = this.risultati.freqCr;
      this.prefissoMcc = 'CR';
    }

  }

  onChangeIndiceDscrAnnuo() {
    if (this.indiceDscrAnnuo == 'annuo') {
      this.freqDscrAnnuo = this.risultati.freqDscrAnnuo;
    }
    else if (this.indiceDscrAnnuo == 'cumul') {
      this.freqDscrAnnuo = this.risultati.freqDscrCumul;
    }
  }

  onChangeIndiceBil() {
    if (this.indiceBil == 'capCirc') {
      this.freqIndiceBil = this.risultati.freqCapCirc;
      this.bordiIndiceBil = this.risultati.bordiCapCircString;
    }
    else if (this.indiceBil == 'netProfit') {
      this.freqIndiceBil = this.risultati.freqNetProfit;
      this.bordiIndiceBil = this.risultati.bordiNetProfitString;
    }
    else if (this.indiceBil == 'patrDeb') {
      this.freqIndiceBil = this.risultati.freqRateoPatrDeb;
      this.bordiIndiceBil = this.risultati.bordiPatrDebString;
    }
    else if (this.indiceBil == 'patrImmob') {
      this.freqIndiceBil = this.risultati.freqRateoPatrImmob;
      this.bordiIndiceBil = this.risultati.bordiPatrImmobString;
    }
    else if (this.indiceBil == 'ricavi') {
      this.freqIndiceBil = this.risultati.freqRicavi;
      this.bordiIndiceBil = this.risultati.bordiRicaviString;
    }
  }

  /* questo blocco si attiva dopo */
  onChangeBilancio() {
    if (this.f.presenzaBilancio.value) {
      /* this.submitted = false; */
      /* fornisce le possibili scelte per dei bilanci precompilati */
      //this.tokenService.getUser().id
      //SISTEMARE GLI ID
      this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAnagraficaRichiedenteByidCliente', { idCliente: 2 }).subscribe(data => {
        this.listaBilanci = []; /* svuoto la lista */
        data.forEach(element => {
          let elementoLista = {
            idIntestatario: element.id,
            //data: element.bilancio.dataInserimento,
            descrizione: element.ragioneSociale
          };
          this.listaBilanci.push(elementoLista)
          /* se il campo � vuoto e currisponde a quello che c � in elementolista.data e .idDest setta il valore a quello. Questo capita solo al ritorno nella pag quando setNuovoBilancio()
          inserisce il bilancio appena creato nel campo bilancio del form */
          //if (elementoLista.data == this.f.bilancio.value.data && elementoLista.idIntestatario == this.f.bilancio.value.idIntestatario) {
          if (elementoLista.idIntestatario == this.f.bilancio.value.idIntestatario) {

            /* setto il campo bilancio che pero non viene settato finch� setNuovoBilancio() non viene richiamata in ngOnInit  */
            this.f.bilancio.setValue(elementoLista);

          }
        });
      });
      /* rendo i campi obbligatori */
      this.f.tipoAzienda.setValidators(Validators.required);
      this.f.nomeAzienda.setValidators(Validators.required);
      this.f.partitaIva.setValidators(Validators.required);
      this.f.bilancio.setValidators(Validators.required);
      /* When you add or remove a validator at run time call the function, */

    } else {
      this.f.tipoAzienda.setValidators(null);
      this.f.nomeAzienda.setValidators(null);
      this.f.partitaIva.setValidators(null);
      this.f.bilancio.setValidators(null);
    }
    this.f.tipoAzienda.updateValueAndValidity();
    this.f.nomeAzienda.updateValueAndValidity();
    this.f.partitaIva.updateValueAndValidity();
    this.f.bilancio.updateValueAndValidity();
  }
  /* viene richiamato al caricamento quando atterro sulla pag la seconda volta*/
  getLastBilancio() {
    this.bilancioService.currentParamBilancio.subscribe((bilancio) => {
      if (bilancio && bilancio.id_intestatario) {  /* condizione per non farlo partire al primo atterraggio sulla pag */
        this.formModelloCommerciale.controls.bilancio.setValue({ idIntestatario: bilancio.id_intestatario, data: bilancio.data_inserimento }) /* this.listaBilanci[length-1] */
      }
    })
  }
  getTipoAzienda() {
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda').subscribe(data => {
      this.listaTipiAzienda = data;
      if (this.f.tipoAzienda.value != '') {
        this.listaTipiAzienda.forEach(element => {
          /* lascia nel campo il tipo azienda messo confrontando gli id */
          if (element.id == this.f.tipoAzienda.value.id) {
            this.f.tipoAzienda.setValue(element)
          };
        });
      }
    });
  }

  goToBilancio() {
    this.submitParBilancio = false;
    console.log(this.f.tipoAzienda.value.descrizione)

    if (this.f.tipoAzienda.value.descrizione == undefined || this.f.nomeAzienda.value == "" || this.f.partitaIva.value == "") {
      this.submitParBilancio = true;
    } else {
      this.submitParBilancio = false;
      /* salvo i dati inseriti in un service */
      this.datiModelloUniversaleService.changeDatiModelloUniversale(this.formModelloCommerciale)

      /* parametri in input al post */
      let request = {
        ragioneSociale: this.f.nomeAzienda.value,
        idTipoAziendaMcc: this.f.tipoAzienda.value.id,
        partitaIva: this.f.partitaIva.value,
        utente: this.tokenService.getUser().username,
      };
      console.log(request)
      this.httpClient
        .post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getIntestatarioBilancio', request) /* richiesta request per vedere l'id intestario e se non c � crea un nuovo record */
        .subscribe((response) => { /* subscribe per vedere la risposta della chiamata */
          /* prendo i dati della risposta e li uso per creare i parmetri di un altro bilancio */
          this.bilancioService.changeParamBilancio({
            id: response.id,
            nome: response.ragioneSociale,
            azienda: response.idTipoAziendaMcc,
            bilancio: 'nuovo',

          });
          console.log(response)
          this.router.navigate(['/bilancio']);
        });
    }
  }
}

//Patrimonio netto finale = patrimonio netto * (reddito- tasso WACC)

