<div class="container-fluid margin-top-10" id="ConfigurazioneDistintaBaseProdotti">
    <ng-container *ngIf="idRichiedenteTemp==undefined">

        <div class="row nopadding">
            <div class="col-8 nopadding">
                <h2>Configurazione Distinta base Prodotti</h2>
            </div>
        </div>
        <div class="row nopadding">
            <div class="col-12 nopadding d-flex align-items-center margin-top-10">
                <h3> Inserisci l'azienda di cui vuoi configurare l'ambiente.</h3>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="idRichiedenteTemp!=undefined">

        <div class="row">
            <div class="col-12 nopadding">
                <h2>Configurazione Distinta base Prodotti</h2>
                <p>Inserisci i prodotti associati alle Linee di Business dell'azienda. <strong>Nota Bene:</strong> per
                    inserire i prodotti è necessario salvare almeno una Linea di Business.</p>
            </div>
        </div>
        <hr>
        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="currentIndex"
            class="d-flex justify-content-around"><!-- (selectedTabChange)="resetScrollingSize()" -->
            <mat-tab *ngFor="let lineaBusiness of lineeBusiness; let i = index"
                [label]="lineeBusiness[i].nomeLineaBusiness">
                <app-linea-business [lineaBusiness]="lineaBusiness" [submitted]="isDirtyProdotti[i]">
                    <!-- *ngIf="currentIndex==2" -->
                </app-linea-business>
            </mat-tab>
        </mat-tab-group>

    </ng-container>



</div>