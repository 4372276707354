import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProdottoLineaBusiness } from '../condivisi/ProdottoLineaBusiness';
import { customCorrAmbienteToSave } from '../interface/indicatori-guida-bp';
import { BeanStatico } from './../condivisi/beanStatico';
import { regione } from './../interfaces/user-key';
import { SituazioneCreditizia, SituazioneDebitoria } from '../interface/situazione-debitoria';
import { TokenStorageService } from './token-storage.service';
import { BeanStaticoEsteso } from '../condivisi/beanStaticoEsteso';
import { LineaBusiness } from '../condivisi/linea-business';
import { Richiedente } from '../interface/richiedente';

@Injectable({
  providedIn: 'root'
})
export class ConfigurazioneAmbienteService {



  resetDistintaBase: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _resetDistintaBase$: Observable<boolean> = this.resetDistintaBase.asObservable();

  resetCountDistintaBase: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  _resetCountDistintaBase$: Observable<number> = this.resetCountDistintaBase.asObservable();

  /* private formsDirty = new BehaviorSubject<boolean[]>([false, false, false]);
  currentformsDirty: Observable<boolean[]> = this.formsDirty.asObservable(); */

  lineedSaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentLineedSaved: Observable<boolean> = this.lineedSaved.asObservable();

  annoIniziale: BehaviorSubject<number> = new BehaviorSubject<number>(new Date().getFullYear());
  currentAnnoIniziale: Observable<number> = this.annoIniziale.asObservable();

  constructor(
    private httpClient: HttpClient, private tokenStorage: TokenStorageService
  ) {
  }

  getSituazioneDebitoria(idRichiedente: number) {
    return this.httpClient.post<SituazioneDebitoria>(environment.apiUrlQuestionario + '/fasGoAlgo/getSituazioneDebitoria', { idRichiedente: idRichiedente, idUtente: this.tokenStorage.getUser().idUtente })
  }

  saveSituazioneDebitoria(situazioneDebitoria: SituazioneDebitoria) {
    return this.httpClient.post<SituazioneDebitoria>(environment.apiUrlQuestionario + '/fasGoAlgo/saveSituazioneDebitoria', situazioneDebitoria)
  }
  getSituazioneCreditizia(idRichiedente: number) {
    return this.httpClient.post<SituazioneCreditizia>(environment.apiUrlQuestionario + '/fasGoAlgo/getSituazioneCreditizia', { idRichiedente: idRichiedente, idUtente: this.tokenStorage.getUser().idUtente })
  }

  saveSituazioneCreditizia(situazioneCreditizia: SituazioneCreditizia) {
    return this.httpClient.post<SituazioneCreditizia>(environment.apiUrlQuestionario + '/fasGoAlgo/saveSituazioneCreditizia', situazioneCreditizia)
  }

  getListaTipoRate() {
    return this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getListaTipiRate')
  }


  resetDistintaBaseCount() {
    this.resetCountDistintaBase.next(0);
  }

  getProdottiDropDown(idCategoria: number, idRegione: number): any {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/distintaBaseProdotti/getProdottiDropDown', { idCategoria: idCategoria, idRegione: idRegione })
  }
  getTipologiaAttivita() {
    return this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/lineeBusiness/getTipologiaAttivita')
  }
  getCategorie() {
    return this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/lineeBusiness/getCategorie')
  }

  getRegioni() {
    return this.httpClient.get<regione[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/lineeBusiness/getRegioni')
  }

  getListaCodiciAteco(idRichiedente: number,idCliente: number) {
    return this.httpClient.post<BeanStaticoEsteso[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getListaCodiciAteco',{idRichiedente: idRichiedente, idCliente: idCliente})
  }

  getValoriMax(nomeSezione: string) {
    return this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getValoriMax', { nomeSezione: nomeSezione });
  }

  getParametriGenerali(idRichiedente: number, idUtente: number) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getParametriGenerali', { idRichiedente: idRichiedente, idUtente: idUtente });
  }
  saveParametriGenerali(request: any) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/saveParametriGenerali', request);
  }

  getLineeBusiness(idRichiedente: number, idCliente: number) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getLineeBusiness', { idRichiedente: idRichiedente, idCliente: idCliente });
  }
  saveLineeBusiness(request: any) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/saveLineeBusiness', request);
  }
  deleteLineabusiness(idLinea: number, idUtente: number, idRichiedente: number, numeroLinee: number) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/deleteLineaBusiness', { idLinea: idLinea, idRichiedente: idRichiedente, idUtente: idUtente, numeroLinee: numeroLinee });
  }

  getProdotti(idLineaBusiness: number, idUtente: number) {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getProdotti', { idLineeBusiness: idLineaBusiness, idUtente: idUtente });
  }
  saveProdotti(request: ProdottoLineaBusiness[]) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/saveProdotti', request);
  }
  deleteProdotti(idProdotto: number, idLinea: number) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/deleteProdotti', { id: idProdotto, idLineeBusiness: idLinea });
  }

  configurazioneAmbienteIsPresent(idUtente: number) {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/configurazioneAmbienteIsPresent', { idUtente: idUtente });
  }

  listaRichiedentiConAmbiente(idUtente: number) {
    return this.httpClient.post<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/listaRichiedentiConAmbiente', { idUtente: idUtente });

  }

  lineeBpChanged(lineeSaved: boolean) {
    this.lineedSaved.next(lineeSaved)
  }

  saveCorrelazioni(AMBcorr: customCorrAmbienteToSave) {
    return this.httpClient.post<customCorrAmbienteToSave>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/saveCorrAmb', AMBcorr)
  }

  oscillazioniByAteco(codiceAteco: string) {
    return this.httpClient.post<LineaBusiness>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getOscillazioniByAteco', { codiceAteco: codiceAteco });

  }

  getCostiFissi(idRichiedente: number) {
    return this.httpClient.post<number>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getCostiFissiBilancio', { idRichiedente: idRichiedente });

  }

  getListaRichiedentiByIdUtente(idUtente: number): Observable<Richiedente[]>  {
    return this.httpClient.post<Richiedente[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getListaRichiedentiByIdUtente', { idUtente: idUtente });

  }
  
  inviaAnnoIniziale(annoIniziale: number) {
    this.annoIniziale.next(annoIniziale)
  }
}

