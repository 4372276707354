import { HttpClient, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Categoria } from 'src/app/models/categoria.model';
import { RisultatiComplessivo } from 'src/app/risultati-questionario/interface/risultatiComplessivi';
import { asIsSection, saveVolatileSplashpage } from 'src/app/risultati-questionario/interface/save-splashpage-data';
import { SintesiAsIsComponent } from 'src/app/risultati-questionario/sintesi-as-is/sintesi-as-is.component';
import { environment } from 'src/environments/environment';
import { saveDettaglio } from '../../risultati-questionario/interface/dettaglio'

@Injectable({
  providedIn: 'root'
})
export class MenuPulsantieraService {

  constructor(private http: HttpClient,) { }

  baseUrl = environment.apiUrlQuestionario + '/fasGoAlgo';

  /*bottone save*/
  private _richiesta_di_save: BehaviorSubject<saveVolatileSplashpage> = new BehaviorSubject<saveVolatileSplashpage>(null);
  public readonly _richiesta_di_save$: Observable<saveVolatileSplashpage> = this._richiesta_di_save.asObservable();
  private _richiesta_di_save_dettaglio: BehaviorSubject<saveDettaglio> = new BehaviorSubject<saveDettaglio>(null);
  public readonly _richiesta_di_save_dettaglio$: Observable<saveDettaglio> = this._richiesta_di_save_dettaglio.asObservable();
  /*changen state after save*/
  private _change_state_to_save: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly _change_state_to_save$: Observable<boolean> = this._change_state_to_save.asObservable();


  //change save state
  chageSaveState() {
    this._change_state_to_save.next(true)
  }

  /*bottone as is*/
  private _NAVIGATE_DETTAGLIO: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly NAVIGATE_DETTAGLIO$: Observable<boolean> = this._NAVIGATE_DETTAGLIO.asObservable();

  private _NAVIGATE_SINTESI: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly NAVIGATE_SINTESI$: Observable<boolean> = this._NAVIGATE_SINTESI.asObservable();

  private _NAVIGATE_DOSSIER: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly NAVIGATE_DOSSIER$: Observable<boolean> = this._NAVIGATE_DOSSIER.asObservable();

  private _CONTROLLA_CONFIGURAZIONE: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly CONTROLLA_CONFIGURAZIONE$: Observable<boolean> = this._CONTROLLA_CONFIGURAZIONE.asObservable();

  clickOnDettaglio(): void {
    this._NAVIGATE_DETTAGLIO.next(true);
    this._NAVIGATE_DOSSIER.next(false);
    this._NAVIGATE_SINTESI.next(false);
  }

  clickOnSintesi(): void {
    this._NAVIGATE_DETTAGLIO.next(false);
    this._NAVIGATE_DOSSIER.next(false);
    this._NAVIGATE_SINTESI.next(true);
  }

  clickOnDossier(): void {
    this._NAVIGATE_DETTAGLIO.next(false);
    this._NAVIGATE_DOSSIER.next(true);
    this._NAVIGATE_SINTESI.next(false);
  }

  caricaConfigurazioneDossier(): void {
    this._CONTROLLA_CONFIGURAZIONE.next(true);
  }

  clickOnSave(risultati: RisultatiComplessivo): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + '/saveRisultati', risultati) // /saveQuestionario  xdettagli
  }

  /*   clickOnSaveDettaglio(saveDet: saveDettaglio): Observable<saveDettaglio> {
      return this.detService.saveDettaglio(saveDet)
    } */

  //invio save method
  invioDatiAlButtonSave(saveVolatileSplashpage: saveVolatileSplashpage) {
    this._richiesta_di_save.next(saveVolatileSplashpage);
  }
  invioDatiAlButtonSaveDettaglio(saveVolatileDettaglio: saveDettaglio) {

    this._richiesta_di_save_dettaglio.next(saveVolatileDettaglio);
  }

  generaExcel(idAziendaRichiedente: number, idBp: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/generaExcel/' + idAziendaRichiedente + '/' + idBp, { responseType: 'blob' as 'json' })
  }

  generaExcelSmart(idAziendaRichiedente: number, risultati: saveDettaglio): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/generaExcelSmart/' + idAziendaRichiedente,risultati, { responseType: 'blob' as 'json' })
  }

  public isFormDirty: () => boolean

  checkFormDirty(fn: () => boolean) {
    this.isFormDirty = fn
    return this.isFormDirty;
  }

}
