<!-- anagrafica dell'amministrazione per inserire/modificare i clienti -->
<div class="container-fluid">
    <div class="card nopadding" [formGroup]="anagraficaForm">
        <div class="card-header text-center font-big">Censimento cliente</div>
        <div class="card-body">
            <div class="row">
                <div class="col-3 margin-bottom-10">
                    <label for="partitaIva">Partita IVA*</label>
                    <input type="text" formControlName="partitaIva" placeholder="partita IVA" class="form-control"
                        (focusout)="checkPartitaIva()" [ngClass]="{ 'is-invalid': submitted && f.partitaIva.errors }" />
                    <div *ngIf="submitted && f.partitaIva.errors" class="invalid-feedback">
                        <div *ngIf="f.partitaIva.errors.required">Campo obbligatorio</div>
                        <div *ngIf="f.partitaIva.errors.maxlength ||f.partitaIva.errors.minlength">Lunghezza partita IVA
                            non
                            corrispode</div>
                    </div>
                </div>
                <div class="col-3 col-xl-3 margin-bottom-10">
                    <label for="nomeAzienda">Nome azienda</label>
                    <input type="text" formControlName="nomeAzienda" class="form-control" placeholder="Nome Azienda"
                        [ngClass]="{ 'is-invalid': submitted && f.nomeAzienda.errors }" />
                    <div *ngIf="submitted && f.nomeAzienda.errors" class="invalid-feedback">
                        <div *ngIf="f.nomeAzienda.errors.required">Campo obbligatorio</div>
                    </div>
                </div>

                <div class="col-3 col-xl-3 margin-bottom-10">
                    <label for="tipoAzienda">Forma Giuridica*</label>
                    <select formControlName="tipoAzienda" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.tipoAzienda.errors }">
                        <option [ngValue]="tipoAzienda" *ngFor="let tipoAzienda of listaTipiAzienda">
                            {{tipoAzienda.descrizione}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.tipoAzienda.errors" class="invalid-feedback">
                        <div *ngIf="f.tipoAzienda.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 margin-bottom-10">
                    <label for="annoCostituzione">Anno di costituzione*</label>
                    <select formControlName="annoCostituzione" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.annoCostituzione.errors }">
                        <option [ngValue]="annoCostituzione" *ngFor="let annoCostituzione of listaAnni">
                            {{annoCostituzione}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.annoCostituzione.errors" class="invalid-feedback">
                        <div *ngIf="f.annoCostituzione.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <!-- <div class="col-2 d-flex align-items-center justify-content-center margin-left-20">
                        <label>Azienda di nuova formazione <i style="margin-top:-10px" id="icon"
                        class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                        [ngbTooltip]="listaTips[3]?.descrizione"></i></label>
                        <input class="margin-left-20" type="checkbox" formControlName="startUp">
                </div> -->
            </div>
            <div class="row">
                <div class="col-4 col-lg-4 margin-bottom-10">
                    <label for="sedeLegale">Sede Legale*</label>
                    <input type="text" formControlName="sedeLegale" placeholder="Indirizzo" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.sedeLegale.errors }" />
                    <div *ngIf="submitted && f.sedeLegale.errors" class="invalid-feedback">
                        <div *ngIf="f.sedeLegale.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 margin-bottom-10">
                    <label for="provincia">Provincia*</label>
                    <select formControlName="provincia" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.provincia.errors }">

                        <option [ngValue]="provincia" *ngFor="let provincia of listaProvince">{{provincia.provincia}} -
                            ({{provincia.sigla}})</option>
                    </select>
                    <div *ngIf="submitted && f.provincia.errors" class="invalid-feedback">
                        <div *ngIf="f.provincia.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 margin-bottom-10">
                    <label for="comune">Comune*
                        <fa-icon class="cursor pull-right margin-left-20" style="font-size: 12px" [icon]="faInfo"
                            placement="top" ngbTooltip="selezionare prima la Provincia">
                        </fa-icon>
                    </label>
                    <select type="text" formControlName="comune" class="form-control form-select"
                        [ngClass]="{ 'is-invalid': submitted && f.comune.errors }">
                        <option [ngValue]="comune" *ngFor="let comune of listaComuni">{{comune.descrizione}}</option>
                    </select>
                    <div *ngIf="submitted && f.comune.errors" class="invalid-feedback">
                        <div *ngIf="f.comune.errors.required">Campo obbligatorio</div>
                    </div>
                </div>

                <div class="col-2 col-lg-2 margin-bottom-10">
                    <label for="CAP">CAP*</label>
                    <input type="text" formControlName="cap" placeholder="CAP" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.cap.errors }" />
                    <div *ngIf="submitted && f.cap.errors" class="invalid-feedback">
                        <div *ngIf="f.cap.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2 col-lg-2 margin-bottom-10">
                    <label for="telefonoCliente">Telefono cliente*</label>
                    <input type="text" formControlName="telefonoCliente" class="form-control" placeholder="Telefono"
                        [ngClass]="{ 'is-invalid': submitted && f.telefonoCliente.errors }" />
                    <div *ngIf="submitted && f.telefonoCliente.errors" class="invalid-feedback">
                        <div *ngIf="f.telefonoCliente.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 margin-bottom-10">
                    <label for="emailCliente">Email cliente*</label>
                    <input type="text" formControlName="emailCliente" class="form-control" placeholder="Email"
                        [ngClass]="{ 'is-invalid': submitted && f.emailCliente.errors }" />
                    <div *ngIf="submitted && f.emailCliente.errors" class="invalid-feedback">
                        <div *ngIf="f.emailCliente.errors.required">Campo obbligatorio</div>
                        <div *ngIf="f.emailCliente.errors.email">Inserire una mail valida</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 margin-bottom-10">
                    <label for="pecCliente">PEC cliente*</label>
                    <input type="text" formControlName="pecCliente" class="form-control" placeholder="PEC"
                        [ngClass]="{ 'is-invalid': submitted && f.pecCliente.errors }" />
                    <div *ngIf="submitted && f.pecCliente.errors" class="invalid-feedback">
                        <div *ngIf="f.pecCliente.errors.required">Campo obbligatorio</div>
                        <div *ngIf="f.pecCliente.errors.email">Inserire una mail valida</div>
                    </div>
                </div>
                <div class="col-2 col-lg-2 margin-bottom-10">
                    <label for="cdi">CDI*</label>
                    <input type="number" formControlName="cdi" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.cdi.errors }" />
                    <div *ngIf="submitted && f.cdi.errors" class="invalid-feedback">
                        <div *ngIf="f.cdi.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-2 col-lg-2 margin-bottom-10">
                    <label for="numeroContratto">Contratto n°:</label>
                    <input type="text" formControlName="numeroContratto" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.numeroContratto.errors }" />
                    <div *ngIf="submitted && f.numeroContratto.errors" class="invalid-feedback">
                        <div *ngIf="f.numeroContratto.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
            </div>
            <div class="row ">

                <div class="col-2 col-lg-2 col-xl-2 margin-bottom-10">
                    <!-- varierà con il tipo di contratto e si disabilità -->
                    <label for="dataContratto">Data contratto*</label>
                    <input type="date" formControlName="dataContratto" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.dataContratto.errors }" />
                    <div *ngIf="submitted && f.dataContratto.errors" class="invalid-feedback">
                        <div *ngIf="f.dataContratto.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 margin-bottom-10">
                    <label for="tipoCliente">Tipo Cliente*</label>
                    <select formControlName="tipoCliente" class="form-control form-select" type="text"
                        [ngClass]="{ 'is-invalid': submitted && f.tipoCliente.errors }">
                        <option [ngValue]="tipoCliente" *ngFor="let tipoCliente of listaTipiCliente">
                            {{tipoCliente.descrizione}}</option>
                    </select>
                    <div *ngIf="submitted && f.tipoCliente.errors" class="invalid-feedback">
                        <div *ngIf="f.tipoCliente.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-3 col-lg-3 margin-bottom-10">
                    <!-- varierà con il tipo di cliente -->
                    <label for="tipoContratto">Tipologia contratto*
                        <fa-icon class="cursor pull-right margin-left-20" style="font-size: 12px" [icon]="faInfo"
                            placement="top" ngbTooltip="selezionare prima il tipo di cliente">
                        </fa-icon>
                    </label>
                    <select type="text" formControlName="tipoContratto" class="form-control form-select"
                        (change)="onChangeTipoContratto()"
                        [ngClass]="{ 'is-invalid': submitted && f.tipoContratto.errors }">
                        <option [ngValue]="tipoContratto" *ngFor="let tipoContratto of listaContratti">
                            {{tipoContratto.descrizione}}</option>
                    </select>

                    <div *ngIf="submitted && f.tipoContratto.errors" class="invalid-feedback">
                        <div *ngIf="f.tipoContratto.errors.required">Campo obbligatorio</div>
                    </div>
                </div>

                <div class="col-2 col-lg-2 col-xl-2 margin-bottom-10">
                    <!-- varierà con il tipo di contratto e si disabilità -->
                    <label for="maxPartiteIva">N.ro partite IVA*</label>
                    <input type="number" formControlName="maxPartiteIva" class="form-control" min="1"
                        [ngClass]="{ 'is-invalid': submitted && f.maxPartiteIva.errors }" />
                    <div *ngIf="submitted && f.maxPartiteIva.errors" class="invalid-feedback">
                        <div *ngIf="f.maxPartiteIva.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
                <div class="col-2 col-lg-2 col-xl-2 margin-bottom-10">
                    <!-- varierà con il tipo di contratto e si disabilità -->
                    <label for="numeroUtenze">N.ro utenze*</label>
                    <input type="number" formControlName="numeroUtenze" class="form-control" min="1"
                        [ngClass]="{ 'is-invalid': submitted && f.numeroUtenze.errors }" />
                    <div *ngIf="submitted && f.numeroUtenze.errors" class="invalid-feedback">
                        <div *ngIf="f.numeroUtenze.errors.required">Campo obbligatorio</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 text-center" style="margin: 20px 0;">
        <button class="btn btn-primary" (click)="submit()">Salva</button>
        <button class="btn btn-secondary" style="margin-left: 5px" type="button" (click)="onReset()">
            Reset
        </button>
    </div>
</div>