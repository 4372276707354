<div class="container-correlazioni">

    <ng-container *ngIf="idRichiedenteTemp==undefined">
        <div class="row nopadding">
            <div class="col-8 nopadding">
                <h2>Gestione correlazioni linee di business</h2>
            </div>
        </div>
        <div class="row nopadding">
            <div class="col-12 nopadding d-flex align-items-center margin-top-10">
                <h3> Inserisci l'azienda di cui vuoi configurare l'ambiente.</h3>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="idRichiedenteTemp!=undefined">


        <div *ngIf="lineeBusiness.length == 1">
            <p> Per configurare le correlazioni sono necessarie almeno 2 linee di business</p>
        </div>
    
    <div [formGroup]="correlazioneAmbienteFormGroup" *ngIf="lineeBusiness.length > 1">
    
    
        <div class="row d-flex  margin-top-20">
            <div class="col-9 ">
                <h2>Configurazione correlazioni tra le linee di business:&nbsp;&nbsp;</h2>
            </div>
            <div class="col-3 text-center d-flex align-items-center justify-content-end">
                <button class="btn btn-success" type="submit" 
                    style="margin-left: 20px" (click)="submitCors()">Salva</button>
                <button class="btn btn-secondary" style="margin-left: 20px" type="button" (click)="annulla()" >
                    Annulla
                </button>
            </div> 
        </div>
    
        <div formArrayName="correlazioneAmbiente" class="row">
            <div *ngFor="let linea of correlazioneAmbienteFormArray; index as i" [formGroupName]="i" class="linea col-3" >
                <label > Seleziona la correlazione numero {{i+1}}</label>
    
                <select #couple type="text" class="form-control form-select  " formControlName="correlazione"
                    [ngClass]="{ 'is-invalid':submitted && correlazioneAmbienteFormArray[i].controls.correlazione.errors }"
                    (change)="onChangeCouple(couple.value,i)">
                    <option value="" [hidden]="true">  Seleziona un valore...</option>
                    <option style="font-weight: bold;" *ngFor="let el of cartProductCorr;index as k"
                                value='{{[el.uno.id,el.due.id]}}'
                                [hidden]="i>0 && (!filterOtherByFirst(this.firstLb,el.uno.id) || filterNext(this.otherLb,el.due.id,i))"
                                [selected]="presetSelect(i,[el.uno.id,el.due.id],'label')">
                                {{el.uno.descrizione}}-{{el.due.descrizione}}
                     </option>
                 </select>
    
           <div class="row"  style="min-height: 25px;color:red;">
                <div class="testo" *ngIf="submitted && correlazioneAmbienteFormArray[i].controls.correlazione.errors"> 
                    Campo obbligatorio 
                </div>
           </div>
        
    
            <select #corr type="text" class="form-control form-select margin-top-10"
                [ngClass]="{ 'is-invalid':submitted && correlazioneAmbienteFormArray[i].controls.valoreCorrelazione.errors }"
                formControlName="valoreCorrelazione" 
                (change)="cambiaOpt(corr.value,i)">
                <option value=""  [hidden]="true"> Seleziona un valore... </option>
                <option style="font-weight: bold;" *ngFor="let dc of listaTipoCorrelazioniTraLinee;index as l"
                    value="{{dc.id}}"
                    [hidden]="!checkAble(dc.id,i)"
                     [selected]="presetSelect(i,dc.id,'NOTlabel')">
                    {{dc.descrizione}}
                </option>
            </select>
            <div class="row" style="min-height: 25px;color:red;" >
                <div class="testo" *ngIf="submitted && correlazioneAmbienteFormArray[i].controls.valoreCorrelazione.errors"
                    > 
                    Campo obbligatorio 
                </div>
            </div>
                                         
            </div>
        </div>
    </div>
 
    </ng-container>



    

</div>