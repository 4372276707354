import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ToolTip } from '../interface/toolTip';
import { SectionDb } from '../interface/sezione';



/* scrivere la chiamata get e copiare lo script nell'ng on init del component */
const QuestionarioUrl = environment.apiUrlQuestionario;
@Injectable({
  providedIn: 'root'
})
export class ToolTipService {

  tipsArray: BehaviorSubject<ToolTip[]> = new BehaviorSubject<ToolTip[]>([]);
  //tipsArray: Subject<ToolTip[]> = new Subject<ToolTip[]>();

  constructor(private http: HttpClient) { }

  getAnagraficaAziendaTipsContent(): Observable<any> {
    return this.http.get<any>(QuestionarioUrl + '/fasGoAlgo/getGlossarioToolTip/tipAnagraficaAzienda');
  }

  getModelloUniversaleTipsContent(): Observable<any> {
    return this.http.get(QuestionarioUrl + '/fasGoAlgo/getGlossarioToolTip/modelloUniversale');
  }

  getTipsContent(codiceSezione: string): Observable<ToolTip[]> {
    return this.http.get<ToolTip[]>(QuestionarioUrl + '/tips/getbysez/' + codiceSezione);
  }
  //carico i tip sul service per sezioni grosse come GBP e CAM
  getAllTipsContent(codiceSezione: string): void {
    this.http.get<ToolTip[]>(QuestionarioUrl + '/tips/getbysez/' + codiceSezione).subscribe(risultato => {
      if (risultato) {
        let arr: ToolTip[] = risultato
        /* risultato.forEach(tip => {
          let obj: any;
          obj[tip.numero] = tip;
        }) */
        //arr.sort((n1, n2) => n1.numero - n2.numero)
        //bisogna modificare l'array con una chiave valore con l'id del tooltip. altrimenti ad ogni modifica sfancula l'ordine
        this.tipsArray.next(arr);
      }
    });
  }




  takeAll(): Observable<ToolTip[]> {
    return this.http.get<ToolTip[]>(QuestionarioUrl + '/tips/getall');
  }

  prepareSection(): Observable<SectionDb[]> {
    return this.http.get<SectionDb[]>(QuestionarioUrl + '/sez/get');
  }


  _submitTip(dataValues: any): Observable<ToolTip> {
    return this.http.post<ToolTip>(QuestionarioUrl + '/tips/inserisci', dataValues);
  }


  _modifyTip(dataValues: any): Observable<ToolTip> {
    return this.http.put<ToolTip>(QuestionarioUrl + '/tips/modifica', dataValues);
  }


  _onChange(myform: any): Observable<ToolTip[]> {
    return this.http.get<ToolTip[]>(QuestionarioUrl + '/tips/getbysez/' + myform.value.sez);
  }

}     