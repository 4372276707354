import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BeanStatico } from '../condivisi/beanStatico';

@Component({
  selector: 'app-censimento-ateco-secondari',
  templateUrl: './censimento-ateco-secondari.component.html',
  styleUrls: ['./censimento-ateco-secondari.component.css']
})
export class CensimentoAtecoSecondariComponent implements OnInit {
  @Input() atecoSecondario: string;
  @Input() i: number;
  @Input('formGroup') atecoForm: UntypedFormGroup;
  @Output() selectedAteco = new EventEmitter<number>();
  @Input() submitted: boolean;


  listaAtecoSecondarioSezione: BeanStatico[] = [];
  listaAtecoSecondarioDivisione: BeanStatico[] = [];
  listaAtecoSecondarioClasse: BeanStatico[] = [];
  listaAtecoSecondarioSottocategoria: BeanStatico[] = [];

  tempIdDivisione: number = -1;
  tempIdClasse: number = -1;
  tempIdSottocategoria: number = -1;


  get f() { return this.atecoForm.controls; }

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.onChangeAteco();
  }

  /* carica le select e gestisce gli on change */
  onChangeAteco() {
    /* ateco Sezione*/
    this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoSezioni').subscribe(data => {
      this.listaAtecoSecondarioSezione = [];
      data.forEach(element => {
        let atecoSezione = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
        this.listaAtecoSecondarioSezione.push(atecoSezione)
      });
      if (this.atecoSecondario != null) {
        this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/codiciAteco', this.atecoSecondario).subscribe(response => {
          this.tempIdDivisione = response.idDivisione;
          this.tempIdClasse = response.idClasse;
          this.tempIdSottocategoria = response.idSottocategoria;
          this.listaAtecoSecondarioSezione.forEach(atecoSezione => {
            if (atecoSezione.id == response.idSezione) {
              this.f.atecoSecondarioSezione.setValue(atecoSezione);
            }
          })
        })
      }
    });
    /* ateco divisione */
    this.f.atecoSecondarioSezione.valueChanges.subscribe(selectedValue => {
      this.listaAtecoSecondarioDivisione = [];
      if (this.tempIdDivisione == -1) {
        this.f.atecoSecondarioDivisione.setValue('');
      }
      if (selectedValue) {
        this.f.atecoSecondarioDivisione.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoDivisioniByIdSezione', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const atecoDivisione = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoSecondarioDivisione.push(atecoDivisione)
            if (element.id == this.tempIdDivisione) {
              this.f.atecoSecondarioDivisione.setValue(atecoDivisione);
              //this.f.comune.disable();
              this.tempIdDivisione = -1;
            }
          });
        });
      }
      else {
        this.f.atecoSecondarioDivisione.disable();
      }
      this.f.atecoSecondarioDivisione.updateValueAndValidity();
    })
    /* ateco classe */
    this.f.atecoSecondarioDivisione.valueChanges.subscribe(selectedValue => {
      this.listaAtecoSecondarioClasse = [];
      if (this.tempIdClasse == -1) {
        this.f.atecoSecondarioClasse.setValue('');
      }
      if (selectedValue) {
        this.f.atecoSecondarioClasse.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoClassiByIdDivisione', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const atecoClasse = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoSecondarioClasse.push(atecoClasse)
            if (element.id == this.tempIdClasse) {
              this.f.atecoSecondarioClasse.setValue(atecoClasse);
              //this.f.comune.disable();
              this.tempIdClasse = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.atecoSecondarioClasse.disable();
      }
      this.f.atecoSecondarioClasse.updateValueAndValidity();
    })
    /* ateco sottocategoria */
    this.f.atecoSecondarioClasse.valueChanges.subscribe(selectedValue => {
      this.listaAtecoSecondarioSottocategoria = [];
      if (this.tempIdSottocategoria == -1) {
        this.f.atecoSecondarioSottocategoria.setValue('');
      }
      if (selectedValue) {
        this.f.atecoSecondarioSottocategoria.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoSottocategorieByIdClasse', selectedValue.id).subscribe(data => {
          if (data.length == 0) {
            this.listaAtecoSecondarioSottocategoria.push({ id: 0, descrizione: 'N/A' })
          }
          data.forEach(element => {
            const atecoSottocategoria = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoSecondarioSottocategoria.push(atecoSottocategoria)
            if (element.id == this.tempIdSottocategoria) {
              this.f.atecoSecondarioSottocategoria.setValue(atecoSottocategoria);
              //this.f.comune.disable();
              this.tempIdSottocategoria = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.atecoSecondarioSottocategoria.disable();
      }
      this.f.atecoSecondarioSottocategoria.updateValueAndValidity();
    })
  }

  eliminaAteco(index: number) {
    /* evenetemitter */
    this.selectedAteco.emit(index);
  }
}
