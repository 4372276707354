<div class="container-fluid" [formGroup]="prodottiForm">
    <div class="row">
        <div class="col-12 d-flex justify-content-end align-items-center margin-bottom-10 margin-top-10">
            <button class=" d-flex justify-content-center btn fa fa-plus fa-lg pointer" aria-hidden="true" type="button"
                (click)="addProdotto()" placement="left" ngbTooltip="Aggiungi prodotto alla Linea di Business">
            </button>
            <button class="btn btn-success" type="submit" (click)="onSubmit()" style="margin-left: 20px">Salva</button>
            <button class="btn btn-secondary" style="margin-left: 20px" type="button" (click)="annulla()">
                Annulla
            </button>
        </div>
    </div>
    <div id="container-linee-bp-configurazione-ambiente">
        <div class="row" formArrayName="prodotti">
            <div class="col-12" *ngFor="let prodottoForm of prodottiFormArray;let j=index">
                <hr>
                <app-prodotto [formGroup]="prodottoForm" [i]="j" (selectedProdotto)="eliminaProdotto($event)"
                    [lineaBusiness]="lineaBusiness" [valoreMax]="valoreMax" [dropsDown]="dropsDown"
                    [submitted]="submitted"><!-- (presenzaFidelizzazione)="onChangeFidelizzazione($event)" -->
                </app-prodotto>
            </div>
        </div>
    </div>
</div>