<div id="conatainer_endStroke">
    <div class="mat-body-text-2"><span style="color: var(--main-black);">INDICI AL</span>
        31.12.{{this.durataBP.annoStart + this.durataBP.durataAnni - 1}}</div>
    <table mat-table [dataSource]="dataSourceMWB_first" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="More Likely Case">
            <th mat-header-cell class="mlc" *matHeaderCellDef> Expected case </th>
            <td mat-cell *matCellDef="let element"> {{element.mlcString}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_ml">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_mlc| number:'1.0-2':'it'}} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Worst Case">
            <th mat-header-cell *matHeaderCellDef class="wc"> Worst Case </th>
            <td mat-cell *matCellDef="let element"> {{element.wcString}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_wc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_wc | number:'1.0-2':'it' }} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Best Case">
            <th mat-header-cell *matHeaderCellDef class="bc"> Best Case </th>
            <td mat-cell *matCellDef="let element"> {{element.bcString}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_bc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_bc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <div class="mat-body-text-2"><span style="color: var(--main-black);">MEDIA ANNUA PROSPETTICA</span>
        {{this.durataBP.annoStart}}-{{this.durataBP.annoStart + this.durataBP.durataAnni - 1}}</div>
    <table mat-table [dataSource]="dataSourceMWB_second" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="More Likely Case">
            <th mat-header-cell *matHeaderCellDef class="mlc"> Expected case </th>
            <td mat-cell *matCellDef="let element"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') + ' €' :
                '-'}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_ml">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_mlc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Worst Case">
            <th mat-header-cell *matHeaderCellDef class="wc"> Worst Case </th>
            <td mat-cell *matCellDef="let element"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') + ' €' :
                '-'}}
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_wc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_wc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Best Case">
            <th mat-header-cell *matHeaderCellDef class="bc"> Best Case </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') + ' €' :
                '-'}}
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_bc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_bc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <table mat-table [dataSource]="dataSourceMWB_thirt" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="More Likely Case">
            <th mat-header-cell *matHeaderCellDef class="mlc"> Expected case </th>
            <td mat-cell *matCellDef="let element">{{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') + ' €' :
                '-'}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_ml">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_mlc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Worst Case">
            <th mat-header-cell *matHeaderCellDef class="wc"> Worst Case </th>
            <td mat-cell *matCellDef="let element"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') + ' €' :
                '-'}}
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_wc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_wc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Best Case">
            <th mat-header-cell *matHeaderCellDef class="bc"> Best Case </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') + ' €' :
                '-'}}
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_bc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_bc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <div class="mat-body-text-2"><span style="color: var(--main-black);">STATO PATRIMONIALE</span>
        31.12.{{this.durataBP.annoStart + this.durataBP.durataAnni - 1}}</div>
    <table mat-table [dataSource]="dataSourceMWB_fourt" class="mat-elevation-z8 md-tab-center ">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="More Likely Case">
            <th mat-header-cell *matHeaderCellDef class="mlc"> Expected case </th>
            <td mat-cell *matCellDef="let element"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') + ' €' :
                '-'}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_ml">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_mlc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Worst Case">
            <th mat-header-cell *matHeaderCellDef class="wc"> Worst Case </th>
            <td mat-cell *matCellDef="let element">{{element.wc != 0 ? (element.wc | number:'1.0-0':'it') + ' €' : '-'}}
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_wc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_wc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Best Case">
            <th mat-header-cell *matHeaderCellDef class="bc"> Best Case </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') + ' €' :
                '-'}}
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_bc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_bc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-5">
            <table mat-table [dataSource]="dataSourcePerditaSortino"
                class="mat-elevation-z8 sm-tab mx-auto last-table-margin" *ngIf="idRuoloUtente != 6">

                <!--- Note that these columns can be defined in any order.
                                                                        The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="INDICI">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> {{element.indice}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="VALORE">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> {{element.value | number:'1.0-0':'it'}} € </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="ANNO">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> {{element.anno}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
        <div class="col-1">

        </div>
        <div class="col-6" style="padding-left: max(25px, 0.5vw);">
            <table mat-table [dataSource]="dataSourcePerditaSortinoTwo"
                class="mat-elevation-z8 sm-tab mx-auto last-table-margin">

                <!--- Note that these columns can be defined in any order.
                                                                The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="INDICI">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> {{element.label}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="VALORE">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> {{element.value | number:'1.2':'it'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSortino"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsSortino;"></tr>
            </table>
        </div>
    </div>
</div>