<div id="modal-content-wrapper">
    <div class="modal-header" [ngClass]="StyleClass[0]">

        <h3 id=" modal-title" style="margin-bottom:0px ;" *ngIf="modalPar.title"><strong>{{ modalPar.title }}</strong>
        </h3>

        <!-- tasto X scompare nella modale messaggio -->
        <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close(0)"></button>
    </div>

    <div class="modal-body">
        <div *ngIf="modalPar.name =='salvaBusinessPlanNuovo'">
            <h4 style="margin-top: 0;"><strong>NB:</strong> A partire dal 01/04/{{this.data.getFullYear()}} per la
                creazione di un nuovo
                Business plan sarà necessario inserire il bilancio dell' anno di esercizio.
                {{this.data.getFullYear()-1}}.</h4>
            <h4>Seleziona azienda osservata:</h4>
            <select type="text" [formControl]="azienda" class="form-control form-select" (change)="onchangeAzienda()">
                <option [ngValue]="azienda" *ngFor="let azienda of listaAziende">{{azienda.descrizione}}
                </option>
            </select>
            <h4>Riferimento salvataggio:</h4>
            <input type="text" [(ngModel)]="salvataggioBp" class="form-control " />
            <span *ngIf="salvataggioBp == ''">Inserire il nome del riferimento</span>
            <span style="color:red;" *ngIf="!checkNameCorrect">Esiste già un business plan con questo nome per
                questa
                azienda.</span>
            <div *ngIf="invalidRichiedente" class="text margin-top-20" style="color:red ;">Errore! <br> Esiste già un
                Business in plan in
                preparazione generato il {{this.currentData}},
                associato a questa azienda. Cambiare azienda selezionata o procedere con il calcola del Business in plan
                in preparazione.
            </div>
        </div>
        <div *ngIf="modalPar.name.includes('salvaBp') ">
            <h4>Riferimento salvataggio:</h4>
            <input type="text" [(ngModel)]="nomeSalvataggio" class="form-control" />
            <span style="color:red;" *ngIf="nomeSalvataggio == ''">Inserire il nome del salvataggio.</span>
            <span style="color:red;" *ngIf="!checkNameCorrect">Esiste già un business plan con questo nome per
                questa azienda.</span>
        </div>
        <!-- modale form che compare con le modali di salvataggio anche di richiedente e utente-->
        <div *ngIf="modalPar.name =='salva'">
            <h4>Riferimento salvataggio:</h4>
            <input type="text" [(ngModel)]="nomeSalvataggio" class="form-control" />
            <span style="color:red;" *ngIf="nomeSalvataggio == ''">Inserire il nome del salvataggio.</span>
        </div>

        <!-- modale che compare quando viene trovata la p iva a db -->
        <div *ngIf="modalPar.name.includes('checkdata')" style="margin: auto;">
            <div class="text" style="margin: auto;"> la partita IVA:
                <strong>{{modalPar.description.partitaIva}}</strong> è
                associata a: <br>
                <strong>azienda:</strong> {{modalPar.description.ragioneSociale}} <br>
                <strong>Indirizzo :</strong> {{modalPar.description.sedeLegale}}, {{modalPar.description.cap}},
                {{modalPar.description.comune}} ({{modalPar.description.provincia}}). <br>
            </div>
            <div class="text margin-top-20">
                {{modalPar.question}}
            </div>
        </div>
        <!-- modali di check e messaggi semplici  *ngIf="modalPar.name.includes('errore')-->
        <div style="margin: auto;"
            *ngIf="modalPar.name.includes('errore') || modalPar.name.includes('messaggio')|| modalPar.name.includes('elimina')|| modalPar.name == 'salvamodifiche'
            || modalPar.name == 'esci' || modalPar.name == 'annulla'|| modalPar.name == 'confermaBp'|| modalPar.name =='assenzaBilancio'">
            <div class="text-center" style="margin: auto;"> <strong style="white-space: pre-line">{{
                    modalPar.description }} </strong>
            </div>

        </div>
        <div style="margin: auto;" *ngIf="modalPar.name=='idleCD'">
            <div class="text-center" style="margin: auto;"> <strong style="white-space: pre-line">{{
                    modalPar.description }} </strong><span style="font-size: 24px;"> {{idleCd}}
                </span><strong style="white-space: pre-line">secondi.</strong>
                <br><strong style="white-space: pre-line">{{
                    modalPar.description2 }} </strong>
            </div>

        </div>


    </div>
    <div class="modal-footer">
        <button type="button" id="modal-action-button" *ngIf="this.modalPar.name=='salvaBusinessPlanNuovo'"
            [ngClass]="StyleClass[1]" [disabled]="buttonDisabled"
            (click)="checknameBp('')">{{modalPar.actionButtonText}}</button>
        <!-- [disabled] disabilita il salvataggio se il campo è vuoto -->
        <!-- button salva bp semplice -->
        <button type="button" id="modal-action-button" *ngIf="this.modalPar.name.includes('salvaBp')"
            [ngClass]="StyleClass[1]" [disabled]="nomeSalvataggio == ''" (click)="checknameBp('')"
            class="btn btn-success">{{modalPar.actionButtonText}}</button>
        <!-- button salva bp def -->
        <button type="button" id="modal-action-button" *ngIf="this.modalPar.name=='salvaBpDef'"
            [disabled]="nomeSalvataggio == ''" (click)="checknameBp('definitivo')"
            class="btn btn-primary">{{modalPar.actionButtonConferma}}</button>

        <button type="button" id="modal-action-button" *ngIf="displayButton()" [ngClass]="StyleClass[1]"
            [disabled]="nomeSalvataggio == ''" (click)="actionFunction('')">{{modalPar.actionButtonText}}</button>
        <!-- BUTTON UNDO -->
        <button type="button" [ngClass]="StyleClass[2]" id="modal-close-button"
            (click)="modalRef.close(0)">{{modalPar.undoButtonText}}</button>
    </div>