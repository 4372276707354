import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { indicAsIs, indicAsIsPicchi } from '../interface/indici-as-is';
import { indiciTimeShift } from '../interface/indici-time-shift';
import { SplashpageService } from '../services/splashpage.service';
import { TabelleSecondarieService } from '../services/tabelle-secondarie.service';
import { TimelineButtonsNavigatorService } from '../services/timeline-buttons-navigator.service';

import { mergeMap } from 'rxjs/operators';
import { SplashpageServiceService } from 'src/app/_services/splashpage-service.service';
import { DatiQuestionarioService } from 'src/app/_services/dati-questionario.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';

@Component({
  selector: 'app-sintesi-precisely-year',
  templateUrl: './sintesi-precisely-year.component.html',
  styleUrls: ['./sintesi-precisely-year.component.css']
})
export class SintesiPreciselyYearComponent implements OnInit, OnDestroy {



  displayedColumns: string[] = ['INDICI', 'VALORE', 'ANNO'];
  dataEndStrokeAppoggio;
  valoreMax: any = {};

  ELEMENT_ML_W_B_first: indiciTimeShift[] = [
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 }
  ]

  ELEMENT_ML_W_B_second: indiciTimeShift[] = [
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 }
  ]

  ELEMENT_ML_W_B_third: indiciTimeShift[] = [
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
  ]


  ELEMENT_ML_W_B_fourt: indiciTimeShift[] = [
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
    { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 }
  ]

  displayedColumnsCenter: string[] = ['More Likely Case', '%PROB_ml', 'Worst Case', '%PROB_wc', 'Best Case', '%PROB_bc',];
  dataSourceMWB_first = this.ELEMENT_ML_W_B_first;
  dataSourceMWB_second = this.ELEMENT_ML_W_B_second;
  dataSourceMWB_thirt = this.ELEMENT_ML_W_B_third;
  dataSourceMWB_fourt = this.ELEMENT_ML_W_B_fourt;

  @Input() select_year: number;
  year: number;
  idBplan: number;

  isPersistent: boolean = false;

  constructor(private configurazioneAmbienteService: ConfigurazioneAmbienteService, private tabelleSecondarieService: TabelleSecondarieService,
    private timelineButtonsNavigatorService: TimelineButtonsNavigatorService,
    private splashpageService: SplashpageService,
    private datiQuestionarioService: DatiQuestionarioService,
    private splashpage_year_volatili: SplashpageServiceService,
    private splash_service: SplashpageServiceService,
    private serviceBp: ConfigurazioneBusinessPlanService) { }

  subscription_PicchiDataTable: Subscription;
  subscription_YearData: Subscription;
  risultatiVolatileSubscription: Subscription
  yearSubscription: Subscription
  selezionatoSplashpageAnniSubscription: Subscription

  ngOnDestroy(): void {
    if (!this.isPersistent) {
      //this.subscription_PicchiDataTable.unsubscribe();
      this.selezionatoSplashpageAnniSubscription.unsubscribe()
    }
    this.risultatiVolatileSubscription.unsubscribe();
    this.yearSubscription.unsubscribe()

    //this.subscription_YearData.unsubscribe()
  }

  ngOnInit(): void {
    /*
        this.datiQuestionarioService.checkFormTouched.subscribe(isVolatile => {
    
          this.isPersistent = !isVolatile;
    
        })*/

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isPersistent = !isVolatile;
    })

    this.splash_service.BpId$.subscribe(
      id => {

        this.idBplan = id;
      }
    )

    this.yearSubscription = this.timelineButtonsNavigatorService.YEARS$.subscribe(year => {
      this.year = year;

      if (this.isPersistent) {

        this.splashpage_year_volatili.postGetAnnoPreciso(this.idBplan, this.year).subscribe(yeardDate => {

          let yeardDate_str = JSON.stringify(yeardDate);
          let valueYearDate_json = JSON.parse(yeardDate_str);
          console.log(valueYearDate_json + 'D')
          this.dataEndStrokeAppoggio = valueYearDate_json;
          this.ELEMENT_ML_W_B_first = [
            {
              mlc: valueYearDate_json.indici_end_stroke[0].mlcString, prob_mlc: valueYearDate_json.indici_end_stroke[0].prob_mlc, wc: valueYearDate_json.indici_end_stroke[0].wcString,
              prob_wc: valueYearDate_json.indici_end_stroke[0].prob_wc, bc: valueYearDate_json.indici_end_stroke[0].bcString, prob_bc: valueYearDate_json.indici_end_stroke[0].prob_bc
            },
            {
              mlc: valueYearDate_json.indici_end_stroke[1].mlcString, prob_mlc: valueYearDate_json.indici_end_stroke[1].prob_mlc, wc: valueYearDate_json.indici_end_stroke[1].wcString,
              prob_wc: valueYearDate_json.indici_end_stroke[1].prob_wc, bc: valueYearDate_json.indici_end_stroke[1].bcString, prob_bc: valueYearDate_json.indici_end_stroke[1].prob_bc
            },
            {
              mlc: valueYearDate_json.indici_end_stroke[2].mlcString, prob_mlc: valueYearDate_json.indici_end_stroke[2].prob_mlc, wc: valueYearDate_json.indici_end_stroke[2].wcString,
              prob_wc: valueYearDate_json.indici_end_stroke[2].prob_wc, bc: valueYearDate_json.indici_end_stroke[2].bcString, prob_bc: valueYearDate_json.indici_end_stroke[2].prob_bc
            }


            /*,
        {
          mlc: valueYearDate_json.indici_end_stroke[3].mlc, prob_mlc: valueYearDate_json.indici_end_stroke[3].prob_mlc, wc: valueYearDate_json.indici_end_stroke[3].wc,
          prob_wc: valueYearDate_json.indici_end_stroke[3].prob_wc, bc: valueYearDate_json.indici_end_stroke[3].bc, prob_bc: valueYearDate_json.indici_end_stroke[3].prob_bc
        },
        {
          mlc: valueYearDate_json.indici_end_stroke[4].mlc, prob_mlc: valueYearDate_json.indici_end_stroke[4].prob_mlc, wc: valueYearDate_json.indici_end_stroke[4].wc,
          prob_wc: valueYearDate_json.indici_end_stroke[4].prob_wc, bc: valueYearDate_json.indici_end_stroke[4].bc, prob_bc: valueYearDate_json.indici_end_stroke[4].prob_bc
        }*/

          ]

          this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
            valMax => {
              valMax.forEach(element => {
                if (element.error == null) {
                  this.valoreMax[element.nomeCampo] = element.valoreMax;
                }
              });

              if (valueYearDate_json.indici_end_stroke[0].mlc > this.valoreMax['dscr']) {
                this.ELEMENT_ML_W_B_first[0].mlc = '> ' + this.valoreMax['dscr'];
              }
              else if (valueYearDate_json.indici_end_stroke[0].mlc < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_ML_W_B_first[0].mlc = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (valueYearDate_json.indici_end_stroke[0].bc > this.valoreMax['dscr']) {
                this.ELEMENT_ML_W_B_first[0].bc = '> ' + this.valoreMax['dscr'];
              }
              else if (valueYearDate_json.indici_end_stroke[0].bc < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_ML_W_B_first[0].bc = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (valueYearDate_json.indici_end_stroke[0].wc > this.valoreMax['dscr']) {
                this.ELEMENT_ML_W_B_first[0].wc = '> ' + this.valoreMax['dscr'];
              }
              else if (valueYearDate_json.indici_end_stroke[0].wc < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_ML_W_B_first[0].wc = '< ' + this.valoreMax['dscr'] * (-1);
              }

              this.dataSourceMWB_first
                = this.ELEMENT_ML_W_B_first;
            }
          )

          this.ELEMENT_ML_W_B_second = [
            {
              mlc: valueYearDate_json.media_annua_propspetticca_conto_economico[0].mlc, prob_mlc: valueYearDate_json.media_annua_propspetticca_conto_economico[0].prob_mlc, wc: valueYearDate_json.media_annua_propspetticca_conto_economico[0].wc,
              prob_wc: valueYearDate_json.media_annua_propspetticca_conto_economico[0].prob_wc, bc: valueYearDate_json.media_annua_propspetticca_conto_economico[0].bc, prob_bc: valueYearDate_json.media_annua_propspetticca_conto_economico[0].prob_bc
            },
            {
              mlc: valueYearDate_json.media_annua_propspetticca_conto_economico[1].mlc, prob_mlc: valueYearDate_json.media_annua_propspetticca_conto_economico[1].prob_mlc, wc: valueYearDate_json.media_annua_propspetticca_conto_economico[1].wc,
              prob_wc: valueYearDate_json.media_annua_propspetticca_conto_economico[1].prob_wc, bc: valueYearDate_json.media_annua_propspetticca_conto_economico[1].bc, prob_bc: valueYearDate_json.media_annua_propspetticca_conto_economico[1].prob_bc
            },
            {
              mlc: valueYearDate_json.media_annua_propspetticca_conto_economico[2].mlc, prob_mlc: valueYearDate_json.media_annua_propspetticca_conto_economico[2].prob_mlc, wc: valueYearDate_json.media_annua_propspetticca_conto_economico[2].wc,
              prob_wc: valueYearDate_json.media_annua_propspetticca_conto_economico[2].prob_wc, bc: valueYearDate_json.media_annua_propspetticca_conto_economico[2].bc, prob_bc: valueYearDate_json.media_annua_propspetticca_conto_economico[2].prob_bc
            },
            {
              mlc: valueYearDate_json.media_annua_propspetticca_conto_economico[3].mlc, prob_mlc: valueYearDate_json.media_annua_propspetticca_conto_economico[3].prob_mlc, wc: valueYearDate_json.media_annua_propspetticca_conto_economico[3].wc,
              prob_wc: valueYearDate_json.media_annua_propspetticca_conto_economico[3].prob_wc, bc: valueYearDate_json.media_annua_propspetticca_conto_economico[3].bc, prob_bc: valueYearDate_json.media_annua_propspetticca_conto_economico[3].prob_bc
            }
          ]

          this.ELEMENT_ML_W_B_third = [
            {
              mlc: valueYearDate_json.media_annua_propspetticca_cash_flow[0].mlc, prob_mlc: valueYearDate_json.media_annua_propspetticca_cash_flow[0].prob_mlc, wc: valueYearDate_json.media_annua_propspetticca_cash_flow[0].wc,
              prob_wc: valueYearDate_json.media_annua_propspetticca_cash_flow[0].prob_wc, bc: valueYearDate_json.media_annua_propspetticca_cash_flow[0].bc, prob_bc: valueYearDate_json.media_annua_propspetticca_cash_flow[0].prob_bc
            },
            {
              mlc: valueYearDate_json.media_annua_propspetticca_cash_flow[1].mlc, prob_mlc: valueYearDate_json.media_annua_propspetticca_cash_flow[1].prob_mlc, wc: valueYearDate_json.media_annua_propspetticca_cash_flow[1].wc,
              prob_wc: valueYearDate_json.media_annua_propspetticca_cash_flow[1].prob_wc, bc: valueYearDate_json.media_annua_propspetticca_cash_flow[1].bc, prob_bc: valueYearDate_json.media_annua_propspetticca_cash_flow[1].prob_bc
            }
          ]


          this.ELEMENT_ML_W_B_fourt = [
            {
              mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[0].mlc, prob_mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[0].prob_mlc, wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[0].wc,
              prob_wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[0].prob_wc, bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[0].bc, prob_bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[0].prob_bc
            },
            {
              mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[1].mlc, prob_mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[1].prob_mlc, wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[1].wc,
              prob_wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[1].prob_wc, bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[1].bc, prob_bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[1].prob_bc
            },
            {
              mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[2].mlc, prob_mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[2].prob_mlc, wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[2].wc,
              prob_wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[2].prob_wc, bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[2].bc, prob_bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[2].prob_bc
            },
            {
              mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[3].mlc, prob_mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[3].prob_mlc, wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[3].wc,
              prob_wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[3].prob_wc, bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[3].bc, prob_bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[3].prob_bc
            },
            {
              mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[4].mlc, prob_mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[4].prob_mlc, wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[4].wc,
              prob_wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[4].prob_wc, bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[4].bc, prob_bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[4].prob_bc
            },
            {
              mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[5].mlc, prob_mlc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[5].prob_mlc, wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[5].wc,
              prob_wc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[5].prob_wc, bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[5].bc, prob_bc: valueYearDate_json.propspetticca_stato_patrimoniale_end_stroke[5].prob_bc
            },
          ]

          this.dataSourceMWB_first = this.ELEMENT_ML_W_B_first;
          this.dataSourceMWB_second = this.ELEMENT_ML_W_B_second;
          this.dataSourceMWB_thirt = this.ELEMENT_ML_W_B_third;
          this.dataSourceMWB_fourt = this.ELEMENT_ML_W_B_fourt;
        })
      }
    })


    /*this.timelineButtonsNavigatorService.INDEX$.subscribe(index => {
      this.ELEMENT_ML_W_B_first = [
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 220, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 }
      ]

      this.ELEMENT_ML_W_B_second = [
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 }
      ]

      this.ELEMENT_ML_W_B_third = [
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
      ]


      this.ELEMENT_ML_W_B_fourt = [
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 },
        { mlc: 0, prob_mlc: 0, wc: 0, prob_wc: 0, bc: 0, prob_bc: 0 }
      ]

      //this.ELEMENT_AS_IS_PERDITA_SORTINO = [
      //{ indice: "PICCO DEBITI", value: 0 },
      //{ indice: "PICCO CREDITI", value: 0 },
      //{ indice: "PICCO PFN", value: 0 }
      //];

      this.dataSourceMWB_first = this.ELEMENT_ML_W_B_first;
      this.dataSourceMWB_second = this.ELEMENT_ML_W_B_second;
      this.dataSourceMWB_thirt = this.ELEMENT_ML_W_B_third;
      this.dataSourceMWB_fourt = this.ELEMENT_ML_W_B_fourt;
      //this.dataSourcePerditaSortino = this.ELEMENT_AS_IS_PERDITA_SORTINO;
    })

    this.subscription_YearData = this.timelineButtonsNavigatorService.getYearSelectData(this.year).subscribe(yearDataValue => {

    })*/

    if (!this.isPersistent) {
      this.selezionatoSplashpageAnniSubscription = this.splashpage_year_volatili.selezionatoSplashpageAnni$.subscribe(annoData => {
        console.log(annoData)
        if (annoData != null) {
          this.ELEMENT_ML_W_B_first = [
            {
              mlc: annoData[0].mlcString, prob_mlc: annoData[0].prob_mlc, wc: annoData[0].wcString,
              prob_wc: annoData[0].prob_wc, bc: annoData[0].bcString, prob_bc: annoData[0].prob_bc
            },
            {
              mlc: annoData[1].mlcString, prob_mlc: annoData[1].prob_mlc, wc: annoData[1].wcString,
              prob_wc: annoData[1].prob_wc, bc: annoData[1].bcString, prob_bc: annoData[1].prob_bc
            },
            {
              mlc: annoData[2].mlcString, prob_mlc: annoData[2].prob_mlc, wc: annoData[2].wcString,
              prob_wc: annoData[2].prob_wc, bc: annoData[2].bcString, prob_bc: annoData[2].prob_bc
            }
            /*,
            {
              mlc: annoData[3].mlc, prob_mlc: annoData[3].prob_mlc, wc: annoData[3].wc,
              prob_wc: annoData[3].prob_wc, bc: annoData[3].bc, prob_bc: annoData[3].prob_bc
            },
            {
              mlc: annoData[4].mlc, prob_mlc: annoData[4].prob_mlc, wc: annoData[4].wc,
              prob_wc: annoData[4].prob_wc, bc: annoData[4].bc, prob_bc: annoData[4].prob_bc
            }*/

          ]

          this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
            valMax => {
              valMax.forEach(element => {
                if (element.error == null) {
                  this.valoreMax[element.nomeCampo] = element.valoreMax;
                }
              });

              if (annoData[0].mlc > this.valoreMax['dscr']) {
                this.ELEMENT_ML_W_B_first[0].mlc = '> ' + this.valoreMax['dscr'];
              }
              else if (annoData[0].mlc < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_ML_W_B_first[0].mlc = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (annoData[0].bc > this.valoreMax['dscr']) {
                this.ELEMENT_ML_W_B_first[0].bc = '> ' + this.valoreMax['dscr'];
              }
              else if (annoData[0].bc < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_ML_W_B_first[0].bc = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (annoData[0].wc > this.valoreMax['dscr']) {
                this.ELEMENT_ML_W_B_first[0].wc = '> ' + this.valoreMax['dscr'];
              }
              else if (annoData[0].wc < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_ML_W_B_first[0].wc = '< ' + this.valoreMax['dscr'] * (-1);
              }

              this.dataSourceMWB_first
                = this.ELEMENT_ML_W_B_first;
            }
          )

          this.ELEMENT_ML_W_B_second = [
            {
              mlc: annoData[3].mlc, prob_mlc: annoData[3].prob_mlc, wc: annoData[3].wc,
              prob_wc: annoData[3].prob_wc, bc: annoData[3].bc, prob_bc: annoData[3].prob_bc
            },
            {
              mlc: annoData[4].mlc, prob_mlc: annoData[4].prob_mlc, wc: annoData[4].wc,
              prob_wc: annoData[4].prob_wc, bc: annoData[4].bc, prob_bc: annoData[4].prob_bc
            },
            {
              mlc: annoData[5].mlc, prob_mlc: annoData[5].prob_mlc, wc: annoData[5].wc,
              prob_wc: annoData[5].prob_wc, bc: annoData[5].bc, prob_bc: annoData[5].prob_bc
            },
            {
              mlc: annoData[6].mlc, prob_mlc: annoData[6].prob_mlc, wc: annoData[6].wc,
              prob_wc: annoData[6].prob_wc, bc: annoData[6].bc, prob_bc: annoData[6].prob_bc
            }
          ]

          this.ELEMENT_ML_W_B_third = [
            {
              mlc: annoData[7].mlc, prob_mlc: annoData[7].prob_mlc, wc: annoData[7].wc,
              prob_wc: annoData[7].prob_wc, bc: annoData[7].bc, prob_bc: annoData[7].prob_bc
            },
            {
              mlc: annoData[8].mlc, prob_mlc: annoData[8].prob_mlc, wc: annoData[8].wc,
              prob_wc: annoData[8].prob_wc, bc: annoData[8].bc, prob_bc: annoData[8].prob_bc
            }
          ]


          this.ELEMENT_ML_W_B_fourt = [
            {
              mlc: annoData[9].mlc, prob_mlc: annoData[9].prob_mlc, wc: annoData[9].wc,
              prob_wc: annoData[9].prob_wc, bc: annoData[9].bc, prob_bc: annoData[9].prob_bc
            },
            {
              mlc: annoData[10].mlc, prob_mlc: annoData[10].prob_mlc, wc: annoData[10].wc,
              prob_wc: annoData[10].prob_wc, bc: annoData[10].bc, prob_bc: annoData[10].prob_bc
            },
            {
              mlc: annoData[11].mlc, prob_mlc: annoData[11].prob_mlc, wc: annoData[11].wc,
              prob_wc: annoData[11].prob_wc, bc: annoData[11].bc, prob_bc: annoData[11].prob_bc
            },
            {
              mlc: annoData[12].mlc, prob_mlc: annoData[12].prob_mlc, wc: annoData[12].wc,
              prob_wc: annoData[12].prob_wc, bc: annoData[12].bc, prob_bc: annoData[12].prob_bc
            },
            {
              mlc: annoData[13].mlc, prob_mlc: annoData[13].prob_mlc, wc: annoData[13].wc,
              prob_wc: annoData[13].prob_wc, bc: annoData[13].bc, prob_bc: annoData[13].prob_bc
            },
            {
              mlc: annoData[14].mlc, prob_mlc: annoData[14].prob_mlc, wc: annoData[14].wc,
              prob_wc: annoData[14].prob_wc, bc: annoData[14].bc, prob_bc: annoData[14].prob_bc
            },
          ]

          this.dataSourceMWB_first = this.ELEMENT_ML_W_B_first;
          this.dataSourceMWB_second = this.ELEMENT_ML_W_B_second;
          this.dataSourceMWB_thirt = this.ELEMENT_ML_W_B_third;
          this.dataSourceMWB_fourt = this.ELEMENT_ML_W_B_fourt;
        }
      })
    }

  }


}
