import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../loading.service';
import { BilancioIntestatarioService } from '../_services/bilancio_intestatario.service';
import { EbaComplianceService } from '../_services/ebaCompliance.service ';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-eba-compliance',
  templateUrl: './eba-compliance.component.html',
  styleUrls: ['./eba-compliance.component.css']
})
export class EbaComplianceComponent implements OnInit {

  ebaComplianceForm: UntypedFormGroup; 
  listaBilanci: any[] = [];
  isLoading: boolean = false;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  submitted = false;
  initialValues: any;
  idBusinessPlan = 0;
  risultatiEba = false;
  risultati = null;
  listaAnni: number[] = [];
  annoBusinessPlan = 0;

  constructor(private httpClient: HttpClient, private tokenService: TokenStorageService, private router: Router,
     private formBuilder: UntypedFormBuilder, private loading: LoadingService, private bilancioService: BilancioIntestatarioService,
     private ebaComplianceService: EbaComplianceService) { 
    this.ebaComplianceForm = this.formBuilder.group({
      anno: ['', Validators.required],
      ricavi: ['', Validators.required],
      costi: ['', Validators.required],
      interessi: ['', Validators.required],
      tasse: ['', Validators.required],
      utili: ['', Validators.required],
      debiti: ['', Validators.required],
      bilancio: ['']
    })
    this.initialValues = this.ebaComplianceForm.value;
  }

  ngOnInit(): void {

    this.listenToLoading();

    this.ebaComplianceService.currentDatiEba.subscribe((result) => {
      if (result && result.ebaForm != null) {
        this.ebaComplianceForm = result.ebaForm;
      }
    });

    this.ebaComplianceService.currentDatiBp.subscribe((result) => {
      if (result && result != null) {
        this.idBusinessPlan = result.idBusinessPlan;
        this.annoBusinessPlan = result.annoBusinessPlan;
        const annoAttuale = new Date().getFullYear();
        /*
        for(var i = this.annoBusinessPlan; i < annoAttuale; i++){
          this.listaAnni.push(i);
        }
        */
        for(var i = this.annoBusinessPlan; i <= 2030; i++){
          this.listaAnni.push(i);
        }
      }else{
        this.router.navigate(["/pratiche"]);
      }
    });

    this.bilancioService.currentParamBilancio.subscribe(bilancio =>{ 
      if(bilancio && bilancio.id_intestatario) {
      this.f.bilancio.setValue({idIntestatario: bilancio.id_intestatario, data: bilancio.data_inserimento});
    }
    });

    this.ebaComplianceService.currentSubmitted.subscribe(submitted => this.submitted = submitted)

/*
    this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getBilanciRichiedente',this.tokenService.getUser().username).subscribe(data => {
      this.listaBilanci = [];
      data.forEach(element => {
        let elementoLista ={idIntestatario: element.intestatario.id, data: element.bilancio.dataInserimento, descrizione: element.intestatario.ragioneSociale + " - " + element.bilancio.dataUltimaModifica};
        this.listaBilanci.push(elementoLista)
        if(this.f.bilancio.value != '' && elementoLista.data == this.f.bilancio.value.data && elementoLista.idIntestatario == this.f.bilancio.value.idIntestatario)  this.f.bilancio.setValue(elementoLista);
      });
    });
*/
  }

  // convenience getter for easy access to form fields
  get f() { return this.ebaComplianceForm.controls; }

    /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
    listenToLoading(): void {
      this.loading.loadingSub
        .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
        .subscribe((loading) => {
          this.isLoading = loading;
        });
    }

    ebaCompliance() {
      this.submitted = true;
      if(this.ebaComplianceForm.valid){
        const request = {
          idBusinessPlan: this.idBusinessPlan,
          ricavi: this.f.ricavi.value,
          costi: this.f.costi.value,
          interessi: this.f.interessi.value,
          tasse: this.f.tasse.value,
          utili: this.f.utili.value,
          debiti: this.f.debiti.value,
          anno: this.f.anno.value
        }

        this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/EbaCompliance', request).subscribe(data => { 
          console.log(data) 
          this.risultati = data; 
          this.risultatiEba = true;    
        })
        
        
      }
    }

    onReset() {
      this.ebaComplianceForm.reset(this.initialValues);
      this.submitted = false;
      this.risultatiEba = false;    

    }

}


