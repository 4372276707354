import { Component, OnInit, Inject } from '@angular/core';
import { User, Users, DialogData } from '../interfaces/user';
import { GestioneUtentiService } from '../_services/gestioneUtenti.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListaUtentiComponent } from '../lista-utenti/lista-utenti.component';
import { AziendaRichiedente, Aziende } from '../interfaces/AziendaRichiedente';
import { UserDialogFormComponent } from '../user-dialog-form/user-dialog-form.component';
import { TokenStorageService } from '../_services/token-storage.service';




@Component({
  selector: 'app-gestione-piva-dialog',
  templateUrl: './gestione-piva-dialog.component.html',
  styleUrls: ['./gestione-piva-dialog.component.css']
})

export class GestionePivaDialogComponent implements OnInit {

  ARic: AziendaRichiedente = { id: 0, ragioneSociale: "", partitaIva: 0, idUtente: 0, idCliente: 0, idRuolo: 0, idAziendaRichiedente: 0 }
  Bind = { id: 0, idAziendaRichiedente: 0, idCliente: 0, idUtente: 0, idRuolo: 0 }

  ARicEnti: Aziende = { rows: [] }
  AziendeRichiedentiAssegnabili: Aziende = { rows: [] }
  AziendeRichiedentiAssegnate: Aziende = { rows: [] }
  AziendeRichiedentiScollegate: Aziende = { rows: [] }

  idut!: number;
  idclie!: number;
  idresp!: number;
  username!: string;

  primo: boolean = true;

  constructor(public dialogRef2: MatDialogRef<GestionePivaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data2: any,
    private http: HttpClient,
    private serviceAzienda: GestioneUtentiService,
    public dialog2: MatDialog,
    private token: TokenStorageService
  ) { }

  ngOnInit(): void {
    console.log(this.data2.username)

    this.serviceAzienda.takePartIva(this.token.getUser().cliente.idCliente).subscribe(dati => {
      dati.forEach(elem => {
        this.ARicEnti.rows.push(elem);
        this.AziendeRichiedentiAssegnabili.rows.push(elem);
      });

      this.serviceAzienda.takeBindedPartIvaByUser(this.data2.idclie, this.data2.idut).subscribe(els => {

        if (els != null) {
          els.forEach(el => {
            this.AziendeRichiedentiAssegnate.rows.push(el);
            this.AziendeRichiedentiAssegnabili.rows.forEach((value, index) => {
              if (value.partitaIva == el.partitaIva) this.AziendeRichiedentiAssegnabili.rows.splice(index, 1);
            });
          });
        }
      });

      this.serviceAzienda.takeAllBindedByDiffUser(this.data2.idclie).subscribe(elm => {
        elm.forEach(el => {
          this.AziendeRichiedentiAssegnabili.rows.forEach((value, index) => {
            if (value.id == el.idAziendaRichiedente) this.AziendeRichiedentiAssegnabili.rows.splice(index, 1);
          });
        });
      });

    });

  }


  onNoClick(): void {
    this.dialog2.closeAll();
  }



  // cambio grafico delle p iva nella lista 


  changeSide(Aric: any, side: string) {

    if (side == 'dx') {
      this.AziendeRichiedentiAssegnate.rows.push(Aric)
      this.AziendeRichiedentiAssegnabili.rows.forEach((value, index) => {
        if (value == Aric) this.AziendeRichiedentiAssegnabili.rows.splice(index, 1);
      });
    } else {

      this.AziendeRichiedentiAssegnabili.rows.push(Aric)
      this.AziendeRichiedentiAssegnate.rows.forEach((value, index) => {
        if (value == Aric) this.AziendeRichiedentiAssegnate.rows.splice(index, 1);
      });

    }

  }





  submitModPiva() {
    this.AziendeRichiedentiAssegnate.rows.forEach((el) => {

      this.serviceAzienda.singleBind(this.data2.idclie, el.id, this.data2.idut).subscribe(elz => {
        this.Bind.idAziendaRichiedente = el.id;
        this.Bind.idCliente = this.data2.idclie;
        this.Bind.idUtente = this.data2.idut;
        this.Bind.idRuolo = this.data2.idRuo;
        if (elz == null) {
          this.serviceAzienda.insertBind(this.Bind).subscribe();
        } else if (elz.idRuolo == 0) {
          this.serviceAzienda.modifyBind(this.Bind).subscribe();
        }
      });
    });

    this.AziendeRichiedentiAssegnabili.rows.forEach(element => {
      this.serviceAzienda.singleBind(this.data2.idclie, element.id, this.data2.idut).subscribe(elz => {
        if (elz != null) {
          elz.idRuolo = 0;
          this.serviceAzienda.modifyBind(elz).subscribe();

        }

      });

    });
  }

}


