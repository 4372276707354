/* ------------------------------------------------------------------------------
 * SERVIZIO di MEMORIZZAZIONE dei TOKEN
 * per gestire il token e le informazioni sull'utente (nome utente, email, ruoli)
 * all'interno del Session Storage del browser.
 * Per il logout, dobbiamo solo cancellare questa memoria di sessione.
 * ------------------------------------------------------------------------------ */
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';


const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';


@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentLoggedIn: Observable<boolean> = this.loggedIn.asObservable();

  expiredTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  currentExpiredTime: Observable<number> = this.expiredTime.asObservable();
  constructor(private jwtHelper: JwtHelperService) { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }
  public saveLoggedIn(loggedIn: boolean): void {
    this.loggedIn.next(loggedIn)  // in millisec
  }

  public saveTokenExpirationTime(): void {
    this.expiredTime.next(this.jwtHelper.getTokenExpirationDate(this.getToken()).valueOf() - new Date().valueOf())  // in millisec
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

}
