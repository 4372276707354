<div *ngIf="id=='principale'">
    <div id="{{lancetta}}" class="lancetta"></div> 
    <canvas #pr_chart *ngIf="id=='principale'" id="{{id}}" class="potenziometro-chart" width="400" height="400"></canvas>
</div>
<div *ngIf="id=='debitoria'" style="position: absolute; top: -60%;">
    <div id="{{lancetta}}" class="lancetta" ></div>
    <canvas #pr_chart *ngIf="id=='debitoria'" id="{{id}}" class="potenziometro-chart" width="400" height="400"></canvas>
</div>
<div *ngIf="id=='mcc'" style=" position: absolute; top: -195%;">
    <div id="{{lancetta}}" class="lancetta" ></div> 
    <canvas #pr_chart *ngIf="id=='mcc'" id="{{id}}" class="potenziometro-chart" width="400" height="400"></canvas>
</div>
<div *ngIf="id=='garanzia'">
    <div id="{{lancetta}}" class="lancetta"></div>
    <canvas #pr_chart *ngIf="id=='garanzia'" id="{{id}}" class="potenziometro-chart" width="400" height="400"></canvas>
</div>
