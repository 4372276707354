import { Component, Input, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent {

  @Input() valueOrdinataNegativo: number[] = []
  @Input() valueOrdinataMedia: number[] = []
  @Input() valueOrdinataPositivo: number[] = []
  @Input() labelAscissa: string[] = []
  MaxOrdinate: number;
  MinOrdinate: number;
  onceCalculactionUpperboundY: boolean = true;
  onceCeck: boolean = true

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40],
        label: 'MORE LIKE CASE',
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      },
      {
        data: [28, 48, 40, 19, 86, 27, 90],
        label: 'WORST CASE',
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'rgba(77,83,96,1)',
        pointBackgroundColor: 'rgba(77,83,96,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)',
        fill: 'origin',
      },
      {
        data: [180, 480, 770, 90, 1000, 270, 400],
        label: 'BEST CASE',
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
      }
    ],
    labels: ['2023', '2024', '2025', '2026', '2027']
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      y:
      {
        max: 200,
        min: 0,
        ticks: {

        },
        position: 'left',

      }
    },

    plugins: {
      legend: { display: true },

    }
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;



  ngAfterViewChecked(): void {
    this.chart.data.labels = ['2023', '2024', '2025', '2026', '2027'];//da rendere dinamico

    if (this.valueOrdinataNegativo.length > 0 && this.valueOrdinataMedia.length > 0 && this.valueOrdinataPositivo.length > 0 && this.onceCeck) {
      this.chart.data.datasets[0].data = this.valueOrdinataNegativo.slice(0, 5);
      this.chart.data.datasets[1].data = this.valueOrdinataMedia.slice(0, 5);
      this.chart.data.datasets[2].data = this.valueOrdinataPositivo.slice(0, 5);

      this.onceCeck = !this.onceCeck;


      this.MaxOrdinate = this.selectUpperBoundY(this.valueOrdinataNegativo.slice(0, 5), this.valueOrdinataMedia.slice(0, 5), this.valueOrdinataPositivo.slice(0, 5));

      this.MinOrdinate = this.selectLowerBoundY(this.valueOrdinataNegativo.slice(0, 5), this.valueOrdinataMedia.slice(0, 5), this.valueOrdinataPositivo.slice(0, 5));

      this.chart.options.scales = {
        y: {
          display: true,
          min: this.MinOrdinate,
          max: this.MaxOrdinate,
          ticks: {
            //stepSize: 100
          }
        },
      };

      this.chart.update();
      this.chart.render();


      //console.log(this.valueOrdinataMedia);
    }
  }

  selectLowerBoundY(valoriNegativo: number[], valoriMedia: number[], valoriPositivo: number[]): number {

    let min: number = 0;


    let tempNegativo = 0;
    let tempMedia = 0;
    let tempPositivo = 0;

    this.valueOrdinataNegativo.forEach((element) => {
      if (tempNegativo > element) {
        tempNegativo = element;
      }
    });

    this.valueOrdinataMedia.forEach((element) => {
      if (tempMedia > element) {
        tempMedia = element;
      }
    });

    this.valueOrdinataPositivo.forEach((element) => {
      if (tempPositivo > element) {
        tempPositivo = element;
      }
    });

    min = Math.min(tempNegativo, tempMedia, tempPositivo);

    return min;
  }

  selectUpperBoundY(valoriNegativo: number[], valoriMedia: number[], valoriPositivo: number[]): number {

    let max: number = 1000000;

    let tempNegativo = 0;
    let tempMedia = 0;
    let tempPositivo = 0;

    this.valueOrdinataNegativo.forEach((element) => {
      if (tempNegativo < element) {
        tempNegativo = element;
      }
    });

    this.valueOrdinataMedia.forEach((element) => {
      if (tempMedia < element) {
        tempMedia = element;
      }
    });

    this.valueOrdinataPositivo.forEach((element) => {
      if (tempPositivo < element) {
        tempPositivo = element;
      }
    });

    max = Math.max(tempNegativo, tempMedia, tempPositivo);

    max = this.foundUpperboundY(max);


    return max;
  }

  foundUpperboundY(value: number): number {

    value = Math.floor(value);

    console.log(value);
    switch (value.toString().length) {

      case 1: {
        if (value >= 9)
          return 10;
        if (value >= 8)
          return 9;
        if (value >= 7)
          return 8;
        if (value >= 6)
          return 7;
        if (value >= 5)
          return 6;
        if (value >= 4)
          return 5;
        if (value >= 3)
          return 4;
        if (value >= 2)
          return 3;
        if (value >= 1)
          return 2;
        if (value >= 0)
          return 1;
        break;
      }

      case 2: {
        if (value >= 90)
          return 100;
        if (value >= 80)
          return 90;
        if (value >= 70)
          return 80;
        if (value >= 60)
          return 70;
        if (value >= 50)
          return 60;
        if (value >= 40)
          return 50;
        if (value >= 30)
          return 40;
        if (value >= 20)
          return 30;
        if (value >= 10)
          return 20;
        if (value >= 10)
          return 10;
        break;
      }

      case 3: {
        if (value >= 900)
          return 1000;
        if (value >= 800)
          return 900;
        if (value >= 700)
          return 800;
        if (value >= 600)
          return 700;
        if (value >= 500)
          return 600;
        if (value >= 400)
          return 500;
        if (value >= 300)
          return 400;
        if (value >= 200)
          return 300;
        if (value >= 100)
          return 200;
        break;
      }


      case 4: {
        if (value >= 9000)
          return 10000;
        if (value >= 8000)
          return 9000;
        if (value >= 7000)
          return 8000;
        if (value >= 6000)
          return 7000;
        if (value >= 5000)
          return 6000;
        if (value >= 4000)
          return 5000;
        if (value >= 3000)
          return 4000;
        if (value >= 2000)
          return 3000;
        if (value >= 1000)
          return 2000;
        break;
      }


      case 5: {
        if (value >= 90000)
          return 100000;
        if (value >= 80000)
          return 90000;
        if (value >= 70000)
          return 80000;
        if (value >= 60000)
          return 70000;
        if (value >= 50000)
          return 60000;
        if (value >= 40000)
          return 50000;
        if (value >= 30000)
          return 40000;
        if (value >= 20000)
          return 30000;
        if (value >= 10000)
          return 20000;
        break;
      }


      case 6: {
        if (value >= 900000)
          return 1000000;
        if (value >= 800000)
          return 900000;
        if (value >= 700000)
          return 800000;
        if (value >= 600000)
          return 700000;
        if (value >= 500000)
          return 600000;
        if (value >= 400000)
          return 500000;
        if (value >= 300000)
          return 400000;
        if (value >= 200000)
          return 300000;
        if (value >= 100000)
          return 200000;
        break;
      }

      case 7: {
        if (value >= 9000000)
          return 10000000;
        if (value >= 8000000)
          return 9000000;
        if (value >= 7000000)
          return 8000000;
        if (value >= 6000000)
          return 7000000;
        if (value >= 5000000)
          return 6000000;
        if (value >= 4000000)
          return 5000000;
        if (value >= 3000000)
          return 4000000;
        if (value >= 2000000)
          return 3000000;
        if (value >= 1000000)
          return 2000000;
        break;
      }

      default: {
        if (value >= 90000000)
          return 100000000;
        if (value >= 80000000)
          return 90000000;
        if (value >= 70000000)
          return 80000000;
        if (value >= 60000000)
          return 70000000;
        if (value >= 50000000)
          return 60000000;
        if (value >= 40000000)
          return 50000000;
        if (value >= 30000000)
          return 40000000;
        if (value >= 20000000)
          return 30000000;
        if (value >= 10000000)
          return 20000000;
        break;
      }
    }



  }
}

