<div class="contenitore-dett-target">
    <div class="row">
        <div class="col-12 year" >
            Anno
        </div>
        
            <div class="sxUp col-6">
                In dicatore
                <div class="valore">
                    9.999.999.999
                </div>
                <div class="trend">
                    100% 
                    <mat-icon class="arrows"
                        [ngStyle]="{'color': 'rgb(19, 194, 112)'}">arrow_upward
                    </mat-icon>
                </div>

            </div>

            <div class="dxUp col-6">
              
                Indicatore
                <div class="valore">
                    9.999.999.999
                </div>
                <div class="trend">
                    100% 
                    <mat-icon class="arrows"
                        [ngStyle]="{'color': 'rgb(19, 194, 112)'}">arrow_upward
                    </mat-icon>
                </div>
            </div>
        
            <div class="sxDown col-6">
                Indicatore
                <div class="valore">
                    9.999.999.999
                </div>
                <div class="trend">
                    100% 
                    <mat-icon class="arrows"
                        [ngStyle]="{'color': 'rgb(19, 194, 112)'}">arrow_upward
                    </mat-icon>
                </div>
            </div>

            <div class="dxDown col-6">
                Indicatore
                <div class="valore">
                    9.999.999.999
                </div>
                <div class="trend">
                    100% 
                    <mat-icon class="arrows"
                        [ngStyle]="{'color': 'rgb(19, 194, 112)'}">arrow_upward
                    </mat-icon>
                </div>
            </div>
        
    </div>

</div>