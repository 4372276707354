import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BeanStatico } from 'src/app/condivisi/beanStatico';

@Component({
  selector: 'app-check-box-list',
  templateUrl: './check-box-list.component.html',
  styleUrls: ['./check-box-list.component.css']
})
export class CheckBoxListComponent implements OnInit {
  @Input() listaCheckBox: BeanStatico[];
  modalForm: UntypedFormGroup;

  @Output() listaCheckBoxModificata = new EventEmitter<BeanStatico[]>()

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.modalForm = this.formBuilder.group({
      arrayFormCheckBox: this.formBuilder.array([])
    })
  }

  get arrayForm(): UntypedFormArray { return <UntypedFormArray> this.modalForm.controls.arrayFormCheckBox }
  get FormControlArray(): UntypedFormControl[] { return <UntypedFormControl[]> this.arrayForm.controls }

  ngOnInit(): void {
    if (this.listaCheckBox.length > 0) {
      this.listaCheckBox.forEach(checkBox => {
        this.arrayForm.push(
          this.formBuilder.control(checkBox.checked)
        )
      })
    }
  }

  onChangeCheckBox() {
    for (let i = 0; i < this.listaCheckBox.length; i++) {
      this.listaCheckBox[i].checked = this.FormControlArray[i].value;
    }
    this.listaCheckBoxModificata.emit(this.listaCheckBox);
  }

}
