<div class="col-12" [formGroup]="anagraficaForm">
  <div class="card nopadding">
    <div class="card-header text-center font-big">Anagrafica azienda</div>
    <div class="card-body">
      <div class="col-4 col-lg-4 margin-bottom-10">
        <label>Tipo richiedente*</label>
        <select formControlName="tipoRichiedente" class="form-control" (change)="onChangeTipoRichiedente()"
          [ngClass]="{ 'is-invalid': submitted && f.tipoRichiedente.errors }">
          <option [ngValue]="richiedente" *ngFor="let richiedente of listaTipiRichiedente">{{richiedente.descrizione}}
          </option>
        </select>
        <div *ngIf="submitted && f.tipoRichiedente.errors" class="invalid-feedback">
          <div *ngIf="f.tipoRichiedente.errors.required">Campo obbligatorio</div>
        </div>
      </div>

      <div class="row">
        <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
          <label *ngIf="f.tipoAzienda.value.descrizione == 'Società di persone';else newTitle">Partita IVA</label>
          <ng-template #newTitle><label>Partita IVA*</label></ng-template>
          <input type="text" formControlName="partitaIva" placeholder="partita IVA" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.partitaIva.errors }" />
          <div *ngIf="submitted && f.partitaIva.errors" class="invalid-feedback">
            <div *ngIf="f.partitaIva.errors.required">Campo obbligatorio</div>
          </div>
        </div>

        <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
          <!-- *ngIf="f.tipoAzienda.value.descrizione != 'Società di persone' -->
          <label>Ragione sociale*</label>
          <input type="text" formControlName="nomeAzienda" class="form-control" placeholder="Nome Azienda"
            (change)="onChangeNomeAzienda()" [ngClass]="{ 'is-invalid': submitted && f.nomeAzienda.errors }" />
          <div *ngIf="submitted && f.nomeAzienda.errors" class="invalid-feedback">
            <div *ngIf="f.nomeAzienda.errors.required">Campo obbligatorio</div>
          </div>
        </div>
        <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
          <!-- *ngIf="f.tipoRichiedente.value && f.tipoRichiedente.value.descrizione != 'Start up'" -->
          <label>Forma Giuridica*</label>
          <select formControlName="tipoAzienda" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.tipoAzienda.errors }">
            <option [ngValue]="tipoAzienda" *ngFor="let tipoAzienda of listaTipiAzienda">{{tipoAzienda.descrizione}}
            </option>
          </select>
          <div *ngIf="submitted && f.tipoAzienda.errors" class="invalid-feedback">
            <div *ngIf="f.tipoAzienda.errors.required">Campo obbligatorio</div>
          </div>
        </div>

      </div>
      <!-- codici ATECO compariranno se viene fatto l'inserimento manuale -->

      <!-- <div class="row">
          <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
            <label>Codice ATECO primario</label>
            <select formControlName="ateco1" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.ateco1.errors }">
              <option [ngValue]="codiceAteco" *ngFor="let codiceAteco of listaCodiciAteco">{{codiceAteco.descrizione}}
              </option>
            </select>
            <div *ngIf="submitted && f.ateco1.errors" class="invalid-feedback">
              <div *ngIf="f.ateco1.errors.required">Campo obbligatorio</div>
            </div>
          </div>
          <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
            <label>Codice ATECO secondario-1</label>
            <select formControlName="ateco21" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.ateco21.errors}">
              <option [ngValue]="codiceAteco" *ngFor="let codiceAteco of listaCodiciAteco">{{codiceAteco.descrizione}}
              </option>
            </select>
            <div *ngIf="submitted && f.ateco21.errors" class="invalid-feedback">
              <div *ngIf="f.ateco12.errors.required">Campo obbligatorio</div>
            </div>
          </div>
          <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
            <label>Codice ATECO secondario-2</label>
            <select formControlName="ateco22" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.ateco22.errors }">
              <option [ngValue]="codiceAteco" *ngFor="let codiceAteco of listaCodiciAteco">{{codiceAteco.descrizione}}
              </option>
            </select>
            <div *ngIf="submitted && f.ateco22.errors" class="invalid-feedback">
              <div *ngIf="f.ateco22.errors.required">Campo obbligatorio</div>
            </div>
          </div>

        </div>-->
      <div class="row">
        <div class="col-4 col-lg-4 margin-bottom-10">
          <label>Sede Legale</label>
          <input type="text" formControlName="sedeLegale" placeholder="Indirizzo" class="form-control" />
        </div>
        <div class="col-3 col-lg-3 margin-bottom-10">
          <label>Provincia*</label>
          <select formControlName="provincia" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.provincia.errors }">
            <option [ngValue]="provincia" *ngFor="let provincia of listaProvince">{{provincia.provincia}} -
              ({{provincia.sigla}})</option>
          </select>
          <div *ngIf="submitted && f.provincia.errors" class="invalid-feedback">
            <div *ngIf="f.provincia.errors.required">Campo obbligatorio</div>
          </div>
        </div>
        <div class="col-3 col-lg-3 margin-bottom-10">
          <label>Comune*
            <fa-icon class="cursor pull-right margin-left-20" style="font-size: 15px" [icon]="faInfo" placement="top"
              ngbTooltip="selezionare prima la Provincia"></fa-icon>
          </label>
          <select type="text" formControlName="comune" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.comune.errors }">
            <option [ngValue]="comune" *ngFor="let comune of listaComuni">{{comune.descrizione}}</option>
          </select>
          <div *ngIf="submitted && f.comune.errors" class="invalid-feedback">
            <div *ngIf="f.comune.errors.required">Campo obbligatorio</div>
          </div>
        </div>

        <div class="col-2 col-lg-2 margin-bottom-10">
          <label>CAP</label>
          <input type="number" formControlName="cap" placeholder="CAP" class="form-control" />
        </div>
      </div>
      <!-- blocco anagrafica richiedente -->
      <div class="margin 0" *ngIf="f.tipoAzienda.value && f.tipoAzienda.value.descrizione == 'Società di persone'">
        <h4 class=" margin-top-10 font-bold">Informazioni aggiuntive richiedente</h4>
        <div class="row">
          <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
            <label>Nome</label>
            <input type="text" formControlName="nomeRichiedente" placeholder="Nome" class="form-control" />
          </div>
          <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
            <label>Cognome</label>
            <input type="text" formControlName="cognomeRichiedente" placeholder="Cognome" class="form-control"
              (change)="onChangeNomeAzienda()" />

          </div>
          <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
            <label>Codice Fiscale</label>
            <input type="text" formControlName="codiceFiscale" placeholder="Codice fiscale"
              class="form-control text-uppercase" />
          </div>
        </div>
        <div class="row">
          <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
            <!-- *ngIf="f.ateco.value" -->
            <label>Titolo di studio</label>
            <select formControlName="titoloStudio" class="form-control">
              <option [ngValue]="titolo" *ngFor="let titolo of listaTitoliStudio">{{titolo.descrizione}}</option>
            </select>
          </div>
          <div class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
            <!-- *ngIf="f.ateco.value" -->
            <label>Esperienza nel settore agricolo</label>
            <input type="number" min="0" formControlName="esperienza" placeholder="Anni di esperienza"
              class="form-control" />
          </div>
        </div>
        <div class="row">
          <h4 class=" margin-top-10 font-bold">Recapiti richiedente</h4>
          <div class="col-4 col-lg-4 margin-bottom-10">
            <label>Email richiedente</label>
            <input type="text" formControlName="emailRichiedente" class="form-control" placeholder="Email" />
          </div>
          <div class="col-4 col-lg-4 margin-bottom-10">
            <label>Telefono richiedente</label>
            <input type="text" formControlName="telefonoRichiedente" class="form-control" placeholder="Telefono" />
          </div>
        </div>
      </div>
      <!-- fine -->

    </div>
  </div>

  <div class="card nopadding margin-top-20"
    *ngIf="f.tipoRichiedente.value && f.tipoRichiedente.value.descrizione != 'Start up'">
    <div class="card-header text-center font-big ">Dossier amministrativi</div>
    <div class="card-body">
      <div class="row margin-0">
        <div class="margin-bottom-10 col-21 col-lg-12">
          Per produrre la valutazione preliminare di finanziabilit&agrave; del tuo progetto &egrave;
          necessario conoscere un po' la tua societ&agrave;. Puoi fornirci gli ultimi due bilanci oppure l'ultima
          dichiarazione IVA.
          In alternativa puoi inserire direttamente le informazioni necessarie.
          Fornendoci anche la tua Centrale dei Rischi saremo nelle migliori condizioni per fornirti un realistico piano
          di fattibilit&agrave;
        </div>
        <div class="col-6">

          <div class="row">


            <div class="margin-bottom-10 col-6 col-lg-10">
              <label>Vuoi eseguire l'analisi dell'ultimo bilancio?</label>
            </div>
            <div class="margin-bottom-10 col-2 col-lg-2">
              <select id="presenzaBilancio" formControlName="presenzaBilancio" class="form-control"
                (change)="onChangeBilancio()">
                <option [ngValue]="true">Si</option> <!-- setto il value di presenza bilancio -->
                <option [ngValue]="false">No</option>
              </select>
            </div>
          </div>

          <div class="row" *ngIf="f.presenzaBilancio.value">
            <!-- ================================= -->
            <div class="col-5 col-lg-4 margin-bottom-10 margin-top-20">
              <button class="btn btn-primary mr-1" type="button" (click)="goToBilancio()">Inserisci dati
                bilancio</button>
            </div>
            <div class="col-5 col-lg-6 margin-bottom-10" *ngIf="listaBilanci.length > 0">
              <label>Seleziona Bilancio</label>
              <select formControlName="bilancio" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.bilancio.errors }">
                <option [ngValue]="bilancio" *ngFor="let bilancio of listaBilanci">{{bilancio.descrizione}}</option>
              </select>
              <div *ngIf="submitted && f.bilancio.errors" class="invalid-feedback">
                <div *ngIf="f.bilancio.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>

          <div class="row" *ngIf="f.presenzaBilancio.value === false">

            <div class="margin-bottom-10 col-6 col-lg-10">
              <label>Hai a disposizione l'ultima dichiarazione IVA?</label>
            </div>
            <div class="margin-bottom-10 col-2 col-lg-2">
              <select formControlName="dichiarazioneIVA" class="form-control" (change)="onChangeDichiarazioneIVA()"
                [ngClass]="{ 'is-invalid': submitted && f.dichiarazioneIVA.errors }">
                <option [ngValue]="true">Si</option>
                <option [ngValue]="false">No</option>
              </select>
              <div *ngIf="submitted && f.dichiarazioneIVA.errors" class="invalid-feedback">
                <div *ngIf="f.dichiarazioneIVA.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>

          <div class="row" [hidden]="!(f.dichiarazioneIVA.value && f.dichiarazioneIVA.value == true)">

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Inserire quadro VE, riga VE 50</label>
              <input type="text" formControlName="rigaVE50" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rigaVE50.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #rigaVE50 />
              <div *ngIf="submitted && f.rigaVE50.errors" class="invalid-feedback">
                <div *ngIf="f.rigaVE50.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Inserire quadro VF, riga VF 14</label>
              <input type="text" formControlName="rigaVF14" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rigaVF14.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #rigaVF14 />
              <div *ngIf="submitted && f.rigaVF14.errors" class="invalid-feedback">
                <div *ngIf="f.rigaVF14.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="margin-bottom-10 offset-2 offset-lg-0">
            </div>

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Inserire quadro VF, riga VF23</label>
              <input type="text" formControlName="rigaVF23" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rigaVF23.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #rigaVF23 />
              <div *ngIf="submitted && f.rigaVF23.errors" class="invalid-feedback">
                <div *ngIf="f.rigaVF23.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Inserire quadro VF, riga VF 27</label>
              <input type="text" formControlName="rigaVF27" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rigaVF27.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #rigaVF27 />
              <div *ngIf="submitted && f.rigaVF27.errors" class="invalid-feedback">
                <div *ngIf="f.rigaVF27.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>

          <div class="row" [hidden]="!(f.dichiarazioneIVA.value === false)">

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Ricavi anno precedente</label>
              <input type="text" formControlName="ricaviAnnoPrecedente" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.ricaviAnnoPrecedente.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #ricaviExAnte />
              <div *ngIf="submitted && f.ricaviAnnoPrecedente.errors" class="invalid-feedback">
                <div *ngIf="f.ricaviAnnoPrecedente.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Costi anno precedente</label>
              <input type="text" formControlName="costiAnnoPrecedente" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.costiAnnoPrecedente.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #costiExAnte />
              <div *ngIf="submitted && f.costiAnnoPrecedente.errors" class="invalid-feedback">
                <div *ngIf="f.costiAnnoPrecedente.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>

        </div>

        <div class="col-6 borderL">

          <div class="row">

            <div class="margin-bottom-10 col-6 col-lg-10">
              <label>Hai a disposizione la Centrale dei Rischi?</label>
            </div>

            <div class="margin-bottom-10 col-2 col-lg-2">
              <select id="presenzaCr" formControlName="presenzaCr" class="form-control" (change)="onChangeCr()">
                <option [ngValue]="true">Si</option>
                <option [ngValue]="false">No</option>
              </select>
            </div>

          </div>

          <div class="row" *ngIf="f.presenzaCr.value">
            <div class="col-5 col-lg-4 margin-bottom-10 margin-top-20">
              <button class="btn btn-primary mr-1" type="button" (click)="goToCr()">Carica CR</button>
            </div>
            <div class="col-5 col-lg-6 margin-bottom-10" *ngIf="listaCr.length > 0">
              <label>Seleziona CR</label>
              <select formControlName="centraleRischi" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.centraleRischi.errors }">
                <option [ngValue]="centraleRischi" *ngFor="let centraleRischi of listaCr">{{centraleRischi.descrizione}}
                </option>
              </select>
              <div *ngIf="submitted && f.centraleRischi.errors" class="invalid-feedback">
                <div *ngIf="f.centraleRischi.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="card nopadding margin-top-20"
    *ngIf="f.tipoRichiedente.value && f.tipoRichiedente.value.descrizione != 'Start up'">
    <div class="card nopadding margin 0" *ngIf="f.presenzaBilancio.value">
      <div class="card-header text-center font-big">Esposizione finanziaria</div>
      <div class="card-body">

        <div class="row">
          <div class="col-12 font-bold">Durata debito a breve termine: 18 mesi</div>

          <div class="col-5 col-lg-4 margin-bottom-10">
            <label>Importo residuo debito a breve termine</label>
            <input type="text" formControlName="importoDebitoBT" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.importoDebitoBT.errors }" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
              #debitoBT />
            <div *ngIf="submitted && f.importoDebitoBT.errors" class="invalid-feedback">
              <div *ngIf="f.importoDebitoBT.errors.required">Campo obbligatorio</div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12 font-bold">Durata debito a medio termine: 4 anni</div>

          <div class="col-5 col-lg-4 margin-bottom-10">
            <label>Importo residuo debito a medio termine</label>
            <input type="text" formControlName="importoDebitoMT" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.importoDebitoMT.errors }" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
              #debitoMT />
            <div *ngIf="submitted && f.importoDebitoMT.errors" class="invalid-feedback">
              <div *ngIf="f.importoDebitoMT.errors.required">Campo obbligatorio</div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12 font-bold">Durata debito a lungo termine: 7 anni</div>

          <div class="col-5 col-lg-4">
            <label>Importo residuo debito a lungo termine</label>
            <input type="text" formControlName="importoDebitoLT" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.importoDebitoLT.errors }" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
              #debitoLT />
            <div *ngIf="submitted && f.importoDebitoLT.errors" class="invalid-feedback">
              <div *ngIf="f.importoDebitoLT.errors.required">Campo obbligatorio</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card nopadding margin 0" *ngIf="f.presenzaBilancio.value === false">
      <!--  TODO
      <app-anagrafica-azienda [formGroup]="anagraficaAziendaForm" [presenzaBilancio]="f.presenzaBilancio.value">

      </app-anagrafica-azienda>
      -->

    </div>

  </div>
</div>