import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimelineButtonsNavigatorService {

  baseUrl = environment.apiUrlQuestionario + '/fasGoAlgo';

  constructor(private http: HttpClient) { }

  /*bottone as is*/
  private _AS_IS: Subject<boolean> = new Subject<boolean>();

  public readonly AS_IS$: Observable<boolean> = this._AS_IS.asObservable();

  /*bottone year*/
  private _YEARS: Subject<number> = new Subject<number>();

  public readonly YEARS$: Observable<number> = this._YEARS.asObservable();

  /*bottone index*/
  private _INDEX: Subject<number> = new Subject<number>();

  public readonly INDEX$: Observable<number> = this._INDEX.asObservable();

  showAsIs(): void {
    this._AS_IS.next(true)
  }

  noShowAsIs(): void {
    this._AS_IS.next(false)
  }

  selectYear(vlaue: number, index: number): void {
    this.noShowAsIs();
    this._YEARS.next(vlaue);
    this._INDEX.next(index);
  }

  getYearSelectData(year: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}` + `/risultatiSplashPageEndStrokeTable/` + year);
  }
}
