import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Ruolo } from '../interfaces/ruoli';
import { AziendaRichiedente } from '../interfaces/AziendaRichiedente';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GestioneUtentiService {
  private baseUrl = environment.apiUrlQuestionario;
  constructor(private http: HttpClient) { }

  UtenzeMassime(Cli: number): Observable<number> {

    return this.http.get<number>(this.baseUrl + '/fasGoAlgo/numut/' + Cli);

  }

  takeAll(Cli: number): Observable<User[]> {

    return this.http.get<User[]>(this.baseUrl + '/auth/get/' + Cli);

  }

  takeRoles(): Observable<Ruolo[]> {

    return this.http.get<Ruolo[]>(this.baseUrl + '/ruoli/get');

  }

  takeById(idUtente: number): Observable<User> {

    return this.http.get<User>(this.baseUrl + '/auth/getbyid/' + idUtente);

  }

  takeByNomeUtente(Clie: number, NomeUtente: string): Observable<User> {

    return this.http.get<User>(this.baseUrl + '/auth/getbyname/' + Clie + "/" + NomeUtente);

  }

  takeByEmailUtente(Clie: number, EmailUtente: string): Observable<User> {

    return this.http.get<User>(this.baseUrl + '/auth/getbymail/' + Clie + "/" + EmailUtente);

  }

  _submitUser(utente: User): Observable<User> {

    return this.http.post<User>(this.baseUrl + '/auth/ins', utente);

  }


  disattivaUtente(dataValues: any): Observable<User> {

    return this.http.put<User>(this.baseUrl + '/auth/disattiva', dataValues);

  }

  modifyUser(dataValues: User): Observable<User> {

    return this.http.put<User>(this.baseUrl + '/auth/modifica', dataValues);

  }

  takePartIva(idCliente: number): Observable<AziendaRichiedente[]> {

    return this.http.get<AziendaRichiedente[]>(this.baseUrl + '/fasGoAlgo/selicli/' + idCliente);
  }

  takeBindedPartIvaByUser(idClie: number, idUser: number): Observable<AziendaRichiedente[]> {

    return this.http.get<AziendaRichiedente[]>(this.baseUrl + '/fasGoAlgo/getbindbyid/' + idClie + "/" + idUser)
  }

  singleBind(idCliente: number, idAziendaRichidente: number, idUtente: number): Observable<AziendaRichiedente> {

    return this.http.get<AziendaRichiedente>(this.baseUrl + '/utzbind/getsinglebind/' + idCliente + "/" + idUtente + "/" + idAziendaRichidente);

  }

  takeAllBindedByDiffUser(Clie: number): Observable<AziendaRichiedente[]> {

    return this.http.get<AziendaRichiedente[]>(this.baseUrl + '/utzbind/getallbindedotheruser/' + Clie);

  }

  insertBind(dataSet: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + '/utzbind/insertbind', dataSet);
  }

  modifyBind(dataValues: any): Observable<AziendaRichiedente> {

    return this.http.put<AziendaRichiedente>(this.baseUrl + '/utzbind/modbind', dataValues);

  }

  deleteAllUserBind(dataValues: any): Observable<AziendaRichiedente> {

    return this.http.put<AziendaRichiedente>(this.baseUrl + '/utzbind/delallusbind/', dataValues);

  }

  updateUserBind(Ute: User): Observable<AziendaRichiedente> {

    return this.http.put<AziendaRichiedente>(this.baseUrl + '/utzbind/modallusbind/', Ute);

  }

  updateUtenzeAttive(ActUt: number, Clie: number): Observable<any> {

    return this.http.put<any>(this.baseUrl + '/fasGoAlgo/updtact/' + ActUt + "/" + Clie, ActUt)
  }

}
