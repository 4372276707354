import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from  '@angular/common/http';
import { PivaDataService } from 'src/app/_services/piva-data.service';
import { UserObject, VariabileBenchmark } from 'src/app/interfaces/user-key';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { environment } from 'src/environments/environment';
import { BeanStaticoEsteso } from 'src/app/condivisi/beanStaticoEsteso';




@Component({
  selector: 'app-competitor-dialog',
  templateUrl: './competitor-dialog.component.html',
  styleUrls: ['./competitor-dialog.component.css']
}) 

export class CompetitorDialogComponent implements OnInit {

  
  constructor(public dialogRef: MatDialogRef<CompetitorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    public dialog: MatDialog,
    private PivaService: PivaDataService) { }

  listaCompetitors: UserObject[] = [];
  listaCompetitorsSelezionati: UserObject[] = this.data.listSelected.splice(1);
  listaVariabili: VariabileBenchmark[] = [];
  listaCheckedVars : VariabileBenchmark[] = this.data.varSelected as VariabileBenchmark[];
  controllo: boolean = false;
  maxVariable : boolean = false;

  
  listaAtecoPrimarioDivisione:BeanStaticoEsteso[] = []
  listaAtecoPrimarioClasse: BeanStaticoEsteso[] = [];
  listaAtecoPrimarioGruppo: BeanStaticoEsteso[] = [];
  selectedAtecos1 = [];
  selectedAtecos2 = [];
  selectedAtecos3 = [];

  

  ngOnInit(): void {
    
    this.PivaService.getAllVars().subscribe(vars=>{
     this.listaVariabili = vars.sort((n1, n2) => n1.id - n2.id);
    })
    
    this.PivaService.getCompetitors(this.data.tk).subscribe(
      (RetData) => {
        RetData.forEach((elem:UserObject) => { 
          if(this.listaCompetitorsSelezionati.filter((f: UserObject) => f.id == elem.id).length == 0) this.listaCompetitors.push(elem);
        });
      }
    )
      this.controlloSubmit();
      this.getSelectValue();

  }





  getSelectValue(){
     this.selectedAtecos1 = []
      this.http.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoDivisioneData', this.data.tk.codiceAteco.slice(0,2) ).subscribe(response => {
        const atecoDivisione = { id: response.id, descrizione:response.codiceAteco, descrizioneCompleta: response.codiceAteco+" - "+ response.descrizione};
        this.listaAtecoPrimarioDivisione = [...this.listaAtecoPrimarioDivisione,atecoDivisione]
        this.selectedAtecos1 = [...this.selectedAtecos1,response.id]
        this.getGruppiValues();
    });
  }

  getGruppiValues(){

    this.selectedAtecos2 =[]
    this.listaAtecoPrimarioGruppo = []

    this.http.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoGruppiByIdDivisione', this.listaAtecoPrimarioDivisione[0].id).subscribe(data => {
      data.forEach(resp2 => {
        const atecoGruppo = { id: resp2.id, descrizione:resp2.codiceAteco,descrizioneCompleta: resp2.codiceAteco+" - "+ resp2.descrizione};
        this.listaAtecoPrimarioGruppo = [...this.listaAtecoPrimarioGruppo, atecoGruppo]
        this.pushScelteGruppi()
      });
    });
  }

  pushScelteGruppi(){
    if(this.data.atecoSelected[0]?.descrizione.length == 4) {
      this.data.atecoSelected.forEach(element => {
        this.selectedAtecos2 = [...this.selectedAtecos2,(element.id as number ) ]
       });
    }else if(this.data.atecoSelected[0]?.descrizione.length == 5){
      let filtered = this.listaAtecoPrimarioGruppo.filter(obj => { return obj.descrizione == this.data.atecoSelected[0].descrizione.slice(0,4)  });
      filtered.forEach(el => {
        this.selectedAtecos2 =[...this.selectedAtecos2, (el.id as number)]
      })    
      
    }
    this.selectedAtecos2.length == 1 ? this.getClasseByGruppo(): '' ; 
  }


  getClasseByGruppo(){
    this.selectedAtecos3 = [];
    this.listaAtecoPrimarioClasse = []
    this.http.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoClassiByIdGruppo', this.selectedAtecos2[0]).subscribe(data => {
      data.forEach(response => {
        const atecoClasse = { id: response.id, descrizione:response.codiceAteco, descrizioneCompleta: response.codiceAteco+" - "+ response.descrizione};
       this.listaAtecoPrimarioClasse = [...this.listaAtecoPrimarioClasse, atecoClasse]
      });
      this.data.atecoSelected[0]?.descrizione.length == 5 ? this.pushScelteClassi() : '' ; 

    });
  }



  pushScelteClassi(){
          this.selectedAtecos3 = []
          this.data.atecoSelected.forEach(element => {
              this.selectedAtecos3 = [...this.selectedAtecos3,( element.id as number ) ]
              console.log("SEL 3 :" + this.selectedAtecos3)
            });
  }




  onChange(){
    this.selectedAtecos2.length != 1 ? (this.listaAtecoPrimarioClasse = [] , this.selectedAtecos3 = []) : this.getClasseByGruppo(); 
   }


  changeSide(Aric: any, side: string) {
    if (side == 'sx') {
      this.listaCompetitorsSelezionati.push(Aric)
      this.listaCompetitors.forEach((value, index) => {
        if (value == Aric) this.listaCompetitors.splice(index, 1);
      });
    } else if(side == 'dx') {

      this.listaCompetitors.push(Aric)
      this.listaCompetitorsSelezionati.forEach((value, index) => {
        if (value == Aric) this.listaCompetitorsSelezionati.splice(index, 1);
      });
    }
    this.controlloSubmit();
  }

  onNoClick(): void {

    let atecoToPass:BeanStaticoEsteso[] = [];
    this.selectedAtecos3.length == 0 ? 
    ( this.selectedAtecos2.length == 0 ? atecoToPass = this.listaAtecoPrimarioDivisione.filter(obj =>{return this.selectedAtecos1.includes(obj.id)})  : atecoToPass = this.listaAtecoPrimarioGruppo.filter(obj =>{ return this.selectedAtecos2.includes(obj.id)})   ) 
     :  atecoToPass = this.listaAtecoPrimarioClasse.filter(obj =>{return this.selectedAtecos3.includes(obj.id)}) ;

    this.dialogRef.close({ data: {compets :this.listaCompetitorsSelezionati , vars: this.listaCheckedVars,atecos: atecoToPass ,isCancel: true} });

  }

  saveComp() {


    let atecoToPass:BeanStaticoEsteso[] = [];
    this.selectedAtecos3.length == 0 ? 
    ( this.selectedAtecos2.length == 0 ? atecoToPass = this.listaAtecoPrimarioDivisione.filter(obj =>{return this.selectedAtecos1.includes(obj.id)})  : atecoToPass = this.listaAtecoPrimarioGruppo.filter(obj =>{ return this.selectedAtecos2.includes(obj.id)})   ) 
     :  atecoToPass = this.listaAtecoPrimarioClasse.filter(obj =>{return this.selectedAtecos3.includes(obj.id)}) ;

    this.dialogRef.close({ data: {compets :this.listaCompetitorsSelezionati , atecos: atecoToPass,vars: this.listaCheckedVars,isCancel: false} });

  }


  checkCheckBoxvalue(evento: MatCheckboxChange){
   evento.checked ?  (this.listaCheckedVars.push(this.listaVariabili[evento.source.value]) && this.controlloSubmit() ): (this.listaCheckedVars = this.listaCheckedVars.filter(s => s.variabile != this.listaVariabili[evento.source.value].variabile) , this.controlloSubmit()) ;
  }


  getNotActiveCheckbox(variabile : string): boolean{
    return this.listaCheckedVars.filter(f => f.variabileLabel == variabile).length == 0;
  }

  controlloSubmit(){
    this.listaCheckedVars.length != 0 && (this.listaCompetitorsSelezionati.length != 0 ) ? this.controllo = true : this.controllo = false;
    
  }

}
