import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { BeanStaticoEsteso } from 'src/app/condivisi/beanStaticoEsteso';
import { MatCorr } from 'src/app/interface/correlazioni';
import { customCorrAmbienteToSave } from 'src/app/interface/indicatori-guida-bp';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { CorrelazioniService } from 'src/app/_services/correlazioni.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-configurazione-correlazioni-ambiente',
  templateUrl: './configurazione-correlazioni-ambiente.component.html',
  styleUrls: ['./configurazione-correlazioni-ambiente.component.css']
})
export class ConfigurazioneCorrelazioniAmbienteComponent implements OnInit {


  @Input() get idRichiedenteTemp(): number { return this.idRichiedenteTemp1; }

  set tabIndex(tabIndex: number) {
    this.currentIndex = tabIndex;
  }
  set idRichiedenteTemp(idRichiedenteTemp: number) {
    this.idRichiedenteTemp1 = idRichiedenteTemp;
    if (this.idRichiedenteTemp1 != undefined) {
      this.ngOnInit();//forse
    }
  }

  get correlazioneAmbienteArray(): UntypedFormArray { return <UntypedFormArray> this.correlazioneAmbienteFormGroup?.controls.correlazioneAmbiente; }
  get correlazioneAmbienteFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.correlazioneAmbienteArray?.controls; }

  correlazioneAmbienteFormGroup: UntypedFormGroup;
  correlazione: UntypedFormControl;
  valoreCorrelazione: UntypedFormControl;
  cartProductCorr: any[] = [{ uno: BeanStatico, due: BeanStatico }];
  listaTipoCorrelazioniTraLinee:BeanStaticoEsteso[] = [];

  submitted:boolean;

  firstLb: number = 0;
  otherLb: number[] = [];
  lastSelected: number = -1;
  lastSelectedCp: number = -1;
  isFirstTime: boolean = true;
  opt1: number[] = [1, 2, 3, 4]
  opt2: number[] = [1, 2, 3, 4]
  opt3: number[] = [1, 2, 3, 4]
  opt4: number[] = [1, 2, 3, 4]
  tempMat: MatCorr = { matrice1: 0, matrice2: 0, matrice3: 0, matrice4: 0, matrice5: 0, corr12: 0, corr13: 0, corr14: 0, corr15: 0 };
  corrMiste: number[] = [];
  corrMistePerLegenda: number[] = [];
  corrInterne: number[] = [];
  corrMisteTemp: number[] = [0, 0, 0, 0];
  ordineLinee: number[] = [];
  ordineLineeAmbiente: number[] = []
  tempDataToSave: customCorrAmbienteToSave = { id: 0, idUtente: 0,idRichiedente:0, idMatrice: 0, ordineLinee: [] };
  firstLBStart: number = 0;
  otherLBstart: number[] = [];

  lineeBusiness: BeanStatico[] = [];
  currentIndex: number;
  private idRichiedenteTemp1: number;
  currentLineeSavedSub: Subscription

  constructor( private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private tokeStorageService: TokenStorageService,
    private corrService: CorrelazioniService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,

    ) { }

  ngOnInit(): void
   {
    this.currentLineeSavedSub = this.configurazioneAmbienteService.currentLineedSaved.subscribe(lineeAreSaved => {
      this.getLineeBusiness()
    })
  }

  getLineeBusiness() {
    if (this.idRichiedenteTemp1 != undefined) {
      this.currentIndex = 1;
      this.configurazioneAmbienteService.getLineeBusiness(this.idRichiedenteTemp, this.tokeStorageService.getUser().cliente.idCliente).subscribe(response => {
        this.lineeBusiness = []
        if (response.lineeBusiness) {
          for (let i = 0; i < response.lineeBusiness.length; i++) {
            const lineaBusiness = { id: response.lineeBusiness[i].id, descrizione: response.lineeBusiness[i].nomeLineaBusiness };
            this.lineeBusiness.push(lineaBusiness);
          }
          this.setData();
        }
      })
    }
  }

  setData(){
    
    this.corrService.takeAllCorrDesc().subscribe(result => {
      this.listaTipoCorrelazioniTraLinee = result;
    })

  
    this.correlazioneAmbienteFormGroup = this.formBuilder.group({
      correlazioneAmbiente: this.formBuilder.array([])
    });

   
    for (var i = 0; i < this.lineeBusiness.length; i++) {
      if (i != 0) {
        let tempForm = this.formBuilder.group({
          correlazione: [{ value: ''}, Validators.required],
          valoreCorrelazione: [{ value: ''}, Validators.required],
        })
        this.correlazioneAmbienteArray.push(tempForm)
      }

    }

    this.corrService.getValuesForAmbient(this.idRichiedenteTemp,this.tokeStorageService.getUser().idUtente, this.lineeBusiness.length).subscribe(r=>{

      
    this.cartProductCorr = [];
    for (var i = 0; i < this.lineeBusiness.length; i++) {
      for (var l = 0; l < this.lineeBusiness.length; l++) {
        i != l ? this.cartProductCorr.push({ uno: this.lineeBusiness[i], due: this.lineeBusiness[l] }) : null;
      }
    }

      this.corrInterne = [];
      this.corrInterne.push(r.valoriMatrice.matrice1)
      this.corrInterne.push(r.valoriMatrice.matrice2)
      this.corrInterne.push(r.valoriMatrice.matrice3)
      this.corrInterne.push(r.valoriMatrice.matrice4)
      this.corrInterne.push(r.valoriMatrice.matrice5)

      if(r.customCorrAMB !=null){

          this.ordineLinee = [];
          this.corrMiste = [];
          this.otherLb = [];
          this.otherLBstart = [];
          this.corrMisteTemp = [];
          this.lastSelected = this.lineeBusiness.length - 2;
          this.lastSelectedCp = this.lineeBusiness.length - 2;
          this.ordineLinee = r.customCorrAMB.ordineLinee;

          this.tempDataToSave.id = r.customCorrAMB.id
          this.tempDataToSave.idMatrice = r.customCorrAMB.idMatrice;
          this.tempDataToSave.idUtente = r.customCorrAMB.idUtente;
          this.tempDataToSave.idRichiedente = r.customCorrAMB.idRichiedente
          this.tempDataToSave.ordineLinee = r.customCorrAMB.ordineLinee

          this.corrMiste.push(r.valoriMatrice.corr12)
          this.corrMiste.push(r.valoriMatrice.corr13)
          this.corrMiste.push(r.valoriMatrice.corr14)
          this.corrMiste.push(r.valoriMatrice.corr15)

          this.corrMisteTemp.push(r.valoriMatrice.corr12)
          this.corrMisteTemp.push(r.valoriMatrice.corr13)
          this.corrMisteTemp.push(r.valoriMatrice.corr14)
          this.corrMisteTemp.push(r.valoriMatrice.corr15)


          this.firstLb = r.customCorrAMB.ordineLinee[0];
          this.firstLBStart = r.customCorrAMB.ordineLinee[0];

          r.customCorrAMB.ordineLinee.forEach((el, i) => {
            if (i > 0) {
              this.otherLb.push(el);
              this.otherLBstart.push(el);
            }
          })


    
      }else{
        this.correlazioneAmbienteFormGroup.markAsDirty()

        this.tempDataToSave.id =0 
        this.tempDataToSave.idMatrice = r.valoriMatrice.id;
        this.tempDataToSave.idUtente  = this.tokeStorageService.getUser().idUtente
        this.tempDataToSave.idRichiedente =  this.idRichiedenteTemp

        this.ordineLinee = [];
        this.corrMiste = [];
        this.otherLb = [];
        this.otherLBstart = [];
        this.corrMisteTemp = [];
        this.lastSelected = this.lineeBusiness.length - 2;
        this.lastSelectedCp = this.lineeBusiness.length - 2;
        
        this.corrMiste.push(r.valoriMatrice.corr12)
        this.corrMiste.push(r.valoriMatrice.corr13)
        this.corrMiste.push(r.valoriMatrice.corr14)
        this.corrMiste.push(r.valoriMatrice.corr15)

        this.corrMisteTemp.push(r.valoriMatrice.corr12)
        this.corrMisteTemp.push(r.valoriMatrice.corr13)
        this.corrMisteTemp.push(r.valoriMatrice.corr14)
        this.corrMisteTemp.push(r.valoriMatrice.corr15)


        this.lineeBusiness.forEach((el, i) => {
          i == 0 ? (this.firstLb = el.id, this.firstLBStart = el.id) : null;
          if (i > 0) {
            this.otherLb.push(el.id);
            this.otherLBstart.push(el.id);
          }
          this.ordineLinee.push(el.id)
        })
        
        this.tempDataToSave.ordineLinee = this.ordineLinee;
        let tempMat: MatCorr = {
          matrice1: this.corrInterne[0], matrice2: this.corrInterne[1], matrice3: this.corrInterne[2], matrice4: this.corrInterne[3], matrice5: this.corrInterne[4],
          corr12: this.corrMisteTemp[0], corr13: this.corrMisteTemp[1], corr14: this.corrMisteTemp[2], corr15: this.corrMisteTemp[3]
        };
        this.mergeData(tempMat)


      }

    })


  }

  // funzione che setta le option della select con i risultati che arrivano da backend
presetSelect(indice: number, LBorID: number[] | number, tipo: string): boolean {
  if (tipo == "label") {
    if (LBorID[0] == this.firstLb && this.otherLb[indice] == LBorID[1]) {
      this.correlazioneAmbienteFormArray[indice]?.controls['correlazione'].setValue([LBorID[0], LBorID[1]])
      this.correlazioneAmbienteFormArray[indice]?.controls['correlazione'].updateValueAndValidity()
      this.correlazioneAmbienteArray.updateValueAndValidity();
      return true;
    } else {
      return false
    }
  } else {
    if (LBorID == this.corrMisteTemp[indice]) {
      this.correlazioneAmbienteFormArray[indice]?.controls['valoreCorrelazione'].setValue(LBorID)
      this.correlazioneAmbienteFormArray[indice]?.controls['valoreCorrelazione'].updateValueAndValidity()
      this.correlazioneAmbienteArray.updateValueAndValidity();
      return true
    } else {
      return false
    }
  }
}

filterOtherByFirst(idLB: number, idOptPrinc: number): boolean {
  return idLB != 0 ? (idOptPrinc == idLB) : true;
}

filterNext(idOptSecArr: number[], idOptSec: number, index: number): boolean {
  let tempLB = idOptSecArr;
  return tempLB.slice(0,index).includes(idOptSec);
}


checkAble(n: number, index: number): boolean {
  if (index == 1) {
    return this.opt1.includes(n);
  } else if (index == 2) {
    return this.opt2.includes(n);
  } else if (index == 3) {
    return this.opt3.includes(n);
  } else if (index == 4) {
    return this.opt4.includes(n);
  } else {
    return true
  }
}


onChangeCouple(el: any, index: number) {

  this.lastSelectedCp = index;
  index < this.lineeBusiness.length - 2 ? (this.correlazioneAmbienteFormArray[index + 1]?.controls.correlazione.enable(),this.correlazioneAmbienteFormArray[index + 1]?.controls.correlazione.setValue(null),this.correlazioneAmbienteFormArray[index + 1]?.controls.correlazione.markAsPristine()) : null;
 
  if (index == 0) {
    this.firstLb = el.split(",")[0]
    this.otherLb = []
    this.ordineLinee = []

    for (let i = index + 2; i < this.lineeBusiness.length - 1; i++) {
       this.correlazioneAmbienteFormArray[i]?.controls.correlazione.disable()
       this.correlazioneAmbienteFormArray[i]?.controls.correlazione.markAsPristine()
       this.correlazioneAmbienteFormArray[i]?.controls.correlazione.setValue(null)
    }

    this.otherLb.push(+el.split(",")[1])
    this.ordineLinee.push(+el.split(",")[0])
    this.ordineLinee.push(+el.split(",")[1])

  } else {
    this.otherLb = this.otherLb.slice(0,index)
    this.otherLb.push(+el.split(",")[1])
    this.ordineLinee = this.ordineLinee.slice(0,index+1)
    this.ordineLinee.push(+el.split(",")[1])

  }
  

  if (this.lastSelectedCp == this.lineeBusiness.length - 2 && this.lastSelected == this.lineeBusiness.length - 2) {
    let tempMat: MatCorr = {
      matrice1: this.corrInterne[0], matrice2: this.corrInterne[1], matrice3: this.corrInterne[2], matrice4: this.corrInterne[3], matrice5: this.corrInterne[4],
      corr12: this.corrMisteTemp[0], corr13: this.corrMisteTemp[1], corr14: this.corrMisteTemp[2], corr15: this.corrMisteTemp[3]
    };
    this.mergeData(tempMat)
  }

}



cambiaOpt(scelta: number, indice: number) {


  this.isFirstTime = false;
  this.opt1 = [1, 2, 3, 4]
  this.opt2 = [1, 2, 3, 4]
  this.opt3 = [1, 2, 3, 4]
  this.opt4 = [1, 2, 3, 4]

  this.lastSelected = indice;
  this.corrMisteTemp[indice] = +scelta;

  this.corrMisteTemp.forEach((el, i) => {
    if (i > indice) {
      this.corrMisteTemp[i] = 0;
      if (i < this.lineeBusiness.length - 1) {
        this.correlazioneAmbienteFormArray[i]?.controls.valoreCorrelazione.disable()
      }
    }
  })

  let tempMat: MatCorr = {
    matrice1: this.corrInterne[0], matrice2: this.corrInterne[1], matrice3: this.corrInterne[2], matrice4: this.corrInterne[3], matrice5: this.corrInterne[4],
    corr12: this.corrMisteTemp[0], corr13: this.corrMisteTemp[1], corr14: this.corrMisteTemp[2], corr15: this.corrMisteTemp[3]
  };

  this.corrService.checkOptions(tempMat).subscribe(r => {
    if (indice == 0) {
      return this.opt1 = r
    } else if (indice == 1) {
      return this.opt2 = r
    } else if (indice == 2) {
      return this.opt3 = r
    } else if (indice == 3) {
      return this.opt4 = r
    } else {
      return true
    }
  })

  if (indice < this.lineeBusiness.length - 2) {
    this.correlazioneAmbienteFormArray[indice + 1]?.controls.valoreCorrelazione.enable()
    this.correlazioneAmbienteFormArray[indice + 1]?.controls.valoreCorrelazione.markAsPristine()
    this.correlazioneAmbienteFormArray[indice + 1]?.controls.valoreCorrelazione.setValue(null)
  }
  indice == this.lineeBusiness.length - 2 && this.lastSelectedCp == this.lineeBusiness.length - 2 ? this.mergeData(tempMat) : null;
}


mergeData(tempMat: MatCorr) {
  this.corrService.checkExistingMat(tempMat).subscribe(r => {
    this.tempDataToSave.idMatrice = r
    this.tempDataToSave.ordineLinee = this.ordineLinee;
    this.tempDataToSave.idRichiedente = this.idRichiedenteTemp1;
    this.tempDataToSave.idUtente = this.tokeStorageService.getUser().idUtente;
    this.correlazioneAmbienteFormGroup.markAsDirty()
  })
}


isFormDirty() {
  for (let i = 0; i < this.correlazioneAmbienteFormArray.length; i++) {
    if (this.correlazioneAmbienteArray.controls[i].dirty) {
      this.correlazioneAmbienteFormGroup.markAsDirty()
    }
  }
  this.submitted = this.correlazioneAmbienteFormGroup.dirty
  return this.submitted
}

annulla() {
  /* devo fare il ciclo perche il DirtyformDirty si vede solo al livello prodottiFormArray */
  if (this.isFormDirty()) {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll();
    }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Così facendo, tutte le modifiche non salvate andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
  
          this.corrMisteTemp = [];
          this.otherLb = []
          this.firstLb = this.firstLBStart;
          this.lastSelected = this.otherLBstart.length;
          this.lastSelectedCp = this.otherLBstart.length;
          this.corrMiste.forEach(el => {
            this.corrMisteTemp.push(el)
          })
          this.otherLBstart.forEach(el => {
            this.otherLb.push(el)
          })
  
          this.correlazioneAmbienteFormGroup.markAsPristine()
  
        }
      })
   

   
  }
}

submitCors(){
  if (this.isFormDirty()) {
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll();
    }
    if(this.correlazioneAmbienteFormGroup.valid){
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Salva correlazioni",
        description: "Sicuro di voler salvare queste impostazioni per le correlazioni?",
        undoButtonText: "Annulla",
        actionButtonText: "Salva",
      }
      modalRef.result.then((r) => {
        if (r) {
  
         this.configurazioneAmbienteService.saveCorrelazioni(this.tempDataToSave).subscribe();
         this.correlazioneAmbienteFormGroup.markAsPristine()
        }
      })
    }else{
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "errore",
        title: "Errore",
        description: "Compila tutti i dati delle correlazioni:",
        actionButtonText: "",
        undoButtonText: "Ok",
      }
    }
  
  }

}

}
