<div>
    <div class="mat-body-text-2" [ngStyle]="{'color': this.annoInizioBilancio <= 0 ? 'transparent' : ''}">
        <span [hidden]="this.annoInizioBilancio <= 0" style="color: var(--main-black);">INDICI
            AL</span>
        31.12.{{this.annoInizioBilancio}}
        <!--{{this.durataBP.annoStart-1}}-->
    </div>
    <table *ngIf="is_as; else precisely_year_indici" mat-table [dataSource]="dataSourceIndici"
        class="mat-elevation-z8 md-tab">

        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="INDICI">
            <th mat-header-cell *matHeaderCellDef> Indici </th>
            <td mat-cell *matCellDef="let element"> {{element.indice}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="VALORE">
            <th mat-header-cell *matHeaderCellDef> {{this.annoInizioBilancio > 0 ? 'Valore' : ''}} </th>
            <td mat-cell *matCellDef="let element"> {{this.annoInizioBilancio > 0 ? (element.value != 0 ?
                element.valueString
                : '-') : ''}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <ng-template #precisely_year_indici>
        <table mat-table [dataSource]="dataSourceIndiciRidotto" class="mat-elevation-z8 md-tab">

            <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="INDICI">
                <th mat-header-cell *matHeaderCellDef> Indici </th>
                <td mat-cell *matCellDef="let element"> {{element.indice}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="VALORE">
                <th mat-header-cell *matHeaderCellDef> {{this.annoInizioBilancio > 0 ? 'Valore' : ''}} </th>
                <td mat-cell *matCellDef="let element"> {{this.annoInizioBilancio > 0 ? (element.value != 0 ?
                    element.valueString
                    : '-') : ''}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </ng-template>

    <div class="mat-body-text-2"><span style="color: rgba(0, 0, 0, 0);">AS IS: ANNO</span> </div>
    <table mat-table [dataSource]=" dataSourceContoEconomico" class="mat-elevation-z8 md-tab">

        <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="INDICI">
            <th mat-header-cell *matHeaderCellDef> Conto Economico </th>
            <td mat-cell *matCellDef="let element"> {{element.indice}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="VALORE">
            <th mat-header-cell *matHeaderCellDef> {{this.annoInizioBilancio > 0 ? 'Valore' : ''}} </th>
            <td mat-cell *matCellDef="let element"> {{this.annoInizioBilancio > 0 ? (element.value != 0 ? (element.value
                | number:'1.0':'it')+ '€' : '-') : ''}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div *ngIf="is_as; else precisely_year">
        <table mat-table [dataSource]="dataSourceCashFlow" class="mat-elevation-z8 md-tab">

            <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="INDICI">
                <th mat-header-cell *matHeaderCellDef> Cash Flow </th>
                <td mat-cell *matCellDef="let element"> {{element.indice}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="VALORE">
                <th mat-header-cell *matHeaderCellDef> {{this.annoInizioBilancio > 0 ? 'Valore' : ''}} </th>
                <td mat-cell *matCellDef="let element"> {{this.annoInizioBilancio > 0 ? (element.value != 0 ?
                    (element.value | number:'1.0':'it')+ '€' : '-') : '' }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <ng-template #precisely_year>
        <table mat-table [dataSource]="dataSourceCashFlowRidotto" class="mat-elevation-z8 md-tab">

            <!--- Note that these columns can be defined in any order.
                                        The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="INDICI">
                <th mat-header-cell *matHeaderCellDef> Cash Flow </th>
                <td mat-cell *matCellDef="let element"> {{element.indice}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="VALORE">
                <th mat-header-cell *matHeaderCellDef> {{this.annoInizioBilancio > 0 ? 'Valore' : ''}} </th>
                <td mat-cell *matCellDef="let element"> {{this.annoInizioBilancio > 0 ? (element.value != 0 ?
                    (element.value | number:'1.0':'it')+ '€' : '-'): '' }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </ng-template>

    <div class="mat-body-text-2"><span style="color: rgba(0, 0, 0, 0) !important">.</span></div>
    <table mat-table [dataSource]="dataSourceStstoPatrimoniale" class="mat-elevation-z8 md-tab">

        <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="INDICI">
            <th mat-header-cell *matHeaderCellDef> Stato Patrimoniale </th>
            <td mat-cell *matCellDef="let element"> {{element.indice}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="VALORE">
            <th mat-header-cell *matHeaderCellDef> {{this.annoInizioBilancio > 0 ? 'Valore' : ''}} </th>
            <td mat-cell *matCellDef="let element"> {{this.annoInizioBilancio > 0 ? (element.value != 0 ? (element.value
                | number:'1.0':'it')+ '€' : '-') : ''}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>