<h1 mat-dialog-title>Gestione Partite Iva  -  Nome Utente : {{this.data2.username}}</h1>
<div mat-dialog-content>
    <div class="container">
<div class="row" style="overflow: hidden;">
        <div class="col-sm-6" >
            <h3> Aziende Assegnate:</h3>
            <mat-chip-list class="mat-chip-list-stacked" >
                <mat-chip *ngFor="let Aric of AziendeRichiedentiAssegnate.rows" >
               Nome Azienda: {{Aric.ragioneSociale}} - Partita Iva: {{Aric.partitaIva}} <button matChipRemove  (click)="changeSide(this.Aric,'sx')" ><mat-icon>cancel</mat-icon></button>
                </mat-chip>
              </mat-chip-list>
        </div>
        <div class="col-sm-6">
            <h3> Aziende Assegnabili:</h3>
            <mat-chip-list class="mat-chip-list-stacked">
                <mat-chip *ngFor="let Aric of AziendeRichiedentiAssegnabili.rows">
                Nome Azienda: {{Aric.ragioneSociale}} - Partita Iva: {{Aric.partitaIva}} <button matChipRemove (click)="changeSide(this.Aric,'dx')" ><mat-icon>add_circle</mat-icon></button>
                </mat-chip>
              </mat-chip-list>
        </div>
    </div>

    </div>
  
</div>
<div mat-dialog-actions class="rigaDialog" >
  <button class="btn btn-danger" (click)="onNoClick()">Annulla</button>
  <button class="btn btn-primary" (click)="submitModPiva()" style="margin-left:10px;">Salva</button>
</div>

