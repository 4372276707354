import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Validators, UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { Prodotti } from '../anagraficaprodotti/prodotti';
import { TokenStorageService } from '../_services/token-storage.service';
import { environment } from 'src/environments/environment';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';

@Component({
  selector: 'mv-anagrafica-prodotti',
  template: `
    <div class="container-fluid center">
      <h3 class="text-center mb-3">Anagrafica Prodotti</h3>
      <form #f="ngForm" name="form" (ngSubmit)="f.form.valid && registra(f)" > <!-- richiama la modale? -->

        <div class="row margin-bottom-10">
          <div class="col-sm-12">
            <button type="button" class="btn btn-success btn-md me-1" (click)="toggle()" >
              Ricerca
            </button>
            <img class="mb-0 mt-n1" src="{{ imgsearch }}" alt="" style="border-radius: 10px;" />
          </div>
        </div>

        <ng-container *ngIf="show">
          <div class="row margin-bottom-10">
            <div class="col-sm-4" style="text-align: right;"><label>Categorie:</label></div>
            <div class="col-sm-4">
              <input id="categoriaRicerca" name="categoriaRicerca" class="form-control" type="text"
                [ngbTypeahead]="search_categorie" #instance="ngbTypeahead"
                (focus)="focus_categorie.next($any($event).target.value)"
                (click)="click_categorie.next($any($event).target.value)"
                [inputFormatter]="formatter" [resultFormatter]="formatter"
                (selectItem)="getCategorie($event)" />
            </div>
            <div class="col-sm-4"></div>
          </div>

          <div class="row margin-bottom-10 mt-1">
            <div class="col-sm-4" style="text-align: right;"><label>Prodotti:</label></div>
            <div class="col-sm-4">
              <input id="prodottoRicerca" name="prodottoRicerca" class="form-control" type="text"
                [ngbTypeahead]="search_prodotti" #instance="ngbTypeahead"
                (focus)="focus_prodotti.next($any($event).target.value)"
                (click)="click_prodotti.next($any($event).target.value)"
                [inputFormatter]="formatter" [resultFormatter]="formatter"
                (selectItem)="SelezionaProdotto($event)" />
            </div>
            <div class="col-sm-4"></div>
          </div>

          <div class="row margin-bottom-10 mt-1">
            <div class="col-sm-4" style="text-align: right;"><label>Varietà:</label></div>
            <div class="col-sm-4">
              <input id="varietaRicerca" name="varietaRicerca" class="form-control" type="text"
                [ngbTypeahead]="search_varieta" #instance="ngbTypeahead"
                (focus)="focus_varieta.next($any($event).target.value)"
                (click)="click_varieta.next($any($event).target.value)"
                [inputFormatter]="formatter" [resultFormatter]="formatter"
                (selectItem)="getDescrizione($event)" />
            </div>
            <div class="col-sm-4"></div>
          </div>
        </ng-container>

        <div class="row margin-bottom-10 mt-n3">
          <div class="col-sm-12"><hr /></div>
        </div>

        <div class="row margin-bottom-10 mt-n2">
          <div class="col-sm-4" style="text-align: right;"><label>Descrizione Varietà:</label></div>
          <div class="col-sm-4">
            <input type="text" class="form-control" name="descrizioneVarieta"
              [(ngModel)]="form.descrizioneVarieta" #descrizioneVarieta="ngModel"
              aria-label="Default" aria-describedby="inputGroup-sizing-default"
              (ngModelChange)="getDescrizione2($event)" required />
          </div>
          <div class="col-sm-4 mt-2">
            <div class="alert-danger" *ngIf="f.submitted && descrizioneVarieta.invalid" >
              <div *ngIf="descrizioneVarieta.errors.required">dato obbligatorio</div>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10 mt-1">
          <div class="col-sm-4" style="text-align: right;"><label>Cicli di Produzione Annuali:</label></div>
          <div class="col-lg-1">
            <input type="number" min="0" class="form-control" name="cicliDiProduzioneAnnuali"
              [(ngModel)]="form.cicliDiProduzioneAnnuali" #cicliDiProduzioneAnnuali="ngModel"
              aria-label="Default" aria-describedby="inputGroup-sizing-default" required
              onKeyDown="return false" (ngModelChange)="getCicliProduzioneAnnuali($event)" style="width:65px;" />
          </div>
          <div class="col-sm-7 mt-2">
            <div class="alert-danger" *ngIf="f.submitted && cicliDiProduzioneAnnuali.invalid" >
              <div *ngIf="cicliDiProduzioneAnnuali.errors.required">dato obbligatorio</div>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10 mt-1">
          <div class="col-sm-4" style="text-align: right;"><label>Numero Anni Latenza Produttiva:</label></div>
          <div class="col-lg-1">
            <input type="number" min="0" class="form-control" name="numeroAnniLatenzaProduttiva"
              [(ngModel)]="form.numeroAnniLatenzaProduttiva" #numeroAnniLatenzaProduttiva="ngModel"
              aria-label="Default" aria-describedby="inputGroup-sizing-default" required
              onKeyDown="return false" (ngModelChange)="getAnniLatenzaProduttiva($event)" style="width:65px;" />
          </div>
          <div class="col-sm-7 mt-2">
            <div class="alert-danger" *ngIf="f.submitted && numeroAnniLatenzaProduttiva.invalid" >
              <div *ngIf="numeroAnniLatenzaProduttiva.errors.required">dato obbligatorio</div>
            </div>
          </div>
        </div>

        <div class="row margin-bottom-10 mt-1">
          <div class="col-sm-4" style="text-align: right;"><label>% Annua Latenza Produttiva:</label></div>
          <div class="col-lg-1">
            <input class="form-control" name="percentualeAnnuaLatenzaProduttiva"
              aria-label="Default" aria-describedby="inputGroup-sizing-default"
              [(ngModel)]="form.percentualeAnnuaLatenzaProduttiva" #percentualeAnnuaLatenzaProduttiva="ngModel"
              (ngModelChange)="setArrayLatenzaProduttiva($event)" style="width:65px;" />
          </div>

          <div class="col-sm-1" >
            <ng-template #tipContent>
              Inserire {{ this.form.numeroAnniLatenzaProduttiva }} valori
            </ng-template>
            <a (click)="getArrayLatenzaProduttiva()" placement="top" [ngbTooltip]="tipContent" >
              <img class="mt-2" src="{{ imgadd }}" alt="" style="border-radius: 10px;" /></a>
          </div>

          <div class="col-sm-5 mt-n1">
            <label name="arrayLatenzaProduttiva">{{ this.arrayValori }}</label>
            <div>
              <span class="text-danger" *ngIf="this.count != this.form.numeroAnniLatenzaProduttiva && submitted">
                Inserire {{ this.form.numeroAnniLatenzaProduttiva }} valori, tanti quanti dichiarati nel campo precedente!
              </span>
            </div>
          </div>

          <div class="col-sm-1 mt-1">
            <ng-template #tipContent2>
              Pulisce stringa % ...
            </ng-template>
            <a (click)="this.count = 0; this.arrayValori = []; this.arrayLatenzaProduttiva = [];"
              placement="top" [ngbTooltip]="tipContent2">
              <img class="mt-1" src="{{ imgclear }}" alt="" style="border-radius: 10px;" /></a>
          </div>
        </div>

        <div class="row margin-bottom-10 mt-1">
          <div class="col-sm-4" style="text-align: right;"><label>Anni Durata Pianta:</label></div>
          <div class="col-lg-1">
            <input type="number" min="0" class="form-control" name="anniDurataPianta"
              aria-label="Default" aria-describedby="inputGroup-sizing-default"
              [(ngModel)]="form.anniDurataPianta" #anniDurataPianta="ngModel"
              onKeyDown="return false" (ngModelChange)="getAnniDurataPianta($event)" style="width:65px;"/>
          </div>
          <div class="col-sm-7 mt-2"></div>
        </div>

        <div class="row margin-bottom-10 mt-n3">
          <div class="col-sm-12"><hr /></div>
        </div>

        <div class="row margin-bottom-10 mt-n3">
          <div class="col-sm-4"></div>
          <div class="col-sm-8">
            <button type="submit" class="btn btn-primary btn-md me-1" *ngIf="this.visibilitaRegistra">
              Registra
            </button>
            <button class="btn btn-success btn-md me-1" *ngIf="this.visibilitaElimina"
              (click)="elimina()">Elimina
            </button>
            <button class="btn btn-secondary btn-md me-1" *ngIf="this.visibilitaReset"
              (click)="refresh()">Pulisci
            </button>
          </div>
        </div>
      </form>
    </div>
<!-- modale registra prodotto -->
<!--     <ng-template #content let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgok }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Registrato</h4>
        <button type="button" class="close" aria-label="Close" (click)="refresh()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Varietà prodotto registrata correttamente! <fa-icon [icon]="faCheck" class="fa-2x"></fa-icon></p>
      </div>
    </ng-template> -->
<!-- modale errore registra prodotto -->
<!--     <ng-template #contentError let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgabort }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Errore</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p> Varietà prodotto NON registrata! <fa-icon [icon]="faTimes" class="fa-2x"></fa-icon></p>
      </div>
    </ng-template> -->
    <!-- modale elimina prodotto -->
<!--     <ng-template #contentEliminato let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgok }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Eliminato</h4>
        <button type="button" class="close" aria-label="Close" (click)="refresh()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Varietà prodotto eliminata correttamente! <fa-icon [icon]="faTrashAlt" class="fa-2x"></fa-icon></p>
      </div>
    </ng-template> -->
  `,
  styles: [`
    hr {
      background-color: #059b5b;
      height: 1px;
      border: 0;
    }
    .top-titolo {
      color: #059b5b;
      font-weight: 600;
    }
  `],
})

export class AnagraficaProdottiComponent implements OnInit {
  imgsearch = 'assets/img/View.png';
  imgadd = 'assets/img/Add.png';
  imgabort = 'assets/img/Abort.png';
  imgok = 'assets/img/OK.png';
  imgapply = 'assets/img/Apply.png';
  imgclear = 'assets/img/Clear.png';
  form?: Prodotti = {};

  faCheck = faCheck;
  faTimes = faTimes;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;
  faEdit = faEdit;
  registerForm: UntypedFormGroup;
  submitted = false;

  public varietaRicerca: any;

  descrizione: string = '';
  cicli_produzione_annuali: number;
  anni_latenza_produttiva: number;
  anni_durata_pianta: number;
  array_latenza_produttiva: number;

  arrayValori: string = '';
  count_array_latenza_produttiva: boolean = false;
  public model_ricercaprodotto: any;
  public model_benchmark: any;
  public model_ricercaprodottovarieta: any;
  public model_ciclianniproduzione: any;
  public model_selezionaprodotto: any;

  aggiunta_categoria_descrizione: any;
  HttpClient: HttpClient;
  visibilitaRegistra: boolean;
  visibilitaAggiorna: boolean;
  visibilitaElimina: boolean;
  visibilitaReset: boolean;

  array_categorie: any[] = [];
  array_prodotti: any[] = [];
  array_varieta: any[] = [];
  array_prodotto_varieta: any[] = [];
  array_ricercaprodotto: any[] = [];
  array_ricercaprodottovarieta: any[] = [];
  array_ricercaprodottovarieta2: any[] = [];
  array_getricercaprodottovarieta: any[] = [];

  id_prodotto: number;
  prodotto: string = '';
  cicliDiProduzioneAnnuali: number;
  anniDiLatenzaProduttiva: number;
  anniDiDurataDellaPianta: number;
  id_ricercaprodottovarieta: number;
  id_categoria: number;
  arrayLatenzaProduttivaB: boolean = false;
  arrayLatenzaProduttiva_no: number;
  arrayLatenzaProduttiva: number[] = [];
  percentualeAnnuaLatenzaProduttiva: number[] = [];
  count: number = 0;

  show: boolean = false;
  constructor(
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private tokenStorageService: TokenStorageService,
  ) {
  }

  ngOnInit(): void {
    this.httpClient
      .get<any[]>(environment.apiUrlQuestionario +'/fasGoAlgo/categorie')
      .subscribe((data) => {
        console.log(data);
        this.array_categorie = data;
      });

    this.visibilitaRegistra = true;
    this.visibilitaReset = true;
  }
  get f() {
    return this.registerForm.controls;
  }

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus_ricercaprodotto = new Subject<string>();
  click_ricercaprodotto = new Subject<string>();
  focus_benchmark = new Subject<string>();
  click_benchmark = new Subject<string>();
  click_ricercaprodottovarieta = new Subject<string>();
  focus_ricercaprodottovarieta = new Subject<string>();
  click_selezionaprodotto = new Subject<string>();
  focus_selezionaprodotto = new Subject<string>();

  click_prodotti = new Subject<string>();
  focus_prodotti = new Subject<string>();
  click_prodotti_inserimento = new Subject<string>();
  focus_prodotti_inserimento = new Subject<string>();
  click_categorie = new Subject<string>();
  focus_categorie = new Subject<string>();
  click_categorie_inserimento = new Subject<string>();
  focus_categorie_inserimento = new Subject<string>();
  click_varieta = new Subject<string>();
  focus_varieta = new Subject<string>();

  search_categorie = (text_categorie: Observable<string>) => {
    const debouncedText$ = text_categorie.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click_categorie.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus_categorie;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ? this.array_categorie : this.array_categorie.filter((v) =>
          v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  search_categorie_inserimento = (text_categorie_inserimento: Observable<string>) => {
    const debouncedText$ = text_categorie_inserimento.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click_categorie_inserimento.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus_categorie_inserimento;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ? this.array_categorie : this.array_categorie.filter((v) =>
          v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  search_prodotti = (text_prodotti: Observable<string>) => {
    const debouncedText$ = text_prodotti.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click_prodotti.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus_prodotti;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ? this.array_prodotti : this.array_prodotti.filter((v) =>
          v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  search_prodotti_inserimento = (
    text_prodotti_inserimento: Observable<string>
  ) => {
    const debouncedText$ = text_prodotti_inserimento.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click_prodotti_inserimento.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus_prodotti_inserimento;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ? this.array_prodotti : this.array_prodotti.filter((v) =>
          v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  search_varieta = (text_varieta: Observable<string>) => {
    const debouncedText$ = text_varieta.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click_varieta.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus_varieta;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ? this.array_varieta : this.array_varieta.filter((v) =>
          v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  search_selezionaprodotto = (text_selezionaprodotto: Observable<string>) => {
    const debouncedText$ = text_selezionaprodotto.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click_selezionaprodotto.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus_selezionaprodotto;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ? this.array_ricercaprodotto : this.array_ricercaprodotto.filter((v) =>
          v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  search_ricercaprodottovarieta = (text_ricercaprodottovarieta: Observable<string>) => {
    const debouncedText$ = text_ricercaprodottovarieta.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click_ricercaprodottovarieta.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus_ricercaprodottovarieta;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ? this.array_ricercaprodottovarieta : this.array_ricercaprodottovarieta.filter((v) =>
          v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  search_ricercaprodotto = (text_ricercaprodotto: Observable<string>) => {
    const debouncedText$ = text_ricercaprodotto.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click_ricercaprodotto.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus_ricercaprodotto;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ? this.array_ricercaprodotto : this.array_ricercaprodotto.filter((v) =>
          v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  formatter = (x: { descrizione: string }) => x.descrizione;
/*
  public getRicercaProdotto(event): void {
    console.log('Open ' + event);
    if (event.length == 0) {
    }
    if (event) {
      console.log('1 ' + event.id);
      console.log('1 ' + event);

      this.httpClient
        .post<any[]>(environment.apiUrlQuestionario +'/fasGoAlgo/getVarietaByIdProdotto',
          event.id
        )
        .subscribe((data) => {
          this.array_ricercaprodottovarieta = data;
          console.log(this.array_ricercaprodottovarieta);
        });
    }
  }*/

  public SelezionaProdotto(event): void {
    console.log('Open ' + event.id);
    console.log('Open ' + event.item.id);
    this.id_prodotto = event.item.id;
    console.log(this.id_prodotto);
    this.httpClient
      .post<any[]>(environment.apiUrlQuestionario +'/fasGoAlgo/getRicercaProdottoVarieta/',
        this.id_prodotto
      )
      .subscribe((data) => {
        this.array_varieta = data;
        console.log(this.array_varieta);
        this.visibilitaElimina = true;
        this.visibilitaAggiorna = true;
        this.visibilitaRegistra = true;
        this.visibilitaReset = true;
        this.array_ricercaprodottovarieta2 = this.array_ricercaprodottovarieta;
      });
  }

  public getDescrizione(event): void {
    console.log('getDescrizione-1 ' + event.item.id);
    this.id_ricercaprodottovarieta = event.item.id;
    console.log('getDescrizione-1 ' + this.id_ricercaprodottovarieta);
    console.log('getDescrizione-1 ' + event.id);
    console.log('getDescrizione-2 ' + event.descrizione);
    this.httpClient
      .post<any>(environment.apiUrlQuestionario +'/fasGoAlgo/ricercaProdottoVarieta/',
        event.item.id
      )
      .subscribe((data) => {
        this.form.descrizioneVarieta = data.descrizione;
        this.form.cicliDiProduzioneAnnuali = data.cicliProduzioneAnnuali;
        this.form.numeroAnniLatenzaProduttiva = data.anniLatenzaProduttiva;
        this.form.anniDurataPianta = data.anniDurataPianta;
        this.count = data.anniLatenzaProduttiva;
        this.arrayValori = data.arrayLatenzaProduttiva;
      }
    );
  }

  public getDescrizione2(event): void {
    this.form.descrizioneVarieta = event;
    console.log(this.form.descrizioneVarieta);
    this.visibilitaRegistra = true;
  }
  public getCicliProduzioneAnnuali(event): void {
    console.log('getCicliProduzioneAnnuali ' + event);
    this.form.cicliDiProduzioneAnnuali = event;
    console.log(this.form.cicliDiProduzioneAnnuali);
  }
  public getAnniLatenzaProduttiva(event): void {
    this.form.numeroAnniLatenzaProduttiva = event;
    console.log(this.form.numeroAnniLatenzaProduttiva);
  }
  public setArrayLatenzaProduttiva(event): void {
    this.form.percentualeAnnuaLatenzaProduttivaNo2 = event;
    console.log(this.form.percentualeAnnuaLatenzaProduttivaNo2);
  }

  public getArrayLatenzaProduttiva(): void {
    this.count++;
    if (this.count <= this.form.numeroAnniLatenzaProduttiva) {
      this.percentualeAnnuaLatenzaProduttiva.push(
        this.form.percentualeAnnuaLatenzaProduttivaNo2
      );
      console.log('[' + `${this.percentualeAnnuaLatenzaProduttiva}` + ']');
      this.arrayValori =
        '[' + `${this.percentualeAnnuaLatenzaProduttiva}` + ']';
    }
  }

  public getAnniDurataPianta(event): void {
    console.log('getAnniDurataPianta' + event);
    this.form.anniDurataPianta = event;
    console.log(this.form.anniDurataPianta);
  }

  public registra(f): void {
    let modalRef;
    this.submitted = true;

    if (this.count == this.form.numeroAnniLatenzaProduttiva
    && this.id_prodotto !== 0) {
      const prodottoVarietaBody = {
        id: this.id_ricercaprodottovarieta,
        idProdotto: this.id_prodotto,
        descrizione: this.form.descrizioneVarieta,
        cicliProduzioneAnnuali: this.form.cicliDiProduzioneAnnuali,
        anniLatenzaProduttiva: this.form.numeroAnniLatenzaProduttiva,
        arrayLatenzaProduttiva: this.arrayValori,
        anniDurataPianta: this.form.anniDurataPianta,
        utente: this.tokenStorageService.getUser().username,
        dataUltimaModifica: new Date()
      };

      console.log(prodottoVarietaBody);
      this.httpClient
        .post<any[]>(environment.apiUrlQuestionario +'/fasGoAlgo/salvataggioVarieta', /* faccio un post */
          prodottoVarietaBody,
          { observe: 'response' }
        )
        .subscribe(
          (data) => {
            console.log(data.status);
            if (data.status == 200) {
              /* this.modalService.open(content, { centered: true }); */
              modalRef = this.modalService.open(ModalcontentComponent, {
                centered: true,
                backdrop: 'static',
                backdropClass: 'customBackdrop',
              });
              modalRef.componentInstance.modalPar =  {
                name : "messaggioprodottoRegistrato" /* aggiungere la condizione di ritorno se serve refresh */
              }
              modalRef.result.then(r=>{
                if(!r){
                  window.location.reload();
                }
              })
            }else{}
          },
          (error) => { /* this.modalService.open(contentError, { centered: true }); */
            modalRef = this.modalService.open(ModalcontentComponent, {
            centered: true,
            backdrop: 'static',
            backdropClass: 'customBackdrop',
          });
          modalRef.componentInstance.modalPar =  {
            name : "messaggioerroreProdotto" /* aggiungere la condizione di ritorno se serve refresh */
          }
          modalRef.result.then(r=>{
            if(!r){
              window.location.reload();
            }
          })
          });
    } else {
      /* this.modalService.open(contentError, { centered: true }); */
      modalRef = this.modalService.open(ModalcontentComponent, {
        centered: true,
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar =  {
        name : "messaggioerroreProdotto" /* aggiungere la condizione di ritorno se serve refresh */
      }
    }
    modalRef.result.then(r =>{
      if(!r){
        window.location.reload();
      }
    })

  }
  /* elimina non funziona */
  public elimina(): void {
    let modalRef;
    this.httpClient
      .post<any[]>(environment.apiUrlQuestionario +'/fasGoAlgo/elimina_prodottovarieta/',
        this.id_ricercaprodottovarieta,
        { observe: 'response' }
      )
      .subscribe(
        (data) => {
          console.log("dato: "+data);
          if (data.status == 200) {
            /* this.modalService.open(contentEliminato, { centered: true }); */
            modalRef = this.modalService.open(ModalcontentComponent, {
              centered: true,
              backdrop: 'static',
              backdropClass: 'customBackdrop',
            });
            modalRef.componentInstance.modalPar =  {
              name : "messaggioprodottoEliminato" /* aggiungere la condizione di ritorno se serve refresh */
            }
            modalRef.result.then(r=>{
              if(!r){
                window.location.reload();
              }
            })
          }
        },
        (error) => {/* this.modalService.open(contentError, { centered: true }) */
          modalRef = this.modalService.open(ModalcontentComponent, {
            centered: true,
            backdrop: 'static',
            backdropClass: 'customBackdrop',
          });
          modalRef.componentInstance.modalPar =  {
            name : "messaggioerroreProdotto" /* aggiungere la condizione di ritorno se serve refresh */
          }
          modalRef.result.then(r =>{
          if(!r){
           window.location.reload();
          }
        })
        },
        
      );

    // Cancellazione LOGICA
    const prodottoVarietaBody = {
      idProdotto: this.id_prodotto,
      descrizione: this.descrizione,
      cicliProduzioneAnnuali: this.cicliDiProduzioneAnnuali,
      anniLatenzaProduttiva: this.anniDiLatenzaProduttiva,
      arrayLatenzaProduttiva: '[' + `${this.arrayLatenzaProduttiva}` + ']',
      anniDurataPianta: this.anniDiDurataDellaPianta,
    };

    this.httpClient
      .post<any[]>(environment.apiUrlQuestionario +'/fasGoAlgo/salvataggioVarieta',
        prodottoVarietaBody,
        { observe: 'response' }
      )
      .subscribe(
        (data) => {
          console.log(data.status);
          if (data.status == 200) {
            /* this.modalService.open(contentEliminato, { centered: true }); */
            modalRef = this.modalService.open(ModalcontentComponent, {
              centered: true,
              backdrop: 'static',
              backdropClass: 'customBackdrop',
            });
            modalRef.componentInstance.modalPar =  {
              name : "messaggioprodottoEliminato" /* aggiungere la condizione di ritorno se serve refresh */
            }
            modalRef.result.then(r=>{
              if(!r){
                window.location.reload();
              }
            })
          }
        },
        (error) => {/* this.modalService.open(contentError, { centered: true }) */
        modalRef = this.modalService.open(ModalcontentComponent, {
          centered: true,
          backdrop: 'static',
          backdropClass: 'customBackdrop',
        });
        modalRef.componentInstance.modalPar =  {
          name : "messaggioerroreProdotto" /* aggiungere la condizione di ritorno se serve refresh */
        }
        modalRef.result.then(r=>{
          if(!r){
            window.location.reload();
          }
        })
        }
      );
  }

  public getCategorie(event): void {
    console.log(event.item.id);
    this.id_categoria = event.item.id;
    console.log(this.id_categoria);
    this.httpClient
      .post<any[]>(environment.apiUrlQuestionario +'/fasGoAlgo/getProdottoCategoria',
        this.id_categoria
      )
      .subscribe((data) => {
        console.log(data);
        this.array_prodotti = data;
      });
  }

  public toggle(): void {
    this.show = !this.show;
  }

  public check(): void {
    console.log('cliccato');
  }
}
