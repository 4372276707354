<div class="container-fluid margin-top-10 nopadding" [formGroup]="ricaviEcostiForm" id="lineeDiBusiness">
    <div class="row">
        <div class="col-12" *ngIf="ricaviCostiDisabled">
            <h2 class="erroreRicaviCosti">Compilare almeno una linea di business.</h2>
        </div>
    </div>
    <mat-tab-group mat-stretch-tabs [(selectedIndex)]="currentIndex" class="d-flex justify-content-around"
        (selectedTabChange)="resetPaddingBodyNoSticky($event)">
        <mat-tab *ngFor="let lineaBusiness of lineeBusiness; let i = index" label="{{lineeBusiness[i].nome}}"
            [labelClass]="{'label-error':!lineaBusinessArrayValid[i] && lineeBpDirty[i], 'label-correct': lineaBpSaved[i]}">
            <app-linea-business-bp [lineabusiness]="lineaBusiness" [businessPlanTemp]="businessPlanTemp"
                [lineaBpSaved]="lineaBpSaved[i]" [submitted]="lineeBpDirty[i]" (selectedLineaBp)="emitLineaBp($event)"
                [ricaviEcostiForm]="ricaviCostiFormArray[i]"
                [lineaBusinessVolatile]="ricaviCostiVolatile ? ricaviCostiVolatile[i]: null"
                (emitLineaFormValid)="saveRicaviCostiValid(i,$event)">

            </app-linea-business-bp>
        </mat-tab>
    </mat-tab-group>
</div>