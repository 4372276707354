import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qs-expert-opinion',
  template: `
<!--
  <div class="row">
    <div class="col-12">

    <div class="row margin-0">
        <div class="col-4">
          <div class="row margin-0 text-center">
            <div class="col-6 borderAllBlack min-height-25">Valore iniziale</div>
            <div class="col-6 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">{{valoreIniziale}}</div>
            <div class="col-6 borderAllBlack min-height-25">Valore minimo</div>
            <div class="col-6 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">{{valoreMin}}</div>
            <div class="col-6 borderAllBlack min-height-25">Valore massimo</div>
            <div class="col-6 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">{{valoreMax}}</div>
            <div class="col-6 borderAllBlack min-height-25">Anni previsione</div>
            <div class="col-6 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">{{anni}}</div>
            <div class="col-6 borderAllBlack min-height-25">Scarto</div>
            <div class="col-6 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center">{{scarto}}</div>
          </div>
        </div>

        <div class="col-8">
          <div class="row margin-0 text-center">
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              Peercentile
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{i + 1 | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              99.5%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              99%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              95%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              65%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              50%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              35%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              5%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              1%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>
            <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
              0.5%
            </div>
            <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center" *ngFor="let item of [].constructor(5); let i = index">{{risultatiQuestionario.ebidtaAzienda[i*2] | number: '1.0-0':'it'}} €</div>


          </div>
        </div>

      </div>
    </div>
  </div>
-->
  `,
  styles: [
  ]
})
export class ExpertOpinionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
/*
  onChangeProdotto(){
    this.httpClient.post<any>('http://localhost:9696/fasGoAlgo/calcolaQuestionarioNuovoModello', this.questionario).subscribe(data => {
      console.log(data);
      this.risultatiQuestionario = data
      if(this.questionario.idTipoRichiedente && this.questionario.idTipoRichiedente != 3){
        this.boolAziendaEsistente = true;
        //this.coloreRichiedente = this.risultatiQuestionario.valAzienda < 33.333 ? this.dangerColor : this.risultatiQuestionario.valAzienda >= 33.333 && this.risultatiQuestionario.valAzienda < 66.666 ? this.warningColor : this.successColor;
      }

      this.frequenze = this.risultatiQuestionario.freqIrrEq;
      this.minimoIndicatore = this.risultatiQuestionario.minMaxMedIrrEq[0];
      this.massimoIndicatore = this.risultatiQuestionario.minMaxMedIrrEq[1];
      this.mediaIndicatore = this.risultatiQuestionario.minMaxMedIrrEq[2];
      this.frequenzeDebito= this.risultatiQuestionario.freqDscr;
      this.minimoIndicatoreDebito = this.risultatiQuestionario.minMaxMedDscr[0];
      this.massimoIndicatoreDebito = this.risultatiQuestionario.minMaxMedDscr[1];
      this.mediaIndicatoreDebito = this.risultatiQuestionario.minMaxMedDscr[2];

      //this.coloreProgetto = this.risultatiQuestionario.valProgMed < 33.333 ? this.dangerColor : this.risultatiQuestionario.valProgMed >= 33.333 && this.risultatiQuestionario.valProgMed < 66.666 ? this.warningColor : this.successColor;
      //this.coloreIndebitamento = this.risultatiQuestionario.valDebMed < 33.333 ? this.dangerColor : this.risultatiQuestionario.valDebMed >= 33.333 && this.risultatiQuestionario.valDebMed < 66.666 ? this.warningColor : this.successColor;
      //this.coloreGaranzie = this.risultatiQuestionario.valGaranzia < 33.333 ? this.dangerColor : this.risultatiQuestionario.valGaranzia >= 33.333 && this.risultatiQuestionario.valGaranzia < 66.666 ? this.warningColor : this.successColor;

      });
  }
*/
}
