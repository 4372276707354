/* ------------------------------------------
 * SERVIZIO di AUTENTICAZIONE
 *    login: per accedere (signin)
 * register: per nuova registrazione (signup)
 * ------------------------------------------ */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

const AUTH_API = environment.apiUrlLogin;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  idleCd: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  currentIdleCd: Observable<number> = this.idleCd.asObservable();
  constructor(private http: HttpClient) { }

  login(user: string, pass: string): Observable<any> {
    return this.http.post(AUTH_API + '/auth/login', {
      user,
      pass,
    }, httpOptions);
  }

  refreshToken(oldToken: string) {
    return this.http.post<any>(AUTH_API + '/auth/refreshToken', { oldToken: oldToken }, httpOptions);
  }

  saveIdleCd(cd: number) {
    this.idleCd.next(cd);
  }

  register(nome: string, cognome: string, username: string, password: string,
    email: string, attivo: boolean, levelAccess: BigInteger): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      nome,
      cognome,
      username,
      password,
      email,
      attivo,
      levelAccess
    }, httpOptions);
  }

}
