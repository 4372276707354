<div class="container-fluid" [formGroup]="bilancioManualeForm">
  <div class="row d-flex justify-content-end margin-0">
    <div class="col-12">
      <h2 class="text-center mb-1 margin-0">Dati di Bilancio</h2>
    </div>
    <div class="col-12">
      <h2 class="text-center mb-2 blu margin-0"><strong>{{aziendaRichiedente!=undefined ?
          aziendaRichiedente.ragioneSociale:''}}</strong></h2>
    </div>
    <div class="col-2 d-flex justify-content-center"><button class=" me-1 btn btn-success"
        (click)="onSubmit()">{{isNuovo ? 'Salva':
        'Modifica'}}</button>
      <button type="button" class="margin-left-20 me-1 btn btn-secondary" (click)="svuota()">Svuota</button>
    </div>
  </div>
  <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex" class="d-flex justify-content-around">
    <mat-tab label="Stato patrimoniale" formGroupName="statoPatrimoniale" [labelClass]="{'label-error': erroriSP}">
      <div class="row">
        <div class="col-12 labelError margin-top-10" *ngIf="!attivoPassivoCheck && patrimonialeSubmitted"> Il
          " {{patrimonialeAttivo[13].descrizione}}" e il "{{patrimonialePassivo[8].descrizione}}" devono essere guali.
        </div>
        <div class="col-12 labelError margin-top-10" *ngIf="!utilePerditaCheck && patrimonialeSubmitted">Il campo
          "{{patrimonialePassivo[1].descrizione}}" dello Stato patrimoniale e il campo "{{contoEcon[23].descrizione}}"
          del
          Conto
          economico devono essere guali.</div>
        <div class="col-12 labelError margin-top-10" *ngIf="!immobilizzazioneCheck && patrimonialeSubmitted">Il valore
          di "{{patrimonialeAttivo[4].descrizione}}" si discosta di più di 100€ dalla somma dei campi delle
          immobilizzazioni.</div>
        <div class="col-12 labelError margin-top-10" *ngIf="!creditiCheck && patrimonialeSubmitted">
          Il valore
          di "{{patrimonialeAttivo[8].descrizione}}" si è minore della somma dei campi relativi a
          "{{patrimonialeAttivo[6].descrizione}}" e "{{patrimonialeAttivo[7].descrizione}}".
        </div>
        <div class="col-12 labelError margin-top-10" *ngIf="!attivoCheck && patrimonialeSubmitted">
          Il valore di "{{patrimonialeAttivo[13].descrizione}}" si discosta di più di 100€ dalla somma dei campi di:
          "{{patrimonialeAttivo[4].descrizione}}", "{{patrimonialeAttivo[0].descrizione}}",
          "{{patrimonialeAttivo[12].descrizione}}"
          e "{{patrimonialeAttivo[11].descrizione}}".
        </div>
        <div class="col-12 labelError margin-top-10" *ngIf="!attivoCircCheck && patrimonialeSubmitted">
          Il valore di "{{patrimonialeAttivo[11].descrizione}}" si discosta di più di 100€ dalla somma dei campi di:
          "{{patrimonialeAttivo[5].descrizione}}", "{{patrimonialeAttivo[8].descrizione}}",
          "{{patrimonialeAttivo[9].descrizione}}"
          e "{{patrimonialeAttivo[10].descrizione}}".
        </div>
        <div class="col-12 labelError margin-top-10" *ngIf="!debitiCheck && patrimonialeSubmitted">
          Il valore
          di "{{patrimonialePassivo[6].descrizione}}" si discosta di più di 100€ dalla somma dei campi
          "{{patrimonialePassivo[5].descrizione}}" e
          "{{patrimonialePassivo[4].descrizione}}".
        </div>
        <div class="col-12 labelError margin-top-10" *ngIf="!passivoCheck && patrimonialeSubmitted">
          Il valore
          di "{{patrimonialePassivo[8].descrizione}}" si discosta di più di 100€ dalla somma dei campi
          "{{patrimonialePassivo[0].descrizione}}", "{{patrimonialePassivo[2].descrizione}}",
          "{{patrimonialePassivo[3].descrizione}}", "{{patrimonialePassivo[6].descrizione}}" e
          "{{patrimonialePassivo[7].descrizione}}."</div>
      </div>
      <div class="row margin-top-10">
        <div class="col-5 d-flex justify-content-end"><label class="text-right">Esercizio :</label></div>
        <div class="col-3 col-xl-2 d-flex justify-content-center"> <label
            class="text-center"><strong><i>{{annoBilancio}}</i></strong></label></div>
        <div class="col-3 col-xl-2 d-flex justify-content-center"> <label
            class="text-center"><strong><i>{{annoBilancioPrec}}</i></strong></label></div>
      </div>
      <ng-container formArrayName="attivo" class="margin-top-20">
        <h2 class="text-left mb-2 margin-left-20"><strong>Attivo</strong></h2>
        <div class="row" *ngFor="let attivo of attivoFormArray;let i = index" [formGroupName]="i">
          <div class="col-5">
            <h3 [ngStyle]="{'font-weight': i==4|| i==8 || i==13 || i == 11? 'bold' : 'normal'}">
              {{patrimonialeAttivo[i].descrizione}}&nbsp;&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                aria-hidden="false" [autoClose]="'inside'" placement="top" container="body"
                ngbTooltip={{listaTips[i]?.descrizione}}></i>
            </h3>
          </div>
          <div class="col-3 col-xl-2 d-flex justify-content-center">
            <input type="text" class="form-control" formControlName="inputAnnoScorso" currencyMask
              (keyup)="totaliAttivo()"
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }"
              [ngClass]="{ 'is-invalid':patrimonialeSubmitted && attivo.controls.inputAnnoScorso.errors }" />
            <div *ngIf=" patrimonialeSubmitted && attivo.controls.inputAnnoScorso.errors" class="invalid-feedback">
              <div *ngIf="attivo.controls.inputAnnoScorso.errors.required">Campo obbligatorio
              </div>
            </div>
          </div>
          <div class="col-3 col-xl-2 d-flex justify-content-center">
            <input type="text" class="form-control" formControlName="inputAnnoPrec" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }"
              [ngClass]="{ 'is-invalid':patrimonialeSubmitted && attivo.controls.inputAnnoPrec.errors }" />
            <div *ngIf=" patrimonialeSubmitted && attivo.controls.inputAnnoPrec.errors" class="invalid-feedback">
              <div *ngIf="attivo.controls.inputAnnoPrec.errors.required">Campo obbligatorio
              </div>
            </div>
          </div>

        </div>
      </ng-container>
      <hr>
      <ng-container formArrayName="passivo" class="margin-top-20">
        <h2 class="text-left mb-2 margin-left-20"><strong>Passivo</strong></h2>
        <div class="row" *ngFor="let passivo of passivoFormArray;let i = index" [formGroupName]="i">
          <div class="col-5">
            <h3 [ngStyle]="{'font-weight': i==6 || i==8? 'bold' : 'normal'}">
              {{patrimonialePassivo[i].descrizione}}&nbsp;&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                aria-hidden="false" [autoClose]="'inside'" placement="top" container="body"
                ngbTooltip={{listaTips[i]?.descrizione}}></i></h3>
          </div>
          <div class="col-3 col-xl-2 d-flex justify-content-center">
            <input type="text" class="form-control" formControlName="inputAnnoScorso" currencyMask
              (keyup)="totaliPassivo()"
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }"
              [ngClass]="{ 'is-invalid':patrimonialeSubmitted && passivo.controls.inputAnnoScorso.errors }" />
            <!-- ||this.tipoCreditoList[i]!=7 && submittedCrediti && arrayErroriCrediti.sommaParziale[i]==true || submittedCrediti && arrayErroriCrediti.sommainputAnnoScorso ==true  -->
            <div *ngIf=" patrimonialeSubmitted && passivo.controls.inputAnnoScorso.errors" class="invalid-feedback">
              <div *ngIf="passivo.controls.inputAnnoScorso.errors.required">Campo obbligatorio
              </div>
            </div>
          </div>
          <div class="col-3 col-xl-2 d-flex justify-content-center">
            <input type="text" class="form-control" formControlName="inputAnnoPrec" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }"
              [ngClass]="{ 'is-invalid':patrimonialeSubmitted && passivo.controls.inputAnnoPrec.errors }" />
            <!-- ||this.tipoCreditoList[i]!=7 && submittedCrediti && arrayErroriCrediti.sommaParziale[i]==true || submittedCrediti && arrayErroriCrediti.sommainputAnnoPrec ==true  -->
            <div *ngIf=" patrimonialeSubmitted && passivo.controls.inputAnnoPrec.errors" class="invalid-feedback">
              <div *ngIf="passivo.controls.inputAnnoPrec.errors.required">Campo obbligatorio
              </div>
            </div>
          </div>

        </div>
      </ng-container>

    </mat-tab>
    <mat-tab label="Conto Economico" formGroupName="contoEconomico" [labelClass]="{'label-error':erroriCE}">
      <div class="row margin-top-20">
        <div class="row">
          <div class="col-12 labelError margin-top-10" *ngIf="!contoEconomicoCheck && contoSubmitted">Il valore
            di "{{contoEcon[23].descrizione}}" si discosta di più di 100€ dalla somma dei campi di:
            "{{contoEcon[21].descrizione}}" e "{{contoEcon[22].descrizione}}".</div>
          <div class="col-12 labelError margin-top-10" *ngIf="!utilePerditaCheck && contoSubmitted">Il campo
            "{{patrimonialePassivo[1].descrizione}}" dello Stato patrimoniale e il campo "{{contoEcon[23].descrizione}}"
            del
            Conto
            economico devono essere guali.</div>

          <div class="col-12 labelError margin-top-10" *ngIf="!imposteCheck && contoSubmitted">
            Il valore di "{{contoEcon[21].descrizione}}" si discosta di più di 100€ dalla somma dei campi di:
            "{{contoEcon[5].descrizione}}", "{{contoEcon[17].descrizione}}",
            "{{contoEcon[19].descrizione}}"
            e "{{contoEcon[20].descrizione}}".
          </div>
          <div class="col-12 labelError margin-top-10" *ngIf="!totaleCostiCheck && contoSubmitted">
            Il valore di "{{contoEcon[17].descrizione}}" si discosta di più di 100€ dalla somma dei campi di:
            "{{contoEcon[6].descrizione}}", "{{contoEcon[7].descrizione}}", "{{contoEcon[8].descrizione}}",
            "{{contoEcon[12].descrizione}}",
            "{{contoEcon[13].descrizione}}", "{{contoEcon[14].descrizione}}", "{{contoEcon[15].descrizione}}" e
            "{{contoEcon[16].descrizione}}".
          </div>
        </div>
        <div class="col-5 d-flex justify-content-end"><label class="text-right">Esercizio :</label></div>
        <div class="col-3 col-xl-2 d-flex justify-content-center"> <label
            class="text-center"><strong><i>{{annoBilancio}}</i></strong></label></div>
        <div class="col-3 col-xl-2 d-flex justify-content-center"> <label
            class="text-center"><strong><i>{{annoBilancioPrec}}</i></strong></label></div>
      </div>
      <ng-container formArrayName="contoEconomicoarray" class="margin-top-20">
        <div class="row margin-top-20" *ngFor=" let conto of contoEconomicoFormArray;let i=index" [formGroupName]="i">
          <div class="col-5">
            <h3 [ngStyle]="{'font-weight': i==17 || i==21 ||  i == 23 ? 'bold' : 'normal'}">
              {{contoEcon[i].descrizione}}&nbsp;&nbsp;<i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="false"
                [autoClose]="'inside'" placement="top" container="body" ngbTooltip={{listaTips[i]?.descrizione}}></i>
            </h3>
          </div>
          <div class="col-3 col-xl-2 d-flex justify-content-center">
            <input type="text" class="form-control" formControlName="inputAnnoScorso" currencyMask
              (keyup)="totaliContoEconomico()"
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }"
              [ngClass]="{ 'is-invalid':contoSubmitted && conto.controls.inputAnnoScorso.errors }" />
            <!-- ||this.tipoCreditoList[i]!=7 && submittedCrediti && arrayErroriCrediti.sommaParziale[i]==true || submittedCrediti && arrayErroriCrediti.sommainputAnnoScorso ==true  -->
            <div *ngIf=" contoSubmitted && conto.controls.inputAnnoScorso.errors" class="invalid-feedback">
              <div *ngIf="conto.controls.inputAnnoScorso.errors.required">Campo obbligatorio
              </div>
            </div>
          </div>
          <div class="col-3 col-xl-2 d-flex justify-content-center">
            <input type="text" class="form-control" formControlName="inputAnnoPrec" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: true }"
              [ngClass]="{ 'is-invalid':contoSubmitted && conto.controls.inputAnnoPrec.errors }" />
            <!-- ||this.tipoCreditoList[i]!=7 && submittedCrediti && arrayErroriCrediti.sommaParziale[i]==true || submittedCrediti && arrayErroriCrediti.sommainputAnnoPrec ==true  -->
            <div *ngIf=" contoSubmitted && conto.controls.inputAnnoPrec.errors" class="invalid-feedback">
              <div *ngIf="conto.controls.inputAnnoPrec.errors.required">Campo obbligatorio
              </div>
            </div>
          </div>

        </div>
      </ng-container>
    </mat-tab>

  </mat-tab-group>
</div>