<div class="col-12">
    <div class="row justify-content-center d-flex">
        <div class="col-4" style="color: var(--main-white);padding-top: 7px;">
            <p>Conto Economico</p>
        </div>
    </div>
</div>
<div class="col-11">
    <div class="row justify-content-end d-flex">
        <div class="col-6 container-smile">
            <p>intestation-plash-section works!</p>
        </div>
    </div>
</div>

