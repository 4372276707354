<div class="container-fluid no padding sticky-bp" id="configurazione-ambiente" [formGroup]="configurazioneAmbienteForm">
      <h2 style="margin-bottom:0;">Smart Business Plan</h2>
      <div class=" row d-flex justify-content-between">
        <div class="col-8" *ngIf="listaRichiedenti.length > 0">
          <div class="row margin-bottom-10">
            <div class="col-3">
              <Label>Scegliere l'azienda</Label>
              <select type="text" formControlName="nomeAzienda" class="form-control form-select"
                (change)="onChangeNomeAzienda()" [ngClass]="{ 'is-invalid': submitted && f.nomeAzienda.errors }">
                <option [ngValue]="nomeAzienda" *ngFor="let nomeAzienda of listaNomiAzienda">{{nomeAzienda}}
                </option>
              </select>
              <div *ngIf="submitted && f.nomeAzienda.errors" class="invalid-feedback">
                <div *ngIf="f.nomeAzienda.errors.required">Campo obbligatorio</div>
              </div>
            </div>
            <div ngClass="col-1" *ngIf="currentUser.cliente.idTipoCliente==2"
              class="col-2 d-flex align-items-end justify-content-start">
              <button *ngIf="f.nomeAzienda.disabled" class=" btn btn-secondary" style="padding: 3px 12px" type="button"
                (click)="unlock()" placement="top" ngbTooltip="Sblocca per cambiare il nome azienda">Sblocca
              </button>
              <!-- <button *ngIf="f.nomeAzienda.disabled" class=" btn fa fa-lock fa-lg pointer" aria-hidden="true"
                              type="button" (click)="unlock()" placement="top"
                              ngbTooltip="Sblocca per cambiare il nome azienda">
                          </button>
                          <button *ngIf="f.nomeAzienda.enabled" class=" btn fa fa-unlock fa-lg pointer" aria-hidden="true"
                              type="button">
                          </button> -->
            </div>
            <div ngClass="col-3" 
            class="col-1 d-flex align-items-end justify-content-start">
            <button type="button" class="btn btn-dark" (click)="censimentoSmart();">
                <i class="fa fa-plus fa-fw" style="font-size: 0.8rem;" aria-hidden="true"></i>Nuova
            </button>
          </div>
          </div>
        </div>
      </div>
    <!-- selectedIndex proprietà delle mat tab -->
    <div class=" row d-flex justify-content-between">
      <div class="col-12">
    <div class="card">
      <div class="card-header font-big text-center">
        <div class="card-title">Anagrafica azienda</div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-3 ">
              <label for="partitaIva">Partita IVA</label>
              <div>{{richiedenteTemp != null ? richiedenteTemp.partitaIva : ''}}</div>
          </div>
          <div class="col-3 ">
              <label for="nomeAzienda">Nome azienda</label>
              <div>{{richiedenteTemp != null ? richiedenteTemp.ragioneSociale : ''}}</div>
          </div>

          <div class="col-2 ">
              <label for="tipoAzienda">Forma Giuridica</label>
<!--{{tipoAziendaTemp}}-->
          </div>
          <div class="col-2 ">
              <label for="annoCostituzione">Anno di costituzione*</label>
              <div>{{richiedenteTemp != null ? richiedenteTemp.annoCostituzione : ''}}</div>

          </div>
          <div class="col-2 ">
              <label for="numAddetti">Numero dipendenti</label>
<!--{{classeAddettiTemp}}-->
          </div>
      </div>
      </div>
    </div>
  </div>
</div>
<div class=" row d-flex justify-content-between">
  <div class="col-12">

    <app-dossier-amministrativi [idRichiedenteTemp]="richiedenteTemp !=null ? richiedenteTemp.id : null"
    [tabIndex]="selectedIndex">
  </app-dossier-amministrativi>
  </div>
</div>
</div>
