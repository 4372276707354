import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, FormArray, Validators } from '@angular/forms';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { HttpClient } from '@angular/common/http';
import { BeanStatico } from '../condivisi/beanStatico'
import { Provincia } from '../condivisi/provincia';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, merge } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../_services/token-storage.service';
import { CrService } from '../_services/cr.services';
import { BilancioIntestatarioService } from '../_services/bilancio_intestatario.service';

@Component({
  selector: 'qs-anagrafica-questionario-old',

  templateUrl: './anagrafica-questionario-old.component.html',
  styleUrls: ['./anagrafica-questionario-old.component.css']
})
export class AnagraficaQuestionarioOldComponent implements OnInit {
  @Input('formGroup') anagraficaForm!: UntypedFormGroup;
  @Output() registraBilancio = new EventEmitter();
  @Output() registraCr = new EventEmitter();
  @ViewChild('debitoBT') debitoBT!: ElementRef;
  @ViewChild('debitoMT') debitoMT!: ElementRef;
  @ViewChild('debitoLT') debitoLT!: ElementRef;
  @ViewChild('ricaviExAnte') ricaviExAnte!: ElementRef;
  @ViewChild('costiExAnte') costiExAnte!: ElementRef;
  @ViewChild('rigaVE50') rigaVE50!: ElementRef;
  @ViewChild('rigaVF14') rigaVF14!: ElementRef;
  @ViewChild('rigaVF23') rigaVF23!: ElementRef;
  @ViewChild('rigaVF27') rigaVF27!: ElementRef;

  submitted = false;
  listaTitoliStudio: BeanStatico[] = [];
  listaTipiRichiedente: BeanStatico[] = [];
  listaProvince: Provincia[] = [];
  listaCategorie: BeanStatico[] = [];
  listaProdottiCategoria: BeanStatico[] = [];
  listaVarieta: BeanStatico[] = [];
  listaCanaliDistribuzione: BeanStatico[] = [];
  listaTipiAzienda: BeanStatico[] = [];
  listaBilanci: any[] = [];
  listaCr: any[] = [];
  boolVarieta = false;
  tempIdVarieta = 0;


  constructor(private datiQuestionarioService: DatiQuestionarioService, private httpClient: HttpClient,
    private decimalPipe: DecimalPipe, private router: Router, private tokenService: TokenStorageService,
    private crService: CrService, private bilancioIntService: BilancioIntestatarioService,) {

  }

  ngOnInit() {

    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTitoliStudio').subscribe(data => {
      this.listaTitoliStudio = data;
      if (this.f.titoloStudio.value != '') {
        this.listaTitoliStudio.forEach(element => {
          if (element.id == this.f.titoloStudio.value.id) this.f.titoloStudio.setValue(element);
        });
      }
    });

    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiRichiedente').subscribe(data => {
      this.listaTipiRichiedente = data;
      if (this.f.tipoRichiedente.value != '') {
        this.listaTipiRichiedente.forEach(element => {
          if (element.id == this.f.tipoRichiedente.value.id) this.f.tipoRichiedente.setValue(element);
        });
      }
    });

    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda').subscribe(data => {
      this.listaTipiAzienda = data;
      if (this.f.tipoAzienda.value != '') {
        this.listaTipiAzienda.forEach(element => {
          if (element.id == this.f.tipoAzienda.value.id) this.f.tipoAzienda.setValue(element);
        });
      }
    });

    this.datiQuestionarioService.currentSubmitted.subscribe(submitted => this.submitted = submitted)

    this.crService.currentParamCr.subscribe(cr => {
      if (cr && cr.id_intestatario) {
        this.anagraficaForm.controls.centraleRischi.setValue({ codiceIntestatario: cr.id_intestatario, data: cr.data_inserimento });
      }
    });

    this.bilancioIntService.currentParamBilancio.subscribe(bilancio => {
      if (bilancio && bilancio.id_intestatario) {
        this.anagraficaForm.controls.bilancio.setValue({ idIntestatario: bilancio.id_intestatario, data: bilancio.data_inserimento });
      }
    });

    this.onChangeCr();
    this.onChangeBilancio();

  }
  /*
    ngAfterViewInit() {

      if (this.anagraficaForm.valid && this.f.tipoRichiedente.value != '') {
        if (typeof this.debitoBT !== 'undefined' && typeof this.debitoMT !== 'undefined' && typeof this.debitoLT !== 'undefined') {
          this.f.importoDebitoBT.value !== '' ? this.debitoBT.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.importoDebitoBT.value), '1.0-0', 'it') + ' €' : '';
          this.f.importoDebitoMT.value !== '' ? this.debitoMT.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.importoDebitoMT.value), '1.0-0', 'it') + ' €' : '';
          this.f.importoDebitoLT.value !== '' ? this.debitoLT.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.importoDebitoLT.value), '1.0-0', 'it') + ' €' : '';
        }

        if (typeof this.ricaviExAnte !== 'undefined' && typeof this.costiExAnte !== 'undefined' && typeof this.debitoLT !== 'undefined') {
          this.f.ricaviAnnoPrecedente.value !== '' ? this.ricaviExAnte.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.ricaviAnnoPrecedente.value), '1.0-0', 'it') + ' €' : '';
          this.f.costiAnnoPrecedente.value !== '' ? this.costiExAnte.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.costiAnnoPrecedente.value), '1.0-0', 'it') + ' €' : '';
        }

        if (typeof this.rigaVE50 !== 'undefined' && typeof this.rigaVF14 !== 'undefined' && typeof this.rigaVF23 !== 'undefined' && typeof this.rigaVF27 !== 'undefined') {
          this.f.rigaVE50.value !== '' ? this.rigaVE50.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.rigaVE50.value), '1.0-0', 'it') + ' €' : '';
          this.f.rigaVF14.value !== '' ? this.rigaVF14.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.rigaVF14.value), '1.0-0', 'it') + ' €' : '';
          this.f.rigaVF23.value !== '' ? this.rigaVF23.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.rigaVF23.value), '1.0-0', 'it') + ' €' : '';
          this.f.rigaVF27.value !== '' ? this.rigaVF27.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.rigaVF27.value), '1.0-0', 'it') + ' €' : '';

        }


      }

    }

  */
  // convenience getter for easy access to form fields
  get f() { return this.anagraficaForm.controls; }


  onChangeTipoRichiedente() {
    if (this.f.tipoRichiedente.value.descrizione != "Start up") {
      //this.f.importoDebitoBT.setValidators(Validators.required);
      //this.f.importoDebitoMT.setValidators(Validators.required);
      //this.f.importoDebitoLT.setValidators(Validators.required);
      //this.f.dichiarazioneIVA.setValidators(Validators.required);
      this.f.tipoAzienda.setValidators(Validators.required);
    }
    else {
      this.f.tipoAzienda.setValue('');
      //this.f.importoDebitoBT.setValue('');
      //this.f.importoDebitoMT.setValue('');
      //this.f.importoDebitoLT.setValue('');
      this.f.dichiarazioneIVA.setValue('');
      this.f.rigaVE50.setValue('');
      this.f.rigaVF14.setValue('');
      this.f.rigaVF23.setValue('');
      this.f.rigaVF27.setValue('');
      this.f.ricaviAnnoPrecedente.setValue('');
      this.f.costiAnnoPrecedente.setValue('');
      this.f.presenzaBilancio.setValue('');
      this.f.presenzaCr.setValue('');
      this.f.bilancio.setValue('');
      //this.f.importoDebitoBT.clearValidators();
      //this.f.importoDebitoMT.clearValidators();
      //this.f.importoDebitoLT.clearValidators();
      this.f.dichiarazioneIVA.clearValidators();
      this.f.rigaVE50.clearValidators();
      this.f.rigaVF14.clearValidators();
      this.f.rigaVF23.clearValidators();
      this.f.rigaVF27.clearValidators();
      this.f.ricaviAnnoPrecedente.clearValidators();
      this.f.costiAnnoPrecedente.clearValidators();
      this.f.tipoAzienda.clearValidators();
    }
    this.f.tipoAzienda.updateValueAndValidity();
    //this.f.importoDebitoBT.updateValueAndValidity();
    //this.f.importoDebitoMT.updateValueAndValidity();
    //this.f.importoDebitoLT.updateValueAndValidity();
    this.f.dichiarazioneIVA.updateValueAndValidity();
    this.f.rigaVE50.updateValueAndValidity();
    this.f.rigaVF14.updateValueAndValidity();
    this.f.rigaVF23.updateValueAndValidity();
    this.f.rigaVF27.updateValueAndValidity();
    this.f.ricaviAnnoPrecedente.updateValueAndValidity();
    this.f.costiAnnoPrecedente.updateValueAndValidity();
  }


  onChangeDichiarazioneIVA() {
    if (this.f.dichiarazioneIVA.value == true) {
      this.f.rigaVE50.setValidators(Validators.required);
      this.f.rigaVF14.setValidators(Validators.required);
      this.f.rigaVF23.setValidators(Validators.required);
      this.f.rigaVF27.setValidators(Validators.required);
      this.f.ricaviAnnoPrecedente.clearValidators();
      this.f.costiAnnoPrecedente.clearValidators();
      this.f.ricaviAnnoPrecedente.setValue('');
      this.f.costiAnnoPrecedente.setValue('');
    }
    else {
      this.f.rigaVE50.clearValidators();
      this.f.rigaVF14.clearValidators();
      this.f.rigaVF23.clearValidators();
      this.f.rigaVF27.clearValidators();
      this.f.rigaVE50.setValue('');
      this.f.rigaVF14.setValue('');
      this.f.rigaVF23.setValue('');
      this.f.rigaVF27.setValue('');
      this.f.ricaviAnnoPrecedente.setValidators(Validators.required);
      this.f.costiAnnoPrecedente.setValidators(Validators.required);
    }
    this.f.rigaVE50.updateValueAndValidity();
    this.f.rigaVF14.updateValueAndValidity();
    this.f.rigaVF23.updateValueAndValidity();
    this.f.rigaVF27.updateValueAndValidity();
    this.f.ricaviAnnoPrecedente.updateValueAndValidity();
    this.f.costiAnnoPrecedente.updateValueAndValidity();

  }

  onChangeNomeAzienda() {
    this.datiQuestionarioService.changeNomeAzienda(this.f.nomeAzienda.value)
  }

  onChangeBilancio() {
    if (this.f.presenzaBilancio.value) {
      this.f.dichiarazioneIVA.setValue("");
      this.f.rigaVE50.clearValidators();
      this.f.rigaVF14.clearValidators();
      this.f.rigaVF23.clearValidators();
      this.f.rigaVF27.clearValidators();
      this.f.rigaVE50.setValue('');
      this.f.rigaVF14.setValue('');
      this.f.rigaVF23.setValue('');
      this.f.rigaVF27.setValue('');
      this.f.ricaviAnnoPrecedente.clearValidators();
      this.f.costiAnnoPrecedente.clearValidators();
      this.f.ricaviAnnoPrecedente.setValue('');
      this.f.costiAnnoPrecedente.setValue('');
      this.f.rigaVE50.updateValueAndValidity();
      this.f.rigaVF14.updateValueAndValidity();
      this.f.rigaVF23.updateValueAndValidity();
      this.f.rigaVF27.updateValueAndValidity();
      this.f.ricaviAnnoPrecedente.updateValueAndValidity();
      this.f.costiAnnoPrecedente.updateValueAndValidity();


      //      this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getBilanciRichiedente',this.tokenService.getUser().username).subscribe(data => {
      this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAnagraficaRichiedenteByidCliente', { idCliente: 2 }).subscribe(data => {

        this.listaBilanci = [];
        data.forEach(element => {
          let elementoLista = {
            idIntestatario: element.id,
            // data: element.bilancio.dataInserimento,
            descrizione: element.ragioneSociale
            // + " - " + element.bilancio.dataUltimaModifica
          };

          this.listaBilanci.push(elementoLista)
          if (this.f.bilancio.value != '' && elementoLista.idIntestatario == this.f.bilancio.value.idIntestatario)
            this.f.bilancio.setValue(elementoLista);
        });
      });

    }
  }

  onChangeCr() {
    if (this.f.presenzaCr.value) {
      this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/intermediari/getListaCr', this.tokenService.getUser().username).subscribe(data => {
        this.listaCr = [];
        data.forEach(element => {
          let elementoLista = { id: element.id, data: element.data_registrazione, descrizione: element.cognome ? element.cognome + " - " + element.data_registrazione : element.nome + " - " + element.data_registrazione };
          this.listaCr.push(elementoLista)
          if (this.f.centraleRischi.value != '' && element.data_registrazione == this.f.centraleRischi.value.data && (element.id == this.f.centraleRischi.value.id || element.codice == this.f.centraleRischi.value.codiceIntestatario)) this.f.centraleRischi.setValue(elementoLista);
        });
      });
    } else {
      this.f.centraleRischi.setValue('');
    }
  }
  goToBilancio() {
    this.registraBilancio.next(null);
  }

  goToCr() {
    this.registraCr.next(null);
  }


}
