/* Componente che viene passato all'apertura della finestra modale */
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { BusinessPlanElement } from 'src/app/lista-bplan-nuova/lista-bplan-nuova.component';
import { AuthService } from 'src/app/_services/auth.service';
import { ModalActionService } from 'src/app/_services/modal-action.service';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-modalcontent',
  templateUrl: './modalcontent.component.html',
  styleUrls: ['./modalcontent.component.css'],


})
export class ModalcontentComponent implements OnInit {
  @Input() public modalPar: any;/* valori passati in input qunado richiamo la modale */
  modalData: any; /* struttura text della modale */
  StyleClass: any; /* template modale */

  nomeSalvataggio: string;
  azienda: UntypedFormControl;

  idRichiedente: number
  //salvataggioBp: FormControl;
  salvataggioBp: string = '';
  listaAziende: any[] = []
  listaBp: BusinessPlanElement[] = [];
  data = new Date();
  currentData: string;
  buttonDisabled: boolean = true;
  invalidRichiedente: boolean = false;
  risultatiVolatile: boolean = false
  checkNameCorrect: boolean = true
  nomeSalvataggioOld: string = '';
  idleCd: number
  idleCdObs: Subscription
  /* parametro da usare per aggiungere componenti alla modale */
  constructor(
    public modalRef: NgbActiveModal,
    private authService: AuthService,
    private modalService: ModalActionService,
    public httpService: HttpClient, private formBuilder: FormBuilder) /* importo il servizio da cui ignetterò tutte le scritte della modale*/ {
    this.azienda = new UntypedFormControl('', [Validators.required]);


    //this.salvataggioBp = new FormControl('', [Validators.required]);


  }

  ngOnInit(): void {
    this.idRichiedente = this.modalPar.idAzienda
    this.nomeSalvataggio = this.modalPar.description; /* impt nomesalvataggio della modale nuovosalvataggio in questionario */
    this.nomeSalvataggioOld = this.nomeSalvataggio;
    this.listaAziende = this.modalPar.listaAziende;
    this.listaBp = this.modalPar.listaBp;

    this.risultatiVolatile = this.modalPar.isRisultatiVolatile
    this.StyleClass = this.modalService.ModalStyle(this.modalPar);
    this.idleCdObs = this.authService.currentIdleCd.subscribe(cd => {
      this.idleCd = 60 - cd;
    })


  }
  ngOnDestroy() {
    if (this.idleCdObs != null) this.idleCdObs.unsubscribe()
  }
  /* funzione su click di modal-action-button */
  actionFunction() {
    if (this.modalPar.name == "salva") {
      /* ritorna il nome del salvataggio oltre aalla promise = 1 */
      let emitter = { "promise": 1, "nomesalvataggio": this.nomeSalvataggio, "conferma": false };
      this.modalRef.close(emitter);
    }
    else {
      this.modalRef.close(1);
    }
  }
  /* funzione salva bp */
  salvaBp(action: string) {
    if (this.modalPar.name.includes("salvaBp")) {
      if (action == "definitivo") {
        /* sezione conferma del bp per renderlo definitivo */
        let emitter = { "promise": 1, "nomesalvataggio": this.nomeSalvataggio, "conferma": true };
        this.modalRef.close(emitter);
      } else {
        /* ritorna il nome del salvataggio oltre aalla promise = 1 */
        let emitter = { "promise": 1, "nomesalvataggio": this.nomeSalvataggio, "conferma": false };
        this.modalRef.close(emitter);
      }
    }
    else if (this.modalPar.name == "salvaBusinessPlanNuovo") {
      let nuovoBp = {
        idAziendaRichiedente: this.azienda.value.id,
        riferimentoBp: this.salvataggioBp,
        nomeAzienda: this.azienda.value.descrizione,
        dataCreazione: this.data//(this.data.getFullYear() + "-" + ((this.data.getMonth() + 1) < 10 ? ('0' + (this.data.getMonth() + 1)) : (this.data.getMonth() + 1)) + "-" + (this.data.getDate())).toString(),
      }
      /* bisogna fare il check se esistono gia bp con stesso nome azienda e data */
      let emitter = { "promise": 1, "riferimento": this.salvataggioBp, "nuovoBp": nuovoBp };
      this.modalRef.close(emitter);
    }
  }

  /* display del button azione */
  displayButton(): boolean {
    if (this.modalPar.name.includes('messaggio')) {
      return false;
    } else if (this.modalPar.name == "errorecheckdata") {
      return false;
    } else if (this.modalPar.name == "errore") {
      return false;
    } else if (this.modalPar.name == "salvaBusinessPlanNuovo") {
      return false
    } else if (this.modalPar.name.includes("salvaBp")) {
      return false
    } else if (this.modalPar.name == "assenzaBilancio") {
      return false
    } else { return true; }
  }

  onchangeAzienda() {
    this.azienda.updateValueAndValidity;
    /* bisogna fare il check se esistono gia bp con stesso nome azienda e data */
    this.currentData = ((this.data.getDate()) + "/" + ((this.data.getMonth() + 1) < 10 ? ('0' + (this.data.getMonth() + 1)) : (this.data.getMonth() + 1)) + "/" + this.data.getFullYear()).toString()
    this.invalidRichiedente = false;
    this.buttonDisabled = false;
    if (this.listaBp && this.listaBp.length > 0) {
      this.listaBp.forEach(bp => {
        if (bp.idAziendaRichiedente == this.azienda.value.id && this.currentData == bp.dataCreazione && bp.idStato == 1) {
          this.invalidRichiedente = true;
          this.buttonDisabled = true;

        }/*  else {
          this.salvataggioBp = this.azienda.value.descrizione + "-" + this.currentData;
        } */
      })
    }
    this.salvataggioBp = this.azienda.value.descrizione + "-" + this.currentData // setValue(this.azienda.value.descrizione + "-" + this.currentData)
    //this.salvataggioBp.updateValueAndValidity; nnomeSalvataggio superfluo

  }
  /* funzione sul click per il sava bo */
  checknameBp(action: string) {
    let idAzienda: number;
    let nomeBp: string
    let boolNomeOld = false;
    if (this.modalPar.name == 'salvaBusinessPlanNuovo') {
      idAzienda = this.azienda.value.id
      nomeBp = this.salvataggioBp
    } else if (this.modalPar.name.includes("salvaBp")) {
      idAzienda = this.idRichiedente
      nomeBp = this.nomeSalvataggio
      boolNomeOld = this.nomeSalvataggio == this.nomeSalvataggioOld;
    }
    this.httpService.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneBusinessPlan/checkBpName', { idRichiedente: idAzienda, nomeBp: nomeBp }).subscribe(response => {
      this.checkNameCorrect = boolNomeOld || !response;
      if (this.checkNameCorrect) {
        if (action == 'definitivo') {
          this.salvaBp('definitivo')
        } else {
          this.salvaBp('')
        }
      }
    });
  }


}