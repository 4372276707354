<!--
/* -------------------------------------------*/
 * COMPONENTE di AUTENTICAZIONE
 * Modulo di convalida completo di dati utente
 * ------------------------------------------- */
  -->
<div class="col-sm-12">
  <div class="card card-container">
    <img id="profile-img" src="./assets/img/avatar_2x.png" class="profile-img-card" />

    <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

      <div class="row form-group mt-3">
        <div class="col-sm-4">
        <label for="nome">Nome :</label>
        </div>
        <div class="col-sm-6">
        <input type="text" class="form-control"  name="nome" [(ngModel)]="form.nome"
          required minlength="4" maxlength="20" #nome="ngModel"/>
        <div class="alert-danger" *ngIf="nome.errors && f.submitted">
          <div *ngIf="nome.errors.required">Nome è obbligatorio</div>
          <div *ngIf="nome.errors.minlength">Il nome deve contenere almeno 4 caratteri</div>
          <div *ngIf="nome.errors.maxlength">Il nome deve contenere al massimo 20 caratteri</div>
        </div>
      </div>
      </div>

      <div class="row form-group mt-2">
        <div class="col-sm-4">
        <label for="cognome">Cognome :</label>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control"  name="cognome" [(ngModel)]="form.cognome"
          required minlength="6" maxlength="20" #cognome="ngModel"/>
        <div class="alert-danger" *ngIf="cognome.errors && f.submitted">
          <div *ngIf="cognome.errors.required">Cognome è obbligatorio</div>
          <div *ngIf="cognome.errors.minlength">Il cognome deve contenere almeno 6 caratteri</div>
          <div *ngIf="cognome.errors.maxlength">Il cognome deve contenere al massimo 20 caratteri</div>
        </div>
      </div>
    </div>
      <div class="row form-group mt-2">
        <div class="col-sm-4">
        <label for="username">Username :</label>
      </div>
      <div class="col-sm-6">
        <input type="text" class="form-control"  name="username" [(ngModel)]="form.username"
          required minlength="6" maxlength="20" #username="ngModel"/>
        <div class="alert-danger" *ngIf="username.errors && f.submitted">
          <div *ngIf="username.errors.required">Username è obbligatorio</div>
          <div *ngIf="username.errors.minlength">Username deve contenere almeno 6 caratteri</div>
          <div *ngIf="username.errors.maxlength">Username deve contenere al massimo 20 caratteri</div>
        </div>
      </div>
    </div>

      <div class="row form-group mt-2">
        <div class="col-sm-4">
        <label for="password">Password :</label>
      </div>
      <div class="col-sm-6">
        <input type="password" class="form-control" name="password" [(ngModel)]="form.password"
          required minlength="6" #password="ngModel"/>
        <div class="alert-danger" *ngIf="password.errors && f.submitted">
          <div *ngIf="password.errors.required">Password è obbligatoria</div>
          <div *ngIf="password.errors.minlength">Password deve contenere almeno 6 caratteri</div>
        </div>
      </div>
    </div>

      <div class="row form-group mt-2">
        <div class="col-sm-4">
        <label for="email">Email :</label>
      </div>
      <div class="col-sm-6">
        <input type="email" class="form-control" name="email" [(ngModel)]="form.email"
          required email #email="ngModel" />
        <div class="alert-danger" *ngIf="email.errors && f.submitted">
          <div *ngIf="email.errors.required">Email è obbligatoria</div>
          <div *ngIf="email.errors.email">Email deve essere un indirizzo email valido</div>
        </div>
      </div>
    </div>

      <div class="row form-group mt-2">
        <div class="col-sm-4">
        <label for="attivo">Attivo :</label>
      </div>
      <div class="col-sm-6">
        <div class="col-sm-1">
        <input type="checkbox" class="checkbox-lg" name="attivo" [(ngModel)]="form.attivo"
          required #attivo="ngModel" />
        </div>
        <div class="alert-danger" *ngIf="attivo.errors && f.submitted">
            <div *ngIf="attivo.errors.required">Attivo è obbligatorio</div>
        </div>
      </div>
    </div>

      <div class="row form-group mt-1">
        <div class="col-sm-4">
        <label for="level_access">Level Access :</label>
      </div>
      <div class="col-sm-2">
        <select class="btn dropdown-toggle border" name="level_access" [(ngModel)]="form.level_access"
          required #level_access="ngModel">
          <option *ngFor=" let n of [0,1,2,3,4,5,6,7,8,9]" [ngValue]="n">{{n}}</option>
        </select>
      </div>
    </div>

      <div class="row form-group mt-2">
        <div class="col-sm-12">
        <button class="btn btn-primary btn-block">Iscriviti</button>
        </div>
      </div>

      <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
        Accesso fallito!<br />{{ errorMessage }}
      </div>
    </form>

    <div class="alert alert-success" *ngIf="isSuccessful">
      La tua registrazione è andata a buon fine!
    </div>
  </div>
</div>
