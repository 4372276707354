import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { BeanStatico } from '../condivisi/beanStatico';
import { Provincia } from '../condivisi/provincia';
import { Richiedente } from '../interface/richiedente';
import { LoadingService } from '../loading.service';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { ClienteService } from '../_services/cliente.service';
import { RichiedenteService } from '../_services/richiedente.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { Location } from '@angular/common';
import { ToolTipService } from '../_services/tool-tip.service';
import { ToolTip } from '../interface/toolTip';

/* manca il salvataggio e l'import dei dei dati del richiedente singolo */
@Component({
  selector: 'app-gestione-anagrafica',
  templateUrl: './gestione-anagrafica.component.html',
  styleUrls: ['./gestione-anagrafica.component.css']
})
export class GestioneAnagraficaComponent implements OnInit {
  anagraficaForm: UntypedFormGroup;
  initialValues: any;
  date: Date = new Date();
  listaProvince: Provincia[] = [];
  listaComuni: BeanStatico[];
  listaTipiAzienda: BeanStatico[] = [];

  listaAtecoPrimarioSezione: BeanStatico[] = [];
  listaAtecoPrimarioDivisione: BeanStatico[] = [];
  listaAtecoPrimarioClasse: BeanStatico[] = [];
  listaAtecoPrimarioSottocategoria: BeanStatico[] = [];

  listaAnni: number[];
  currentAnno = new Date().getFullYear()

  atecoPrimarioTemp: string;
  /* serve per passare il dato al component ateco-secondari */
  atecoSecondariTemp: string[] = [];
  /* serve a passare il dato per la query al component ateco-secondari */
  atecoSecondarioTemp: string;
  /* servono solo per salvare il dato */
  atecoSecondarioTemp1: string = null;
  atecoSecondarioTemp2: string = null;

  addAtecoDisabled = false;
  submitDisabled = false;

  richiedenteTemp: Richiedente[] = null;
  tempIdComune: number = -1;
  tempIdSezione: number = -1;
  tempIdDivisione: number = -1;
  tempIdClasse: number = -1;
  tempIdSottocategoria: number = -1;
  listaTips: ToolTip[] = [];
  submitted = false;
  faInfo = faInfo;
  constructor(
    private httpClient: HttpClient,
    private richiedenteService: RichiedenteService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private location: Location,
    private toolTipService: ToolTipService,
  ) {
    this.anagraficaForm = this.formBuilder.group({
      nomeAzienda: [{ value: '', disabled: true }],
      partitaIva: [{ value: '', disabled: true }],
      tipoAzienda: [{ value: '', disabled: true }],
      sedeLegale: [''],
      provincia: [''],
      comune: [{ value: '', disabled: true }],
      cap: [''],
      emailRichiedente: [''],
      telefonoRichiedente: [''],
      startUp: [false],
      annoCostituzione: [''],
      atecoPrimarioSezione: [''],
      atecoPrimarioDivisione: [{ value: '', disabled: true }],
      atecoPrimarioClasse: [{ value: '', disabled: true }],
      atecoPrimarioSottocategoria: [{ value: '', disabled: true }],
      atecoSecondariArray: this.formBuilder.array([
      ]),
    })
    this.initialValues = this.anagraficaForm.value;
  }
  get f() { return this.anagraficaForm.controls; }
  get atecoSecondariArray(): UntypedFormArray { return <UntypedFormArray> this.anagraficaForm.controls.atecoSecondariArray; }
  get atecoForm(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.atecoSecondariArray.controls }

  ngOnInit(): void {
    this.listaAnni = []
    for (let i = this.currentAnno; i >= 1950; i--) {
      this.listaAnni.push(i);
    }
    this.submitted = false;
    this.updateValidators()
    this.toolTipService.getTipsContent('ANA').subscribe(risultato => {
      if (risultato) {
        risultato.forEach(el => {
          this.listaTips.push(el)
        })
        this.listaTips.sort((n1, n2) => n1.numero - n2.numero)
      }
    })
    /* RESTITUISCE UN vettore ma 3in questo caso è solo 1 */
    this.loadRichiedente();

  }
  loadRichiedente() {
    this.richiedenteService.getAnagraficaRichiedenteByidCliente().subscribe((richiedente) => {
      this.richiedenteTemp = null;
      this.richiedenteTemp = richiedente;
      this.tempIdComune = this.richiedenteTemp[0].idComune;

      /* passo la stringa del codice ateco a BE per prendere gli id di classe */
      if (this.richiedenteTemp[0].codiceAtecoPrimario != null) {
        this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/codiciAteco', this.richiedenteTemp[0].codiceAtecoPrimario).subscribe(response => {
          this.tempIdSezione = response.idSezione;
          this.tempIdDivisione = response.idDivisione;
          this.tempIdClasse = response.idClasse;
          this.tempIdSottocategoria = response.idSottocategoria;

          /* devo richiamare qui le select altrimenti gli idAteco non arrivano in tempo */
          this.onLoadSelect();
        })
      }
      /* costruzione del form ateco secondari se l'azienda ce  l'ha. un form per ogni ATECO secondario (max 2)*/
      this.atecoSecondariArray.clear();
      this.atecoSecondariTemp = [];
      /* carico gli ateco secondairi su un vettore */
      if (this.richiedenteTemp[0].codiceAtecoSecondario1 != null) {
        this.atecoSecondariTemp.push(this.richiedenteTemp[0].codiceAtecoSecondario1)
      } if (this.richiedenteTemp[0].codiceAtecoSecondario2 != null) {
        this.atecoSecondariTemp.push(this.richiedenteTemp[0].codiceAtecoSecondario2)
      }
      for (let index = 0; index < this.atecoSecondariTemp.length; index++) {
        this.addAteco();
      }

      /* carico i parammetri nel form */
      this.f.partitaIva.setValue(this.richiedenteTemp[0].partitaIva);
      this.f.nomeAzienda.setValue(this.richiedenteTemp[0].ragioneSociale);
      this.f.sedeLegale.setValue(this.richiedenteTemp[0].sedeLegale);
      this.f.cap.setValue(this.richiedenteTemp[0].cap);
      this.f.emailRichiedente.setValue(this.richiedenteTemp[0].email);
      this.f.telefonoRichiedente.setValue(this.richiedenteTemp[0].telefono);
      this.f.telefonoRichiedente.setValue(this.richiedenteTemp[0].isStartUp);
      this.f.annoCostituzione.setValue(this.richiedenteTemp[0].annoCostituzione)

      this.f.sedeLegale.updateValueAndValidity();
      this.f.emailRichiedente.updateValueAndValidity();
      this.f.telefonoRichiedente.updateValueAndValidity();
      this.f.cap.updateValueAndValidity();
      this.f.partitaIva.updateValueAndValidity();
      this.f.nomeAzienda.updateValueAndValidity();
      this.f.comune.updateValueAndValidity();
      this.f.annoCostituzione.updateValueAndValidity()

    })
  }

  updateValidators() {
    this.f.partitaIva.setValidators([Validators.required, Validators.minLength(16), Validators.maxLength(16)]);
    this.f.tipoAzienda.setValidators(Validators.required);
    this.f.nomeAzienda.setValidators(Validators.required);
    this.f.provincia.setValidators(Validators.required);
    this.f.comune.setValidators(Validators.required);
    this.f.sedeLegale.setValidators(Validators.required);
    this.f.cap.setValidators(Validators.required);
    this.f.emailRichiedente.setValidators([Validators.required, Validators.email]);
    this.f.telefonoRichiedente.setValidators(Validators.required);
    this.f.atecoPrimarioSezione.setValidators(Validators.required);
    this.f.atecoPrimarioDivisione.setValidators(Validators.required);
    this.f.atecoPrimarioClasse.setValidators(Validators.required);
    this.f.annoCostituzione.setValidators(Validators.required);
    /* this.f.atecoPrimarioSottocategoria.setValidators(Validators.required); */

    /* validatori di tutti i campi */

    this.f.sedeLegale.updateValueAndValidity();
    this.f.emailRichiedente.updateValueAndValidity();
    this.f.telefonoRichiedente.updateValueAndValidity();
    this.f.cap.updateValueAndValidity();
    this.f.partitaIva.updateValueAndValidity();
    this.f.tipoAzienda.updateValueAndValidity();
    this.f.nomeAzienda.updateValueAndValidity();
    this.f.provincia.updateValueAndValidity();
    this.f.comune.updateValueAndValidity();
    this.f.startUp.updateValueAndValidity()
    this.f.annoCostituzione.updateValueAndValidity()
    this.f.atecoPrimarioSezione.updateValueAndValidity();
    this.f.atecoPrimarioDivisione.updateValueAndValidity();
    this.f.atecoPrimarioClasse.updateValueAndValidity();
    /* this.f.atecoPrimarioSottocategoria.updateValueAndValidity(); */

  }
  onLoadSelect() {
    //tipo azienda, ragione sociale
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda').subscribe(data => {
      this.listaTipiAzienda = data;
      this.listaTipiAzienda.forEach(tipoAzienda => {
        if (tipoAzienda.id == this.richiedenteTemp[0].idFormaGiuridica) {
          this.f.tipoAzienda.setValue(tipoAzienda);

        }
      })
      this.f.tipoAzienda.updateValueAndValidity();
    });

    //province
    this.httpClient.get<Provincia[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProvince').subscribe(data => {
      this.listaProvince = data;
      this.listaProvince.forEach(provincia => {
        if (provincia.id == this.richiedenteTemp[0].idProvincia) {
          this.f.provincia.setValue(provincia);
        }
      })
      this.f.provincia.updateValueAndValidity();
    })
    //comuni
    this.f.provincia.valueChanges.subscribe(selectedValue => {
      this.listaComuni = [];
      if (this.tempIdComune == -1) {
        this.f.comune.setValue('');
      }
      if (selectedValue) {
        this.f.comune.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTerritoriByIdProvincia', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const elementoLista = { id: element.id, descrizione: element.comune };
            //console.log(elementoLista)
            this.listaComuni.push(elementoLista)
            if (element.id == this.tempIdComune) {
              this.f.comune.setValue(elementoLista);
              //this.f.comune.disable();
              this.tempIdComune = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.comune.disable();
      }
      this.f.comune.updateValueAndValidity();
    })
    /* ateco Sezione*/
    this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoSezioni').subscribe(data => {
      this.listaAtecoPrimarioSezione = [];
      data.forEach(element => {
        let atecoSezione = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
        this.listaAtecoPrimarioSezione.push(atecoSezione)
        if (atecoSezione.id == this.tempIdSezione) {
          this.f.atecoPrimarioSezione.setValue(atecoSezione);
        }
      });
    });

    /* ateco divisione */
    this.f.atecoPrimarioSezione.valueChanges.subscribe(selectedValue => {
      this.listaAtecoPrimarioDivisione = [];
      if (this.tempIdDivisione == -1) {
        this.f.atecoPrimarioDivisione.setValue('');
      }
      if (selectedValue) {
        this.f.atecoPrimarioDivisione.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoDivisioniByIdSezione', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const atecoDivisione = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoPrimarioDivisione.push(atecoDivisione)
            if (element.id == this.tempIdDivisione) {
              this.f.atecoPrimarioDivisione.setValue(atecoDivisione);
              //this.f.comune.disable();
              this.tempIdDivisione = -1;
            }
          });
        });
      }
      else {
        this.f.atecoPrimarioDivisione.disable();
      }
      this.f.atecoPrimarioDivisione.updateValueAndValidity();
    })
    /* ateco classe */
    this.f.atecoPrimarioDivisione.valueChanges.subscribe(selectedValue => {
      this.listaAtecoPrimarioClasse = [];
      if (this.tempIdClasse == -1) {
        this.f.atecoPrimarioClasse.setValue('');
      }
      if (selectedValue) {
        this.f.atecoPrimarioClasse.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoClassiByIdDivisione', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const atecoClasse = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoPrimarioClasse.push(atecoClasse)
            if (element.id == this.tempIdClasse) {
              this.f.atecoPrimarioClasse.setValue(atecoClasse);
              //this.f.comune.disable();
              this.tempIdClasse = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.atecoPrimarioClasse.disable();
      }
      this.f.atecoPrimarioClasse.updateValueAndValidity();
    })
    /* ateco sottocategoria */
    this.f.atecoPrimarioClasse.valueChanges.subscribe(selectedValue => {
      this.listaAtecoPrimarioSottocategoria = [];
      if (this.tempIdSottocategoria == -1) {
        this.f.atecoPrimarioSottocategoria.setValue('');
      }
      if (selectedValue) {
        this.f.atecoPrimarioSottocategoria.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAtecoSottocategorieByIdClasse', selectedValue.id).subscribe(data => {
          if (data.length == 0) {
            this.listaAtecoPrimarioSottocategoria.push({ id: 0, descrizione: 'N/A' })
          }
          data.forEach(element => {
            const atecoSottocategoria = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
            //console.log(elementoLista)
            this.listaAtecoPrimarioSottocategoria.push(atecoSottocategoria)
            if (element.id == this.tempIdSottocategoria) {
              this.f.atecoPrimarioSottocategoria.setValue(atecoSottocategoria);
              //this.f.comune.disable();
              this.tempIdSottocategoria = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.atecoPrimarioSottocategoria.disable();
      }
      this.f.atecoPrimarioSottocategoria.updateValueAndValidity();
    })
  }
  eliminaAteco(idAteco: number) {
    this.atecoSecondariArray.removeAt(idAteco);
    if (this.atecoSecondariArray.length == 1) {
      this.atecoSecondarioTemp2 = null;
    } else if (this.atecoSecondariArray.length == 0) {
      this.atecoSecondarioTemp1 = null;
    }

  }
  addAteco() {
    if (this.atecoSecondariArray.length < 2) {
      const sottoForm = this.formBuilder.group({
        atecoSecondarioSezione: ['', Validators.required],
        atecoSecondarioDivisione: [{ value: '', disabled: true }, Validators.required],
        atecoSecondarioClasse: [{ value: '', disabled: true }, Validators.required],
        atecoSecondarioSottocategoria: [{ value: '', disabled: true }],

      })
      sottoForm.controls.atecoSecondarioSezione.updateValueAndValidity();
      sottoForm.controls.atecoSecondarioDivisione.updateValueAndValidity();
      sottoForm.controls.atecoSecondarioSottocategoria.updateValueAndValidity();

      this.atecoSecondariArray.push(sottoForm);
    } else {
      this.addAtecoDisabled = true;
    }

  }
  onReset() {

    this.anagraficaForm.reset(this.initialValues);
    this.submitted = false;
    this.submitDisabled = false;
    this.addAtecoDisabled = false;
    this.atecoSecondarioTemp1 = null;
    this.atecoSecondarioTemp2 = null;
    this.loadRichiedente();
  }

  submit() {
    if (!this.anagraficaForm.valid) {
      this.submitted = true;
    } else if (this.anagraficaForm.valid) {
      this.submitted = false;
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
      }
      const modalRef = this.modalService.open(ModalcontentComponent);
      modalRef.componentInstance.modalPar = {
        name: "salvamodifiche",
        description: "Salvare le modifiche apportate a " + this.richiedenteTemp[0].ragioneSociale + "?",
        actionButtonText: "Salva",
        undoButtonText: "Annulla",
      }
      modalRef.result.then(r => {
        if (r) {
          this.salvaAnagraficaRichiedente();
        }
      })

    }
  }

  salvaAnagraficaRichiedente() {
    /* invio il codice corretto. non è detto che compila tutto */
    /* gli carico la sottocategoria di dafault e vado a scalare. codice ateco primario obbligaorio fino alla classe */

    if (this.f.atecoPrimarioSottocategoria.value != "" && this.f.atecoPrimarioSottocategoria.value.descrizione != "N/A") {
      this.atecoPrimarioTemp = this.f.atecoPrimarioSottocategoria.value.descrizione.substring(0, 8);
      console.log(this.atecoPrimarioTemp)
    } else {
      this.atecoPrimarioTemp = this.f.atecoPrimarioClasse.value.descrizione.substring(0, 5).trim();;
    }
    /* codici ateco secondari */
    if (this.atecoSecondariArray.length > 0) {
      /*Ateco secondario-1 */
      if (this.atecoForm[0].controls.atecoSecondarioSottocategoria.value != "" && this.atecoForm[0].controls.atecoSecondarioSottocategoria.value.descrizione != "N/A") {
        this.atecoSecondarioTemp1 = this.atecoForm[0].controls.atecoSecondarioSottocategoria.value.descrizione.substring(0, 8);
      } else {
        if (this.atecoForm[0].controls.atecoSecondarioClasse.value != "") {
          this.atecoSecondarioTemp1 = this.atecoForm[0].controls.atecoSecondarioClasse.value.descrizione.substring(0, 5).trim();
        } else {
          this.atecoSecondarioTemp1 = this.atecoForm[0].controls.atecoSecondarioDivisione.value.descrizione.substring(0, 2);
        }

      }
      /*Ateco secondario-2 */
      if (this.atecoSecondariArray.length > 1) {
        if (this.atecoForm[1].controls.atecoSecondarioSottocategoria.value != "" && this.atecoForm[1].controls.atecoSecondarioSottocategoria.value.descrizione != "N/A") {
          this.atecoSecondarioTemp2 = this.atecoForm[1].controls.atecoSecondarioSottocategoria.value.descrizione.substring(0, 8);
        } else {
          if (this.atecoForm[1].controls.atecoSecondarioClasse.value != '') {
            this.atecoSecondarioTemp2 = this.atecoForm[1].controls.atecoSecondarioClasse.value.descrizione.substring(0, 5).trim();;
          } else {
            this.atecoSecondarioTemp2 = this.atecoForm[1].controls.atecoSecondarioDivisione.value.descrizione.substring(0, 2);
          }
        }
      }
    }
    let richiedente: Richiedente = {
      id: this.richiedenteTemp[0].id,
      partitaIva: this.f.partitaIva.value,
      ragioneSociale: this.f.nomeAzienda.value,
      codiceFiscale: this.richiedenteTemp[0].codiceFiscale, /* modificare */
      idFormaGiuridica: this.f.tipoAzienda.value.id,
      sedeLegale: this.f.sedeLegale.value,
      idRegione: this.f.provincia.value.idRegione,
      idProvincia: this.f.provincia.value.id,
      idComune: this.f.comune.value.id,
      cap: this.f.cap.value,
      isStartUp: false,//this.f.startUp.value
      annoCostituzione: this.f.annoCostituzione.value,
      idInfoAggiuntive: this.richiedenteTemp[0].idInfoAggiuntive,
      codiceAtecoPrimario: this.atecoPrimarioTemp,
      codiceAtecoSecondario1: this.atecoSecondarioTemp1,
      codiceAtecoSecondario2: this.atecoSecondarioTemp2,
      telefono: this.f.telefonoRichiedente.value,
      email: this.f.emailRichiedente.value,
      dataCreazione: this.richiedenteTemp[0].dataCreazione,
    }
    this.richiedenteService.salvaAnagraficaRichiedenteSingolo(richiedente).subscribe(response => {
      const modalRef = this.modalService.open(ModalcontentComponent);
      modalRef.componentInstance.modalPar = {
        name: "messaggiosalva",
        description: response.response,
        actionButtonText: "",
        undoButtonText: "Ok",
      }

    })
  }



}
