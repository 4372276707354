/* ---------------------------------------------------------------------------------------------
 * COMPONENTE del PROFILO
 * Questo componente ottiene l'utente corrente dallo spazio di archiviazione
 * utilizzando TokenStorageService e mostra le informazioni (nome utente, token, e-mail, ruoli).
 * --------------------------------------------------------------------------------------------- */
import { Component, OnInit } from '@angular/core';
import { ClienteService } from '../_services/cliente.service';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  currentUser: any;
  anagraficaCliente: any = {
    formaGiuridica: {},
    regione: {},
    provincia: {},
    comune: {},
  }

  constructor(private token: TokenStorageService,
    private clienteService: ClienteService) { }

  ngOnInit(): void {
    this.currentUser = this.token.getUser();

    if (this.currentUser.roles.id != 1) {
      this.clienteService.GetAnagraficaClienteById().subscribe(
        response => {
          this.anagraficaCliente = response;
        }
      )
    }
  }

}
