<div class="container-fluid contBench">
  <div [formGroup]="BenchData">

    <div class="row margin-top-20">
      <div class="col-sm-2 margin-bottom-20">
        <select formControlName="AziendaTarget" class="form-control" (change)="setAteco()">
          <option *ngFor="let Target of listaRichiedentiUtente" [ngValue]="Target"> {{Target.descrizione}} </option>
        </select>


      </div>
      <div class="col-sm-2 margin-bottom-20">
        <button class="btn btn-primary" (click)="openDialog()" id="gest"
          [disabled]="BenchData.controls.AziendaTarget.value==''">Gestisci
          presentazione dati</button>


      </div>

      <div class="col-sm-12 margin-bottom-20">
        <div class="row" style="margin-top: 30px;">
          <app-dati-mercato-bench-row *ngIf="present" [listaCodiciAteco]="this.listaAtecoScelti" [idAzienda]="this.TokenTarget.id" [TokenAzienda]="this.TokenTarget">
          </app-dati-mercato-bench-row>
        </div>

        <div class="row row-cols-6 text-center">
          <div class="col">

          </div>
          <div *ngFor="let colo of TokenRigaTarget.datiAziende; index as i ">



            <div class="col" style=" text-align: center;">

              <div style="font-weight: bold;cursor:pointer">{{nomiVariabili[i]}}<mat-icon class="search">search
                </mat-icon>
              </div>

            </div>

          </div>

        </div>




        <div  class="row row-cols-7 text-center" style="margin-top: 5px;">

          <div class="col">
            <div class="nomeRic" style="height: 100%;" >{{labelAziendaTarget}}
            </div>
          </div>

          <div *ngFor="let cell of rigaAziendaTarget; index as i">
            <div class="col" [ngClass]="{ 'dx':i>0, 'sx':i==0 }">
                  <app-bench-cell class="{{'Azienda'+(j+1)}} "   [valoriCella]="cell" [listaVariabili]="listaVariabiliScelte" [isLast]="i==4" [isFirst]="i == 0">
                  </app-bench-cell>
           </div>
      </div>
        

        </div>

       <div class="container-dati-benchmark">


        
        <div *ngIf="tabellaV2.length > 1" class="row row-cols-7 text-center" style="margin-top: 5px;">

          <div class="col">
            <div class="nomeRic" style="height: 100%;margin-left:20px;" > Media benchmark 

            </div>
          </div>

          <div *ngFor="let cell of rigaMediaBench; index as i">
            <div class="col" [ngClass]="{ 'dx':i>0, 'sx':i==0 }">
                  <app-bench-cell class="{{'Azienda'+(j+1)}} "   [valoriCella]="cell" [listaVariabili]="listaVariabiliScelte" [isLast]="i==4" [isFirst]="i == 0">
                  </app-bench-cell>
           </div>
      </div>
        

        </div>





        <div *ngFor="let riga of tabellaV2; index as j" class="row row-cols-7" style="margin-top: 5px;">

              <div class="col">
                <div class="nomeRic" style="height: 100%;margin-left:20px;"
                 >
                  {{listaNomiBench[j]}}
                </div>
              </div>

              <div *ngFor="let cell of riga; index as i">
                    <div class="col" [ngClass]="{ 'dx':i>0, 'sx':i==0 }">
                          <app-bench-cell class="{{'Azienda'+(j+1)}} "   [valoriCella]="cell" [listaVariabili]="listaVariabiliScelte" [isLast]="i==4" [isFirst]="i == 0">
                          </app-bench-cell>
                   </div>
              </div>

        </div>
       
      </div>





      </div>

    </div>


  </div>
</div>