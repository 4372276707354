import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EbaComplianceService {

  private datiEba = new BehaviorSubject<any>(null);
  private submitted = new BehaviorSubject(false);
  private datiBp = new BehaviorSubject<any>(null);
  currentSubmitted = this.submitted.asObservable();
  currentDatiBp = this.datiBp.asObservable();
  currentDatiEba = this.datiEba.asObservable();

  constructor() { }

  changeDatiBp(valore: Object) {
    this.datiBp.next(valore)
  }

  changeSubmitted(valore: boolean) {
    this.submitted.next(valore)
  }

  changeDatiEba(valore: Object) {
    this.datiEba.next(valore)
  }
}
