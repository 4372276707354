import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[currencyInput]'
})

export class currencyInputDirective {

  constructor(private model: NgControl, private decimalPipe: DecimalPipe) { }

  @HostListener('input') inputChange() {
    if (this.model.value != null && this.model.value !== '') {
      const newValue = this.model.value.toString().replace(/[^0-9.,]/g, '');
      this.model.valueAccessor!.writeValue(newValue);
    }
  }
  /* quando esco dal campo mostra il numero con i divisori */
  @HostListener('blur') onBlur() {
    if (this.model.value != null && this.model.value !== '') {
      const newValue = this.decimalPipe.transform(parseFloat(this.model.value.toString().replace(/\,/g, '.')), '1.0-0', 'it') + ' €';
      this.model.control!.setValue(this.model.value.toString().replace(/\,/g, '.'));/* (/\,/g, '.') rimpiazza tutti i , con .  */
      this.model.valueAccessor!.writeValue(newValue);
    }
  }

  @HostListener('load') onLoad() {
    if (this.model.value != null && this.model.value !== '') {
      const newValue = this.decimalPipe.transform(parseFloat(this.model.value.toString().replace(/\,/g, '.')), '1.0-0', 'it') + ' €';
      this.model.control!.setValue(this.model.value.toString().replace(/\,/g, '.'));/* (/\,/g, '.') rimpiazza tutti i , con .  */
      this.model.valueAccessor!.writeValue(newValue);
    }
  }
  /* quando vado sul campo mostra il numero per intero */
  @HostListener('focusin') onFocus() {
    if (this.model.value != null && this.model.value !== '') {
      this.model.valueAccessor!.writeValue(this.model.value);
    }
  }
}
