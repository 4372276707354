import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { faCaretDown, faCaretUp, faInfo } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../loading.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { BilancioIntestatarioService } from '../_services/bilancio_intestatario.service';
import { Router } from '@angular/router';
import { BeanStatico } from '../condivisi/beanStatico';
import { ModelloUniversaleService } from '../_services/modello-universale.service';
import { Subscription, identity } from 'rxjs';
//Costi fissi annuali botteghelle 10900649.36
@Component({
  selector: 'app-modello-universale',
  templateUrl: './modello-universale.component.html',
  styleUrls: ['./modello-universale.component.css']
})
export class ModelloUniversaleComponent implements OnInit {
  
  
  coloreProgetto = '#92a8d1';
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  formModelloUniversale: UntypedFormGroup;
  listaTipiAzienda: BeanStatico[] = [];
  initialValues: any;
  isLoading: boolean = false;
  submitted = false;
  submitParBilancio =false;
  annoCorrente = (new Date()).getFullYear();
  boolRisultati = false;
  boolBP = true;
  boolIndicatori = true;
  boolCE = false;
  boolCF = false;
  boolSP = false;
  boolDeb = false;
  boolIndiceBil = false;
  boolAziendaEsistente = false;
  anniRisultati = [];
  listaBilanci: any[] = [];
  arrayNomeProdotti:any [];
  indicatoreEconometrico = 'IRR Equity';
  frequenze = [0];
  maxFrequenze = 0;
  minimoIndicatore = 0;
  massimoIndicatore = 0;
  mediaIndicatore = 0;
  range = [];
  valutazioneMcc = 'Tot';
  anniMcc = 0;
  frequenzeMcc = [[0]];
  prefissoMcc = '';
  anniIndice = 0;
  freqDscrAnnuo = [[0]];
  indiceDscrAnnuo = 'cumul';
  anniDscrAnnuo = 0;
  indiceBil = 'capCirc';
  freqIndiceBil = [[0]];
  bordiIndiceBil = [0];
  anniBP = 0;
  anniCE = 0;
  anniCF = 0;
  anniSP = 0;
  anniDeb = 0;
  anniIndici = 0;
  anniSimulazioneRisultati = 0;
  numProdottiRisultati = 0;
  colonneRisultatiBP = 5;
  risultati:any = {};
  profiloBP = 4;
  profiloCE = 4;
  profiloCF = 4;
  profiloSP = 4;
  profiloDeb = 4;
  profiloIndici = 4;
  colonneRisultatiCE = 5;
  colonneRisultatiCF = 5;
  colonneRisultatiSP = 5;
  colonneRisultatiDeb = 5;
  colonneRisultatiDscr = 5;
  colonneRisultatiMcc = 5;
  colonneIndici = 5;
  numProdottiIniziale: number;
  perditaAttesaAnnuaRadice = 0;
  perditaInattesaAnnuaRadice = 0;
  /* ho creato 
  ho importato  private tokenService: TokenStorageService per far funzionare onChangeBilancio */
  constructor(private formBuilder: UntypedFormBuilder, private httpClient: HttpClient, private loading: LoadingService, private tokenService: TokenStorageService,
    private bilancioService: BilancioIntestatarioService,private router: Router, private datiModelloUniversaleService: ModelloUniversaleService) {

    this.formModelloUniversale = this.formBuilder.group({
      anniSimulazione: ['', Validators.required], /* i campi sono inizializzati vuoti ['']*/
      numProdotti: ['', Validators.required], /* Validators.required */
      datiProdotti: this.formBuilder.array([ /* creo un array perch� ho diversi campi uguali che si ripetono in base al numero diprodotti */
        this.formBuilder.group({
          /* form controls */
          nomeProdotto : [''],
          prezzoIniziale: ['', Validators.required],
          prezzoMax: ['', Validators.required],
          prezzoMin: ['', Validators.required],
          driftPrezzo: ['', Validators.required],
          scartoPrezzo: ['', Validators.required],
          anniPrezzo: ['', Validators.required],
          costoIniziale: ['', Validators.required],
          costoMax: ['', Validators.required],
          costoMin: ['', Validators.required],
          driftCosto: ['', Validators.required],
          scartoCosto: ['', Validators.required],
          anniCosto: ['', Validators.required],
          capacitaProduttiva: ['', Validators.required],
         // percMagazzino: ['', Validators.required],
          percAnnuali: this.formBuilder.array([
          ])
        })
      ]),
      costiFissi: this.formBuilder.array([
        this.formBuilder.group({
        valoreAnnuo: [''],
        })
      ]),
      costoFatturato: [''],
      giorniCrediti: ['', Validators.required],
      giorniDebiti: ['', Validators.required],
      capex: [''],
      rimanenzeIn: [''],
      capexEquity: [''],
      importoFinanziamento: ['', Validators.required],
      durataFinanziamento: ['', Validators.required],
      durataAmmortamentoAnni: ['', Validators.required],
      valoreGaranzie: ['', Validators.required],
      /* campi non obbligatori */
      presenzaBilancio:['', Validators.required], 
      bilancio:[''],
      nomeAzienda: [''],
      tipoAzienda: [''],
      partitaIva: [''],
    })
  }


  ngOnInit(): void {

    this.listenToLoading();
    this.initialValues = this.formModelloUniversale.value;
    this.numProdottiIniziale =  this.formModelloUniversale.controls.numProdotti.value;
    /* Questa parte serve a non far scomparire i dati dal form salvandoli in datiModelloUniversaleService.datiModellouniversale di cui currentDatiModelloUniversale � l'observer */
    /* il salvataggio in datiModellouniversale viene richiamato da gotoBilancio e campi vengono fillati al reloading della pag. modelloUniversale*/
    this.datiModelloUniversaleService.currentDatiModelloUniversale.subscribe((result) => {
      if (result != null) {
        this.formModelloUniversale = result;
      }
    });
    /* per settare il nome del nuovo bilancio nel form */
    /* richiamo l'osservabile che punta all'oggetto parambilancio il cui sono salvati i dati*/
    this.getLastBilancio()
    
    
    /* si prende il nome dei possibili tipi di aziende al caricamento della pag */
    this.getTipoAzienda()

    this.onChangeBilancio()
    
  }
  get f() { return this.formModelloUniversale.controls; } /* sostitusce tutti i get di formModelloUniversale.controls con f */
  get prodottiFormArray(): UntypedFormArray { return <UntypedFormArray> this.formModelloUniversale.controls.datiProdotti; }; /* <casting> */
  get prodottiArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.prodottiFormArray.controls; };
  get costiFormArray(): UntypedFormArray { return <UntypedFormArray> this.formModelloUniversale.controls.costiFissi; }; /* <casting> */
  get costiArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.costiFormArray.controls; };
  /**
* Listen to the loadingSub property in the LoadingService class. This drives the
* display of the loading spinner.
*/
  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }

  /* variabili riempite al submit */
  onSubmit() {
    this.submitted = true;
    /* this.submitParBilancio = true; */
    this.prodottiFormArray.updateValueAndValidity();
    this.formModelloUniversale.updateValueAndValidity();
    if (this.formModelloUniversale.valid) {

      this.salvaNomeProdotti();

      this.boolAziendaEsistente = this.formModelloUniversale.controls.presenzaBilancio.value;
      let request = {
        anniSimulazione: this.formModelloUniversale.controls.anniSimulazione.value,
        numProdotti: this.formModelloUniversale.controls.numProdotti.value,
        costoFatturato: this.formModelloUniversale.controls.costoFatturato.value,
        capex: this.formModelloUniversale.controls.capex.value,
        rimanenzeIn: this.formModelloUniversale.controls.rimanenzeIn.value,
        capexEquity: this.formModelloUniversale.controls.capexEquity.value,
        importoFinanziamento: this.formModelloUniversale.controls.importoFinanziamento.value,
        durataFinanziamento: this.formModelloUniversale.controls.durataFinanziamento.value,
        giorniCrediti: this.formModelloUniversale.controls.giorniCrediti.value,
        giorniDebiti: this.formModelloUniversale.controls.giorniDebiti.value,
        bilancioPresente: this.formModelloUniversale.controls.presenzaBilancio.value,
        idIntestatario: this.formModelloUniversale.controls.bilancio.value == '' ? 0 : this.formModelloUniversale.controls.bilancio.value.idIntestatario,
        dataBilancio: this.formModelloUniversale.controls.bilancio.value == '' ? '' : this.formModelloUniversale.controls.bilancio.value.data,
        valoreGaranzie: this.formModelloUniversale.controls.valoreGaranzie.value,
        durataAmmortamentoAnni: this.formModelloUniversale.controls.durataAmmortamentoAnni.value,
        /*  */
        /* presenzaBilancio: this.f.presenzaBilancio.value,
        bilancio: this.f.bilancio.value,
        nomeAzienda: this.f.nomeAzienda.value,
        tipoAzienda: this.f.tipoAzienda.value,
        partitaIva: this.f.partitaIva.value, */
        /*varibili nel form array vengono riempite dopo nel for*/
        /* nomeProdotto: [], */
        anniPrezzo: [],
        anniCosto: [],
        scartoCosto: [],
        scartoPrezzo: [],
        driftCosto: [],
        driftPrezzo: [],
        prezzoIniziale: [],
        prezzoMin: [],
        prezzoMax: [],
        costoIniziale: [],
        costoMin: [],
        costoMax: [],
        capacitaProduttiva: [],
        //percMagazzino: [],
        matriceVenduto: [],
        matriceProdotto: [],
        costiFissi: []
      }
 
      for(let i = 0; i < this.formModelloUniversale.controls.numProdotti.value; i++){
        /* request.nomeProdotto.push(this.prodottiArray[i].controls.nomeProdotto.value) */
        request.anniCosto.push(this.prodottiArray[i].controls.anniCosto.value);
        request.anniPrezzo.push(this.prodottiArray[i].controls.anniPrezzo.value);
        request.scartoCosto.push(this.prodottiArray[i].controls.scartoCosto.value);
        request.scartoPrezzo.push(this.prodottiArray[i].controls.scartoPrezzo.value);
        request.driftCosto.push(this.prodottiArray[i].controls.driftCosto.value);
        request.driftPrezzo.push(this.prodottiArray[i].controls.driftPrezzo.value);
        request.prezzoIniziale.push(this.prodottiArray[i].controls.prezzoIniziale.value);
        request.prezzoMin.push(this.prodottiArray[i].controls.prezzoMin.value);
        request.prezzoMax.push(this.prodottiArray[i].controls.prezzoMax.value);
        request.costoIniziale.push(this.prodottiArray[i].controls.costoIniziale.value);
        request.costoMin.push(this.prodottiArray[i].controls.costoMin.value);
        request.costoMax.push(this.prodottiArray[i].controls.costoMax.value);
        request.capacitaProduttiva.push(this.prodottiArray[i].controls.capacitaProduttiva.value);
        //request.percMagazzino.push(this.prodottiArray[i].controls.percMagazzino.value);
      }

      let percentualiVenduto = [];
      let percentualiProdotto = [];

      for(let j = 0; j < this.formModelloUniversale.controls.anniSimulazione.value; j++){
        percentualiVenduto = [];
        percentualiProdotto = [];
        for(let i = 0; i < this.formModelloUniversale.controls.numProdotti.value; i++){
          percentualiVenduto.push((<UntypedFormGroup[]> (<UntypedFormArray> this.prodottiArray[i].controls.percAnnuali).controls)[j].controls.percVenduto.value);
          percentualiProdotto.push((<UntypedFormGroup[]> (<UntypedFormArray> this.prodottiArray[i].controls.percAnnuali).controls)[j].controls.percProdotto.value);
         
        }
        request.matriceVenduto.push(percentualiVenduto);
        request.matriceProdotto.push(percentualiProdotto);

        request.costiFissi.push(this.costiArray[j].controls.valoreAnnuo.value);

      }
      
      this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/calcolaUniversale', request).subscribe(data => {
        this.risultati = data

        this.valutazioneMcc = 'Tot';
        this.anniMcc = 0;
        this.frequenzeMcc = this.risultati.freqMccTot;
        this.prefissoMcc = '';
        
        this.numProdottiRisultati = this.formModelloUniversale.controls.numProdotti.value;
        this.anniSimulazioneRisultati = this.f.anniSimulazione.value;
        this.anniRisultati = [];
        let lustri = 1+Math.floor((this.f.anniSimulazione.value - 1.)/5.);
        for(let i = 0; i < lustri; i++){
          this.anniRisultati.push(
            {
              valore: i*5,
              label: (i*5+1) + "-" + Math.min((i+1)*5,this.f.anniSimulazione.value),
            })
        }
        this.onChangeAnniBP();
        this.onChangeAnniCE();
        this.onChangeAnniCF();
        this.onChangeAnniSP();
        this.onChangeAnniDeb();
        this.onChangeAnniDscr();
        this.onChangeAnniMcc();
        this.onChangeAnniIndici();
        this.indicatoreEconometrico = 'IRR Equity';
        this.range = this.risultati.bordiIrrEqString;
        this.frequenze = this.risultati.freqIrrEq;
        this.minimoIndicatore = this.risultati.minMaxMedIrrEq[0];
        this.massimoIndicatore = this.risultati.minMaxMedIrrEq[1];
        this.mediaIndicatore = this.risultati.minMaxMedIrrEq[2];

        this.freqDscrAnnuo = this.risultati.freqDscrCumul;
        this.freqIndiceBil = this.risultati.freqCapCirc;
        this.bordiIndiceBil = this.risultati.bordiCapCircString;

        this.perditaAttesaAnnuaRadice = this.risultati.perditaAttesaAnnuale/Math.sqrt(this.f.anniSimulazione.value);
        this.perditaInattesaAnnuaRadice = this.risultati.perditaInattesa/Math.sqrt(this.f.anniSimulazione.value);
        this.boolRisultati = true;
      });
      

    }

  }

  onReset() {
    this.formModelloUniversale.reset(this.initialValues);
    this.submitted = false;
    this.boolRisultati = false;
    
  }

  onChangeAnniSimulazione() {
    for(let i = 0; i < this.prodottiArray.length; i++){
      (<UntypedFormArray> this.prodottiArray[i].controls.percAnnuali).clear();
      (<UntypedFormArray> this.formModelloUniversale.controls.costiFissi).clear();
      if(this.f.anniSimulazione.value && this.f.anniSimulazione.value > 0){
        let arrayPerc = <UntypedFormGroup[]> (<UntypedFormArray> this.prodottiArray[i].controls.percAnnuali).controls;
        let arrayCosti = <UntypedFormGroup[]> (<UntypedFormArray> this.formModelloUniversale.controls.costiFissi).controls;
        for(let j = 0; j < this.f.anniSimulazione.value; j++){
          arrayPerc.push(this.formBuilder.group({
            percVenduto: [0],
            percProdotto: [0]
            })
          );
          arrayCosti.push(this.formBuilder.group({
            valoreAnnuo: [0]
            })
          );
        } 
      }
    }

  }
  /* gestisce i lnumero di form per i prodotti in base al numero inserito */
  /*
  onChangeNumeroProdotti() {
    for(let i =0  ; i< Math.abs(this.f.numProdotti.value- this.numProdottiIniziale); i++){
      if(this.f.numProdotti.value - this.numProdottiIniziale > 0) {
        this.prodottiArray.push(
          this.formBuilder.group({
            nomeProdotto: [''],
            prezzoIniziale: ['', Validators.required],
            prezzoMax: ['', Validators.required],
            prezzoMin: ['', Validators.required],
            driftPrezzo: ['', Validators.required],
            scartoPrezzo: ['', Validators.required],
            anniPrezzo: ['', Validators.required],
            costoIniziale: ['', Validators.required],
            costoMax: ['', Validators.required],
            costoMin: ['', Validators.required],
            driftCosto: ['', Validators.required],
            scartoCosto: ['', Validators.required],
            anniCosto: ['', Validators.required],
            capacitaProduttiva: ['', Validators.required],
            //percMagazzino: ['', Validators.required],
            percAnnuali: this.formBuilder.array([
            ])
            })
          )

      }else if(this.f.numProdotti.value- this.numProdottiIniziale < 0){
        this.prodottiArray.splice(this.prodottiArray.length-1) basta cambiare il valore di splice se si volesse eliminare uno specifico prodotto 
      }
    }
      this.onChangeAnniSimulazione();
      this.numProdottiIniziale = this.f.numProdotti.value;

  }
  */
  onChangeNumeroProdotti() {
    this.prodottiFormArray.clear();
    for(let i = 0; i < this.f.numProdotti.value; i++){
      this.prodottiArray.push(
        this.formBuilder.group({
          nomeProdotto: [''],
          prezzoIniziale: ['', Validators.required],
          prezzoMax: ['', Validators.required],
          prezzoMin: ['', Validators.required],
          driftPrezzo: ['', Validators.required],
          scartoPrezzo: ['', Validators.required],
          anniPrezzo: ['', Validators.required],
          costoIniziale: ['', Validators.required],
          costoMax: ['', Validators.required],
          costoMin: ['', Validators.required],
          driftCosto: ['', Validators.required],
          scartoCosto: ['', Validators.required],
          anniCosto: ['', Validators.required],
          capacitaProduttiva: ['', Validators.required],
          //percMagazzino: ['', Validators.required],
          percAnnuali: this.formBuilder.array([
          ])
          })
        )
      }

      for(let i = 0; i < this.prodottiArray.length; i++){
        (<UntypedFormArray> this.prodottiArray[i].controls.percAnnuali).clear();
        if(this.f.anniSimulazione.value && this.f.anniSimulazione.value > 0){
          let arrayPerc = <UntypedFormGroup[]> (<UntypedFormArray> this.prodottiArray[i].controls.percAnnuali).controls;
          for(let j = 0; j < this.f.anniSimulazione.value; j++){
            arrayPerc.push(this.formBuilder.group({
              percVenduto: [0],
              percProdotto: [0]
              })
            );
          } 
        }
      }
    
  }

  onChangeAnniBP(){
    this.colonneRisultatiBP = Math.min(5,this.anniSimulazioneRisultati-this.anniBP); 
  }

  onChangeAnniCE(){
    this.colonneRisultatiCE = Math.min(5,this.anniSimulazioneRisultati-this.anniCE); 
  }

  onChangeAnniCF(){
    this.colonneRisultatiCF = Math.min(5,this.anniSimulazioneRisultati-this.anniCF); 
  }

  onChangeAnniSP(){
    this.colonneRisultatiSP = Math.min(5,this.anniSimulazioneRisultati-this.anniSP); 
  }

  onChangeAnniDeb(){
    this.colonneRisultatiDeb = Math.min(5,this.anniSimulazioneRisultati-this.anniDeb); 
  }

  onChangeAnniDscr(){
    this.colonneRisultatiDscr = Math.min(5,this.anniSimulazioneRisultati-this.anniDscrAnnuo); 
  }

  onChangeAnniMcc(){
    this.colonneRisultatiMcc = Math.min(5,this.anniSimulazioneRisultati-this.anniMcc); 
  }

  onChangeAnniIndici(){
    this.colonneIndici = Math.min(5,this.anniSimulazioneRisultati-this.anniIndici); 
  }

  onChangeIndicatore() {
    if (this.indicatoreEconometrico == 'IRR Equity') {
      this.range = this.risultati.bordiIrrEqString;
      this.frequenze = this.risultati.freqIrrEq;
      this.minimoIndicatore = this.risultati.minMaxMedIrrEq[0];
      this.massimoIndicatore = this.risultati.minMaxMedIrrEq[1];
      this.mediaIndicatore = this.risultati.minMaxMedIrrEq[2];
    }
    else if (this.indicatoreEconometrico == 'IRR Progetto') {
      this.range = this.risultati.bordiIrrPrString;
      this.frequenze = this.risultati.freqIrrPr;
      this.minimoIndicatore = this.risultati.minMaxMedIrrPr[0];
      this.massimoIndicatore = this.risultati.minMaxMedIrrPr[1];
      this.mediaIndicatore = this.risultati.minMaxMedIrrPr[2];
    }
    else if (this.indicatoreEconometrico == 'Break even') {
      this.range = this.risultati.bordiBreakEvenString;
      this.frequenze = this.risultati.freqBreakEven;
      this.minimoIndicatore = this.risultati.minMaxMedBreakEven[0];
      this.massimoIndicatore = this.risultati.minMaxMedBreakEven[1];
      this.mediaIndicatore = this.risultati.minMaxMedBreakEven[2];
    }
    else if (this.indicatoreEconometrico == 'Valore compagnia') {
      this.range = this.risultati.bordiValCompanyString;
      this.frequenze = this.risultati.freqValCompany;
      this.minimoIndicatore = this.risultati.minMaxMedValCompany[0];
      this.massimoIndicatore = this.risultati.minMaxMedValCompany[1];
      this.mediaIndicatore = this.risultati.minMaxMedValCompany[2];
    }
    else if (this.indicatoreEconometrico == 'Dscr') {
      this.range = this.risultati.bordiDscrString;
      this.frequenze = this.risultati.freqDscr;
      this.minimoIndicatore = this.risultati.minMaxMedDscr[0];
      this.massimoIndicatore = this.risultati.minMaxMedDscr[1];
      this.mediaIndicatore = this.risultati.minMaxMedDscr[2];
    }
  }

  salvaNomeProdotti(){
    this.arrayNomeProdotti = [];
    for(let i = 0; i < this.formModelloUniversale.controls.numProdotti.value; i++){
    this.arrayNomeProdotti.push(this.prodottiArray[i].controls.nomeProdotto.value)
    }
    /* this.arrayNomeProdotti.forEach(element => console.log(element))
    console.log("\n========") */
  }
  onChangeValutazioneMcc() {
    if (this.valutazioneMcc == 'Tot') {
      this.frequenzeMcc = this.risultati.freqMccTot;
      this.prefissoMcc = '';
    }
    else if (this.valutazioneMcc == 'Bil') {
      this.frequenzeMcc = this.risultati.freqBil;
      this.prefissoMcc = 'F';
    }
    else if (this.valutazioneMcc == 'Cr') {
      this.frequenzeMcc = this.risultati.freqCr;
      this.prefissoMcc = 'CR';
    }

  }

  onChangeIndiceDscrAnnuo(){
    if (this.indiceDscrAnnuo == 'annuo') {
      this.freqDscrAnnuo = this.risultati.freqDscrAnnuo;
    }
    else if (this.indiceDscrAnnuo == 'cumul') {
      this.freqDscrAnnuo = this.risultati.freqDscrCumul;
    }
  }

  onChangeIndiceBil(){
    if (this.indiceBil == 'capCirc') {
      this.freqIndiceBil = this.risultati.freqCapCirc;
      this.bordiIndiceBil = this.risultati.bordiCapCircString;
    }
    else if (this.indiceBil == 'netProfit') {
      this.freqIndiceBil = this.risultati.freqNetProfit;
      this.bordiIndiceBil = this.risultati.bordiNetProfitString;
    }
    else if (this.indiceBil == 'patrDeb') {
      this.freqIndiceBil = this.risultati.freqRateoPatrDeb;
      this.bordiIndiceBil = this.risultati.bordiPatrDebString;
    }
    else if (this.indiceBil == 'patrImmob') {
      this.freqIndiceBil = this.risultati.freqRateoPatrImmob;
      this.bordiIndiceBil = this.risultati.bordiPatrImmobString;
    }
  }

  /* questo blocco si attiva dopo */
  onChangeBilancio(){
    if(this.f.presenzaBilancio.value){
      /* this.submitted = false; */
      /* fornisce le possibili scelte per dei bilanci precompilati */
       this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getBilanciRichiedente',this.tokenService.getUser().username).subscribe(data => {
        this.listaBilanci = []; /* svuoto la lista */
        data.forEach(element => {
          let elementoLista = {
                idIntestatario: element.intestatario.id, 
                data: element.bilancio.dataInserimento, 
                descrizione: element.intestatario.ragioneSociale + " - " + element.bilancio.dataUltimaModifica
          };
          this.listaBilanci.push(elementoLista)
          /* se il campo � vuoto e currisponde a quello che c � in elementolista.data e .idDest setta il valore a quello. Questo capita solo al ritorno nella pag quando setNuovoBilancio()
          inserisce il bilancio appena creato nel campo bilancio del form */
          if( elementoLista.data == this.f.bilancio.value.data && elementoLista.idIntestatario == this.f.bilancio.value.idIntestatario)  
          {
            /* setto il campo bilancio che pero non viene settato finch� setNuovoBilancio() non viene richiamata in ngOnInit  */
            this.f.bilancio.setValue(elementoLista);
            
          }
        });
      }); 
      /* rendo i campi obbligatori */
      this.f.tipoAzienda.setValidators(Validators.required);
      this.f.nomeAzienda.setValidators(Validators.required);
      this.f.partitaIva.setValidators(Validators.required);
      this.f.bilancio.setValidators(Validators.required);
      /* When you add or remove a validator at run time call the function, */

    }else{
      this.f.tipoAzienda.setValidators(null);
      this.f.nomeAzienda.setValidators(null);
      this.f.partitaIva.setValidators(null);
      this.f.bilancio.setValidators(null);
    }
    this.f.tipoAzienda.updateValueAndValidity();
    this.f.nomeAzienda.updateValueAndValidity();
    this.f.partitaIva.updateValueAndValidity();
    this.f.bilancio.updateValueAndValidity();
  }
  /* viene richiamato al caricamento quando atterro sulla pag la seconda volta*/
  getLastBilancio(){
    this.bilancioService.currentParamBilancio.subscribe((bilancio) =>{      
      if(bilancio && bilancio.id_intestatario){  /* condizione per non farlo partire al primo atterraggio sulla pag */
        this.formModelloUniversale.controls.bilancio.setValue({idIntestatario: bilancio.id_intestatario, data: bilancio.data_inserimento}) /* this.listaBilanci[length-1] */
      }
    })
  }
  getTipoAzienda(){
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda').subscribe(data => {  
      this.listaTipiAzienda = data;
        if(this.f.tipoAzienda.value != ''){
          this.listaTipiAzienda.forEach(element => {
            /* lascia nel campo il tipo azienda messo confrontando gli id */
            if(element.id == this.f.tipoAzienda.value.id)  {
              this.f.tipoAzienda.setValue(element)
            };
          });
        }
      });
  }

  goToBilancio() {
    this.submitParBilancio = false;
    console.log(this.f.tipoAzienda.value.descrizione)

    if(this.f.tipoAzienda.value.descrizione == undefined || this.f.nomeAzienda.value =="" || this.f.partitaIva.value =="" ){
      this.submitParBilancio = true;
    }else{
      this.submitParBilancio = false;
      /* salvo i dati inseriti in un service */
      this.datiModelloUniversaleService.changeDatiModelloUniversale(this.formModelloUniversale)

      /* parametri in input al post */
      let request = {
        ragioneSociale: this.f.nomeAzienda.value,
        idTipoAziendaMcc: this.f.tipoAzienda.value.id,
        partitaIva: this.f.partitaIva.value,
        utente: this.tokenService.getUser().username,
      };
      console.log(request)
      this.httpClient
        .post<any>(environment.apiUrlQuestionario +'/fasGoAlgo/getIntestatarioBilancio',request) /* richiesta request per vedere l'id intestario e se non c � crea un nuovo record */
        .subscribe((response) => { /* subscribe per vedere la risposta della chiamata */
        /* prendo i dati della risposta e li uso per creare i parmetri di un altro bilancio */
          this.bilancioService.changeParamBilancio({
            id: response.id,
            nome: response.ragioneSociale,
            azienda: response.idTipoAziendaMcc,
            bilancio: 'nuovo',
            
          });
          console.log(response)
          this.router.navigate(['/bilancio']);
        });
    }
  }
}
