
<div class="container-fluid" style="margin-top: 35px">
  <div class="loading-container" *ngIf="isLoading">
    <div
      class="spinner-border text-primary"
      style="width: 50px; height: 50px"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div
    class="animated fadeInDown sticky"
    id="navbar"
    style="z-index: 1000;"
  >
    <div class="row mb-3">
      <div class="col-12 nopadding">
        <ul
          class="progress-indicator"
          style="width: 100%; margin-bottom: 0px"
        >
          <li
            [ngClass]="[
                  anagraficaCompleted ? 'completed' : '',
                  !anagraficaCompleted && submitted ? 'danger' : ''
                ]"
            (click)="onClick(1)"
            class="pointer"
          >
            <span class="bubble"></span>
            <span [ngClass]="[stepIndex == 1 ? 'font-bold font-black' : '']"
            >Profilo richiedente</span
            >
          </li>
          <li
            [ngClass]="[
                  finanziamentoCompleted ? 'completed' : '',
                  !finanziamentoCompleted && submitted ? 'danger' : ''
                ]"
            (click)="onClick(2)"
            class="pointer"
          >
                <span class="bubble"></span
                ><span
            [ngClass]="[stepIndex == 2 ? 'font-bold font-black' : '']"
          >Piano investimenti
                </span>
          </li>
          <li
            [ngClass]="[
                  garanzieCompleted ? 'completed' : '',
                  !garanzieCompleted && submitted ? 'danger' : ''
                ]"
            (click)="onClick(3)"
            class="pointer"
          >
            <span class="bubble"></span>
            <span [ngClass]="[stepIndex == 3 ? 'font-bold font-black' : '']"
            >Piano finanziamento
                </span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <form [formGroup]="formTotale" (ngSubmit)="onSubmit()">
    <div class="row margin-0">
      <div class="col-12 nopadding" [hidden]="stepIndex != 1">
        <qs-anagrafica-questionario-old
          [formGroup]="anagraficaForm"
          (registraCr)="goToCr()"
          (registraBilancio)="goToBilancio()"
        ></qs-anagrafica-questionario-old>
      </div>

      <div class="col-12 nopadding" [hidden]="stepIndex != 2">
        <qs-dati-investimento
          [formGroup]="investimentoForm"
          (addProdotto)="addProdProgetto()"
          (removeProdotto)="removeProdProgetto($event.valueOf())"
        ></qs-dati-investimento>
      </div>
      <!--
    <div class="col-12 nopadding" [hidden]="stepIndex != 3">
      <qs-indebitamento [formGroup]="indebitamentoForm" ></qs-indebitamento>
    </div>
-->
      <div class="col-12 nopadding" [hidden]="stepIndex != 3">
        <qs-garanzie [formGroup]="garanzieForm"></qs-garanzie>
      </div>

      <div class="col-12" style="padding-top:15px">
        <button
          class="btn btn-secondary pull-left"
          type="button"
          style="padding-top:0px; padding-bottom: 0px"
          (click)="indietro()"
          *ngIf="stepIndex > 1"
        >
          <fa-icon
            style="font-size: 25px"
            [icon]="faAngleDoubleLeft"
          ></fa-icon>
        </button>
        <button
          class="btn btn-secondary pull-right"
          type="button"
          style="padding-top:0px; padding-bottom: 0px"
          (click)="avanti()"
          *ngIf="stepIndex < 3"
        >
          <fa-icon
            style="font-size: 25px"
            [icon]="faAngleDoubleRight"
          ></fa-icon>
        </button>
      </div>

      <div class="col-12 text-center" style="margin: 20px 0;">
        <button class="btn btn-primary">Calcola</button>
        <button class="btn btn-primary" style="margin-left: 5px; border-color: #1658b5; background: #1658b5" *ngIf="anagraficaForm.controls.nomeAzienda.value != ''" type="button"
                (click)="openModal()">Salva</button>
        <button
          class="btn btn-secondary"
          style="margin-left: 5px"
          type="button"
          (click)="onReset()"
        >
          Annulla
        </button>
      </div>
    </div>
  </form>
  <!-- modale -->
  <!--       <ng-template #modaleSalva let-c="close" let-d="dismiss">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">
              Salva Business Plan
            </h4>
            <button type="button"  class="btn-close" aria-label="Close"(click)="d('Cross click')"></button>
          </div>
          <div class="modal-body">
            <div>
              <label>Riferimento salvataggio:</label>
              <input type="text" [(ngModel)]="nomeSalvataggio" class="form-control"/>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" (click)="c('Close click')">
                Chiudi
              </button>
              <button
                type="button"
                ngbAutofocus
                class="btn btn-primary"
                (click)="chiudiModaleESalva(c)"
              >
                Salva
              </button>
            </div>
          </div>
        </ng-template> -->
  <!-- fine html modale -->

  <!-- modale 2 -->
  <!-- <ng-template #modaleMessaggio let-c="close" let-d="dismiss">
    <div class="modal-header bg-success">
      <h4 class="modal-title text-center" id="modal-basic-title"></h4>
    </div>
    <div class="modal-body">
      <h5>
        <p class="text-center">
          {{ msg }}
        </p>
      </h5>
      <div class="modal-footer text-center">
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="tornaIndietro()"
        >
          <i class="fa fa-check fa-fw" aria-hidden="true"></i>
          Ok
        </button>
      </div>
    </div>
  </ng-template>
  fine modale 2 -->
</div>
