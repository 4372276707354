<div class="container-fluid" [formGroup]="indicatoriGuidaForm">
    <div class="row" id="save-Indicatori">
        <div class="col-12 d-flex align-items-center justify-content-end margin-top-10">
            <!--<button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-success" (click)="onSubmit()">Salva</button>-->
            <button *ngIf="businessPlanTemp!=null && (businessPlanTemp.idStato==1 ||businessPlanTemp.idStato==2)"
                class="btn btn-secondary" type="button" style="margin-left: 20px" (click)="Annulla()">
                Annulla modifiche
            </button>
        </div>
    </div>
    <hr>
    <div>
        <!-- id="container-indicatori-body-bp" -->
        <div class="row d-flex flex-column margin-top-20">
            <!-- id="addPaddingInStickyIndicatori" -->
            <div class="col-12 d-flex align-items-center">
                <h2>Anni di sviluppo Business plan:
                </h2>
            </div>
            <div class="col-12  margin-left-20">
                <div class="row margin-left-20">
                    <div class="col-2">
                        <label>Anni:</label>
                        <input formControlName="anniSviluppoBP" type="number" class="form-control"
                            [ngClass]="{ 'is-invalid':submitted && f.anniSviluppoBP.errors }" min="1" max="10"
                            (change)="onChangeInflazione()"
                            onkeyup="if(this.value<0){this.value= this.value *-1} if(this.value==0){this.value= 1}" />
                        <div *ngIf=" submitted && f.anniSviluppoBP.errors" class="invalid-feedback">
                            <div *ngIf="f.anniSviluppoBP.errors.required">Campo obbligatorio</div>
                            <div *ngIf="f.anniSviluppoBP.errors.max">Numero massimo di anni consentito:
                                {{valoreMax.anniSviluppoBp}} anni</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>

        <div class="row margin-top-20">
            <div class="col-12 d-flex align-items-center">

                <h2 class="align-middle">Inflazione: modalità di gestione: &nbsp;&nbsp;<i id="icon"
                        class="fa fa-1x fa-info-circle" aria-hidden="true" placement="top" container="body"
                        ngbTooltip={{listaTips[5]?.descrizione}}></i></h2>
            </div>
            <div class="col-12  margin-left-20">
                <div class="row margin-left-20">
                    <div class="col-3 col-xl-3" *ngIf="idRuolo == 0">
                        <label class="align-middle">Evoluzione:</label>
                        <select formControlName="inflazione" type="text" value="" class="form-control form-select"
                            [ngClass]="{ 'is-invalid':submitted && f.inflazione.errors }" style="max-width:100%;"
                            (change)="onChangeInflazione()">
                            <option [ngValue]="inflazione" *ngFor="let inflazione of listaEvoluzioneInflazione">
                                {{inflazione.descrizione}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.inflazione.errors" class="invalid-feedback">
                            <div *ngIf="f.inflazione.errors.required">Campo obbligatorio</div>
                        </div>
                    </div>
                    <div *ngIf="f.inflazione.value && f.inflazione.value.id==1" formArrayName="inflazioneAnnuale"
                        class="row margin-0 d-flex align-items-center">
                        <div class="col-2 col-xl-1 margin-bottom-10 margin-top-10 margin-right-20"
                            *ngFor="let annoForm of inflazioneAnnualeFormArray;let i =index">
                            <label>% al {{arrayAnni[i]}}</label>
                            <input type="number" [formControlName]="i" class="form-control" style="max-width: 100%;"
                                [ngClass]="{ 'is-invalid':submitted && inflazioneAnnualeFormArray[i].errors,'warning-control':warningInflazioneAnnuale[i]}"
                                onkeyup="if(this.value<0){this.value= this.value *-1}"
                                (keyup)="checkValMaxInflazioneAnnuale(i)" />
                            <div *ngIf=" submitted && inflazioneAnnualeFormArray[i].errors" class="invalid-feedback">
                                <div *ngIf="inflazioneAnnualeFormArray[i].errors.required">
                                    Campo
                                    obbligatorio
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="warningInflazioneAnnualeComp" class="warning-label">
                        <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                        mantenere
                        l'inflazione per anno sotto il {{valoreMax.valoreInflazione}}%
                    </div>
                    <div *ngIf="f.inflazione.value && f.inflazione.value.id==2" class="col-9" style="padding-top:2.5px;"
                        [formGroup]="evoluzioneInflazione">
                        <div class="row d-flex justify-content-start">
                            <div class="col-3" style="padding-left: 20px;">
                                <label>Inflazione
                                    iniziale(%)&nbsp;<i id="icon" container="body"
                                        class="fa fa-1x fa-info-circle margin-left-20" aria-hidden="true"
                                        placement="top" ngbTooltip={{listaTips[6]?.descrizione}}></i></label>
                                <input formControlName="inflazioneIniziale" type="number" class="form-control"
                                    [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.inflazioneIniziale.errors,'warning-control':warningInflazioneIniziale }"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('inflazioneIniziale')" />
                                <div *ngIf="submitted && evoluzioneInflazione.controls.inflazioneIniziale.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="evoluzioneInflazione.controls.inflazioneIniziale.errors.required">Campo
                                        obbligatorio</div>
                                </div>
                                <div *ngIf="warningInflazioneIniziale" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    l'inflazione iniziale sotto il {{valoreMax.valoreInflazione}}%
                                </div>
                            </div>
                            <div class="col-2">
                                <label> Ribasso(%)
                                </label>
                                <input formControlName="ribasso" type="number" class="form-control inflazione"
                                    [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.ribasso.errors,'warning-control':warningInflazioneRibasso }"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('inflazioneRibasso')" />
                                <div *ngIf="submitted && evoluzioneInflazione.controls.ribasso.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="evoluzioneInflazione.controls.ribasso.errors.required">Campo
                                        obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningInflazioneRibasso" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    il Ribasso sotto il {{valoreMax.ribassoInflazione}}%
                                </div>
                            </div>
                            <div class="col-2">
                                <label>Rialzo
                                    (%)
                                </label>
                                <input formControlName="rialzo" type="number" class="form-control"
                                    [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.rialzo.errors,'warning-control':warningInflazioneRialzo }"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('inflazioneRialzo')" />
                                <div *ngIf="submitted && evoluzioneInflazione.controls.rialzo.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="evoluzioneInflazione.controls.rialzo.errors.required">Campo obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningInflazioneRialzo" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    il Rialzo sotto il {{valoreMax.rialzoInflazione}}%
                                </div>
                            </div>
                            <div class="col-2" *ngIf="idRuolo == 0">
                                <label> Scarto
                                    (%)&nbsp;<i id="icon" class="fa fa-1x fa-info-circle margin-left-20"
                                        aria-hidden="true" container="body" placement="top"
                                        ngbTooltip={{listaTips[8]?.descrizione}}></i>

                                </label>
                                <input formControlName="scarto" type="number" class="form-control" min="0.1"
                                    [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.scarto.errors ,'warning-control':warningInflazioneScarto}"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('inflazioneScarto')" />
                                <div *ngIf="submitted && evoluzioneInflazione.controls.scarto.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="evoluzioneInflazione.controls.scarto.errors.required">Campo obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningInflazioneScarto" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    lo Scarto sotto il {{valoreMax.ScartoInflazione}}%
                                </div>
                            </div>
                            <div class="col-2">
                                <label> Drift
                                    (%)&nbsp;<i id="icon" class="fa fa-1x fa-info-circle margin-left-20"
                                        aria-hidden="true" container="body" placement="top"
                                        ngbTooltip={{listaTips[9]?.descrizione}}></i>

                                </label>
                                <input formControlName="drift" type="number" class="form-control"
                                    [ngClass]="{ 'is-invalid':submitted && evoluzioneInflazione.controls.drift.errors ,'warning-control':warningInflazioneDrift}"
                                    onkeyup="if(this.value<0){this.value= this.value *-1}"
                                    (keyup)="checkValMax('inflazioneDrift')" />
                                <div *ngIf="submitted && evoluzioneInflazione.controls.drift.errors"
                                    class="invalid-feedback">
                                    <div *ngIf="evoluzioneInflazione.controls.drift.errors.required">Campo obbligatorio
                                    </div>
                                </div>
                                <div *ngIf="warningInflazioneDrift" class="warning-label">
                                    <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                                    mantenere
                                    il Drift sotto il {{valoreMax.driftInflazione}}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr>
        <div class="row d-flex flex-column margin-top-20">
            <div class="col-12 d-flex align-items-center">

                <h2>Costi di business:&nbsp;&nbsp;<i id="icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                        placement="top" container="body" ngbTooltip={{listaTips[10]?.descrizione}}></i></h2>
            </div>
            <div class="col-12  margin-left-20">
                <div class="row margin-left-20">
                    <div class="col-4 col-xl-3"><label>Costi sul fatturato (%)</label>
                        <input formControlName="costiSulFatturato" type="number" class="form-control"
                            [ngClass]="{ 'is-invalid':submitted && f.costiSulFatturato.errors,'warning-control':warningCostiSulFatturato}"
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            (keyup)="checkValMax('costiSulFatturato')" />
                        <div *ngIf=" submitted && f.costiSulFatturato.errors" class="invalid-feedback">
                            <div *ngIf="f.costiSulFatturato.errors.required">Campo obbligatorio</div>
                        </div>
                        <div *ngIf="warningCostiSulFatturato" class="warning-label">
                            <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                            mantenere
                            i costi sul fatturato sotto il {{valoreMax.costiSulFatturato}}%
                        </div>
                    </div>
                    <div class="col-4 col-xl-3">
                        <label>Variazione su anno precedente
                            (%)</label>
                        <input formControlName="costiFatturatoVariazioneAnnoPrecedente" type="number"
                            class="form-control"
                            [ngClass]="{ 'is-invalid':submitted && f.costiFatturatoVariazioneAnnoPrecedente.errors,'warning-control':warningCostiFatturatoVariazioneAnnoPrecedente }"
                            (keyup)="checkValMax('costiFatturatoVariazioneAnnoPrecedente')" />
                        <div *ngIf=" submitted && f.costiFatturatoVariazioneAnnoPrecedente.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.costiFatturatoVariazioneAnnoPrecedente.errors.required">Campo obbligatorio
                            </div>
                            <div *ngIf="f.costiFatturatoVariazioneAnnoPrecedente.errors.max">Parametro fuori scala.
                                Inserire
                                un valore compreso tra 0 e {{valoreMax.costiFatturatoVariazioneAnnoPrecedente}}%</div>
                        </div>
                        <div *ngIf="warningCostiFatturatoVariazioneAnnoPrecedente" class="warning-label">
                            <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                            mantenere la variazione
                            dei costi sul fatturato sotto il {{valoreMax.costiFatturatoVariazioneAnnoPrecedente}}%
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12  margin-left-20 margin-top-20" *ngIf="(this.indicatoriGuidaVolatile && this.indicatoriGuidaVolatile.indicatoriGuida.costiFissi > 0) || f.costiFissi.value > 0">
                <div class="row margin-left-20">
                    <div class="col-4 col-xl-3"><label>Costi fissi annuali al 1° anno (€)</label>
                        <input formControlName="costiFissi" type="text" class="form-control" currencyMask
                            [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                            #costiFissi [ngClass]="{ 'is-invalid':submitted && f.costiFissi.errors }"
                            onkeyup="if(this.value<0){this.value= this.value *-1}" />
                        <div *ngIf=" submitted && f.costiFissi.errors" class="invalid-feedback">
                            <div *ngIf="f.costiFissi.errors.required">Campo obbligatorio</div>

                        </div>
                    </div>
                    <div class="col-4 col-xl-3">
                        <label>Variazione su anno precedente
                            (%)</label>
                        <input formControlName="costiFissiVariazioneAnnoPrecedente" type="number" class="form-control"
                            [ngClass]="{ 'is-invalid':submitted && f.costiFissiVariazioneAnnoPrecedente.errors,'warning-control':warningCostiFissiVariazioneAnnoPrecedente }"
                            (keyup)="checkValMax('costiFissiVariazioneAnnoPrecedente')" />
                        <div *ngIf=" submitted && f.costiFissiVariazioneAnnoPrecedente.errors" class="invalid-feedback">
                            <div *ngIf="f.costiFissiVariazioneAnnoPrecedente.errors.required">Campo obbligatorio</div>
                        </div>
                        <div *ngIf="warningCostiFissiVariazioneAnnoPrecedente" class="warning-label">
                            <strong style="font-size: 1em">Warning!</strong><br>Valore elevato. Consigliamo di
                            mantenere la variazione
                            sui costi fissi sotto il {{valoreMax.costiFissiVariazioneAnnoPrecedente}}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row d-flex flex-column margin-top-20">
            <div class="col-12 d-flex align-items-center">

                <h2>Pianificazione incassi/pagamenti per arco temporale:</h2>
            </div>
        </div>
        <table class="table table-borderless">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">% a meno di 30 giorni</th>
                    <th scope="col">% tra 30 e 60 giorni</th>
                    <th scope="col">% tra 60 e 90 giorni</th>
                    <th scope="col">% tra 90 e 120 giorni</th>
                    <th scope="col">% tra 120 e 150 giorni</th>
                    <th scope="col">% a più di 150 giorni</th>

                </tr>
            </thead>
            <tbody>
                <tr [formGroup]="giorniIncassoCrediti"
                    [ngClass]="{'border': !sommaCentoCrediti,'border-danger': !sommaCentoCrediti}">
                    <th scope="row" class="align-middle text-center" style="width: 15%">Giorni incasso
                        crediti
                    </th>
                    <td><input formControlName="a30Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a30Giorni.errors }" />
                        <div *ngIf="submitted && giorniIncassoCrediti.controls.a30Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniIncassoCrediti.controls.a30Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="a60Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a60Giorni.errors }" />
                        <div *ngIf="submitted && giorniIncassoCrediti.controls.a60Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniIncassoCrediti.controls.a60Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="a90Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a90Giorni.errors }" />
                        <div *ngIf="submitted && giorniIncassoCrediti.controls.a90Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniIncassoCrediti.controls.a90Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="a120Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a120Giorni.errors }" />
                        <div *ngIf="submitted && giorniIncassoCrediti.controls.a120Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniIncassoCrediti.controls.a120Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="a150Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.a150Giorni.errors }" />
                        <div *ngIf="submitted && giorniIncassoCrediti.controls.a150Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniIncassoCrediti.controls.a150Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="oltre150Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniIncassoCrediti.controls.oltre150Giorni.errors }" />
                        <div *ngIf="submitted && giorniIncassoCrediti.controls.oltre150Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniIncassoCrediti.controls.oltre150Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
        <div class="row">
            <div *ngIf="!sommaCentoCrediti" class="erroreCrediti">
                La somma delle percentuali dei crediti deve essere 100%
            </div>
        </div>
        <table class="table table-borderless">
            <tbody>
                <tr [formGroup]="giorniPagamentoFornitori"
                    [ngClass]="{'border': !sommaCentoPagamenti,'border-danger': !sommaCentoPagamenti}">
                    <th scope="row" class="align-middle text-center" style="width: 15%">Giorni
                        pagamento
                        fornitori
                    </th>
                    <td><input formControlName="a30Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a30Giorni.errors}" />
                        <div *ngIf="submitted && giorniPagamentoFornitori.controls.a30Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniPagamentoFornitori.controls.a30Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="a60Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a60Giorni.errors}" />
                        <div *ngIf="submitted && giorniPagamentoFornitori.controls.a60Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniPagamentoFornitori.controls.a60Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="a90Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a90Giorni.errors}" />
                        <div *ngIf="submitted && giorniPagamentoFornitori.controls.a90Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniPagamentoFornitori.controls.a90Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="a120Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a120Giorni.errors }" />
                        <div *ngIf="submitted && giorniPagamentoFornitori.controls.a120Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniPagamentoFornitori.controls.a120Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="a150Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.a150Giorni.errors }" />
                        <div *ngIf="submitted && giorniPagamentoFornitori.controls.a150Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniPagamentoFornitori.controls.a150Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>
                    <td><input formControlName="oltre150Giorni" type="number" class="form-control" min=0
                            onkeyup="if(this.value<0){this.value= this.value *-1}"
                            [ngClass]="{ 'is-invalid':submitted && giorniPagamentoFornitori.controls.oltre150Giorni.errors }" />
                        <div *ngIf="submitted && giorniPagamentoFornitori.controls.oltre150Giorni.errors"
                            class="invalid-feedback">
                            <div *ngIf="giorniPagamentoFornitori.controls.oltre150Giorni.errors.required">Campo
                                obbligatorio
                            </div>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
        <div class="row">
            <div *ngIf="!sommaCentoPagamenti" class="errorePagamenti">
                La somma delle percentuali dei pagamenti deve essere 100%
            </div>
        </div>
        <hr>

        <div *ngIf="correlazioneFormArray.length > 0">
            <div class="row d-flex flex-column margin-top-20">
                <div class="col-12 d-flex align-items-center">
                    <h2>Configurazione correlazioni tra le linee di business:&nbsp;&nbsp;</h2>
                </div>
            </div>
            <div class="row">
                <div *ngIf="corrMistePerLegenda.length > 0 " class="col-9"> Correlazioni di ambiente:
                    <h2 *ngFor="let linea of lineeBusinessCorrelazioniOrdinato| slice:1; index as i" class="legC">
                        {{lineeBusinessCorrelazioniOrdinato[0]?.descrizione}}-{{lineeBusinessCorrelazioniOrdinato[i+1]?.descrizione}}
                        :
                        {{listaTipoCorrelazioniTraLinee[corrMistePerLegenda[i]-1]?.descrizione}}
                    </h2>

                </div>
                <div class="col-3 btn-toolbar">
                    <button class="btn btn-primary corrAct" (click)="onResetDefault()">Imposta con dati
                        ambiente</button>
                </div>


            </div>


            <div formArrayName="correlazioniBusinessPlan" class="row">
                <div *ngFor="let linea of correlazioneFormArray; index as i" [formGroupName]="i" class="linea"
                    [ngClass]="{'col-3':this.correlazioneFormArray.length == 4, 'col-4': this.correlazioneFormArray.length <4 }">
                    <label> Seleziona la correlazione numero {{i+1}}</label>


                    <select #couple type="text" class="form-control form-select  " formControlName="correlazione"
                        [ngClass]="{ 'is-invalid':submitted && correlazioneFormArray[i].controls.correlazione.errors }"
                        (change)="onChangeCouple(couple.value,i)">
                        <option value="" [selected]="cleanOpt(i,'correlazione')" [hidden]="true"> Seleziona un valore...
                        </option>
                        <option style="font-weight: bold;" *ngFor="let el of cartProductCorr;index as k"
                            value='{{[el.uno.id,el.due.id]}}'
                            [hidden]="i>0 && (!filterOtherByFirst(this.firstLb,el.uno.id) || filterNext(this.otherLb,el.due.id,i))"
                            [selected]="presetSelect(i,[el.uno.id,el.due.id],'label')">
                            {{el.uno.descrizione}}-{{el.due.descrizione}}
                        </option>
                    </select>

                    <div class="row" style="min-height: 25px;color:red;">
                        <div class="testo" *ngIf="submitted && correlazioneFormArray[i].controls.correlazione.errors">
                            Campo obbligatorio
                        </div>
                    </div>


                    <select #corr type="text" class="form-control form-select margin-top-10"
                        [ngClass]="{ 'is-invalid':submitted && correlazioneFormArray[i].controls.valoreCorrelazione.errors }"
                        formControlName="valoreCorrelazione" (change)="cambiaOpt(corr.value,i)">
                        <option value="" [selected]="cleanOpt(i,'valoreCorrelazione')" [hidden]="true"> Seleziona un
                            valore... </option>
                        <option style="font-weight: bold;" *ngFor="let dc of listaTipoCorrelazioniTraLinee;index as l"
                            value="{{dc.id}}" [hidden]="!checkAble(dc.id,i)"
                            [selected]="presetSelect(i,dc.id,'NOTlabel')">
                            {{dc.descrizione}}
                        </option>
                    </select>
                    <div class="row" style="min-height: 25px;color:red;">
                        <div class="testo"
                            *ngIf="submitted && correlazioneFormArray[i].controls.valoreCorrelazione.errors">
                            Campo obbligatorio
                        </div>
                    </div>


                </div>
            </div>
            <hr>

        </div>

    </div>
</div>