import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CrService } from 'src/app/cr.service';
import { BilancioIntestatarioService } from 'src/app/_services/bilancio_intestatario.service';
import { DatiQuestionarioService } from 'src/app/_services/dati-questionario.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { environment } from 'src/environments/environment';
import { MatCommonModule, MatNativeDateModule } from '@angular/material/core';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { PraticheNuova } from 'src/app/interface/Pratiche-nuova';
import { BilancioService } from 'src/app/_services/bilancio.service';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { ToolTip } from 'src/app/interface/toolTip';
import { ConfigurazioneBusinessPlanService } from './../../_services/configurazione-business-plan.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dossier-amministrativi',
  templateUrl: './dossier-amministrativi.component.html',
  styleUrls: ['./dossier-amministrativi.component.css'],
})
export class DossierAmministrativiComponent implements OnInit, OnDestroy {
  /* @Input('formGroup') dossierAmministrativiForm: FormGroup; */
  @Input() dossierAmministrativiForm: UntypedFormGroup;
  //@Input() dataBilObj: any

  @Output() registraBilancio = new EventEmitter();
  @Output() registraCr = new EventEmitter();

  @Input() get idRichiedenteTemp(): number {
    return this._idRichiedenteTemp;

  }
  set idRichiedenteTemp(idRichiedenteTemp: number) {
    this._idRichiedenteTemp = idRichiedenteTemp;
    this.ngOnInit()
  }
  dataBilObj: any;
  dataCrObj: any
  private _idRichiedenteTemp: number;
  submitted = false;
  selectedIndex = 0; /* serve per farlo partire da bilancio */

  listaBilanci: any[] = [];
  bilancioCaricato: File;
  crCaricato: File;
  listaCr: any[] = [];
  faTrashAlt = faTrashAlt;


  /* varibili upload file */
  fileBilancio: File;
  fileCr: File;
  errorBilancio: string;
  errorCr: string
  messaggioerroreBilancio: string = ""
  buttonDisabledBilancio = true
  buttonDisabledCr = true
  messaggioerroreCr: string = ""
  annoBilancio: number;
  annoCr: number
  dataInserimentoCr: string
  dataInserimentoBilancio: string;
  tipoInserimentoBil: string;
  tipoInserimentoCr: string
  currentData = new Date().getFullYear()
  listaTips: ToolTip[] = [];
  warningBilancioVecchio: boolean = false
  diffAnni: number = 0
  currentDataBilObjSub: Subscription
  tipsSubscription: Subscription
  messaggioSuccessoCr: any = "";

  constructor(
    private crService: CrService,
    private bilancioService: BilancioService,
    private formBuilder: UntypedFormBuilder,
    private toolTipService: ToolTipService,
    private bpService: ConfigurazioneBusinessPlanService,
    private router: Router) {


  }
  get f() { return this.dossierAmministrativiForm.controls; }
  ngOnDestroy() {
    if (this.dataBilObj != null) {
      this.currentDataBilObjSub.unsubscribe()
    }
    this.tipsSubscription.unsubscribe()
  }
  ngOnInit(): void {
    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })
    if (this._idRichiedenteTemp != undefined) {

      this.dossierAmministrativiForm = this.formBuilder.group({
        inserisciBilancio: ['', Validators.required],
        caricaBilancio: [''],
        bilancio: [''],
        inserisciCr: ['', Validators.required],
        cr: [''],
      })

      this.currentDataBilObjSub = this.bilancioService.currentDataBilObj.subscribe(obj => {
        this.dataBilObj = obj
        //console.log(this.dataBilObj)
        this.getDataBilancio()
      })
      this.bilancioService.getDataLastCr(this._idRichiedenteTemp).subscribe(obj => {
        this.dataCrObj = obj
        if (obj != null) {
          this.annoCr = this.dataCrObj;
        }
      })
    }
  }

  getDataBilancio() {
    //this.warningBilancioVecchio = false
    this.tipoInserimentoBil = ''
    this.annoBilancio = 0
    this.diffAnni = 0

    if (this.dataBilObj != null) {
      /* azienda vecchia */
      this.annoBilancio = this.dataBilObj.anno;
      let arrayData = this.dataBilObj.dataCreazione.split('-');
      this.dataInserimentoBilancio = arrayData[2] + "/" + arrayData[1] + "/" + arrayData[0]
      this.tipoInserimentoBil = this.dataBilObj.tipoInserimento
      this.diffAnni = this.currentData - this.annoBilancio
      this.f.inserisciBilancio.setValue('')
      this.f.inserisciBilancio.enable()

      this.f.inserisciBilancio.updateValueAndValidity()
      //azienda nuova degli ultimi 2 anni
    } else {
      this.annoBilancio = 0;
      this.dataInserimentoBilancio = null
      this.tipoInserimentoBil = null
      this.f.inserisciBilancio.setValue(true)
      this.f.inserisciBilancio.disable()
      this.f.inserisciBilancio.updateValueAndValidity()

    }

  }
  onChangeBilancio() {
    this.f.inserisciBilancio.updateValueAndValidity()
    if (this.f.inserisciBilancio.value) {
      this.f.caricaBilancio.setValue(1)
    }
  }
  onChangeCr() {
    this.f.cr.setValidators(Validators.required)
    this.f.cr.updateValueAndValidity()
  }

  onChangeCaricaBilancio(tipoCaricamento: number) {
    this.f.caricaBilancio.updateValueAndValidity()
    if (tipoCaricamento == 1) {
      this.f.bilancio.setValidators(Validators.required)
      this.errorBilancio = null;
      this.messaggioerroreBilancio = ""
    } else if (tipoCaricamento == 2) {
      this.errorBilancio = null;
      this.f.bilancio.clearValidators()
    }
    this.f.bilancio.updateValueAndValidity();
  }

  getFileBilancio(event) {
    if (event.target.files.length > 1) {
      this.errorBilancio = "Non è possibile caricare più di un file."
      this.buttonDisabledBilancio = true;
    } else {
      let fileName = event.target.files[0].name
      /* console.log(fileName) */
      let split = fileName.split('.');/* spezzo il nome al . nell array split */
      let ext = split[split.length - 1];
      if (ext.toLowerCase() != 'xbrl') {
        this.buttonDisabledBilancio = true;
        this.errorBilancio = 'Il file non è compatibile. Carica un file .xbrl'
      } else {
        this.errorBilancio = null;
        this.buttonDisabledBilancio = false;
        this.fileBilancio = event.target.files[0]

      }
    }

  }

  getFileCr(event) {
    if (event.target.files.length > 1) {
      this.errorCr = "Non è possibile caricare più di un file."
      this.buttonDisabledCr = true;
    } else {
      let fileName = event.target.files[0].name
      /* console.log(fileName) */
      let split = fileName.split('.');/* spezzo il nome al . nell array split */
      let ext = split[split.length - 1];
      if (ext.toLowerCase() != 'pdf') {
        this.buttonDisabledCr = true;
        this.errorCr = 'Il file non è compatibile. Carica un file .pdf'
      } else {
        this.errorCr = null;
        this.buttonDisabledCr = false;
        this.fileCr = event.target.files[0]

      }
    }
  }
  CaricaBilancio() {
    const formdata: FormData = new FormData();
    formdata.append('file', this.fileBilancio);
    formdata.append('idRichiedente', (this._idRichiedenteTemp).toString());
    this.bilancioCaricato = null
    this.messaggioerroreBilancio = ""
    this.bilancioService.caricaXbrl(formdata).subscribe(response => {
      if (response.response == "salvato con successo") {
        this.bilancioService.callPython().subscribe((response1) => {
          if (response1.response == "successo") {
            this.bilancioCaricato = this.fileBilancio
            this.getDataBilancio()
            this.bpService.aggiornaListaBp(this._idRichiedenteTemp)
          } else {
            //messaggio errorBilancioe in response.errorBilancioe
            this.messaggioerroreBilancio = "Errore nel caricamento. Contattare l'amministrazione."
            /* implementare chiamata per cancellare il record nella tabella ga_xbrl */
          }
        })

      } else {
        this.messaggioerroreBilancio = "Errore nel caricamento"
      }
    });

  }

  caricaCr() {
    const formdata: FormData = new FormData();
    formdata.append('file', this.fileCr);
    formdata.append('idRichiedente', (this._idRichiedenteTemp).toString());
    this.crCaricato = null
    this.messaggioerroreCr = ""
    this.bilancioService.caricaCr(formdata).subscribe(response => {
      if (response.response == "Centrale rischi salvata correttamente") {
        this.messaggioerroreCr = "";
        this.bilancioService.getDataLastCr(this._idRichiedenteTemp).subscribe(obj => {
          this.dataCrObj = obj
          if (obj != null) {
            this.annoCr = this.dataCrObj;
            this.messaggioSuccessoCr = response.response;
          }
        })
      
      }
      else{
        this.messaggioerroreCr = response.response;
        this.messaggioSuccessoCr = "";

      }
    });

  }


  deleteFileBilancio() {
    this.bilancioCaricato = null
  }

  deleteFileCr() {
    this.crCaricato = null
  }



  goToBilancio() {
    /* emetto l'evento per far partire il service di caricamento dei dati da conf. ambiente */
    //
    this.router.navigate(['/bilancio']);
  }


}
