import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AllDataResults, datoAlimentazione, regione, RisultatiBenchDTO, Tipo, UserKey, ValoriMercato } from '../interfaces/user-key';
import { UserObject } from '../interfaces/user-key';
import { environment } from 'src/environments/environment';
import { BeanStatico } from '../condivisi/beanStatico';
import { BeanStaticoEsteso } from '../condivisi/beanStaticoEsteso';





const apiUrl = environment.apiUrlQuestionario;

@Injectable({
  providedIn: 'root'
})
export class PivaDataService {

  constructor(private http: HttpClient) { }


  takeBindedPartIvaByUser(Utente: UserKey): Observable<UserObject[]> {

    return this.http.post<UserObject[]>(apiUrl + '/fasGoAlgo/benchmark/getAllAziendeRichiedenti', Utente)

  }

  getCompetitors(Obj: UserObject): Observable<UserObject[]> {

    return this.http.post<UserObject[]>(apiUrl + '/fasGoAlgo/benchmark/getAllCompetitors', Obj)

  }


  getUserObjectData(Comps: UserObject[]): Observable<AllDataResults> {

    return this.http.post<AllDataResults>(apiUrl + '/fasGoAlgo/benchmark/getCompetitorsData', Comps)

  }


  getUserObjectDataFull(Comps: UserObject[]): Observable<AllDataResults> {

    return this.http.post<AllDataResults>(apiUrl + '/fasGoAlgo/benchmark/getCompetitorsDataFull', Comps)

  }

  getMarketDataByAteco(codiciAteco:BeanStaticoEsteso[]): Observable<ValoriMercato> {

    return this.http.post<ValoriMercato>(apiUrl + '/fasGoAlgo/benchmark/getMarketData', codiciAteco)

  }
  getMarketDataByAtecoFull(codiceAteco: string): Observable<ValoriMercato> {

    return this.http.post<ValoriMercato>(apiUrl + '/fasGoAlgo/benchmark/getMarketDataFull', codiceAteco)

  }


  getAllAlimentazioneMacchine() : Observable<Tipo[]>
  {
    return this.http.get<Tipo[]>(apiUrl + '/fasGoAlgo/benchmark/getAllTipoAlimentazione')
  }

  getAllVars() : Observable<any[]>
  {
    return this.http.get<any[]>(apiUrl + '/fasGoAlgo/benchmark/varlist')
  }

 

  getDatiGraficiMercatoPerRegione(idRegione:number) : Observable<datoAlimentazione[]>
  {
    return this.http.get<datoAlimentazione[]>(apiUrl + '/fasGoAlgo/benchmark/getDatiGraficiMercatoPerRegione/'+ idRegione)
  }

  getRegioni() : Observable<regione[]>
  {
    return this.http.get<regione[]>(apiUrl + '/fasGoAlgo/benchmark/getRegioni')
  }

  hasBpSaved(idBp: number): Observable<boolean>{
    return this.http.get<boolean>(apiUrl + '/fasGoAlgo/benchmark/hasbp/'+ idBp);
  }

}
