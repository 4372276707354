<div id="mySidenav" class="sidenav" 
    style="border-top-left-radius:0 !important;border-bottom-left-radius:0 !important;">
    <a id="clo-btn" class="closebtn" (click)="navLeft()" >
        <mat-icon class="material-icons-outlined arrow-left-button">arrow_right</mat-icon>
    </a>
    <a class="ancora-grafico">
        <app-doughnut-chart [zoomActiveLeft]="true"
         [viewportDevice]='this.device[0]'
         [valore_tip_dscr]="valore_tip_dscr"
         [name]="name_potenziometro_one "
         [lancetta]='lancetta_one'
         [rotate_principale]="rotate_dscr"
         [tips_type]="tips_types[0]">
        </app-doughnut-chart>  
    </a>
    <a class="ancora-grafico">
        <app-doughnut-chart [zoomActiveLeft]="true"
        [viewportDevice]='this.device[0]'
        [valore_tip_debitoria]="valore_tip_debitoria" 
        [name]="name_potenziometro_two" 
        [lancetta]='lancetta_two' 
        [rotate_debitoria]="rotate_debitoria" 
        [tips_type]="tips_types[1]">
    </app-doughnut-chart>
    </a>
    <a class="ancora-grafico">
        <app-doughnut-chart [zoomActiveLeft]="true"
        [viewportDevice]='this.device[0]' 
        [valore_tip_mcc]="valore_tip_mcc" 
        [name]="name_potenziometro_three" 
        [lancetta]='lancetta_three'
        [rotate_mcc]="rotate_mcc" 
        [tips_type]="tips_types[2]">
    </app-doughnut-chart>
    </a>
    <a class="ancora-grafico">
        <app-doughnut-chart [zoomActiveLeft]="true"
        [viewportDevice]='this.device[0]' 
        [valore_tip_garanzia]="valore_tip_garanzia" 
        [name]="name_potenziometro_four" 
        [lancetta]='lancetta_four' 
        [rotate_garanzia]="rotate_garanzia" 
        [tips_type]="tips_types[3]">
        </app-doughnut-chart>  
    </a>
    <a class="ancora-grafico">
        <app-doughnut-chart [zoomActiveLeft]="true"
        [viewportDevice]='this.device[0]'  
        [name]="name_potenziometro_five" 
        [lancetta]='lancetta_five' 
        [rotate_principale]="rotate_dscr_mock1">
        </app-doughnut-chart>  
    </a>
    <a class="ancora-grafico">
        <app-doughnut-chart [zoomActiveLeft]="true"
        [viewportDevice]='this.device[0]' 
        [name]="name_potenziometro_six" 
        [lancetta]='lancetta_six' 
        [rotate_principale]="rotate_dscr_mock2">
        </app-doughnut-chart>  
    </a>
  </div>