import { Component, OnInit, ViewChild } from '@angular/core';
import { DistintaBaseProdottiComponent } from '../configurazione-ambiente/distinta-base-prodotti/distinta-base-prodotti.component';
import { ParametriGeneraliComponent } from '../configurazione-ambiente/parametri-generali/parametri-generali.component';
import { LineeBusinessComponent } from '../configurazione-ambiente/linee-business/linee-business.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BilancioService } from '../_services/bilancio.service';
import { ConfigurazioneAmbienteService } from '../_services/configurazione-ambiente.service';
import { RichiedenteService } from '../_services/richiedente.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { ToolTipService } from '../_services/tool-tip.service';
import { DossierAmministrativiComponent } from '../configurazione-ambiente/dossier-amministrativi/dossier-amministrativi.component';
import { SituazioneCreditiziaAmbienteComponent } from '../configurazione-ambiente/situazione-creditizia-ambiente/situazione-creditizia-ambiente.component';
import { SituazioneDebitoriaAmbienteComponent } from '../configurazione-ambiente/situazione-debitoria-ambiente/situazione-debitoria-ambiente.component';
import { Richiedente } from '../interface/richiedente';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';

@Component({
  selector: 'app-smart-bp',
  templateUrl: './smart-bp.component.html',
  styleUrls: ['./smart-bp.component.css']
})
export class SmartBpComponent implements OnInit {

  selectedIndex = 0;
  submitted = false;
  codiceAtecoPrimario: string;
  isStartUp: boolean = false;
  timer: any = 0
  aziendaSelected: boolean = false;
  configurazioneAmbienteForm: UntypedFormGroup
  currentAnno = new Date().getFullYear()
  listaNomiAzienda: string[] = [];
  richiedenteTemp: Richiedente;
  //dataBilObj: any = null;
  //ciccio: FormControl = new FormControl();
  listaRichiedenti: Richiedente[] = [];
  listaTipiAzienda = [];
  listaClassiAddetti = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private bilancioService: BilancioService,
    private tokeStorageService: TokenStorageService,
    private richiedenteService: RichiedenteService,
    private toolTipService: ToolTipService,
    private router: Router) {
    /* form per nome azienda */

    this.configurazioneAmbienteForm = this.formBuilder.group({
      nomeAzienda: ['', Validators.required],
      dossierAmministrativi: this.formBuilder.group({})
    })
  }
  get currentUser() { return this.tokeStorageService.getUser() }
  get f() { return this.configurazioneAmbienteForm.controls }
  
  ngOnInit(): void {
        //tipo azienda, forma giuridica
        this.richiedenteService.getListaTipiAzienda().subscribe(data => {
          this.listaTipiAzienda = data;
        });
    
        //classi numero addetti
        this.richiedenteService.getListaClassiAddetti().subscribe(data => {
          this.listaClassiAddetti = data;
        })

    this.toolTipService.getAllTipsContent('CAM')//carico tutti i tip di GBP sul service
    /* cambiare chiamata inserendo il check sul bilancio */
    this.richiedenteService.getAnagraficaRichiedenteByidCliente().subscribe(
      response => {
        if (response.length > 0) {
          this.listaRichiedenti = response;  //vediamo se mi serve
          response.forEach((element) => {
            this.listaNomiAzienda.push(element.ragioneSociale)
          })
          /* setta l'id e docice ateco per l'azineda singola */
          if (response.length == 1) {
            this.f.nomeAzienda.setValue(response[0].ragioneSociale);
            this.f.nomeAzienda.disable();
            this.aziendaSelected = true
            this.richiedenteTemp = response[0];
            this.codiceAtecoPrimario = response[0].codiceAtecoPrimario;
            this.isStartUp = response[0].isStartUp
            this.bilancioService.getDataLastBilancio(this.richiedenteTemp.id).subscribe(checkBil => {
              if (checkBil != null) {
                this.bilancioService.loadDataBilObj(checkBil)
                    this.bilancioService.getAziendaRichiedente(response[0])
          }
        })
        
      }
    }
  });

  }

  unlock() {
    this.f.nomeAzienda.enable();
    this.aziendaSelected = false;


    this.timer = setTimeout(() => {

      this.f.nomeAzienda.disable(),
        this.aziendaSelected = true;
      this.f.nomeAzienda.updateValueAndValidity()
    }, 7000)
    this.f.nomeAzienda.updateValueAndValidity()
  }

  onChangeNomeAzienda() {
    clearTimeout(this.timer)

    for (let i = 0; i < this.listaRichiedenti.length; i++) {
      if (this.f.nomeAzienda.value === this.listaRichiedenti[i].ragioneSociale) {
        this.richiedenteTemp = this.listaRichiedenti[i];

        //this.selectedIndex = 0;
        /*
          this.bilancioService.getDataLastBilancio(this.listaRichiedenti[i].id).subscribe(checkBil => {
            if (checkBil != null) {
              
                
                //this.dataBilObj = checkBil
                this.bilancioService.loadDataBilObj(checkBil)
                this.codiceAtecoPrimario = this.listaRichiedenti[i].codiceAtecoPrimario;
                this.isStartUp = this.listaRichiedenti[i].isStartUp

                this.bilancioService.getAziendaRichiedente(this.listaRichiedenti[i])
                this.aziendaSelected = true;
              }

          })
            */
        }

      }


    }
    
    censimentoSmart(){
      this.router.navigate(['/censimentoSmart']);
  
    }
}




