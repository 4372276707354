<div class="container-fluid questionario-cointainer">
    <!-- <app-header></app-header> -->
    <div class="loading-container" *ngIf="isLoading">
        <div class="spinner-border text-primary" style="width: 50px; height: 50px" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="animated fadeInDown sticky" id="navbar" style="z-index: 1000;">
        <div class="row mb-3">
            <div class="col-12 nopadding">
                <ul class="progress-indicator" style="width: 100%; margin-bottom: 0px">
                    <li [ngClass]="[
                    anagraficaCompleted ? 'completed' : '',
                    !anagraficaCompleted && submitted ? 'danger' : '',
                    ]" (click)="onClick(1)" class="pointer">
                        <span class="bubble"></span>
                        <span [ngClass]="[stepIndex == 1 ? 'font-bold font-black' : '']">Azienda osservata</span>
                    </li>
                    <li [ngClass]="[
                        dossierAmministrativiCompleted ? 'completed' : '',
                        !dossierAmministrativiCompleted && submitted ? 'danger' : '',
                        ]" (click)="onClick(2)" class="pointer">
                        <span class="bubble"></span>
                        <span [ngClass]="[stepIndex == 2 ? 'font-bold font-black' : '']">Dossier Amministrativi</span>
                    </li>
                    <li [ngClass]="[
                     finanziamentoCompleted ? 'completed' : '',
                    !finanziamentoCompleted && submitted ? 'danger' : ''
                    ]" (click)="onClick(3)" class="pointer">
                        <span class="bubble"></span>
                        <span [ngClass]="[stepIndex == 3 ? 'font-bold font-black' : '']">Piano investimenti</span>
                    </li>
                    <li [ngClass]="[
                    garanzieCompleted ? 'completed' : '',
                    !garanzieCompleted && submitted ? 'danger' : '',
                    ]" (click)="onClick(4)" class="pointer">
                        <span class="bubble"></span>
                        <span [ngClass]="[stepIndex == 4 ? 'font-bold font-black' : '']">Piano finanziamento
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>



    <form [formGroup]="formTotale" (ngSubmit)="onSubmit()">
        <div class="row margin-0">
            <div class="col-12 nopadding" [hidden]="stepIndex != 1">
                <!-- <qs-anagrafica-questionario
                [formGroup]="anagraficaForm"             
                (registraCr)="goToCr()"
                (registraBilancio)="goToBilancio()"
              ></qs-anagrafica-questionario> -->
                <app-questionario-anagrafica [formGroup]="anagraficaForm">
                </app-questionario-anagrafica>
            </div>
            <div class="col-12 nopadding" [hidden]="stepIndex != 2">
                <app-dossier-amministrativi [formGroup]="dossierAmministrativiForm" (registraCr)="goToCr()"
                    (registraBilancio)="goToBilancio()">

                </app-dossier-amministrativi>
            </div>

            <div class="col-12 nopadding" [hidden]="stepIndex != 3">
                <qs-dati-investimento [formGroup]="investimentoForm" (addProdotto)="addProdProgetto()"
                    (removeProdotto)="removeProdProgetto($event.valueOf())"></qs-dati-investimento>
            </div>
            <!--
          <div class="col-12 nopadding" [hidden]="stepIndex != 3">
            <qs-indebitamento [formGroup]="indebitamentoForm" ></qs-indebitamento>
          </div>
            -->
            <div class="col-12 nopadding" [hidden]="stepIndex != 4">
                <qs-garanzie [formGroup]="garanzieForm"></qs-garanzie>
            </div>

            <div class="col-12" style="padding-top:15px">
                <button class="btn btn-secondary pull-left align-middle d-flex flex-row align-items-center"
                    type="button" style="padding-top:0px; padding-bottom: 0px;" (click)="indietro()"
                    *ngIf="stepIndex > 1">
                    <fa-icon style="font-size: 25px; margin-right: 10px;" [icon]="faAngleDoubleLeft"></fa-icon>
                    <p class="margin-0">indietro</p>
                </button>
                <button class="btn btn-secondary pull-right align-middle d-flex flex-row align-items-center"
                    type="button" style="padding-top:0px; padding-bottom: 0px;" [disabled]="formCompleted"
                    (click)="avanti()" *ngIf="stepIndex < 4">
                    <p class="margin-0 margin-left-10">avanti</p>
                    <fa-icon style="font-size: 25px; margin-left: 10px;" [icon]="faAngleDoubleRight"></fa-icon>
                </button>
            </div>

            <div class="col-12 text-center" style="margin: 20px 0;" [hidden]="stepIndex != 4">
                <button class="btn btn-primary">Calcola</button>
                <button class="btn btn-primary" style="margin-left: 5px; border-color: #1658b5; background: #1658b5"
                    *ngIf="anagraficaForm.controls.nomeAzienda.value != ''" type="button"
                    (click)="openModal()">Salva</button>
                <button class="btn btn-secondary" style="margin-left: 5px" type="button" (click)="onReset()">
                    Annulla
                </button>
            </div>
        </div>
    </form>

</div>

<!-- nuova nav bar da sistemare -->
<!-- <div class="row">
    <div class="col">
        <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
            <div class="timeline-step">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2003">
                    <div class="inner-circle"></div>
                    <p class="h6 text-muted mb-0 mb-lg-0">Favland Founded</p>
                </div>
            </div>
            <div class="timeline-step">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2004">
                    <div class="inner-circle"></div>
                    <p class="h6 text-muted mb-0 mb-lg-0">Launched Trello</p>
                </div>
            </div>
            <div class="timeline-step">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2005">
                    <div class="inner-circle"></div>

                    <p class="h6 text-muted mb-0 mb-lg-0">Launched Messanger</p>
                </div>
            </div>
            <div class="timeline-step">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2010">
                    <div class="inner-circle"></div>
                    <p class="h6 mt-3 mb-1">2010</p>
                    <p class="h6 text-muted mb-0 mb-lg-0">Open New Branch</p>
                </div>
            </div>
            <div class="timeline-step mb-0">
                <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title=""
                    data-content="And here's some amazing content. It's very engaging. Right?"
                    data-original-title="2020">
                    <div class="inner-circle"></div>
                    <p class="h6 mt-3 mb-1">2020</p>
                    <p class="h6 text-muted mb-0 mb-lg-0">In Fortune 500</p>
                </div>
            </div>
        </div>
    </div>
</div> 

css
body {
margin-top: 20px;
}

.timeline-steps {
display: flex;
justify-content: center;
flex-wrap: wrap
}

.timeline-steps .timeline-step {
align-items: center;
display: flex;
flex-direction: column;
position: relative;
margin: 1rem
}

@media (min-width:768px) {
.timeline-steps .timeline-step:not(:last-child):after {
content: "";
display: block;
border-top: .25rem dotted #3b82f6;
width: 3.46rem;
position: absolute;
left: 7.5rem;
top: .3125rem
}

.timeline-steps .timeline-step:not(:first-child):before {
content: "";
display: block;
border-top: .25rem dotted #3b82f6;
width: 3.8125rem;
position: absolute;
right: 7.5rem;
top: .3125rem
}
}

.timeline-steps .timeline-content {
width: 10rem;
text-align: center
}

.timeline-steps .timeline-content .inner-circle {
border-radius: 1.5rem;
height: 1rem;
width: 1rem;
display: inline-flex;
align-items: center;
justify-content: center;
background-color: #3b82f6
}

.timeline-steps .timeline-content .inner-circle:before {
content: "";
background-color: #3b82f6;
display: inline-block;
height: 3rem;
width: 3rem;
min-width: 3rem;
border-radius: 6.25rem;
opacity: .5
}-->