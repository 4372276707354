import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BeanStaticoEsteso } from '../condivisi/beanStaticoEsteso';
import { MatCorr } from '../interface/correlazioni';

const apiUrl = environment.apiUrlQuestionario;

@Injectable({
  providedIn: 'root'
})
export class CorrelazioniService {

  
  constructor(private http: HttpClient) { }


  takeAllMatDesc(): Observable<BeanStaticoEsteso[]> {

    return this.http.get<BeanStaticoEsteso[]>(apiUrl + '/fasGoAlgo/correlazioni/getAllDescMatType')

  }


  takeAllCorrDesc(): Observable<BeanStaticoEsteso[]> {

    return this.http.get<BeanStaticoEsteso[]>(apiUrl + '/fasGoAlgo/correlazioni/getAllDescCorrelazioni')

  }

  getAmbienteValues(idRichiedente: number, numeroLinee:number): Observable<any> {

    return this.http.get<MatCorr>(apiUrl + '/fasGoAlgo/correlazioni/getDefaultCorrAmbiente/'+idRichiedente+"/"+numeroLinee)

  }


  checkCorrelazioni(idBusinessPlan: number, idRichiedente:number, NL:number): Observable<any> {

    return this.http.get<any>(apiUrl + '/fasGoAlgo/correlazioni/checkCustomCorr/'+idBusinessPlan+"/"+idRichiedente+"/"+NL)

  }

  getCorrelazioniMat(idMat:number): Observable<MatCorr> {

    return this.http.get<any>(apiUrl + '/fasGoAlgo/correlazioni/getCorrValue/'+idMat)

  }

  checkExistingMat(valori: MatCorr):Observable<number>{
    return this.http.post<number>(apiUrl + '/fasGoAlgo/correlazioni/checkExistingMat',valori)
  }


  checkOptions(valori: MatCorr):Observable<number[]>{
    return this.http.post<number[]>(apiUrl + '/fasGoAlgo/correlazioni/checkOptions',valori)
  }

  getValuesForAmbient(idRichiedente:number, idUtente: number, numeroLinee:number): Observable<any>{
    return this.http.get<any>(apiUrl + '/fasGoAlgo/configurazioneAmbiente/getCorrAmb/'+idRichiedente+"/"+idUtente +"/"+ numeroLinee )
  }
  

}


