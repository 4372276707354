<div class="row justify-content-center d-flex" style="padding: 10px 0;">
    <div class="col-11 header-top-info">
        <div class="row justify-content-center d-flex">
            <div class="col-4">

            </div>
            <div class="col-3">

            </div>
            <div class="col-4">

            </div>
        </div>
    </div>
    <div class="col-6 section-left-top-info">
        <div class="row" style="padding: 0 15px;">
            <div class="col-5" id="sud-section-top-info-one">
                
                <!--START 5 row sottotabella-->
                <!--1-->
                <div class="row">
                    <div class="col-12">

                    </div>
                </div>
                <!--2-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--3-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--4-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--5-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--END 5 row sottotabella-->

            </div>
            <div class="col-2"  id="sud-section-top-info-two">
                <!--START 5 row sottotabella-->
                <!--1-->
                <div class="row">
                    <div class="col-12">

                    </div>
                </div>
                <!--2-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--3-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--4-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--5-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--END 5 row sottotabella-->

            </div>
            <div class="col-5"  id="sud-section-top-info-three">
                <!--START 5 row sottotabella-->
                <!--1-->
                <div class="row">
                    <div class="col-12">

                    </div>
                </div>
                <!--2-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--3-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--4-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--5-->
                <div class="row">
                    <div class="col-12">
                        
                    </div>
                </div>
                <!--END 5 row sottotabella-->
            </div>
        </div>
    </div>
    <div class="col-5 section-right-top-info">
        <form>
            <div class="form-group">
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
          </form>
    </div>
</div>
