<div class="container-fluid no padding sticky-bp" id="configurazione-ambiente" [formGroup]="configurazioneSmartForm">
    <div class="card-header">
      <h2 style="margin-bottom:0;">Situazione finanziaria</h2>

    </div>
    <!-- selectedIndex proprietà delle mat tab -->
    <div><!-- class="card-body" -->

          <mat-tab-group mat-stretch-tabs (selectedIndex)="0" class="d-flex justify-content-around">
            <mat-tab label="Situazione creditizia">
              <app-situazione-creditizia-smart [creditiformGroup]="f.situazioneCreditizia"
                [idRichiedenteTemp]="idRichiedente">
              </app-situazione-creditizia-smart>
            </mat-tab>
            <mat-tab label="Situazione debitoria">
              <app-situazione-debitoria-smart [debitiformGroup]="f.situazioneDebitoria"
                [idRichiedenteTemp]="idRichiedente">
              </app-situazione-debitoria-smart>
            </mat-tab>
          </mat-tab-group>
  

    </div>
  </div>