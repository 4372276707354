import { Component, OnInit, Pipe, PipeTransform, HostListener, Inject, Injectable, ViewChild } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder, FormControl, NgForm } from '@angular/forms';
import { trigger, state, transition, style, animate } from '@angular/animations';

import { CommonModule, CurrencyPipe, DOCUMENT, DecimalPipe } from '@angular/common';
import { Prezzi } from './prezzi';
import { Costi } from './costi';
import { Rese } from './rese';

import { Gaussian } from 'ts-gaussian';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { merge, Observable, Subject } from 'rxjs';
import { faCaretDown, faCaretUp, faClosedCaptioning } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NgbModal, NgbModalConfig, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../_services/token-storage.service';
import { environment } from 'src/environments/environment';
//import { off } from 'process';
//import { Role } from '../../component/role';

@Component({
  selector: 'mv-prezziecosti',
  template: `
    <div class="container-fluid-center nopadding">
      <h3 class="text-center mb-3">Prezzi - Costi - Rese</h3>

      <!-- RICERCA -->
      <div class="animated fadeInDown" id="navbar" style="z-index: 1000;">
        <ng-container>
          <div>
            <div class="row form-group mt-n3">
              <div class="col-sm-12"><hr /></div>
            </div>
            <!-- START input categoria -->
            <div class="row form-group mt-n3">
              <div class="col-sm-1 text-right"><label>Categoria:</label></div>
              <div class="col-sm-3">
                <input type="text" id="categoriaRicerca" name="categoriaRicerca" class="form-control"
                  [ngbTypeahead]="searchCategorie" [inputFormatter]="formatter" [resultFormatter]="formatter"
                  (focus)="focusCategorie.next($any($event).target.value)"
                  (click)="clickCategorie.next($any($event).target.value)"
                  [(ngModel)]="categoria" [editable]="false"
                  (ngModelChange)="getCategoria($event)" #instance="ngbTypeahead" required />
              </div>
              <!-- END input categoria -->
              <!-- START input prodotto -->
              <div class="col-sm-1 text-right"><label>Prodotto:</label></div>
              <div class="col-sm-3">
                <input type="text" id="prodottoRicerca" name="prodottoRicerca" class="form-control"
                  [ngbTypeahead]="searchProdotti" [inputFormatter]="formatter" [resultFormatter]="formatter"
                  (focus)="focusProdotti.next($any($event).target.value)"
                  (click)="clickProdotti.next($any($event).target.value)" [disabled]="boolDisableProdotto"
                  [(ngModel)]="prodotto" [editable]="false"
                  (ngModelChange)="getProdotto($event)" #instance="ngbTypeahead" />
              </div>
              <!-- END input prodotto -->
              <div class="col-sm-1 text-right"><label>Varietà:</label></div>
              <div class="col-sm-3">
                <input type="text" id="prodottoVarietaRicerca" name="prodottoVarietaRicerca" class="form-control"
                  [ngbTypeahead]="searchVarietaProdotti" [inputFormatter]="formatter" [resultFormatter]="formatter"
                  (focus)="focusVarietaProdotti.next($any($event).target.value)"
                  (click)="clickVarietaProdotti.next($any($event).target.value)" [disabled]="boolDisableVarieta"
                  [(ngModel)]="varieta" [editable]="false" #instance="ngbTypeahead" />
              </div>
            </div>

            <div class="row form-group m-top">
              <div class="col-sm-1 text-right"><label>Provincia:</label></div>
              <div class="col-sm-3">
                <input type="text" id="provinciaRicerca" name="provinciaRicerca" class="form-control"
                  [ngbTypeahead]="searchProvince" [inputFormatter]="formatterProvince" [resultFormatter]="formatterProvince"
                  (focus)="focusProvince.next($any($event).target.value)"
                  (click)="clickProvince.next($any($event).target.value)"
                  [(ngModel)]="provincia" [editable]="false"
                  (ngModelChange)="getProvince($event)" #instance="ngbTypeahead" />
              </div>

              <div class="col-sm-1 text-right"><label>Comune:</label></div>
              <div class="col-sm-3">
                <input type="text" id="comuneRicerca" name="comuneRicerca" class="form-control"
                [ngbTypeahead]="searchComuni" [inputFormatter]="formatterComuni" [resultFormatter]="formatterComuni"
                (focus)="focusComuni.next($any($event).target.value)"
                (click)="clickComuni.next($any($event).target.value)" [disabled]="boolDisableComune"
                [(ngModel)]="comune" [editable]="false"
                #instance="ngbTypeahead" />
              </div>
              <div class="col-sm-1 text-center">
              </div>
              <div class="col-sm-3">
                <button type="button" class="btn btn-success btn-md mr-1 pull-right" [disabled]="!(comune && comune.id && varieta && varieta.id)" (click)="ricerca()">
                Cerca <img class="mb-0" style="height: 20px" src="{{ imgsearch }}" alt=""/>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- FINE RICERCA -->

      <!-- BUTTON PREZZI COSTI e RESE -->
      <ng-container>
        <div class="row form-group mt-n3">
          <div class="col-sm-12"><hr /></div>
        </div>

        <div class="row form-group">
          <div class="col-sm-6 border-left text-center">
            <button type="button" class="btn btn-warning btn-md mr-1" (click)="togglePrezzi()">
              Prezzi
            </button>
            <button type="button" class="btn btn-warning btn-md mr-1 ms-2" (click)="toggleCosti()">
              Costi &nbsp;
            </button>
            <button type="button" class="btn btn-warning btn-md mr-1 ms-2" (click)="toggleResa()" >
              Rese &nbsp;
            </button>
            &nbsp;<img class="mb-0 mt-n1" src="{{ imgaccounting }}" alt=""/>
          </div>

          <div class="col-sm-6 border-left text-center">
            <button type="button" [disabled]="!pagina.length" class="btn btn-primary btn-md ms-1 ms-2" (click)="toggleValueML()">
              Dati riferimento
            </button>
            &nbsp;<img class="mb-0 mt-n1" src="{{ imgabout }}" alt=""/>
          </div>
        </div>
      </ng-container>
      <!-- FINE BUTTON PREZZI COSTI e RESE -->

      <div class="row form-group mt-n3">
        <div class="col-sm-12"><hr /></div>
      </div>

      <!-- FORM PREZZI -->
      <form #f="ngForm" name="form" novalidate
        (ngSubmit)="(f.form.valid) && onClick(f,contentPrezzoInserito,contentPrezzoNonInseritoCorrettamente)">
        <ng-container *ngIf="showPrezzi">
          <div class="row text-center mb-2">
            <div class="col-sm-12"><label>P R E Z Z I</label></div>
          </div>
          <div class="row">
            <!-- COLONNA DI SINISTRA - PREZZI -->
            <div class="col-sm-6">

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Qualità Produzione:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="qualitaProduzione" name="qualitaProduzione" class="form-control"
                    [(ngModel)]="form.qualitaProduzione" [inputFormatter]="formatter" [resultFormatter]="formatter"
                    (focus)="focusQualitaProduzione.next($any($event).target.value)"
                    (click)="clickQualitaProduzione.next($any($event).target.value)"
                    (selectItem)="getQualita($event)" #qualitaProduzione="ngModel" #instance="ngbTypeahead"
                    [ngbTypeahead]="searchQualitaProduzione" required />
                  <div class="alert-danger" *ngIf="f.submitted && qualitaProduzione.invalid">
                    <div *ngIf="qualitaProduzione.errors.required">
                      Qualità Produzione è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Tipo Prezzo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="tipoPrezzo" name="tipoPrezzo" class="form-control"
                    [(ngModel)]="form.tipoPrezzo" [inputFormatter]="formatter" [resultFormatter]="formatter"
                    (focus)="focusTipoPrezzo.next($any($event).target.value)"
                    (click)="clickTipoPrezzo.next($any($event).target.value)"
                    (selectItem)="getTipoPrezzo($event)" #tipoPrezzo="ngModel" #instance="ngbTypeahead"
                    [ngbTypeahead]="searchTipoPrezzo" required />
                  <div class="alert-danger" *ngIf="f.submitted && tipoPrezzo.invalid">
                    <div *ngIf="tipoPrezzo.errors.required">
                      Tipo Prezzo è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Prezzo Iniziale:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="prezzoIniziale" name="prezzoIniziale" class="form-control"
                    [(ngModel)]="form.prezzoInizialeConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getPrezzoIniziale($event)" #prezzoInizialeConvertito="ngModel" required />
                  <div class="alert-danger" *ngIf="f.submitted && prezzoInizialeConvertito.invalid">
                    <div *ngIf="prezzoInizialeConvertito.errors.required">
                      Prezzo Iniziale è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="this.PrezzoInizMagPrezzoMin">
                      Prezzo Iniziale non può essere minore del Prezzo Minimo o maggiore del Prezzo Massimo
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="this.prezzoInizialeIsNumber">
                      Prezzo Iniziale deve essere un numero
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Prezzo Massimo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="prezzoMassimo" name="prezzoMassimo" class="form-control"
                    [(ngModel)]="form.prezzoMassimoConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getPrezzoMassimo($event)" #prezzoMassimoConvertito="ngModel" required />
                  <div class="alert-danger" *ngIf="f.submitted && prezzoMassimoConvertito.invalid">
                    <div *ngIf="prezzoMassimoConvertito.errors.required">
                      Prezzo Massimo è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger" >
                    <div *ngIf="this.prezzoMassimoisNumber">
                      Prezzo Massimo deve essere un numero
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Prezzo Minimo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="prezzoMinimo" name="prezzoMinimo" class="form-control"
                    [(ngModel)]="form.prezzoMinimoConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getPrezzoMinimo($event)" #prezzoMinimoConvertito="ngModel" required />
                  <div class="alert-danger" *ngIf="f.submitted && prezzoMinimoConvertito.invalid">
                    <div *ngIf="prezzoMinimoConvertito.errors.required">
                      Prezzo Minimo è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="MinMagMax">
                      Il Prezzo Minimo non può essere maggiore del Prezzo Massimo
                    </div>
                    <div *ngIf="this.prezzoMinimoIsNumber">
                      Prezzo Minimo deve essere un numero
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FINE COLONNA DI SINISTRA - PREZZI -->

            <!-- COLONNA DI DESTRA - PREZZI -->
            <div class="col-sm-6 bordo-sx">
              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Drift %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="drift" name="drift" class="form-control"
                    [(ngModel)]="form.drift" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getDrift($event)" #drift="ngModel" required />
                  <div class="alert-danger" *ngIf="f.submitted && drift.invalid">
                    <div *ngIf="drift.errors.required">
                      Drift è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="this.driftPrezziIsNumber">
                      Drift deve essere un numero
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Scarto %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="scarto" name="scarto" class="form-control"
                    [(ngModel)]="form.scarto" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getScarto($event)" #scartoConvertito="ngModel" required
                    pattern="^[1-9]$|^[1-9][0-9]$|^(100)$" />
                  <div class="alert-danger" *ngIf="f.submitted && scartoConvertito.invalid">
                    <div *ngIf="scartoConvertito.errors.required">
                      Scarto è obbligatorio
                    </div>
                    <div *ngIf="scartoConvertito.errors.pattern">
                      Scarto deve contenere numeri da 0 a 100
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="this.scartoIsNumber">
                      Scarto deve essere un numero
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Anni Previsione Prezzo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="anniPrevisionePrezzo" name="anniPrevisionePrezzo" class="form-control"
                    [(ngModel)]="form.anniPrevisionePrezzo" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getAnniPrevisionePrezzo($event)" #anniPrevisionePrezzo="ngModel" required />
                  <div class="alert-danger" *ngIf="f.submitted && anniPrevisionePrezzo.invalid">
                    <div *ngIf="anniPrevisionePrezzo.errors.required">
                      Anni Previsione Prezzo è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Volatilità a Rialzo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="volatilitaRialzo" name="volatilitaRialzo" class="form-control"
                    [(ngModel)]="form.volatilitaRialzoFormattato" [readonly]="true" #volatilitaRialzo="ngModel" />
                 </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Volatilità a Ribasso:</label></div>
                <div class="col-sm-5">
                  <input type="text"
                    id="volatilitaRibasso" name="volatilitaRibasso" class="form-control"
                    [(ngModel)]="form.volatilitaRibassoFormattato" [readonly]="true" #volatilitaRibasso="ngModel" />
                </div>
              </div>
            </div>
            <!-- FINE COLONNA DI DESTRA - PREZZI -->
          </div>
          <!-- fine riga -->

          <!-- REGISTRA PREZZI -->
          <div class="row form-group m-top">
            <div class="col-sm-12">
              <button type="submit" class="btn btn-primary btn-md mr-1">
                Registra
              </button>
              <button class="btn btn-success btn-md mr-1" *ngIf="this.visibilitaElimina">
                Elimina
              </button>
            </div>
          </div>

          <div class="row form-group mt-n3">
            <div class="col-sm-12"><hr /></div>
          </div>
        </ng-container>
      </form>
      <!-- FINE FORM PREZZI -->

      <!-- FORM COSTI -->
      <form #fCosti="ngForm" name="formCosti" novalidate
        (ngSubmit)="(fCosti.form.valid) && onClickCosti(fCosti,contentCostoInserito,contentCostoNonInseritoCorrettamente)">
        <ng-container *ngIf="showCosti">
          <div class="row text-center mb-2">
            <div class="col-sm-12"><label>C O S T I</label></div>
          </div>
          <div class="row">
            <!-- COLONNA DI SINISTRA - COSTI -->
            <div class="col-sm-6">

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Costi Fissi Massimo x ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="costiFissiMassimo" name="costiFissiMassimo" class="form-control"
                    [(ngModel)]="formCosti.costiFissiMassimoXHaConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getCostiFissiMassimoXHa($event)" #costiFissiMassimoXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && costiFissiMassimoXHa.invalid">
                    <div *ngIf="costiFissiMassimoXHa.errors.required">
                      Costi Fissi Massimo x ha è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Costi Fissi Minimo x ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="costiFissiMinimo" name="costiFissiMinimo" class="form-control"
                    [(ngModel)]="formCosti.costiFissiMinimoXHaConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getCostiFissiMinimoXHa($event)" #costiFissiMinimoXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && costiFissiMinimoXHa.invalid">
                    <div *ngIf="costiFissiMinimoXHa.errors.required">
                      Costi Fissi Minimo x ha è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="CostiMinFisMagCostiMaxFis">
                      Costo fisso minimo non può essere maggiore del Costo fisso Massimo
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Costi Variabili Iniziali x ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="costiVariabiliIniziali" name="costiVariabiliIniziali" class="form-control"
                    [(ngModel)]="formCosti.costiVariabiliInizialiXHaConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getCostiVariabiliInizialiXHa($event)" #costiVariabiliInizialiXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && costiVariabiliInizialiXHa.invalid">
                    CostiVarInizXHaMagCostiVarMinXHa
                    <div *ngIf="costiVariabiliInizialiXHa.errors.required">
                      Costi Variabili Iniziali x ha è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="CostiVarInizXHaMagCostiVarMinXHa">
                      Costi Variabili Iniziali x ha non possono essere minori dei Costi Variabili Minimi x ha
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Costi Variabili Massimo x ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="costiVariabiliMassimo" name="costiVariabiliMassimo" class="form-control"
                    [(ngModel)]="formCosti.costiVariabiliMassimoXHaConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getCostiVariabiliMassimoXHa($event)" #costiVariabiliMassimoXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && costiVariabiliMassimoXHa.invalid">
                    <div *ngIf="costiVariabiliMassimoXHa.errors.required">
                      Costi Variabili Massimo x ha è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Costi Variabili Minimo x ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="costiVariabiliMinimo" name="costiVariabiliMinimo" class="form-control"
                    [(ngModel)]="formCosti.costiVariabiliMinimoXHaConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getCostiVariabiliMinimoXHa($event)" #costiVariabiliMinimoXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && costiVariabiliMinimoXHa.invalid">
                    <div *ngIf="costiVariabiliMinimoXHa.errors.required">
                      Costi Variabili Minimo x ha è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="MinVarMagMaxVar">
                      Costo variabile minimo non può essere maggiore del Costo variabile Massimo
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Scarto Costi %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="scartoCosti" name="scartoCosti" class="form-control"
                    [(ngModel)]="formCosti.scartoCosti" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getScartoCosti($event)" #scartoCosti="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && scartoCosti.invalid">
                    <div *ngIf="scartoCosti.errors.required">
                      Scarto Costi è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Drift Costi %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="driftCosti" name="driftCosti" class="form-control"
                    [(ngModel)]="formCosti.driftCosti" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getDriftCosti($event)" #driftCosti="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && driftCosti.invalid">
                    <div *ngIf="driftCosti.errors.required">
                      Drift Costi è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Anni Previsione Costi:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="anniPrevisioneCosti" name="anniPrevisioneCosti" class="form-control"
                    [(ngModel)]="formCosti.anniPrevisioneCosti" [ngModelOptions]="{ updateOn: 'blur' }"
                   (ngModelChange)="getAnniPrevisioneCosti($event)" #anniPrevisioneCosti="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && anniPrevisioneCosti.invalid">
                    <div *ngIf="anniPrevisioneCosti.errors.required">
                      Anni Previsione Costi è obbligatorio
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FINE COLONNA DI SINISTRA - COSTI -->

            <!-- COLONNA DI DESTRA - COSTI -->
            <div class="col-sm-6 bordo-sx">

              <div class="row form-group m-top">
                  <div class="col-sm-7 destra"><label>Costi Fatturato Massimo:</label></div>
                  <div class="col-sm-5">
                    <input type="text" id="costiFatturatoMassimo" name="costiFatturatoMassimo" class="form-control"
                      [(ngModel)]="formCosti.costiFatturatoMassimoConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                      (ngModelChange)="getCostiFatturatoMassimo($event)" #costiFatturatoMassimo="ngModel" required />
                    <div class="alert-danger" *ngIf="fCosti.submitted && costiFatturatoMassimo.invalid">
                      <div *ngIf="costiFatturatoMassimo.errors.required">
                        Costi Fatturato Massimo è obbligatorio
                      </div>
                    </div>
                  </div>
                </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Costi Fatturato Minimo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="costiFatturatoMinimo" name="costiFatturatoMinimo" class="form-control"
                    [(ngModel)]="formCosti.costiFatturatoMinimoConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getCostiFatturatoMinimo($event)" #costiFatturatoMinimo="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && costiFatturatoMinimo.invalid">
                    <div *ngIf="costiFatturatoMinimo.errors.required">
                      Costi Fatturato Minimo è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="CostiFatMinMagCostiFatMax">
                      Costo fatturato minimo non può essere maggiore del Costo fatturato Massimo
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Numero Piante Massimo x ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="numeroPianteMassimoXha" name="numeroPianteMassimoXha" class="form-control"
                    [(ngModel)]="formCosti.numeroPianteMassimoXHa" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getNumeroPianteMassimoXHa($event)" #numeroPianteMassimoXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && numeroPianteMassimoXHa.invalid">
                    <div *ngIf="numeroPianteMassimoXHa.errors.required">
                      Numero Piante Miassimo x ha è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Numero Piante Minimo x ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="numeroPianteMinimoXha" name="numeroPianteMinimoXha" class="form-control"
                    [(ngModel)]="formCosti.numeroPianteMinimoXHa" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getNumeroPianteMinimoXHa($event)" #numeroPianteMinimoXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && numeroPianteMinimoXHa.invalid">
                    <div *ngIf="numeroPianteMinimoXHa.errors.required">
                      Numero Piante Minimo x ha è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="NumPianteMinMagNumPianteMax">
                       Numero Piante minimo x HA non può essere maggiore del Numero Piante Massimo x HA
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Costi Pianta Massimo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="costoPianteMassimoXha" name="costoPianteMassimoXha" class="form-control"
                    [(ngModel)]="formCosti.costiPiantaMassimoConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getCostiPiantaMassimo($event)" #costiPiantaMassimo="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && costiPiantaMassimo.invalid">
                    <div *ngIf="costiPiantaMassimo.errors.required">
                      Costi Pianta Massimo è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Costi Pianta Minimo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="costoPianteMinimoXha" name="costoPianteMinimoXha" class="form-control"
                    [(ngModel)]="formCosti.costiPiantaMinimoConvertito" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getCostiPiantaMinimo($event)" #costiPiantaMinimo="ngModel" required />
                  <div class="alert-danger" *ngIf="fCosti.submitted && costiPiantaMinimo.invalid">
                    <div *ngIf="costiPiantaMinimo.errors.required">
                      Costi Pianta Minimo è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="CostiPiantaMinMagCostiPiantaMAx">
                      Costo pianta minimo non può essere maggiore del Costo pianta Massimo
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Volatilità Costi a Rialzo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="volatilitaCostiRialzo" name="volatilitaCostiRialzo" class="form-control"
                    [(ngModel)]="formCosti.volatilitaCostiRialzoFormattato" [readonly]="true"
                    #volatilitaCostiRialzo="ngModel" />
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Volatilità Costi a Ribasso:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="volatilitaCostiRibasso" name="volatilitaCostiRibasso" class="form-control"
                    [(ngModel)]="formCosti.volatilitaCostiRibassoFormattato" [readonly]="true"
                    #volatilitaCostiRibasso="ngModel" />
                </div>
              </div>
            </div>
            <!-- FINE COLONNA DI DESTRA - COSTI -->
          </div>
          <!-- fine riga -->

          <!-- REGISTRA COSTI -->
          <div class="row form-group m-top">
            <div class="col-sm-12">
              <button type="submit" class="btn btn-primary btn-md mr-1">
                Registra
              </button>
              <button class="btn btn-success btn-md mr-1" *ngIf="this.visibilitaElimina">
                Elimina
              </button>
            </div>
          </div>

          <div class="row form-group mt-n3">
            <div class="col-sm-12"><hr /></div>
          </div>
        </ng-container>
      </form>
      <!-- FINE FORM COSTI -->

      <!-- RESE -->
      <form #fRese="ngForm" name="formRese" novalidate
        (ngSubmit)="(fRese.form.valid) && onClickRese(fRese,contentReseInserite,contentReseNonInseritoCorrettamente)">
        <ng-container *ngIf="showResa">
          <div class="row text-center mb-2">
            <div class="col-sm-12"><label>R E S E</label></div>
          </div>
          <div class="row">
            <!-- COLONNA DI SINISTRA - RESE -->
            <div class="col-sm-6">

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Resa Iniziale q.li per ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="resaIniziale" name="resaIniziale" class="form-control"
                    [(ngModel)]="formRese.reseInizialiXHa" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getResaInizialeXHa($event)" #reseInizialiXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && reseInizialiXHa.invalid">
                    <div *ngIf="reseInizialiXHa.errors.required">
                      Resa Iniziale q.li X Ha è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Resa Massima q.li per ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="resaMassimna" name="resaMassimna" class="form-control"
                    [(ngModel)]="formRese.resaMassimaXHa" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getResaMassimaXHa($event)" #resaMassimaXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && resaMassimaXHa.invalid">
                    <div *ngIf="resaMassimaXHa.errors.required">
                      Resa Massima q.li X Ha è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Resa Minima per q.li ha:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="resaMinima" name="resaMinima" class="form-control"
                    [(ngModel)]="formRese.resaMinimaXHa" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getResaMinimaXHa($event)" #resaMinimaXHa="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && resaMinimaXHa.invalid">
                    <div *ngIf="resaMinimaXHa.errors.required">
                      Resa Minima X Ha è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="ResaMinMagResaMax">
                      Resa Minima X Ha non può essere maggiore di Resa MAssima q.li x HA
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Drift Resa %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="driftResa" name="driftResa" class="form-control"
                    [(ngModel)]="formRese.driftResa" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getDriftResa($event)" #driftResa="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && driftResa.invalid">
                    <div *ngIf="driftResa.errors.required">
                      Drift Resa è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Scarto Resa %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="scartoResa" name="scartoResa" class="form-control"
                    [(ngModel)]="formRese.scartoResa" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getScartoResa($event)" #scartoResa="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && scartoResa.invalid">
                    <div *ngIf="scartoResa.errors.required">
                      Scarto Resa è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Anni Previsione Resa:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="anniPrevisioneResa" name="anniPrevisioneResa" class="form-control"
                    [(ngModel)]="formRese.anniPrevisioneResa" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getAnniPrevisioneResa($event)" #anniPrevisioneResa="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && anniPrevisioneResa.invalid">
                    <div *ngIf="anniPrevisioneResa.errors.required">
                      Anni Previsione Resa è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Volatilità Resa a Rialzo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="volatilitaReseRialzo" name="volatilitaReseRialzo" class="form-control"
                    [(ngModel)]="formRese.volatilitaReseRialzoFormattato" [readonly]="true"
                    #volatilitaReseRialzo="ngModel" />
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Volatilità Resa a Ribasso:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="volatilitaReseRibasso" name="volatilitaReseRibasso" class="form-control"
                    [(ngModel)]="formRese.volatilitaReseRibassoFormattato" [readonly]="true"
                    #volatilitaReseRibasso="ngModel" />
                 </div>
              </div>
            </div>

            <!-- COLONNA DI DESTRA - RESE -->
            <div class="col-sm-6 bordo-sx">

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Invenduto Iniziale %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="invendutoIniziale" name="invendutoIniziale" class="form-control"
                    [(ngModel)]="formRese.invendutoIniziale" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getInvendutoIniziale($event)" #invendutoIniziale="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && invendutoIniziale.invalid">
                    <div *ngIf="invendutoIniziale.errors.required">
                      Invenduto Iniziale è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Invenduto Massimo %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="invendutoMassimo" name="invendutoMassimo" class="form-control"
                    [(ngModel)]="formRese.invendutoMassimo" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getInvendutoMassimo($event)" #invendutoMassimo="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && invendutoMassimo.invalid">
                    <div *ngIf="invendutoMassimo.errors.required">
                      Invenduto Massimo è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Invenduto Minimo %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="invendutoMinimo" name="invendutoMinimo" class="form-control"
                    [(ngModel)]="formRese.invendutoMinimo" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getInvendutoMinimo($event)" #invendutoMinimo="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && invendutoMinimo.invalid">
                    <div *ngIf="invendutoMinimo.errors.required">
                      Invenduto Minimo è obbligatorio
                    </div>
                  </div>
                  <div class="alert-danger">
                    <div *ngIf="InvendutoMinMagInvendutoMax">
                      Invenduto Minima  non può essere maggiore di Invenduto Massimo
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Drift Invenduto %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="driftInvenduto" name="driftInvenduto" class="form-control"
                    [(ngModel)]="formRese.driftInvenduto" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getDriftInvenduto($event)" #driftInvenduto="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && driftInvenduto.invalid">
                    <div *ngIf="driftInvenduto.errors.required">
                      Drift Invenduto è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Scarto Invenduto %:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="scartoInvenduto" name="scartoInvenduto" class="form-control"
                    [(ngModel)]="formRese.scartoInvenduto" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getScartoInvenduto($event)" #scartoInvenduto="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && scartoInvenduto.invalid">
                    <div *ngIf="scartoInvenduto.errors.required">
                      Scarto Invenduto è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Anni Previsione Invenduto:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="anniPrevisioneInvenduto" name="anniPrevisioneInvenduto" class="form-control"
                    [(ngModel)]="formRese.anniPrevisioneInvenduto" [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getAnniPrevisioneInvenduto($event)" #anniPrevisioneInvenduto="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && anniPrevisioneInvenduto.invalid">
                    <div *ngIf="anniPrevisioneInvenduto.errors.required">
                      Anni Previsione Invenduto è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Volatilità Invenduto a Rialzo:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="volatilitaInvendutoRialzo" name="volatilitaInvendutoRialzo" class="form-control"
                    [(ngModel)]="formRese.volatilitaInvendutoRialzoFormattato" [readonly]="true"
                    #volatilitaInvendutoRialzo="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && volatilitaInvendutoRialzo.invalid">
                    <div *ngIf="volatilitaInvendutoRialzo.errors.required">
                      Volatilità Invenduto a Rialzo è obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row form-group m-top">
                <div class="col-sm-7 destra"><label>Volatilità Invenduto a Ribasso:</label></div>
                <div class="col-sm-5">
                  <input type="text" id="volatilitaInvendutoRibasso" name="volatilitaInvendutoRibasso" class="form-control"
                    [(ngModel)]="formRese.volatilitaInvendutoRibassoFormattato" [readonly]="true"
                    #volatilitaInvendutoRibasso="ngModel" required />
                  <div class="alert-danger" *ngIf="fRese.submitted && volatilitaInvendutoRibasso.invalid">
                    <div *ngIf="volatilitaInvendutoRibasso.errors.required">
                      Volatilità Invenduto a Ribasso è obbligatorio
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- FINE COLONNA DI DESTRA - RESE -->
          </div>
          <!-- fine riga -->

          <!-- REGISTRA RESE -->
          <div class="row form-group m-top">
            <div class="col-sm-12">
              <button type="submit" class="btn btn-primary btn-md mr-1">
                Registra
              </button>
              <button class="btn btn-success btn-md mr-1" *ngIf="this.visibilitaElimina">
                Elimina
              </button>
            </div>
          </div>

          <div class="row form-group mt-n3">
            <div class="col-sm-12"><hr /></div>
          </div>
        </ng-container>
      </form>
      <!-- FINE FORM RESE -->

     <!-- MACHINE LEARNING -->
      <ng-container *ngIf="showValueML">

        <div class="row form-group mt-n3">
          <div class="col-md-12">
            <p style="text-align: center; padding-top: 10px;">
              <strong>Dati di riferimento</strong>
            </p>
          </div>
        </div>
        <!-- Marco -->
        <div class="row form-group mt-n3" style="margin-bottom: 20px">
          <div class="col-md-12 container-sm mb-3">
            <div class="row margin-0">
              <div class="col-sm-3 bordo-i"></div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10">
                  <div class="col bordo-i">Zona</div>
                  <div class="col bordo-i">{{pagina[0].anno}}</div>
                  <div class="col bordo-i">Trend</div>
                  <div class="col bordo-i">{{pagina[ind].anno}}</div>
                  <div class="col bordo-i">Trend</div>
                  <div class="col bordo-i">{{pagina[ind].anno - 1}}</div>
                  <div class="col bordo-i">Trend</div>
                  <div class="col bordo-i">{{pagina[ind].anno - 2}}</div>
                  <div class="col bordo-i">Trend</div>
                  <div class="col bordo-i">{{pagina[ind].anno - 3}}</div>
                </div>
              </div>

            </div>

            <div class="row margin-0">
              <div class="col-sm-3 bordo-t padding-left-right-5">Prezzo statistico (€/kg)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{statZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[0] && pagina[0].stat > 0 ? (pagina[0].stat | number: '1.0-2':'it') : 'n.p.'}} 
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaStat[0] > 0?'+':''}}{{deltaStat[0] == 0 ? '-' : (deltaStat[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaStat[0] > 0 ? 'fa-long-arrow-up verde': deltaStat[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[ind] && pagina[ind].stat > 0 ? (pagina[ind].stat | number: '1.0-2':'it') : 'n.p.'}}
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaStat[1] > 0?'+':''}}{{deltaStat[1] == 0 ? '-' : (deltaStat[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaStat[1] > 0 ? 'fa-long-arrow-up verde': deltaStat[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[ind + 1] && pagina[ind + 1].stat > 0 ? (pagina[ind + 1].stat | number: '1.0-2':'it') : 'n.p.'}}
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaStat[2] > 0?'+':''}}{{deltaStat[2] == 0 ? '-' : (deltaStat[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaStat[2] > 0 ? 'fa-long-arrow-up verde': deltaStat[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[ind + 2] && pagina[ind + 2].stat > 0 ? (pagina[ind + 2].stat | number: '1.0-2':'it') : 'n.p.'}}
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaStat[3] > 0 ? '+':''}}{{deltaStat[3] == 0 ? '-' : (deltaStat[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaStat[3] > 0 ? 'fa-long-arrow-up verde': deltaStat[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[ind + 3] && pagina[ind + 3].stat > 0 ? (pagina[ind + 3].stat | number: '1.0-2':'it') : 'n.p.'}}
                  </div>
                </div>  
              </div>
            </div>
            <div class="row margin-0">
              <div class="col-sm-3 bordo-t padding-left-right-5">Intervallo prezzo statistico (€/kg)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[0] && pagina[0].stat > 0  && pagina[0].statMax >= 0 && pagina[0].statMin >= 0"> 
                        {{pagina[0].statMin | number: '1.0-2':'it'}} - {{pagina[0].statMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[0] && pagina[0].stat > 0  && pagina[0].statMax >= 0 && pagina[0].statMin >= 0"> 
                      {{100*((pagina[0].stat - pagina[0].statMin)/pagina[0].stat + (pagina[0].statMax - pagina[0].stat)/pagina[0].stat)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind] && pagina[ind].stat > 0  && pagina[ind].statMax >= 0 && pagina[ind].statMin >= 0">       
                        {{pagina[ind].statMin | number: '1.0-2':'it'}} - {{pagina[ind].statMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind] && pagina[ind].stat > 0  && pagina[ind].statMax >= 0 && pagina[ind].statMin >= 0">       
                      {{100*((pagina[ind].stat - pagina[ind].statMin)/pagina[ind].stat + (pagina[ind].statMax - pagina[ind].stat)/pagina[ind].stat)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>

                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 1] && pagina[ind + 1].stat > 0  && pagina[ind + 1].statMax >= 0 && pagina[ind + 1].statMin >= 0">
                        
                        {{pagina[ind + 1].statMin | number: '1.0-2':'it'}} - {{pagina[ind + 1].statMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 1] && pagina[ind + 1].stat > 0  && pagina[ind + 1].statMax >= 0 && pagina[ind + 1].statMin >= 0">
                        
                      {{100*((pagina[ind + 1].stat - pagina[ind + 1].statMin)/pagina[ind + 1].stat + (pagina[ind + 1].statMax - pagina[ind + 1].stat)/pagina[ind + 1].stat)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 2] && pagina[ind + 2].stat > 0  && pagina[ind + 2].statMax >= 0 && pagina[ind + 2].statMin >= 0">                    
                        {{pagina[ind + 2].statMin | number: '1.0-2':'it'}} - {{pagina[ind + 2].statMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 2] && pagina[ind + 2].stat > 0  && pagina[ind + 2].statMax >= 0 && pagina[ind + 2].statMin >= 0">                    
                        {{100*((pagina[ind + 2].stat - pagina[ind + 2].statMin)/pagina[ind + 2].stat + (pagina[ind + 2].statMax - pagina[ind + 2].stat)/pagina[ind + 2].stat)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>    
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 3] && pagina[ind + 3].stat > 0  && pagina[ind + 3].statMax >= 0 && pagina[ind + 3].statMin >= 0">                    
                        {{pagina[ind + 3].statMin | number: '1.0-2':'it'}} - {{pagina[ind + 3].statMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 3] && pagina[ind + 3].stat > 0  && pagina[ind + 3].statMax >= 0 && pagina[ind + 3].statMin >= 0">                    
                      {{100*((pagina[ind + 3].stat - pagina[ind + 3].statMin)/pagina[ind + 3].stat + (pagina[ind + 3].statMax - pagina[ind + 3].stat)/pagina[ind + 3].stat)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row margin-0">
              <div class="col-sm-3 bordo-t padding-left-right-5">Prezzo machine learning (€/kg)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{mlZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[0] && pagina[0].ml > 0 ? (pagina[0].ml | number: '1.0-2':'it') : 'n.p.'}} 
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMl[0] > 0?'+':''}}{{deltaMl[0] == 0 ? '-' : (deltaMl[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMl[0] > 0 ? 'fa-long-arrow-up verde': deltaMl[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[ind] && pagina[ind].ml > 0 ? (pagina[ind].ml | number: '1.0-2':'it') : 'n.p.'}}
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMl[1] > 0?'+':''}}{{deltaMl[1] == 0 ? '-' : (deltaMl[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMl[1] > 0 ? 'fa-long-arrow-up verde': deltaMl[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[ind + 1] && pagina[ind + 1].ml > 0 ? (pagina[ind + 1].ml | number: '1.0-2':'it') : 'n.p.'}}
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMl[2] > 0?'+':''}}{{deltaMl[2] == 0 ? '-' : (deltaMl[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMl[2] > 0 ? 'fa-long-arrow-up verde': deltaMl[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[ind + 2] && pagina[ind + 2].ml > 0 ? (pagina[ind + 2].ml | number: '1.0-2':'it') : 'n.p.'}}
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMl[3] > 0 ? '+':''}}{{deltaMl[3] == 0 ? '-' : (deltaMl[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMl[3] > 0 ? 'fa-long-arrow-up verde': deltaMl[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    {{pagina[ind + 3] && pagina[ind + 3].ml > 0 ? (pagina[ind + 3].ml | number: '1.0-2':'it') : 'n.p.'}}
                  </div>
                </div>  
              </div>
            </div>
            <div class="row margin-0">
              <div class="col-sm-3 bordo-t padding-left-right-5">Intervallo prezzo machine learning (€/kg)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[0] && pagina[0].ml > 0  && pagina[0].mlMax >= 0 && pagina[0].mlMin >= 0"> 
                        {{pagina[0].mlMin | number: '1.0-2':'it'}} - {{pagina[0].mlMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[0] && pagina[0].ml > 0  && pagina[0].mlMax >= 0 && pagina[0].mlMin >= 0"> 
                      {{100*((pagina[0].ml - pagina[0].mlMin)/pagina[0].ml + (pagina[0].mlMax - pagina[0].ml)/pagina[0].ml)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind] && pagina[ind].ml > 0  && pagina[ind].mlMax >= 0 && pagina[ind].mlMin >= 0">       
                        {{pagina[ind].mlMin | number: '1.0-2':'it'}} - {{pagina[ind].mlMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind] && pagina[ind].ml > 0  && pagina[ind].mlMax >= 0 && pagina[ind].mlMin >= 0">       
                      {{100*((pagina[ind].ml - pagina[ind].mlMin)/pagina[ind].ml + (pagina[ind].mlMax - pagina[ind].ml)/pagina[ind].ml)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>

                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 1] && pagina[ind + 1].ml > 0  && pagina[ind + 1].mlMax >= 0 && pagina[ind + 1].mlMin >= 0">
                        
                        {{pagina[ind + 1].mlMin | number: '1.0-2':'it'}} - {{pagina[ind + 1].mlMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 1] && pagina[ind + 1].ml > 0  && pagina[ind + 1].mlMax >= 0 && pagina[ind + 1].mlMin >= 0">
                        
                      {{100*((pagina[ind + 1].ml - pagina[ind + 1].mlMin)/pagina[ind + 1].ml + (pagina[ind + 1].mlMax - pagina[ind + 1].ml)/pagina[ind + 1].ml)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 2] && pagina[ind + 2].ml > 0  && pagina[ind + 2].mlMax >= 0 && pagina[ind + 2].mlMin >= 0">                    
                        {{pagina[ind + 2].mlMin | number: '1.0-2':'it'}} - {{pagina[ind + 2].mlMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 2] && pagina[ind + 2].ml > 0  && pagina[ind + 2].mlMax >= 0 && pagina[ind + 2].mlMin >= 0">                    
                      {{100*((pagina[ind + 2].ml - pagina[ind + 2].mlMin)/pagina[ind + 2].ml + (pagina[ind + 2].mlMax - pagina[ind + 2].ml)/pagina[ind + 2].ml)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>    
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 3] && pagina[ind + 3].ml > 0  && pagina[ind + 3].mlMax >= 0 && pagina[ind + 3].mlMin >= 0">                    
                        {{pagina[ind + 3].mlMin | number: '1.0-2':'it'}} - {{pagina[ind + 3].mlMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 3] && pagina[ind + 3].ml > 0  && pagina[ind + 3].mlMax >= 0 && pagina[ind + 3].mlMin >= 0">                    
                        {{100*((pagina[ind + 3].ml - pagina[ind + 3].mlMin)/pagina[ind + 3].ml + (pagina[ind + 3].mlMax - pagina[ind + 3].ml)/pagina[ind + 3].ml)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
            
            <div class="row margin-0 sfondo-azzurro">
              <div class="col-sm-3 bordo-t padding-left-right-5">Prezzo ISMEA {{listaPrezziIsmeaAnno && listaPrezziIsmeaAnno.length > 0 ? '(' + listaPrezziIsmeaAnno[0].unitaMisura + ')': ''}}</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{ismeaZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].ismea > 0 ? (pagina[0].ismea | number: '1.0-2':'it') : 'n.p.'}}{{pagina[0] && pagina[0].ismea > 0 && utimoMese != '' && ultimoMese != 'dicembre' ? '*':''}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaIsmea[0] > 0?'+':''}}{{deltaIsmea[0] == 0 ? '-' : (deltaIsmea[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaIsmea[0] > 0 ? 'fa-long-arrow-up verde': deltaIsmea[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].ismea > 0 ? (pagina[ind].ismea | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaIsmea[1] > 0?'+':''}}{{deltaIsmea[1] == 0 ? '-' : (deltaIsmea[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaIsmea[1] > 0 ? 'fa-long-arrow-up verde': deltaIsmea[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].ismea > 0 ? (pagina[ind + 1].ismea | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaIsmea[2] > 0?'+':''}}{{deltaIsmea[2] == 0 ? '-' : (deltaIsmea[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaIsmea[2] > 0 ? 'fa-long-arrow-up verde': deltaIsmea[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].ismea > 0 ? (pagina[ind + 2].ismea | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaIsmea[3] > 0 ? '+':''}}{{deltaIsmea[3] == 0 ? '-' : (deltaIsmea[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaIsmea[3] > 0 ? 'fa-long-arrow-up verde': deltaIsmea[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].ismea > 0 ? (pagina[ind + 3].ismea | number: '1.0-2':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>
<!--            
            <div class="row margin-0 sfondo-azzurro">
              <div class="col-sm-3 bordo-t padding-left-right-5">Coefficiente di variazione prezzo ISMEA </div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].coeffVar != null ? (pagina[0].coeffVar | number: '1.0-1':'it') + ' %': 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].coeffVar != null ? (pagina[ind].coeffVar | number: '1.0-1':'it') + ' %': 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].coeffVar != null ? (pagina[ind + 1].coeffVar | number: '1.0-1':'it') + ' %': 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].coeffVar != null ? (pagina[ind + 2].coeffVar | number: '1.0-1':'it') + ' %': 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].coeffVar != null ? (pagina[ind + 3].coeffVar | number: '1.0-1':'it') + ' %': 'n.p.'}}</div>
                </div>
              </div>
            </div>
-->
            <div class="row margin-0">
              <div class="col-sm-3 bordo-t padding-left-right-5">Prezzo Expert Opinion (€/kg)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{eoZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center">
                        {{pagina[0] && pagina[0].eo > 0 ? (pagina[0].eo | number: '1.0-2':'it') : 'n.p.'}}
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[0] && pagina[0].eo > 0  && pagina[0].eoMax >= 0 && pagina[0].eoMin >= 0">
                        {{pagina[0].eoMin | number: '1.0-2':'it'}} - {{pagina[0].eoMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[0] && pagina[0].eo > 0  && pagina[0].eoMax >= 0 && pagina[0].eoMin >= 0">
                        {{100*((pagina[0].eo - pagina[0].eoMin)/pagina[0].eo + (pagina[0].eoMax - pagina[0].eo)/pagina[0].eo)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaEo[0] > 0?'+':''}}{{deltaEo[0] == 0 ? '-' : (deltaEo[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaEo[0] > 0 ? 'fa-long-arrow-up verde': deltaEo[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center">
                      {{pagina[ind] && pagina[ind].eo > 0 ? (pagina[ind].eo | number: '1.0-2':'it') : 'n.p.'}}
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind] && pagina[ind].eo > 0  && pagina[ind].eoMax >= 0 && pagina[ind].eoMin >= 0">        
                        {{pagina[ind].eoMin | number: '1.0-2':'it'}} - {{pagina[ind].eoMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind] && pagina[ind].eo > 0  && pagina[ind].eoMax >= 0 && pagina[ind].eoMin >= 0">        
                        {{100*((pagina[ind].eo - pagina[ind].eoMin)/pagina[ind].eo + (pagina[ind].eoMax - pagina[ind].eo)/pagina[ind].eo)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>  
                  </div>
                  
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaEo[1] > 0?'+':''}}{{deltaEo[1] == 0 ? '-' : (deltaEo[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaEo[1] > 0 ? 'fa-long-arrow-up verde': deltaEo[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center">
                        {{pagina[ind + 1] && pagina[ind + 1].eo > 0 ? (pagina[ind + 1].eo | number: '1.0-2':'it') : 'n.p.'}}
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 1] && pagina[ind + 1].eo > 0  && pagina[ind + 1].eoMax >= 0 && pagina[ind + 1].eoMin >= 0">                   
                        {{pagina[ind + 1].eoMin | number: '1.0-2':'it'}} - {{pagina[ind + 1].eoMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 1] && pagina[ind + 1].eo > 0  && pagina[ind + 1].eoMax >= 0 && pagina[ind + 1].eoMin >= 0">                   
                        {{100*((pagina[ind + 1].eo - pagina[ind + 1].eoMin)/pagina[ind + 1].eo + (pagina[ind + 1].eoMax - pagina[ind + 1].eo)/pagina[ind + 1].eo)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaEo[2] > 0?'+':''}}{{deltaEo[2] == 0 ? '-' : (deltaEo[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaEo[2] > 0 ? 'fa-long-arrow-up verde': deltaEo[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center">
                        {{pagina[ind + 2] && pagina[ind + 2].eo > 0 ? (pagina[ind + 2].eo | number: '1.0-2':'it') : 'n.p.'}}
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 2] && pagina[ind + 2].eo > 0  && pagina[ind + 2].eoMax >= 0 && pagina[ind + 2].eoMin >= 0">
                        {{pagina[ind + 2].eoMin | number: '1.0-2':'it'}} - {{pagina[ind + 2].eoMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 2] && pagina[ind + 2].eo > 0  && pagina[ind + 2].eoMax >= 0 && pagina[ind + 2].eoMin >= 0">
                        {{100*((pagina[ind + 2].eo - pagina[ind + 2].eoMin)/pagina[ind + 2].eo + (pagina[ind + 2].eoMax - pagina[ind + 2].eo)/pagina[ind + 2].eo)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>  

                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaEo[3] > 0 ? '+':''}}{{deltaEo[3] == 0 ? '-' : (deltaEo[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaEo[3] > 0 ? 'fa-long-arrow-up verde': deltaEo[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">
                    <div class="row margin-0">
                      <div class="col-12 nopadding text-center">
                        {{pagina[ind + 3] && pagina[ind + 3].eo > 0 ? (pagina[ind + 3].eo | number: '1.0-2':'it') : 'n.p.'}}
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 3] && pagina[ind + 3].eo > 0  && pagina[ind + 3].eoMax >= 0 && pagina[ind + 3].eoMin >= 0">
                        {{pagina[ind + 3].eoMin | number: '1.0-2':'it'}} - {{pagina[ind + 3].eoMax | number: '1.0-2':'it'}} 
                      </div>
                      <div class="col-12 nopadding text-center" *ngIf="pagina[ind + 3] && pagina[ind + 3].eo > 0  && pagina[ind + 3].eoMax >= 0 && pagina[ind + 3].eoMin >= 0">
                        {{100*((pagina[ind + 3].eo - pagina[ind + 3].eoMin)/pagina[ind + 3].eo + (pagina[ind + 3].eoMax - pagina[ind + 3].eo)/pagina[ind + 3].eo)/2. | number: '1.0-2':'it'}} % 
                      </div>
                    </div>  
                  </div>
                </div>  
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro">
              <div class="col-sm-3 bordo-t padding-left-right-5">Prezzo CREA per prodotto (€/kg)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{ricaZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].prezzoRica > 0 ? (pagina[0].prezzoRica/100. | number: '1.0-2':'it') : 'n.p.'}}{{pagina[0] && pagina[0].prezzoRica > 0 && utimoMese != '' && ultimoMese != 'dicembre' ? '*':''}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaPrezzoRica[0] > 0?'+':''}}{{deltaPrezzoRica[0] == 0 ? '-' : (deltaPrezzoRica[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaPrezzoRica[0] > 0 ? 'fa-long-arrow-up verde': deltaPrezzoRica[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].prezzoRica > 0 ? (pagina[ind].prezzoRica/100. | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaPrezzoRica[1] > 0?'+':''}}{{deltaPrezzoRica[1] == 0 ? '-' : (deltaPrezzoRica[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaPrezzoRica[1] > 0 ? 'fa-long-arrow-up verde': deltaPrezzoRica[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].prezzoRica > 0 ? (pagina[ind + 1].prezzoRica/100. | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaPrezzoRica[2] > 0?'+':''}}{{deltaPrezzoRica[2] == 0 ? '-' : (deltaPrezzoRica[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaPrezzoRica[2] > 0 ? 'fa-long-arrow-up verde': deltaPrezzoRica[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].prezzoRica > 0 ? (pagina[ind + 2].prezzoRica/100. | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaPrezzoRica[3] > 0 ? '+':''}}{{deltaPrezzoRica[3] == 0 ? '-' : (deltaPrezzoRica[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaPrezzoRica[3] > 0 ? 'fa-long-arrow-up verde': deltaPrezzoRica[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].prezzoRica > 0 ? (pagina[ind + 3].prezzoRica/100. | number: '1.0-2':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>
            
            <div class="row margin-0">
              <div class="col-sm-3 bordo-t padding-left-right-5">Resa statistica dati CREA (Q.li/Ha)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{reseStatZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].reseStat > 0 ? (pagina[0].reseStat | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseStat[0] > 0?'+':''}}{{deltaReseStat[0] == 0 ? '-' : (deltaReseStat[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseStat[0] > 0 ? 'fa-long-arrow-up verde': deltaReseStat[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].reseStat > 0 ? (pagina[ind].reseStat | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseStat[1] > 0?'+':''}}{{deltaReseStat[1] == 0 ? '-' : (deltaReseStat[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseStat[1] > 0 ? 'fa-long-arrow-up verde': deltaReseStat[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].reseStat > 0 ? (pagina[ind + 1].reseStat | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseStat[2] > 0?'+':''}}{{deltaReseStat[2] == 0 ? '-' : (deltaReseStat[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseStat[2] > 0 ? 'fa-long-arrow-up verde': deltaReseStat[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].reseStat > 0 ? (pagina[ind + 2].reseStat | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseStat[3] > 0 ? '+':''}}{{deltaReseStat[3] == 0 ? '-' : (deltaReseStat[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseStat[3] > 0 ? 'fa-long-arrow-up verde': deltaReseStat[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].reseStat > 0 ? (pagina[ind + 3].reseStat | number: '1.0-2':'it') : 'n.p.'}}</div>
            
                </div>
              </div>
            </div>
            <div class="row margin-0 sfondo-azzurro">
              <div class="col-sm-3 bordo-t padding-left-right-5">Resa Expert Opinion (Q.li/Ha)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{reseStatZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].reseEo > 0 ? (pagina[0].reseEo | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseEo[0] > 0?'+':''}}{{deltaReseEo[0] == 0 ? '-' : (deltaReseEo[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseEo[0] > 0 ? 'fa-long-arrow-up verde': deltaReseEo[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].reseEo > 0 ? (pagina[ind].reseEo | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseEo[1] > 0?'+':''}}{{deltaReseEo[1] == 0 ? '-' : (deltaReseEo[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseEo[1] > 0 ? 'fa-long-arrow-up verde': deltaReseEo[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].reseEo > 0 ? (pagina[ind + 1].reseEo | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseEo[2] > 0?'+':''}}{{deltaReseEo[2] == 0 ? '-' : (deltaReseEo[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseEo[2] > 0 ? 'fa-long-arrow-up verde': deltaReseEo[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].reseEo > 0 ? (pagina[ind + 2].reseEo | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseEo[3] > 0 ? '+':''}}{{deltaReseEo[3] == 0 ? '-' : (deltaReseEo[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseEo[3] > 0 ? 'fa-long-arrow-up verde': deltaReseEo[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].reseEo > 0 ? (pagina[ind + 3].reseEo | number: '1.0-2':'it') : 'n.p.'}}</div>
            
                </div>
              </div>
            </div>

            <div class="row margin-0">
              <div class="col-sm-3 bordo-t padding-left-right-5">Resa CREA per prodotto (Q.li/Ha)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{ricaZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].resaRica > 0 ? (pagina[0].resaRica | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaResaRica[0] > 0?'+':''}}{{deltaResaRica[0] == 0 ? '-' : (deltaResaRica[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaResaRica[0] > 0 ? 'fa-long-arrow-up verde': deltaResaRica[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].resaRica > 0 ? (pagina[ind].resaRica | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaResaRica[1] > 0?'+':''}}{{deltaResaRica[1] == 0 ? '-' : (deltaResaRica[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaResaRica[1] > 0 ? 'fa-long-arrow-up verde': deltaResaRica[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].resaRica > 0 ? (pagina[ind + 1].resaRica | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaResaRica[2] > 0?'+':''}}{{deltaResaRica[2] == 0 ? '-' : (deltaResaRica[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaResaRica[2] > 0 ? 'fa-long-arrow-up verde': deltaResaRica[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].resaRica > 0 ? (pagina[ind + 2].resaRica | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaResaRica[3] > 0 ? '+':''}}{{deltaResaRica[3] == 0 ? '-' : (deltaResaRica[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaResaRica[3] > 0 ? 'fa-long-arrow-up verde': deltaResaRica[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].resaRica > 0 ? (pagina[ind + 3].resaRica | number: '1.0-1':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro">
              <div class="col-sm-3 bordo-t padding-left-right-5 flex-justify-space-between">
                Resa ISTAT per prodotto (Q.li/Ha)
                <span class="pull-right pointer" (click)="boolResaIstat = !boolResaIstat; boolResaNaz = false">
                      <i class="fa fa-2x" [ngClass]="[boolResaIstat? 'fa-caret-up': 'fa-caret-down']"></i>
                </span>
              </div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{istatZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].reseIstat > 0 ? (pagina[0].reseIstat | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseIstat[0] > 0?'+':''}}{{deltaReseIstat[0] == 0 ? '-' : (deltaReseIstat[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseIstat[0] > 0 ? 'fa-long-arrow-up verde': deltaReseIstat[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].reseIstat > 0 ? (pagina[ind].reseIstat | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseIstat[1] > 0?'+':''}}{{deltaReseIstat[1] == 0 ? '-' : (deltaReseIstat[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseIstat[1] > 0 ? 'fa-long-arrow-up verde': deltaReseIstat[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].reseIstat > 0 ? (pagina[ind + 1].reseIstat | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseIstat[2] > 0?'+':''}}{{deltaReseIstat[2] == 0 ? '-' : (deltaReseIstat[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseIstat[2] > 0 ? 'fa-long-arrow-up verde': deltaReseIstat[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].reseIstat > 0 ? (pagina[ind + 2].reseIstat | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseIstat[3] > 0 ? '+':''}}{{deltaReseIstat[3] == 0 ? '-' : (deltaReseIstat[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseIstat[3] > 0 ? 'fa-long-arrow-up verde': deltaReseIstat[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].reseIstat > 0 ? (pagina[ind + 3].reseIstat | number: '1.0-1':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolResaIstat">
            <div class="col-sm-3 bordo-t padding-left-right-5">Superficie ISTAT per prodotto (Ha)</div>
            <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{istatZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].superfici > 0 ? (pagina[0].superfici | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaSuperfici[0] > 0?'+':''}}{{deltaSuperfici[0] == 0 ? '-' : (deltaSuperfici[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaSuperfici[0] > 0 ? 'fa-long-arrow-up verde': deltaSuperfici[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].superfici > 0 ? (pagina[ind].superfici | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaSuperfici[1] > 0?'+':''}}{{deltaSuperfici[1] == 0 ? '-' : (deltaSuperfici[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaSuperfici[1] > 0 ? 'fa-long-arrow-up verde': deltaSuperfici[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].superfici > 0 ? (pagina[ind + 1].superfici | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaSuperfici[2] > 0?'+':''}}{{deltaSuperfici[2] == 0 ? '-' : (deltaSuperfici[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaSuperfici[2] > 0 ? 'fa-long-arrow-up verde': deltaSuperfici[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].superfici > 0 ? (pagina[ind + 2].superfici | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaSuperfici[3] > 0 ? '+':''}}{{deltaSuperfici[3] == 0 ? '-' : (deltaSuperfici[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaSuperfici[3] > 0 ? 'fa-long-arrow-up verde': deltaSuperfici[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].superfici > 0 ? (pagina[ind + 3].superfici | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolResaIstat">
              <div class="col-sm-3 bordo-t padding-left-right-5">Produzione ISTAT per prodotto (Q.li)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{istatZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].produzione > 0 ? (pagina[0].produzione | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaProduzione[0] > 0?'+':''}}{{deltaProduzione[0] == 0 ? '-' : (deltaProduzione[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaProduzione[0] > 0 ? 'fa-long-arrow-up verde': deltaProduzione[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].produzione > 0 ? (pagina[ind].produzione | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaProduzione[1] > 0?'+':''}}{{deltaProduzione[1] == 0 ? '-' : (deltaProduzione[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaProduzione[1] > 0 ? 'fa-long-arrow-up verde': deltaProduzione[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].produzione > 0 ? (pagina[ind + 1].produzione | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaProduzione[2] > 0?'+':''}}{{deltaProduzione[2] == 0 ? '-' : (deltaProduzione[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaProduzione[2] > 0 ? 'fa-long-arrow-up verde': deltaProduzione[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].produzione > 0 ? (pagina[ind + 2].produzione | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaProduzione[3] > 0 ? '+':''}}{{deltaProduzione[3] == 0 ? '-' : (deltaProduzione[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaProduzione[3] > 0 ? 'fa-long-arrow-up verde': deltaProduzione[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].produzione > 0 ? (pagina[ind + 3].produzione | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolResaIstat">
            <div class="col-sm-3 bordo-t padding-left-right-5 flex-justify-space-between">
                Percentuale della produzione nazionale ISTAT
                <span class="pull-right pointer" (click)="boolResaNaz = !boolResaNaz">
                      <i class="fa fa-2x" [ngClass]="[boolResaNaz? 'fa-caret-up': 'fa-caret-down']"></i>
                </span>
              </div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].produzione && pagina[0].produzioneNaz > 0 ? (100*pagina[0].produzione/pagina[0].produzioneNaz | number: '1.0-1':'it') + '%' : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].produzione > 0 && pagina[ind].produzioneNaz > 0 ? (100*pagina[ind].produzione/pagina[ind].produzioneNaz | number: '1.0-1':'it') + '%' : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].produzione > 0 && pagina[ind + 1].produzioneNaz > 0 ? (100*pagina[ind + 1].produzione/pagina[ind + 1].produzioneNaz | number: '1.0-1':'it') + '%' : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].produzione > 0 && pagina[ind + 2].produzioneNaz > 0 ? (100*pagina[ind + 2].produzione/pagina[ind + 2].produzioneNaz | number: '1.0-1':'it') + '%' : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center"></div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].produzione > 0 && pagina[ind + 3].produzioneNaz > 0? (100* pagina[ind + 3].produzione/pagina[ind + 3].produzioneNaz | number: '1.0-1':'it') + '%' : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolResaNaz">
              <div class="col-sm-3 bordo-t padding-left-right-5"> Resa ISTAT nazionale per prodotto (Q.li/Ha)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> Italia </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].reseIstatNaz > 0 ? (pagina[0].reseIstatNaz | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseIstatNaz[0] > 0?'+':''}}{{deltaReseIstatNaz[0] == 0 ? '-' : (deltaReseIstatNaz[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseIstatNaz[0] > 0 ? 'fa-long-arrow-up verde': deltaReseIstatNaz[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].reseIstatNaz > 0 ? (pagina[ind].reseIstatNaz | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseIstatNaz[1] > 0?'+':''}}{{deltaReseIstatNaz[1] == 0 ? '-' : (deltaReseIstatNaz[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseIstatNaz[1] > 0 ? 'fa-long-arrow-up verde': deltaReseIstatNaz[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].reseIstatNaz > 0 ? (pagina[ind + 1].reseIstatNaz | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseIstatNaz[2] > 0?'+':''}}{{deltaReseIstatNaz[2] == 0 ? '-' : (deltaReseIstatNaz[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseIstatNaz[2] > 0 ? 'fa-long-arrow-up verde': deltaReseIstatNaz[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].reseIstatNaz > 0 ? (pagina[ind + 2].reseIstatNaz | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaReseIstatNaz[3] > 0 ? '+':''}}{{deltaReseIstatNaz[3] == 0 ? '-' : (deltaReseIstatNaz[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaReseIstatNaz[3] > 0 ? 'fa-long-arrow-up verde': deltaReseIstatNaz[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].reseIstatNaz > 0 ? (pagina[ind + 3].reseIstatNaz | number: '1.0-1':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolResaNaz">
            <div class="col-sm-3 bordo-t padding-left-right-5">Superficie ISTAT nazionale per prodotto (Ha)</div>
            <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> Italia </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].superficiNaz > 0 ? (pagina[0].superficiNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaSuperficiNaz[0] > 0?'+':''}}{{deltaSuperficiNaz[0] == 0 ? '-' : (deltaSuperficiNaz[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaSuperficiNaz[0] > 0 ? 'fa-long-arrow-up verde': deltaSuperficiNaz[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].superficiNaz > 0 ? (pagina[ind].superficiNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaSuperficiNaz[1] > 0?'+':''}}{{deltaSuperficiNaz[1] == 0 ? '-' : (deltaSuperficiNaz[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaSuperficiNaz[1] > 0 ? 'fa-long-arrow-up verde': deltaSuperficiNaz[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].superficiNaz > 0 ? (pagina[ind + 1].superficiNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaSuperficiNaz[2] > 0?'+':''}}{{deltaSuperficiNaz[2] == 0 ? '-' : (deltaSuperficiNaz[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaSuperficiNaz[2] > 0 ? 'fa-long-arrow-up verde': deltaSuperficiNaz[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].superficiNaz > 0 ? (pagina[ind + 2].superficiNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaSuperficiNaz[3] > 0 ? '+':''}}{{deltaSuperficiNaz[3] == 0 ? '-' : (deltaSuperficiNaz[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaSuperficiNaz[3] > 0 ? 'fa-long-arrow-up verde': deltaSuperficiNaz[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].superficiNaz > 0 ? (pagina[ind + 3].superficiNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolResaNaz">
              <div class="col-sm-3 bordo-t padding-left-right-5">Produzione ISTAT nazionale per prodotto (Q.li)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> Italia </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].produzioneNaz > 0 ? (pagina[0].produzioneNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaProduzioneNaz[0] > 0?'+':''}}{{deltaProduzioneNaz[0] == 0 ? '-' : (deltaProduzioneNaz[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaProduzioneNaz[0] > 0 ? 'fa-long-arrow-up verde': deltaProduzioneNaz[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].produzioneNaz > 0 ? (pagina[ind].produzioneNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaProduzioneNaz[1] > 0?'+':''}}{{deltaProduzioneNaz[1] == 0 ? '-' : (deltaProduzioneNaz[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaProduzioneNaz[1] > 0 ? 'fa-long-arrow-up verde': deltaProduzioneNaz[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].produzioneNaz > 0 ? (pagina[ind + 1].produzioneNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaProduzioneNaz[2] > 0?'+':''}}{{deltaProduzioneNaz[2] == 0 ? '-' : (deltaProduzioneNaz[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaProduzioneNaz[2] > 0 ? 'fa-long-arrow-up verde': deltaProduzioneNaz[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].produzioneNaz > 0 ? (pagina[ind + 2].produzioneNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaProduzioneNaz[3] > 0 ? '+':''}}{{deltaProduzioneNaz[3] == 0 ? '-' : (deltaProduzioneNaz[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaProduzioneNaz[3] > 0 ? 'fa-long-arrow-up verde': deltaProduzioneNaz[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].produzioneNaz > 0 ? (pagina[ind + 3].produzioneNaz | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0">
            <div class="col-sm-3 bordo-t padding-left-right-5 flex-justify-space-between">
                Costi totali per prodotto CREA (€/Ha)
                <span class="pull-right pointer" (click)="boolCostiRica = !boolCostiRica">
                      <i class="fa fa-2x" [ngClass]="[boolCostiRica? 'fa-caret-up': 'fa-caret-down']"></i>
                </span>
              </div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{ricaZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].plt - pagina[0].margOp > 0 ? (pagina[0].plt - pagina[0].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaCostiTot[0] > 0?'+':''}}{{deltaCostiTot[0] == 0 ? '-' : (deltaCostiTot[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaCostiTot[0] > 0 ? 'fa-long-arrow-up verde': deltaCostiTot[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].plt - pagina[ind].margOp > 0 ? (pagina[ind].plt - pagina[ind].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaCostiTot[1] > 0?'+':''}}{{deltaCostiTot[1] == 0 ? '-' : (deltaCostiTot[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaCostiTot[1] > 0 ? 'fa-long-arrow-up verde': deltaCostiTot[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].plt - pagina[ind + 1].margOp > 0 ? (pagina[ind + 1].plt - pagina[ind + 1].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaCostiTot[2] > 0?'+':''}}{{deltaCostiTot[2] == 0 ? '-' : (deltaCostiTot[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaCostiTot[2] > 0 ? 'fa-long-arrow-up verde': deltaCostiTot[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].plt - pagina[ind + 2].margOp > 0 ? (pagina[ind + 2].plt - pagina[ind + 2].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaCostiTot[3] > 0 ? '+':''}}{{deltaCostiTot[3] == 0 ? '-' : (deltaCostiTot[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaCostiTot[3] > 0 ? 'fa-long-arrow-up verde': deltaCostiTot[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].plt - pagina[ind + 3].margOp > 0 ? (pagina[ind + 3].plt - pagina[ind + 3].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0" *ngIf="boolCostiRica">
              <div class="col-sm-3 bordo-t padding-left-right-5">Produzione lorda totale per prodotto CREA (€/Ha)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{ricaZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].plt > 0 ? (pagina[0].plt | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaPlt[0] > 0?'+':''}}{{deltaPlt[0] == 0 ? '-' : (deltaPlt[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaPlt[0] > 0 ? 'fa-long-arrow-up verde': deltaPlt[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].plt > 0 ? (pagina[ind].plt | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaPlt[1] > 0?'+':''}}{{deltaPlt[1] == 0 ? '-' : (deltaPlt[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaPlt[1] > 0 ? 'fa-long-arrow-up verde': deltaPlt[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].plt > 0 ? (pagina[ind + 1].plt | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaPlt[2] > 0?'+':''}}{{deltaPlt[2] == 0 ? '-' : (deltaPlt[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaPlt[2] > 0 ? 'fa-long-arrow-up verde': deltaPlt[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].plt > 0 ? (pagina[ind + 2].plt | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaPlt[3] > 0 ? '+':''}}{{deltaPlt[3] == 0 ? '-' : (deltaPlt[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaPlt[3] > 0 ? 'fa-long-arrow-up verde': deltaPlt[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].plt > 0 ? (pagina[ind + 3].plt | number: '1.0-1':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0" *ngIf="boolCostiRica">
              <div class="col-sm-3 bordo-t padding-left-right-5">Costi specifici per prodotto CREA (€/Ha)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{ricaZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].cs > 0 ? (pagina[0].cs | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaCs[0] > 0?'+':''}}{{deltaCs[0] == 0 ? '-' : (deltaCs[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaCs[0] > 0 ? 'fa-long-arrow-up verde': deltaCs[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].cs > 0 ? (pagina[ind].cs | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaCs[1] > 0?'+':''}}{{deltaCs[1] == 0 ? '-' : (deltaCs[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaCs[1] > 0 ? 'fa-long-arrow-up verde': deltaCs[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].cs > 0 ? (pagina[ind + 1].cs | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaCs[2] > 0?'+':''}}{{deltaCs[2] == 0 ? '-' : (deltaCs[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaCs[2] > 0 ? 'fa-long-arrow-up verde': deltaCs[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].cs > 0 ? (pagina[ind + 2].cs | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaCs[3] > 0 ? '+':''}}{{deltaCs[3] == 0 ? '-' : (deltaCs[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaCs[3] > 0 ? 'fa-long-arrow-up verde': deltaCs[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].cs > 0 ? (pagina[ind + 3].cs | number: '1.0-1':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0" *ngIf="boolCostiRica">
              <div class="col-sm-3 bordo-t padding-left-right-5">Margine lordo per prodotto CREA (€/Ha)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{ricaZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].margLor > 0 ? (pagina[0].margLor | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMargLor[0] > 0?'+':''}}{{deltaMargLor[0] == 0 ? '-' : (deltaMargLor[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMargLor[0] > 0 ? 'fa-long-arrow-up verde': deltaMargLor[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].margLor > 0 ? (pagina[ind].margLor | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMargLor[1] > 0?'+':''}}{{deltaMargLor[1] == 0 ? '-' : (deltaMargLor[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMargLor[1] > 0 ? 'fa-long-arrow-up verde': deltaMargLor[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].margLor > 0 ? (pagina[ind + 1].margLor | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMargLor[2] > 0?'+':''}}{{deltaMargLor[2] == 0 ? '-' : (deltaMargLor[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMargLor[2] > 0 ? 'fa-long-arrow-up verde': deltaMargLor[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].margLor > 0 ? (pagina[ind + 2].margLor | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMargLor[3] > 0 ? '+':''}}{{deltaMargLor[3] == 0 ? '-' : (deltaMargLor[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMargLor[3] > 0 ? 'fa-long-arrow-up verde': deltaMargLor[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].margLor > 0 ? (pagina[ind + 3].margLor | number: '1.0-1':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0" *ngIf="boolCostiRica">
              <div class="col-sm-3 bordo-t padding-left-right-5">Margine operativo per prodotto CREA (€/Ha)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> {{ricaZona}} </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].margOp > 0 ? (pagina[0].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMargOp[0] > 0?'+':''}}{{deltaMargOp[0] == 0 ? '-' : (deltaMargOp[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMargOp[0] > 0 ? 'fa-long-arrow-up verde': deltaMargOp[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].margOp > 0 ? (pagina[ind].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMargOp[1] > 0?'+':''}}{{deltaMargOp[1] == 0 ? '-' : (deltaMargOp[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMargOp[1] > 0 ? 'fa-long-arrow-up verde': deltaMargOp[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>

                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].margOp > 0 ? (pagina[ind + 1].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMargOp[2] > 0?'+':''}}{{deltaMargOp[2] == 0 ? '-' : (deltaMargOp[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMargOp[2] > 0 ? 'fa-long-arrow-up verde': deltaMargOp[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].margOp > 0 ? (pagina[ind + 2].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaMargOp[3] > 0 ? '+':''}}{{deltaMargOp[3] == 0 ? '-' : (deltaMargOp[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaMargOp[3] > 0 ? 'fa-long-arrow-up verde': deltaMargOp[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>            
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].margOp > 0 ? (pagina[ind + 3].margOp | number: '1.0-1':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro">
              <div class="col-sm-3 bordo-t padding-left-right-5 flex-justify-space-between">
                Bilancia import-export nazionale per prodotto (Q.li)
                <span class="pull-right pointer" (click)="boolImpExp = !boolImpExp">
                      <i class="fa fa-2x" [ngClass]="[boolImpExp? 'fa-caret-up': 'fa-caret-down']"></i>
                </span>
              </div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> Italia </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].quantImport > 0 && pagina[0].quantExport > 0? ((pagina[0].quantImport - pagina[0].quantExport)/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaBilanciaImpExp[0] > 0?'+':''}}{{deltaBilanciaImpExp[0] == 0 ? '-' : (deltaBilanciaImpExp[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaBilanciaImpExp[0] > 0 ? 'fa-long-arrow-up verde': deltaBilanciaImpExp[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].quantImport > 0 && pagina[ind].quantExport > 0? ((pagina[ind].quantImport - pagina[ind].quantExport)/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaBilanciaImpExp[1] > 0?'+':''}}{{deltaBilanciaImpExp[1] == 0 ? '-' : (deltaBilanciaImpExp[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaBilanciaImpExp[1] > 0 ? 'fa-long-arrow-up verde': deltaBilanciaImpExp[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].quantImport > 0 && pagina[ind + 1].quantExport > 0? ((pagina[ind + 1].quantImport - pagina[ind + 1].quantExport)/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaBilanciaImpExp[2] > 0?'+':''}}{{deltaBilanciaImpExp[2] == 0 ? '-' : (deltaBilanciaImpExp[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaBilanciaImpExp[2] > 0 ? 'fa-long-arrow-up verde': deltaBilanciaImpExp[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].quantImport > 0 && pagina[ind + 2].quantExport > 0? ((pagina[ind + 2].quantImport- pagina[ind + 2].quantExport)/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaBilanciaImpExp[3] > 0?'+':''}}{{deltaBilanciaImpExp[3] == 0 ? '-' : (deltaBilanciaImpExp[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaBilanciaImpExp[3] > 0 ? 'fa-long-arrow-up verde': deltaBilanciaImpExp[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].quantExport > 0 ? ((pagina[ind + 3].quantImport - pagina[ind + 3].quantExport)/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolImpExp">
              <div class="col-sm-3 bordo-t padding-left-right-5">Quantit&agrave; import nazionale per prodotto (Q.li)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> Italia </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].quantImport > 0 ? (pagina[0].quantImport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaQuantImport[0] > 0?'+':''}}{{deltaQuantImport[0] == 0 ? '-' : (deltaQuantImport[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaQuantImport[0] > 0 ? 'fa-long-arrow-up verde': deltaQuantImport[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].quantImport > 0 ? (pagina[ind].quantImport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaQuantImport[1] > 0?'+':''}}{{deltaQuantImport[1] == 0 ? '-' : (deltaQuantImport[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaQuantImport[1] > 0 ? 'fa-long-arrow-up verde': deltaQuantImport[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].quantImport > 0 ? (pagina[ind + 1].quantImport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaQuantImport[2] > 0?'+':''}}{{deltaQuantImport[2] == 0 ? '-' : (deltaQuantImport[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaQuantImport[2] > 0 ? 'fa-long-arrow-up verde': deltaQuantImport[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].quantImport > 0 ? (pagina[ind + 2].quantImport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaQuantImport[3] > 0?'+':''}}{{deltaQuantImport[3] == 0 ? '-' : (deltaQuantImport[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaQuantImport[3] > 0 ? 'fa-long-arrow-up verde': deltaQuantImport[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].quantImport > 0 ? (pagina[ind + 3].quantImport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolImpExp">
              <div class="col-sm-3 bordo-t padding-left-right-5">Valore import nazionale per prodotto (€)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> Italia </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].valImport > 0 ? (pagina[0].valImport | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaValImport[0] > 0 ? '+' : ''}}{{deltaValImport[0] == 0 ? '-' : (deltaValImport[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaValImport[0] > 0 ? 'fa-long-arrow-up verde': deltaValImport[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].valImport > 0 ? (pagina[ind].valImport | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaValImport[1] > 0 ? '+' : ''}}{{deltaValImport[1] == 0 ? '-' : (deltaValImport[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaValImport[1] > 0 ? 'fa-long-arrow-up verde': deltaValImport[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].valImport > 0 ? (pagina[ind + 1].valImport | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaValImport[2] > 0 ? '+' : ''}}{{deltaValImport[2] == 0 ? '-' : (deltaValImport[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaValImport[2] > 0 ? 'fa-long-arrow-up verde': deltaValImport[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].valImport > 0 ? (pagina[ind + 2].valImport | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaValImport[3] > 0 ? '+' : ''}}{{deltaValImport[3] == 0 ? '-' : (deltaValImport[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaValImport[3] > 0 ? 'fa-long-arrow-up verde': deltaValImport[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].valImport > 0 ? (pagina[ind + 3].valImport | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolImpExp">
              <div class="col-sm-3 bordo-t padding-left-right-5">Quantit&agrave; export nazionale per prodotto (Q.li)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> Italia </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].quantExport > 0 ? (pagina[0].quantExport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaQuantExport[0] > 0?'+':''}}{{deltaQuantExport[0] == 0 ? '-' : (deltaQuantExport[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaQuantExport[0] > 0 ? 'fa-long-arrow-up verde': deltaQuantExport[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].quantExport > 0 ? (pagina[ind].quantExport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaQuantExport[1] > 0?'+':''}}{{deltaQuantExport[1] == 0 ? '-' : (deltaQuantExport[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaQuantExport[1] > 0 ? 'fa-long-arrow-up verde': deltaQuantExport[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].quantExport > 0 ? (pagina[ind + 1].quantExport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaQuantExport[2] > 0?'+':''}}{{deltaQuantExport[2] == 0 ? '-' : (deltaQuantExport[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaQuantExport[2] > 0 ? 'fa-long-arrow-up verde': deltaQuantExport[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].quantExport > 0 ? (pagina[ind + 2].quantExport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaQuantExport[3] > 0?'+':''}}{{deltaQuantExport[3] == 0 ? '-' : (deltaQuantExport[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaQuantExport[3] > 0 ? 'fa-long-arrow-up verde': deltaQuantExport[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].quantExport > 0 ? (pagina[ind + 3].quantExport/100. | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="row margin-0 sfondo-azzurro" *ngIf="boolImpExp">
              <div class="col-sm-3 bordo-t padding-left-right-5">Valore export nazionale per prodotto (€)</div>
              <div class="col-sm-9 nopadding">
                <div class="row margin-0 row-cols-10 full-height">
                  <div class="col bordo-d nopadding flex-justify-center"> Italia </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[0] && pagina[0].valExport > 0 ? (pagina[0].valExport | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaValExport[0] > 0 ? '+' : ''}}{{deltaValExport[0] == 0 ? '-' : (deltaValExport[0] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaValExport[0] > 0 ? 'fa-long-arrow-up verde': deltaValExport[0] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind] && pagina[ind].valExport > 0 ? (pagina[ind].valExport | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaValExport[1] > 0 ? '+' : ''}}{{deltaValExport[1] == 0 ? '-' : (deltaValExport[1] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaValExport[1] > 0 ? 'fa-long-arrow-up verde': deltaValExport[1] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 1] && pagina[ind + 1].valExport > 0 ? (pagina[ind + 1].valExport | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaValExport[2] > 0 ? '+' : ''}}{{deltaValExport[2] == 0 ? '-' : (deltaValExport[2] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaValExport[2] > 0 ? 'fa-long-arrow-up verde': deltaValExport[2] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 2] && pagina[ind + 2].valExport > 0 ? (pagina[ind + 2].valExport | number: '1.0-0':'it') : 'n.p.'}}</div>
                  <div class="col bordo-d nopadding flex-justify-center">{{deltaValExport[3] > 0 ? '+' : ''}}{{deltaValExport[3] == 0 ? '-' : (deltaValExport[3] | number: '1.0-1':'it')}} %
                  <i class= "fa {{deltaValExport[3] > 0 ? 'fa-long-arrow-up verde': deltaValExport[3] < 0 ? 'fa-long-arrow-down rosso': 'fa-arrows-h blu'}}"></i>
                  </div>
                  <div class="col bordo-d nopadding flex-justify-center">{{pagina[ind + 3] && pagina[ind + 3].valExport > 0 ? (pagina[ind + 3].valExport | number: '1.0-0':'it') : 'n.p.'}}</div>
                </div>
              </div>
            </div>

            <div class="col-12 font-small" *ngIf="pagina[0] && pagina[0].ismea > 0 && utimoMese != '' && ultimoMese != 'dicembre'">
              *Dati aggiornati a {{ultimoMese}}
            </div>
            <div class="row mt-3 margin-0">
              <div class="col-md-4"></div>
              <div class="col-md-2 text-center">
                <button class="btn shadow-none" (click)="indietroVal()">
                <i class="fa fa-arrow-circle-left fa-2x"  aria-hidden="true" placement="top" ngbTooltip="Anno più uno" ></i>
                </button>
              </div>
              <div class="col-md-2 text-center">
                <!-- class="btn btn-link" -->
              <button  class="btn shadow-none" (click)="avantiVal()">
                <i class="fa fa-arrow-circle-right fa-2x"  aria-hidden="true" placement="top" ngbTooltip="Anno meno uno"></i>
              </button>
              </div>
              <div class="col-md-4"></div>
            </div>

          </div>
          
       
          <div class="col-md-4" *ngIf="listaPrezziIsmeaAnno.length > 0">
          </div>
          <div class="col-md-4" *ngIf="listaPrezziIsmeaAnno.length > 0">
            <div class="row row-cols-3 margin-0 text-center">

              <div class="col borderRBlack borderBBlack min-height-25 font-bold">Serie storica ISMEA</div>    
              <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Prezzo</div>
              <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Unit&agrave; di misura</div>

              <div class="col-12 nopadding">
                <div class="row margin-0 row-cols-3" *ngFor="let prezzoAnno of listaPrezziIsmeaAnno">
                  <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                    {{prezzoAnno.anno}}
                    <span class="pull-right pointer" (click)="prezzoAnno.bool = !prezzoAnno.bool; chiudiAnno(prezzoAnno)">
                      <i class="fa fa-2x" [ngClass]="[prezzoAnno.bool? 'fa-caret-up': 'fa-caret-down']"></i>
                    </span>
                  </div>
                  <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{prezzoAnno.prezzo > 0  ? (prezzoAnno.prezzo | number: '1.0-2':'it') : 'n.p.'}}</div>
                  <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{prezzoAnno.unitaMisura}}</div>

                  <div class="col-12 nopadding" *ngIf="prezzoAnno.bool">
                    <div class="row margin-0 row-cols-3" *ngFor="let prezzoMese of prezzoAnno.listaPrezziIsmeaMese">
                      <div class="col borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-space-between">
                        {{prezzoMese.mese}}
                        <span class="pull-right pointer" (click)="prezzoMese.bool = !prezzoMese.bool">
                          <i class="fa" [ngClass]="[prezzoMese.bool? 'fa-caret-up': 'fa-caret-down']"></i>
                        </span>
                      </div>
                      <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{prezzoMese.prezzo > 0  ? (prezzoMese.prezzo | number: '1.0-2':'it') : 'n.p.'}}</div>
                      <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{prezzoMese.unitaMisura}}</div>
                      
                      <div class="col-12 nopadding" *ngIf="prezzoMese.bool">
                        <div class="row margin-0 row-cols-3" *ngFor="let prezzoSettimana of prezzoMese.listaPrezziIsmeaSettimana">
                          <div class="col borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-space-between">
                            Settimana {{prezzoSettimana.settimana}}
                          </div>
                          <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{prezzoSettimana.prezzo > 0  ? (prezzoSettimana.prezzo | number: '1.0-2':'it') : 'n.p.'}}</div>
                          <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{prezzoSettimana.unitaMisura}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>        
        </div>
      </ng-container>
      <!-- FINE MACHINE LEARNING -->
    </div>

    <ng-template #contentPrezzoInserito let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgok }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Registrato</h4>
        <button type="button" class="close" aria-label="Close" (click)="refresh()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Prezzi Inseriti correttamente!</p>
      </div>
    </ng-template>

    <ng-template #contentPrezzoNonInseritoCorrettamente let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgabort }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Errore</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Prezzi non inseriti! Ricontrollare i campi!</p>
      </div>
    </ng-template>

    <ng-template #contentCostoInserito let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgok }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Registrato</h4>
        <button type="button" class="close" aria-label="Close" (click)="refresh()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Costi Inseriti correttamente!</p>
      </div>
    </ng-template>

    <ng-template #contentCostoNonInseritoCorrettamente let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgabort }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Errore</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Costi non inseriti! Ricontrollare i campi!</p>
      </div>
    </ng-template>

    <ng-template #contentReseInserite let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgok }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Registrato</h4>
        <button type="button" class="close" aria-label="Close" (click)="refresh()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Rese Inserite correttamente!</p>
      </div>
    </ng-template>

    <ng-template #contentReseNonInseritoCorrettamente let-c="close" let-d="dismiss">
      <div class="modal-header">
        <img class="mt-2" src="{{ imgabort }}" alt=""/>
        <h4 class="modal-title" id="modal-basic-title">&nbsp;&nbsp;Errore</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Rese non inserite! Ricontrollare i campi!</p>
      </div>
    </ng-template>
  `,
  styles: [`
    hr {
      background-color: #059b5b;
      height: 1px;
      border: 0;
    }
    .bordo-sx {
      border-left: 1px solid #059b5b;
    }
    .sfondo-ml {
      border-radius: 10px;
      margin-bottom: 20px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      border: 1px solid #059b5b;
    }
    .sfondo-azzurro{
      background: #c5cae9;
    }
    .bordo-i {
      background: -webkit-gradient(linear, to left top,to left bottom, from(#A2A3A2), to(#707070));
      background: -webkit-linear-gradient(to top, #A2A3A2, #707070);
      background: -moz-linear-gradient(to top, #A2A3A2, #707070);
      background: -ms-linear-gradient(to top, #A2A3A2, #707070);
      background: -o-linear-gradient(to top, #A2A3A2, #707070);
      background: linear-gradient(to top, #A2A3A2, #707070);
      color: #fff;
      font-weight: 600;
      text-align: center;
    }
    .bordo-t {
      font-weight: 600;
      text-align: left;
      border: 1px solid black;
    }
    .bordo-d {
      font-weight: 700;
      text-align: right;
      border: 1px solid black;
    }
    .bordo-dc {
      font-weight: 700;
      text-align: center;
      border: 1px solid black;
    }
    .m-top {
      margin-top: 5px;
    }
    .destra {
      text-align: right;
    }
    .blu {color: blue; font-weight: bold;}
    .rosso {color: red; font-weight: bold;}
    .verde {color: green; font-weight: bold;}
    .sticky {
      /* background-color: #adadad;
      background-image: url('./assets/img/sfondonav.png'); */
      background: #EFEFEF;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      height: auto;
      width: 100%;
      padding-right: 3px;
    }

  `],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 100 })),
      transition(':enter', [animate(100)]),
      transition(':leave', [animate(300)])
    ])
  ]
})

export class PrezziECostiComponent implements OnInit {
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  imgaccounting = './assets/img/Accounting.png';
  imgsearch = './assets/img/View.png';
  imgabout = './assets/img/About.png';
  imgabort = './assets/img/Abort.png';
  imgok = './assets/img/OK.png';

  prezziecostiForm: UntypedFormGroup;
  distribution = new Gaussian(0, 1);

  form?: Prezzi = {};
  formCosti?: Costi = {};
  formRese?: Rese = {};

  prezzi: Prezzi = {};
  errorMessage = '';

  showPrezzi = true;
  showCosti = false;
  showResa = false;
  showValueML = false;

  amount;
  submitted = false;

  visibilitaRegistra: boolean;
  visibilitaAggiorna: boolean;
  visibilitaElimina: boolean;
  visibilitaReset: boolean;
  MinMagMax = false;
  CostiMinFisMagCostiMaxFis = false;
  MinVarMagMaxVar = false;
  CostiFatMinMagCostiFatMax = false;
  CostiPiantaMinMagCostiPiantaMAx = false;
  NumPianteMinMagNumPianteMax = false;
  PrezzoInizMagPrezzoMin = false;
  CostiVarInizXHaMagCostiVarMinXHa = false;
  ResaMinMagResaMax = false;
  InvendutoMinMagInvendutoMax = false;

  arrayCategorie: any;
  arrayProvince: any;
  arrayProdotti: any;
  arrayVarietaProdotti: any;
  arrayComuni: any;
  idPrezzi = 0;
  idCosti = 0;
  idRese = 0;

  arrayQualitaProduzione: any;
  arrayTipoPrezzo: any;
  annoIncrementatoPrezzi: number;
  annoIncrementatoCosti: number;
  annoIncrementatoRese: number;
  //Marco

  deltaMl = [0, 0, 0, 0];
  deltaStat = [0, 0, 0, 0];
  deltaEo = [0, 0, 0, 0];
  deltaReseEo = [0, 0, 0, 0];
  deltaReseStat = [0, 0, 0, 0];
  deltaSuperfici = [0, 0, 0, 0];
  deltaProduzione = [0, 0, 0, 0];
  deltaReseIstat = [0, 0, 0, 0];
  deltaBilanciaImpExp = [0, 0, 0, 0];
  deltaQuantImport = [0, 0, 0, 0];
  deltaValImport = [0, 0, 0, 0];
  deltaQuantExport = [0, 0, 0, 0];
  deltaValExport = [0, 0, 0, 0];
  deltaIsmea = [0, 0, 0, 0];
  deltaPlt = [0, 0, 0, 0];
  deltaCs = [0, 0, 0, 0];
  deltaMargLor = [0, 0, 0, 0];
  deltaMargOp = [0, 0, 0, 0];
  deltaPrezzoRica = [0, 0, 0, 0];
  deltaResaRica = [0, 0, 0, 0];
  deltaSuperficiNaz = [0, 0, 0, 0];
  deltaProduzioneNaz = [0, 0, 0, 0];
  deltaReseIstatNaz = [0, 0, 0, 0];
  deltaCostiTot = [0, 0, 0, 0];

  pagina: Storico[] = [];
  ml: DatiStorici[] = [];
  ismea: DatiStorici[] = [];
  prezzi_eo: DatiStorici[] = [];
  rese_eo: DatiStorici[] = [];
  superfici: DatiStorici[] = [];
  produzione: DatiStorici[] = [];
  reseIstat: DatiStorici[] = [];
  quantImport: DatiStorici[] = [];
  valImport: DatiStorici[] = [];
  quantExport: DatiStorici[] = [];
  valExport: DatiStorici[] = [];
  ind: number = 1;
  anno_statistica: number = 2011;
  boolImpExp = false;
  boolCostiRica = false;
  boolResaIstat = false;
  boolResaIstatNaz = false;
  ultimoMese = '';
  mlZona = '';
  eoZona = '';
  statZona = '';
  ismeaZona = '';
  reseEoZona = '';
  reseStatZona = '';
  istatZona = '';
  ricaZona = '';
  //fine
  //Riccardo
  listaPrezziIsmeaAnno: any[] = [];
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;

  //ricerca
  categoria: any = {};
  prodotto: any = {};
  varieta: any = {};
  provincia: any = {};
  comune: any = {};
  boolDisableProdotto = true;
  boolDisableVarieta = true;
  boolDisableComune = true;
  //Fine
  drift = false;
  prezzoInizialeIsNumber = false;
  prezzoMassimoisNumber = false;
  prezzoMinimoIsNumber = false;
  driftPrezziIsNumber = false;
  scartoIsNumber = false;

  clickTipoPrezzo = new Subject<string>();
  focusTipoPrezzo = new Subject<string>();
  clickCategorie = new Subject<string>();
  focusCategorie = new Subject<string>();
  clickProdotti = new Subject<string>();
  focusProdotti = new Subject<string>();
  clickVarietaProdotti = new Subject<string>();
  focusVarietaProdotti = new Subject<string>();
  clickProvince = new Subject<string>();
  focusProvince = new Subject<string>();
  clickComuni = new Subject<string>();
  focusComuni = new Subject<string>();
  clickQualitaProduzione = new Subject<string>();
  focusQualitaProduzione = new Subject<string>();

  constructor(
    private fb: UntypedFormBuilder,
    private currencyPipe: CurrencyPipe,
    @Inject(DOCUMENT) document,
    private http: HttpClient,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private decimalPipe: DecimalPipe,
    private tokenStorageService: TokenStorageService
  ) { }

  ngOnInit(): void {
    this.visibilitaRegistra = true;
    this.http
      .get<any[]>(environment.apiUrlQuestionario + '/tipoPrezzo/getAll')
      .subscribe(data => {
        console.log(data);
        this.arrayTipoPrezzo = data;
        console.log(this.arrayTipoPrezzo);
      });
    this.http
      .get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getQualitaProduzione')
      .subscribe(data => {
        console.log(data);
        this.arrayQualitaProduzione = data;
        console.log(this.arrayQualitaProduzione);
      });
    this.http
      .get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/categorie')
      .subscribe(data => {
        console.log(data);
        this.arrayCategorie = data;
      });
    //inizializzazione array dei prodotti antecedente a eventuale scelta della categoria
    this.http//STEP-2
      .get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProdotti')  
      .subscribe(data => {
        console.log(data);
        this.arrayProdotti = data;
      });
    this.http
      .get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAllProvince')
      .subscribe(data => {
        console.log(data);
        this.arrayProvince = data;
      });

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: Event): void {
    const element = document.getElementById('navbar');
    element.classList.add('sticky');
    /* marco
    if (window.pageYOffset > 150) {
 
       if (element) { element.classList.add('sticky'); }
     } else {
       const element = document.getElementById('navbar');
       if (element) { element.classList.remove('sticky'); }
    }
    */
  }

  public togglePrezzi(): void {
    this.showPrezzi = !this.showPrezzi;
  }
  public toggleCosti(): void {
    this.showCosti = !this.showCosti;
  }
  public toggleResa(): void {
    this.showResa = !this.showResa;
  }
  public toggleValueML(): void {
    this.showValueML = !this.showValueML;
  }
  public refresh(): void {
    window.location.reload();
  }
  public stripText(event): void {
    const seperator = '^([0-9])';
    const maskSeperator = new RegExp(seperator, 'g');
    const result = maskSeperator.test(event.key);
    console.log(result);
  }

  getPrezzoIniziale(element): void {
    if (isNaN(element.replace(',', '.').toString()) === false) {
      this.prezzoInizialeIsNumber = false;
      const convertVirgolaInPunto = element.replace(',', '.');
      this.form.prezzoIniziale = convertVirgolaInPunto;
      console.log(this.form.prezzoIniziale);
      this.form.prezzoInizialeConvertito = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
      }).format(this.form.prezzoIniziale);
      console.log(this.form.prezzoInizialeConvertito);
    } else {
      this.prezzoInizialeIsNumber = true;
    }
  }

  getPrezzoMassimo(element): void {
    if (isNaN(element.replace(',', '.').toString()) === false) {
      this.prezzoMassimoisNumber = false;
      const convertVirgolaInPunto = element.replace(',', '.');
      this.form.prezzoMassimo = convertVirgolaInPunto;
      console.log(this.form.prezzoMassimo);
      this.form.prezzoMassimoConvertito = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
      }).format(this.form.prezzoMassimo);
      console.log(this.form.prezzoMassimoConvertito);
    } else {
      this.prezzoMassimoisNumber = true;
    }
  }

  getPrezzoMinimo(element): void {
    if (isNaN(element.replace(',', '.').toString()) === false) {
      this.prezzoMinimoIsNumber = false;
      const convertVirgolaInPunto = element.replace(',', '.');
      this.form.prezzoMinimo = convertVirgolaInPunto;
      console.log(this.form.prezzoMinimo);
      this.form.prezzoMinimoConvertito = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR'
      }).format(this.form.prezzoMinimo);
      console.log(this.form.prezzoMinimoConvertito);

      if (parseFloat(this.form.prezzoMassimo) < parseFloat(this.form.prezzoMinimo)) {
        this.MinMagMax = true;
        console.log(this.MinMagMax);
      } else {
        this.MinMagMax = false;
        console.log(this.MinMagMax);
      }
    } else {
      this.prezzoMinimoIsNumber = true;
    }
  }

  getDrift(element): void {
    if (isNaN(element.replace(',', '.').toString()) === false) {
      this.driftPrezziIsNumber = false;
      this.form.drift = element;
      this.form.driftConvertito = this.form.drift.toString() + '%';
      console.log(this.form.driftConvertito);
      this.drift = false;
    } else {
      this.driftPrezziIsNumber = true;
      this.form.drift = element;
      this.form.driftConvertito = this.form.drift.toString() + '%';
      console.log(this.form.driftConvertito);
      this.drift = true;
    }
  }

  getScarto(element): void {
    if (isNaN(element.replace(',', '.').toString()) === false) {
      this.scartoIsNumber = false;
      this.form.scarto = element;
      this.form.scartoConvertito = this.form.scarto.toString() + '%';
      console.log(this.form.scartoConvertito);
      if (element === 0) {
        console.log(element);
        this.form.scarto = 1;
        this.form.scartoConvertito = this.form.scarto.toString() + '%';
        console.log(this.form.scartoConvertito);
      } else {
        this.form.scarto = element;
        this.form.scartoConvertito = this.form.scarto.toString() + '%';
        console.log(this.form.scartoConvertito);
      }
    } else {
      this.scartoIsNumber = true;
    }
  }

  getAnniPrevisionePrezzo(element): void {
    if (element === 0) {
      console.log(element);
      this.form.anniPrevisionePrezzo = 1;
      const a = Math.abs(
        (1 / this.form.anniPrevisionePrezzo) *
        (this.distribution.ppf(this.form.scarto / 100) *
          Math.sqrt(this.form.anniPrevisionePrezzo) +
          Math.sqrt(this.form.anniPrevisionePrezzo *
            Math.pow(this.distribution.ppf(this.form.scarto / 100), 2) +
            2 * this.form.anniPrevisionePrezzo *
            ((this.form.drift / 100) * this.form.anniPrevisionePrezzo -
              Math.log(this.form.prezzoMassimo / this.form.prezzoIniziale))
          )
        )
      );

      console.log(a);
      this.form.volatilitaRialzo = a.toFixed(2);
      const volatilitaRialzoFormat = this.decimalPipe.transform(a, '1.0-2', 'it');
      this.form.volatilitaRialzoFormattato = volatilitaRialzoFormat;

      const b = Math.abs(
        (1 / this.form.anniPrevisionePrezzo) *
        (this.distribution.ppf(1 - this.form.scarto / 100) *
          Math.sqrt(this.form.anniPrevisionePrezzo) -
          Math.sqrt(this.form.anniPrevisionePrezzo *
            Math.pow(this.distribution.ppf(1 - this.form.scarto / 100), 2) +
            2 * this.form.anniPrevisionePrezzo *
            ((this.form.drift / 100) * this.form.anniPrevisionePrezzo -
              Math.log(this.form.prezzoMinimo / this.form.prezzoIniziale))
          )
        )
      );

      console.log(b);
      this.form.volatilitaRibasso = b.toFixed(2);
      const volatilitaRibassoFormat = this.decimalPipe.transform(b, '1.0-2', 'it');
      this.form.volatilitaRibassoFormattato = volatilitaRibassoFormat;

    } else {
      this.form.anniPrevisionePrezzo = element;
      const c = Math.abs(
        (1 / this.form.anniPrevisionePrezzo) *
        (this.distribution.ppf(this.form.scarto / 100) *
          Math.sqrt(this.form.anniPrevisionePrezzo) +
          Math.sqrt(this.form.anniPrevisionePrezzo *
            Math.pow(this.distribution.ppf(this.form.scarto / 100), 2) +
            2 * this.form.anniPrevisionePrezzo *
            ((this.form.drift / 100) * this.form.anniPrevisionePrezzo -
              Math.log(this.form.prezzoMassimo / this.form.prezzoIniziale))
          )
        )
      );

      console.log(c);
      this.form.volatilitaRialzo = c.toFixed(2);
      const volatilitaRialzoFormat = this.decimalPipe.transform(c, '1.0-2', 'it');
      this.form.volatilitaRialzoFormattato = volatilitaRialzoFormat;

      const d =
        Math.abs(
          (1 / this.form.anniPrevisionePrezzo) *
          (this.distribution.ppf(1 - this.form.scarto / 100) *
            Math.sqrt(this.form.anniPrevisionePrezzo) -
            Math.sqrt(this.form.anniPrevisionePrezzo *
              Math.pow(this.distribution.ppf(1 - this.form.scarto / 100), 2) +
              2 * this.form.anniPrevisionePrezzo *
              ((this.form.drift / 100) * this.form.anniPrevisionePrezzo -
                Math.log(this.form.prezzoMinimo / this.form.prezzoIniziale))
            )
          )
        );

      console.log(d);
      this.form.volatilitaRibasso = d.toFixed(2);
      const volatilitaRibassoFormat = this.decimalPipe.transform(d, '1.0-2', 'it');
      this.form.volatilitaRibassoFormattato = volatilitaRibassoFormat;
    }
  }

  getCostiFissiMinimoXHa(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiFissiMinimoXHa = convertVirgolaInPunto;
    console.log(this.formCosti.costiFissiMinimoXHa);
    this.formCosti.costiFissiMinimoXHaConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiFissiMinimoXHa);
    console.log(this.formCosti.costiFissiMinimoXHaConvertito);

    if (parseFloat(this.formCosti.costiFissiMinimoXHa) > parseFloat(this.formCosti.costiFissiMassimoXHa)) {
      this.CostiMinFisMagCostiMaxFis = true;
      console.log(this.CostiMinFisMagCostiMaxFis);
    } else {
      this.CostiMinFisMagCostiMaxFis = false;
      console.log(this.CostiMinFisMagCostiMaxFis);
    }
  }

  getCostiFissiMassimoXHa(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiFissiMassimoXHa = convertVirgolaInPunto;
    console.log(this.formCosti.costiFissiMassimoXHa);
    this.formCosti.costiFissiMassimoXHaConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiFissiMassimoXHa);
    console.log(this.formCosti.costiFissiMassimoXHaConvertito);
  }

  getCostiVariabiliInizialiXHa(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiVariabiliInizialiXHa = convertVirgolaInPunto;
    console.log(this.formCosti.costiVariabiliInizialiXHa);
    this.formCosti.costiVariabiliInizialiXHaConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiVariabiliInizialiXHa);
    console.log(this.formCosti.costiVariabiliInizialiXHaConvertito);

    if (this.formCosti.costiVariabiliInizialiXHa > this.formCosti.costiVariabiliMinimoXHa) {
      this.CostiVarInizXHaMagCostiVarMinXHa = true;
      console.log(this.CostiVarInizXHaMagCostiVarMinXHa);
    } else {
      this.CostiVarInizXHaMagCostiVarMinXHa = false;
      console.log(this.CostiVarInizXHaMagCostiVarMinXHa);
    }
  }

  getCostiVariabiliMinimoXHa(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiVariabiliMinimoXHa = convertVirgolaInPunto;
    console.log(this.formCosti.costiVariabiliMinimoXHa);
    this.formCosti.costiVariabiliMinimoXHaConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiVariabiliMinimoXHa);
    console.log(this.formCosti.costiVariabiliMinimoXHaConvertito);

    if (parseFloat(this.formCosti.costiVariabiliMinimoXHa) > parseFloat(this.formCosti.costiVariabiliMassimoXHa)) {
      this.MinVarMagMaxVar = true;
      console.log(this.MinVarMagMaxVar);
    } else {
      this.MinVarMagMaxVar = false;
      console.log(this.MinVarMagMaxVar);
    }
  }

  getCostiVariabiliMassimoXHa(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiVariabiliMassimoXHa = convertVirgolaInPunto;
    console.log(this.formCosti.costiVariabiliMassimoXHa);
    this.formCosti.costiVariabiliMassimoXHaConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiVariabiliMassimoXHa);
    console.log(this.formCosti.costiVariabiliMassimoXHaConvertito);
  }

  getScartoCosti(element): void {
    this.formCosti.scartoCosti = element;
    this.formCosti.scartoCostiConvertito = this.formCosti.scartoCosti.toString() + '%';
    console.log(this.formCosti.scartoCostiConvertito);
    if (element === 0) {
      console.log(element);
      this.formCosti.scartoCosti = 1;
      this.formCosti.scartoCostiConvertito = this.formCosti.scartoCosti.toString() + '%';
      console.log(this.formCosti.scartoCostiConvertito);
    } else {
      this.formCosti.scartoCosti = element;
      this.formCosti.scartoCostiConvertito = this.formCosti.scartoCosti.toString() + '%';
      console.log(this.formCosti.scartoCostiConvertito);
    }
  }

  getAnniPrevisioneCosti(element): void {
    if (element === 0) {
      console.log(element);
      this.formCosti.anniPrevisioneCosti = 1;
      const a = Math.abs((1 / this.formCosti.anniPrevisioneCosti) *
        (this.distribution.ppf(this.formCosti.scartoCosti / 100) *
          Math.sqrt(this.formCosti.anniPrevisioneCosti) +
          Math.sqrt(this.formCosti.anniPrevisioneCosti *
            Math.pow(this.distribution.ppf(this.formCosti.scartoCosti / 100), 2) +
            2 * this.formCosti.anniPrevisioneCosti *
            ((this.formCosti.driftCosti / 100) * this.formCosti.anniPrevisioneCosti -
              Math.log(this.formCosti.costiVariabiliMassimoXHa / this.formCosti.costiVariabiliInizialiXHa))
          )
        )
      );

      console.log(a);
      this.formCosti.volatilitaCostiRialzo = a.toFixed(2);
      const volatilitaCostiRialzoFormat = this.decimalPipe.transform(a, '1.0-2', 'it');
      this.formCosti.volatilitaCostiRialzoFormattato = volatilitaCostiRialzoFormat;

      const b = Math.abs(
        (1 / this.formCosti.anniPrevisioneCosti) *
        (this.distribution.ppf(1 - this.formCosti.scartoCosti / 100) *
          Math.sqrt(this.formCosti.anniPrevisioneCosti) -
          Math.sqrt(this.formCosti.anniPrevisioneCosti *
            Math.pow(this.distribution.ppf(1 - this.formCosti.scartoCosti / 100), 2) +
            2 * this.formCosti.anniPrevisioneCosti *
            ((this.formCosti.driftCosti / 100) * this.formCosti.anniPrevisioneCosti -
              Math.log(this.formCosti.costiVariabiliMinimoXHa / this.formCosti.costiVariabiliInizialiXHa))
          )
        )
      );

      console.log(b);
      this.formCosti.volatilitaCostiRibasso = b.toFixed(2);
      const volatilitaCostiRibassoFormat = this.decimalPipe.transform(b, '1.0-2', 'it');
      this.formCosti.volatilitaCostiRibassoFormattato = volatilitaCostiRibassoFormat;

    } else {
      this.formCosti.anniPrevisioneCosti = element;
      const c = Math.abs(
        (1 / this.formCosti.anniPrevisioneCosti) *
        (this.distribution.ppf(this.formCosti.scartoCosti / 100) *
          Math.sqrt(this.formCosti.anniPrevisioneCosti) +
          Math.sqrt(this.formCosti.anniPrevisioneCosti *
            Math.pow(this.distribution.ppf(this.formCosti.scartoCosti / 100), 2) +
            2 * this.formCosti.anniPrevisioneCosti *
            ((this.formCosti.driftCosti / 100) * this.formCosti.anniPrevisioneCosti -
              Math.log(this.formCosti.costiVariabiliMassimoXHa / this.formCosti.costiVariabiliInizialiXHa))
          )
        )
      );

      console.log(c);
      this.formCosti.volatilitaCostiRialzo = c.toFixed(2);
      const volatilitaCostiRialzoFormat = this.decimalPipe.transform(c, '1.0-2', 'it');
      this.formCosti.volatilitaCostiRialzoFormattato = volatilitaCostiRialzoFormat;

      const d = Math.abs(
        (1 / this.formCosti.anniPrevisioneCosti) *
        (this.distribution.ppf(1 - this.formCosti.scartoCosti / 100) *
          Math.sqrt(this.formCosti.anniPrevisioneCosti) -
          Math.sqrt(this.formCosti.anniPrevisioneCosti *
            Math.pow(this.distribution.ppf(1 - this.formCosti.scartoCosti / 100), 2) +
            2 * this.formCosti.anniPrevisioneCosti *
            ((this.formCosti.driftCosti / 100) * this.formCosti.anniPrevisioneCosti -
              Math.log(this.formCosti.costiVariabiliMinimoXHa / this.formCosti.costiVariabiliInizialiXHa))
          )
        )
      );

      console.log(d);
      this.formCosti.volatilitaCostiRibasso = d.toFixed(2);
      const volatilitaCostiRibassoFormat = this.decimalPipe.transform(d, '1.0-2', 'it');
      this.formCosti.volatilitaCostiRibassoFormattato = volatilitaCostiRibassoFormat;
    }
  }

  getDriftCosti(element): void {
    this.formCosti.driftCosti = element;
    this.formCosti.driftCostiConvertito = this.formCosti.driftCosti.toString() + '%';
    console.log(this.formCosti.driftCostiConvertito);
  }

  getCostiFatturatoMinimo(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiFatturatoMinimo = convertVirgolaInPunto;
    console.log(this.formCosti.costiFatturatoMinimo);
    this.formCosti.costiFatturatoMinimoConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiFatturatoMinimo);
    console.log(this.formCosti.costiFatturatoMinimoConvertito);

    if (parseFloat(this.formCosti.costiFatturatoMinimo) > parseFloat(this.formCosti.costiFatturatoMassimo)) {
      this.CostiFatMinMagCostiFatMax = true;
      console.log(this.CostiFatMinMagCostiFatMax);
    } else {
      this.CostiFatMinMagCostiFatMax = false;
      console.log(this.CostiFatMinMagCostiFatMax);
    }
  }

  getCostiFatturatoMassimo(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiFatturatoMassimo = convertVirgolaInPunto;
    console.log(this.formCosti.costiFatturatoMassimo);
    this.formCosti.costiFatturatoMassimoConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiFatturatoMassimo);
    console.log(this.formCosti.costiFatturatoMassimoConvertito);
  }

  getNumeroPianteMassimoXHa(element): void {
    this.formCosti.numeroPianteMassimoXHa = element;
  }

  getNumeroPianteMinimoXHa(element): void {
    this.formCosti.numeroPianteMinimoXHa = element;
    if (parseFloat(this.formCosti.numeroPianteMinimoXHa) > parseFloat(this.formCosti.numeroPianteMassimoXHa)) {
      this.NumPianteMinMagNumPianteMax = true;
      console.log(this.NumPianteMinMagNumPianteMax);
    } else {
      this.NumPianteMinMagNumPianteMax = false;
      console.log(this.NumPianteMinMagNumPianteMax);
    }
  }

  getCostiPiantaMinimo(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiPiantaMinimo = convertVirgolaInPunto;
    console.log(this.formCosti.costiPiantaMinimo);
    this.formCosti.costiPiantaMinimoConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiPiantaMinimo);
    console.log(this.formCosti.costiPiantaMinimoConvertito);

    if (parseFloat(this.formCosti.costiPiantaMinimo) > parseFloat(this.formCosti.costiPiantaMassimo)) {
      this.CostiPiantaMinMagCostiPiantaMAx = true;
      console.log(this.CostiPiantaMinMagCostiPiantaMAx);
    } else {
      this.CostiPiantaMinMagCostiPiantaMAx = false;
      console.log(this.CostiPiantaMinMagCostiPiantaMAx);
    }
  }

  getCostiPiantaMassimo(element): void {
    const convertVirgolaInPunto = element.replace(',', '.');
    this.formCosti.costiPiantaMassimo = convertVirgolaInPunto;
    console.log(this.formCosti.costiPiantaMassimo);
    this.formCosti.costiPiantaMassimoConvertito = new Intl.NumberFormat(
      'it-IT',
      {
        style: 'currency',
        currency: 'EUR'
      }
    ).format(this.formCosti.costiPiantaMassimo);
    console.log(this.formCosti.costiPiantaMassimoConvertito);
  }

  getResaMassimaXHa(element): void {
    this.formRese.resaMassimaXHa = element;
    console.log(this.formRese.resaMassimaXHa);
  }

  getResaInizialeXHa(element): void {
    this.formRese.reseInizialiXHa = element;
    console.log(this.formRese.reseInizialiXHa);
  }

  getResaMinimaXHa(element): void {
    this.formRese.resaMinimaXHa = element;
    console.log(this.formRese.resaMinimaXHa);

    if (parseFloat(this.formRese.resaMinimaXHa) > parseFloat(this.formRese.resaMassimaXHa)) {
      this.ResaMinMagResaMax = true;
      console.log(this.ResaMinMagResaMax);
    } else {
      this.ResaMinMagResaMax = false;
      console.log(this.ResaMinMagResaMax);
    }
  }

  getDriftResa(element): void {
    this.formRese.driftResa = element;
    this.formRese.driftResaConvertito = this.formRese.driftResa.toString() + '%';
    console.log(this.formRese.driftResaConvertito);
  }

  getScartoResa(element): void {
    this.formRese.scartoResa = element;
    this.formRese.scartoResaConvertito = this.formRese.scartoResa.toString() + '%';
    console.log(this.formRese.scartoResaConvertito);

    if (element === 0) {
      console.log(element);
      this.formRese.scartoResa = 1;
      this.formRese.scartoResaConvertito = this.formRese.scartoResa.toString() + '%';
      console.log(this.formRese.scartoResaConvertito);
    } else {
      this.formRese.scartoResa = element;
      this.formRese.scartoResaConvertito = this.formRese.scartoResa.toString() + '%';
      console.log(this.formRese.scartoResaConvertito);
    }
  }

  getAnniPrevisioneResa(element): void {
    if (element === 0) {
      console.log(element);
      this.formRese.anniPrevisioneResa = 1;
      const a = Math.abs(
        (1 / this.formRese.anniPrevisioneResa) *
        (this.distribution.ppf(this.formRese.scartoResa / 100) *
          Math.sqrt(this.formRese.anniPrevisioneResa) +
          Math.sqrt(this.formRese.anniPrevisioneResa *
            Math.pow(this.distribution.ppf(this.formRese.scartoResa / 100), 2) +
            2 * this.formRese.anniPrevisioneResa *
            ((this.formRese.driftResa / 100) * this.formRese.anniPrevisioneResa -
              Math.log(this.formRese.resaMassimaXHa / this.formRese.reseInizialiXHa))
          )
        )
      );

      console.log(a);
      this.formRese.volatilitaReseRialzo = a.toFixed(2);
      const volatilitaReseRialzoFormat = this.decimalPipe.transform(a, '1.0-2', 'it');
      this.formRese.volatilitaReseRialzoFormattato = volatilitaReseRialzoFormat;

      const b = Math.abs(
        (1 / this.formRese.anniPrevisioneResa) *
        (this.distribution.ppf(1 - this.formRese.scartoResa / 100) *
          Math.sqrt(this.formRese.anniPrevisioneResa) -
          Math.sqrt(this.formRese.anniPrevisioneResa *
            Math.pow(this.distribution.ppf(1 - this.formRese.scartoResa / 100), 2) +
            2 * this.formRese.anniPrevisioneResa *
            ((this.formRese.driftResa / 100) * this.formRese.anniPrevisioneResa -
              Math.log(this.formRese.resaMinimaXHa / this.formRese.reseInizialiXHa))
          )
        )
      );

      console.log(b);
      this.formRese.volatilitaReseRibasso = b.toFixed(2);
      const volatilitaReseRibasso = this.decimalPipe.transform(b, '1.0-2', 'it');
      this.formRese.volatilitaReseRibassoFormattato = volatilitaReseRibasso;

    } else {
      this.formRese.anniPrevisioneResa = element;
      const c = Math.abs(
        (1 / this.formRese.anniPrevisioneResa) *
        (this.distribution.ppf(this.formRese.scartoResa / 100) *
          Math.sqrt(this.formRese.anniPrevisioneResa) +
          Math.sqrt(this.formRese.anniPrevisioneResa *
            Math.pow(this.distribution.ppf(this.formRese.scartoResa / 100), 2) +
            2 * this.formRese.anniPrevisioneResa *
            ((this.formRese.driftResa / 100) * this.formRese.anniPrevisioneResa -
              Math.log(this.formRese.resaMassimaXHa / this.formRese.reseInizialiXHa))
          )
        )
      );

      console.log(c);
      this.formRese.volatilitaReseRialzo = c.toFixed(2);
      const volatilitaReseRialzo = this.decimalPipe.transform(c, '1.0-2', 'it');
      this.formRese.volatilitaReseRialzoFormattato = volatilitaReseRialzo;

      const d = Math.abs(
        (1 / this.formRese.anniPrevisioneResa) *
        (this.distribution.ppf(1 - this.formRese.scartoResa / 100) *
          Math.sqrt(this.formRese.anniPrevisioneResa) -
          Math.sqrt(this.formRese.anniPrevisioneResa *
            Math.pow(this.distribution.ppf(1 - this.formRese.scartoResa / 100), 2) +
            2 * this.formRese.anniPrevisioneResa *
            ((this.formRese.driftResa / 100) * this.formRese.anniPrevisioneResa -
              Math.log(this.formRese.resaMinimaXHa / this.formRese.reseInizialiXHa))
          )
        )
      );

      console.log(d);
      this.formRese.volatilitaReseRibasso = d.toFixed(2);
      const volatilitaReseRibasso = this.decimalPipe.transform(d, '1.0-2', 'it');
      this.formRese.volatilitaReseRibassoFormattato = volatilitaReseRibasso;
    }
  }

  getInvendutoIniziale(element): void {
    this.formRese.invendutoIniziale = element;
    this.formRese.invendutoInizialeConvertito = this.formRese.invendutoIniziale.toString() + '%';
    console.log(this.formRese.invendutoInizialeConvertito);
  }

  getInvendutoMassimo(element): void {
    this.formRese.invendutoMassimo = element;
    this.formRese.invendutoMassimoConvertito = this.formRese.invendutoMassimo.toString() + '%';
    console.log(this.formRese.invendutoMassimoConvertito);
  }

  getInvendutoMinimo(element): void {
    this.formRese.invendutoMinimo = element;
    this.formRese.invendutoMinimoConvertito = this.formRese.invendutoMinimo.toString() + '%';
    console.log(this.formRese.invendutoMinimoConvertito);

    if (parseFloat(this.formRese.invendutoMinimo) > parseFloat(this.formRese.invendutoMassimo)) {
      this.InvendutoMinMagInvendutoMax = true;
      console.log(this.InvendutoMinMagInvendutoMax);
    } else {
      this.InvendutoMinMagInvendutoMax = false;
      console.log(this.InvendutoMinMagInvendutoMax);
    }
  }

  getDriftInvenduto(element): void {
    this.formRese.driftInvenduto = element;
    this.formRese.driftInvendutoConvertito = this.formRese.driftInvenduto.toString() + '%';
    console.log(this.formRese.driftInvendutoConvertito);
  }

  getScartoInvenduto(element): void {
    this.formRese.scartoInvenduto = element;
    this.formRese.scartoInvendutoConvertito = this.formRese.scartoInvenduto.toString() + '%';
    console.log(this.formRese.scartoInvendutoConvertito);
    if (element === 0) {
      console.log(element);
      this.formRese.scartoInvenduto = 1;
      this.formRese.scartoInvendutoConvertito = this.formRese.scartoInvenduto.toString() + '%';
      console.log(this.formRese.scartoInvendutoConvertito);
    } else {
      this.formRese.scartoInvenduto = element;
      this.formRese.scartoInvendutoConvertito = this.formRese.scartoInvenduto.toString() + '%';
      console.log(this.formRese.scartoInvendutoConvertito);
    }
  }

  getAnniPrevisioneInvenduto(element): void {
    if (element === 0) {
      console.log(element);
      this.formRese.anniPrevisioneInvenduto = 1;
      const a = Math.abs(
        (1 / this.formRese.anniPrevisioneInvenduto) *
        (this.distribution.ppf(this.formRese.scartoInvenduto / 100) *
          Math.sqrt(this.formRese.anniPrevisioneInvenduto) +
          Math.sqrt(this.formRese.anniPrevisioneInvenduto *
            Math.pow(this.distribution.ppf(this.formRese.scartoInvenduto / 100), 2) +
            2 * this.formRese.anniPrevisioneInvenduto *
            ((this.formRese.driftInvenduto / 100) * this.formRese.anniPrevisioneInvenduto -
              Math.log(this.formRese.invendutoMassimo / this.formRese.invendutoIniziale))
          )
        )
      );

      console.log(a);
      this.formRese.volatilitaInvendutoRialzo = a.toFixed(2);
      const volatilitaInvendutoRialzo = this.decimalPipe.transform(a, '1.0-2', 'it');
      this.formRese.volatilitaInvendutoRialzoFormattato = volatilitaInvendutoRialzo;

      const b = Math.abs(
        (1 / this.formRese.anniPrevisioneInvenduto) *
        (this.distribution.ppf(1 - this.formRese.scartoInvenduto / 100) *
          Math.sqrt(this.formRese.anniPrevisioneInvenduto) -
          Math.sqrt(this.formRese.anniPrevisioneInvenduto *
            Math.pow(this.distribution.ppf(1 - this.formRese.scartoInvenduto / 100), 2) +
            2 * this.formRese.anniPrevisioneInvenduto *
            ((this.formRese.driftInvenduto / 100) * this.formRese.anniPrevisioneInvenduto -
              Math.log(this.formRese.invendutoMinimo / this.formRese.invendutoIniziale))
          )
        )
      );

      console.log(b);
      this.formRese.volatilitaInvendutoRibasso = b.toFixed(2);
      const volatilitaInvendutoRibasso = this.decimalPipe.transform(b, '1.0-2', 'it');
      this.formRese.volatilitaInvendutoRibassoFormattato = volatilitaInvendutoRibasso;

    } else {
      this.formRese.anniPrevisioneInvenduto = element;
      const c = Math.abs(
        (1 / this.formRese.anniPrevisioneInvenduto) *
        (this.distribution.ppf(this.formRese.scartoInvenduto / 100) *
          Math.sqrt(this.formRese.anniPrevisioneInvenduto) +
          Math.sqrt(this.formRese.anniPrevisioneInvenduto *
            Math.pow(this.distribution.ppf(this.formRese.scartoInvenduto / 100), 2) +
            2 * this.formRese.anniPrevisioneInvenduto *
            ((this.formRese.driftInvenduto / 100) * this.formRese.anniPrevisioneInvenduto -
              Math.log(this.formRese.invendutoMassimo / this.formRese.invendutoIniziale))
          )
        )
      );

      console.log(c);
      this.formRese.volatilitaInvendutoRialzo = c.toFixed(2);
      const volatilitaInvendutoRialzo = this.decimalPipe.transform(c, '1.0-2', 'it');
      this.formRese.volatilitaInvendutoRialzoFormattato = volatilitaInvendutoRialzo;

      this.formRese.volatilitaInvendutoRibasso =
        Math.abs(
          (1 / this.formRese.anniPrevisioneInvenduto) *
          (this.distribution.ppf(1 - this.formRese.scartoInvenduto / 100) *
            Math.sqrt(this.formRese.anniPrevisioneInvenduto) -
            Math.sqrt(this.formRese.anniPrevisioneInvenduto *
              Math.pow(this.distribution.ppf(1 - this.formRese.scartoInvenduto / 100), 2) +
              2 * this.formRese.anniPrevisioneInvenduto *
              ((this.formRese.driftInvenduto / 100) * this.formRese.anniPrevisioneInvenduto -
                Math.log(this.formRese.invendutoMinimo / this.formRese.invendutoIniziale))
            )
          )
        );

      console.log(c);
      this.formRese.volatilitaInvendutoRibasso = c.toFixed(2);
      const volatilitaInvendutoRibasso = this.decimalPipe.transform(c, '1.0-2', 'it');
      this.formRese.volatilitaInvendutoRibassoFormattato = volatilitaInvendutoRibasso;
    }
  }

  searchQualitaProduzione = (textQualitaProduzione: Observable<string>) => {
    const debouncedText$ = textQualitaProduzione.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickQualitaProduzione.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focusQualitaProduzione;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ?
          this.arrayQualitaProduzione : this.arrayQualitaProduzione.filter((v) =>
            v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
      )
    );
  }

  searchTipoPrezzo = (textTipoPrezzo: Observable<string>) => {
    const debouncedText$ = textTipoPrezzo.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickTipoPrezzo.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focusTipoPrezzo;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ?
          this.arrayTipoPrezzo : this.arrayTipoPrezzo.filter((v) =>
            v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
      )
    );
  }

  searchCategorie = (textCategorie: Observable<string>) => {
    const debouncedText$ = textCategorie.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickCategorie.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focusCategorie;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ?
          this.arrayCategorie : this.arrayCategorie.filter((v) =>
            v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
      )
    );
  }

  searchProdotti = (textProdotti: Observable<string>) => {
    const debouncedText$ = textProdotti.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickProdotti.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focusProdotti;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ?
          this.arrayProdotti : this.arrayProdotti.filter((v) =>
            v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
      )
    );
  }

  searchVarietaProdotti = (textVarietaProdotti: Observable<string>) => {
    const debouncedText$ = textVarietaProdotti.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickVarietaProdotti.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focusVarietaProdotti;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ?
          this.arrayVarietaProdotti : this.arrayVarietaProdotti.filter((v) =>
            v.descrizione.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
      )
    );
  }

  searchProvince = (textProvince: Observable<string>) => {
    const debouncedText$ = textProvince.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickProvince.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focusProvince;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ?
          this.arrayProvince : this.arrayProvince.filter((v) =>
            v.provincia.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
      )
    );
  }

  searchComuni = (textComuni: Observable<string>) => {
    const debouncedText$ = textComuni.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickComuni.pipe(
      filter(() => this.instance && !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focusComuni;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        term === '' ?
          this.arrayComuni : this.arrayComuni.filter((v) =>
            v.comune.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
      )
    );
  }

  formatter = (x: { descrizione: string }) => x.descrizione;
  formatterProvince = (x: { provincia: string }) => x.provincia;
  formatterComuni = (x: { comune: string }) => x.comune;

  // REGISTRA : PREZZI
  onClick(f: NgForm, content, contentError): void {

    console.log(f.form);
    if (f.form.valid
      && this.PrezzoInizMagPrezzoMin === false
      && this.MinMagMax === false
      && this.prezzoMassimoisNumber === false
      && this.prezzoInizialeIsNumber === false
      && this.prezzoMinimoIsNumber === false
      && this.driftPrezziIsNumber === false
      && this.scartoIsNumber === false) {
      console.log('TUTTO OK');

      const dataInizioValidita = new Date();
      const dataFineValidita = new Date();
      const anniPrevisPrezzo = Math.floor(this.form.anniPrevisionePrezzo);
      dataFineValidita.setFullYear(dataInizioValidita.getFullYear() + anniPrevisPrezzo);
      dataInizioValidita.setDate(dataInizioValidita.getDate() + 1);

      const prezzi = {
        id: this.idPrezzi,
        idProdottoVarieta: this.varieta.id,
        idQualitaProduzione: this.form.id_qualita_produzione,
        idRegione: this.provincia.idRegione,
        idProvincia: this.provincia.id,
        idComune: this.comune.id,
        idTipoPrezzo: this.form.id_tipo_prezzo,
        dataInizioValidita: dataInizioValidita,
        dataFineValidita: dataFineValidita,
        prezzoIniziale: this.form.prezzoIniziale,
        prezzoMax: this.form.prezzoMassimo,
        prezzoMin: this.form.prezzoMinimo,
        volRialzo: this.form.volatilitaRialzo,
        volRibasso: this.form.volatilitaRibasso,
        drift: this.form.drift,
        scarto: this.form.scarto,
        durataPrevisioneAnni: this.form.anniPrevisionePrezzo,
        divisa: '€',
        utente: this.tokenStorageService.getUser().username
      };

      this.http
        .post<any[]>(environment.apiUrlQuestionario + '/prezzo/salvataggio', prezzi)
        .subscribe(data => {
          console.log(data);
        });

      this.modalService.open(content, { centered: true });

    } else {
      console.log('NO');
      this.modalService.open(contentError, { centered: true });

      return;
    }
  }

  // REGISTRA : COSTI
  onClickCosti(fCosti: NgForm, content, contentError): void {

    console.log(fCosti.form);
    if (fCosti.form.valid
      && this.CostiVarInizXHaMagCostiVarMinXHa === false
      && this.CostiMinFisMagCostiMaxFis === false
      && this.MinVarMagMaxVar === false
      && this.CostiFatMinMagCostiFatMax === false
      && this.CostiPiantaMinMagCostiPiantaMAx === false) {
      console.log('TUTTO OK');

      const dataInizioValidita = new Date();
      const dataFineValidita = new Date();
      const anniPrevisCosti = Math.floor(this.formCosti.anniPrevisioneCosti);
      dataFineValidita.setFullYear(dataInizioValidita.getFullYear() + anniPrevisCosti);
      dataInizioValidita.setDate(dataInizioValidita.getDate() + 1);

      const costi = {
        id: this.idCosti,
        idProdottoVarieta: this.varieta.id,
        idRegione: this.provincia.idRegione,
        idProvincia: this.provincia.id,
        idComune: this.comune.id,
        costiFissiMinHa: this.formCosti.costiFissiMinimoXHa,
        costiFissiMaxHa: this.formCosti.costiFissiMassimoXHa,
        costiVariabiliInizHa: this.formCosti.costiVariabiliInizialiXHa,
        costiVariabiliMaxHa: this.formCosti.costiVariabiliMassimoXHa,
        costiVariabiliMinHa: this.formCosti.costiVariabiliMinimoXHa,
        volCostiRialzo: this.formCosti.volatilitaCostiRialzo,
        volCostiRibasso: this.formCosti.volatilitaCostiRibasso,
        driftCosti: this.formCosti.driftCosti,
        scartoCosti: this.formCosti.scartoCosti,
        anniPrevisioneCosti: this.formCosti.anniPrevisioneCosti,
        costiFatturatoMin: this.formCosti.costiFatturatoMinimo,
        costiFatturatoMax: this.formCosti.costiFatturatoMassimo,
        numPianteMinHa: this.formCosti.numeroPianteMinimoXHa,
        numPianteMaxHa: this.formCosti.numeroPianteMassimoXHa,
        costiPiantaMin: this.formCosti.costiPiantaMinimo,
        costiPiantaMax: this.formCosti.costiPiantaMassimo,
        dataInizioValidita: dataInizioValidita,
        dataFineValidita: dataFineValidita,
        divisa: '€',
        utente: this.tokenStorageService.getUser().username
      };

      this.http
        .post<any[]>(environment.apiUrlQuestionario + '/costi/salvataggioCosti', costi)
        .subscribe(data => {
          console.log(data);
        });

      this.modalService.open(content, { centered: true });

    } else {
      console.log('NO');
      this.modalService.open(contentError, { centered: true });

      return;
    }
  }

  getQualita(event): void {
    this.form.id_qualita_produzione = event.item.id;
    console.log(event.item.id);
    console.log(this.form.id_qualita_produzione);
  }

  getTipoPrezzo(event): void {
    this.form.id_tipo_prezzo = event.item.id;
    console.log(event.item.id);
    console.log(this.form.id_tipo_prezzo);
  }

  getCategoria(event): void {
    this.arrayProdotti = [];
    if (event) {
      this.http
        .post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProdottoCategoria', event.id)
        .subscribe(data => {
          this.arrayProdotti = data;
          this.boolDisableProdotto = false;
        });
      }else{
        this.prodotto = {};
        this.http.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProdotti')//STEP-4 refill della proprietà categoria
        .subscribe(data => {
          console.log(data);
          this.arrayProdotti = data;
        });
        this.varieta = {};
        this.boolDisableVarieta = true;
      }
    
  }

  getProdotto(event): void {
    this.arrayVarietaProdotti = [];
    if(event) {
      
      this.arrayCategorie.forEach( categoria => {
          if(categoria.id == event.idCategoria){
             this.categoria = categoria;
          }
      })

      /*this.http// STEP-3
      .get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getCategoriaDatoProdotto')
      .subscribe(data => {
        this.categoria = data;
      });*/
      this.http
      .post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getRicercaProdottoVarieta', event.id)
      .subscribe(data => {
        this.arrayVarietaProdotti = data;
        this.boolDisableVarieta = false;
      });
    }else{
      this.varieta = {};
      this.boolDisableVarieta = true;
    }

  }
  getProvince(event): void {

    this.arrayComuni = [];
    if(event) {
      this.http
      .post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTerritoriByIdProvincia', event.id)
      .subscribe(data => {
        this.arrayComuni = data;
        this.boolDisableComune = false;
      });

    }else{
      this.comune = {};
      this.boolDisableComune = true;
    }

  }

  ricerca(): void {
    const body = {
      id_comunePassato: this.comune.id,
      id_prodottoVarietaPassato: this.varieta.id
    };

    this.http
      .post<any>(environment.apiUrlQuestionario + '/prezzo/getPrezzo', body)
      .subscribe(data => {
        console.log('DB prezzi: ' + data);
        if (data != null) {

          this.idPrezzi = data.id;
          console.log('id prezzi: ' + this.idPrezzi);

          if (data.idQualitaProduzione > 0) {
            for (const i in this.arrayQualitaProduzione) {
              if (data.idQualitaProduzione === this.arrayQualitaProduzione[i].id) {
                this.form.qualitaProduzione = this.arrayQualitaProduzione[i];
                this.form.id_qualita_produzione =  this.arrayQualitaProduzione[i].id;
                console.log(this.form.qualitaProduzione);
              }
            }
          }

          if (data.idTipoPrezzo > 0) {
            for (const i in this.arrayTipoPrezzo) {
              if (data.idTipoPrezzo === this.arrayTipoPrezzo[i].id) {
                this.form.tipoPrezzo = this.arrayTipoPrezzo[i];
                this.form.id_tipo_prezzo =  this.arrayTipoPrezzo[i].id;
                console.log(this.form.tipoPrezzo);
              }
            }
          }

          this.form.prezzoIniziale = data.prezzoIniziale;
          console.log(this.form.prezzoIniziale);
          this.form.prezzoInizialeConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.form.prezzoIniziale);

          this.form.prezzoMassimo = data.prezzoMax;
          console.log(this.form.prezzoIniziale);
          this.form.prezzoMassimoConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.form.prezzoMassimo);

          this.form.prezzoMinimo = data.prezzoMin;
          console.log(this.form.prezzoIniziale);
          this.form.prezzoMinimoConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.form.prezzoMinimo);

          this.form.drift = data.drift;
          this.form.scarto = data.scarto;

          this.form.anniPrevisionePrezzo = data.durataPrevisioneAnni;

          this.form.volatilitaRialzo = data.volRialzo.toFixed(2);
          const volatilitaRialzoFormat = this.decimalPipe.transform(data.volRialzo, '1.0-2', 'it');
          this.form.volatilitaRialzoFormattato = volatilitaRialzoFormat;

          this.form.volatilitaRibasso = data.volRibasso.toFixed(2);
          const volatilitaRibassoFormat = this.decimalPipe.transform(data.volRibasso, '1.0-2', 'it');
          this.form.volatilitaRibassoFormattato = volatilitaRibassoFormat;
        } else {
          this.form = {};
        }
      });

    this.http
      .post<any>(environment.apiUrlQuestionario + '/costi/getCosto', body)
      .subscribe(data => {
        console.log('DB costi: ' + data);
        if (data != null) {

          this.idCosti = data.id;
          console.log('id costi: ' + this.idCosti);

          this.formCosti.costiFissiMassimoXHa = data.costiFissiMaxHa;
          this.formCosti.costiFissiMassimoXHaConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiFissiMassimoXHa);

          this.formCosti.costiFissiMinimoXHa = data.costiFissiMinHa;
          this.formCosti.costiFissiMinimoXHaConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiFissiMinimoXHa);

          this.formCosti.costiVariabiliInizialiXHa = data.costiVariabiliInizHa;
          this.formCosti.costiVariabiliInizialiXHaConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiVariabiliInizialiXHa);

          this.formCosti.costiVariabiliMassimoXHa = data.costiVariabiliMaxHa;
          this.formCosti.costiVariabiliMassimoXHaConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiVariabiliMassimoXHa);

          this.formCosti.costiVariabiliMinimoXHa = data.costiVariabiliMinHa;
          this.formCosti.costiVariabiliMinimoXHaConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiVariabiliMinimoXHa);

          this.formCosti.scartoCosti = data.scartoCosti;
          this.formCosti.driftCosti = data.driftCosti;

          this.formCosti.costiFatturatoMassimo = data.costiFatturatoMax;
          this.formCosti.costiFatturatoMassimoConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiFatturatoMassimo);

          this.formCosti.costiFatturatoMinimo = data.costiFatturatoMin;
          this.formCosti.costiFatturatoMinimoConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiFatturatoMinimo);

          this.formCosti.numeroPianteMassimoXHa = data.numPianteMaxHa;
          this.formCosti.numeroPianteMinimoXHa = data.numPianteMinHa;

          this.formCosti.costiPiantaMassimo = data.costiPiantaMax;
          this.formCosti.costiPiantaMassimoConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiPiantaMassimo);

          this.formCosti.costiPiantaMinimo = data.costiPiantaMin;
          this.formCosti.costiPiantaMinimoConvertito = new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
          }).format(this.formCosti.costiPiantaMinimo);

          this.formCosti.anniPrevisioneCosti = data.anniPrevisioneCosti;

          this.formCosti.volatilitaCostiRialzo = data.volCostiRialzo.toFixed(2);
          const volatilitaRialzoFormat = this.decimalPipe.transform(data.volCostiRialzo, '1.0-2', 'it');
          this.formCosti.volatilitaCostiRialzoFormattato = volatilitaRialzoFormat;

          this.formCosti.volatilitaCostiRibasso = data.volCostiRibasso.toFixed(2);
          const volatilitaRibassoFormat = this.decimalPipe.transform(data.volCostiRibasso, '1.0-2', 'it');
          this.formCosti.volatilitaCostiRibassoFormattato = volatilitaRibassoFormat;
        } else {
          this.formCosti = {};
        }
      });

    this.http
      .post<any>(environment.apiUrlQuestionario + '/rese/getRese/', body)
      .subscribe(data => {
        console.log('DB rese: ' + data);
        if (data != null) {

          this.idRese = data.id;
          console.log('id rese: ' + this.idRese);

          this.formRese.reseInizialiXHa = data.resaInizHa;
          this.formRese.resaMassimaXHa = data.resaMaxHa;
          this.formRese.resaMinimaXHa = data.resaMinHa;
          this.formRese.driftResa = data.driftResa;
          this.formRese.scartoResa = data.scartoResa;
          this.formRese.anniPrevisioneResa = data.anniPrevisioneResa;

          this.formRese.volatilitaReseRialzo = data.volResaRialzo.toFixed(2);
          const volatilitaReseRialzoFormat = this.decimalPipe.transform(data.volResaRialzo, '1.0-2', 'it');
          this.formRese.volatilitaReseRialzoFormattato = volatilitaReseRialzoFormat;

          this.formRese.volatilitaReseRibasso = data.volResaRibasso.toFixed(2);
          const volatilitaReseRibassoFormat = this.decimalPipe.transform(data.volResaRibasso, '1.0-2', 'it');
          this.formRese.volatilitaReseRibassoFormattato = volatilitaReseRibassoFormat;

          this.formRese.invendutoIniziale = data.invendutoIniz;
          this.formRese.invendutoMassimo = data.invendutoMax;
          this.formRese.invendutoMinimo = data.invendutoMin;
          this.formRese.driftInvenduto = data.driftInvenduto;
          this.formRese.scartoInvenduto = data.scartoInvenduto;
          this.formRese.anniPrevisioneInvenduto = data.anniPrevisioneInvenduto;

          this.formRese.volatilitaInvendutoRialzo = data.volInvendutoRialzo.toFixed(2);
          const volatilitaRialzoFormat = this.decimalPipe.transform(data.volInvendutoRialzo, '1.0-2', 'it');
          this.formRese.volatilitaInvendutoRialzoFormattato = volatilitaRialzoFormat;

          this.formRese.volatilitaInvendutoRibasso = data.volInvendutoRibasso.toFixed(2);
          const volatilitaRibassoFormat = this.decimalPipe.transform(data.volInvendutoRibasso, '1.0-2', 'it');
          this.formRese.volatilitaInvendutoRibassoFormattato = volatilitaRibassoFormat;
        } else {
          this.formRese = {};
        }
      });
    /* ------------
       Marco: ISMEA
       ------------ */
      this.ind = 1;

      let param = {  
        idProdVar: this.varieta.id,
        idCom: this.comune.id, 
        idProv: this.provincia.id,
        idReg: this.provincia.idRegione,
        idProd: this.prodotto.id
      };

      this.http
      .post<any>(environment.apiUrlQuestionario + '/storico/totale', param)
      .subscribe(data => {
        console.log('DB Prezzi: '+ data);
        if (data != null) {
          this.pagina = data.listaStorico;
          this.mlZona = data.mlZona;
          this.statZona = data.statZona;
          this.eoZona = data.eoZona;
          this.ismeaZona = data.ismeaZona;
          this.reseEoZona = data.reseEoZona;
          this.reseStatZona = data.reseStatZona;
          this.istatZona = data.istatZona;
          this.ricaZona = data.ricaZona;
          this.valutazione();
        }
      });

      this.http
      .post<any[]>(environment.apiUrlQuestionario + '/storico/ismeaTotale', param)
      .subscribe(data => {
        this.listaPrezziIsmeaAnno = [];
        let listaPrezziIsmeaSettimana = [];
        if (data != null && data.length > 0) {
          let mese = data[0].mese;
          let listaPrezziIsmeaMese = [];
          let anno = data[0].anno;
          let sommaMensile = 0.;
          let countMensile = 0;
          let countAnnuale = 0;
          let sommaAnnuale = 0.;
          for (let i = 0; i < data.length; i++) {
            if (data[i].mese != mese || i == data.length - 1) {
              let date = new Date(1, mese - 1, 15);
              let longMonth = date.toLocaleString('it-IT', { month: 'long' });
              longMonth = longMonth[0].toLocaleUpperCase() + longMonth.slice(1);
              let elementoMese = {
                mese: longMonth,
                prezzo: sommaMensile / countMensile,
                unitaMisura: data[i].unitaMisura,
                listaPrezziIsmeaSettimana: listaPrezziIsmeaSettimana,
                bool: false
              };
              listaPrezziIsmeaMese.push(elementoMese);
              if (sommaMensile != 0) {
                sommaAnnuale += sommaMensile;
                countAnnuale += countMensile;
              }
              sommaMensile = 0;
              countMensile = 0;
              listaPrezziIsmeaSettimana = [];
              mese = data[i].mese;
            }

            if (data[i].anno != anno || i == data.length - 1) {
              if (anno == data[0].anno) {
                let date = new Date(1, data[i - 1].mese - 1, 15);
                this.ultimoMese = date.toLocaleString('it-IT', { month: 'long' });

              }
              let elementoAnno = {
                anno: anno,
                prezzo: sommaAnnuale / countAnnuale,
                unitaMisura: data[i].unitaMisura,
                listaPrezziIsmeaMese: listaPrezziIsmeaMese,
                bool: false
              };
              this.listaPrezziIsmeaAnno.push(elementoAnno);
              sommaAnnuale = 0;
              countAnnuale = 0;
              listaPrezziIsmeaMese = [];
              anno = data[i].anno;
            }

            let elementoSettimana = {
              settimana: data[i].settimana,
              prezzo: data[i].prezzo,
              unitaMisura: data[i].unitaMisura
            };
            listaPrezziIsmeaSettimana.push(elementoSettimana);
            if (elementoSettimana.prezzo != 0) {
              sommaMensile += data[i].prezzo;
              countMensile++;
            }
          }
        }
      });
  }

  valutazione(): void {

    this.deltaIsmea = [0, 0, 0, 0];
    this.deltaReseEo = [0, 0, 0, 0];
    this.deltaReseStat = [0, 0, 0, 0];
    this.deltaSuperfici = [0, 0, 0, 0];
    this.deltaProduzione = [0, 0, 0, 0];
    this.deltaReseIstat = [0, 0, 0, 0];
    this.deltaBilanciaImpExp = [0, 0, 0, 0];
    this.deltaQuantImport = [0, 0, 0, 0];
    this.deltaValImport = [0, 0, 0, 0];
    this.deltaQuantExport = [0, 0, 0, 0];
    this.deltaValExport = [0, 0, 0, 0];
    this.deltaEo = [0, 0, 0, 0];
    this.deltaMl = [0, 0, 0, 0];
    this.deltaStat = [0, 0, 0, 0];
    this.deltaPlt = [0, 0, 0, 0];
    this.deltaCs = [0, 0, 0, 0];
    this.deltaMargLor = [0, 0, 0, 0];
    this.deltaMargOp = [0, 0, 0, 0];
    this.deltaPrezzoRica = [0, 0, 0, 0];
    this.deltaResaRica = [0, 0, 0, 0];
    this.deltaSuperficiNaz = [0, 0, 0, 0];
    this.deltaProduzioneNaz = [0, 0, 0, 0];
    this.deltaReseIstatNaz = [0, 0, 0, 0];
    this.deltaCostiTot = [0, 0, 0, 0];

    if (this.pagina[0].superfici != 0 && this.pagina[this.ind].superfici != 0) {
      this.deltaSuperfici[0] = ((this.pagina[0].superfici - this.pagina[this.ind].superfici) / this.pagina[this.ind].superfici) * 100;
    }

    if (this.pagina[0].produzione != 0 && this.pagina[this.ind].produzione != 0) {
      this.deltaProduzione[0] = ((this.pagina[0].produzione - this.pagina[this.ind].produzione) / this.pagina[this.ind].produzione) * 100;
    }

    if (this.pagina[0].reseIstat != 0 && this.pagina[this.ind].reseIstat != 0) {
      this.deltaReseIstat[0] = ((this.pagina[0].reseIstat - this.pagina[this.ind].reseIstat) / this.pagina[this.ind].reseIstat) * 100;
    }

    if (this.pagina[0].ml != 0 && this.pagina[this.ind].ismea != 0) {
      this.deltaMl[0] = ((this.pagina[0].ml - this.pagina[this.ind].ismea) / this.pagina[this.ind].ismea) * 100;
    }

    if (this.pagina[0].stat != 0 && this.pagina[this.ind].ismea != 0) {
      this.deltaStat[0] = ((this.pagina[0].stat - this.pagina[this.ind].ismea) / this.pagina[this.ind].ismea) * 100;
    }

    if (this.pagina[0].eo != 0 && this.pagina[this.ind].prezzoRica != 0) {
      this.deltaEo[0] = ((this.pagina[0].eo - this.pagina[this.ind].prezzoRica / 100.) / (this.pagina[this.ind].prezzoRica / 100.)) * 100;
    }

    if (this.pagina[0].ismea != 0 && this.pagina[this.ind].ismea != 0) {
      this.deltaIsmea[0] = ((this.pagina[0].ismea - this.pagina[this.ind].ismea) / this.pagina[this.ind].ismea) * 100;
    }

    if (this.pagina[0].reseEo != 0 && this.pagina[this.ind].reseEo != 0) {
      this.deltaReseEo[0] = ((this.pagina[0].reseEo - this.pagina[this.ind].reseEo) / this.pagina[this.ind].reseEo) * 100;
    }

    if (this.pagina[0].reseStat != 0 && this.pagina[this.ind].reseStat != 0) {
      this.deltaReseStat[0] = ((this.pagina[0].reseStat - this.pagina[this.ind].reseStat) / this.pagina[this.ind].reseStat) * 100;
    }

    if (this.pagina[0].quantImport != 0 && this.pagina[this.ind].quantImport != 0 && this.pagina[0].quantExport != 0 && this.pagina[this.ind].quantExport != 0) {
      this.deltaBilanciaImpExp[0] = (((this.pagina[0].quantImport - this.pagina[0].quantExport) - (this.pagina[this.ind].quantImport - this.pagina[this.ind].quantExport)) / (this.pagina[this.ind].quantImport - this.pagina[this.ind].quantExport)) * 100;
    }

    if (this.pagina[0].quantImport != 0 && this.pagina[this.ind].quantImport != 0) {
      this.deltaQuantImport[0] = ((this.pagina[0].quantImport - this.pagina[this.ind].quantImport) / this.pagina[this.ind].quantImport) * 100;
    }

    if (this.pagina[0].valImport != 0 && this.pagina[this.ind].valImport != 0) {
      this.deltaValImport[0] = ((this.pagina[0].valImport - this.pagina[this.ind].valImport) / this.pagina[this.ind].valImport) * 100;
    }

    if (this.pagina[0].quantExport != 0 && this.pagina[this.ind].quantExport != 0) {
      this.deltaQuantExport[0] = ((this.pagina[0].quantExport - this.pagina[this.ind].quantExport) / this.pagina[this.ind].quantExport) * 100;
    }

    if (this.pagina[0].valExport != 0 && this.pagina[this.ind].valExport != 0) {
      this.deltaValExport[0] = ((this.pagina[0].valExport - this.pagina[this.ind].valExport) / this.pagina[this.ind].valExport) * 100;
    }

    if (this.pagina[0].plt != 0 && this.pagina[this.ind].plt != 0) {
      this.deltaPlt[0] = ((this.pagina[0].plt - this.pagina[this.ind].plt) / this.pagina[this.ind].plt) * 100;
    }

    if (this.pagina[0].cs != 0 && this.pagina[this.ind].cs != 0) {
      this.deltaCs[0] = ((this.pagina[0].cs - this.pagina[this.ind].cs) / this.pagina[this.ind].cs) * 100;
    }

    if (this.pagina[0].margLor != 0 && this.pagina[this.ind].margLor != 0) {
      this.deltaMargLor[0] = ((this.pagina[0].margLor - this.pagina[this.ind].margLor) / this.pagina[this.ind].margLor) * 100;
    }

    if (this.pagina[0].margOp != 0 && this.pagina[this.ind].margOp != 0) {
      this.deltaMargOp[0] = ((this.pagina[0].margOp - this.pagina[this.ind].margOp) / this.pagina[this.ind].margOp) * 100;
    }

    if (this.pagina[0].prezzoRica != 0 && this.pagina[this.ind].prezzoRica != 0) {
      this.deltaPrezzoRica[0] = ((this.pagina[0].prezzoRica - this.pagina[this.ind].prezzoRica) / this.pagina[this.ind].prezzoRica) * 100;
    }

    if (this.pagina[0].resaRica != 0 && this.pagina[this.ind].resaRica != 0) {
      this.deltaResaRica[0] = ((this.pagina[0].resaRica - this.pagina[this.ind].resaRica) / this.pagina[this.ind].resaRica) * 100;
    }

    if (this.pagina[0].superficiNaz != 0 && this.pagina[this.ind].superficiNaz != 0) {
      this.deltaSuperficiNaz[0] = ((this.pagina[0].superficiNaz - this.pagina[this.ind].superficiNaz) / this.pagina[this.ind].superficiNaz) * 100;
    }

    if (this.pagina[0].produzioneNaz != 0 && this.pagina[this.ind].produzioneNaz != 0) {
      this.deltaProduzioneNaz[0] = ((this.pagina[0].produzioneNaz - this.pagina[this.ind].produzioneNaz) / this.pagina[this.ind].produzioneNaz) * 100;
    }

    if (this.pagina[0].reseIstatNaz != 0 && this.pagina[this.ind].reseIstatNaz != 0) {
      this.deltaReseIstatNaz[0] = ((this.pagina[0].reseIstatNaz - this.pagina[this.ind].reseIstatNaz) / this.pagina[this.ind].reseIstatNaz) * 100;
    }

    if (this.pagina[0].plt != 0 && this.pagina[this.ind].plt != 0 && this.pagina[0].margOp != 0 && this.pagina[this.ind].margOp != 0) {
      this.deltaCostiTot[0] = (((this.pagina[0].plt - this.pagina[0].margOp) - (this.pagina[this.ind].plt - this.pagina[this.ind].margOp)) / (this.pagina[this.ind].plt - this.pagina[this.ind].margOp)) * 100;
    }

    for (let i = 1; i < this.deltaEo.length; i++) {
      if (this.pagina[this.ind - 1 + i].superfici != 0 && this.pagina[this.ind + i].superfici != 0) {
        this.deltaSuperfici[i] = ((this.pagina[this.ind - 1 + i].superfici - this.pagina[this.ind + i].superfici) / this.pagina[this.ind + i].superfici) * 100;
      }

      if (this.pagina[this.ind - 1 + i].produzione != 0 && this.pagina[this.ind + i].produzione != 0) {
        this.deltaProduzione[i] = ((this.pagina[this.ind - 1 + i].produzione - this.pagina[this.ind + i].produzione) / this.pagina[this.ind + i].produzione) * 100;
      }

      if (this.pagina[this.ind - 1 + i].reseIstat != 0 && this.pagina[this.ind + i].reseIstat != 0) {
        this.deltaReseIstat[i] = ((this.pagina[this.ind - 1 + i].reseIstat - this.pagina[this.ind + i].reseIstat) / this.pagina[this.ind + i].reseIstat) * 100;
      }

      if (this.pagina[this.ind - 1 + i].ml != 0 && this.pagina[this.ind + i].ismea != 0) {
        this.deltaMl[i] = ((this.pagina[this.ind - 1 + i].ml - this.pagina[this.ind + i].ismea) / this.pagina[this.ind + i].ismea) * 100;
      }

      if (this.pagina[this.ind - 1 + i].stat != 0 && this.pagina[this.ind + i].ismea != 0) {
        this.deltaStat[i] = ((this.pagina[this.ind - 1 + i].stat - this.pagina[this.ind + i].ismea) / this.pagina[this.ind + i].ismea) * 100;
      }

      if (this.pagina[this.ind - 1 + i].eo != 0 && this.pagina[this.ind + i].prezzoRica != 0) {
        this.deltaEo[i] = ((this.pagina[this.ind - 1 + i].eo - this.pagina[this.ind + i].prezzoRica / 100.) / (this.pagina[this.ind + i].prezzoRica / 100.)) * 100;
      }

      if (this.pagina[this.ind - 1 + i].ismea != 0 && this.pagina[this.ind + i].ismea != 0) {
        this.deltaIsmea[i] = ((this.pagina[this.ind - 1 + i].ismea - this.pagina[this.ind + i].ismea) / this.pagina[this.ind + i].ismea) * 100;
      }

      if (this.pagina[this.ind - 1 + i].reseEo != 0 && this.pagina[this.ind + i].reseEo != 0) {
        this.deltaReseEo[i] = ((this.pagina[this.ind - 1 + i].reseEo - this.pagina[this.ind + i].reseEo) / this.pagina[this.ind + i].reseEo) * 100;
      }

      if (this.pagina[this.ind - 1 + i].reseStat != 0 && this.pagina[this.ind + i].reseStat != 0) {
        this.deltaReseStat[i] = ((this.pagina[this.ind - 1 + i].reseStat - this.pagina[this.ind + i].reseStat) / this.pagina[this.ind + i].reseStat) * 100;
      }

      if (this.pagina[this.ind - 1 + i].quantImport != 0 && this.pagina[this.ind + i].quantImport != 0 && this.pagina[this.ind - 1 + i].quantExport != 0 && this.pagina[this.ind + i].quantExport != 0) {
        this.deltaBilanciaImpExp[i] = (((this.pagina[this.ind - 1 + i].quantImport - this.pagina[this.ind - 1 + i].quantExport) - (this.pagina[this.ind + i].quantImport - this.pagina[this.ind + i].quantExport)) / (this.pagina[this.ind + i].quantImport - this.pagina[this.ind + i].quantExport)) * 100;
      }

      if (this.pagina[this.ind - 1 + i].quantImport != 0 && this.pagina[this.ind + i].quantImport != 0) {
        this.deltaQuantImport[i] = ((this.pagina[this.ind - 1 + i].quantImport - this.pagina[this.ind + i].quantImport) / this.pagina[this.ind + i].quantImport) * 100;
      }

      if (this.pagina[this.ind - 1 + i].valImport != 0 && this.pagina[this.ind + i].valImport != 0) {
        this.deltaValImport[i] = ((this.pagina[this.ind - 1 + i].valImport - this.pagina[this.ind + i].valImport) / this.pagina[this.ind + i].valImport) * 100;
      }

      if (this.pagina[this.ind - 1 + i].quantExport != 0 && this.pagina[this.ind + i].quantExport != 0) {
        this.deltaQuantExport[i] = ((this.pagina[this.ind - 1 + i].quantExport - this.pagina[this.ind + i].quantExport) / this.pagina[this.ind + i].quantExport) * 100;
      }

      if (this.pagina[this.ind - 1 + i].valExport != 0 && this.pagina[this.ind + i].valExport != 0) {
        this.deltaValExport[i] = ((this.pagina[this.ind - 1 + i].valExport - this.pagina[this.ind + i].valExport) / this.pagina[this.ind + i].valExport) * 100;
      }

      if (this.pagina[this.ind - 1 + i].plt != 0 && this.pagina[this.ind + i].plt != 0) {
        this.deltaPlt[i] = ((this.pagina[this.ind - 1 + i].plt - this.pagina[this.ind + i].plt) / this.pagina[this.ind + i].plt) * 100;
      }

      if (this.pagina[this.ind - 1 + i].cs != 0 && this.pagina[this.ind + i].cs != 0) {
        this.deltaCs[i] = ((this.pagina[this.ind - 1 + i].cs - this.pagina[this.ind + i].cs) / this.pagina[this.ind + i].cs) * 100;
      }

      if (this.pagina[this.ind - 1 + i].margLor != 0 && this.pagina[this.ind + i].margLor != 0) {
        this.deltaMargLor[i] = ((this.pagina[this.ind - 1 + i].margLor - this.pagina[this.ind + i].margLor) / this.pagina[this.ind + i].margLor) * 100;
      }

      if (this.pagina[this.ind - 1 + i].margOp != 0 && this.pagina[this.ind + i].margOp != 0) {
        this.deltaMargOp[i] = ((this.pagina[this.ind - 1 + i].margOp - this.pagina[this.ind + i].margOp) / this.pagina[this.ind + i].margOp) * 100;
      }

      if (this.pagina[this.ind - 1 + i].prezzoRica != 0 && this.pagina[this.ind + i].prezzoRica != 0) {
        this.deltaPrezzoRica[i] = ((this.pagina[this.ind - 1 + i].prezzoRica - this.pagina[this.ind + i].prezzoRica) / this.pagina[this.ind + i].prezzoRica) * 100;
      }

      if (this.pagina[this.ind - 1 + i].resaRica != 0 && this.pagina[this.ind + i].resaRica != 0) {
        this.deltaResaRica[i] = ((this.pagina[this.ind - 1 + i].resaRica - this.pagina[this.ind + i].resaRica) / this.pagina[this.ind + i].resaRica) * 100;
      }

      if (this.pagina[this.ind - 1 + i].superficiNaz != 0 && this.pagina[this.ind + i].superficiNaz != 0) {
        this.deltaSuperficiNaz[i] = ((this.pagina[this.ind - 1 + i].superficiNaz - this.pagina[this.ind + i].superficiNaz) / this.pagina[this.ind + i].superficiNaz) * 100;
      }

      if (this.pagina[this.ind - 1 + i].produzioneNaz != 0 && this.pagina[this.ind + i].produzioneNaz != 0) {
        this.deltaProduzioneNaz[i] = ((this.pagina[this.ind - 1 + i].produzioneNaz - this.pagina[this.ind + i].produzioneNaz) / this.pagina[this.ind + i].produzioneNaz) * 100;
      }

      if (this.pagina[this.ind - 1 + i].reseIstatNaz != 0 && this.pagina[this.ind + i].reseIstatNaz != 0) {
        this.deltaReseIstatNaz[i] = ((this.pagina[this.ind - 1 + i].reseIstatNaz - this.pagina[this.ind + i].reseIstatNaz) / this.pagina[this.ind + i].reseIstatNaz) * 100;
      }

      if (this.pagina[this.ind - 1 + i].plt != 0 && this.pagina[this.ind + i].plt != 0 && this.pagina[this.ind - 1 + i].margOp != 0 && this.pagina[this.ind + i].margOp != 0) {
        this.deltaCostiTot[i] = (((this.pagina[this.ind - 1 + i].plt - this.pagina[this.ind - 1 + i].margOp) - (this.pagina[this.ind + i].plt - this.pagina[this.ind + i].margOp)) / (this.pagina[this.ind + i].plt - this.pagina[this.ind + i].margOp)) * 100;
      }
    }

  }
  avantiVal(): void {
    if (this.ind < this.pagina.length - 4) {
      this.ind++;

      this.valutazione();
    }

  }
  indietroVal(): void {
    if (this.ind > 1) {
      this.ind--;

      this.valutazione();
    }
  }

  chiudiAnno(elementoAnno: any): void {
    for (let i = 0; i < elementoAnno.listaPrezziIsmeaMese.length; i++) {
      elementoAnno.listaPrezziIsmeaMese[i].bool = false;
    }

  }
  //Marco: fine

  onClickRese(fRese: NgForm, content, contentError): void {
    console.log(fRese.form);

    if (fRese.form.valid
      && this.CostiMinFisMagCostiMaxFis === false
      && this.MinVarMagMaxVar === false
      && this.CostiFatMinMagCostiFatMax === false
      && this.CostiPiantaMinMagCostiPiantaMAx === false) {
      console.log('TUTTO OK');

      const dataInizioValidita = new Date();
      const dataFineValidita = new Date();
      const anniPrevisResa = Math.floor(this.formRese.anniPrevisioneResa);
      dataFineValidita.setFullYear(dataInizioValidita.getFullYear() + anniPrevisResa);
      dataInizioValidita.setDate(dataInizioValidita.getDate() + 1);

      const rese = {
        id: this.idRese,
        idProdottoVarieta: this.varieta.id,
        idRegione: this.provincia.idRegione,
        idProvincia: this.provincia.id,
        idComune: this.comune.id,
        resaInizHa: this.formRese.reseInizialiXHa,
        resaMinHa: this.formRese.resaMinimaXHa,
        resaMaxHa: this.formRese.resaMassimaXHa,
        volResaRialzo: this.formRese.volatilitaReseRialzo,
        volResaRibasso: this.formRese.volatilitaReseRibasso,
        driftResa: this.formRese.driftResa,
        scartoResa: this.formRese.scartoResa,
        anniPrevisioneResa: this.formRese.anniPrevisioneResa,
        invendutoIniz: this.formRese.invendutoIniziale,
        invendutoMin: this.formRese.invendutoMinimo,
        invendutoMax: this.formRese.invendutoMassimo,
        volInvendutoRialzo: this.formRese.volatilitaInvendutoRialzo,
        volInvendutoRibasso: this.formRese.volatilitaInvendutoRibasso,
        driftInvenduto: this.formRese.driftInvenduto,
        scartoInvenduto: this.formRese?.scartoInvenduto,
        anniPrevisioneInvenduto: this.formRese.anniPrevisioneInvenduto,
        dataInizioValidita: dataInizioValidita,
        dataFineValidita: dataFineValidita,
        unitaMisura: 'Q.li',
        utente: this.tokenStorageService.getUser().username
      };

      this.http
        .post<any[]>(environment.apiUrlQuestionario + '/rese/salvataggio', rese)
        .subscribe(data => {
          console.log(data);
        });

      this.modalService.open(content, { centered: true });

    } else {
      console.log('NO');
      this.modalService.open(contentError, { centered: true });
      return;
    }
  }
}
interface Storico {
  anno: number;
  ml: number;
  mlMax: number;
  mlMin: number;
  stat: number;
  statMax: number;
  statMin: number;
  eo: number;
  eoMax: number;
  eoMin: number;
  ismea: number;
  coeffVar: any;
  reseEo: number;
  reseStat: number;
  superfici: number;
  produzione: number;
  reseIstat: number;
  superficiNaz: number;
  produzioneNaz: number;
  reseIstatNaz: number;
  quantImport: number;
  valImport: number;
  quantExport: number;
  valExport: number;
  plt: number;
  cs: number;
  margLor: number;
  margOp: number;
  prezzoRica: number;
  resaRica: number;
}
interface DatiStorici { valore?: number; anno?: number; }
