import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lista-clienti',
  templateUrl: './lista-clienti.component.html',
  styleUrls: ['./lista-clienti.component.css']
})
export class ListaClientiComponent implements OnInit {
  userFilter: any = { riferimento: '' };

  constructor(
    private router: Router,
  ) { }
  clienti: any[] = [];
  ngOnInit(): void {
  }






}
