<div style="margin-bottom: 0px;" class="center-verticaly-p" >
    <div [ngClass]="{'potenziometro-zoom' : this.zoomActive,'potenziometro-zoom-left-side' : this.zoomActiveLeft}">
      <div style="display: block; position : relative;">
        <div *ngIf="viewportDevice == 1" 
        id="{{lancetta}}" 
        class="lancetta"
        [ngStyle]="{'border-bottom-color': !this.labelBlack ? 'var(--accessory-grey)' : ''}" >
      </div>
        <div *ngIf="viewportDevice == 2" id="{{lancetta}}-ipad" class="lancetta"></div>  
        <div id="container-potenziometro" (click)="this.focusDscr()" class="chart-potenziometro-container"
         [ngStyle]="{'background-color': !this.labelBlack ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0)'}" >
            <canvas baseChart id="chart" class=""
              [data]="doughnutChartData"
              [type]="doughnutChartType"
              [options]="percentageInnerCutout">
            </canvas>

            <div *ngIf="tips_type == 'DSRC'" class="move-tip reverse-move-tip tips-potenziometro">
              <p *ngIf="rotate_principale!=null && rotate_principale <= 60">
                il valore {{ valore_tip_dscr }} della DSRC non è in un range rassicurante
              </p>
              <p *ngIf="rotate_principale!=null && rotate_principale >= 61 && rotate_principale <= 120">
                il valore {{ valore_tip_dscr }} della DSRC è nella media
              </p>
              <p *ngIf="rotate_principale!=null && rotate_principale >= 121 && rotate_principale <= 180">
                il valore {{ valore_tip_dscr }} della DSRC è particolarmente rassicurante
              </p>
            </div>
            <div *ngIf="tips_type == 'Debitoria'" class="move-tip reverse-move-tip tips-potenziometro">
              <p *ngIf="rotate_debitoria !=null && rotate_debitoria <= 60">
                il valore {{ valore_tip_debitoria }} della debitoria non è in un range rassicurante
              </p>
              <p *ngIf="rotate_debitoria !=null && rotate_debitoria >= 61 && rotate_debitoria <= 120">
                il valore {{ valore_tip_debitoria }} della debitoria è nella media
              </p>
              <p *ngIf="rotate_debitoria !=null && rotate_debitoria >= 121 && rotate_debitoria <= 180">
                il valore {{ valore_tip_debitoria }} della debitoria è particolarmente rassicurante
              </p>
            </div>
            <div *ngIf="tips_type == 'MCC'" class="move-tip reverse-move-tip tips-potenziometro">
              <p *ngIf="rotate_mcc !=null && rotate_mcc <= 60">
                il valore {{ valore_tip_mcc }} della MCC non è in un range rassicurante
              </p>
              <p *ngIf="rotate_mcc !=null && rotate_mcc >= 61 && rotate_mcc <= 120">
                il valore {{ valore_tip_mcc }} della MCC è nella media
              </p>
              <p *ngIf="rotate_mcc !=null && rotate_mcc >= 121 && rotate_mcc <= 180">
                il valore {{ valore_tip_mcc }} della MCC è particolarmente rassicurante
              </p>
            </div>
            <div *ngIf="tips_type == 'Garanzie'" class="move-tip reverse-move-tip tips-potenziometro">
              <p *ngIf="rotate_garanzia!=null && rotate_garanzia <= 60">
                il valore {{ valore_tip_garanzia }} della garanzia non è in un range rassicurante
              </p>
              <p *ngIf="rotate_garanzia!=null && rotate_garanzia >= 61 && rotate_garanzia <= 120">
                il valore {{ valore_tip_garanzia }} della garanzia è nella media
              </p>
              <p *ngIf="rotate_garanzia!=null && rotate_garanzia >= 121 && rotate_garanzia <= 180">
                il valore {{ valore_tip_garanzia }} della garanzia è particolarmente rassicurante
              </p>
            </div>
        </div>    
        <p class=" align-middle text-center mx-auto mt-2 h5" 
        [ngStyle]="{'color': this.labelBlack ? 'var(--main-black)' : 'var(--main-white)'}" 
        #namePotenziometro>{{name}}</p>
      </div>
    </div>
  </div>
