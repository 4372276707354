import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BilancioService } from '../_services/bilancio.service';

@Component({
    selector: 'app-analisicr',
    template: `
<div class="card nopadding">
    <div class="card-header text-center font-big ">Dossier amministrativi</div>
    <div class="card-body" [formGroup]="dossierAmministrativiForm">

<div class="row container-fluid margin-left-right-0 nopadding margin-top-20">
                    <div class=" col-12 col-lg-12">
                        <h3>
                            Per avere un'analisi accurata ci occorrono i bilanci aziendali degli ultimi 8 anni. Da i
                            nostri database risultano i bilanci degli anni: <strong> data bilancio</strong>

                        </h3>
                    </div>
                    <div class="row margin-top-10">
                        <div class="margin-bottom-20 col-4">
                            <label for="inserisciBilancio">Desideri aggiornare le informazioni con i bilanci più
                                recenti?</label>
                        </div>
                        <div class="col-1 margin-bottom-20">
                            <select id="inserisciBilancio" formControlName="inserisciBilancio" class="form-control"
                                
                                [ngClass]="{ 'is-invalid': submitted && f.inserisciBilancio.errors }">
                                <option [ngValue]="true">Si</option> <!-- setto il value di presenza bilancio -->
                                <option [ngValue]="false">No</option>
                                <div *ngIf="submitted && f.inserisciBilancio.errors" class="invalid-feedback">
                                    <div *ngIf="f.inserisciBilancio.errors.required">Campo obbligatorio</div>
                                </div>
                            </select>
                        </div>
                    </div>
                    <div class="row margin-bottom-10 " *ngIf="f.inserisciBilancio.value">
                        <hr>
                        <div class=" col-lg-3">
                            <label>Inserisci bilancio:</label>
                        </div>
                        <div class=" form-check col-3">
                            <input class="form-check-input" formControlName="caricaBilancio" type="radio"
                                name="caricaBilancio" id="caricaBilancioXbrl" checked [value]="1">
                            <label class="form-check-label" for="caricaBilancioXbrl">
                                Carica file.xbrl
                            </label>
                        </div>
                        <div class=" form-check col-3">
                            <input class="form-check-input" formControlName="caricaBilancio" type="radio"
                                name="caricaBilancio" id="caricaBilancioManuale" checked [value]="2">
                            <label class="form-check-label" for="caricaBilancioManuale">
                                Inserimento manuale
                            </label>
                        </div>
                        <div class="row margin-top-20" *ngIf="f.caricaBilancio.value==1">

                            <div class="col-6 div-upload">
                                <div class="d-flex justify-content-between">
                                    <input type="file" class="form-control upload-file" accept="application/xbrl"
                                        (change)="getFile($event)">
                                    <button class="btn btn-primary" [disabled]="buttonDisabled"
                                        (click)="Carica()">Carica</button>
                                </div>
                                <p id="p-error"><strong>{{error}}</strong></p>
                            </div>
                            <div class="col-6  d-flex flex-column justify-content-center align-items-start">
                                <div id="uploadcontainer"
                                    class="file-list d-flex flex-row justify-content-around  align-items-center"
                                    *ngFor="let file of listaBilanciCaricati; let i = index">
                                    <p style="margin-bottom: 0px;">{{file.name}}</p>
                                    <fa-icon class="pointer margin-left-20"
                                        style="font-size: 25px; color: rgb(138, 43, 43);" [icon]="faTrashAlt"
                                        (click)="deleteFile(i)">
                                    </fa-icon>
                                </div>
                            </div>
                        </div>
                        <div class="row margin-top-20" *ngIf="f.caricaBilancio.value==2">
                            <div class="col-6 d-flex justify-content-start">
                                <button class="btn btn-primary mr-1" type="button" (click)="goToBilancio()">Inserisci
                                    nuovo bilancio</button>
                            </div>
                            <div class="col-6">


                                <!-- icona cestino -->
                                <!-- -->
                            </div>
                        </div>

                    </div>
                </div>

    </div>
</div>
  `,
    styles: [
    ]
})
export class AnalisicrComponent implements OnInit {

    /* @Input('formGroup') dossierAmministrativiForm: FormGroup; */
    dossierAmministrativiForm: UntypedFormGroup;

    submitted = false;
    selectedIndex = 0; /* serve per farlo partire da bilancio */

    listaBilanci: any[] = [];
    listaBilanciCaricati: any[] = []
    listaCr: any[] = [];
    faTrashAlt = faTrashAlt;

    /* varibili upload file */
    file: File;
    error: string;
    buttonDisabled = true
    constructor(private formBuilder: UntypedFormBuilder, private bilancioService: BilancioService) {

        this.dossierAmministrativiForm = this.formBuilder.group({
            inserisciBilancio: ['', Validators.required],
            caricaBilancio: [1],
            bilancio: [''],
        })
    }


    get f() { return this.dossierAmministrativiForm.controls; }

    ngOnInit(): void {



    }

    getFile(event) {
        if (event.target.files.length > 1) {
            this.error = "Non è possibile caricare più di un file"
            this.buttonDisabled = true;
        } else {
            let fileName = event.target.files[0].name
            console.log(fileName)
            let split = fileName.split('.');/* spezzo il nome al . nell array split */
            let ext = split[split.length - 1];
            if (ext != 'xbrl') {
                this.buttonDisabled = true;
                this.error = 'Il file non è compatibile. Carica un file .xbrl'
            } else {
                this.error = null;
                this.buttonDisabled = false;
                this.file = event.target.files[0]
            }
        }

    }
    Carica() {
        this.listaBilanciCaricati.push(this.file)

        const formdata: FormData = new FormData();
        formdata.append('file', this.file);
        formdata.append('idCliente', "2");
        formdata.append('PI', "12345678901");
        this.bilancioService.caricaXbrl(formdata).subscribe(response => { });
    }
    deleteFile(i: number) {
        this.listaBilanciCaricati.splice(i, 1)
    }


}
