import { ThisReceiver } from '@angular/compiler';
import { Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { indiciTimeShift } from '../risultati-questionario/interface/indici-time-shift';
import { TimelineButtonsNavigatorService } from '../risultati-questionario/services/timeline-buttons-navigator.service';
import { SplashpageRisultatiComponent } from '../risultati-questionario/splashpage-risultati/splashpage-risultati.component';
import { ConfigurazioneBusinessPlanService } from '../_services/configurazione-business-plan.service';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { SplashpageServiceService } from '../_services/splashpage-service.service';


@Component({
  selector: 'app-timeline-with-buttons',
  templateUrl: './timeline-with-buttons.component.html',
  styleUrls: ['./timeline-with-buttons.component.css']
})

export class TimelineWithButtonsComponent implements OnInit, OnDestroy {

  risultatiVolatileSubscription: Subscription;
  listaSplashpageAnni: Subscription;
  durataBP: Subscription;
  count: number = 1;
  count_year: number = 1;
  isPersistent: boolean = false;

  yearStart !: number;
  durataYears !: number;
  yearLista: number[] = [];
  listaData: { lista: [indici: indiciTimeShift[]] } = null;

  constructor(public timelineButtonsNavigatorService: TimelineButtonsNavigatorService, public splashpageVolatile: SplashpageServiceService,
    public datiQuestionarioService: DatiQuestionarioService, private serviceBp: ConfigurazioneBusinessPlanService) { }

  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    this.listaSplashpageAnni.unsubscribe()
    this.durataBP.unsubscribe()

  }
  ngOnInit(): void {
    /*this.datiQuestionarioService.checkFormTouched.subscribe(isVolatile => {

      this.isPersistent = !isVolatile;

    })*/

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isPersistent = !isVolatile;
    })

    this.listaSplashpageAnni = this.splashpageVolatile.listaSplashpageAnni$.subscribe(
      listaData => {
        if (listaData != null) {
          this.listaData = listaData;
        }
      })

    this.durataBP = this.splashpageVolatile.durataBP$.subscribe(
      durata => {
        if (durata != null) {
          this.yearStart = durata.annoStart;
          this.durataYears = durata.durataAnni;
          this.yearLista = [];
          for (let i = 0; i < this.durataYears; i++) {
            this.yearLista.push(this.yearStart + i);
          }
        }
      }
    )
  }

  clickOnAsIs(realclick: boolean | null, count: number, splashpageVolatile: SplashpageServiceService): void {

    this.count = count;

    let a = function () {
      splashpageVolatile.takeValueSplashpageASIs();
      splashpageVolatile.takeValueSplashpageEndStroke();
    }

    if (realclick && count != 0) {
      this.timelineButtonsNavigatorService.showAsIs();
      this.splashpageVolatile.takeValueSplashpageASIs();
      this.splashpageVolatile.takeValueSplashpageEndStroke();
      setTimeout(function () {
        a();
      }, 200);
    }
  }

  clickOnYear(listaData: { lista: [indici: indiciTimeShift[]] } | null, realclick: boolean | null, year: number, index: number, splashPageVolatili: TimelineButtonsNavigatorService, splashpageVolatile: SplashpageServiceService): void {

    if (realclick) {
      this.timelineButtonsNavigatorService.selectYear(year, index);
      if (!this.isPersistent) {
        this.splashpageVolatile.takeDataYear(this.listaData[index].indici_end_stroke.concat(this.listaData[index].media_annua_propspetticca_conto_economico, this.listaData[index].media_annua_propspetticca_cash_flow, this.listaData[index].propspetticca_stato_patrimoniale_end_stroke));
        var lista = this.listaData[index].indici_end_stroke.concat(this.listaData[index].media_annua_propspetticca_conto_economico, this.listaData[index].media_annua_propspetticca_cash_flow, this.listaData[index].propspetticca_stato_patrimoniale_end_stroke);
      }
      setTimeout(() => {
        this.timelineButtonsNavigatorService.selectYear(year, index);
        if (!this.isPersistent) {
          this.splashpageVolatile.takeDataYear(lista);
        }
      }, 200);
    }
  }
}
