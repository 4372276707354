<div class="mat-body-text-2"><span style="color: rgba(red, green, blue, 0)">.</span></div>
<div class="container-fluid">
    <div class="row content-group-charts">
        <div class="col-12" style=" background-color: var(--soft-grey); position: relative;">
            <mat-icon class="zoom-button-trigger" (click)="zoomOnClick()">fullscreen</mat-icon>
            <app-line-chart [valueOrdinataNegativo]="valueOrdinataNegativo" [valueOrdinataMedia]="valueOrdinataMedia"
                [valueOrdinataPositivo]="valueOrdinataPositivo" [labelAscissa]="">
            </app-line-chart>

        </div>
        <div class="col-12" style=" background-color: var(--soft-grey); position: relative;">
            <mat-icon class="zoom-button-trigger" (click)="zoomOnClick()">fullscreen</mat-icon>
            <app-line-chart [valueOrdinataNegativo]="valueOrdinataNegativo" [valueOrdinataMedia]="valueOrdinataMedia"
                [valueOrdinataPositivo]="valueOrdinataPositivo" [labelAscissa]="">
            </app-line-chart>

        </div>
        <div class="col-12" style=" background-color: var(--soft-grey); position: relative;">
            <mat-icon class="zoom-button-trigger" (click)="zoomOnClick()">fullscreen</mat-icon>
            <app-line-chart [valueOrdinataNegativo]="valueOrdinataNegativo" [valueOrdinataMedia]="valueOrdinataMedia"
                [valueOrdinataPositivo]="valueOrdinataPositivo" [labelAscissa]="">
            </app-line-chart>
        </div>
    </div>
</div>