<div class="container-fluid nopadding" style="margin-top: 35px">
    <div class="loading-container" *ngIf="isLoading">
        <div class="spinner-border text-primary" style="width: 50px; height: 50px" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <form [formGroup]="ebaComplianceForm" (ngSubmit)="ebaCompliance()">
        <div class="col-12 nopadding">
            <div class="card nopadding">
                <div class="card-header text-center font-big">Analisi consuntiva linee guida EBA</div>
                <div class="card-body">

                    <div class="row">

                        <div class="col-4 margin-bottom-10">
                            <label>Anno riferimento</label>
                            <select formControlName="anno" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.anno.errors }">
                                <option [ngValue]="anno" *ngFor="let anno of listaAnni">{{anno}}</option>
                            </select>
                            <div *ngIf="submitted && f.anno.errors" class="invalid-feedback">
                                <div *ngIf="f.anno.errors.required">Campo obbligatorio</div>
                            </div>
                        </div>
                        <div class="col-4 margin-bottom-10">
                            <label>Ricavi</label>
                            <input min="0" type="number" formControlName="ricavi" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.ricavi.errors }" />
                            <div *ngIf="submitted && f.ricavi.errors" class="invalid-feedback">
                                <div *ngIf="f.ricavi.errors.required">Campo obbligatorio</div>
                            </div>
                        </div>
                        <div class="col-4 margin-bottom-10">
                            <label>Costi</label>
                            <input min="0" type="number" formControlName="costi" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.costi.errors }" />
                            <div *ngIf="submitted && f.costi.errors" class="invalid-feedback">
                                <div *ngIf="f.costi.errors.required">Campo obbligatorio</div>
                            </div>
                        </div>
                        <div class="col-4 margin-bottom-10">
                            <label>Interessi</label>
                            <input min="0" type="number" formControlName="interessi" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.interessi.errors }" />
                            <div *ngIf="submitted && f.interessi.errors" class="invalid-feedback">
                                <div *ngIf="f.interessi.errors.required">Campo obbligatorio</div>
                            </div>
                        </div>
                        <div class="col-4 margin-bottom-10">
                            <label>Tasse</label>
                            <input min="0" type="number" formControlName="tasse" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.tasse.errors }" />
                            <div *ngIf="submitted && f.tasse.errors" class="invalid-feedback">
                                <div *ngIf="f.tasse.errors.required">Campo obbligatorio</div>
                            </div>
                        </div>
                        <div class="col-4 margin-bottom-10">
                            <label>Utili</label>
                            <input min="0" type="number" formControlName="utili" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.utili.errors }" />
                            <div *ngIf="submitted && f.utili.errors" class="invalid-feedback">
                                <div *ngIf="f.utili.errors.required">Campo obbligatorio</div>
                            </div>
                        </div>
                        <div class="col-4 margin-bottom-10">
                            <label>Totale debiti</label>
                            <input min="0" type="number" formControlName="debiti" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.debiti.errors }" />
                            <div *ngIf="submitted && f.debiti.errors" class="invalid-feedback">
                                <div *ngIf="f.debiti.errors.required">Campo obbligatorio</div>
                            </div>
                        </div>

                    </div>

                    <div class="col-12 text-center">
                        <button class="btn btn-primary">Analizza</button>
                        <button class="btn btn-secondary" style="margin-left: 5px" type="button" (click)="onReset()">
                            Annulla
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row margin-0" *ngIf="risultatiEba">

        <div class="col-12 padding-left-right-15 padding-top-bottom-10 pointer borderAll text-center font-big margin-top-20" style="background-color: #92a8d1">
          Risultati
        </div>
        <div class="col-12 padding-top-bottom-10 borderR borderL borderB margin-bottom-20" style="background-color: white">
            <div class="col-4 margin-bottom-10">
                Percentile ricavi: {{risultati.percentileRicavi | number: '1.0-1':'it'}}
            </div> 
            <div class="col-4 margin-bottom-10">
                Percentile costi: {{risultati.percentileCosti | number: '1.0-1':'it'}}
            </div> 
            <div class="col-4 margin-bottom-10">
                Percentile ebitda: {{risultati.percentileEbitda | number: '1.0-1':'it'}}
            </div> 
            <div class="col-4 margin-bottom-10">
                Percentile interessi: {{risultati.percentileInteressi | number: '1.0-1':'it'}}
            </div> 
            <div class="col-4 margin-bottom-10">
                Percentile tasse: {{risultati.percentileTasse | number: '1.0-1':'it'}}
            </div> 
            <div class="col-4 margin-bottom-10">
                Percentile utili: {{risultati.percentileUtili | number: '1.0-1':'it'}}
            </div> 
            <div class="col-4 margin-bottom-10">
                Percentile debiti: {{risultati.percentileDebito | number: '1.0-1':'it'}}
            </div> 
        </div>
    </div>
</div>