import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DettaglioChartService {

  constructor() { }

  private _UnZoom: Subject<string> = new Subject<string>();
  private _Zoom: Subject<string> = new Subject<string>();

  public readonly UnZoom$: Observable<string> = this._UnZoom.asObservable();
  public readonly Zoom$: Observable<string> = this._Zoom.asObservable();

  noShowDettaglioChart(): void {
    this._UnZoom.next('unzoom');
  }

  ShowDettaglioChart(): void {
    this._Zoom.next('zoom');
  }
}
