<div class="container-fluid">
    <h2>Prodotto {{i+1}}</h2>
    <div class="row overflow-hidden" [formGroup]=" prodottoForm"><!-- style="position:relative;" -->
        <div class="col-3 align-items-center margin-bottom-10" *ngIf="lineaBusiness.idTipologiaAtt!=1">
            <div>
                <label>Descrizione prodotto</label>
                <input type="text" formControlName="nomeProdotto" class="form-control"
                    [ngClass]="{ 'is-invalid':submitted && prodottoForm.controls.nomeProdotto.errors }" />
                <div *ngIf=" submitted && prodottoForm.controls.nomeProdotto.errors" class="invalid-feedback">
                    <div *ngIf="prodottoForm.controls.nomeProdotto.errors.required">Campo obbligatorio
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="lineaBusiness.idTipologiaAtt==1">
            <div class="col-2 align-items-center margin-bottom-10">
                <label>Coltivazione</label>
                <input type="text" formControlName="coltura" class="form-control" [ngbTypeahead]="searchProdotto"
                    (focus)="focusProdotto$.next($any($event).target.value)"
                    (click)="clickProdotto$.next($any($event).target.value)" #instanceProdotto="ngbTypeahead"
                    [ngClass]="{ 'is-invalid': submitted && prodottoForm.controls.coltura.errors }"
                    [inputFormatter]="formatterProdotto" [resultFormatter]="formatterProdotto" [editable]='false' />
                <div *ngIf="submitted && prodottoForm.controls.coltura.errors" class="invalid-feedback">
                    <div *ngIf="prodottoForm.controls.coltura.errors.required">Campo obbligatorio</div>
                </div>
            </div>
            <div class="col-2 align-items-center margin-bottom-10">
                <!--<label *ngIf="nascondiBoolean === false || (booleanFirst && nascondiBoolean)">Provincia</label>-->
                <label>Provincia</label>
                <input type="text" class="form-control" formControlName="provincia" [ngbTypeahead]="searchProvincia"
                    (focus)="focusProvincia$.next($any($event).target.value)"
                    (click)="clickProvincia$.next($any($event).target.value)" #instanceProvincia="ngbTypeahead"
                    [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]='false'
                    [ngClass]="{ 'is-invalid': submitted && prodottoForm.controls.provincia.errors }" />
                <div *ngIf="submitted && prodottoForm.controls.provincia.errors" class="invalid-feedback">
                    <div *ngIf="prodottoForm.controls.provincia.errors.required">Campo obbligatorio</div>
                </div>
            </div>
            <div class="col-2 align-items-center margin-bottom-10">
                <label>Variet&agrave;</label>
                <select formControlName="varieta" class="form-control" (change)="onChangeVarieta()"
                    aria-placeholder="wefwef"
                    [ngClass]="{ 'is-invalid': submitted && prodottoForm.controls.varieta.errors }">
                    <option [ngValue]="varieta" *ngFor="let varieta of listaVarietaPerIdCategoria">
                        {{varieta.descrizione}}</option>
                </select>
                <div *ngIf="submitted && prodottoForm.controls.varieta.errors" class="invalid-feedback">
                    <div *ngIf="prodottoForm.controls.varieta.errors.required">Campo obbligatorio</div>
                </div>
            </div>
            <div class="col-2 align-items-center margin-bottom-10">
                <label>Metodo coltivazione</label>
                <select formControlName="metodoColtivazione" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodottoForm.controls.metodoColtivazione.errors }">
                    <option [ngValue]="metodoColtivazione"
                        *ngFor="let metodoColtivazione of dropsDown.listaMetodiColtivazione">
                        {{metodoColtivazione.descrizione}}</option>
                </select>
                <div *ngIf="submitted && prodottoForm.controls.metodoColtivazione.errors" class="invalid-feedback">
                    <div *ngIf="prodottoForm.controls.metodoColtivazione.errors.required">Campo obbligatorio</div>
                </div>
            </div>
            <div class="col-1 align-items-center margin-bottom-10 "
                *ngIf="prodottoForm.controls.varieta.value && prodottoForm.controls.varieta.value.latenza>2">
                <label>Et&agrave; piante</label>
                <input min="0" type="number" formControlName="etaPiante" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodottoForm.controls.etaPiante.errors }"
                    onkeyup="{if (this.value<0)  this.value=value*-1}" />
                <div *ngIf="submitted && prodottoForm.controls.etaPiante.errors" class="invalid-feedback">
                    <div *ngIf="prodottoForm.controls.etaPiante.errors.required">Campo obbligatorio</div>
                </div>
            </div>

            <div class="col-1 align-items-center margin-bottom-10 ">
                <label>
                    Ettari
                </label>
                <input min="0" type="number" formControlName="ettariColtura" class="form-control"
                    onkeyup="{if (this.value<0)  this.value=value*-1}"
                    [ngClass]="{ 'is-invalid': submitted && prodottoForm.controls.ettariColtura.errors }" />
                <div *ngIf="submitted && prodottoForm.controls.ettariColtura.errors" class="invalid-feedback">
                    <div *ngIf="prodottoForm.controls.ettariColtura.errors.required">Campo obbligatorio</div>
                </div>
            </div>
        </ng-container>


        <div class="col-4 d-flex align-items-center" *ngIf="false">
            <div class="row">
                <div class="col-7 d-flex align-items-center">
                    <label class="text-left">Si prevede
                        fidelizzazione?</label>
                </div>
                <div class="col-5">
                    <select formControlName="presenzaFidelizzazione" type="boolean" class="form-control form-select"
                        (click)="onChangeFidelizzazione(i)"
                        [ngClass]="{ 'is-invalid':submitted && prodottoForm.controls.presenzaFidelizzazione.errors }">
                        <option [ngValue]="true">Si</option>
                        <option [ngValue]="false">No</option>
                    </select>
                    <div *ngIf=" submitted && prodottoForm.controls.presenzaFidelizzazione.errors"
                        class="invalid-feedback">
                        <div *ngIf="prodottoForm.controls.presenzaFidelizzazione.errors.required">Campo obbligatorio
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="false && prodottoForm.controls.presenzaFidelizzazione.value"
            class="col-4 d-flex align-items-center">
            <div class="row">
                <div class="col-7 d-flex align-items-center">
                    <label class="text-center margin-top-10">% fidelizzazione
                        per anno: &nbsp;&nbsp; <i id=" icon" class="fa fa-1x fa-info-circle" aria-hidden="true"
                            placement="left" container="body" ngbTooltip="{{listaTips[13]?.descrizione}}"></i></label>
                </div>
                <div class="col-5 d-flex flex-column align-items-center justify-content-start ">
                    <input formControlName="fidelizzazioneAnnuale" type="number" class="form-control" min="0"
                        onkeyup="if(this.value<0){this.value= this.value *-1}"
                        [ngClass]="{ 'is-invalid':submitted && prodottoForm.controls.fidelizzazioneAnnuale.errors }" />
                    <div *ngIf="submitted && prodottoForm.controls.fidelizzazioneAnnuale.errors"
                        class="invalid-feedback">
                        <div *ngIf="prodottoForm.controls.fidelizzazioneAnnuale.errors.required">Campo obbligatorio
                        </div>
                        <div *ngIf="prodottoForm.controls.fidelizzazioneAnnuale.errors.max">Parametro fuori
                            scala. Inserire
                            un valore compreso tra 0 e {{valoreMax.fidelizzazione}}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-1 margin-bottom-10 d-flex justify-content-end align-items-center">
            <!--  style="position:absolute; right:0;" -->
            <button class="btn fa fa-trash fa-lg pointer margin-top-10" aria-hidden="true"
                (click)="eliminaProdotto(i)"></button>
        </div>

    </div>
</div>