import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalActionService {
  constructor() { }


  /* funzione scelta style */
  ModalStyle(modalPar: any): any {
    let stylesUndoButtonText: any;
    let stylesActionButtonText: any;
    let styleModalHeader: any;
    /* APP-COMPONENT */
    if (modalPar.name == "esci") {
      styleModalHeader = {

      };
      stylesUndoButtonText = {
        'btn': true,
        'btn-primary': true,
      };
      stylesActionButtonText = {
        'btn': true,
        'btn-secondary': true,
      };
    }
    if (modalPar.name == "annulla") {
      styleModalHeader = {

      };
      stylesUndoButtonText = {
        'btn': true,
        'btn-primary': true,
      };
      stylesActionButtonText = {
        'btn': true,
        'btn-secondary': true,
      };
    }
    if (modalPar.name == "errorecheckdata" || modalPar.name == "errore" || modalPar.name == 'assenzaBilancio') {
      styleModalHeader = {
        ' bg-danger': true
      };
      stylesUndoButtonText = {
        'btn': true,
        'btn-secondary': true,
      };
      stylesActionButtonText = {
      };
    }
    /* QUESTIONARIO-COMPONENT e RIS-QUESTIONARIO-COMPONENT*/
    if (modalPar.name == "salva" || modalPar.name == "confermaBp" || modalPar.name == "checkdata"
      || modalPar.name == "errorecensimentorichiedente" || modalPar.name == "checkdatarichiedente" || modalPar.name == "messaggiorichiedentegiacensito"
      || modalPar.name == "salvamodifiche") {
      styleModalHeader = {
        /* style dell'modal header  */
      };
      stylesUndoButtonText = {
        'btn': true,
        'btn-secondary': true,
      };
      stylesActionButtonText = {
        'btn': true,
        'btn-primary': modalPar.name != "salva" || modalPar.name != "salvamodifiche" ? true : false,
        'btn-success': modalPar.name.includes('salva') ? true : false,
      };
    }
    if (modalPar.name.includes("salvaBp") || modalPar.name == 'salvaBusinessPlanNuovo') {
      styleModalHeader = {
      };
      stylesUndoButtonText = {
        'btn': true,
        'btn-secondary': true,
      };
      stylesActionButtonText = {
        'btn': true,
        'btn-success': true,
      };
    }
    if (modalPar.name.includes('messaggio')) {
      styleModalHeader = {
        'bg-success': modalPar.name == "messaggiosalva" ? true : false,
        'bg-secondary': modalPar.name == "messaggioelimina" ? true : false,
        'bg-danger': modalPar.name == "messaggioerrore" ? true : false,
      };
      stylesUndoButtonText = {
        'btn': true,
        'btn-secondary': true,
      };
      stylesActionButtonText = {
        /* style dell'action button  */
      };
    }
    /* LISTA-BPLAN COMPONENT */
    if (modalPar.name == "cancellapratica" || modalPar.name.includes('elimina')) {
      styleModalHeader = {
      };
      stylesUndoButtonText = {
        'btn': true,
        'btn-secondary': true,
      };
      stylesActionButtonText = {
        'btn': true,
        'btn-danger': true,
      };
    }
    if (modalPar.name == "idleCD") {
      styleModalHeader = {
      };
      stylesUndoButtonText = {
        'btn': true,
        'btn-danger': true,
      };
      stylesActionButtonText = {
        'btn': true,
        'btn-success': true,
      };
    }
    return [styleModalHeader, stylesActionButtonText, stylesUndoButtonText]
  }
}
