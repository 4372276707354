<div class="container">


  <div class="row justify-content-center">

    <div class="col-xs-4 col-sm-4 col-md-8 col-sm-offset-1 col-md-offset-2">
      <form [formGroup]="myform" (ngSubmit)="submitTip()">


        <div class="form-group ">
          <label>Seleziona sezione help</label>
          <select class="form-control margin-bottom-20" formControlName="sez" (change)="onChange()" required>
            <option *ngFor="let sez of SezSet.rows" [value]="sez.codice">
              {{sez.label}}
            </option>
          </select>
        </div>
        <div class="backdrop" *ngIf="inserted">
          <div class="alert-box">
            <p *ngIf="almod">
              Help inserito con successo con il codice:
              <b>{{myform.controls['sez'].value}}-{{myform.controls['num'].value}}</b>
            </p>
            <p *ngIf="!almod">
              L'help con il codice: <b>{{myform.controls['sez'].value}}-{{myform.controls['num'].value}}</b> modificato
              con successo!
            </p>
            <div class="alert-box-actions">
              <button class="btn btn-primary" (click)="onCloseAlert()">Chiudi</button>
            </div>
          </div>
        </div>

      </form>


    </div>



  </div>
</div>



<div class="container" style="width:100%;">

  <div class="row">

    <div class="col-md-10 " style=" display: inline-block;height: auto;">

      <div class="mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" matSort style="height:auto; width:100%;">

          <ng-container matColumnDef="CI">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 10%;"> Codice Id: </th>
            <td mat-cell *matCellDef="let row" style="width: 10%;"> {{row.sezione}}-{{row.numero}} </td>
          </ng-container>

          <ng-container matColumnDef="descrizione">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Descrizione: </th>
            <td mat-cell *matCellDef="let row" #locRef>{{row.descrizione.length > 160 ?
              row.descrizione.slice(0,158)+'...' : row.descrizione}}</td>
          </ng-container>

          <ng-container matColumnDef="azioni" *ngIf="admin" style="width:10%;">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let row"><button class="btn btn-primary" type="button"
                style="vertical-align: middle;float: right;" (click)="onModify(row.sezione,row.numero,row.descrizione)">
                Modifica </button></td>
          </ng-container>

          <ng-container matColumnDef="azioni" *ngIf="!admin" style="width: 10%;">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let row"><button class="btn btn-primary " type="button"
                style="vertical-align: middle;float: right;" (click)="onModify(row.sezione,row.numero,row.descrizione)">
                Visualizza </button></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Nessun risultato della ricerca</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10]" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>

      </div>
      <button class="btn btn-primary" type="button" (click)="onInsert(myform.value.sez ,myform.value.num)"
        style="margin-top:4px;" *ngIf="add"> + Nuovo </button>


    </div>


    <div class="col-md-2">

      <form [formGroup]="myform" (ngSubmit)="submitTip()" *ngIf="showForm">
        <label *ngIf="new" class="mod">Inserisci Nuovo Help</label>
        <label *ngIf="!new" class="mod">Modifica Help</label>
        <div class="form-group" style="padding-top:2%;">
          <label for="codtip">Codice Help:</label>
          <input type="text" name="codtip" [value]="myform.value.sez +-+ myform.value.num " [disabled]="true">
        </div>

        <div class="form-group">
          <label>Descrizione Help:</label>
          <textarea cols="80" rows="15" class="form-control" formControlName="descr" [value]="myform.value.descr"
            required> </textarea>
        </div>

        <button class="btn btn-primary" type="submit" [disabled]="!myform.valid" *ngIf="new">Inserisci</button>
        <button class="btn btn-primary" type="submit" [disabled]="!myform.valid" *ngIf="!new && admin">Salva
          Modifiche</button>

      </form>
    </div>
  </div>

</div>