import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModelloUniversaleService {
private datiModelloUniversale = new BehaviorSubject<any>(null); /* A variant of Subject that requires an initial value and emits its current
 value whenever it is subscribed to.  */
 private submitted = new BehaviorSubject(false);

currentDatiModelloUniversale = this.datiModelloUniversale.asObservable();
currentSubmitted = this.submitted.asObservable(); /* creo un osservabile del datiModelloUniversale*/

  constructor() { }

  /* prende in input il valore corrente e e lo assegna a datiModelloUniversale */
  changeDatiModelloUniversale(value:object){
    this.datiModelloUniversale.next(value) /* sostituisce il valore assunto con il prossimo inserito*/
  }
  changeSubmitted(valore: boolean) {
    this.submitted.next(valore)
  }
}
