import { Component, Input, OnInit } from '@angular/core';
import { Categoria } from '../models/categoria.model';
import { ThisReceiver } from '@angular/compiler';
import { Businessplanjasper } from '../models/businessplanjasper.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MenuPulsantieraService } from '../header/services/menu-pulsantiera.service';

@Component({
  selector: 'app-categorie',
  templateUrl: './categorie.component.html',
  styleUrls: ['./categorie.component.css']
})
export class CategorieComponent implements OnInit {

  @Input() nomeAzienda: string;

  //public isCollapsed: boolean[] = [];
  isCollapsed: boolean[] = [];
  check_group: boolean = false;
  super_check: boolean = false; //mod
  lista_cat: Categoria[] = new Array();

  id_bp: number = 70; //da prendere dinamicamente
  bp_data: Businessplanjasper = new Businessplanjasper();
  //super_check: boolean = false;

  main_check_list: boolean[] = []; //check gruppo
  check_list: boolean[][] = []; //array da ritornare


  array_preset: string[] = [];
  optionFocus: number = -1;

  carica: boolean = false;




  constructor(public http: HttpClient, private menuPulsantieraService: MenuPulsantieraService) { }

  ngOnInit(): void {
    this.caricaCategorie();
    this.caricaBusinessPlan();
    this.sincronizzaCheckAll();

    this.menuPulsantieraService.CONTROLLA_CONFIGURAZIONE$.subscribe(
      controllo => {
        console.log(controllo);
        console.log("ceck controllo");
        this.carica = controllo;
        if (this.carica) {
          this.setResponse();
          this.http.post(environment.apiUrlQuestionario + "/categorie/carica", this.lista_cat).subscribe(res => {
            console.log(res);
          });
          this.carica = false;
        }
      })
  }


  focusSection(el: HTMLElement, i: number) {
    let pre_focus = document.getElementsByClassName('focus-section');
    console.log(pre_focus)
    if (typeof pre_focus != 'undefined' && pre_focus.length > 0) {
      pre_focus[0].classList.remove('focus-section');
    }
    el.classList.add('focus-section');

    this.showOption(i);
  }

  showOption(i: number) {
    this.optionFocus = i;
    let cheks = document.getElementsByClassName('check_' + i);
    let labels = document.getElementsByClassName('label_check_' + i);

    if (typeof cheks != 'undefined' && cheks.length > 0) {
      for (let j = 0; j < cheks.length; j++) {
        cheks[j].classList.add('displayer');
        labels[j].classList.add('displayer');
      }
    }
  }

  //gestisce i preset
  preset(n: number) {

    switch (n) {
      case 1:
        this.array_preset = ["DSCR", "IRR Equity", "IRR Progetto", "DSCR Totale"];
        break;
      case 2:
        this.array_preset = ["DSCR Cumulato Totale", "DSCR Totale"];
        break;
    }

    //azzero
    for (let i = 0; i < this.lista_cat.length; i++) {
      for (let j = 0; j < this.lista_cat[i].jasOpt.length; j++) {
        this.check_list[i][j] = false;
      }
    }

    for (let i = 0; i < this.lista_cat.length; i++) {
      for (let j = 0; j < this.lista_cat[i].jasOpt.length; j++) {
        for (let k in this.array_preset) {
          if (this.lista_cat[i].jasOpt[j].nome == this.array_preset[k]) {
            this.check_list[i][j] = true;
          }
        }
        //test
        let group_flag = true;
        for (let j = 0; j < this.lista_cat[i].jasOpt.length; j++) {
          if (this.check_list[i][j] == false) {
            group_flag = false;
          }
        }

        if (group_flag == true) {
          this.main_check_list[i] = true;
        } else {
          this.main_check_list[i] = false;
        }
        this.sincronizzaCheckAll();
        //endtest
      }

      //console.log("TEST "+this.lista_cat[0].jasOpt[0].nome);
      //this.gestisciCheck(i);
    }
    this.sincronizzaCheckAll();

  }


  caricaBusinessPlan() {
    this.http.get(environment.apiUrlQuestionario + "/bpjasper/" + this.id_bp).subscribe(res => {
      this.bp_data = res as Businessplanjasper;
      console.log(this.bp_data);
    });
  }

  //Carica le categorie dal backend
  caricaCategorie(): void {
    this.http.get<Categoria[]>(environment.apiUrlQuestionario + "/categorie").subscribe(res => {
      this.lista_cat = res;
      let group_flag = true;
      for (let i = 0; i < this.lista_cat.length; i++) {
        this.check_list[i] = [];
        this.isCollapsed.push(true); //True = collapsed, False = expanded
        for (let j = 0; j < this.lista_cat[i].jasOpt.length; j++) {
          this.check_list[i].push(this.lista_cat[i].jasOpt[j].stato);
          if (this.check_list[i][j] == false) {
            group_flag = false;
          }
        }
        this.main_check_list.push(group_flag);
        group_flag = true;
      }
      this.sincronizzaCheckAll();

    });
    this.sincronizzaCheckAll();
  }

  caricaCheck(): void {
    console.log(this.isCollapsed.length);
  }

  //Gestisce i check di gruppo
  gestisciCheck(n: number) {

    this.main_check_list[n] = !this.main_check_list[n];

    for (let i = 0; i < this.check_list[n].length; i++) {
      this.check_list[n][i] = this.main_check_list[n];
    }
    this.sincronizzaCheckAll();
  }

  //Gestisce il main check in funzione del check singolo
  gestisciSottoCheck(m: number, n: number) {

    this.check_list[m][n] = !this.check_list[m][n];


    let group_flag = true;
    for (let j = 0; j < this.lista_cat[m].jasOpt.length; j++) {
      if (this.check_list[m][j] == false) {
        group_flag = false;
      }
    }

    if (group_flag == true) {
      this.main_check_list[m] = true;
    } else {
      this.main_check_list[m] = false;
    }
    this.sincronizzaCheckAll();
  }

  elementSize(n: number): number {
    //lista_cat length[n]
    return this.lista_cat[n].jasOpt.length;
  }


  setResponse() {

    for (let i = 0; i < this.lista_cat.length; i++) {

      for (let j = 0; j < this.lista_cat[i].jasOpt.length; j++) {

        this.lista_cat[i].jasOpt[j].stato = this.check_list[i][j];

      }

    }

  }
  //Esporta le selezioni
  save() {
    this.setResponse();
    //let json_check_list = JSON.stringify(this.lista_cat);

    this.http.post(environment.apiUrlQuestionario + "/categorie/carica", this.lista_cat).subscribe(res => {
      console.log(res);
    });
  }



  //Ricarica il componente
  reload() {
    window.location.reload();
  }

  //apri singolo lista (collapse)
  open(n: number) {
    for (let i = 0; i < this.isCollapsed.length; i++) {
      this.isCollapsed[i] = true;
    }
    this.isCollapsed[n] = false;

  }


  //check all da richiamare nel change del main check
  //creare una boolean super


  sincronizzaCheckAll() {
    this.super_check = true;
    for (let i = 0; i < this.main_check_list.length; i++) {
      for (let j = 0; j < this.check_list[i].length; j++) {
        if (this.check_list[i][j] == false) {
          this.super_check = false;
          return;
        }
      }
    }

  }
  checkAll() {
    //switch
    this.super_check = !this.super_check;

    for (let i = 0; i < this.main_check_list.length; i++) {
      this.main_check_list[i] = this.super_check;
      for (let j = 0; j < this.check_list[i].length; j++) {
        this.check_list[i][j] = this.super_check;
      }
    }
  }
}
