<div class="container">
  <header>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 benvenuto">
        <!-- <p>{{ content }}</p> -->
        <h3><strong>Benvenuto in ENDY</strong><br><small><em>Enterprise Buddy</em></small><br></h3>
        <h6>
          la piattaforma dedicata all'intermediazione creditizia finalizzata <br>alla valutazione preventiva di richieste di finanziamenti <br>attraverso l'utilizzo di strumenti
          di rating e credit scoring.
        </h6>
      </div>
      <div class="col-md-6"></div>
    </div>
  </header>
</div>
