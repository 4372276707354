<div class="container-fluid">

    <div class="row nopadding">
        <div class="col-8 nopadding">
            <h2>Dossier amministrativi</h2>
        </div>
    </div>
    <div class="row nopadding" *ngIf="idRichiedenteTemp==undefined">
        <div class="col-12 nopadding d-flex align-items-center margin-top-10">
            <h3> Inserisci l'azienda di cui vuoi configurare l'ambiente.</h3>
        </div>
    </div>
    <hr>
    <ng-container *ngIf="idRichiedenteTemp!=undefined" [formGroup]="dossierAmministrativiForm">
        <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex" class="d-flex justify-content-around">
            <!-- (selectedTabChange)="resetScrollingSize()" -->
            <mat-tab label="Bilancio" [labelClass]="{'label-error':false, 'label-correct':false}">
                <ng-container *ngIf="dataBilObj !=null">
                    <div class="row d-flex flex-column margin-left-right-0 nopadding margin-top-10">
                        <div class=" col-12 col-lg-12" style="padding-left: 15px;">
                            <h3 *ngIf="diffAnni>=2">
                                L'ultimo bilancio registrato negli archivi è relativo all'anno
                                <strong>{{annoBilancio}}.</strong>
                                {{tipoInserimentoBil!=null && tipoInserimentoBil =='manuale'? 'Inserito manualmente':
                                'Ottenuto dal Registro imprese'}} in data:
                                <strong>{{dataInserimentoBilancio}}</strong>

                            </h3>
                            <h3 *ngIf="tipoInserimentoBil=='registro_imprese' && diffAnni<2 ">
                                Dati correttamente aggiornati dal Registro imprese all'ultimo anno disponibile
                                (<strong>{{annoBilancio}}</strong>). Inserito in data : <strong>
                                    {{dataInserimentoBilancio}}</strong>

                            </h3>
                            <h3 *ngIf="diffAnni<2 && tipoInserimentoBil=='manuale'">
                                L'ultimo bilancio registrato negli archivi è relativo all'anno
                                <strong>{{annoBilancio}}.</strong>
                                Inserito manualmente dall'utente in data:
                                <strong>
                                    {{dataInserimentoBilancio}}</strong>

                            </h3>
                        </div>
                    </div>
                    <hr>
                    <ng-container *ngIf="diffAnni<2 && tipoInserimentoBil=='manuale' || diffAnni>=2">
                        <div class="row margin-top-10">

                            <div class="margin-bottom-20 col-4 col-xl-4">
                                <label class>Desideri aggiornare le informazioni con i bilanci più
                                    recenti?</label>
                            </div>
                            <div class="col-1 margin-bottom-20">
                                <select formControlName="inserisciBilancio" class="form-control"
                                    (change)="onChangeBilancio()"
                                    [ngClass]="{ 'is-invalid': submitted && f.inserisciBilancio.errors }">
                                    <option [ngValue]="true">Si</option> <!-- setto il value di presenza bilancio -->
                                    <option [ngValue]="false">No</option>
                                    <div *ngIf="submitted && f.inserisciBilancio.errors" class="invalid-feedback">
                                        <div *ngIf="f.inserisciBilancio.errors.required">Campo obbligatorio</div>
                                    </div>
                                </select>
                            </div>
                            <div class="col-7 warning-label">
                                <label>NB: L'aggiornamento del bilancio comporterà l'eliminazione del Business
                                    Plan in stato 'Preparazione' e l'archiviazione dei Business Plan in stato.
                                    'Lavorazione'&nbsp;&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                                        aria-hidden="false" [autoClose]="'inside'" placement="top" container="body"
                                        [ngbTooltip]="listaTips[2]?.descrizione"></i></label>
                            </div>
                        </div>

                        <hr>
                        <div class="row margin-bottom-10 mmargin-top-10 " *ngIf="f.inserisciBilancio.value">

                            <div class=" col-lg-3">
                                <label>Inserisci bilancio:</label>
                            </div>
                            <div class=" form-check col-3">
                                <input class="form-check-input" formControlName="caricaBilancio" type="radio"
                                    name="caricaBilancio" id="caricaBilancioXbrl" checked [value]="1"
                                    (click)="onChangeCaricaBilancio(1)">
                                <label class="form-check-label" for="caricaBilancioXbrl">
                                    Carica file.xbrl
                                </label>
                            </div>
                            <div class=" form-check col-3">
                                <input class="form-check-input" formControlName="caricaBilancio" type="radio"
                                    name="caricaBilancio" id="caricaBilancioManuale" checked [value]="2"
                                    (click)="onChangeCaricaBilancio(2)">
                                <label class="form-check-label" for="caricaBilancioManuale">
                                    Inserimento manuale
                                </label>
                            </div>
                            <div class="row margin-top-20" *ngIf="f.caricaBilancio.value==1">
                                <div class="col-6 div-upload">
                                    <div class="d-flex justify-content-between">
                                        <input type="file" class="form-control upload-file" formControlName="bilancio"
                                            accept=".xbrl" (change)="getFileBilancio($event)">
                                        <button class="btn btn-primary" [disabled]="buttonDisabledBilancio"
                                            (click)="CaricaBilancio()">Carica</button>
                                    </div>
                                    <p id="p-error" *ngIf="errorBilancio!=null"><strong>{{errorBilancio}}</strong></p>
                                </div>
                                <div class="col-6  d-flex flex-column justify-content-center align-items-start">
                                    <div id="uploadcontainer"
                                        class="file-list d-flex flex-row justify-content-around  align-items-center"
                                        *ngIf="bilancioCaricato!=null">
                                        <p style="margin-bottom: 0px;">{{bilancioCaricato.name}}</p>
                                        <fa-icon class="pointer margin-left-20"
                                            style="font-size: 25px; color: rgb(138, 43, 43);" [icon]="faTrashAlt"
                                            (click)="deleteFileBilancio()">
                                        </fa-icon>
                                    </div>
                                    <div class="margin-left-20" *ngIf="messaggioerroreBilancio !=''"><label
                                            id="p-error">{{messaggioerroreBilancio}}</label></div>
                                </div>
                            </div>
                            <div class="row margin-top-20" *ngIf="f.caricaBilancio.value==2">
                                <div class="col-6 d-flex justify-content-start">
                                    <button class="btn btn-primary mr-1" type="button"
                                        (click)="goToBilancio()">Inserisci
                                        nuovo bilancio</button>
                                </div>
                            </div>

                        </div>
                    </ng-container>
                </ng-container>
                <div class="row margin-top-10" *ngIf="dataBilObj ==null">

                    <div class="margin-bottom-20 col-6 col-xl-5">
                        <label class>Come azienda di nuova formazione, non ci sono informazioni da censire in questa
                            sezione.</label>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Centrale rischi" [labelClass]="{'label-error':false, 'label-correct':false}">
                <ng-container>
                    <div class="row d-flex flex-column margin-left-right-0 nopadding margin-top-10">
                        <div class=" col-12 col-lg-12" style="padding-left: 15px;">
                            <h3 *ngIf="dataCrObj !=null">
                                L'ultima centrale rischi registrata negli archivi &egrave; relativa al
                                <strong>{{annoCr | date: 'MM/YYYY' }}.</strong>
                            </h3>
                            <h3 *ngIf="dataCrObj ==null">
                                Non ci sono informazioni relative alla centrale rischi per quest'azienda.
                            </h3>
                        </div>
                    </div>
                    <hr>
                    <ng-container>
                        <div class="row margin-top-10">
                            <div class="margin-bottom-20 col-4 col-xl-4">
                                <label class>Desideri aggiornare le informazioni con dei dati più
                                    recenti?</label>
                            </div>
                            <div class="col-1 margin-bottom-20">
                                <select formControlName="inserisciCr" class="form-control" (change)="onChangeCr()"
                                    [ngClass]="{ 'is-invalid': submitted && f.inserisciCr.errors }">
                                    <option [ngValue]="true">Si</option> <!-- setto il value di presenza Cr -->
                                    <option [ngValue]="false">No</option>
                                    <div *ngIf="submitted && f.inserisciCr.errors" class="invalid-feedback">
                                        <div *ngIf="f.inserisciCr.errors.required">Campo obbligatorio</div>
                                    </div>
                                </select>
                            </div>
                            <div class="col-7 warning-label">
                                <label>NB: L'aggiornamento della Cr comporterà l'eliminazione del Business
                                    Plan in stato 'Preparazione' e l'archiviazione dei Business Plan in stato
                                    'Lavorazione'&nbsp;&nbsp;<i id="icon" class="fa fa-1x fa-info-circle"
                                        aria-hidden="false" [autoClose]="'inside'" placement="top" container="body"
                                        [ngbTooltip]="listaTips[2]?.descrizione"></i>.</label>
                            </div>
                        </div>
                        <hr>
                        <div class="row margin-bottom-10 margin-top-20 " *ngIf="f.inserisciCr.value">

                            <div class=" col-2">
                                <label>Carica il file pdf della Cr:</label>
                            </div>
                            <div class="col-6 div-upload">
                                <div class="d-flex justify-content-between">
                                    <input type="file" class="form-control upload-file" formControlName="cr"
                                        accept=".pdf" (change)="getFileCr($event)">
                                    <button class="btn btn-primary" [disabled]="buttonDisabledCr"
                                        (click)="caricaCr()">Carica</button>
                                </div>
                                <p id="p-error" *ngIf="errorCr!=null"><strong>{{errorCr}}</strong></p>
                            </div>
                            <div class="col-6  d-flex flex-column justify-content-center align-items-start">
                                <div id="uploadcontainer"
                                    class="file-list d-flex flex-row justify-content-around  align-items-center"
                                    *ngIf="crCaricato!=null">
                                    <p style="margin-bottom: 0px;">{{crCaricato.name}}</p>
                                    <fa-icon class="pointer margin-left-20"
                                        style="font-size: 25px; color: rgb(138, 43, 43);" [icon]="faTrashAlt"
                                        (click)="deleteFileCr()">
                                    </fa-icon>
                                </div>
                                <div class="margin-left-20" *ngIf="messaggioerroreCr !=''"><label
                                    id="p-error">{{messaggioerroreCr}}</label></div>
                                <div class="margin-left-20" *ngIf="messaggioSuccessoCr !=''"><label
                                        id="p-success">{{messaggioSuccessoCr}}</label></div>
                            </div>


                        </div>
                    </ng-container>
                </ng-container>

            </mat-tab>
        </mat-tab-group>
    </ng-container>
</div>