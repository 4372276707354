import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PDFserviceService {

  constructor(private http: HttpClient) { }

  invioDatiPdf(idAziendaRichiedente: number, id_bp: number, indiciPagine: Boolean[]): Observable<any> {

    return this.http.post<any>(environment.apiUrlQuestionario + '/pdf/generaPDF/' + idAziendaRichiedente + "/" + id_bp, indiciPagine, { responseType: 'blob' as 'json' })
  }

}
