import { HttpClient } from '@angular/common/http';
import {  Component, Inject, Input, OnInit,ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PivaDataService } from 'src/app/_services/piva-data.service';
import { ChartConfiguration, ChartData, ChartEvent, ChartType,Title } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { regione, Tipo, UserObject } from 'src/app/interfaces/user-key';

@Component({
  selector: 'app-dettagli-dati-mercato',
  templateUrl: './dettagli-dati-mercato.component.html',
  styleUrls: ['./dettagli-dati-mercato.component.css']
})


export class DettagliDatiMercatoComponent implements OnInit {

  TipiAlimentazione: Tipo[]= [];
  listaRegioni: regione[] = [ 
    {id:0, idStato:0 ,regione:'Italia'}
  ];
 regioneForm!: FormGroup;
 regioneControl!:FormControl;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  constructor(public dialogRef: MatDialogRef<DettagliDatiMercatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    public dialog: MatDialog,
    private PivaService: PivaDataService) { }

  public barChartType: ChartType = 'bar';
  public allBarChartData: ChartData<'bar'>[] = [];
  listaColori =['#0F90B8','#88D4EB','#188562','#17439a','#B80F40','#EB522A','#A17635']
  listaPerDati: UserObject[] = [];

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    scales: {
      x: {},
      y: {
        min: 0
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip:{
        callbacks:{
          
          label: function(context) {
            let label = context.dataset.label || '';
            if (context.parsed.y !== null ) {
              let currentValue:number =  context.parsed.y/(context.chart.data.datasets[0].data[0] as number);
              label = new Intl.NumberFormat('it-IT', { style: 'percent'}).format(currentValue);
            }
            return context.chart.data.datasets[context.datasetIndex].label +  ": " + label;
        },

          title: function(tooltipItems) {
            return ''
            }

      }
    }
    
    }
  };

  ngOnInit(): void {
    
    this.regioneForm = new FormGroup({
      regioneControl: new FormControl('')  
    });
    this.regioneForm.controls['regioneControl'].setValue(this.listaRegioni[0], {onlySelf: true})

    this.PivaService.getRegioni().subscribe(ArrRegioni => {
      ArrRegioni.forEach(Regione => {
        this.listaRegioni.push(Regione)
      })
    })

    this.listaPerDati.push(this.data.token)
    this.PivaService.getUserObjectDataFull(this.listaPerDati).subscribe();
    this.PivaService.getMarketDataByAtecoFull(this.data.token.codiceAteco).subscribe();


    this.PivaService.getAllAlimentazioneMacchine().subscribe(Tipi=>{
      this.TipiAlimentazione = Tipi.filter(x => x.tipoAlimentazione != "benzina e metano" && x.tipoAlimentazione != "metano"  );
      this.TipiAlimentazione.push({id:6, tipoAlimentazione:"altri tipi"})
      this.compilaGrafici(0)
    });

  }

  onNoClick(): void {
    this.dialogRef.close({});
  }
 
  onChangeRegione(){
    let selectedRegione = this.regioneForm.controls['regioneControl'].value;
    this.allBarChartData = [];
    this.compilaGrafici(selectedRegione.id)
  }

  compilaGrafici(idRegione: number){
    this.PivaService.getDatiGraficiMercatoPerRegione(idRegione).subscribe(datiPerAnno =>{
      datiPerAnno.forEach((e)=>{
        let barChartData: ChartData<'bar'> = {labels: [ e.anno ],datasets: []};
        let dataSet = barChartData.datasets;
        e.listaDatiPerAnno.forEach((al,i) =>{
          if(i==0){
            dataSet.push({data:[al.quantita] , hidden:true, label: 'totale'})
          }else{
           
            dataSet.push({data:[al.quantita] , label: this.TipiAlimentazione[i-1]?.tipoAlimentazione , backgroundColor: this.listaColori[i-1],hoverBackgroundColor:this.listaColori[i-1]})
          }
        })
        this.allBarChartData.push(barChartData) 
      })
    });
  }

}




