import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DoughnutChartComponent } from '../doughnut-chart/doughnut-chart.component';
import { InterazioneRisultatiQuestionarioService } from '../interazione-risultati-questionario.service';

@Component({
  selector: 'app-side-left-menu-potenziometri',
  templateUrl: './side-left-menu-potenziometri.component.html',
  styleUrls: ['./side-left-menu-potenziometri.component.css']
})
export class SideLeftMenuPotenziometriComponent implements OnInit {

  @Input() name_potenziometro_one: string;
  @Input() name_potenziometro_two: string;
  @Input() name_potenziometro_three: string;
  @Input() name_potenziometro_four: string;
  @Input() name_potenziometro_five: string;
  @Input() name_potenziometro_six: string;

  @Input() lancetta_one: string;
  @Input() lancetta_two: string;
  @Input() lancetta_three: string;
  @Input() lancetta_four: string;
  @Input() lancetta_five: string;
  @Input() lancetta_six: string;


  @Input() rotate_dscr: string;
  @Input() rotate_debitoria: string;
  @Input() rotate_mcc: string;
  @Input() rotate_garanzia: string;
  @Input() rotate_dscr_mock1: string;
  @Input() rotate_dscr_mock2: string;

  @Input() valore_tip_dscr: any;
  @Input() valore_tip_debitoria: any;
  @Input() valore_tip_mcc: any;
  @Input() valore_tip_garanzia: any;

  @Input() ancore: string[];

  device: number[] = [1, 2];
  tips_types: string[] = ['DSRC', 'Debitoria', 'MCC', 'Garanzie'];
  close: boolean;

  right_side_menu_close: boolean = false;

  constructor(private _interazioneRisultatiQuestionarioService: InterazioneRisultatiQuestionarioService) { }

  ngOnInit(): void {
    this._interazioneRisultatiQuestionarioService.dilatateContainer(['74%', '13%', '13%', '100%']);

    this._interazioneRisultatiQuestionarioService.observeRightBehaviour$
      .subscribe((right) => {
        this.right_side_menu_close = right;
      })
  }

  navLeft(): void {

    if (this.close) {
      this.openNavLeft();
      this.close = false;
    }
    else {
      this.closeNavLeft();
      this.close = true;
    }
  }

  openNavLeft(): void {
    document.getElementById("mySidenav").style.width = "15%";

    this._interazioneRisultatiQuestionarioService.checkLeftSide(false);
    if (!this.right_side_menu_close) {
      this._interazioneRisultatiQuestionarioService
        .dilatateContainer(['74%', '13%', '13%', '100%']);
    }
    if (this.right_side_menu_close) {
      this._interazioneRisultatiQuestionarioService
        .dilatateContainer(['82%', '13%', '5%', '100%']);
    }

    document.querySelector("#mySidenav > a:nth-child(2) > app-doughnut-chart > div").setAttribute('style', 'display:block');
    document.querySelector("#mySidenav > a:nth-child(3) > app-doughnut-chart > div").setAttribute('style', 'display:block');
    document.querySelector("#mySidenav > a:nth-child(4) > app-doughnut-chart > div").setAttribute('style', 'display:block');
    document.querySelector("#mySidenav > a:nth-child(5) > app-doughnut-chart > div").setAttribute('style', 'display:block');
    document.querySelector("#mySidenav > a:nth-child(6) > app-doughnut-chart > div").setAttribute('style', 'display:block');
    document.querySelector("#mySidenav > a:nth-child(7) > app-doughnut-chart > div").setAttribute('style', 'display:block');
  }

  closeNavLeft(): void {
    document.getElementById("mySidenav").style.width = "0";
    this._interazioneRisultatiQuestionarioService.checkLeftSide(true);
    if (this.right_side_menu_close) {
      this._interazioneRisultatiQuestionarioService
        .dilatateContainer(['95%', '2.5%', '2.5%', '100%']);
    }
    if (!this.right_side_menu_close) {
      this._interazioneRisultatiQuestionarioService
        .dilatateContainer(['82%', '5%', '13%', '100%']);
    }

    document.querySelector("#mySidenav > a:nth-child(2) > app-doughnut-chart > div").setAttribute('style', 'display:none');
    document.querySelector("#mySidenav > a:nth-child(3) > app-doughnut-chart > div").setAttribute('style', 'display:none');
    document.querySelector("#mySidenav > a:nth-child(4) > app-doughnut-chart > div").setAttribute('style', 'display:none');
    document.querySelector("#mySidenav > a:nth-child(5) > app-doughnut-chart > div").setAttribute('style', 'display:none');
    document.querySelector("#mySidenav > a:nth-child(6) > app-doughnut-chart > div").setAttribute('style', 'display:none');
    document.querySelector("#mySidenav > a:nth-child(7) > app-doughnut-chart > div").setAttribute('style', 'display:none');
  }

}
