/* ----------------------------------------------------------------
 * SERVIZIO DATI (API-KEY)
 * Fornisce i metodi per accedere alle risorse pubbliche e protette
 * ---------------------------------------------------------------- */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrlNode + '/api/cr/';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(API_URL + 'all', { responseType: 'text' });
  }

  getUserBoard(): Observable<any> {
    return this.http.get(API_URL + 'user', { responseType: 'text' });
  }

  getFrontofficeBoard(): Observable<any> {
    return this.http.get(API_URL + 'frontoffice', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(API_URL + 'administrator', { responseType: 'text' });
  }

}
