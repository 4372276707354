import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalisiMccService {

  private datiAnalisiMcc = new BehaviorSubject<any>(null);
  private submitted = new BehaviorSubject(false);
  currentSubmitted = this.submitted.asObservable();
  currentDatiAnalisiMcc = this.datiAnalisiMcc.asObservable();

  constructor() { }

  changeSubmitted(valore: boolean) {
    this.submitted.next(valore)
  }

  changeDatiAnalisiMcc(valore: Object) {
    this.datiAnalisiMcc.next(valore)
  }
}
