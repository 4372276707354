import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BeanStatico } from '../condivisi/beanStatico';
import { VarietaProdotto } from '../condivisi/prodotto';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';

@Component({
  selector: 'qs-colture-aggiuntive',
  template: `

<div [formGroup]="coltureAggiuntiveForm" class="borderTThin">
  <div class="row row-cols-3 row-cols-lg-5 margin-top-10">  
    <div class="margin-bottom-10 col">
      <label>Categoria</label>
      <input type="text" formControlName="categoria" class="form-control" [ngbTypeahead]="searchCategoria"
      (focus)="focusCategoria$.next($any($event).target.value)" (click)="clickCategoria$.next($any($event).target.value)"
      #instanceCategoria="ngbTypeahead" [ngClass]="{ 'is-invalid': submitted && f.categoria.errors }"
      [inputFormatter]="formatterCategoria" [resultFormatter]="formatterCategoria" [editable]='false'
      />
      <div *ngIf="submitted && f.categoria.errors" class="invalid-feedback">
        <div *ngIf="f.categoria.errors.required">Campo obbligatorio</div>
      </div>
    </div>

    <div class="margin-bottom-10 col">
      <label>Coltivazione</label>
      <input type="text" formControlName="prodotto" class="form-control" [ngbTypeahead]="searchProdotto"
      (focus)="focusProdotto$.next($any($event).target.value)" (click)="clickProdotto$.next($any($event).target.value)"
      #instanceProdotto="ngbTypeahead" [ngClass]="{ 'is-invalid': submitted && f.prodotto.errors }"
      [inputFormatter]="formatterProdotto" [resultFormatter]="formatterProdotto" [editable]='false'
      />
      <div *ngIf="submitted && f.prodotto.errors" class="invalid-feedback">
        <div *ngIf="f.prodotto.errors.required">Campo obbligatorio</div>
      </div>

  <!--   <div class="form-check nopadding">
        <input type="checkbox" formControlName="bio" id="bio"/>
        <label for="bio">&nbsp; BIO &nbsp;</label>                
      </div> -->
    </div>

    <div class="margin-bottom-10 col">
      <label>Variet&agrave;</label>
      <select formControlName="varieta" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.varieta.errors }">
        <option [ngValue]="varieta" *ngFor="let varieta of listaVarieta">{{varieta.descrizione}}</option>
      </select>
      <div *ngIf="submitted && f.varieta.errors" class="invalid-feedback">
        <div *ngIf="f.varieta.errors.required">Campo obbligatorio</div>
      </div>
    </div>

    <div class="margin-bottom-10 col" *ngIf="f.prodotto.value && !f.prodotto.value.flagRotazione && idTipoProgetto == 1">
      <label>Et&agrave; piante (anni)</label>
      <input min="0" type="number" formControlName="etaPiante" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.etaPiante.errors }" />
      <div *ngIf="submitted && f.etaPiante.errors" class="invalid-feedback">
        <div *ngIf="f.etaPiante.errors.required">Campo obbligatorio</div>
      </div>
    </div>

    <div class="margin-bottom-10 col" *ngIf="f.prodotto.value && f.prodotto.value.flagRotazione">
      <label>Produzione</label>
      <select formControlName="produzione" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.produzione.errors }" (change)="f.produzione.value == 'campo' && coltureRotazioneArray.length == 0  ? aggiungiColtura() : f.produzione.value == 'serra' ? coltureRotazioneFormArray.clear() : ''">
        <option value="campo">Campo aperto</option>
        <option value="serra">Serra</option>
      </select>
      <div *ngIf="submitted && f.produzione.errors" class="invalid-feedback">
        <div *ngIf="f.produzione.errors.required">Campo obbligatorio</div>
      </div>
    </div>

    <div class="margin-bottom-10 col">
      <label>
        Ettari per la coltura
      </label>
      <input min="0" type="number" formControlName="ettariColtura" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.ettariColtura.errors }" (change)="output()"/>
      <div *ngIf="submitted && f.ettariColtura.errors" class="invalid-feedback">
        <div *ngIf="f.ettariColtura.errors.required">Campo obbligatorio</div>
      </div>
    </div>

  </div>

  <div class="col-12 font-bold nopadding" *ngIf="f.varieta.value && f.prodotto.value.flagRotazione && f.produzione.value == 'campo'">Durante l'anno fai anche altre colture?</div>

  <div class="margin-bottom-20" [hidden]="!(f.varieta.value && f.prodotto.value.flagRotazione && f.produzione.value == 'campo')">

    <div class="col-12 nopadding margin-bottom-20" *ngFor="let item of coltureRotazioneArray; let i = index">
      <app-orticoli [formGroup]="item" [provincia]="_provincia" [idProdotto]="f.prodotto.value.id" [submitted]="submitted" (eventoOutput)="removeColturaAggiuntiva(i)" (caricamentoOutput)="outputCaricamento()"></app-orticoli>

    </div> 
    <div style="text-align:right">
      <button class="btn btn-primary mr-1" type="button" (click)="aggiungiColtura()">Aggiungi coltivazione</button>
    </div>
    
  </div>
</div>

  `,
  styles: [`
  label{
    display: inline-block;
    margin-bottom: 0rem !important;
  }
  `]
})
export class ColtureAggiuntiveComponent implements OnInit {
  @Input('formGroup') public coltureAggiuntiveForm!: UntypedFormGroup;
  //@Input('idProvincia')  idProvincia: any;
  @Input('idTipoProgetto') idTipoProgetto: any;
  @Input('idProdotto') idProdotto: any;
  @Input('submitted') submitted: boolean = false;
  @Input()
  get provincia(): any { return this._provincia; }
  set provincia(provincia: any) {
    this._provincia = provincia;
    this.ngOnInit();

  }
  private _provincia: any = '';

  @Output() eventoOutput = new EventEmitter();
  @Output() caricamentoOutput = new EventEmitter();

  listaVarieta: VarietaProdotto[] = [];
  listaCategorie: BeanStatico[] = [];
  listaProdottiCategoria: BeanStatico[] = [];
  tempIdProdotto: number = -1;
  tempIdVarieta: number = -1;
  tempIdCategoria: number = -1;
  idPrecedenteCategoria: number = -1;
  idPrecedenteProdotto: number = -1;
  modificaBoolean = false;
  faCheck = faCheck;
  faTimes = faTimes;
  faEdit = faEdit;

  constructor(private httpClient: HttpClient, private formBuilder: UntypedFormBuilder, private datiQuestionarioService: DatiQuestionarioService) { }

  ngOnInit() {

    if ((this.coltureAggiuntiveForm.valid || this.coltureAggiuntiveForm.disabled) && this.f.categoria.value != '') {
      this.tempIdProdotto = this.f.prodotto.value.id;
      this.tempIdVarieta = this.f.varieta.value.id;
      this.tempIdCategoria = this.f.categoria.value.id;
      this.modificaBoolean = true;
    } else if (this.f.categoria.value != '') {
      this.tempIdCategoria = this.f.categoria.value.id;
      this.f.prodotto.value != '' ? this.tempIdProdotto = this.f.prodotto.value.id : '';
      this.f.varieta.value != '' ? this.tempIdVarieta = this.f.varieta.value.id : '';

    }


    if (this.provincia && this.provincia.id) {

      const param = {
        idRegione: this.provincia.idRegione,
        idProvincia: this.provincia.id
      }
      this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getCategorie', param).subscribe(data => {
        this.listaCategorie = [];
        data.forEach(element => {
          const elementoLista = { id: element.id, descrizione: element.descrizione };
          this.listaCategorie.push(elementoLista)
          if (element.id == this.tempIdCategoria) {
            this.f.categoria.setValue(elementoLista);
            this.tempIdCategoria = -1;
          }
        });
      });

    } else {
      this.f.categoria.setValue('');
      this.f.prodotto.setValue('');
      this.f.varieta.setValue('');
      this.f.etaPiante.setValue('');
      this.f.categoria.disable();
      this.f.prodotto.disable();
      this.f.varieta.disable();
      this.idPrecedenteCategoria = -1;
      this.idPrecedenteProdotto = -1;
      this.tempIdProdotto = -1;
      this.tempIdVarieta = -1;
      this.tempIdCategoria = -1;

    }



    //ascolta il change di categoria e agisce di conseguenza
    this.coltureAggiuntiveForm.controls.categoria.valueChanges.pipe(delay(0)).subscribe(selectedValue => {
      if (typeof selectedValue === 'undefined' || (selectedValue && this.idPrecedenteCategoria != selectedValue.id)) {
        this.f.prodotto.setValue('');
        this.f.varieta.setValue('');
        this.idPrecedenteProdotto = -1;

        if (selectedValue) {
          this.f.prodotto.enable();
          this.idPrecedenteCategoria = this.f.categoria.value.id;
          this.listaProdottiCategoria = [];
          const param = {
            idCategoria: this.f.categoria.value.id,
            idRegione: this._provincia.idRegione,
            idProvincia: this._provincia.id
          }
          this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProdottiByIdCategoria', param).subscribe(data => {
            data.forEach(element => {
              const elementoLista = { id: element.id, descrizione: element.descrizione, flagRotazione: element.flagRotazione };
              this.listaProdottiCategoria.push(elementoLista)
              if (element.id == this.tempIdProdotto) {
                this.f.prodotto.setValue(elementoLista);
                this.tempIdProdotto = -1;
              }
            });
          })

        }
        else {
          this.idPrecedenteCategoria = -1;
          this.f.prodotto.disable();
          this.f.varieta.disable();
        }
      }
    })

    //ascolta il change di prodotto e agisce di conseguenza
    this.coltureAggiuntiveForm.controls.prodotto.valueChanges.pipe(delay(0)).subscribe(selectedValue => {
      if (typeof selectedValue === 'undefined' || (selectedValue && this.idPrecedenteProdotto != selectedValue.id)) {
        this.f.varieta.setValue('');
        if (selectedValue) {
          this.f.varieta.enable();
          this.idPrecedenteProdotto = this.f.prodotto.value.id;
          this.listaVarieta = [];
          const param = {
            idProdotto: selectedValue.id,
            idRegione: this._provincia.idRegione,
            idProvincia: this._provincia.id
          }
          this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getVarietaByIdProdotto', param).subscribe(data => {
            data.forEach(element => {
              const elementoLista = { id: element.id, descrizione: element.descrizione, latenza: element.anniLatenzaProduttiva };
              this.listaVarieta.push(elementoLista)
              if (element.id == this.tempIdVarieta) {
                this.f.varieta.setValue(elementoLista);
                this.tempIdVarieta = -1;
                this.outputCaricamento();
              }

            });
          })
        }
        else {
          this.idPrecedenteProdotto = -1;
          this.f.varieta.disable();
        }
      }
    })


  }

  // convenience getter for easy access to form fields
  get f() { return this.coltureAggiuntiveForm.controls; };
  get coltureRotazioneFormArray(): UntypedFormArray { return <UntypedFormArray> this.coltureAggiuntiveForm.controls.coltureRotazioneArray; };
  get coltureRotazioneArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.coltureRotazioneFormArray.controls; };
  formatterCategoria = (categoria: BeanStatico) => categoria.descrizione;

  @ViewChild('instanceCategoria', { static: true }) instanceCategoria!: NgbTypeahead;
  focusCategoria$ = new Subject<string>();
  clickCategoria$ = new Subject<string>();


  searchCategoria = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickCategoria$.pipe(filter(() => this.instanceCategoria && !this.instanceCategoria.isPopupOpen()));
    const inputFocus$ = this.focusCategoria$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.listaCategorie
        : this.listaCategorie.filter(categoria => new RegExp(term.replace("(", "\\(").replace(")", "\\)"), 'mi').test(categoria.descrizione))))
    );
  }

  formatterProdotto = (prodotto: BeanStatico) => prodotto.descrizione;

  @ViewChild('instanceProdotto', { static: true }) instanceProdotto!: NgbTypeahead;
  focusProdotto$ = new Subject<string>();
  clickProdotto$ = new Subject<string>();


  searchProdotto = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickProdotto$.pipe(filter(() => this.instanceProdotto && !this.instanceProdotto.isPopupOpen()));
    const inputFocus$ = this.focusProdotto$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.listaProdottiCategoria
        : this.listaProdottiCategoria.filter(prodotto => new RegExp(term, 'mi').test(prodotto.descrizione))))
    );
  }

  output() {
    this.eventoOutput.next(null);
  }

  outputCaricamento() {
    this.caricamentoOutput.next(null);
  }

  conferma() {
    this.submitted = true;
    if (this.coltureAggiuntiveForm.valid) {
      this.modificaBoolean = true;
      this.f.categoria.disable();
      this.f.prodotto.disable();
      this.f.varieta.disable();
    }
  }

  modifica() {
    this.modificaBoolean = false;
    this.submitted = false;
    this.coltureAggiuntiveForm.enable()

  }

  initColtura() {
    return this.formBuilder.group({
      bio: [false],
      categoria: ['', Validators.required],
      prodotto: [{ value: '', disabled: true }, Validators.required],
      varieta: [{ value: '', disabled: true }, Validators.required],
      contratto: [''],
      qualitaProduzione: [''],
    })

  }

  aggiungiColtura() {
    this.coltureRotazioneFormArray.push(this.initColtura());
  }

  removeColturaAggiuntiva(index: number) {
    this.coltureRotazioneFormArray.removeAt(index);
    this.coltureAggiuntiveForm.updateValueAndValidity();
  }

}
