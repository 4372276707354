import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BeanStatico } from '../condivisi/beanStatico';
import { Provincia } from '../condivisi/provincia';
import { Richiedente } from '../interface/richiedente';
import { LoadingService } from '../loading.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { Cliente } from './../interface/cliente';
import { Router } from '@angular/router';
import { ClienteService } from './../_services/cliente.service';
import { RichiedenteService } from '../_services/richiedente.service';

@Component({
  selector: 'app-censimento-cliente',
  templateUrl: './censimento-cliente.component.html',
  styleUrls: ['./censimento-cliente.component.css']
})
export class CensimentoClienteComponent implements OnInit {

  anagraficaForm: UntypedFormGroup;


  initialValues: any;
  nomeSalvataggio: string;
  date: Date;
  contratti;

  clienteTemp: any = null;
  maxPiva;
  numeroPivaUtilizzate;

  listaAnni: number[];
  currentAnno = new Date().getFullYear()

  idRichiedenteTemp: number = 0;
  listaContratti: any[] = [];
  listaTipiCliente: BeanStatico[] = [];
  listaProvince: Provincia[] = [];
  listaTipiAzienda: BeanStatico[] = [];
  listaComuni: BeanStatico[];


  isLoading: boolean = false;
  faInfo = faInfo;
  tempIdComune: number = -1;
  tempIdContratto: number = -1;
  submitted = false;

  constructor(
    private router: Router,
    private clienteService: ClienteService,
    private richiedenteService: RichiedenteService,
    private httpClient: HttpClient,
    private loading: LoadingService,
    private tokeStorageService: TokenStorageService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal) {

    this.anagraficaForm = this.formBuilder.group({
      nomeAzienda: [''],
      partitaIva: [''],
      tipoAzienda: [''],
      sedeLegale: [''],
      provincia: [''],
      comune: [{ value: '', disabled: true }],
      cap: [''],
      emailCliente: [''],
      pecCliente: [''],
      telefonoCliente: [''],
      numeroContratto: [''],
      cdi: [''],
      tipoCliente: [''],
      tipoContratto: [{ value: '', disabled: true }],
      maxPartiteIva: [''],
      dataContratto: [''],
      numeroUtenze: [''],
      annoCostituzione: [''],
      startUp: [false],

    })
    this.initialValues = this.anagraficaForm.value;
  }
  get f() { return this.anagraficaForm.controls; }

  ngOnInit(): void {
    this.listaAnni = []
    for (let i = this.currentAnno; i >= 1950; i--) {
      this.listaAnni.push(i);
    }
    this.submitted = false;


    this.onLoadSelectCliente()
    this.updateValidators()
  }
  /* verifico la partita iva quando inserisco un nuovo cliente. se trova un match apre la modale. se l'admin clicca modifica i campi vengono compilati con i dati della p.iva trovata */
  async checkPartitaIva() {
    if (this.f.partitaIva.valid) {
      this.clienteService.getAnagraficaClienteByPiva(this.f.partitaIva.value).subscribe(response => {
        if (response != null) {
          this.clienteTemp = response;
          let request = {
            partitaIva: response.partitaIva,
            ragioneSociale: response.ragioneSociale,
            sedeLegale: response.sedeLegale,
            cap: response.cap,
            comune: response.comune.descrizione,
            provincia: response.provincia.sigla,
          }
          if (this.modalService.hasOpenModals) {
            this.modalService.dismissAll();
          }
          const modalRef = this.modalService.open(ModalcontentComponent, {
            centered: true,
            backdrop: 'static',
            backdropClass: 'customBackdrop',
          });
          modalRef.componentInstance.modalPar = {
            name: "checkdata",
            description: request,
            actionButtonText: "Modifica",
            undoButtonText: "Annulla",
            question: " Desideri modificare i dati del cliente?"
          }
          modalRef.result.then(r => {
            if (r) {
              this.tempIdComune = response.comune.id;
              this.tempIdContratto = response.tipoContratto.id

              this.listaTipiAzienda.forEach(tipoAzienda => {
                if (tipoAzienda.id == response.formaGiuridica.id) {
                  this.f.tipoAzienda.disable();
                  this.f.tipoAzienda.setValue(tipoAzienda);
                }
              })
              this.listaProvince.forEach(provincia => {
                if (provincia.id == response.provincia.id) {
                  this.f.provincia.setValue(provincia);
                }
              })
              this.listaTipiCliente.forEach(cliente => {
                if (cliente.id == response.tipoCliente.id) {
                  //non cambiare l'ordine'
                  this.f.tipoCliente.disable();
                  this.f.tipoCliente.setValue(cliente);
                }
              })

              this.f.partitaIva.setValue(response.partitaIva);
              this.f.partitaIva.disable();
              this.f.nomeAzienda.setValue(response.ragioneSociale);
              this.f.nomeAzienda.disable()
              this.f.sedeLegale.setValue(response.sedeLegale);
              this.f.cap.setValue(response.cap);
              this.f.emailCliente.setValue(response.emailCliente);
              this.f.pecCliente.setValue(response.pecCliente);
              this.f.telefonoCliente.setValue(response.telefonoCliente);
              this.f.numeroContratto.setValue(response.numeroContratto);
              this.f.maxPartiteIva.setValue(response.maxPartiteIva);
              this.f.numeroUtenze.setValue(response.numeroUtenze);
              this.f.cdi.setValue(response.cdi);
              this.f.dataContratto.setValue(response.dataContratto);
              this.f.numeroUtenze.setValue(response.numeroUtenze)
              this.f.annoCostituzione.setValue(response.annoCostituzione);
              this.f.annoCostituzione.disable()
              this.f.startUp.setValue(response.isStartUp)


            }
            else {
              this.f.partitaIva.setValue('')
              this.f.partitaIva.enable();



            }
            this.f.sedeLegale.updateValueAndValidity();
            this.f.emailCliente.updateValueAndValidity();
            this.f.pecCliente.updateValueAndValidity();
            this.f.telefonoCliente.updateValueAndValidity();
            this.f.numeroContratto.updateValueAndValidity();
            /* this.f.tipoCliente.updateValueAndValidity();  altrimenti partono due chiamate*/
            this.f.tipoContratto.updateValueAndValidity();
            this.f.maxPartiteIva.updateValueAndValidity();
            this.f.cap.updateValueAndValidity();
            this.f.partitaIva.updateValueAndValidity();
            this.f.tipoAzienda.updateValueAndValidity();
            this.f.nomeAzienda.updateValueAndValidity();
            /*  this.f.provincia.updateValueAndValidity(); altrimenti partono due chiamate*/
            this.f.comune.updateValueAndValidity();
            this.f.cdi.updateValueAndValidity();
            this.f.dataContratto.updateValueAndValidity();
            this.f.numeroUtenze.updateValueAndValidity()
            this.f.annoCostituzione.updateValueAndValidity(),
              this.f.startUp.updateValueAndValidity()
          })
        }
      })
    }
  }
  updateValidators() {
    this.f.partitaIva.setValidators([Validators.required, Validators.minLength(11), Validators.maxLength(11)]);
    this.f.tipoAzienda.setValidators(Validators.required);
    this.f.nomeAzienda.setValidators(Validators.required);
    this.f.provincia.setValidators(Validators.required);
    this.f.comune.setValidators(Validators.required);
    this.f.sedeLegale.setValidators(Validators.required);
    this.f.cap.setValidators(Validators.required);
    this.f.emailCliente.setValidators([Validators.required, Validators.email]);
    this.f.pecCliente.setValidators([Validators.required, Validators.email]);
    this.f.telefonoCliente.setValidators(Validators.required);
    this.f.numeroContratto.setValidators(Validators.required);
    this.f.tipoCliente.setValidators(Validators.required);
    this.f.tipoContratto.setValidators(Validators.required);
    this.f.maxPartiteIva.setValidators(Validators.required);
    this.f.cdi.setValidators(Validators.required);
    this.f.dataContratto.setValidators(Validators.required);
    this.f.numeroUtenze.setValidators(Validators.required);
    this.f.annoCostituzione.setValidators(Validators.required),


      this.f.sedeLegale.updateValueAndValidity();
    this.f.emailCliente.updateValueAndValidity();
    this.f.pecCliente.updateValueAndValidity();
    this.f.telefonoCliente.updateValueAndValidity();
    this.f.numeroContratto.updateValueAndValidity();
    this.f.tipoCliente.updateValueAndValidity();
    this.f.tipoContratto.updateValueAndValidity();
    this.f.maxPartiteIva.updateValueAndValidity();
    this.f.cap.updateValueAndValidity();
    this.f.partitaIva.updateValueAndValidity();
    this.f.tipoAzienda.updateValueAndValidity();
    this.f.nomeAzienda.updateValueAndValidity();
    this.f.provincia.updateValueAndValidity();
    this.f.comune.updateValueAndValidity();
    this.f.cdi.updateValueAndValidity();
    this.f.dataContratto.updateValueAndValidity();
    this.f.numeroUtenze.updateValueAndValidity()
    this.f.annoCostituzione.updateValueAndValidity(),
      this.f.startUp.updateValueAndValidity()

  }

  onLoadSelectCliente() {
    //tipo cliente
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipoCliente').subscribe(
      response => {
        this.listaTipiCliente = response;
      }
    )

    //tipo azienda, ragione sociale
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda').subscribe(data => {
      this.listaTipiAzienda = data;
    });

    //province
    this.httpClient.get<Provincia[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProvince').subscribe(data => {
      this.listaProvince = data;
    })
    //comuni
    this.f.provincia.valueChanges.subscribe(selectedValue => {
      this.listaComuni = [];
      if (this.tempIdComune == -1) {
        this.f.comune.setValue('');
      }
      if (selectedValue) {
        this.f.comune.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTerritoriByIdProvincia', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const elementoLista = { id: element.id, descrizione: element.comune };
            //console.log(elementoLista)
            this.listaComuni.push(elementoLista)

            if (element.id == this.tempIdComune) {
              this.f.comune.setValue(elementoLista);
              //this.f.comune.disable();
              this.tempIdComune = -1;
            }
            /* carica il comune corrispondente se la p iva è gia stata incontrata */
          });
        });
      }
      else {
        this.f.comune.disable();
      }
      this.f.comune.updateValueAndValidity();
    })
    // Tipo contratto
    this.f.tipoCliente.valueChanges.subscribe(selectedtipo => {
      this.listaContratti = [];
      if (this.tempIdContratto == -1) {
        this.f.tipoContratto.setValue('');
      }
      // se selectedValue non vuoto
      if (selectedtipo) {
        this.f.tipoContratto.enable();
        this.f.maxPartiteIva.setValue('');
        this.f.numeroUtenze.setValue('');
        this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipoContrattoByIdTipoCliente',
          { idTipoCliente: selectedtipo.id }).subscribe(
            (response) => {
              response.forEach(element => {
                const contratto = {
                  id: element.id,
                  descrizione: element.descrizione,
                  numeroPartiteIva: element.numeroPartiteIva,
                  numeroUtenze: element.numeroUtenze,
                }
                /* console.log(contratto) */
                this.listaContratti.push(contratto)

                if (contratto.id == this.tempIdContratto) {
                  this.f.tipoContratto.setValue(contratto);
                  this.onChangeTipoContratto();

                  this.tempIdContratto = -1;
                } /* else {
                  this.f.maxPartiteIva.setValue('')
                  this.f.maxPartiteIva.enable()
                } */
              });
            })
      } else {
        this.f.tipoContratto.disable();
      }
      this.f.tipoContratto.updateValueAndValidity();
      this.f.maxPartiteIva.updateValueAndValidity();
      this.f.numeroUtenze.updateValueAndValidity()
    })
  }


  onChangeTipoContratto() {
    /* max iva commercialista */
    if (this.f.tipoContratto.value != '') {
      this.f.maxPartiteIva.setValue(this.f.tipoContratto.value.numeroPartiteIva)
      this.f.numeroUtenze.setValue(this.f.tipoContratto.value.numeroUtenze)
      if (this.f.tipoCliente.value.id == 1) {
        this.f.maxPartiteIva.disable()
        this.f.numeroUtenze.disable()
      }
      else {
        this.f.maxPartiteIva.enable()
        this.f.numeroUtenze.enable()
      }
      this.f.maxPartiteIva.updateValueAndValidity();
    }

  }


  onReset() {
    this.anagraficaForm.reset(this.initialValues);
    this.f.partitaIva.enable();
    this.f.annoCostituzione.enable()
    this.f.numeroContratto.enable();
    this.f.tipoCliente.enable();
    this.f.cdi.enable();
    this.f.tipoContratto.enable();
    this.f.dataContratto.enable();
    this.f.maxPartiteIva.enable();
    this.f.numeroUtenze.enable();


    this.submitted = false;
  }
  submit() {
    this.nomeSalvataggio = '';
    if (this.anagraficaForm.invalid) {
      this.submitted = true;
    } else {
      this.submitted = false;

      /* prendo l'id richiedente solo nel caso azienda singola e solo se il cliente è gia censito */
      if (this.f.tipoCliente.value.id == 1) {
        if (this.clienteTemp != null) {
          this.httpClient.post<number>(environment.apiUrlQuestionario + '/fasGoAlgo/getIdRichiedente', { idCliente: this.clienteTemp.id }).subscribe(response => {
            this.idRichiedenteTemp = response
            /* console.log(this.idRichiedenteTemp) */
          })
        }
      }
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
      }
      this.nomeSalvataggio = this.f.nomeAzienda.value + " - " + this.f.partitaIva.value

      const modalRef = this.modalService.open(ModalcontentComponent);
      modalRef.componentInstance.modalPar = {
        name: "salva",
        description: this.nomeSalvataggio,
        title: "Salvataggio cliente",
        actionButtonText: "Salva",
        undoButtonText: "Indietro",
      }
      modalRef.result.then(r => {
        if (r.promise) {
          this.nomeSalvataggio == r.nomesalvataggio;
          if (this.nomeSalvataggio != '') {
            this.salvaCliente();
          }
        }
      })
    }
  }
  salvaCliente() {
    const numeroPivaUtilizzate = this.f.tipoCliente.value.id == 1 ? 1 : 0;

    /* invertire il formato della data */
    const date = new Date;
    let cliente: Cliente = {
      id: this.clienteTemp == null ? 0 : this.clienteTemp.id, /* 0 o id del salvataggio */
      partitaIva: this.f.partitaIva.value,
      codiceFiscale: this.clienteTemp == null ? null : this.clienteTemp.codiceFiscale,
      ragioneSociale: this.f.nomeAzienda.value,
      idFormaGiuridica: this.f.tipoAzienda.value.id,
      sedeLegale: this.f.sedeLegale.value,
      idRegione: this.f.provincia.value.idRegione,
      idProvincia: this.f.provincia.value.id,
      idComune: this.f.comune.value.id,
      cap: this.f.cap.value,
      telefonoCliente: this.f.telefonoCliente.value,
      emailCliente: this.f.emailCliente.value,
      pecCliente: this.f.pecCliente.value,
      cdi: this.f.cdi.value,
      numeroContratto: this.f.numeroContratto.value,
      idTipoCliente: this.f.tipoCliente.value.id,
      idTipoContratto: this.f.tipoContratto.value.id,
      maxPartiteIva: this.f.maxPartiteIva.value,
      numeroUtenze: this.f.numeroUtenze.value,
      utenzeAttive: this.clienteTemp == null ? 1 : this.clienteTemp.utenzeAttive,
      dataContratto: this.f.dataContratto.value,
      numeroPivaUtilizzate: this.clienteTemp == null ? numeroPivaUtilizzate : this.clienteTemp.numeroPivaUtilizzate,
      dataCreazione: this.clienteTemp == null ? date.getTime() : this.clienteTemp.dataCreazione,
      scadenzaContratto: this.clienteTemp == null ? null : this.clienteTemp.dataScadenza,
      annoCostituzione: this.f.annoCostituzione.value,
      isStartUp: this.f.startUp.value
    }

    /* chiamata http */
    this.clienteService.saveCliente(cliente).subscribe((response) => {
      /* console.log(cliente) */
      const modalRef = this.modalService.open(ModalcontentComponent);
      modalRef.componentInstance.modalPar = {
        name: "messaggiosalva",
        description: response.response,
        actionButtonText: "",
        undoButtonText: "Ok",
      }
      if (this.f.tipoCliente.value.id == 1) {
        this.salvaAnagraficaRichiedente();
      } else {
        this.onReset()
      }

      /* this.router.navigate(['/gestioneclienti']) */
    })
  }

  salvaAnagraficaRichiedente() {
    /* salvare i dati anche in anagraficarichiedente solo nel caso di azienda singola*/
    const date = new Date();
    let richiedente: Richiedente = {
      id: this.clienteTemp == null ? 0 : this.idRichiedenteTemp,
      partitaIva: this.f.partitaIva.value,
      ragioneSociale: this.f.nomeAzienda.value,
      idFormaGiuridica: this.f.tipoAzienda.value.id,
      sedeLegale: this.f.sedeLegale.value,
      idRegione: this.f.provincia.value.idRegione,
      idProvincia: this.f.provincia.value.id,
      idComune: this.f.comune.value.id,
      cap: this.f.cap.value,
      telefono: this.f.telefonoCliente.value,
      email: this.f.emailCliente.value,
      dataCreazione: date.getTime(),
      annoCostituzione: this.f.annoCostituzione.value,
      isStartUp: this.f.startUp.value
    }
    this.richiedenteService.salvaAnagraficaRichiedenteSingolo(richiedente).subscribe(() => {
      this.onReset()
    })


  }

  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }

}
