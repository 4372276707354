import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from './token-storage.service';
import { Cliente } from './../interface/cliente';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {



  private maxPivaTemp = new BehaviorSubject(0);
  maxPivaObs: Observable<number> = this.maxPivaTemp.asObservable();

  private numeroPivaUtilizzate = new BehaviorSubject(0);
  currentNumeroPivaUtilizzate: Observable<number> = this.numeroPivaUtilizzate.asObservable();



  constructor(
    private httpClient: HttpClient,
    private token: TokenStorageService,
  ) { }


  updateNumeroPivaUtilizzate(valore: number) {
    this.numeroPivaUtilizzate.next(valore)
  }

  maxPiva(valore: number) {
    return this.maxPivaTemp.next(valore)
  }


  /* numero piva usate dallo studio commercialistico */
  getNumeroPivaUtilizzate(): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getNumeroPivaUtilizzate', { idCliente: this.token.getUser().cliente.idCliente })
  }
  getMaxPiva(): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getMaxPivaUtilizzate', { idCliente: this.token.getUser().cliente.idCliente })
  }

  /* anagrafica cliente */
  GetAnagraficaClienteById(): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/GetAnagraficaClienteById', { idCliente: this.token.getUser().cliente.idCliente })
  }

  getNomeClienteById(): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getNomeClienteById',
      this.token.getUser().cliente.idCliente)
  }

  GetAlldatiClienteById(): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/GetAlldatiClienteById', { idCliente: this.token.getUser().cliente.idCliente })
  }

  getAnagraficaClienteByPiva(param: string): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getAnagraficaClienteByPiva', param)
  }

  saveCliente(cliente: Cliente): Observable<any> {
    return this.httpClient.post<Cliente>(environment.apiUrlQuestionario + '/fasGoAlgo/saveCliente', cliente)
  }

}
