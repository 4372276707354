import { Component, Input, OnInit } from '@angular/core';
import { DettaglioChartService } from '../services/dettaglio-chart.service';

@Component({
  selector: 'app-sintesi-graph-group',
  templateUrl: './sintesi-graph-group.component.html',
  styleUrls: ['./sintesi-graph-group.component.css']
})
export class SintesiGraphGroupComponent implements OnInit {

  @Input() valueOrdinataNegativo: number[] = [];
  @Input() valueOrdinataMedia: number[] = [];
  @Input() valueOrdinataPositivo: number[] = [];

  constructor(private dettaglioChartService: DettaglioChartService) { }

  ngOnInit(): void {
  }

  zoomOnClick() {
    this.dettaglioChartService.ShowDettaglioChart();
  }

}
