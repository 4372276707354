import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabella-splashpage',
  templateUrl: './tabella-splashpage.component.html',
  styleUrls: ['./tabella-splashpage.component.css']
})
export class TabellaSplashpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
