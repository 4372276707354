import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { LineaBusiness } from 'src/app/condivisi/linea-business';
import { LineaBusinessBp } from 'src/app/condivisi/linea-business-bp';
import { ProdottoLineaBusiness } from 'src/app/condivisi/ProdottoLineaBusiness';
import { ProdottoLineaBusinessBp } from 'src/app/condivisi/ProdottoLineaBusinessBp';
import { LineaBusinessBpCompleto } from 'src/app/interface/LineaBusinessBpCompleto';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { ToolTip } from 'src/app/interface/toolTip';
import { PraticheNuova } from './../../../interface/Pratiche-nuova';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Provincia } from 'src/app/condivisi/provincia';

@Component({
  selector: 'app-linea-business-bp',
  templateUrl: './linea-business-bp.component.html',
  styleUrls: ['./linea-business-bp.component.css']
})
export class LineaBusinessBpComponent implements OnInit, OnDestroy {
  @Input() businessPlanTemp: PraticheNuova;
  @Input() lineabusiness: LineaBusinessBp;
  @Input() ricaviEcostiForm: UntypedFormGroup;
  @Input() submitted: boolean;
  @Input() lineaBpSaved: boolean;
  @Input() lineaBusinessVolatile: LineaBusinessBpCompleto
  @Output() emitLineaFormValid = new EventEmitter<any>()


  risultatiVolatileSubscription: Subscription
  inizioDurataSubscription: Subscription
  listaEvoluzione: BeanStatico[];
  resetCountSub: Subscription
  anniBp: number
  arrayAnni: number[];
  lineaDisabled: boolean = false

  idCurrentUser: number;
  isRisultatiVolatile: boolean = false;
  count: number = 1;
  listaTips: ToolTip[] = [];
  listaCanaliDistribuzione: BeanStatico[];
  listaProvince: Provincia[];
  prodottiTempArray: ProdottoLineaBusinessBp[] = [];
  valoreMax: any = {};
  presenzaMagazzino = false;
  lineaBusinessFormValid: boolean = true
  warningLinea: boolean = false
  warningProdotti: boolean[] = []
  warningRicaviDriftAnnuale: boolean[] = []
  warningCostiDriftAnnuale: boolean[] = []
  warningRicaviDriftAnnualeTotale: boolean = false
  warningCostiDriftAnnualeTotale: boolean = false

  warningQuantitaInizialeDriftAnnuale: boolean[] = []
  warningCostoInizialeDriftAnnuale: boolean[] = []
  warningPrezzoInizialeDriftAnnuale: boolean[] = []
  warningQuantitaInizialeDriftAnnualeTot: boolean = false
  warningCostoInizialeDriftAnnualeTot: boolean = false
  warningPrezzoInizialeDriftAnnualeTot: boolean = false
  tipsSubscription: Subscription
  warningRicaviRibasso: boolean = false;
  warningRicaviRialzo: boolean = false;
  warningRicaviScarto: boolean = false;
  warningRicaviDrift: boolean = false;
  warningCostiRibasso: boolean = false;
  warningCostiRialzo: boolean = false;
  warningCostiScarto: boolean = false;
  warningCostiDrift: boolean = false;
  warningQuantitaVendutaRialzo: boolean = false
  warningQuantitaVendutaRibasso: boolean = false
  warningQuantitaVendutaScarto: boolean = false
  warningQuantitaVendutaDrift: boolean = false
  warningPrezzoInizialeRialzo: boolean = false
  warningPrezzoInizialeRibasso: boolean = false
  warningPrezzoInizialeScarto: boolean = false
  warningPrezzoInizialeDrift: boolean = false
  warningCostoInizialeRialzo: boolean = false
  warningCostoInizialeRibasso: boolean = false
  warningCostoInizialeScarto: boolean = false
  warningCostoInizialeDrift: boolean = false
  annoIniziale = new Date().getFullYear();
  sommaCentoVenduto = true;
  sommaCentoMagaz = true;
  idRuolo: number;
  ricaviTot: number;
  costiTot: number;
  margineTot: number;
  trimestreList: any[] = [{ id: 1, descrizione: "1° trimestre" }, { id: 2, descrizione: "2° trimestre" }, { id: 3, descrizione: "3° trimestre" }, { id: 4, descrizione: "4° trimestre" }]

  //initialValues: any;

  listaValorizzazione: BeanStatico[] = [];
  listaValorizzazioneFiltered: BeanStatico[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
    private tokenStorageService: TokenStorageService,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private configurazioneBusinessPlanService: ConfigurazioneBusinessPlanService,
    private toolTipService: ToolTipService,
    private modalService: NgbModal,
    private httpClient: HttpClient) {


  }

  ngOnInit(): void {

    this.risultatiVolatileSubscription = this.configurazioneBusinessPlanService.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isRisultatiVolatile = isVolatile
    })
    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })
    this.idRuolo = this.tokenStorageService.getUser().roles.id;

    this.inizioDurataSubscription = this.configurazioneBusinessPlanService.currentInizioDurata.subscribe(element => {
      if (element != null) {
        this.anniBp = element.anniSviluppoBp
        this.annoIniziale = element.annoIniziale
        this.arrayAnni = [];
        if (this.anniBp < 11) {
          for (let i = 0; i < this.anniBp; i++) {
            this.arrayAnni.push(this.annoIniziale + i);
          }
        }
      }
    })
    this.resetCountSub = this.configurazioneBusinessPlanService._ResetCountRicaviCosti$.subscribe(
      resetCount => {
        this.count = resetCount;
      })
    this.configurazioneAmbienteService.getValoriMax('linee business').subscribe(response => {
      response.forEach(element => {
        if (element.error == null) {
          this.valoreMax[element.nomeCampo] = element.valoreMax;
        }
      });
    });



    this.ricaviEcostiForm.controls = this.formBuilder.group({
      valorizzazione: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
      evoluzioneComplessiva: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
      oscillazioniComplessive: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
      ricavi: this.formBuilder.group({
        ricaviIniziali: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        ribasso: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        rialzo: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        scarto: [{ value: 1, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        drift: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        driftRicaviAnnuale: this.formBuilder.array([]),
      }),
      costi: this.formBuilder.group({
        costiMaterieIniziali: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        costiServiziIniziali: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        ribasso: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        rialzo: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        scarto: [{ value: 1, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        drift: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        driftCostiAnnuale: this.formBuilder.array([]),
      }),

      evoluzioneUnitaria: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }], //Validators.required
      oscillazioniLinea: [{ value: '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
      quantitaVenduta: this.formBuilder.group({
        ribasso: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        rialzo: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        scarto: [{ value: 1, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        drift: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],

      }),
      driftQuantitaInizialeAnnuale: this.formBuilder.array([]),
      prezzoIniziale: this.formBuilder.group({
        ribasso: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        rialzo: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        scarto: [{ value: 1, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        drift: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
      }),
      driftPrezzoInizialeAnnuale: this.formBuilder.array([]),
      costoIniziale: this.formBuilder.group({
        ribasso: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        rialzo: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        scarto: [{ value: 1, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
        drift: [{ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }],
      }),
      driftCostoInizialeAnnuale: this.formBuilder.array([]),
      prodotti: this.formBuilder.array([]),
      percTrimVenduto: this.formBuilder.array([]),
      percTrimMagaz: this.formBuilder.array([]),
      costiFissi: [{ value: '', disabled: this.businessPlanTemp != null && ((this.businessPlanTemp.idContoEconomico != 0 && this.lineabusiness.lineaEsistente) || (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)) }],
      totCosti: [{ value: '', disabled: true }],
      annoInizio: [{ value: 0, disabled: this.idRuolo > 2 || (this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)) }],
      trimestreInizio: [{ value: 0, disabled: this.idRuolo > 2 || (this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4)) }],
      latenza: this.formBuilder.array([]),
    }).controls;


    this.idCurrentUser = this.tokenStorageService.getUser().idUtente;

    if (this.lineabusiness.idTipologiaAtt && this.lineabusiness.idTipologiaAtt == 1) {
      this.configurazioneBusinessPlanService.getProvince().subscribe(response => {
        this.listaProvince = response;
      });

      this.configurazioneBusinessPlanService.getCanaliDistribuzione().subscribe(response => {
        this.listaCanaliDistribuzione = response;
      });

    }

    this.configurazioneBusinessPlanService.getRicaviCosti().subscribe(listaVoci => {
      this.listaValorizzazione = listaVoci;
      this.getLineaBp();
    })

  }

  get f() { return this.ricaviEcostiForm.controls; }

  get costi(): UntypedFormGroup { return <UntypedFormGroup>this.f.costi; }
  get ricavi(): UntypedFormGroup { return <UntypedFormGroup>this.f.ricavi; }
  get driftRicaviAnnualeArray(): UntypedFormArray { return <UntypedFormArray>this.ricavi.controls.driftRicaviAnnuale; }
  get driftRicaviAnnualeFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.driftRicaviAnnualeArray.controls; }
  get driftCostiAnnualeArray(): UntypedFormArray { return <UntypedFormArray>this.costi.controls.driftCostiAnnuale; }
  get driftCostiAnnualeFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.driftCostiAnnualeArray.controls; }
  get quantitaVenduta(): UntypedFormGroup { return <UntypedFormGroup>this.f.quantitaVenduta; }
  get prezzoIniziale(): UntypedFormGroup { return <UntypedFormGroup>this.f.prezzoIniziale; }
  get costoIniziale(): UntypedFormGroup { return <UntypedFormGroup>this.f.costoIniziale; }
  get prodottiArray(): UntypedFormArray { return <UntypedFormArray>this.f.prodotti; }
  get driftQuantitaInizialeAnnualeArray(): UntypedFormArray { return <UntypedFormArray>this.f.driftQuantitaInizialeAnnuale; }
  get driftPrezzoInizialeAnnualeArray(): UntypedFormArray { return <UntypedFormArray>this.f.driftPrezzoInizialeAnnuale; }
  get driftCostoInizialeAnnualeArray(): UntypedFormArray { return <UntypedFormArray>this.f.driftCostoInizialeAnnuale; }
  get driftQuantitaInizialeAnnualeFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.driftQuantitaInizialeAnnualeArray.controls; }
  get driftPrezzoInizialeAnnualeFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.driftPrezzoInizialeAnnualeArray.controls; }
  get driftCostoInizialeAnnualeFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.driftCostoInizialeAnnualeArray.controls; }
  get prodottiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.prodottiArray.controls; };
  get percTrimVendutoArray(): UntypedFormArray { return <UntypedFormArray>this.f.percTrimVenduto; }
  get percTrimVendutoFormArray(): UntypedFormControl[] { return <UntypedFormControl[]>this.percTrimVendutoArray.controls; };
  get percTrimMagazArray(): UntypedFormArray { return <UntypedFormArray>this.f.percTrimMagaz; }
  get percTrimMagazFormArray(): UntypedFormControl[] { return <UntypedFormControl[]>this.percTrimMagazArray.controls; };
  get latenzaArray(): UntypedFormArray { return <UntypedFormArray> this.f.latenza; }
  get latenzaFormArray(): UntypedFormControl[] { return <UntypedFormControl[]>this.latenzaArray.controls; };

  ngAfterViewChecked() {
    if (document.querySelectorAll('#lineeDiBusiness [tabindex="-1"][aria-posinset="1"]')[0] != undefined && this.count == 0) {
      const btn = document.querySelectorAll('#lineeDiBusiness [tabindex="-1"][aria-posinset="1"]')[0] as HTMLElement;
      btn?.addEventListener('click', () => { });
      btn?.click();
      const btn2 = document.querySelectorAll('#lineeDiBusiness [tabindex="-1"][aria-posinset="2"]')[0] as HTMLElement;
      btn2?.addEventListener('click', () => { });
      btn2?.click();
      this.count++;
    };
  }

  getLineaBp() {
    this.prodottiTempArray = [];
    this.f.valorizzazione.setValue('')
    this.f.valorizzazione.updateValueAndValidity();
    /* prima verifico se volatile, poi carico i prodotti, poi carico la select iniziale */
    if (this.isRisultatiVolatile) {
      if (this.lineaBusinessVolatile != undefined) {
        this.percTrimMagazArray.clear();
        this.percTrimVendutoArray.clear();
        this.latenzaArray.clear();

        this.f.costiFissi.setValue(this.lineaBusinessVolatile.linea.costiFissiAzienda * this.lineaBusinessVolatile.linea.percCostiFissi / 100.);

        for (let i = 0; i < this.lineaBusinessVolatile.linea.percTrimVenduto.length; i++) {
          this.percTrimVendutoArray.push(new UntypedFormControl({ value: this.lineaBusinessVolatile.linea.percTrimVenduto[i], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required))
          this.percTrimMagazArray.push(new UntypedFormControl({ value: this.lineaBusinessVolatile.linea.percTrimMagaz[i], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required))

        }

        if(!this.lineaBusinessVolatile.linea.lineaEsistente){
          this.f.annoInizio.setValue(this.lineaBusinessVolatile.linea.annoInizio);
          this.f.trimestreInizio.setValue(this.lineaBusinessVolatile.linea.trimestreInizio);
        for (let i = 0; i < this.lineaBusinessVolatile.linea.latenza.length; i++) {
          this.latenzaArray.push(new UntypedFormControl({ value: this.lineaBusinessVolatile.linea.latenza[i], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }));

        }
      }
        this.lineaDisabled = this.lineaBusinessVolatile.linea.disabilitata;
        this.prodottiTempArray = this.lineaBusinessVolatile.prodotti;

        if (this.prodottiTempArray.length == 0) {
          this.listaValorizzazioneFiltered = [];
          for (let i = 0; i < this.listaValorizzazione.length; i++) {
            if (this.listaValorizzazione[i].id != 2) {
              this.listaValorizzazioneFiltered.push(this.listaValorizzazione[i]);
              break;
            }
          }
          this.f.valorizzazione.setValue(this.listaValorizzazioneFiltered[0]);
          this.f.valorizzazione.disable();
        } else {
          this.listaValorizzazioneFiltered = this.listaValorizzazione;
          this.listaValorizzazioneFiltered.forEach(element => {
            if (element.id == this.lineaBusinessVolatile.linea.idRicaviCosti) {
              this.f.valorizzazione.setValue(element);
            }

          })
        }

        this.presenzaMagazzino = this.lineaBusinessVolatile.linea.presenzaMagazzino;

        if (!this.lineaDisabled) this.f.valorizzazione.setValidators(Validators.required);
        this.f.valorizzazione.updateValueAndValidity();

        this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/parametriGenerali/getModalitaEvoluzione').subscribe(response => {
          this.listaEvoluzione = response;
          this.onChangeValorizzazione();

        })
      }
    } else {
      this.percTrimMagazArray.clear();
      this.percTrimVendutoArray.clear();
      this.latenzaArray.clear();
      this.f.costiFissi.setValue(this.lineabusiness.costiFissiAzienda * this.lineabusiness.percCostiFissi / 100.);
      for (let i = 0; i < this.lineabusiness.percTrimVenduto.length; i++) {
        this.percTrimVendutoArray.push(new UntypedFormControl({ value: this.lineabusiness.percTrimVenduto[i], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required))
        this.percTrimMagazArray.push(new UntypedFormControl({ value: this.lineabusiness.percTrimMagaz[i], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required))

      }

      if(!this.lineabusiness.lineaEsistente){
        this.f.annoInizio.setValue(this.lineabusiness.annoInizio);
        this.f.trimestreInizio.setValue(this.lineabusiness.trimestreInizio);
        for (let i = 0; i < this.lineabusiness.latenza.length; i++) {
          this.latenzaArray.push(new UntypedFormControl({ value: this.lineabusiness.latenza[i], disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }));

        }
      }
      this.presenzaMagazzino = this.lineabusiness.presenzaMagazzino;
      this.configurazioneBusinessPlanService.getProdotti(this.lineabusiness.id, this.businessPlanTemp.id, this.lineabusiness.idTipologiaAtt).subscribe(response => {
        this.prodottiTempArray = response;
        if (this.prodottiTempArray.length == 0) {
          this.listaValorizzazioneFiltered = [];
          for (let i = 0; i < this.listaValorizzazione.length; i++) {
            if (this.listaValorizzazione[i].id != 2) {
              this.listaValorizzazioneFiltered.push(this.listaValorizzazione[i]);
              break;
            }
          }
          this.f.valorizzazione.setValue(this.listaValorizzazioneFiltered[0]);
          this.f.valorizzazione.disable();
        } else {
          this.listaValorizzazioneFiltered = this.listaValorizzazione;
          this.listaValorizzazioneFiltered.forEach(element => {
            if (element.id == this.lineabusiness.idRicaviCosti) {
              this.f.valorizzazione.setValue(element);
            }

          })
        }
        this.lineaDisabled = this.lineabusiness.disabilitata;

        if (!this.lineaDisabled) this.f.valorizzazione.setValidators(Validators.required);
        this.f.valorizzazione.updateValueAndValidity();

        this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/parametriGenerali/getModalitaEvoluzione').subscribe(response => {
          this.listaEvoluzione = response;
          this.onChangeValorizzazione();

        })
      })
    }



  }

  getRicaviComplessiviLb() {
    if (this.presenzaMagazzino) {
      let ricaviInizialiLinea: any
      if (this.f.valorizzazione.value && this.f.valorizzazione.value.id == 1) {
        this.ricavi.controls.ricaviIniziali.updateValueAndValidity()
        if (this.ricavi.controls.ricaviIniziali.value != '') {
          ricaviInizialiLinea = {
            ricavi: parseFloat(this.ricavi.controls.ricaviIniziali.value),
            nomeLinea: this.lineabusiness.nome,
          }
        } else {
          ricaviInizialiLinea = {
            ricavi: null,
            nomeLinea: this.lineabusiness.nome,
          }
        }
      } else if (this.f.valorizzazione.value && this.f.valorizzazione.value.id == 2) {
        let prezzoInizialePesato = 0
        let totaleProdotti = 0
        for (let i = 0; i < this.prodottiFormArray.length; i++) {
          this.prodottiFormArray[i].controls.prezzoInizialeProdotto.updateValueAndValidity()
          this.prodottiFormArray[i].controls.quantitaVendutaProdotto.updateValueAndValidity()
          if (this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value != '' && this.prodottiFormArray[i].controls.quantitaVendutaProdotto.value != '') {
            prezzoInizialePesato += parseFloat(this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value) * parseFloat(this.prodottiFormArray[i].controls.quantitaVendutaProdotto.value)
            totaleProdotti += this.prodottiFormArray[i].controls.quantitaVendutaProdotto.value
          }
          else {
            prezzoInizialePesato = null
            totaleProdotti = null
          }
        }
        ricaviInizialiLinea = {
          ricavi: prezzoInizialePesato && totaleProdotti ? prezzoInizialePesato / totaleProdotti : null,
          nomeLinea: this.lineabusiness.nome,
        }
      }
      return ricaviInizialiLinea
    }
  }

  onChangeValorizzazione() {

    //popolo e tengo popolati evoluzione e oscillazioni in ogni caso, perchè il master non può modificarli  
    if (this.isRisultatiVolatile) {
      this.listaEvoluzione.forEach(element => {
        if (element.id == this.lineaBusinessVolatile.linea.idEvoluzioneComplessivo) {
          this.f.evoluzioneComplessiva.setValue(element);
        }
        if (element.id == this.lineaBusinessVolatile.linea.idEvoluzioneUnitario) {
          this.f.evoluzioneUnitaria.setValue(element);
        }
      })
      this.f.oscillazioniComplessive.setValue(this.lineaBusinessVolatile.linea.oscillazioniComplessive);
      this.f.oscillazioniLinea.setValue(this.lineaBusinessVolatile.linea.oscillazioniUnitarie);

    } else {
      this.listaEvoluzione.forEach(element => {
        if (element.id == this.lineabusiness.idEvoluzioneComplessivo) {
          this.f.evoluzioneComplessiva.setValue(element);
        }
        if (element.id == this.lineabusiness.idEvoluzioneUnitario) {
          this.f.evoluzioneUnitaria.setValue(element);
        }
      })
      this.f.oscillazioniComplessive.setValue(this.lineabusiness.oscillazioniComplessive ? this.lineabusiness.oscillazioniComplessive : false);
      this.f.oscillazioniLinea.setValue(this.lineabusiness.oscillazioniUnitarie);

    }
    if (this.f.valorizzazione.value) {
      if(!this.lineabusiness.lineaEsistente){
        this.f.annoInizio.setValidators(Validators.required);
        this.f.trimestreInizio.setValidators(Validators.required);
        for(let i= 0; i< this.latenzaFormArray.length;i++){
          this.latenzaFormArray[i].setValidators(Validators.required);
        }
        this.f.annoInizio.updateValueAndValidity();
        this.f.trimestreInizio.updateValueAndValidity();
        this.latenzaArray.updateValueAndValidity();
      }
      if (this.f.valorizzazione.value.id == 1) {
        this.disabilitaWarningProdotti();

        /* chiamata da linee_businessplan_bp se la risp esiste la carico altrimenti lascio il vampo vuoto */
        if (this.isRisultatiVolatile) {
          this.ricavi.controls.drift.setValue(this.lineaBusinessVolatile.linea.ricaviComplessivo.drift);
          this.costi.controls.drift.setValue(this.lineaBusinessVolatile.linea.costiComplessivo.drift);
          this.ricavi.controls.ricaviIniziali.setValue(this.lineaBusinessVolatile.linea.ricaviComplessivo.valoreIniziale);
          this.costi.controls.costiMaterieIniziali.setValue(this.lineaBusinessVolatile.linea.costiComplessivo.valoreIniziale);
          this.costi.controls.costiServiziIniziali.setValue(this.lineaBusinessVolatile.linea.costiComplessivo.valoreInizialeAlt);
          this.f.totCosti.setValue(this.lineaBusinessVolatile.linea.costiComplessivo.valoreIniziale + this.lineaBusinessVolatile.linea.costiComplessivo.valoreInizialeAlt + this.f.costiFissi.value);

        } else {
          this.ricavi.controls.drift.setValue(this.lineabusiness.ricaviComplessivo != null ? this.lineabusiness.ricaviComplessivo.drift : 0);
          this.costi.controls.drift.setValue(this.lineabusiness.costiComplessivo != null ? this.lineabusiness.costiComplessivo.drift : 0);
          this.ricavi.controls.ricaviIniziali.setValue(this.lineabusiness.ricaviComplessivo != null ? this.lineabusiness.ricaviComplessivo.valoreIniziale : 0,);
          this.costi.controls.costiMaterieIniziali.setValue(this.lineabusiness.costiComplessivo != null ? this.lineabusiness.costiComplessivo.valoreIniziale : 0);
          this.costi.controls.costiServiziIniziali.setValue(this.lineabusiness.costiComplessivo != null ? this.lineabusiness.costiComplessivo.valoreInizialeAlt : 0);
          this.f.totCosti.setValue(this.lineabusiness.costiComplessivo != null ? this.lineabusiness.costiComplessivo.valoreIniziale + this.lineabusiness.costiComplessivo.valoreInizialeAlt + this.f.costiFissi.value : this.f.costiFissi.value);

        }
        this.ricavi.controls.ricaviIniziali.setValidators(Validators.required);
        this.costi.controls.costiMaterieIniziali.setValidators([Validators.required]);
        this.costi.controls.costiServiziIniziali.setValidators([Validators.required]);
        this.f.oscillazioniComplessive.setValidators(Validators.required)

        this.f.evoluzioneUnitaria.clearValidators()
        this.driftQuantitaInizialeAnnualeArray.clear();
        this.driftCostoInizialeAnnualeArray.clear();
        this.driftPrezzoInizialeAnnualeArray.clear();
        this.quantitaVenduta.controls.ribasso.clearValidators();
        this.quantitaVenduta.controls.rialzo.clearValidators();
        this.quantitaVenduta.controls.scarto.clearValidators();
        this.quantitaVenduta.controls.drift.clearValidators();

        this.prezzoIniziale.controls.ribasso.clearValidators();
        this.prezzoIniziale.controls.rialzo.clearValidators();
        this.prezzoIniziale.controls.scarto.clearValidators();
        this.prezzoIniziale.controls.drift.clearValidators();

        this.costoIniziale.controls.ribasso.clearValidators();
        this.costoIniziale.controls.rialzo.clearValidators();
        this.costoIniziale.controls.scarto.clearValidators();
        this.costoIniziale.controls.drift.clearValidators();
        /* eliminare i validatori dell'array prosdotti */
        this.f.oscillazioniComplessive.updateValueAndValidity();
        this.prodottiArray.clear();
        this.onChangeEvoluzioneComplessiva()
        this.onChangeOscillazioniComplessive();

      } else if (this.f.valorizzazione.value.id == 2) {
        this.disabilitaWarningLinea()
        this.f.oscillazioniComplessive.clearValidators();
        this.f.evoluzioneComplessiva.clearValidators()
        this.ricavi.controls.ricaviIniziali.clearValidators();
        this.ricavi.controls.drift.clearValidators();
        this.driftRicaviAnnualeArray.clear();
        this.driftCostiAnnualeArray.clear();
        this.costi.controls.costiMaterieIniziali.clearValidators();
        this.costi.controls.costiServiziIniziali.clearValidators();
        this.costi.controls.drift.clearValidators();


        /* chiamata e setvalue */
        if (this.isRisultatiVolatile) {
          this.quantitaVenduta.controls.drift.setValue(this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.drift,);
          this.prezzoIniziale.controls.drift.setValue(this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.drift);
          this.costoIniziale.controls.drift.setValue(this.lineaBusinessVolatile.linea.costoUnitarioIniziale.drift);
          this.f.totCosti.setValue(this.f.costiFissi.value);

        } else {
          this.quantitaVenduta.controls.drift.setValue(this.lineabusiness.quantitaUnitarioVenduta.drift);
          this.prezzoIniziale.controls.drift.setValue(this.lineabusiness.prezzoUnitarioIniziale.drift);
          this.costoIniziale.controls.drift.setValue(this.lineabusiness.costoUnitarioIniziale.drift);
          this.f.totCosti.setValue(this.f.costiFissi.value);

        }
        this.f.oscillazioniLinea.updateValueAndValidity();
        this.onLoadProdotti();
        this.onChangeOscillazionilinea();
        this.onChangeEvoluzioneUnitaria()
      }
      //this.f.oscillazioniComplessive.updateValueAndValidity();
      this.f.evoluzioneComplessiva.updateValueAndValidity()
      this.f.evoluzioneUnitaria.updateValueAndValidity()
      this.quantitaVenduta.controls.drift.updateValueAndValidity();
      this.prezzoIniziale.controls.drift.updateValueAndValidity();
      this.costoIniziale.controls.drift.updateValueAndValidity();
      this.f.oscillazioniComplessive.updateValueAndValidity()
      this.f.oscillazioniLinea.updateValueAndValidity();
      this.ricavi.controls.ricaviIniziali.updateValueAndValidity();
      this.ricavi.controls.drift.updateValueAndValidity();
      this.ricavi.controls.ribasso.updateValueAndValidity();
      this.ricavi.controls.rialzo.updateValueAndValidity();
      this.ricavi.controls.scarto.updateValueAndValidity();
      this.costi.controls.costiMaterieIniziali.updateValueAndValidity();
      this.costi.controls.costiServiziIniziali.updateValueAndValidity();
      this.costi.controls.drift.updateValueAndValidity();
      this.costi.controls.ribasso.updateValueAndValidity();
      this.costi.controls.rialzo.updateValueAndValidity();
      this.costi.controls.scarto.updateValueAndValidity();
      this.quantitaVenduta.controls.ribasso.updateValueAndValidity();
      this.quantitaVenduta.controls.rialzo.updateValueAndValidity();
      this.quantitaVenduta.controls.scarto.updateValueAndValidity();
      this.quantitaVenduta.controls.drift.updateValueAndValidity();
      this.prezzoIniziale.controls.ribasso.updateValueAndValidity();
      this.prezzoIniziale.controls.rialzo.updateValueAndValidity();
      this.prezzoIniziale.controls.scarto.updateValueAndValidity();
      this.prezzoIniziale.controls.drift.updateValueAndValidity();
      this.costoIniziale.controls.ribasso.updateValueAndValidity();
      this.costoIniziale.controls.rialzo.updateValueAndValidity();
      this.costoIniziale.controls.scarto.updateValueAndValidity();
      this.costoIniziale.controls.drift.updateValueAndValidity();
    }


  }
  onChangeEvoluzioneComplessiva() {
    this.driftRicaviAnnualeArray.clear();
    this.driftCostiAnnualeArray.clear();
    if (this.f.evoluzioneComplessiva.value && this.f.evoluzioneComplessiva.value.id == 2) {

      this.ricavi.controls.drift.setValidators(Validators.required);
      this.costi.controls.drift.setValidators(Validators.required);

    } else if (this.f.evoluzioneComplessiva.value && this.f.evoluzioneComplessiva.value.id == 1) {
      this.costi.controls.drift.clearValidators()
      this.ricavi.controls.drift.clearValidators()
      if (this.anniBp < 11) {
        this.warningRicaviDriftAnnuale = []
        this.warningCostiDriftAnnuale = []

        for (let i = 0; i < this.anniBp; i++) {
          this.warningRicaviDriftAnnuale.push(false)
          this.warningCostiDriftAnnuale.push(false)
          const annoFormRicavi = new UntypedFormControl({ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required)
          const annoFormCosti = new UntypedFormControl({ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required)

          if (this.isRisultatiVolatile) {
            annoFormRicavi.setValue(this.lineaBusinessVolatile.linea.ricaviComplessivoDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.ricaviComplessivoDriftAnnuale[i] : 0)
            annoFormCosti.setValue(this.lineaBusinessVolatile.linea.costiComplessivoDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.costiComplessivoDriftAnnuale[i] : 0)
          } else {
            annoFormRicavi.setValue(this.lineabusiness.ricaviComplessivoDriftAnnuale.length > 0 ? this.lineabusiness.ricaviComplessivoDriftAnnuale[i] : 0)
            annoFormCosti.setValue(this.lineabusiness.costiComplessivoDriftAnnuale.length > 0 ? this.lineabusiness.costiComplessivoDriftAnnuale[i] : 0)
          }
          annoFormCosti.updateValueAndValidity();
          annoFormRicavi.updateValueAndValidity()
          this.driftRicaviAnnualeArray.push(annoFormRicavi)
          this.driftCostiAnnualeArray.push(annoFormCosti);

        }
      }
      //console.log(this.arrayAnni);
    }
    this.driftRicaviAnnualeArray.updateValueAndValidity();
    this.driftCostiAnnualeArray.updateValueAndValidity();
    this.costi.controls.drift.updateValueAndValidity()
    this.ricavi.controls.drift.updateValueAndValidity()
  }
  onChangeEvoluzioneUnitaria() {
    this.driftQuantitaInizialeAnnualeArray.clear();
    this.driftCostoInizialeAnnualeArray.clear();
    this.driftPrezzoInizialeAnnualeArray.clear();
    if (this.f.evoluzioneUnitaria.value && this.f.evoluzioneUnitaria.value.id == 2) {
      this.quantitaVenduta.controls.drift.setValidators(Validators.required);
      this.prezzoIniziale.controls.drift.setValidators(Validators.required);
      this.costoIniziale.controls.drift.setValidators(Validators.required);


    } else if (this.f.evoluzioneUnitaria.value && this.f.evoluzioneUnitaria.value.id == 1) {
      this.quantitaVenduta.controls.drift.clearValidators();
      this.prezzoIniziale.controls.drift.clearValidators();
      this.costoIniziale.controls.drift.clearValidators();

      if (this.anniBp < 11) {
        this.warningQuantitaInizialeDriftAnnuale = []
        this.warningCostoInizialeDriftAnnuale = []
        this.warningPrezzoInizialeDriftAnnuale = []

        for (let i = 0; i < this.anniBp; i++) {
          this.warningQuantitaInizialeDriftAnnuale.push(false)
          this.warningCostoInizialeDriftAnnuale.push(false)
          this.warningPrezzoInizialeDriftAnnuale.push(false)

          const annoFormDriftCostoUnitario = new UntypedFormControl({ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required)
          const annoFormDriftPrezzoUnitario = new UntypedFormControl({ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required)
          const annoFormDriftQuantitaUnitario = new UntypedFormControl({ value: 0, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required)


          if (this.isRisultatiVolatile) {
            annoFormDriftQuantitaUnitario.setValue(this.lineaBusinessVolatile.linea.quantitaUnitarioDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.quantitaUnitarioDriftAnnuale[i] : 0)
            annoFormDriftPrezzoUnitario.setValue(this.lineaBusinessVolatile.linea.prezzoUnitarioDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.prezzoUnitarioDriftAnnuale[i] : 0)
            annoFormDriftCostoUnitario.setValue(this.lineaBusinessVolatile.linea.costoUnitarioDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.costoUnitarioDriftAnnuale[i] : 0)
          } else {
            annoFormDriftQuantitaUnitario.setValue(this.lineabusiness.quantitaUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.quantitaUnitarioDriftAnnuale[i] : 0)
            annoFormDriftPrezzoUnitario.setValue(this.lineabusiness.prezzoUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.prezzoUnitarioDriftAnnuale[i] : 0)
            annoFormDriftCostoUnitario.setValue(this.lineabusiness.costoUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.costoUnitarioDriftAnnuale[i] : 0)
          }
          annoFormDriftQuantitaUnitario.updateValueAndValidity()
          annoFormDriftPrezzoUnitario.updateValueAndValidity();
          annoFormDriftCostoUnitario.updateValueAndValidity()

          this.driftQuantitaInizialeAnnualeArray.push(annoFormDriftQuantitaUnitario)
          this.driftCostoInizialeAnnualeArray.push(annoFormDriftCostoUnitario)
          this.driftPrezzoInizialeAnnualeArray.push(annoFormDriftPrezzoUnitario);

        }
      }
    }
    this.driftQuantitaInizialeAnnualeArray.updateValueAndValidity();
    this.driftCostoInizialeAnnualeArray.updateValueAndValidity();
    this.driftPrezzoInizialeAnnualeArray.updateValueAndValidity();

    this.quantitaVenduta.controls.drift.updateValueAndValidity();
    this.prezzoIniziale.controls.drift.updateValueAndValidity();
    this.costoIniziale.controls.drift.updateValueAndValidity();
  }
  onLoadProdotti() {
    this.warningProdotti = []
    this.prodottiArray.clear();
    this.costiTot = 0;
    this.ricaviTot = 0;

    //this.prodottiTempArray = []; eliminare
    for (let i = 0; i < this.prodottiTempArray.length; i++) {
      this.warningProdotti.push(false)
      // this.prodottiTempArray.push(this.lineaBusinessVolatile.prodotti[i]);
      if (this.lineabusiness.idTipologiaAtt && this.lineabusiness.idTipologiaAtt == 1) {
        const prodottoForm = this.formBuilder.group({
          provincia: { value: this.prodottiTempArray[i].idProvincia, disabled: true },
          ettari: { value: this.prodottiTempArray[i].ettari, disabled: true },
          quantitaVendutaProdotto: [{ value: parseInt(this.prodottiTempArray[i].quantitaIniz.toFixed(0)), disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
          prezzoInizialeProdotto: [{ value: this.prodottiTempArray[i].prezzoIniz, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
          costoMaterieProdotto: [{ value: this.prodottiTempArray[i].costoMaterieIniz, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
          canaleDistribuzione: [{ value: this.prodottiTempArray[i].idCanaleDistribuzione > 0 ? this.prodottiTempArray[i].idCanaleDistribuzione : '', disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
        })
        prodottoForm.controls.canaleDistribuzione.updateValueAndValidity();
        prodottoForm.controls.quantitaVendutaProdotto.updateValueAndValidity();
        prodottoForm.controls.prezzoInizialeProdotto.updateValueAndValidity();
        prodottoForm.controls.costoMaterieProdotto.updateValueAndValidity();
        prodottoForm.controls.costoServiziProdotto.updateValueAndValidity();

        this.prodottiFormArray.push(prodottoForm)
      } else {
        const prodottoForm = this.formBuilder.group({
          nomeProdotto: [{ value: this.prodottiTempArray[i].descrizione, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
          quantitaVendutaProdotto: [{ value: this.prodottiTempArray[i].quantitaIniz, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
          prezzoInizialeProdotto: [{ value: this.prodottiTempArray[i].prezzoIniz, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
          costoMaterieProdotto: [{ value: this.prodottiTempArray[i].costoMaterieIniz, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
          costoServiziProdotto: [{ value: this.prodottiTempArray[i].costoServiziIniz, disabled: this.businessPlanTemp != null && (this.businessPlanTemp.idStato == 3 || this.businessPlanTemp.idStato == 4) }, Validators.required],
        })
        this.costiTot += this.prodottiTempArray[i].costoMaterieIniz + this.prodottiTempArray[i].costoServiziIniz;
        this.ricaviTot += this.prodottiTempArray[i].prezzoIniz;

        prodottoForm.controls.nomeProdotto.updateValueAndValidity();
        prodottoForm.controls.quantitaVendutaProdotto.updateValueAndValidity();
        prodottoForm.controls.prezzoInizialeProdotto.updateValueAndValidity();
        prodottoForm.controls.costoMaterieProdotto.updateValueAndValidity();
        prodottoForm.controls.costoServiziProdotto.updateValueAndValidity();

        this.prodottiFormArray.push(prodottoForm)
      }

      this.prodottiArray.updateValueAndValidity();
    }
    if (!(this.lineabusiness.idTipologiaAtt && this.lineabusiness.idTipologiaAtt == 1)) {
      this.costiTot += this.f.costiFissi.value;
      this.margineTot = this.costiTot > 0 ? 100. * (this.ricaviTot - this.costiTot) / this.ricaviTot : null;

    }
  }
  checkMaggiore() {
    if (this.ricavi.controls.ricaviIniziali.value != '' && this.costi.controls.costiMaterieIniziali.value != '' && this.costi.controls.costiServiziIniziali.value != '' && this.ricavi.controls.ricaviIniziali.value < this.costi.controls.costiMaterieIniziali.value + this.costi.controls.costiServiziIniziali.value + this.f.costiFissi.value) {
      this.warningLinea = true;
    } else {
      this.warningLinea = false
    }

  }

  onChangeCostiComplessivo() {
    this.f.totCosti.setValue(this.costi.controls.costiServiziIniziali.value + this.costi.controls.costiMaterieIniziali.value + this.f.costiFissi.value);
  }

  checkMaggioreProdotti(i: number) {
    let costiProdotto = this.prodottiFormArray[i].controls.costoMaterieProdotto.value ? this.prodottiFormArray[i].controls.costoMaterieProdotto.value : 0;
    costiProdotto += this.prodottiFormArray[i].controls.costoServiziProdotto.value ? this.prodottiFormArray[i].controls.costoServiziProdotto.value : 0;
    this.warningProdotti.splice(i, 1, (this.prodottiFormArray[i].controls.costoMaterieProdotto.value || this.prodottiFormArray[i].controls.costoServiziProdotto.value) && this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value && this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value < costiProdotto);
  }

  onChangeCostoProdotti() {
    this.costiTot = 0;
    for (let i = 0; i < this.prodottiFormArray.length; i++) {
      this.costiTot += this.prodottiFormArray[i].controls.costoMaterieProdotto.value ? this.prodottiFormArray[i].controls.costoMaterieProdotto.value : 0;
      this.costiTot += this.prodottiFormArray[i].controls.costoServiziProdotto.value ? this.prodottiFormArray[i].controls.costoServiziProdotto.value : 0;
    }
    this.costiTot += this.f.costiFissi.value;
    this.margineTot = this.costiTot > 0 ? 100. * (this.ricaviTot - this.costiTot) / this.ricaviTot : null;
  }

  onChangeRicaviProdotti() {
    if (!(this.lineabusiness.idTipologiaAtt && this.lineabusiness.idTipologiaAtt == 1)) {
      this.ricaviTot = 0;
      for (let i = 0; i < this.prodottiFormArray.length; i++) {
        this.ricaviTot += this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value;
      }
      this.margineTot = this.costiTot > 0 ? 100. * (this.ricaviTot - this.costiTot) / this.ricaviTot : null;
    }
  }

  onChangeOscillazioniComplessive() {
    this.f.oscillazioniComplessive.updateValueAndValidity();
    if (this.f.oscillazioniComplessive.value) {
      if (this.isRisultatiVolatile) {
        this.ricavi.controls.ribasso.setValue(this.lineaBusinessVolatile.linea.ricaviComplessivo != null ? this.lineaBusinessVolatile.linea.ricaviComplessivo.ribasso : 0);
        this.ricavi.controls.rialzo.setValue(this.lineaBusinessVolatile.linea.ricaviComplessivo != null ? this.lineaBusinessVolatile.linea.ricaviComplessivo.rialzo : 0);
        this.ricavi.controls.scarto.setValue(this.lineaBusinessVolatile.linea.ricaviComplessivo != null ? this.lineaBusinessVolatile.linea.ricaviComplessivo.scarto : 1);
        this.costi.controls.ribasso.setValue(this.lineaBusinessVolatile.linea.costiComplessivo != null ? this.lineaBusinessVolatile.linea.costiComplessivo.ribasso : 0);
        this.costi.controls.rialzo.setValue(this.lineaBusinessVolatile.linea.costiComplessivo != null ? this.lineaBusinessVolatile.linea.costiComplessivo.rialzo : 0);
        this.costi.controls.scarto.setValue(this.lineaBusinessVolatile.linea.costiComplessivo != null ? this.lineaBusinessVolatile.linea.costiComplessivo.scarto : 1);

      } else {
        this.ricavi.controls.ribasso.setValue(this.lineabusiness.ricaviComplessivo != null ? this.lineabusiness.ricaviComplessivo.ribasso : 0);
        this.ricavi.controls.rialzo.setValue(this.lineabusiness.ricaviComplessivo != null ? this.lineabusiness.ricaviComplessivo.rialzo : 0);
        this.ricavi.controls.scarto.setValue(this.lineabusiness.ricaviComplessivo != null ? this.lineabusiness.ricaviComplessivo.scarto : 1);
        this.costi.controls.ribasso.setValue(this.lineabusiness.costiComplessivo != null ? this.lineabusiness.costiComplessivo.ribasso : 0);
        this.costi.controls.rialzo.setValue(this.lineabusiness.costiComplessivo != null ? this.lineabusiness.costiComplessivo.rialzo : 0);
        this.costi.controls.scarto.setValue(this.lineabusiness.costiComplessivo != null ? this.lineabusiness.costiComplessivo.scarto : 1);
      }
      this.ricavi.controls.ribasso.setValidators(Validators.required);
      this.ricavi.controls.rialzo.setValidators(Validators.required);
      this.ricavi.controls.scarto.setValidators(Validators.required);
      this.costi.controls.ribasso.setValidators(Validators.required)
      this.costi.controls.rialzo.setValidators(Validators.required);
      this.costi.controls.scarto.setValidators(Validators.required);

    } else {
      this.ricavi.controls.ribasso.clearValidators();
      this.ricavi.controls.rialzo.clearValidators();
      this.ricavi.controls.scarto.clearValidators();
      this.costi.controls.ribasso.clearValidators();
      this.costi.controls.rialzo.clearValidators();
      this.costi.controls.scarto.clearValidators();

    }
    this.ricavi.controls.ribasso.updateValueAndValidity();
    this.ricavi.controls.rialzo.updateValueAndValidity();
    this.ricavi.controls.scarto.updateValueAndValidity();
    this.costi.controls.ribasso.updateValueAndValidity();
    this.costi.controls.rialzo.updateValueAndValidity();
    this.costi.controls.scarto.updateValueAndValidity();
  }

  onChange() {
    this.configurazioneBusinessPlanService.sendSelectValueOptionSelect(this.f.valorizzazione.value.id);
  }


  onChangeOscillazionilinea() {
    if (this.f.oscillazioniLinea.value) {
      //chiamata da config.ambiente
      if (this.isRisultatiVolatile) {
        this.quantitaVenduta.controls.ribasso.setValue(this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.ribasso);
        this.quantitaVenduta.controls.rialzo.setValue(this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.rialzo);
        this.quantitaVenduta.controls.scarto.setValue(this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.scarto);
        this.prezzoIniziale.controls.ribasso.setValue(this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.ribasso);
        this.prezzoIniziale.controls.rialzo.setValue(this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.rialzo);
        this.prezzoIniziale.controls.scarto.setValue(this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.scarto);
        this.costoIniziale.controls.ribasso.setValue(this.lineaBusinessVolatile.linea.costoUnitarioIniziale.ribasso);
        this.costoIniziale.controls.rialzo.setValue(this.lineaBusinessVolatile.linea.costoUnitarioIniziale.rialzo);
        this.costoIniziale.controls.scarto.setValue(this.lineaBusinessVolatile.linea.costoUnitarioIniziale.scarto);

      } else {
        this.quantitaVenduta.controls.ribasso.setValue(this.lineabusiness.quantitaUnitarioVenduta.ribasso);
        this.quantitaVenduta.controls.rialzo.setValue(this.lineabusiness.quantitaUnitarioVenduta.rialzo);
        this.quantitaVenduta.controls.scarto.setValue(this.lineabusiness.quantitaUnitarioVenduta.scarto);
        this.prezzoIniziale.controls.ribasso.setValue(this.lineabusiness.prezzoUnitarioIniziale.ribasso);
        this.prezzoIniziale.controls.rialzo.setValue(this.lineabusiness.prezzoUnitarioIniziale.rialzo);
        this.prezzoIniziale.controls.scarto.setValue(this.lineabusiness.prezzoUnitarioIniziale.scarto);
        this.costoIniziale.controls.ribasso.setValue(this.lineabusiness.costoUnitarioIniziale.ribasso);
        this.costoIniziale.controls.rialzo.setValue(this.lineabusiness.costoUnitarioIniziale.rialzo);
        this.costoIniziale.controls.scarto.setValue(this.lineabusiness.costoUnitarioIniziale.scarto);
      }
      this.quantitaVenduta.controls.ribasso.setValidators(Validators.required);
      this.quantitaVenduta.controls.rialzo.setValidators(Validators.required);
      this.quantitaVenduta.controls.scarto.setValidators(Validators.required);
      this.prezzoIniziale.controls.ribasso.setValidators(Validators.required);
      this.prezzoIniziale.controls.rialzo.setValidators(Validators.required);
      this.prezzoIniziale.controls.scarto.setValidators(Validators.required);
      this.costoIniziale.controls.ribasso.setValidators(Validators.required);
      this.costoIniziale.controls.rialzo.setValidators(Validators.required);
      this.costoIniziale.controls.scarto.setValidators(Validators.required);

    } else {
      this.quantitaVenduta.controls.ribasso.clearValidators();
      this.quantitaVenduta.controls.rialzo.clearValidators();
      this.quantitaVenduta.controls.scarto.clearValidators();
      this.prezzoIniziale.controls.ribasso.clearValidators();
      this.prezzoIniziale.controls.rialzo.clearValidators();
      this.prezzoIniziale.controls.scarto.clearValidators();
      this.costoIniziale.controls.ribasso.clearValidators();
      this.costoIniziale.controls.rialzo.clearValidators();
      this.costoIniziale.controls.scarto.clearValidators();

    }
    this.quantitaVenduta.controls.ribasso.updateValueAndValidity();
    this.quantitaVenduta.controls.rialzo.updateValueAndValidity();
    this.quantitaVenduta.controls.scarto.updateValueAndValidity();
    this.prezzoIniziale.controls.ribasso.updateValueAndValidity();
    this.prezzoIniziale.controls.rialzo.updateValueAndValidity();
    this.prezzoIniziale.controls.scarto.updateValueAndValidity();
    this.costoIniziale.controls.ribasso.updateValueAndValidity();
    this.costoIniziale.controls.rialzo.updateValueAndValidity();
    this.costoIniziale.controls.scarto.updateValueAndValidity();


  }

  disabilitaWarningLinea() {
    this.warningRicaviRibasso = false;
    this.warningRicaviRialzo = false;
    this.warningRicaviScarto = false;
    this.warningRicaviDrift = false;
    this.warningCostiRibasso = false;
    this.warningCostiRialzo = false;
    this.warningCostiScarto = false;
    this.warningCostiDrift = false;
    this.warningLinea = false
    this.warningQuantitaVendutaRialzo = false
    this.warningQuantitaVendutaRibasso = false
    this.warningQuantitaVendutaScarto = false
    this.warningQuantitaVendutaDrift = false
    this.warningRicaviDriftAnnualeTotale = false
    this.warningCostiDriftAnnualeTotale = false


    for (let index = 0; index < this.warningProdotti.length; index++) {
      this.warningProdotti.splice(index, 1, false);
    }
    for (let index = 0; index < this.warningRicaviDriftAnnuale.length; index++) {
      this.warningRicaviDriftAnnuale.splice(index, 1, false);
    }
    for (let index = 0; index < this.warningCostiDriftAnnuale.length; index++) {
      this.warningCostiDriftAnnuale.splice(index, 1, false);
    }

  }
  disabilitaWarningProdotti() {
    this.warningPrezzoInizialeRialzo = false
    this.warningPrezzoInizialeRibasso = false
    this.warningPrezzoInizialeScarto = false
    this.warningPrezzoInizialeDrift = false
    this.warningCostoInizialeRialzo = false
    this.warningCostoInizialeRibasso = false
    this.warningCostoInizialeScarto = false
    this.warningCostoInizialeDrift = false

    this.warningQuantitaInizialeDriftAnnualeTot = false
    this.warningCostoInizialeDriftAnnualeTot = false
    this.warningPrezzoInizialeDriftAnnualeTot = false
    for (let index = 0; index < this.warningQuantitaInizialeDriftAnnuale.length; index++) {
      this.warningQuantitaInizialeDriftAnnuale.splice(index, 1, false);
    }
    for (let index = 0; index < this.warningCostoInizialeDriftAnnuale.length; index++) {
      this.warningCostoInizialeDriftAnnuale.splice(index, 1, false);
    }
    for (let index = 0; index < this.warningPrezzoInizialeDriftAnnuale.length; index++) {
      this.warningPrezzoInizialeDriftAnnuale.splice(index, 1, false);
    }
  }

  lineaBpValid() {
    this.f.valorizzazione.updateValueAndValidity()
    this.f.evoluzioneComplessiva.updateValueAndValidity()
    this.f.evoluzioneUnitaria.updateValueAndValidity()
    this.quantitaVenduta.controls.drift.updateValueAndValidity();
    this.prezzoIniziale.controls.drift.updateValueAndValidity();
    this.costoIniziale.controls.drift.updateValueAndValidity();
    this.f.oscillazioniComplessive.updateValueAndValidity()
    this.f.oscillazioniLinea.updateValueAndValidity();
    this.ricavi.controls.ricaviIniziali.updateValueAndValidity();
    this.ricavi.controls.drift.updateValueAndValidity();
    this.driftRicaviAnnualeArray.updateValueAndValidity()
    this.ricavi.controls.ribasso.updateValueAndValidity();
    this.ricavi.controls.rialzo.updateValueAndValidity();
    this.ricavi.controls.scarto.updateValueAndValidity();
    this.costi.controls.costiMaterieIniziali.updateValueAndValidity();
    this.costi.controls.costiServiziIniziali.updateValueAndValidity();
    this.costi.controls.drift.updateValueAndValidity();
    this.driftCostiAnnualeArray.updateValueAndValidity()
    this.costi.controls.ribasso.updateValueAndValidity();
    this.costi.controls.rialzo.updateValueAndValidity();
    this.costi.controls.scarto.updateValueAndValidity();
    this.quantitaVenduta.controls.ribasso.updateValueAndValidity();
    this.quantitaVenduta.controls.rialzo.updateValueAndValidity();
    this.quantitaVenduta.controls.scarto.updateValueAndValidity();
    this.quantitaVenduta.controls.drift.updateValueAndValidity();
    this.prezzoIniziale.controls.ribasso.updateValueAndValidity();
    this.prezzoIniziale.controls.rialzo.updateValueAndValidity();
    this.prezzoIniziale.controls.scarto.updateValueAndValidity();
    this.prezzoIniziale.controls.drift.updateValueAndValidity();
    this.costoIniziale.controls.ribasso.updateValueAndValidity();
    this.costoIniziale.controls.rialzo.updateValueAndValidity();
    this.costoIniziale.controls.scarto.updateValueAndValidity();
    this.costoIniziale.controls.drift.updateValueAndValidity();
    this.driftCostoInizialeAnnualeArray.updateValueAndValidity();
    this.driftPrezzoInizialeAnnualeArray.updateValueAndValidity();
    this.driftQuantitaInizialeAnnualeArray.updateValueAndValidity();
    this.percTrimVendutoArray.updateValueAndValidity();
    this.percTrimMagazArray.updateValueAndValidity();
this.f.annoInizio.updateValueAndValidity();
this.f.trimestreInizio.updateValueAndValidity();
this.latenzaArray.updateValueAndValidity();
    this.prodottiArray.updateValueAndValidity();
    this.ricaviEcostiForm.updateValueAndValidity();
    let sommaVenduto = 0;
    let sommaMagaz = 0;
    for (let i = 0; i < this.percTrimVendutoFormArray.length; i++) {
      sommaVenduto += this.percTrimVendutoFormArray[i].value;
      sommaMagaz += this.percTrimMagazFormArray[i].value;
    }
    this.sommaCentoVenduto = sommaVenduto == 100 || this.lineaDisabled;
    this.sommaCentoMagaz = sommaMagaz == 100 || this.lineaDisabled;
    this.lineaBusinessFormValid = (this.ricaviEcostiForm.valid && this.sommaCentoVenduto && this.sommaCentoMagaz) || this.lineaDisabled;

    return this.lineaBusinessFormValid
  }

  lineaBpDirty() {
    /* qui non uso la condizione sulla lineaSubmitted perche se disabilitassi solo una linea senza fare altre modifche non partirebbe il salva in quanto dirty=false */
    this.f.valorizzazione.updateValueAndValidity()
    this.f.evoluzioneComplessiva.updateValueAndValidity()
    this.f.evoluzioneUnitaria.updateValueAndValidity()
    this.quantitaVenduta.controls.drift.updateValueAndValidity();
    this.prezzoIniziale.controls.drift.updateValueAndValidity();
    this.costoIniziale.controls.drift.updateValueAndValidity();
    this.f.oscillazioniComplessive.updateValueAndValidity()
    this.f.oscillazioniLinea.updateValueAndValidity();
    this.ricavi.controls.ricaviIniziali.updateValueAndValidity();
    this.ricavi.controls.drift.updateValueAndValidity();
    this.driftRicaviAnnualeArray.updateValueAndValidity()
    this.ricavi.controls.ribasso.updateValueAndValidity();
    this.ricavi.controls.rialzo.updateValueAndValidity();
    this.ricavi.controls.scarto.updateValueAndValidity();
    this.costi.controls.costiMaterieIniziali.updateValueAndValidity();
    this.costi.controls.costiServiziIniziali.updateValueAndValidity();
    this.costi.controls.drift.updateValueAndValidity();
    this.driftCostiAnnualeArray.updateValueAndValidity()
    this.costi.controls.ribasso.updateValueAndValidity();
    this.costi.controls.rialzo.updateValueAndValidity();
    this.costi.controls.scarto.updateValueAndValidity();
    this.quantitaVenduta.controls.ribasso.updateValueAndValidity();
    this.quantitaVenduta.controls.rialzo.updateValueAndValidity();
    this.quantitaVenduta.controls.scarto.updateValueAndValidity();
    this.quantitaVenduta.controls.drift.updateValueAndValidity();
    this.prezzoIniziale.controls.ribasso.updateValueAndValidity();
    this.prezzoIniziale.controls.rialzo.updateValueAndValidity();
    this.prezzoIniziale.controls.scarto.updateValueAndValidity();
    this.prezzoIniziale.controls.drift.updateValueAndValidity();
    this.costoIniziale.controls.ribasso.updateValueAndValidity();
    this.costoIniziale.controls.rialzo.updateValueAndValidity();
    this.costoIniziale.controls.scarto.updateValueAndValidity();
    this.costoIniziale.controls.drift.updateValueAndValidity();
    this.driftCostoInizialeAnnualeArray.updateValueAndValidity();
    this.driftPrezzoInizialeAnnualeArray.updateValueAndValidity();
    this.driftQuantitaInizialeAnnualeArray.updateValueAndValidity();
    this.percTrimVendutoArray.updateValueAndValidity();
    this.percTrimMagazArray.updateValueAndValidity();
    this.f.annoInizio.updateValueAndValidity();
    this.f.trimestreInizio.updateValueAndValidity();
    this.latenzaArray.updateValueAndValidity();
    /* dirty prodotti */
    for (let i = 0; i < this.prodottiFormArray.length; i++) {
      this.prodottiFormArray[i].updateValueAndValidity()
      if (this.prodottiFormArray[i].dirty) {
        this.prodottiArray.markAsDirty()
      }
    }
    this.prodottiArray.updateValueAndValidity();
    for (const key of Object.keys(this.ricaviEcostiForm.controls)) {
      //console.log(key)
      if (this.f[key].dirty) {
        this.ricaviEcostiForm.markAsDirty();
      }
    }
    this.ricaviEcostiForm.updateValueAndValidity()
    this.ricaviEcostiForm.dirty;
    this.submitted = this.ricaviEcostiForm.dirty
    return this.submitted;

  }

  markFormPristine() {
    this.ricaviEcostiForm.markAsPristine();
    this.ricaviEcostiForm.updateValueAndValidity();
    this.sommaCentoVenduto = true;
    this.sommaCentoMagaz = true;
  }

  Annulla() {
    if (this.lineaBpDirty()) {
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler annullare ?",
        description: "Cliccando su 'Annulla' tutte le modifiche non salvate su questa pagina andranno perse.",
        undoButtonText: "Indietro",
        actionButtonText: "Annulla",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.submitted = false
          this.lineaBpSaved = false
          this.lineaBusinessFormValid = true
          this.emitLineaFormValid.emit({ lineaBusinessFormValid: this.lineaBusinessFormValid, lineaBpDirty: this.submitted })

          this.getLineaBp()
          this.markFormPristine()
        }
      })
    }

  }
  resetLinea() {
    if (this.lineaDisabled == false) {
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "annulla",
        title: "Sicuro di voler disabilitare la linea '" + this.lineabusiness.nome + "'?",
        description: "Disabilitando la linea questa non verrà considerata nel calcolo del nuovo Business plan.",
        undoButtonText: "Indietro",
        actionButtonText: "Disabilita",
      }
      modalRef.result.then((r) => {
        if (r) {
          this.f.valorizzazione.clearValidators();
          this.f.valorizzazione.updateValueAndValidity();
          this.submitted = false;
          this.lineaBusinessFormValid = true;
          this.lineaDisabled = true;
          this.lineaBpSaved = false;
          this.sommaCentoMagaz = true;
          this.sommaCentoVenduto = true;
          this.emitLineaFormValid.emit({ lineaBusinessFormValid: this.lineaBusinessFormValid, lineaBpDirty: this.submitted });
          this.f.valorizzazione.markAsDirty();

        }
      })
    }

  }


  elaboraLineaBusiness() {
    /* posso scegliere solo alcune linee. se non valorizzo la prima select  */
    if (this.lineaBpValid()) {
      //!this.lineaDisabled &&
      this.disabilitaWarningLinea();
      this.disabilitaWarningProdotti();

      let driftRicaviAnnuale = [];
      let driftCostiAnnuale = [];
      let driftQuantitaInizialeAnnuale = [];
      let driftPrezzoInizialeAnnuale = [];
      let driftCostoInizialeAnnuale = [];
      let percTrimVenduto = [];
      let percTrimMagaz = [];
      let latenza = [];

      for (let i = 0; i < this.driftRicaviAnnualeFormArray.length; i++) {
        driftRicaviAnnuale.push(this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 1 ? this.driftRicaviAnnualeFormArray[i].value : (this.lineabusiness.ricaviComplessivoDriftAnnuale.length > 0 ? this.lineabusiness.ricaviComplessivoDriftAnnuale[i] : 0))
      }
      for (let i = 0; i < this.driftCostiAnnualeFormArray.length; i++) {
        driftCostiAnnuale.push(this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 1 ? this.driftCostiAnnualeFormArray[i].value : (this.lineabusiness.costiComplessivoDriftAnnuale.length > 0 ? this.lineabusiness.costiComplessivoDriftAnnuale[i] : 0))
      }
      for (let i = 0; i < this.driftQuantitaInizialeAnnualeFormArray.length; i++) {
        driftQuantitaInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftQuantitaInizialeAnnualeFormArray[i].value : (this.lineabusiness.quantitaUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.quantitaUnitarioDriftAnnuale[i] : 0))
      }
      for (let i = 0; i < this.driftPrezzoInizialeAnnualeFormArray.length; i++) {
        driftPrezzoInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftPrezzoInizialeAnnualeFormArray[i].value : (this.lineabusiness.prezzoUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.prezzoUnitarioDriftAnnuale[i] : 0))
      }
      for (let i = 0; i < this.driftCostoInizialeAnnualeFormArray.length; i++) {
        driftCostoInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftCostoInizialeAnnualeFormArray[i].value : (this.lineabusiness.prezzoUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.costoUnitarioDriftAnnuale[i] : 0))
      }
      for (let i = 0; i < this.percTrimVendutoFormArray.length; i++) {
        percTrimVenduto.push(this.percTrimVendutoFormArray[i].value);
        percTrimMagaz.push(this.percTrimMagazFormArray[i].value);

      }
      if(!this.lineabusiness.lineaEsistente){
        for (let i = 0; i < this.latenzaFormArray.length; i++) {
          latenza.push(this.latenzaFormArray[i].value);
  
        }  
      }

      let linea: LineaBusinessBp = {
        id: this.lineabusiness.id,
        idBusinessPlan: this.businessPlanTemp.id,
        idLineaBusinessAmbiente: this.lineabusiness.idLineaBusinessAmbiente,
        idRicaviCosti: this.f.valorizzazione.value.id,
        idTipologiaAtt: this.lineabusiness.idTipologiaAtt,
        nome: this.lineabusiness.nome,
        disabilitata: this.lineaDisabled,

        presenzaMagazzino: this.lineabusiness.presenzaMagazzino,
        oscillazioniComplessive: this.f.oscillazioniComplessive.value,
        idEvoluzioneComplessivo: this.f.evoluzioneComplessiva.value.id,

        //sezione 1
        ricaviComplessivoDriftAnnuale: driftRicaviAnnuale,
        costiComplessivoDriftAnnuale: driftCostiAnnuale,
        ricaviComplessivo: {
          valoreIniziale: this.f.valorizzazione.value.id == 1 ? this.ricavi.controls.ricaviIniziali.value : (this.lineabusiness.ricaviComplessivo == null ? 0 : this.lineabusiness.ricaviComplessivo.valoreIniziale),
          drift: this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 2 ? this.ricavi.controls.drift.value : (this.lineabusiness.ricaviComplessivo == null ? 0 : this.lineabusiness.ricaviComplessivo.drift),
          ribasso: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.ribasso.value : (this.lineabusiness.ricaviComplessivo == null ? 0 : this.lineabusiness.ricaviComplessivo.ribasso),
          rialzo: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.rialzo.value : (this.lineabusiness.ricaviComplessivo == null ? 0 : this.lineabusiness.ricaviComplessivo.rialzo),
          scarto: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.scarto.value : (this.lineabusiness.ricaviComplessivo == null ? 1 : this.lineabusiness.ricaviComplessivo.scarto),
        },
        costiComplessivo: {
          valoreIniziale: this.f.valorizzazione.value.id == 1 ? this.costi.controls.costiMaterieIniziali.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.valoreIniziale),
          valoreInizialeAlt: this.f.valorizzazione.value.id == 1 ? this.costi.controls.costiServiziIniziali.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.valoreInizialeAlt),
          drift: this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 2 ? this.costi.controls.drift.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.drift),
          ribasso: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.ribasso.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.ribasso),
          rialzo: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.rialzo.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.rialzo),
          scarto: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.scarto.value : (this.lineabusiness.costiComplessivo == null ? 1 : this.lineabusiness.costiComplessivo.scarto),
        },

        //sezione 2
        oscillazioniUnitarie: this.f.oscillazioniLinea.value,
        idEvoluzioneUnitario: this.f.evoluzioneUnitaria.value.id,
        quantitaUnitarioDriftAnnuale: driftQuantitaInizialeAnnuale,
        prezzoUnitarioDriftAnnuale: driftPrezzoInizialeAnnuale,
        costoUnitarioDriftAnnuale: driftCostoInizialeAnnuale,
        quantitaUnitarioVenduta: {
          valoreIniziale: this.lineabusiness.quantitaUnitarioVenduta == null ? 0 : this.lineabusiness.quantitaUnitarioVenduta.valoreIniziale,
          ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.ribasso.value : this.lineabusiness.quantitaUnitarioVenduta.ribasso,
          rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.rialzo.value : this.lineabusiness.quantitaUnitarioVenduta.rialzo,
          scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.scarto.value : this.lineabusiness.quantitaUnitarioVenduta.scarto,
          drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.quantitaVenduta.controls.drift.value : this.lineabusiness.quantitaUnitarioVenduta.drift,
        },
        costoUnitarioIniziale: {
          valoreIniziale: this.lineabusiness.costoUnitarioIniziale == null ? 0 : this.lineabusiness.costoUnitarioIniziale.valoreIniziale,
          ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.ribasso.value : this.lineabusiness.costoUnitarioIniziale.ribasso,
          rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.rialzo.value : this.lineabusiness.costoUnitarioIniziale.rialzo,
          scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.scarto.value : this.lineabusiness.costoUnitarioIniziale.scarto,
          drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.costoIniziale.controls.drift.value : this.lineabusiness.costoUnitarioIniziale.drift,
        },
        prezzoUnitarioIniziale: {
          valoreIniziale: this.lineabusiness.prezzoUnitarioIniziale == null ? 0 : this.lineabusiness.prezzoUnitarioIniziale.valoreIniziale,
          ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.ribasso.value : this.lineabusiness.prezzoUnitarioIniziale.ribasso,
          rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.rialzo.value : this.lineabusiness.prezzoUnitarioIniziale.rialzo,
          scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.scarto.value : this.lineabusiness.prezzoUnitarioIniziale.scarto,
          drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.prezzoIniziale.controls.drift.value : this.lineabusiness.prezzoUnitarioIniziale.drift,
        },
        percTrimVenduto: percTrimVenduto,
        percTrimMagaz: percTrimMagaz,
        costiFissiAzienda: this.businessPlanTemp.idContoEconomico != 0 && this.lineabusiness.lineaEsistente? this.lineabusiness.costiFissiAzienda : this.f.costiFissi.value,
        percCostiFissi: this.lineabusiness.percCostiFissi,
        codiceAteco: this.lineabusiness.codiceAteco,
        lineaEsistente: this.lineabusiness.lineaEsistente,
        annoInizio: this.lineabusiness.lineaEsistente ? 0 : this.f.annoInizio.value,
        trimestreInizio: this.lineabusiness.lineaEsistente ? 0 : this.f.trimestreInizio.value,
        latenza: latenza
      }
      //prodotti
      let prodottiRequest = [];
      if (this.lineabusiness.idTipologiaAtt && this.lineabusiness.idTipologiaAtt == 1) {
        if (this.prodottiArray.length > 0) {
          /* prendo il prodotto dal form */
          for (let i = 0; i < this.prodottiFormArray.length; i++) {
            let prodotto: ProdottoLineaBusinessBp = {
              id: this.prodottiTempArray[i].id, /* se il prodotto bviene da ambiente creo nuovo id */
              idBusinessPlan: this.businessPlanTemp.id,
              idColtura: this.prodottiTempArray[i].idColtura,
              idProdottoVarieta: this.prodottiTempArray[i].idProdottoVarieta,
              idProvincia: this.prodottiTempArray[i].idProvincia,
              idQualitaProduzione: this.prodottiTempArray[i].idQualitaProduzione,
              idCanaleDistribuzione: this.prodottiFormArray[i].controls.canaleDistribuzione.value,
              prezzoIniz: this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value,
              costoMaterieIniz: this.prodottiFormArray[i].controls.costoMaterieProdotto.value,
              quantitaIniz: this.prodottiFormArray[i].controls.quantitaVendutaProdotto.value,
              invendutoIniz: this.prodottiTempArray[i].invendutoIniz,
              etaPianta: this.prodottiTempArray[i].etaPianta,
              ettari: this.prodottiTempArray[i].ettari,
              descrizione: this.prodottiTempArray[i].descrizione,
            }
            prodottiRequest.push(prodotto);
          }
        } else {
          for (let i = 0; i < this.prodottiTempArray.length; i++) {
            //prendo il rpdotto dall'array se uso il complessivo
            let prodotto: ProdottoLineaBusinessBp = {
              id: this.prodottiTempArray[i].id, /* se il prodotto bviene da ambiente creo nuovo id */
              idBusinessPlan: this.businessPlanTemp.id,
              idColtura: this.prodottiTempArray[i].idColtura,
              idProdottoVarieta: this.prodottiTempArray[i].idProdottoVarieta,
              idProvincia: this.prodottiTempArray[i].idProvincia,
              idQualitaProduzione: this.prodottiTempArray[i].idQualitaProduzione,
              idCanaleDistribuzione: this.prodottiTempArray[i].idCanaleDistribuzione,
              prezzoIniz: this.prodottiTempArray[i].prezzoIniz,
              costoMaterieIniz: this.prodottiTempArray[i].costoMaterieIniz,
              quantitaIniz: this.prodottiTempArray[i].quantitaIniz,
              invendutoIniz: this.prodottiTempArray[i].invendutoIniz,
              etaPianta: this.prodottiTempArray[i].etaPianta,
              ettari: this.prodottiTempArray[i].ettari,
              descrizione: this.prodottiTempArray[i].descrizione,

            }
            prodottiRequest.push(prodotto);
          }
        }
      } else {
        if (this.prodottiArray.length > 0) {
          for (let i = 0; i < this.prodottiFormArray.length; i++) {
            let prodotto: ProdottoLineaBusinessBp = {
              id: this.prodottiTempArray[i].id, /* se il prodotto bviene da ambiente creo nuovo id */
              idBusinessPlan: this.businessPlanTemp.id,
              descrizione: this.prodottiFormArray[i].controls.nomeProdotto.value,
              presenzaFidelizzazione: this.prodottiTempArray[i].presenzaFidelizzazione,
              tassoFidelizzazione: this.prodottiTempArray[i].tassoFidelizzazione,
              prezzoIniz: this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value,
              costoMaterieIniz: this.prodottiFormArray[i].controls.costoMaterieProdotto.value,
              costoServiziIniz: this.prodottiFormArray[i].controls.costoServiziProdotto.value,
              quantitaIniz: this.prodottiFormArray[i].controls.quantitaVendutaProdotto.value,
            }
            prodottiRequest.push(prodotto);
          }
        } else {
          for (let i = 0; i < this.prodottiTempArray.length; i++) {
            let prodotto: ProdottoLineaBusinessBp = {
              id: this.prodottiTempArray[i].id, /* se il prodotto bviene da ambiente creo nuovo id */
              idBusinessPlan: this.businessPlanTemp.id,
              descrizione: this.prodottiTempArray[i].descrizione,
              presenzaFidelizzazione: this.prodottiTempArray[i].presenzaFidelizzazione,
              tassoFidelizzazione: this.prodottiTempArray[i].tassoFidelizzazione,
              prezzoIniz: this.prodottiTempArray[i].prezzoIniz,
              costoMaterieIniz: this.prodottiTempArray[i].costoMaterieIniz,
              costoServiziIniz: this.prodottiTempArray[i].costoServiziIniz,
              quantitaIniz: this.prodottiTempArray[i].quantitaIniz,
            }
            prodottiRequest.push(prodotto);
          }
        }
      }
      let lineaCompleto: LineaBusinessBpCompleto = {
        linea: linea,
        prodotti: prodottiRequest,
      }
      return lineaCompleto

    } else { return null }
  }

  onSubmit() {
    if (this.submitted == true) {//&& this.lineaBpValid()
      this.disabilitaWarningLinea();
      this.disabilitaWarningProdotti();
      let driftRicaviAnnuale = [];
      let driftCostiAnnuale = [];
      let driftQuantitaInizialeAnnuale = [];
      let driftPrezzoInizialeAnnuale = [];
      let driftCostoInizialeAnnuale = [];
      let percTrimVenduto = [];
      let percTrimMagaz = [];
      let latenza = [];
      let linea: LineaBusinessBp;
      if (this.isRisultatiVolatile) {
        for (let i = 0; i < this.driftRicaviAnnualeFormArray.length; i++) {
          driftRicaviAnnuale.push(this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 1 ? this.driftRicaviAnnualeFormArray[i].value : (this.lineaBusinessVolatile.linea.ricaviComplessivoDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.ricaviComplessivoDriftAnnuale[i] : 0))
        }
        for (let i = 0; i < this.driftCostiAnnualeFormArray.length; i++) {
          driftCostiAnnuale.push(this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 1 ? this.driftCostiAnnualeFormArray[i].value : (this.lineaBusinessVolatile.linea.costiComplessivoDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.costiComplessivoDriftAnnuale[i] : 0))
        }

        for (let i = 0; i < this.driftQuantitaInizialeAnnualeFormArray.length; i++) {
          driftQuantitaInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftQuantitaInizialeAnnualeFormArray[i].value : (this.lineaBusinessVolatile.linea.quantitaUnitarioDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.quantitaUnitarioDriftAnnuale[i] : 0))
        }
        for (let i = 0; i < this.driftPrezzoInizialeAnnualeFormArray.length; i++) {
          driftPrezzoInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftPrezzoInizialeAnnualeFormArray[i].value : (this.lineaBusinessVolatile.linea.prezzoUnitarioDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.prezzoUnitarioDriftAnnuale[i] : 0))
        }
        for (let i = 0; i < this.driftCostoInizialeAnnualeFormArray.length; i++) {
          driftCostoInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftCostoInizialeAnnualeFormArray[i].value : (this.lineaBusinessVolatile.linea.prezzoUnitarioDriftAnnuale.length > 0 ? this.lineaBusinessVolatile.linea.costoUnitarioDriftAnnuale[i] : 0))
        }
        for (let i = 0; i < this.percTrimVendutoFormArray.length; i++) {
          percTrimVenduto.push(this.percTrimVendutoFormArray[i].value);
          percTrimMagaz.push(this.percTrimMagazFormArray[i].value);
        }
        if(!this.lineaBusinessVolatile.linea.lineaEsistente){
          for (let i = 0; i < this.latenzaFormArray.length; i++) {
            latenza.push(this.latenzaFormArray[i].value);
    
          }  
        }

        linea = {
          id: this.lineaBusinessVolatile.linea.id,
          idBusinessPlan: this.lineaBusinessVolatile.linea.idBusinessPlan,
          idLineaBusinessAmbiente: this.lineaBusinessVolatile.linea.idLineaBusinessAmbiente,
          idRicaviCosti: this.f.valorizzazione.value.id,
          idTipologiaAtt: this.lineabusiness.idTipologiaAtt,
          nome: this.lineaBusinessVolatile.linea.nome,
          disabilitata: this.lineaDisabled,
          presenzaMagazzino: this.lineaBusinessVolatile.linea.presenzaMagazzino,
          oscillazioniComplessive: this.f.oscillazioniComplessive.value,
          idEvoluzioneComplessivo: this.f.evoluzioneComplessiva.value.id,
          //sezione 1
          ricaviComplessivoDriftAnnuale: driftRicaviAnnuale,
          costiComplessivoDriftAnnuale: driftCostiAnnuale,
          ricaviComplessivo: {
            valoreIniziale: this.f.valorizzazione.value.id == 1 ? this.ricavi.controls.ricaviIniziali.value : (this.lineaBusinessVolatile.linea.ricaviComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.ricaviComplessivo.valoreIniziale),
            drift: this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 2 ? this.ricavi.controls.drift.value : (this.lineaBusinessVolatile.linea.ricaviComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.ricaviComplessivo.drift),
            ribasso: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.ribasso.value : (this.lineaBusinessVolatile.linea.ricaviComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.ricaviComplessivo.ribasso),
            rialzo: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.rialzo.value : (this.lineaBusinessVolatile.linea.ricaviComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.ricaviComplessivo.rialzo),
            scarto: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.scarto.value : (this.lineaBusinessVolatile.linea.ricaviComplessivo == null ? 1 : this.lineaBusinessVolatile.linea.ricaviComplessivo.scarto),
          },
          costiComplessivo: {
            valoreIniziale: this.f.valorizzazione.value.id == 1 ? this.costi.controls.costiMaterieIniziali.value : (this.lineaBusinessVolatile.linea.costiComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.costiComplessivo.valoreIniziale),
            valoreInizialeAlt: this.f.valorizzazione.value.id == 1 ? this.costi.controls.costiServiziIniziali.value : (this.lineaBusinessVolatile.linea.costiComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.costiComplessivo.valoreInizialeAlt),
            drift: this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 2 ? this.costi.controls.drift.value : (this.lineaBusinessVolatile.linea.costiComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.costiComplessivo.drift),
            ribasso: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.ribasso.value : (this.lineaBusinessVolatile.linea.costiComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.costiComplessivo.ribasso),
            rialzo: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.rialzo.value : (this.lineaBusinessVolatile.linea.costiComplessivo == null ? 0 : this.lineaBusinessVolatile.linea.costiComplessivo.rialzo),
            scarto: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.scarto.value : (this.lineaBusinessVolatile.linea.costiComplessivo == null ? 1 : this.lineaBusinessVolatile.linea.costiComplessivo.scarto),
          },

          //sezione 2
          oscillazioniUnitarie: this.f.oscillazioniLinea.value, //
          idEvoluzioneUnitario: this.f.evoluzioneUnitaria.value.id,
          quantitaUnitarioDriftAnnuale: driftQuantitaInizialeAnnuale,
          prezzoUnitarioDriftAnnuale: driftPrezzoInizialeAnnuale,
          costoUnitarioDriftAnnuale: driftCostoInizialeAnnuale,
          quantitaUnitarioVenduta: {
            valoreIniziale: this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta == null ? 0 : this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.valoreIniziale,
            ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.ribasso.value : (this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.ribasso),
            rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.rialzo.value : this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.rialzo,
            scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.scarto.value : this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.scarto,
            drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.quantitaVenduta.controls.drift.value : this.lineaBusinessVolatile.linea.quantitaUnitarioVenduta.drift,
          },
          costoUnitarioIniziale: {
            valoreIniziale: this.lineaBusinessVolatile.linea.costoUnitarioIniziale == null ? 0 : this.lineaBusinessVolatile.linea.costoUnitarioIniziale.valoreIniziale,
            ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.ribasso.value : this.lineaBusinessVolatile.linea.costoUnitarioIniziale.ribasso,
            rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.rialzo.value : this.lineaBusinessVolatile.linea.costoUnitarioIniziale.rialzo,
            scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.scarto.value : this.lineaBusinessVolatile.linea.costoUnitarioIniziale.scarto,
            drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.costoIniziale.controls.drift.value : this.lineaBusinessVolatile.linea.costoUnitarioIniziale.drift,
          },
          prezzoUnitarioIniziale: {
            valoreIniziale: this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale == null ? 0 : this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.valoreIniziale,
            ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.ribasso.value : this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.ribasso,
            rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.rialzo.value : this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.rialzo,
            scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.scarto.value : this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.scarto,
            drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.prezzoIniziale.controls.drift.value : this.lineaBusinessVolatile.linea.prezzoUnitarioIniziale.drift,
          },
          percTrimVenduto: percTrimVenduto,
          percTrimMagaz: percTrimMagaz,
          codiceAteco: this.lineaBusinessVolatile.linea.codiceAteco,
          percCostiFissi: this.lineaBusinessVolatile.linea.percCostiFissi,
          costiFissiAzienda: this.businessPlanTemp.idContoEconomico != 0  && this.lineaBusinessVolatile.linea.lineaEsistente? this.lineaBusinessVolatile.linea.costiFissiAzienda : this.f.costiFissi.value,
          lineaEsistente: this.lineaBusinessVolatile.linea.lineaEsistente,
          annoInizio: this.lineaBusinessVolatile.linea.lineaEsistente ? 0 : this.f.annoInizio.value,
          trimestreInizio: this.lineaBusinessVolatile.linea.lineaEsistente ? 0 : this.f.trimestreInizio.value,
          latenza: latenza
        }
      } else {
        for (let i = 0; i < this.driftRicaviAnnualeFormArray.length; i++) {
          driftRicaviAnnuale.push(this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 1 ? this.driftRicaviAnnualeFormArray[i].value : (this.lineabusiness.ricaviComplessivoDriftAnnuale.length > 0 ? this.lineabusiness.ricaviComplessivoDriftAnnuale[i] : 0))
        } for (let i = 0; i < this.driftCostiAnnualeFormArray.length; i++) {
          driftCostiAnnuale.push(this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 1 ? this.driftCostiAnnualeFormArray[i].value : (this.lineabusiness.costiComplessivoDriftAnnuale.length > 0 ? this.lineabusiness.costiComplessivoDriftAnnuale[i] : 0))
        }
        for (let i = 0; i < this.driftQuantitaInizialeAnnualeFormArray.length; i++) {
          driftQuantitaInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftQuantitaInizialeAnnualeFormArray[i].value : (this.lineabusiness.quantitaUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.quantitaUnitarioDriftAnnuale[i] : 0))
        }
        for (let i = 0; i < this.driftPrezzoInizialeAnnualeFormArray.length; i++) {
          driftPrezzoInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftPrezzoInizialeAnnualeFormArray[i].value : (this.lineabusiness.prezzoUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.prezzoUnitarioDriftAnnuale[i] : 0))
        }
        for (let i = 0; i < this.driftCostoInizialeAnnualeFormArray.length; i++) {
          driftCostoInizialeAnnuale.push(this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 1 ? this.driftCostoInizialeAnnualeFormArray[i].value : (this.lineabusiness.prezzoUnitarioDriftAnnuale.length > 0 ? this.lineabusiness.costoUnitarioDriftAnnuale[i] : 0))
        }
        for (let i = 0; i < this.percTrimVendutoFormArray.length; i++) {
          percTrimVenduto.push(this.percTrimVendutoFormArray[i].value);
          percTrimMagaz.push(this.percTrimMagazFormArray[i].value);

        }
        if(!this.lineabusiness.lineaEsistente){
          for (let i = 0; i < this.latenzaFormArray.length; i++) {
            latenza.push(this.latenzaFormArray[i].value);
    
          }  
        }
        
        linea = {
          id: this.lineabusiness.id,
          idBusinessPlan: this.businessPlanTemp.id,
          idLineaBusinessAmbiente: this.lineabusiness.idLineaBusinessAmbiente,
          idRicaviCosti: this.f.valorizzazione.value.id,
          idTipologiaAtt: this.lineabusiness.idTipologiaAtt,
          nome: this.lineabusiness.nome,
          disabilitata: this.lineaDisabled,
          presenzaMagazzino: this.lineabusiness.presenzaMagazzino,
          oscillazioniComplessive: this.f.oscillazioniComplessive.value,
          idEvoluzioneComplessivo: this.f.evoluzioneComplessiva.value.id,
          ricaviComplessivoDriftAnnuale: driftRicaviAnnuale,
          costiComplessivoDriftAnnuale: driftCostiAnnuale,
          //sezione 1
          ricaviComplessivo: {
            valoreIniziale: this.f.valorizzazione.value.id == 1 ? this.ricavi.controls.ricaviIniziali.value : (this.lineabusiness.ricaviComplessivo == null ? 0 : this.lineabusiness.ricaviComplessivo.valoreIniziale),
            drift: this.f.valorizzazione.value.id == 1 && this.f.evoluzioneComplessiva.value.id == 2 ? this.ricavi.controls.drift.value : (this.lineabusiness.ricaviComplessivo == null ? 0 : this.lineabusiness.ricaviComplessivo.drift),
            ribasso: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.ribasso.value : (this.lineabusiness.ricaviComplessivo == null ? 0 : this.lineabusiness.ricaviComplessivo.ribasso),
            rialzo: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.rialzo.value : (this.lineabusiness.ricaviComplessivo == null ? 0 : this.lineabusiness.ricaviComplessivo.rialzo),
            scarto: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.ricavi.controls.scarto.value : (this.lineabusiness.ricaviComplessivo == null ? 1 : this.lineabusiness.ricaviComplessivo.scarto),
          },
          costiComplessivo: {
            valoreIniziale: this.f.valorizzazione.value.id == 1 ? this.costi.controls.costiMaterieIniziali.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.valoreIniziale),
            valoreInizialeAlt: this.f.valorizzazione.value.id == 1 ? this.costi.controls.costiServiziIniziali.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.valoreInizialeAlt),
            drift: this.f.valorizzazione.value.id == 1 && this.f.valorizzazione.value.id == 2 ? this.costi.controls.drift.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.drift),
            ribasso: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.ribasso.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.ribasso),
            rialzo: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.rialzo.value : (this.lineabusiness.costiComplessivo == null ? 0 : this.lineabusiness.costiComplessivo.rialzo),
            scarto: this.f.valorizzazione.value.id == 1 && this.f.oscillazioniComplessive.value ? this.costi.controls.scarto.value : (this.lineabusiness.costiComplessivo == null ? 1 : this.lineabusiness.costiComplessivo.scarto),
          },

          //sezione 2
          oscillazioniUnitarie: this.f.oscillazioniLinea.value,
          idEvoluzioneUnitario: this.f.evoluzioneUnitaria.value.id,

          quantitaUnitarioDriftAnnuale: driftQuantitaInizialeAnnuale,
          prezzoUnitarioDriftAnnuale: driftPrezzoInizialeAnnuale,
          costoUnitarioDriftAnnuale: driftCostoInizialeAnnuale,
          quantitaUnitarioVenduta: {
            valoreIniziale: this.lineabusiness.quantitaUnitarioVenduta == null ? 0 : this.lineabusiness.quantitaUnitarioVenduta.valoreIniziale,
            ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.ribasso.value : this.lineabusiness.quantitaUnitarioVenduta.ribasso,
            rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.rialzo.value : this.lineabusiness.quantitaUnitarioVenduta.rialzo,
            scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.quantitaVenduta.controls.scarto.value : this.lineabusiness.quantitaUnitarioVenduta.scarto,
            drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.quantitaVenduta.controls.drift.value : this.lineabusiness.quantitaUnitarioVenduta.drift,
          },
          costoUnitarioIniziale: {
            valoreIniziale: this.lineabusiness.costoUnitarioIniziale == null ? 0 : this.lineabusiness.costoUnitarioIniziale.valoreIniziale,
            ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.ribasso.value : this.lineabusiness.costoUnitarioIniziale.ribasso,
            rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.rialzo.value : this.lineabusiness.costoUnitarioIniziale.rialzo,
            scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.costoIniziale.controls.scarto.value : this.lineabusiness.costoUnitarioIniziale.scarto,
            drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.costoIniziale.controls.drift.value : this.lineabusiness.costoUnitarioIniziale.drift,
          },
          prezzoUnitarioIniziale: {
            valoreIniziale: this.lineabusiness.prezzoUnitarioIniziale == null ? 0 : this.lineabusiness.prezzoUnitarioIniziale.valoreIniziale,
            ribasso: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.ribasso.value : this.lineabusiness.prezzoUnitarioIniziale.ribasso,
            rialzo: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.rialzo.value : this.lineabusiness.prezzoUnitarioIniziale.rialzo,
            scarto: this.f.valorizzazione.value.id == 2 && this.f.oscillazioniLinea.value ? this.prezzoIniziale.controls.scarto.value : this.lineabusiness.prezzoUnitarioIniziale.scarto,
            drift: this.f.valorizzazione.value.id == 2 && this.f.evoluzioneUnitaria.value.id == 2 ? this.prezzoIniziale.controls.drift.value : this.lineabusiness.prezzoUnitarioIniziale.drift,
          },
          percTrimVenduto: percTrimVenduto,
          percTrimMagaz: percTrimMagaz,
          codiceAteco: this.lineabusiness.codiceAteco,
          percCostiFissi: this.lineabusiness.percCostiFissi,
          costiFissiAzienda: this.businessPlanTemp.idContoEconomico != 0 && this.lineabusiness.lineaEsistente? this.lineabusiness.costiFissiAzienda : this.f.costiFissi.value,
          lineaEsistente: this.lineabusiness.lineaEsistente,
          annoInizio: this.lineabusiness.lineaEsistente ? 0 : this.f.annoInizio.value,
          trimestreInizio: this.lineabusiness.lineaEsistente ? 0 : this.f.trimestreInizio.value,
          latenza: latenza
        }
      }
      //prodotti
      let prodottiRequest = [];
      if (this.lineabusiness.idTipologiaAtt && this.lineabusiness.idTipologiaAtt == 1) {
        if (this.prodottiArray.length > 0) {
          /* prendo il prodotto dal form */
          for (let i = 0; i < this.prodottiFormArray.length; i++) {
            let prodotto: ProdottoLineaBusinessBp = {
              id: this.prodottiTempArray[i].id, /* se il prodotto bviene da ambiente creo nuovo id */
              idBusinessPlan: this.businessPlanTemp.id,
              idColtura: this.prodottiTempArray[i].idColtura,
              idProdottoVarieta: this.prodottiTempArray[i].idProdottoVarieta,
              idProvincia: this.prodottiTempArray[i].idProvincia,
              idQualitaProduzione: this.prodottiTempArray[i].idQualitaProduzione,
              idCanaleDistribuzione: this.prodottiFormArray[i].controls.canaleDistribuzione.value,
              prezzoIniz: this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value,
              costoMaterieIniz: this.prodottiFormArray[i].controls.costoMaterieProdotto.value,
              quantitaIniz: this.prodottiFormArray[i].controls.quantitaVendutaProdotto.value,
              invendutoIniz: this.prodottiTempArray[i].invendutoIniz,
              etaPianta: this.prodottiTempArray[i].etaPianta,
              ettari: this.prodottiTempArray[i].ettari,
            }
            prodottiRequest.push(prodotto);
          }
        } else {
          for (let i = 0; i < this.prodottiTempArray.length; i++) {
            //prendo il rpdotto dall'array se uso il complessivo
            let prodotto: ProdottoLineaBusinessBp = {
              id: this.prodottiTempArray[i].id, /* se il prodotto bviene da ambiente creo nuovo id */
              idBusinessPlan: this.businessPlanTemp.id,
              idColtura: this.prodottiTempArray[i].idColtura,
              idProdottoVarieta: this.prodottiTempArray[i].idProdottoVarieta,
              idProvincia: this.prodottiTempArray[i].idProvincia,
              idQualitaProduzione: this.prodottiTempArray[i].idQualitaProduzione,
              idCanaleDistribuzione: this.prodottiTempArray[i].idCanaleDistribuzione,
              prezzoIniz: this.prodottiTempArray[i].prezzoIniz,
              costoMaterieIniz: this.prodottiTempArray[i].costoMaterieIniz,
              quantitaIniz: this.prodottiTempArray[i].quantitaIniz,
              invendutoIniz: this.prodottiTempArray[i].invendutoIniz,
              etaPianta: this.prodottiTempArray[i].etaPianta,
              ettari: this.prodottiTempArray[i].ettari,
            }
            prodottiRequest.push(prodotto);
          }
        }
      } else {
        if (this.prodottiArray.length > 0) {
          /* prendo il prodotto dal form */
          for (let i = 0; i < this.prodottiFormArray.length; i++) {
            let prodotto: ProdottoLineaBusinessBp = {
              id: this.prodottiTempArray[i].id, /* se il prodotto bviene da ambiente creo nuovo id */
              idBusinessPlan: this.businessPlanTemp.id,
              descrizione: this.prodottiFormArray[i].controls.nomeProdotto.value,
              presenzaFidelizzazione: this.prodottiTempArray[i].presenzaFidelizzazione,
              tassoFidelizzazione: this.prodottiTempArray[i].tassoFidelizzazione,
              prezzoIniz: this.prodottiFormArray[i].controls.prezzoInizialeProdotto.value,
              costoMaterieIniz: this.prodottiFormArray[i].controls.costoMaterieProdotto.value,
              costoServiziIniz: this.prodottiFormArray[i].controls.costoServiziProdotto.value,
              quantitaIniz: this.prodottiFormArray[i].controls.quantitaVendutaProdotto.value,
            }
            prodottiRequest.push(prodotto);
          }
        } else {
          for (let i = 0; i < this.prodottiTempArray.length; i++) {
            //prendo il rpdotto dall'array se uso il complessivo
            let prodotto: ProdottoLineaBusinessBp = {
              id: this.prodottiTempArray[i].id, /* se il prodotto bviene da ambiente creo nuovo id */
              idBusinessPlan: this.businessPlanTemp.id,
              descrizione: this.prodottiTempArray[i].descrizione,
              presenzaFidelizzazione: this.prodottiTempArray[i].presenzaFidelizzazione,
              tassoFidelizzazione: this.prodottiTempArray[i].tassoFidelizzazione,
              prezzoIniz: this.prodottiTempArray[i].prezzoIniz,
              costoMaterieIniz: this.prodottiTempArray[i].costoMaterieIniz,
              costoServiziIniz: this.prodottiTempArray[i].costoServiziIniz,
              quantitaIniz: this.prodottiTempArray[i].quantitaIniz,
            }
            prodottiRequest.push(prodotto);
          }
        }
      }
      let request = {
        linea: linea,
        prodotti: prodottiRequest,
      }

      this.markFormPristine()
      return request
    } else { return null }

  }
  checkValMaxDiftCostiAnnuale(i) {
    this.warningCostiDriftAnnualeTotale = false;
    if (this.driftCostiAnnualeFormArray[i].value != null) {
      if (this.driftCostiAnnualeFormArray[i].value > this.valoreMax.drift) {
        this.warningCostiDriftAnnuale.splice(i, 1, true);
      } else {
        this.warningCostiDriftAnnuale.splice(i, 1, false);
      }
      for (let index = 0; index < this.warningCostiDriftAnnuale.length; index++) {
        this.warningCostiDriftAnnualeTotale = this.warningCostiDriftAnnualeTotale || this.warningCostiDriftAnnuale[index]
      }
    }
  }
  checkValMaxDiftRicaviAnnuale(i) {
    this.warningRicaviDriftAnnualeTotale = false;
    if (this.driftRicaviAnnualeFormArray[i].value != null) {
      if (this.driftRicaviAnnualeFormArray[i].value > this.valoreMax.drift) {
        this.warningRicaviDriftAnnuale.splice(i, 1, true);
      } else {
        this.warningRicaviDriftAnnuale.splice(i, 1, false);
      }
      for (let index = 0; index < this.warningRicaviDriftAnnuale.length; index++) {
        this.warningRicaviDriftAnnualeTotale = this.warningRicaviDriftAnnualeTotale || this.warningRicaviDriftAnnuale[index]
      }
    }

  }
  checkValMaxDriftQuantitaInizialeAnnuale(i) {
    this.warningQuantitaInizialeDriftAnnualeTot = false;
    if (this.driftQuantitaInizialeAnnualeFormArray[i].value != null) {
      if (this.driftQuantitaInizialeAnnualeFormArray[i].value > this.valoreMax.drift) {
        this.warningQuantitaInizialeDriftAnnuale.splice(i, 1, true);
      } else {
        this.warningQuantitaInizialeDriftAnnuale.splice(i, 1, false);
      }
      for (let index = 0; index < this.warningQuantitaInizialeDriftAnnuale.length; index++) {
        this.warningQuantitaInizialeDriftAnnualeTot = this.warningQuantitaInizialeDriftAnnualeTot || this.warningQuantitaInizialeDriftAnnuale[index]
      }
    }

  }
  checkValMaxDriftCostoInizialeAnnuale(i) {
    this.warningCostoInizialeDriftAnnualeTot = false;
    if (this.driftCostoInizialeAnnualeFormArray[i].value != null) {
      if (this.driftCostoInizialeAnnualeFormArray[i].value > this.valoreMax.drift) {
        this.warningCostoInizialeDriftAnnuale.splice(i, 1, true);
      } else {
        this.warningCostoInizialeDriftAnnuale.splice(i, 1, false);
      }
      for (let index = 0; index < this.warningCostoInizialeDriftAnnuale.length; index++) {
        this.warningCostoInizialeDriftAnnualeTot = this.warningCostoInizialeDriftAnnualeTot || this.warningCostoInizialeDriftAnnuale[index]
      }
    }

  }
  checkValMaxDriftPrezzoInizialeAnnuale(i) {
    this.warningPrezzoInizialeDriftAnnualeTot = false;
    if (this.driftPrezzoInizialeAnnualeFormArray[i].value != null) {
      if (this.driftPrezzoInizialeAnnualeFormArray[i].value > this.valoreMax.drift) {
        this.warningPrezzoInizialeDriftAnnuale.splice(i, 1, true);
      } else {
        this.warningPrezzoInizialeDriftAnnuale.splice(i, 1, false);
      }
      for (let index = 0; index < this.warningPrezzoInizialeDriftAnnuale.length; index++) {
        this.warningPrezzoInizialeDriftAnnualeTot = this.warningPrezzoInizialeDriftAnnualeTot || this.warningPrezzoInizialeDriftAnnuale[index]
      }
    }

  }
  checkValMax(nomeCampo: string) {
    /* ricavi complessivo */
    if (nomeCampo == "ricaviRibasso") {
      if (this.ricavi.controls.ribasso.value != null) {
        if (this.ricavi.controls.ribasso.value > this.valoreMax.ribasso) {
          this.warningRicaviRibasso = true;
        } else {
          this.warningRicaviRibasso = false
        }
      }
    } else if (nomeCampo == "ricaviRialzo") {
      if (this.ricavi.controls.rialzo.value != null) {
        if (this.ricavi.controls.rialzo.value > this.valoreMax.rialzo) {
          this.warningRicaviRialzo = true;
        } else {
          this.warningRicaviRialzo = false
        }
      }
    } else if (nomeCampo == "ricaviScarto") {
      if (this.ricavi.controls.scarto.value != null) {
        if (this.ricavi.controls.scarto.value > this.valoreMax.scarto) {
          this.warningRicaviScarto = true;
        } else {
          this.warningRicaviScarto = false
        }
      }
    } else if (nomeCampo == "ricaviDrift") {
      if (this.ricavi.controls.drift.value != null) {
        if (this.ricavi.controls.drift.value > this.valoreMax.drift) {
          this.warningRicaviDrift = true;
        } else {
          this.warningRicaviDrift = false
        }
      }
      /* costi complessivo */
    }/* costi complessivo */
    if (nomeCampo == "costiRibasso") {
      if (this.costi.controls.ribasso.value != null) {
        if (this.costi.controls.ribasso.value > this.valoreMax.ribasso) {
          this.warningCostiRibasso = true;
        } else {
          this.warningCostiRibasso = false
        }
      }
    } else if (nomeCampo == "costiRialzo") {
      if (this.costi.controls.rialzo.value != null) {
        if (this.costi.controls.rialzo.value > this.valoreMax.rialzo) {
          this.warningCostiRialzo = true;
        } else {
          this.warningCostiRialzo = false
        }
      }
    } else if (nomeCampo == "costiScarto") {
      if (this.costi.controls.scarto.value != null) {
        if (this.costi.controls.scarto.value > this.valoreMax.scarto) {
          this.warningCostiScarto = true;
        } else {
          this.warningCostiScarto = false
        }
      }
    } else if (nomeCampo == "costiDrift") {
      if (this.costi.controls.drift.value != null) {
        if (this.costi.controls.drift.value > this.valoreMax.drift) {
          this.warningCostiDrift = true;
        } else {
          this.warningCostiDrift = false
        }
      }
    }
    /* quantità venduta */
    if (nomeCampo == "quantitaVendutaRibasso") {
      if (this.quantitaVenduta.controls.ribasso.value != null) {
        if (this.quantitaVenduta.controls.ribasso.value > this.valoreMax.ribasso) {
          this.warningQuantitaVendutaRibasso = true;
        } else {
          this.warningQuantitaVendutaRibasso = false
        }
      }
    } else if (nomeCampo == "quantitaVendutaRialzo") {
      if (this.quantitaVenduta.controls.rialzo.value != null) {
        if (this.quantitaVenduta.controls.rialzo.value > this.valoreMax.rialzo) {
          this.warningQuantitaVendutaRialzo = true;
        } else {
          this.warningQuantitaVendutaRialzo = false
        }
      }
    } else if (nomeCampo == "quantitaVendutaScarto") {
      if (this.quantitaVenduta.controls.scarto.value != null) {
        if (this.quantitaVenduta.controls.scarto.value > this.valoreMax.scarto) {
          this.warningQuantitaVendutaScarto = true;
        } else {
          this.warningQuantitaVendutaScarto = false
        }
      }
    } else if (nomeCampo == "quantitaVendutaDrift") {
      if (this.quantitaVenduta.controls.drift.value != null) {
        if (this.quantitaVenduta.controls.drift.value > this.valoreMax.drift) {
          this.warningQuantitaVendutaDrift = true;
        } else {
          this.warningQuantitaVendutaDrift = false
        }
      }
    }
    /* costo iniziale */
    if (nomeCampo == "costoInizialeRibasso") {
      if (this.costoIniziale.controls.ribasso.value != null) {
        if (this.costoIniziale.controls.ribasso.value > this.valoreMax.ribasso) {
          this.warningCostoInizialeRibasso = true;
        } else {
          this.warningCostoInizialeRibasso = false
        }
      }
    } else if (nomeCampo == "costoInizialeRialzo") {
      if (this.costoIniziale.controls.rialzo.value != null) {
        if (this.costoIniziale.controls.rialzo.value > this.valoreMax.rialzo) {
          this.warningCostoInizialeRialzo = true;
        } else {
          this.warningCostoInizialeRialzo = false
        }
      }
    } else if (nomeCampo == "costoInizialeScarto") {
      if (this.costoIniziale.controls.scarto.value != null) {
        if (this.costoIniziale.controls.scarto.value > this.valoreMax.scarto) {
          this.warningCostoInizialeScarto = true;
        } else {
          this.warningCostoInizialeScarto = false
        }
      }
    } else if (nomeCampo == "costoInizialeDrift") {
      if (this.quantitaVenduta.controls.drift.value != null) {
        if (this.quantitaVenduta.controls.drift.value > this.valoreMax.drift) {
          this.warningCostoInizialeDrift = true;
        } else {
          this.warningCostoInizialeDrift = false
        }
      }
    }/* prezzo iniziale */
    if (nomeCampo == "prezzoInizialeRibasso") {
      if (this.prezzoIniziale.controls.ribasso.value != null) {
        if (this.prezzoIniziale.controls.ribasso.value > this.valoreMax.ribasso) {
          this.warningPrezzoInizialeRibasso = true;
        } else {
          this.warningPrezzoInizialeRibasso = false
        }
      }
    } else if (nomeCampo == "prezzoInizialeRialzo") {
      if (this.prezzoIniziale.controls.rialzo.value != null) {
        if (this.prezzoIniziale.controls.rialzo.value > this.valoreMax.rialzo) {
          this.warningPrezzoInizialeRialzo = true;
        } else {
          this.warningPrezzoInizialeRialzo = false
        }
      }
    } else if (nomeCampo == "prezzoInizialeScarto") {
      if (this.prezzoIniziale.controls.scarto.value != null) {
        if (this.prezzoIniziale.controls.scarto.value > this.valoreMax.scarto) {
          this.warningPrezzoInizialeScarto = true;
        } else {
          this.warningPrezzoInizialeScarto = false
        }
      }
    } else if (nomeCampo == "prezzoInizialeDrift") {
      if (this.quantitaVenduta.controls.drift.value != null) {
        if (this.quantitaVenduta.controls.drift.value > this.valoreMax.drift) {
          this.warningPrezzoInizialeDrift = true;
        } else {
          this.warningPrezzoInizialeDrift = false
        }
      }
    }
  }

  abilitaLinea() {
    this.lineaDisabled = false;
    this.f.valorizzazione.setValidators(Validators.required);
    this.f.valorizzazione.updateValueAndValidity();
  }

  onChangeAnnoInizio(){
    this.f.annoInizio.updateValueAndValidity()
    if (this.f.annoInizio.value != '' && this.f.annoInizio.value < this.annoIniziale) {
        this.f.annoInizio.setValue(this.annoIniziale);
      }

  }

  onChangeLatenza(j: number){
    if(this.latenzaFormArray[j].value){
      if(this.latenzaFormArray[j].value < 0) this.latenzaFormArray[j].setValue(-this.latenzaFormArray[j].value)
      if(this.latenzaFormArray[j].value > 100) this.latenzaFormArray[j].setValue(100);
      if(this.latenzaFormArray[j].value == 100){
for(let k = j+1;k < this.latenzaFormArray.length; k++){
  this.latenzaFormArray[k].setValue(100);
}
      }
    }
    
  }

  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    this.inizioDurataSubscription.unsubscribe()
    this.resetCountSub.unsubscribe()
    this.tipsSubscription.unsubscribe()
  }
}

