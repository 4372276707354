import { Component, HostListener, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { LoadingService } from '../loading.service';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { TokenStorageService } from '../_services/token-storage.service';
import { PraticheService } from '../_services/pratiche.service';
import { BilancioIntestatarioService } from '../_services/bilancio_intestatario.service';
import { ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { eventListeners } from '@popperjs/core';

@Component({
  selector: 'qs-questionario-old',
  templateUrl: './questionario-old.component.html',
  styleUrls: ['./questionario-old.component.css', './progress-wizard.min.css'],

  animations: [
    trigger('fade', [
      state('void', style({ opacity: 100 })),
      transition(':enter', [animate(100)]),
      transition(':leave', [animate(300)]),
    ]),
  ],
})
export class QuestionarioOldComponent implements OnInit {
  /* probabilmente andranno cancellate */
  /* @ViewChild('modaleSalva') modaleSalva: any; */
  /* @ViewChild('modaleMessaggio') modaleMessaggio: any; */

  stepIndex: number = 1;
  formTotale: UntypedFormGroup;
  initialValues: any;
  finalValues: any;
  isLoading: boolean = false;
  faAngleDoubleRight = faAngleDoubleRight;
  faAngleDoubleLeft = faAngleDoubleLeft;
  totaleInvestimento = 0;
  anagraficaCompleted: boolean = false;
  finanziamentoCompleted: boolean = false;
  indebitamentoCompleted: boolean = false;
  garanzieCompleted: boolean = false;
  submitted: boolean = false;
  nomeSalvataggio = '';
  idBusinessPlan = 0;
  riferimentoOld = '';
  msg = '';
  dataBusinessPlan: Date;
  definitivo = false;
  fileUpload: any = {};

  constructor(
    private datiQuestionarioService: DatiQuestionarioService,
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    private loading: LoadingService,
    private tokenStorageService: TokenStorageService,
    private praticheService: PraticheService,
    private bilancioService: BilancioIntestatarioService,
    private modalService: NgbModal
  ) {
    this.formTotale = this.formBuilder.group({
      anagrafica: this.formBuilder.group({
        nomeAzienda: ['', Validators.required],
        tipoRichiedente: ['', Validators.required],
        titoloStudio: ['', Validators.required],
        esperienza: ['', [Validators.required, Validators.min(0)]],
        tipoAzienda: [''],
        dichiarazioneIVA: [''],
        rigaVE50: [''],
        rigaVF14: [''],
        rigaVF23: [''],
        rigaVF27: [''],
        costiAnnoPrecedente: [''],
        ricaviAnnoPrecedente: [''],
        presenzaCr: [''],
        presenzaBilancio: [''],
        importoDebitoLT: [''],
        importoDebitoMT: [''],
        importoDebitoBT: [''],
        ateco: [''],
        partitaIva: [''],
        bilancio: [''],
        centraleRischi: [''],
      }),
      investimento: this.formBuilder.group({
        descrizioneProgetto: [''],
        presentazione: [''],
        presentazioneRadio: [false],
        produzioneProgettoArray: this.formBuilder.array([
          this.formBuilder.group({
            tipoProgetto: [''],
            comune: [{ value: '', disabled: true }],
            provincia: [''],
            bio: [false],
            ettari: [''],
            importo: [''],
            affittoTerreno: [''],
            isPropietario: [false],
            macchinari: [''],
            mqSerra: [''],
            //Colture aggiuntive per la spartizione del terreno
            coltureDivisioneArray: this.formBuilder.array([
              this.formBuilder.group({
                bio: [false],
                categoria: [{ value: '', disabled: true }, Validators.required],
                prodotto: [{ value: '', disabled: true }, Validators.required],
                varieta: [{ value: '', disabled: true }, Validators.required],
                produzione: [''],
                ettariColtura: [{ value: '', disabled: true }, Validators.required],
                etaPiante: [''],
                contratto: [''],
                qualitaProduzione: [''],
                coltureRotazioneArray: this.formBuilder.array([]),
              }),
            ]),
          }),
        ]),
      }),
      garanzie: this.formBuilder.group({
        contributoFinanziamento: ['', Validators.required],
        contributoPersonale: ['', Validators.required],
        durataFinanziamentoAnni: ['', Validators.required],
        durataAmmortamentoAnni: [2, Validators.required],
        presenzaGaranzie: ['', Validators.required],
        percentualeGaranzia: [0.2],
        tipologiaGaranzia: [''],
      }),
    });

    this.initialValues = this.formTotale.value;
  }

  ngOnInit(): void {
    this.listenToLoading();
    this.datiQuestionarioService.currentDatiQuestionario.subscribe((result) => {
      if (result && result.questionario != null) {
        this.formTotale = result.questionario;
        this.confermaAnagrafica(this.anagraficaForm.valid);
        this.confermaFinanziamento(
          this.investimentoForm.valid || this.investimentoForm.disabled
        );
        this.confermaGaranzie(this.garanzieForm.valid);
      }
    });
    this.datiQuestionarioService.currentStepIndex.subscribe((stepIndex) => {
      window.scrollTo(0, 0);
      this.stepIndex = stepIndex;
    });

    this.datiQuestionarioService.currentAnagrafica.subscribe(
      (anagraficaCompleted) => (this.anagraficaCompleted = anagraficaCompleted)
    );
    this.datiQuestionarioService.currentFinanziamento.subscribe(
      (finanziamentoCompleted) =>
        (this.finanziamentoCompleted = finanziamentoCompleted)
    );
    this.datiQuestionarioService.currentGaranzie.subscribe(
      (garanzieCompleted) => (this.garanzieCompleted = garanzieCompleted)
    );
    this.datiQuestionarioService.currentSubmitted.subscribe(
      (submitted) => (this.submitted = submitted)
    );

    this.praticheService.currentParamPratiche.subscribe((param) => {
      if (param && typeof param.idBusinessPlan == 'number') { /* cond per verificaare che non è null */
        this.idBusinessPlan = param.idBusinessPlan;
        this.riferimentoOld = param.riferimentoOld;
        this.dataBusinessPlan = param.dataBusinessPlan;
        this.definitivo = param.definitivo;
      }
    });


    this.datiQuestionarioService.currentFilePresentazione.subscribe(
      (fileUpload) => (this.fileUpload = fileUpload)
    );
  }

  get anagraficaForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.formTotale.controls.anagrafica;
  }

  get anagraficaProdottiArray(): UntypedFormArray {
    return <UntypedFormArray>this.anagraficaForm.controls.produzioneArray;
  }

  get investimentoForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.formTotale.controls.investimento;
  }

  get investimentoProdottiArray(): UntypedFormArray {
    return <UntypedFormArray>this.investimentoForm.controls.produzioneProgettoArray;
  }

  get investimentoProdottiGroup(): UntypedFormGroup[] {
    return <UntypedFormGroup[]>this.investimentoProdottiArray.controls;
  }

  get indebitamentoForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.formTotale.controls.indebitamento;
  }

  get garanzieForm(): UntypedFormGroup {
    return <UntypedFormGroup>this.formTotale.controls.garanzie;
  }

  onClick(index: number) {
    if (this.stepIndex == 1) {
      this.confermaAnagrafica(this.anagraficaForm.valid);
    } else if (this.stepIndex == 2) {
      this.totaleInvestimento = 0;
      for (let i = 0; i < this.investimentoProdottiGroup.length; i++) {
        this.totaleInvestimento +=
          this.investimentoProdottiGroup[i].controls.importo.value != '' &&
            this.investimentoProdottiGroup[i].controls.importo.value != null
            ? parseFloat(
              this.investimentoProdottiGroup[i].controls.importo.value
            )
            : 0;
      }
      this.datiQuestionarioService.changeImportoInvestimento(
        this.totaleInvestimento
      );
      this.confermaFinanziamento(
        this.investimentoForm.valid || this.investimentoForm.disabled
      );
    } else if (this.stepIndex == 3) {
      this.confermaGaranzie(this.garanzieForm.valid);
    }

    this.stepIndex = index;
    this.datiQuestionarioService.changeStepIndex(index);
  }

  confermaAnagrafica(valore: boolean) {
    this.anagraficaCompleted = valore;
    this.datiQuestionarioService.changeAnagraficaCompleted(valore);
  }

  confermaFinanziamento(valore: boolean) {
    this.finanziamentoCompleted = valore;
    this.datiQuestionarioService.changeFinanziamentoCompleted(valore);
  }

  confermaGaranzie(valore: boolean) {
    this.garanzieCompleted = valore;
    this.datiQuestionarioService.changeGaranzieCompleted(valore);
  }

  submit(valore: boolean) {
    this.submitted = valore;
    this.datiQuestionarioService.changeSubmitted(valore);
  }

  initProdAnagrafica() {
    return this.formBuilder.group({
      provincia: [''],
      prodotto: [{ value: '', disabled: true }],
      varieta: [{ value: '', disabled: true }],
      bio: [false],
      ettariAzienda: [''],
      categoriaPrincipale: [''],
      tipoProduzione: [''],
    });
  }

  initProdProgetto() {
    return this.formBuilder.group({
      tipoProgetto: [''],
      comune: [{ value: '', disabled: true }],
      provincia: [''],
      bio: [false],
      ettari: [''],
      importo: [''],
      affittoTerreno: [''],
      isPropietario: [false],
      macchinari: [''],
      mqSerra: [''],
      coltureDivisioneArray: this.formBuilder.array([
        this.formBuilder.group({
          bio: [false],
          categoria: [{ value: '', disabled: true }, Validators.required],
          prodotto: [{ value: '', disabled: true }, Validators.required],
          varieta: [{ value: '', disabled: true }, Validators.required],
          produzione: [''],
          ettariColtura: [{ value: '', disabled: true }, Validators.required],
          etaPiante: [''],
          contratto: [''],
          qualitaProduzione: [''],
          coltureRotazioneArray: this.formBuilder.array([]),
        }),
      ]),
    });
  }

  initColtura() {
    return this.formBuilder.group({
      bio: [false],
      categoria: ['', Validators.required],
      prodotto: [{ value: '', disabled: true }, Validators.required],
      varieta: [{ value: '', disabled: true }, Validators.required],
      produzione: [''],
      ettariColtura: [''],
      etaPiante: [''],
      contratto: [''],
      qualitaProduzione: [''],
      coltureRotazioneArray: this.formBuilder.array([]),
    });
  }

  initColturaRotazione() {
    return this.formBuilder.group({
      bio: [false],
      categoria: ['', Validators.required],
      prodotto: [{ value: '', disabled: true }, Validators.required],
      varieta: [{ value: '', disabled: true }, Validators.required],
      qualitaProduzione: [''],
      contratto: [''],
    });
  }

  addProdAnagrafica() {
    this.anagraficaProdottiArray.push(this.initProdAnagrafica());
  }

  removeProdAnagrafica(index: number) {
    this.anagraficaProdottiArray.removeAt(index);
  }

  addProdProgetto() {
    this.investimentoProdottiArray.push(this.initProdProgetto());

    if (this.investimentoProdottiGroup.length == 1) {
      /*   this.investimentoProdottiGroup[0].controls.tipoProgetto.setValidators(
           Validators.required
         );*/
      this.investimentoProdottiGroup[0].controls.tipoProgetto.updateValueAndValidity();
    }
  }

  removeProdProgetto(index: number) {
    this.investimentoProdottiArray.removeAt(index);
    if (this.investimentoProdottiGroup.length == 0) {
      this.addProdProgetto();
    }

    if (this.investimentoProdottiGroup.length == 1) {
      /* this.investimentoProdottiGroup[0].controls.tipoProgetto.setValidators(
         Validators.required
       );*/
      this.investimentoProdottiGroup[0].controls.tipoProgetto.updateValueAndValidity();
    }
  }

  onSubmit() {
    this.submit(true);
    if (this.stepIndex == 2) {
      this.totaleInvestimento = 0;
      for (let i = 0; i < this.investimentoProdottiGroup.length; i++) {
        this.totaleInvestimento +=
          this.investimentoProdottiGroup[i].controls.importo.value != '' &&
            this.investimentoProdottiGroup[i].controls.importo.value != null
            ? parseFloat(
              this.investimentoProdottiGroup[i].controls.importo.value
            )
            : 0;
      }
      this.datiQuestionarioService.changeImportoInvestimento(
        this.totaleInvestimento
      );
    }
    this.confermaAnagrafica(this.anagraficaForm.valid);
    this.confermaFinanziamento(
      this.investimentoForm.valid || this.investimentoForm.disabled
    );
    //this.confermaIndebitamento(this.indebitamentoForm.valid || this.indebitamentoForm.disabled);
    this.confermaGaranzie(this.garanzieForm.valid);

    if (this.formTotale.valid) {
      if (
        !(
          this.investimentoProdottiGroup[
            this.investimentoProdottiGroup.length - 1
          ].controls.tipoProgetto.value &&
          this.investimentoProdottiGroup[
            this.investimentoProdottiGroup.length - 1
          ].controls.tipoProgetto.value != ''
        )
      ) {
        this.removeProdProgetto(this.investimentoProdottiGroup.length - 1);
      }
      this.datiQuestionarioService.changeFormTouched(this.formTotale.touched);
      this.datiQuestionarioService.changeDatiQuestionario({
        questionario: this.formTotale,
        dataBusinessPlan: this.dataBusinessPlan,
      });
      this.submit(false);
      this.router.navigate(['/preview']);
    }

  }

  onReset() {
    this.formTotale.reset(this.initialValues);
    this.datiQuestionarioService.changeImportoInvestimento(0);
    this.submit(false);
    this.confermaFinanziamento(false);
    this.confermaAnagrafica(false);
    // this.confermaIndebitamento(false);
    this.confermaGaranzie(false);
    this.investimentoProdottiArray.clear();
    this.addProdProgetto();
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }

  avanti() {
    this.onClick(this.stepIndex + 1);
  }

  indietro() {
    this.onClick(this.stepIndex - 1);
  }

  goToBilancio() {
    /* salvataggio dati questionario prima di andare al bilancio */
    this.datiQuestionarioService.changeDatiQuestionario({
      questionario: this.formTotale,
      dataBusinessPlan: this.dataBusinessPlan,
    });
    let request = {
      ragioneSociale: this.anagraficaForm.controls.nomeAzienda.value,
      idTipoAziendaMcc: this.anagraficaForm.controls.tipoAzienda.value.id,
      partitaIva: this.anagraficaForm.controls.partitaIva.value,
      utente: this.tokenStorageService.getUser().username,
    };
    this.httpClient
      .post<any>(

        environment.apiUrlQuestionario +
        '/fasGoAlgo/getIntestatarioBilancio',
        request
      )
      .subscribe((data) => {
        this.bilancioService.changeParamBilancio({
          id: data.id,
          nome: data.ragioneSociale,
          azienda: data.idTipoAziendaMcc,
          bilancio: 'nuovo',
        });
        this.router.navigate(['/bilancio']);
      });
  }

  goToCr() {
    this.datiQuestionarioService.changeDatiQuestionario({
      questionario: this.formTotale,
      dataBusinessPlan: this.dataBusinessPlan,
    });
    this.router.navigate(['/presaincaricocr']);
  }

  /*   Salva() {
      if (this.nomeSalvataggio != '') {
        c('close modal');
        this.salvaQuestionario();
      }
    } */

  salvaQuestionario() {
    let businessPlan = {};

    let totaleInvestimento = 0;

    let investimentiArray: any[] = [];

    if (this.anagraficaForm.controls.nomeAzienda.value != '') {
      for (let i = 0; i < this.investimentoProdottiGroup.length; i++) {
        totaleInvestimento +=
          this.investimentoProdottiGroup[i].controls.importo.value != '' &&
            this.investimentoProdottiGroup[i].controls.importo.value != null
            ? parseFloat(
              this.investimentoProdottiGroup[i].controls.importo.value
            )
            : 0;
        let investimento = {
          idTipoInvestimento:
            this.investimentoProdottiGroup[i].controls.tipoProgetto.value,
          importoInvestimento:
            this.investimentoProdottiGroup[i].controls.importo.value == ''
              ? 0
              : parseFloat(
                this.investimentoProdottiGroup[i].controls.importo.value
              ),
          divisa: '€',
        };

        let investimentoTerreni = {
          idRegione:
            this.investimentoProdottiGroup[i].controls.provincia.value.idRegione,
          idProvincia:
            this.investimentoProdottiGroup[i].controls.provincia.value.id,
          idComune: this.investimentoProdottiGroup[i].controls.comune.value.id,
          ettari: this.investimentoProdottiGroup[i].controls.ettari.value,
          affittoTerreno:
            this.investimentoProdottiGroup[i].controls.affittoTerreno.value ==
              ''
              ? 0
              : this.investimentoProdottiGroup[i].controls.affittoTerreno.value,
        };

        let investimentoCompleto = {
          investimento: investimento,
          investimentoTerreni: investimentoTerreni,
          listaOccurs: [],
        };

        if (
          this.investimentoProdottiGroup[i].controls.coltureDivisioneArray &&
          (<UntypedFormArray>(
            this.investimentoProdottiGroup[i].controls.coltureDivisioneArray
          )).length > 0
        ) {
          let coltureDivisioneArray = <UntypedFormGroup[]>(
            (<UntypedFormArray>(
              this.investimentoProdottiGroup[i].controls.coltureDivisioneArray
            )).controls
          );
          for (let j = 0; j < coltureDivisioneArray.length; j++) {
            let occurs = {
              idCanaleDistribuzione:
                coltureDivisioneArray[j].controls.contratto.value.id,
              idCategoria: coltureDivisioneArray[j].controls.categoria.value.id,
              idProdotto: coltureDivisioneArray[j].controls.prodotto.value.id,
              idProdottoVarieta:
                coltureDivisioneArray[j].controls.varieta.value.id,
              idQualitaProduzione:
                coltureDivisioneArray[j].controls.produzione.value == 'serra'
                  ? 3
                  : 1,
              etaPiante:
                coltureDivisioneArray[j].controls.etaPiante.value == ''
                  ? 0
                  : coltureDivisioneArray[j].controls.etaPiante.value,
              ettari: coltureDivisioneArray[j].controls.ettariColtura.value,
              idOccursRiferimento: -1,
            };
            investimentoCompleto.listaOccurs.push(occurs);
            if (
              coltureDivisioneArray[j].controls.coltureRotazioneArray &&
              (<UntypedFormArray>(
                coltureDivisioneArray[j].controls.coltureRotazioneArray
              )).length > 0
            ) {
              let coltureRotazioneArray = <UntypedFormGroup[]>(
                (<UntypedFormArray>(
                  coltureDivisioneArray[j].controls.coltureRotazioneArray
                )).controls
              );
              let indiceRiferimento =
                investimentoCompleto.listaOccurs.length - 1;
              for (let k = 0; k < coltureRotazioneArray.length; k++) {
                let occursRotazione = {
                  idCanaleDistribuzione:
                    coltureRotazioneArray[k].controls.contratto.value.id,
                  idCategoria:
                    coltureRotazioneArray[k].controls.categoria.value.id,
                  idProdotto:
                    coltureRotazioneArray[k].controls.prodotto.value.id,
                  idProdottoVarieta:
                    coltureRotazioneArray[k].controls.varieta.value.id,
                  idQualitaProduzione: 1,
                  etaPiante: 0,
                  ettari: coltureDivisioneArray[j].controls.ettariColtura.value,
                  idOccursRiferimento: indiceRiferimento,
                };
                investimentoCompleto.listaOccurs.push(occursRotazione);
              }
            }
          }
        }

        investimentiArray.push(investimentoCompleto);
      }

      businessPlan = {
        id:
          this.riferimentoOld == this.nomeSalvataggio ? this.idBusinessPlan : 0,
        idTipoRichiedente:
          this.anagraficaForm.controls.tipoRichiedente.value.id,
        idTitoloStudio: this.anagraficaForm.controls.titoloStudio.value.id,
        ragioneSociale: this.anagraficaForm.controls.nomeAzienda.value,
        esperienzaSettore:
          this.anagraficaForm.controls.esperienza.value &&
            this.anagraficaForm.controls.esperienza.value != ''
            ? this.anagraficaForm.controls.esperienza.value
            : -1,
        importoInvestimento: totaleInvestimento,
        contributoFinanziamento: parseFloat(
          this.garanzieForm.controls.contributoFinanziamento.value
        ),
        contributoPersonale: parseFloat(
          this.garanzieForm.controls.contributoPersonale.value
        ),
        durataPreammortamento:
          this.garanzieForm.controls.durataAmmortamentoAnni.value,
        durataFinanziamentoAnni:
          this.garanzieForm.controls.durataFinanziamentoAnni.value,
        dichiarazioneIva: this.anagraficaForm.controls.dichiarazioneIVA.value,
        idIntestatarioBilancio:
          this.anagraficaForm.controls.bilancio.value == ''
            ? 0
            : this.anagraficaForm.controls.bilancio.value.idIntestatario,
        idTipoSocieta: this.anagraficaForm.controls.tipoAzienda.value.id,
        dataBilancio:
          this.anagraficaForm.controls.bilancio.value == ''
            ? ''
            : this.anagraficaForm.controls.bilancio.value.data,
        crPresente: this.anagraficaForm.controls.presenzaCr.value
          ? true
          : false,
        idIntestatarioCr:
          this.anagraficaForm.controls.centraleRischi.value == ''
            ? 0
            : this.anagraficaForm.controls.centraleRischi.value.id,
        dataCr:
          this.anagraficaForm.controls.centraleRischi.value == ''
            ? ''
            : this.anagraficaForm.controls.centraleRischi.value.data,
        idCodiceAteco:
          this.anagraficaForm.controls.ateco.value == ''
            ? 0
            : this.anagraficaForm.controls.ateco.value.id,
        partitaIva: this.anagraficaForm.controls.partitaIva.value,
        costiAnnoPrecedente:
          this.anagraficaForm.controls.costiAnnoPrecedente.value == ''
            ? 0
            : parseFloat(
              this.anagraficaForm.controls.costiAnnoPrecedente.value
            ),
        ricaviAnnoPrecedente:
          this.anagraficaForm.controls.ricaviAnnoPrecedente.value == ''
            ? 0
            : parseFloat(
              this.anagraficaForm.controls.ricaviAnnoPrecedente.value
            ),
        rigaVe50:
          this.anagraficaForm.controls.rigaVE50.value == ''
            ? 0
            : parseFloat(this.anagraficaForm.controls.rigaVE50.value),
        rigaVf14:
          this.anagraficaForm.controls.rigaVF14.value == ''
            ? 0
            : parseFloat(this.anagraficaForm.controls.rigaVF14.value),
        rigaVf23:
          this.anagraficaForm.controls.rigaVF23.value == ''
            ? 0
            : parseFloat(this.anagraficaForm.controls.rigaVF23.value),
        rigaVf27:
          this.anagraficaForm.controls.rigaVF27.value == ''
            ? 0
            : parseFloat(this.anagraficaForm.controls.rigaVF27.value),
        importoDebitoBt:
          this.anagraficaForm.controls.importoDebitoBT.value == ''
            ? 0
            : parseFloat(this.anagraficaForm.controls.importoDebitoBT.value),
        importoDebitoMt:
          this.anagraficaForm.controls.importoDebitoMT.value == ''
            ? 0
            : parseFloat(this.anagraficaForm.controls.importoDebitoMT.value),
        importoDebitoLt:
          this.anagraficaForm.controls.importoDebitoLT.value == ''
            ? 0
            : parseFloat(this.anagraficaForm.controls.importoDebitoLT.value),
        percGaranzia: this.garanzieForm.controls.presenzaGaranzie.value && this.garanzieForm.controls.percentualeGaranzia.value != '' ? this.garanzieForm.controls.percentualeGaranzia.value : 0,
        tipoGaranzia: this.garanzieForm.controls.presenzaGaranzie.value ? this.garanzieForm.controls.tipologiaGaranzia.value : '',
        dataAcquisizioneQuestionario: new Date().getTime(),
        divisa: '€',
        utente: this.tokenStorageService.getUser().username,
        descrizioneProgetto:
          this.investimentoForm.controls.descrizioneProgetto.value,
        idPresentazioneFileSys:
          this.investimentoForm.controls.presentazioneRadio.value &&
            this.investimentoForm.controls.presentazione.value
            ? this.investimentoForm.controls.presentazione.value.id
            : 0,
      };

      let praticaBp = {
        utente: this.tokenStorageService.getUser().username,
        riferimento: this.nomeSalvataggio,
        dataRegistrazione: new Date().getTime(),
        definitivo: false,
      };

      const formdata = new FormData();
      formdata.append('file', null);
      //  formdata: FormData = null;
      if (
        !this.investimentoForm.controls.presentazioneRadio.value &&
        this.fileUpload
      ) {
        formdata.set('file', this.fileUpload);
      }
      // else{
      //   formdata.append('file', '');
      // }

      let request = {
        businessPlan: businessPlan,
        praticaBp: praticaBp,
        listaInvestimenti: investimentiArray,
      };

      formdata.append(
        'body',
        new Blob([JSON.stringify(request)], { type: 'application/json' })
      );
      this.httpClient
        .post<any>(
          environment.apiUrlQuestionario + '/fasGoAlgo/saveBP',
          formdata
        )
        .subscribe((data) => {
          if (this.modalService.hasOpenModals) {
            this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
          }
          const modalRef = this.modalService.open(ModalcontentComponent, {
            centered: true,
            backdrop: 'static',
            backdropClass: 'customBackdrop',
          });
          modalRef.componentInstance.modalPar = {
            name: "messaggiosalva",
            description: "Business Plan salvato con successo!",
            undoButtonText: "Ok",
          }
          modalRef.result.then(r => {
            if (!r) {
              this.router.navigate(['/pratiche']);
            }
          })
        });
    }
  }

  openModal(): void {
    if (this.riferimentoOld != '') {
      this.nomeSalvataggio = this.riferimentoOld;
    } else if (this.anagraficaForm.controls.nomeAzienda.value) {
      const data = new Date();
      this.nomeSalvataggio =
        this.anagraficaForm.controls.nomeAzienda.value +
        data.getFullYear() +
        (data.getMonth() + 1) +
        data.getDate();
    }
    /* chiama modale */
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
    }
    const modalRef = this.modalService.open(ModalcontentComponent);
    modalRef.componentInstance.modalPar = {
      name: "salva",
      namePar: this.nomeSalvataggio,
      actionButtonText: "Salva",
      undoButtonText: "Indietro",
    }
    modalRef.result.then(r => {
      /* console.log(r.promise)
      console.log(r.nomesalvataggio) */
      if (r.promise) {
        this.nomeSalvataggio = r.nomesalvataggio;
        if (this.nomeSalvataggio != '') {
          this.salvaQuestionario();
        }
      }
    })

  }


}
