import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-bar-potenziometri',
  templateUrl: './top-bar-potenziometri.component.html',
  styleUrls: ['./top-bar-potenziometri.component.css']
})
export class TopBarPotenziometriComponent implements OnInit {

  @Input() name_potenziometro_one : string;
  @Input() name_potenziometro_two : string;
  @Input() name_potenziometro_three : string;
  @Input() name_potenziometro_four : string;
  @Input() name_potenziometro_five : string;
  @Input() name_potenziometro_six : string;

  @Input() lancetta_one : string;
  @Input() lancetta_two : string;
  @Input() lancetta_three : string;
  @Input() lancetta_four : string;
  @Input() lancetta_five : string;
  @Input() lancetta_six : string;

  
  @Input() rotate_dscr : string;
  @Input() rotate_debitoria : string;
  @Input() rotate_mcc : string;
  @Input() rotate_garanzia : string;
  @Input() rotate_dscr_mock1 : string;
  @Input() rotate_dscr_mock2 : string; 

  @Input() valore_tip_dscr : any;
  @Input() valore_tip_debitoria : any;
  @Input() valore_tip_mcc : any;
  @Input() valore_tip_garanzia : any;

  @Input() ancore : string[];

  tips_types : string[] = ['DSRC','Debitoria','MCC','Garanzie'];

  device : number[] = [1,2];

  constructor() { }

  ngOnInit(): void {
  }

}
