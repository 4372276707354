import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';


@Component({
  selector: 'app-bar-chart-indicatori-economici',
  templateUrl: './bar-chart-indicatori-economici.component.html',
  styleUrls: ['./bar-chart-indicatori-economici.component.css']
})
export class BarChartComponent implements OnInit, AfterViewInit {

  @Input() AscissaLabel: string[] = [];
  @Input() OrdinataLabel: number[] = [];

  @Input() numSimulazioni: number = 1000;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public barChartOptions: ChartConfiguration['options'] = {

    responsive: true,

    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 0,
        max: 1000,

      }
    },

  };
  public barChartType: ChartType = 'bar';


  public barChartData: ChartData<'bar'> = {
    labels: this.AscissaLabel,
    datasets: [
      { data: this.OrdinataLabel, label: 'Frequenza' }
    ]
  };

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    this.chart.data.labels = this.AscissaLabel;
    this.chart.data.datasets[0].data = this.OrdinataLabel;

    let xScale = this.chart.options.scales.x;
    let yScale = this.chart.options.scales.y;
    this.chart.options.scales = {
      x: {

      },
      y: {
        display: true,
        min: 0,
        max: this.selectUpperBoundY(this.OrdinataLabel.reduce(function (a, b) { return Math.max(a, b); }, -Infinity)),
        ticks: {
          // forces step size to be 50 units
          stepSize: 50
        }
      },
    };

    this.chart.update();
    this.chart.render();

    xScale = this.chart.options.scales.x;
    yScale = this.chart.options.scales.y;

    console.log(this.numSimulazioni);

  }

  selectUpperBoundY(valore: number): number {

    if (this.numSimulazioni == 0) {
      console.log('numero simulazioni non inizializato');
    }
    else {

      for (var i: number = 0; i <= this.numSimulazioni; i += 50) {

        if (i <= valore && (i + 50) >= valore) {
          return i + 50;
        }
      }

    }

    return this.numSimulazioni;
  }
}
