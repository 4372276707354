import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../loading.service';
import { AnalisiMccService } from '../_services/analisiMcc.service';
import { BilancioIntestatarioService } from '../_services/bilancio_intestatario.service';
import { CrService } from '../_services/cr.services';
import { TokenStorageService } from '../_services/token-storage.service';

@Component({
  selector: 'app-analisimcc',
  template: `
    <div class="container-fluid nopadding" style="margin-top: 35px">
      <div class="loading-container" *ngIf="isLoading">
        <div
          class="spinner-border text-primary"
          style="width: 50px; height: 50px"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <form [formGroup]="analisiMccForm" (ngSubmit)="analisiMcc()">
        <div class="col-12 nopadding">
          <div class="card nopadding">
            <div class="card-header text-center font-big">Analisi MCC e Centrale dei Rischi</div>
            <div class="card-body">

              <div class="row">
                <div class="col-4 col-lg-4 margin-bottom-20">
                  <label>Ragione sociale</label>
                  <input required type="text" formControlName="nomeAzienda" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nomeAzienda.errors }" />
                  <div *ngIf="submitted && f.nomeAzienda.errors" class="invalid-feedback">
                    <div *ngIf="f.nomeAzienda.errors.required">Campo obbligatorio</div>
                  </div>
                </div>

                <div class="col-4 col-lg-4 margin-bottom-20">
                  <label>Tipo Azienda</label>
                  <select formControlName="tipoAzienda" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tipoAzienda.errors }">
                  <option [ngValue]="tipoAzienda" *ngFor="let tipoAzienda of listaTipiAzienda">{{tipoAzienda.descrizione}}</option>
                  </select>
                  <div *ngIf="submitted && f.tipoAzienda.errors" class="invalid-feedback">
                    <div *ngIf="f.tipoAzienda.errors.required">Campo obbligatorio</div>
                  </div>
                </div>

                <div class="col-4 col-lg-4 margin-bottom-20">
                  <label>Partita IVA</label>
                  <input type="text" formControlName="partitaIva" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.partitaIva.errors }" />
                  <div *ngIf="submitted && f.partitaIva.errors" class="invalid-feedback">
                    <div *ngIf="f.partitaIva.errors.required">Campo obbligatorio</div>
                  </div>
                </div>   
              </div>

              <div class="row borderTThin margin-bottom-10">
                <div class="col-6 margin-top-10">

                  <div class="row">

                    <div class="col-5 col-lg-4 margin-bottom-10 margin-top-20">
                      <button class="btn btn-primary mr-1" type="button" (click)="goToBilancio()" [disabled]="!(f.nomeAzienda.value && f.tipoAzienda.value && f.partitaIva.value)">Inserisci dati bilancio</button>
                    </div>
                    <div class="col-5 col-lg-6 margin-bottom-10" *ngIf="listaBilanci.length > 0">
                      <label>Seleziona Bilancio</label>
                      <select formControlName="bilancio" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.bilancio.errors }">
                        <option [ngValue]="bilancio" *ngFor="let bilancio of listaBilanci">{{bilancio.descrizione}}</option>
                      </select>
                      <div *ngIf="submitted && f.bilancio.errors" class="invalid-feedback">
                        <div *ngIf="f.bilancio.errors.required">Campo obbligatorio</div>
                      </div>
                    </div>
                  
                  </div>
                </div>

                <div class="col-6 borderL margin-top-10">

                  <div class="row">
                    <div class="col-5 col-lg-4 margin-bottom-10 margin-top-20">
                      <button class="btn btn-primary mr-1" type="button" (click)="goToCr()" [disabled]="!(f.nomeAzienda.value && f.tipoAzienda.value && f.partitaIva.value)">Carica CR</button>
                    </div>
                    <div class="col-5 col-lg-6 margin-bottom-10" *ngIf="listaCr.length > 0">
                      <label>Seleziona CR</label>
                      <select formControlName="centraleRischi" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.centraleRischi.errors }">
                        <option [ngValue]="centraleRischi" *ngFor="let centraleRischi of listaCr">{{centraleRischi.descrizione}}</option>
                      </select>
                      <div *ngIf="submitted && f.centraleRischi.errors" class="invalid-feedback">
                        <div *ngIf="f.centraleRischi.errors.required">Campo obbligatorio</div>
                      </div>
                    </div>
                              
                  </div>
                </div>

                <div *ngIf="submitted && !((f.bilancio.value && f.bilancio.value.idIntestatario) || (f.centraleRischi.value && f.centraleRischi.value.id))" style="width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545;">
                  Inserire il bilancio dell'azienda e/o la Centrale dei Rischi
                </div>
              </div>
              
              <div class="col-12 text-center">
                <button class="btn btn-primary">Analizza</button>
                <button class="btn btn-secondary" style="margin-left: 5px" type="button" (click)="onReset()">
                  Annulla
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      

      <div class="row margin-0" *ngIf="risultatiCr || risultatiMcc">

        <div class="col-12 padding-left-right-15 padding-top-bottom-10 pointer borderAll text-center font-big margin-top-20" style="background-color: #92a8d1">
          Risultati
        </div>
        <div class="col-12 padding-top-bottom-10 borderR borderL borderB margin-bottom-20" style="background-color: white">
          <div class="row margin-0" *ngIf="risultatiMcc">
            <div class="col-12 text-center font-big font-bold margin-top-10">
              MCC
            </div>
            <div class="col-8">
              <div class="row row-cols-5 margin-0 text-center">
                <div class="col borderAllBlack min-height-25 margin-top-10 font-bold">Valutazione bilancio</div>
                <div class="col borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Valutazione CR</div>
                <div class="col borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Valutazione integrata </div>
                <div class="col borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Probabilit&agrave; di inadempimento</div>
                <div class="col borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Rischio di credito</div>
                <div class="col borderLBlack borderRBlack borderBBlack min-height-25">{{valutazioneBilancio.fasciaValutazione}}</div>
                <div class="col borderRBlack borderBBlack min-height-25">{{valutazioneCr.fasciaValutazione}}</div>
                <div class="col borderRBlack borderBBlack min-height-25">{{valutazioneMcc.valutazioneMcc}}</div>
                <div class="col borderRBlack borderBBlack min-height-25">{{valutazioneMcc.probInadempimentoPerc}} %</div>
                <div class="col borderRBlack borderBBlack min-height-25">{{valutazioneMcc.rischioCredito}} </div>

              </div>
            </div>
            <div class="col-4 nopadding" style="transform: translateY(50%)">
              <div class="progress margin-left-right-15">
                <div class="progress-bar bg-danger" role="progressbar" style="width: 33.333%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg-warning" role="progressbar" style="width: 33.333%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100"></div>
                <div class="progress-bar bg-success" role="progressbar" style="width: 33.333%" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="progress padding-left-right-15" style="background-color: white; height: 21px">
                <div class="progress-bar" role="progressbar" style="background-color: white" [ngStyle]="{'width': semaforoMcc + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                <fa-icon style="font-size: 30px; margin-top: -9px; margin-left: -9px; margin-right: -9px" [icon]="faCaretUp"></fa-icon>
              </div>
            </div>
            <div class="col-8 margin-top-20">

              <div class="row row-cols-4 margin-0 text-center">

                <div class="col borderAllBlack min-height-25 font-bold">Variabile</div>
                <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Valore</div>
                <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Cap</div>
                <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Floor</div>

                <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                  Debiti a breve/Fatturato
                </div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v1 == "NaN" ? '' : valutazioneBilancio.v1 | number: '1.0-2':'it'}}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? 1.4 : 0.7 | number: '1.0-2':'it'}}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? 0.4 : 0.25 | number: '1.0-2':'it'}}</div>

                <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                  Oneri Finanziari/MOL
                </div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v2 == "NaN" ? '' : valutazioneBilancio.v2 | number: '1.0-2':'it'}}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (1 | number: '1.0-2':'it') : "" }}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (-1 | number: '1.0-2':'it') : "" }}</div>

                <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                  Costo dei mezzi di terzi
                </div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v3 == "NaN" ? '' : valutazioneBilancio.v3 | number: '1.0-2':'it'}}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (0.06 | number: '1.0-2':'it') : "" }}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (0.01 | number: '1.0-2':'it') : "" }}</div>

                <div class="col-12 nopadding" *ngIf="analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1">
                  <div class="row margin-0 row-cols-4">

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Liquidit&agrave;/Fatturato
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v4 == "NaN" ? '' : valutazioneBilancio.v4 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (0.3 | number: '1.0-2':'it') : "" }}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (0.01 | number: '1.0-2':'it') : "" }}</div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Rotazione magazzino
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v5 == "NaN" ? '' : valutazioneBilancio.v5 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (11 | number: '1.0-2':'it') : "" }}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (1.4 | number: '1.0-2':'it') : "" }}</div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Variazione % Fatturato
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v6 == "NaN" ? '' : valutazioneBilancio.v6 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (0.6 | number: '1.0-2':'it') : "" }}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (-0.4 | number: '1.0-2':'it') : "" }}</div>
                  </div>
                </div>

                <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                  Indice di autonomia finanziaria
                </div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v7 == "NaN" ? '' : valutazioneBilancio.v7 | number: '1.0-2':'it'}}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (0.64 | number: '1.0-2':'it') : "" }}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1 ? (0 | number: '1.0-2':'it') : "" }}</div>

                <div class="col-12 nopadding" *ngIf="analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1">
                  <div class="row margin-0 row-cols-4">
                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Indice di indebitamento
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v8 == "NaN" ? '' : valutazioneBilancio.v8 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                  </div>
                </div>

                <div class="col-12 nopadding" *ngIf="analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1">
                  <div class="row margin-0 row-cols-4">
                 
                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Totale debiti/VDP
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v9 == "NaN" ? '' : valutazioneBilancio.v9 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Incidenza del Passivo Corrente
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v10 == "NaN" ? '' : valutazioneBilancio.v10 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                  </div>
                </div>

                <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                  Risultato netto/VDP
                </div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v11 == "NaN" ? '' : valutazioneBilancio.v11 | number: '1.0-2':'it'}}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                <div class="col-12 nopadding" *ngIf="analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1">
                  <div class="row margin-0 row-cols-4">
                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Copertura immobilizzazioni con Patrimonio Netto
                     </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v12 == "NaN" ? '' : valutazioneBilancio.v12 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Variazione % Valore della Produzione
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v13 == "NaN" ? '' : valutazioneBilancio.v13 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      MOL/(Oneri finanziari + Totale debiti)
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v14 == "NaN" ? '' : valutazioneBilancio.v14 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Quick ratio (Indicie di liquidità primario)
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v15 == "NaN" ? '' : valutazioneBilancio.v15 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Turnover
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v16 == "NaN" ? '' : valutazioneBilancio.v16 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                  </div>
                </div>

                <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                  MON/Totale debiti
                </div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v17 == "NaN" ? '' : valutazioneBilancio.v17 | number: '1.0-2':'it'}}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                <div class="col-12 nopadding" *ngIf="analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id == 1">
                  <div class="row margin-0 row-cols-4">
                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Oneri finanziari/Valore della produzione
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v18 == "NaN" ? '' : valutazioneBilancio.v18 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Incidenza debito
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v19 == "NaN" ? '' : valutazioneBilancio.v19 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                  </div>
                </div>

                <div class="col-12 nopadding" *ngIf="analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1">
                  <div class="row margin-0 row-cols-4">
                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Patrimonio Netto/Fatturato
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v20 == "NaN" ? '' : valutazioneBilancio.v20 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (3 | number: '1.0-2':'it') : "" }}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (0 | number: '1.0-2':'it') : "" }}</div>
                  </div>
                </div>

                <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                  Rotazione attivo circolante VDP
                </div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v21 == "NaN" ? '' : valutazioneBilancio.v21 | number: '1.0-2':'it'}}</div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>

                <div class="col-12 nopadding" *ngIf="analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1">
                  <div class="row margin-0 row-cols-4">
                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Leverage
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v22 == "NaN" ? '' : valutazioneBilancio.v22 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (40 | number: '1.0-2':'it') : "" }}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (0 | number: '1.0-2':'it') : "" }}</div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Elasticit&agrave; dell'Attivo
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v23 == "NaN" ? '' : valutazioneBilancio.v23 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (0.7 | number: '1.0-2':'it') : "" }}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (0 | number: '1.0-2':'it') : "" }}</div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Incidenza della gestione extracaratteristica
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v24 == "NaN" ? '' : valutazioneBilancio.v24 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (0.8 | number: '1.0-2':'it') : "" }}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (0.2 | number: '1.0-2':'it') : "" }}</div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      MOL/Oneri Finanziari
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v25 == "NaN" ? '' : valutazioneBilancio.v25 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (14 | number: '1.0-2':'it') : "" }}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{analisiMccForm.controls.tipoAzienda && analisiMccForm.controls.tipoAzienda.value.id != 1 ? (2 | number: '1.0-2':'it') : "" }}</div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Turnover VDP
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneBilancio.v26 == "NaN" ? '' : valutazioneBilancio.v26 | number: '1.0-2':'it'}}</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row margin-0" *ngIf="risultatiCr">
            <div class="col-12 text-center font-big font-bold margin-top-20">
              Centrale dei Rischi
            </div>
            <div class="col-8">
              <div class="row margin-0 text-center">

                <div class="borderAllBlack min-height-25 margin-top-10 font-bold" [ngClass]="[risultatiMcc ? 'col-4' : 'col-3']">Mesi sconfino rischi autoliquidanti</div>
                <div class="borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold" [ngClass]="[risultatiMcc ? 'col-4' : 'col-3']">Mesi sconfino rischi a scadenza </div>
                <div class="borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold" [ngClass]="[risultatiMcc ? 'col-4' : 'col-3']">Mesi sconfino rischi a scadenza </div>
                <div class="col-3 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold" *ngIf="!risultatiMcc">Valutazione MCC </div>
                <div class="borderLBlack borderRBlack borderBBlack min-height-25" [ngClass]="[risultatiMcc ? 'col-4' : 'col-3']">{{mesiSconfAutoLiq}}</div>
                <div class="borderRBlack borderBBlack min-height-25" [ngClass]="[risultatiMcc ? 'col-4' : 'col-3']">{{mesiSconfScad}}</div>
                <div class="borderRBlack borderBBlack min-height-25" [ngClass]="[risultatiMcc ? 'col-4' : 'col-3']">{{mesiSconfRev}}</div>
                <div class="col-3 borderRBlack borderBBlack min-height-25" *ngIf="!risultatiMcc">{{valutazioneCr.fasciaValutazione}}</div>

              </div>

            </div>

            <div class="col-12 margin-top-20">
              <div class="row margin-0">
                <div class="col-3">
                  <label>Mese</label>
                  <select id="meseCr" [(ngModel)]="meseCr" class="form-control" >
                    <option [ngValue]="i" *ngFor="let data of arrayDateCr; let i = index">{{data | date: 'MMMM yyyy' : 'it'}}</option>
                  </select>
                </div>

                <div class="col-9">
                  <div class="row row-cols-6 margin-0 text-center">

                    <div class="col borderRBlack borderBBlack min-height-25 font-bold"></div>
                    <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Accordato</div>
                    <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Accordato operativo</div>
                    <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Utilizzato</div>
                    <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Percentuale utilizzato</div>
                    <div class="col borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Sconfino</div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Rischi autoliquidanti
                      <span class="pull-right pointer" (click)="boolAutoLiq = !boolAutoLiq"><fa-icon style="font-size: 24px" [icon]="boolAutoLiq? faCaretUp : faCaretDown"></fa-icon></span>
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.accordatoAutoLiq[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.accOpAutoLiq[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.utiAutoLiq[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': valutazioneCr.utiAutoLiq[meseCr]/valutazioneCr.accOpAutoLiq[meseCr] > 0.85 ? dangerColor : 'black'}">{{valutazioneCr.accOpAutoLiq[meseCr] != 0 ? ((valutazioneCr.utiAutoLiq[meseCr]/valutazioneCr.accOpAutoLiq[meseCr])*100 | number: '1.0-1':'it') : 0}} %</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': valutazioneCr.sconfAutoLiq[meseCr] > 0 ? dangerColor : 'black'}">{{valutazioneCr.sconfAutoLiq[meseCr] | number: '1.0-0':'it'}} €</div>

                    <div class="col-12 nopadding" *ngIf="boolAutoLiq">
                      <div class="row margin-0 row-cols-6" *ngFor="let elemento of filterByMonth(valutazioneCr.autoLiqPerBanca)">
                        <div class="col borderLBlack borderRBlack borderBBlack min-height-25">
                          {{elemento.banca}}
                        </div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.accordato | number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.accOperativo | number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.utilizzato | number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': elemento.utilizzato/elemento.accOperativo > 0.85 ? dangerColor : 'black'}">{{elemento.accOperativo != 0 ? ((elemento.utilizzato/elemento.accOperativo)*100 | number: '1.0-1':'it') : 0}} %</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': elemento.sconfino  > 0 ? dangerColor : 'black'}">{{elemento.sconfino | number: '1.0-0':'it'}} €</div>
                      </div>
                    </div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Rischi a scadenza
                      <span class="pull-right pointer" (click)="boolScad = !boolScad"><fa-icon style="font-size: 24px" [icon]="boolScad? faCaretUp : faCaretDown"></fa-icon></span>
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.accordatoScad[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.accOpScad[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.utiScad[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': valutazioneCr.utiScad[meseCr]/valutazioneCr.accOpScad[meseCr] > 0.75 ? dangerColor : 'black'}">{{valutazioneCr.accOpScad[meseCr] != 0 ? ((valutazioneCr.utiScad[meseCr]/valutazioneCr.accOpScad[meseCr])*100 | number: '1.0-1':'it') : 0}} %</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': valutazioneCr.sconfScad[meseCr] > 0 ? dangerColor : 'black'}">{{valutazioneCr.sconfScad[meseCr] | number: '1.0-0':'it'}} €</div>

                    <div class="col-12 nopadding" *ngIf="boolScad">
                      <div class="row margin-0 row-cols-6" *ngFor="let elemento of filterByMonth(valutazioneCr.scadPerBanca)">
                        <div class="col borderLBlack borderRBlack borderBBlack min-height-25">
                          {{elemento.banca}}
                        </div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.accordato | number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.accOperativo | number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.utilizzato | number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': elemento.utilizzato/elemento.accOperativo > 0.85 ? dangerColor : 'black'}">{{elemento.accOperativo != 0 ? ((elemento.utilizzato/elemento.accOperativo)*100 | number: '1.0-1':'it') : 0}} %</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': elemento.sconfino  > 0 ? dangerColor : 'black'}">{{elemento.sconfino | number: '1.0-0':'it'}} €</div>
                      </div>
                    </div>

                    <div class="col borderLBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-space-between">
                      Rischi a revoca
                      <span class="pull-right pointer" (click)="boolRev = !boolRev"><fa-icon style="font-size: 24px" [icon]="boolRev? faCaretUp : faCaretDown"></fa-icon></span>
                    </div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.accordatoRev[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.accOpRev[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{valutazioneCr.utiRev[meseCr] | number: '1.0-0':'it'}} €</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': valutazioneCr.utiRev[meseCr]/valutazioneCr.accOpRev[meseCr] > 1 ? dangerColor : 'black'}">{{valutazioneCr.accOpRev[meseCr] != 0 ? ((valutazioneCr.utiRev[meseCr]/valutazioneCr.accOpRev[meseCr])*100 | number: '1.0-1':'it') : 0}} %</div>
                    <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': valutazioneCr.sconfRev[meseCr] > 0 ? dangerColor : 'black'}">{{valutazioneCr.sconfRev[meseCr] | number: '1.0-0':'it'}} €</div>
                  

                    <div class="col-12 nopadding" *ngIf="boolRev">
                      <div class="row margin-0 row-cols-6" *ngFor="let elemento of filterByMonth(valutazioneCr.revPerBanca)">
                        <div class="col borderLBlack borderRBlack borderBBlack min-height-25">
                          {{elemento.banca}}
                        </div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.accordato| number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.accOperativo | number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center">{{elemento.utilizzato | number: '1.0-0':'it'}} €</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': elemento.utilizzato/elemento.accOperativo > 1 ? dangerColor : 'black'}">{{elemento.accOperativo != 0 ? ((elemento.utilizzato/elemento.accOperativo)*100 | number: '1.0-1':'it') : 0}} %</div>
                        <div class="col borderRBlack borderBBlack min-height-25 flex-justify-center" [ngStyle]="{'color': elemento.sconfino  > 0 ? dangerColor : 'black'}">{{elemento.sconfino | number: '1.0-0':'it'}} €</div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>     
    </div>

  `,
  styles: [
  ]
})
export class AnalisimccComponent implements OnInit {

  analisiMccForm: UntypedFormGroup;
  listaBilanci: any[] = [];
  listaCr: any[] = [];
  listaTipiAzienda: any[] = [];
  isLoading: boolean = false;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  valutazioneMcc: any = {};
  semaforoMcc = 0;
  valutazioneBilancio: any = {};
  valutazioneCr: any = {};
  mesiSconfAutoLiq = 0;
  mesiSconfScad = 0;
  mesiSconfRev = 0;
  arrayDateCr: Date[] = [];
  risultatiMcc = false;
  risultatiCr = false;
  submitted = false;
  meseCr = 0;
  dangerColor = '#dc3545';
  initialValues: any;

  constructor(private httpClient: HttpClient, private tokenService: TokenStorageService, private router: Router,
    private formBuilder: UntypedFormBuilder, private loading: LoadingService, private bilancioService: BilancioIntestatarioService,
    private crService: CrService, private analisiMccService: AnalisiMccService) {
    this.analisiMccForm = this.formBuilder.group({
      nomeAzienda: ['', Validators.required],
      tipoAzienda: ['', Validators.required],
      partitaIva: [''],
      bilancio: [''],
      centraleRischi: ['']
    })
    this.initialValues = this.analisiMccForm.value;
  }

  ngOnInit(): void {

    this.listenToLoading();

    this.analisiMccService.currentDatiAnalisiMcc.subscribe((result) => {
      if (result && result.analisiMcc != null) {
        this.analisiMccForm = result.analisiMcc;
      }
    });

    this.crService.currentParamCr.subscribe(cr => {
      if (cr && cr.id_intestatario) {
        this.f.centraleRischi.setValue({ codiceIntestatario: cr.id_intestatario, data: cr.data_inserimento });
      }
    });

    this.bilancioService.currentParamBilancio.subscribe(bilancio => {
      if (bilancio && bilancio.id_intestatario) {
        this.f.bilancio.setValue({ idIntestatario: bilancio.id_intestatario, data: bilancio.data_inserimento });
      }
    });

    this.analisiMccService.currentSubmitted.subscribe(submitted => this.submitted = submitted)


    this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getBilanciRichiedente', this.tokenService.getUser().username).subscribe(data => {
      this.listaBilanci = [];
      data.forEach(element => {
        let elementoLista = { idIntestatario: element.intestatario.id, data: element.bilancio.dataInserimento, descrizione: element.intestatario.ragioneSociale + " - " + element.bilancio.dataUltimaModifica };
        this.listaBilanci.push(elementoLista)
        if (this.f.bilancio.value != '' && elementoLista.data == this.f.bilancio.value.data && elementoLista.idIntestatario == this.f.bilancio.value.idIntestatario) this.f.bilancio.setValue(elementoLista);
      });
    });

    this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/intermediari/getListaCr', this.tokenService.getUser().username).subscribe(data => {
      this.listaCr = [];
      data.forEach(element => {
        let elementoLista = { id: element.id, data: element.data_registrazione, descrizione: element.cognome ? element.cognome + " - " + element.data_registrazione : element.nome + " - " + element.data_registrazione };
        this.listaCr.push(elementoLista)
        if (this.f.centraleRischi.value != '' && element.data_registrazione == this.f.centraleRischi.value.data && (element.id == this.f.centraleRischi.value.id || element.codice == this.f.centraleRischi.value.codiceIntestatario)) this.f.centraleRischi.setValue(elementoLista);
      });
    });

    this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda').subscribe(data => {
      this.listaTipiAzienda = data;
      if (this.f.tipoAzienda.value != '') {
        this.listaTipiAzienda.forEach(element => {
          if (element.id == this.f.tipoAzienda.value.id) this.f.tipoAzienda.setValue(element);
        });
      }
    });



  }

  // convenience getter for easy access to form fields
  get f() { return this.analisiMccForm.controls; }

  /**
 * Listen to the loadingSub property in the LoadingService class. This drives the
 * display of the loading spinner.
 */
  listenToLoading(): void {
    this.loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.isLoading = loading;
      });
  }

  filterByMonth(array: any) {
    return array.filter((elem: any) => elem.mese == this.meseCr);
  }

  goToBilancio() {
    this.analisiMccService.changeDatiAnalisiMcc({
      analisiMcc: this.analisiMccForm,
    });
    let request = {
      ragioneSociale: this.f.nomeAzienda.value,
      idTipoAziendaMcc: this.f.tipoAzienda.value.id,
      partitaIva: this.f.partitaIva.value,
      utente: this.tokenService.getUser().username,
    };
    this.httpClient
      .post<any>(

        environment.apiUrlQuestionario +
        '/fasGoAlgo/getIntestatarioBilancio',
        request
      )
      .subscribe((data) => {
        this.bilancioService.changeParamBilancio({
          id: data.id,
          nome: data.ragioneSociale,
          azienda: data.idTipoAziendaMcc,
          bilancio: 'nuovo',
        });
        this.router.navigate(['/bilancio']);
      });
  }

  goToCr() {
    this.analisiMccService.changeDatiAnalisiMcc({
      analisiMcc: this.analisiMccForm,
    });
    this.router.navigate(['/presaincaricocr']);
  }

  analisiMcc() {
    this.submitted = true;
    const crPresente = this.analisiMccForm.controls.centraleRischi.value && this.analisiMccForm.controls.centraleRischi.value.id;
    const bilancioPresente = this.analisiMccForm.controls.bilancio.value && this.analisiMccForm.controls.bilancio.value.idIntestatario;
    if (this.analisiMccForm.valid) {
      if (bilancioPresente) {
        const requestBilancio = {
          idIntestatarioBilancio: this.analisiMccForm.controls.bilancio.value.idIntestatario,
          idTipoSocieta: this.analisiMccForm.controls.tipoAzienda.value.id,
          dataBilancio: this.analisiMccForm.controls.bilancio.value.data,
          idIntestatarioCr: crPresente ? this.analisiMccForm.controls.centraleRischi.value.id : 0,
        }

        this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/analisiBilancio', requestBilancio).subscribe(data => {
          this.valutazioneMcc = data.valutazioneMcc;
          this.semaforoMcc = (this.valutazioneMcc.valutazioneMcc == 'UN' ? 0 : 100 - (parseInt(this.valutazioneMcc.valutazioneMcc) - 1) * 100. / 11.)
          this.valutazioneBilancio = data.valutazioneBilancio;
          this.valutazioneCr = data.valutazioneCr;
          this.risultatiMcc = true;
          this.risultatiCr = crPresente;
          if (crPresente) {
            this.mesiSconfAutoLiq = 0;
            this.mesiSconfScad = 0;
            this.mesiSconfRev = 0;
            this.arrayDateCr = [];
            for (let i = 0; i < this.valutazioneCr.numeroMesi; i++) {
              this.arrayDateCr.push(new Date(this.valutazioneCr.annoMax, this.valutazioneCr.meseMax - 1 - i, 15));
              this.valutazioneCr.sconfAutoLiq[i] > 0 ? this.mesiSconfAutoLiq++ : '';
              this.valutazioneCr.sconfScad[i] > 0 ? this.mesiSconfScad++ : '';
              this.valutazioneCr.sconfRev[i] > 0 ? this.mesiSconfRev++ : '';
            }
          }

        })
      } else if (crPresente) {
        this.httpClient.post<any>(environment.apiUrlQuestionario + '/intermediari/analisi', this.analisiMccForm.controls.centraleRischi.value.id).subscribe(data => {
          this.valutazioneCr = data;
          this.risultatiMcc = false;
          this.risultatiCr = true;
          this.mesiSconfAutoLiq = 0;
          this.mesiSconfScad = 0;
          this.mesiSconfRev = 0;
          this.arrayDateCr = [];
          for (let i = 0; i < this.valutazioneCr.numeroMesi; i++) {
            this.arrayDateCr.push(new Date(this.valutazioneCr.annoMax, this.valutazioneCr.meseMax - 1 - i, 15));
            this.valutazioneCr.sconfAutoLiq[i] > 0 ? this.mesiSconfAutoLiq++ : '';
            this.valutazioneCr.sconfScad[i] > 0 ? this.mesiSconfScad++ : '';
            this.valutazioneCr.sconfRev[i] > 0 ? this.mesiSconfRev++ : '';
          }

        })
      }
    }
  }

  onReset() {
    this.analisiMccForm.reset(this.initialValues);
    this.mesiSconfAutoLiq = 0;
    this.mesiSconfScad = 0;
    this.mesiSconfRev = 0;
    this.arrayDateCr = [];
    this.valutazioneCr = {};
    this.valutazioneMcc = {};
    this.valutazioneBilancio = {};
    this.risultatiCr = false;
    this.risultatiMcc = false;
    this.submitted = false;
  }
/*
  provaPython(){
    this.httpClient.get<any>(environment.apiUrlQuestionario + '/file-system/provaPython').subscribe(data => {
    })
  }
  */
}
