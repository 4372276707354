import { ActivatedRoute, Router } from '@angular/router';
import { BilancioIntestatarioService } from './../_services/bilancio_intestatario.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BilancioService } from '../_services/bilancio.service';
import { Bilancio } from './../component/bilancio';
import { Component, OnInit, HostListener, Inject, ViewChild } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { CommonModule, CurrencyPipe, DOCUMENT, formatNumber, ViewportScroller } from '@angular/common';
import { Intestatario } from '../component/intestatario';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { Location } from '@angular/common';

registerLocaleData(localeIt, 'it')

@Component({
  selector: 'app-bilancio',
  template: `
    <div class="container">
      <h3 class="text-center mb-1">Dati di Bilancio</h3>
      <h4 class="text-center mb-2 blu">{{ nomeInt }}</h4>
      <app-spinner *ngIf="!bilancio"></app-spinner>
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Stato Patrimoniale</a>
              <ng-template ngbNavContent>
        <form #f="ngForm" class="p-2" name="form" (ngSubmit)="onSubmit()" novalidate>
        <div class="row">
          <div class="col-sm-6"></div>
          </div>
                <div class="row animated fadeInDown mt-3 mb-3 bordo-sb" id="navbar" style="z-index: 1000; ">
                  <div class="col-sm-3 mt-n2">
                    <button class="ms-auto me-1 btn btn-primary">{{isNuovo ? 'Registra': 'Modifica'}}</button>
                    <button type="button" class="ms-auto me-1 btn btn-success" (click)="svuota()">Svuota</button>
                  </div>
                  <div class="col-sm-3 mt-n2">
                    <h4><em>Stato Patrimoniale</em></h4>
                    </div>
                  <div class="col-sm-1 text-right mt-2">
                    <label for="item_esercizi">Esercizi :</label>
                  </div>
                  <div class="col-sm-2">
                  <select class="btn dropdown-toggle border" style="width: 113px"
                  [(ngModel)]="showForm.item_esercizio1" name="item_esercizio1"
                  (ngModelChange)="scegliAnno($event)">
                  <option *ngFor="let a of anniBilancio" [ngValue]="a">
                    {{a}}
                  </option>
                  </select>
                    <!--
                    <input type="text" class="form-control" name="item_esercizio1" [(ngModel)]="showForm.item_esercizio1"
                    minlength="4" maxlength="4"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="getAnnoSp(showForm.item_esercizio1, $event)"
                      required placeholder="Anno corrente" style="max-width: 70%"/>
                    -->
                  </div>
                  <div class="col-sm-2">
                    <input type="text" class="form-control" name="item_esercizio2" [(ngModel)]="showForm.item_esercizio2"
                      required  disabled style="max-width: 70%"/>
                  </div>
                  <div class="col-sm-1"></div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                  <div class="row form-group mt-1" style="margin-bottom: 15px">
                    <h5><em>Attivo</em></h5>
                  </div>
                    <ng-container *ngFor="let item of bilancio; index as i">
                      <div class="row form-group mt-1" style="margin-bottom: 15px" *ngIf="item.spce == 'SP' && item.formula == 15"><!--La scritta passivo sta sopra patrimonio netto, sp15-->
                      <h5><em>Passivo</em></h5>
                      </div>
                      <div class="row form-group mt-1" *ngIf="item.spce == 'SP'">
                        <div class="col-sm-7">
                        <i id="icon" class="fa fa-1x fa-info-circle"
                            aria-hidden="true" placement="top" ngbTooltip={{item.descrizione_operativa}}></i>&nbsp;&nbsp;
                          <label for="item_{{ i }}" [ngClass]="arrayDisabled[i] == true? 'grassetto': ''">{{ item.descrizione }} :</label>
                        </div>
                        <div class="col-sm-2">
                          <input type="number" class="form-control" value="0.00"
                            placeholder="0.00" step="0.01" name="item1_{{ item.spce + item.formula }}"
                            [(ngModel)]="formSp['item1_' + item.spce + item.formula]" required
                            [ngModelOptions]="{ updateOn: 'blur' }"
                            (ngModelChange)="getValoreSp()"
                            [disabled]="arrayDisabled[i]"
                            #item1_{{item.spce+item.formula}} ="ngModel"
                            style="max-width: 70%"/>

                            <!--
                            <div [hidden]="formSp['item1_' + item.spce + item.formula].valid || formSp['item1_' + item.spce + item.formula].pristine"
                            [ngClass] = "'error'">
                             >
                        </div>
                        <div *ngIf="item1.errors?.pattern" [ngClass] = "'errors'">

                            </div>
                         -->
                        </div>
                        <div class="col-sm-2">
                          <input type="number" class="form-control" value="0.00"
                            placeholder="0.00" step="0.01" name="item2_{{ item.spce + item.formula }}"
                            [(ngModel)]="formSp['item2_' + item.spce + item.formula]" required
                            [ngModelOptions]="{ updateOn: 'blur' }"
                            (ngModelChange)="getValoreSp()"
                            [disabled]="arrayDisabled[i]"
                            #item2_{{item.spce+item.formula}}="ngModel" style="max-width: 70%"/>

                        </div>
                        <div class="col-sm-1"></div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                </form>
              </ng-template>
            </li>

            <li [ngbNavItem]="2">
              <a ngbNavLink>Conto Economico</a>
              <ng-template ngbNavContent>
              <form #f="ngForm" class="p-2" name="form" (ngSubmit)="onSubmit()" novalidate>
                <div class="row animated fadeInDown mt-3 mb-3 bordo-sb" id="navbar" style="z-index: 1000;">
                  <div class="col-sm-3 mt-n2">
                    <button class="ms-auto me-1 btn btn-primary">{{isNuovo ? 'Registra': 'Modifica'}}</button>
                    <button type="button" class="ms-auto me-1 btn btn-success" (click)="svuota()">Svuota</button>
                  </div>
                  <div class="col-sm-3 mt-n2">
                    <h4><em>Conto Economico</em></h4>
                    </div>
                  <div class="col-sm-1 text-right mt-2">
                    <label for="item_esercizi">Esercizi :</label>
                  </div>
                  <div class="col-sm-2">
                    <select class="btn dropdown-toggle border" style="width: 113px"
                    [(ngModel)]="showForm.item_esercizio1" name="item_esercizio1"
                    (ngModelChange)="scegliAnno($event)">
                      <option *ngFor="let a of anniBilancio" [ngValue]="a">
                        {{a}}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-2">
                    <input type="text" class="form-control" name="item_esercizio2" [(ngModel)]="showForm.item_esercizio2"
                      required   disabled style="max-width: 70%"/>
                  </div>
                  <div class="col-sm-1"></div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <ng-container *ngFor="let item of bilancio; index as i">
                      <div class="row form-group mt-1" *ngIf="item.spce == 'CE'">
                        <div class="col-sm-7">
                          <i id="icon" class="fa fa-1x fa-info-circle"
                            aria-hidden="true" placement="top" ngbTooltip={{item.descrizione_operativa}}></i>&nbsp;&nbsp;
                          <label for="item_{{ i }}" [ngClass]="arrayDisabled[i] == true? 'grassetto': ''">{{ item.descrizione }} :</label>
                        </div>
                        <div class="col-sm-2">
                          <input type="number" class="form-control" value="0.00" placeholder="0.00" step="0.01"
                            name="item1_{{ item.spce + item.formula }}"
                            [(ngModel)]="formCe['item1_' + item.spce + item.formula]"
                            [ngModelOptions]="{ updateOn: 'blur' }"
                            (ngModelChange)="getValoreCe()"
                            [disabled]="arrayDisabled[i]"
                            required #item1_{{item.spce+item.formula}}="ngModel" style="max-width: 70%"/>
                        </div>
                        <div class="col-sm-2">
                          <input type="number" class="form-control" value="0.00" placeholder="0.00" step="0.01"
                            name="item2_{{ item.spce + item.formula }}"
                            [(ngModel)]="formCe['item2_' + item.spce + item.formula]"
                            [ngModelOptions]="{ updateOn: 'blur' }"
                            (ngModelChange)="getValoreCe()"
                            [disabled]="arrayDisabled[i]"
                            required #item2_{{item.spce+item.formula}}="ngModel" style="max-width: 70%"/>
                        </div>
                        <div class="col-sm-1"></div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                </form>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>

        <div class="row">
          <div class="col-sm-12">
            <button *ngIf="active == 2" type="button" class="ml-1 btn btn-secondary" (click)="previous()" >
              Stato Patrimoniale
            </button>
            <button *ngIf="active !== 2" type="button" class="ml-auto mr-1 btn btn-secondary" (click)="next()">
              Conto Economico
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center" style="padding-top:20px">
             <button class="btn btn-secondary" type="button" (click)="tornaNoParam()">Torna indietro</button>
          </div>
        </div>

    </div>
        <!-- modale Qualità -->
        <ng-template #content let-c="close" let-d="dismiss">
          <div class="modal-header bg-danger">
            <h4 class="modal-title text-center" id="modal-basic-title">
              Controllo qualità esercizio
            </h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="d('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 *ngIf="isAnno1">Esercizio {{ this.annoModal1 }}</h5>
            <span *ngFor="let m of msg;">
             <li>{{ m }}</li>
            </span>

            <h5 style="margin-top: 10px;" *ngIf="isAnno2">Esercizio {{ this.annoModal2 }}</h5>
            <span *ngFor="let m of msg2;">
             <li>{{ m }}</li>
            </span>
            <div class="modal-footer">
            <div class="col-md-12 d-grid gap-2 text-center">
            <button
                type="button"
                class="btn btn-outline-dark"
                (click)="salvaBilancio(c)"
              >
                Vuoi salvare lo stesso?
              </button>
              <button
                type="button"
                class="btn btn-outline-dark"
                (click)="c('Close click')"
              >
                Chiudi
              </button>
              </div>
            </div>
          </div>
          <!-- Modale messaggio
          <ng-template #content let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="refresh()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          Categoria Inserita Correttamente!
          <fa-icon [icon]="faCheck" class="fa-2x"></fa-icon>
        </p>
      </div>
    </ng-template>
        -->
        </ng-template>
        <ng-template #messaggio let-c="close" let-d="dismiss">
          <div class="modal-header bg-success">
            <h4 class="modal-title text-center" id="modal-basic-title">
            </h4>

          </div>
          <div class="modal-body">
          <h5>
            <p class="text-center">
              {{msgErr}}
            </p>
            </h5>
            <div class="modal-footer text-center">
              <button
                type="button"
                class="btn btn-outline-dark"
                (click)="ritornaIndietro()">
                <i class="fa fa-check fa-fw" aria-hidden="true"></i>
                Ok
              </button>
            </div>
          </div>
        </ng-template>
  `,
  styles: [`

    form {
      line-height: 0.8em;
    }
  .alert-error{
   border:1px solid red;
   color:red;
  }
  .red-border-class
    {
      border-left: 5px solid #a94442;
    }
    /*
    .errors:not(form)  {
      border-left: 5px solid #a94442;
    }
    */

/*
   input.ng-invalid.ng-touched {
  border: 1px solid red;
}
    .ng-valid:not(form), .ng-valid.required {
     border-left: 5px solid #42A948;
    }
  */
    label {
      font-size: 15px;
    }
    input {
      width: 80%;
      font-size: 13px;
    }
    .allinea-dx {
      text-align: right;
    }
    .blu {
      color: blue;
    }
    .bordo-sb {
      padding-top: 3px;
      padding-bottom: 3px;
      border-bottom: 1px solid #059b5b;
    }
    hr {
      background-color: #059b5b;
      height: 1px;
      border: 0;
    }
    .top-titolo {
      color: #059b5b;
      font-weight: 600;
    }
    .select-large {
      width: 100%;
    }

    .grassetto {
      font-weight: bold;
    }

    .sticky {
      /*background-image: url('./assets/img/sfondonav.png'); */
      background-color: #f8f9fa;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      height: 50px;
      padding-right: 3px;
      padding: 5;
      margin: 0;
      width: 100%;
    }
    .container {
      background-color: #fff;
      border-radius: 5px;
    }

    `,
  ],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 100 })),
      transition(':enter', [animate(100)]),
      transition(':leave', [animate(300)]),
    ]),
  ],
})
export class BilancioComponent implements OnInit {
  pageYoffset = 0; //scroll inizio pagina
  @HostListener('window:scroll', ['$event']) onScroll(event: Event){
    this.pageYoffset = window.pageYOffset;
 }
//fine
  @ViewChild('content') content: any;
  @ViewChild('messaggio') messaggio: any;

  active = 1;
  items: string ='';
  nomeInt = 'Nome Intestatario';
  showForm: any = {};
  isSuccessful = false;
  bilancio: Bilancio[] = [];
  intestatario: Intestatario[] = [];
  msg: string[] = [];
  msg2: string[] = [];
  formSp: any = {};
  formCe: any = {};
  errorMessage = '';
  stato: string = '';
  societa: string = '';
  err = false;
  annoModal1: String = '';
  annoModal2: String = '';
  isDisabled = false;
  isSignUpFailed= false;
  arrayDisabled: boolean[] = [];
  isSelect =  true;
  tipo_societa = 0;
  isNuovo = false;
  anniBilancio: any[] = [];
  isAnno1 = false;
  isAnno2 = false;
  //form: any = {nome: null, cognome: null, codice_fiscale: null, mail: null};
  id_intestatario = 0;
  data_inserimento = '';
  modalOptions: NgbModalOptions;
  msgErr = '';

  constructor(
    private bilancioService: BilancioService,
    //@Inject(DOCUMENT) document,
    private modalService: NgbModal,
    private scroll: ViewportScroller,
    private bilancioIntService: BilancioIntestatarioService,
    private router: Router,
    private location: Location
    ) {
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop',
      animation: true,
      centered: true,
      keyboard: true,
      scrollable: true
    }
  }

  ngOnInit(): void {
    const anno = new Date().getFullYear() - 1;
    this.showForm.item_esercizio1 = anno;
    this.showForm.item_esercizio2 = anno - 1;
    this.anniBilancio.push(anno, anno -1 );
    this.formSp = {};
    this.formCe = {};
    this.isNuovo = false;
    this.bilancioIntService.currentParamBilancio
      .subscribe(params  => {
        if(params != null && params != undefined) {
          this.id_intestatario = params.id;
          this.nomeInt =  params.nome;
          this.tipo_societa = params.azienda;
          if(params.bilancio == 'nuovo') this.isNuovo = true;
          this.listaBilancio();
          if(params.bilancio == 'modifica') {
              this.isNuovo = false;
              this.data_inserimento = params.data;
           }
        }
      });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e: Event) {
    if (window.pageYOffset > 150) {
      let element = document.getElementById('navbar');
      if(element)  element.classList.add('sticky');
    } else {
      let element = document.getElementById('navbar');
      if(element)  element.classList.remove('sticky');
    }
  }

  next = () => {
    if (this.active < 2) this.active = this.active + 1;
    this.scroll.scrollToPosition([0,0]); //scroll inizio pagina
  };

  previous = () => {
    if (this.active > 1) this.active = this.active - 1;
    this.scroll.scrollToPosition([0,0]); // scroll inizio pagina
  };
  /*
  selezione(evento: number):void {
  //  console.log(evento);
    this.tipo_societa = evento;
    this.listaBilancio();
  }
  */
  scegliAnno(event: any) {
    this.showForm.item_esercizio2 = event - 1;
  }
  getAnnoSp(value: any, event: any): void
  {
    /*
    const regex = /^\d{4}$/;
    const found = value.match(regex);
    console.log(found);
    */
   const anno = parseInt(value, 10);
   if (isNaN(anno)){ console.log('non è numero');}
   let s = parseInt(value.substring(0,2),10);
   let n = parseInt(value.substring(2,4),10);
   console.log(s);
   if (s < 20) console.log('< 2020');
   if (n < 15) console.log('< 2015');
   //console.log(uno);
   console.log(anno);
  }
  getValoreSp()
  {
    //this.formSp[value] =  parseFloat(this.formSp[value]);
    //this.formSp[value] = this.formSp[value].trim().toString().replace(/^\s+|\s+$/g,'');
   // "^[0-9]*$"
   //this.formSp[value] = this.formSp[value].replace(/^\s+|\s+$/g,'');
    //console.log('Campo : '+this.formSp[value]);
    //if(Number.isNaN(this.formSp[value])) this.formSp[value] = 0.0;
    //console.log(typeof this.formSp[value]);
    //this.formSp[value] = this.currencyPipe.transform(this.formSp[value],'EUR','4.2-4','it-IT');
    //this.formSp[value] =  parseFloat(this.formSp[value]).toFixed(2);
    //this.formSp[value] = formatNumber(this.formSp[value],'it-IT','.2-2');
    //this.formSp[value] = parseFloat(this.formSp[value] || 0);
   // this.formSp[value] = new Intl.NumberFormat('it-IT', { style: 'currency', currency: ''}).format(this.formSp[value]);
    this.somma_totali();
   //formatNumber(value: number, locale: string, digitsInfo?: string)
    //this.formSp[value] =  parseFloat(this.formSp[value]).toFixed(2);

  }
  getValoreCe()
  {
   // this.formCe[value] = parseFloat(this.formSp[value] || 0);
  //  this.formCe[value] =  parseFloat(this.formCe[value]);
    this.somma_totali();
   //formatNumber(value: number, locale: string, digitsInfo?: string)
    //this.formCe[value] =  parseFloat(this.formCe[value]).toFixed(2);

  //this.formCe[value] = this.currencyPipe.transform(this.formCe[value],'EUR','4.2-4','it-IT');
  }

  onSubmit() {
  //  console.log('Risultato ---> ', this.bilancio);
  //  console.log(this.formSp);
    this.somma_totali();
    this.controlli_qualita1();
  }

  somma_totali(): void {
    //SP
    let tot_immobilizzazioni_ultimo = 0;
    let tot_immobilizzazioni_penultimo = 0;
    let tot_crediti_ultimo = 0;
    let tot_crediti_penultimo = 0;
    let tot_attivo_circ_ultimo = 0;
    let tot_attivo_circ_penultimo = 0;
    let tot_attivo_ultimo = 0;
    let tot_attivo_penultimo = 0;
    let tot_debiti_ultimo = 0;
    let tot_debiti_penultimo = 0;
    let tot_passivo_ultimo = 0;
    let tot_passivo_penultimo = 0;
    //CE
    let tot_val_produzione_ultimo = 0;
    let tot_val_produzione_penultimo = 0;
   // let tot_ammort_sval_ultimo = 0;
   // let tot_ammort_sval_penultimo = 0;
    let tot_costi_produzione_ultimo = 0;
    let tot_costi_produzione_penultimo = 0;
    //let tot_proventi_oneri_ultimo = 0;
    //let tot_proventi_oneri_penultimo = 0;
    let tot_prima_imposte_ultimo = 0;
    let tot_prima_imposte_penultimo = 0;
    let tot_utile_ultimo = 0;
    let tot_utile_penultimo = 0;

    //Stato Patrimoniale
    tot_immobilizzazioni_ultimo = Math.abs(this.formSp.item1_SP2 + this.formSp.item1_SP3 + this.formSp.item1_SP4);
    this.formSp.item1_SP5 = tot_immobilizzazioni_ultimo;
    tot_immobilizzazioni_penultimo = Math.abs(this.formSp.item2_SP2 + this.formSp.item2_SP3 + this.formSp.item2_SP4);
    this.formSp.item2_SP5 = tot_immobilizzazioni_penultimo;
    tot_crediti_ultimo =  Math.abs(this.formSp.item1_SP7 + this.formSp.item1_SP8);
    this.formSp.item1_SP9 = tot_crediti_ultimo;
    tot_crediti_penultimo =  Math.abs(this.formSp.item2_SP7 + this.formSp.item2_SP8);
    this.formSp.item2_SP9 = tot_crediti_penultimo;
    tot_attivo_circ_ultimo =  Math.abs(this.formSp.item1_SP6 + this.formSp.item1_SP7 + this.formSp.item1_SP8 +
      this.formSp.item1_SP10 + this.formSp.item1_SP11);
    this.formSp.item1_SP12 = tot_attivo_circ_ultimo;
    tot_attivo_circ_penultimo =  Math.abs(this.formSp.item2_SP6 + this.formSp.item2_SP7 + this.formSp.item2_SP8 +
      this.formSp.item2_SP10 + this.formSp.item2_SP11);
    this.formSp.item2_SP12 = tot_attivo_circ_penultimo;
    tot_attivo_ultimo = Math.abs(this.formSp.item1_SP1 + tot_immobilizzazioni_ultimo + tot_attivo_circ_ultimo + this.formSp.item1_SP13);
    this.formSp.item1_SP14 = tot_attivo_ultimo;
    tot_attivo_penultimo = Math.abs(this.formSp.item2_SP1 + tot_immobilizzazioni_penultimo + tot_attivo_circ_penultimo + this.formSp.item2_SP13);
    this.formSp.item2_SP14 = tot_attivo_penultimo;
    tot_debiti_ultimo = Math.abs(this.formSp.item1_SP19 + this.formSp.item1_SP20);
    this.formSp.item1_SP21 = tot_debiti_ultimo;
    tot_debiti_penultimo = Math.abs(this.formSp.item2_SP19 + this.formSp.item2_SP20);
    this.formSp.item2_SP21 = tot_debiti_penultimo;
    tot_passivo_ultimo =  Math.abs(this.formSp.item1_SP15 + this.formSp.item1_SP17 + this.formSp.item1_SP18 +
      tot_debiti_ultimo + this.formSp.item1_SP22);
    this.formSp.item1_SP23 = tot_passivo_ultimo;
    tot_passivo_penultimo =  Math.abs(this.formSp.item2_SP15 + this.formSp.item2_SP17 + this.formSp.item2_SP18 +
        tot_debiti_penultimo + this.formSp.item2_SP22);
    this.formSp.item2_SP23 = tot_passivo_penultimo;
    // Conto Economico
    if(this.tipo_societa == 1){
    tot_val_produzione_ultimo = Math.abs(this.formCe.item1_CE1 + this.formCe.item1_CE2 + this.formCe.item1_CE3 + this.formCe.item1_CE4 +
        this.formCe.item1_CE5);
    this.formCe.item1_CE6 = tot_val_produzione_ultimo;
    tot_val_produzione_penultimo = Math.abs(this.formCe.item2_CE1 + this.formCe.item2_CE2 + this.formCe.item2_CE3 + this.formCe.item2_CE4 +
        this.formCe.item2_CE5);
    this.formCe.item2_CE6 = tot_val_produzione_penultimo;
  //tot_ammort_sval_ultimo = Math.abs(this.formCe.item1_CE11 + this.formCe.item1_CE12);
  //this.formCe.item1_CE13 = tot_ammort_sval_ultimo;
  //tot_ammort_sval_penultimo = Math.abs(this.formCe.item2_CE11 + this.formCe.item2_CE12);
  //this.formCe.item2_CE13 = tot_ammort_sval_penultimo;
    tot_costi_produzione_ultimo = Math.abs(this.formCe.item1_CE7 + this.formCe.item1_CE8 + this.formCe.item1_CE9 + this.formCe.item1_CE10 +
        this.formCe.item1_CE13 + this.formCe.item1_CE14 + this.formCe.item1_CE15 + this.formCe.item1_CE16 + this.formCe.item1_CE17);
    this.formCe.item1_CE18 = tot_costi_produzione_ultimo;
    tot_costi_produzione_penultimo = Math.abs(this.formCe.item2_CE7 + this.formCe.item2_CE8 + this.formCe.item2_CE9 + this.formCe.item2_CE10 +
         this.formCe.item2_CE13 + this.formCe.item2_CE14 + this.formCe.item2_CE15 + this.formCe.item2_CE16 + this.formCe.item2_CE17);
    this.formCe.item2_CE18 = tot_costi_produzione_penultimo;
    //tot_proventi_oneri_ultimo = - this.formCe.item1_CE19;
    //this.formCe.item1_CE20 =  tot_proventi_oneri_ultimo;
    //tot_proventi_oneri_penultimo = - this.formCe.item2_CE19
    //this.formCe.item2_CE20 =  tot_proventi_oneri_penultimo;
    tot_prima_imposte_ultimo = tot_val_produzione_ultimo - tot_costi_produzione_ultimo + this.formCe.item1_CE20 - this.formCe.item1_CE21 - this.formCe.item1_CE22;
    this.formCe.item1_CE23 =  tot_prima_imposte_ultimo;
    tot_prima_imposte_penultimo = tot_val_produzione_penultimo - tot_costi_produzione_penultimo + this.formCe.item2_CE20 - this.formCe.item2_CE21 - this.formCe.item2_CE22;
    this.formCe.item2_CE23 =  tot_prima_imposte_penultimo;
    tot_utile_ultimo = tot_prima_imposte_ultimo - this.formCe.item1_CE24;
    this.formCe.item1_CE25 = tot_utile_ultimo;
    tot_utile_penultimo = tot_prima_imposte_penultimo - this.formCe.item2_CE24;
    this.formCe.item2_CE25 = tot_utile_penultimo;
    }
  }

  controlli_qualita1(): void {
    this.err = false;
    this.isAnno1 = false;
    this.msg = [];

    const form = this.formSp;
    const formCe = this.formCe;

    this.annoModal1 = this.showForm.item_esercizio1;

    const contrA = form.item1_SP14 + form.item1_SP23;
    const contrC = Math.abs(form.item1_SP5 - (form.item1_SP2 + form.item1_SP3 + form.item1_SP4));
    const contrD = Math.abs(form.item1_SP7 + form.item1_SP8 );
    const contrE = Math.abs(form.item1_SP12 - (form.item1_SP6 + form.item1_SP9 + form.item1_SP10 + form.item1_SP11));
    const contrF = Math.abs(form.item1_SP14 - (form.item1_SP1 + form.item1_SP5 + form.item1_SP12 + form.item1_SP13));
    const contrG = Math.abs(form.item1_SP21 - (form.item1_SP19 + form.item1_SP20 ));
    const contrH = Math.abs(form.item1_SP23 - (form.item1_SP15 + form.item1_SP17 + form.item1_SP18 + form.item1_SP21
      + form.item1_SP22));
    const contrI = Math.abs(formCe.item1_CE18 - (formCe.item1_CE7 + formCe.item1_CE8 + formCe.item1_CE9 + formCe.item1_CE10 +
      formCe.item1_CE13 + formCe.item1_CE14 + formCe.item1_CE15 + formCe.item1_CE16 + formCe.item1_CE17));
    const contrJ = Math.abs(formCe.item1_CE23 - (formCe.item1_CE6 - formCe.item1_CE18 + formCe.item1_CE20 + formCe.item1_CE21
      + formCe.item1_CE22));
    const contrK = Math.abs(formCe.item1_CE25 - (formCe.item1_CE23 - formCe.item1_CE24));

    const contrTotSP = form.item1_SP1 + form.item1_SP2 + form.item1_SP3 + form.item1_SP4 + form.item1_SP5 +
     form.item1_SP6 + form.item1_SP7 + form.item1_SP8 + form.item1_SP9 + form.item1_SP10 + form.item1_SP11 +
     form.item1_SP12 + form.item1_SP13 + form.item1_SP14 + form.item1_SP15 + form.item1_SP16 + form.item1_SP17 +
     form.item1_SP18 + form.item1_SP19 + form.item1_SP20 + form.item1_SP21 + form.item1_SP22 + form.item1_SP23;
    if (contrA == 0 || contrA == undefined || Number.isNaN(contrA)) {
      this.msg.push("Totale attivo e Totale passivo sono a zero");
      this.err = true;
    }
     else if (form.item1_SP14 != form.item1_SP23) {
      this.msg.push("Totale attivo e Totale passivo non sono uguali");
      this.err = true;
    }
    //(Qualita B)
    if(form.item1_SP16 != formCe.item1_CE25) {
      this.msg.push("Utile esercizio S/P diverso Utile esercizio C/E");
      this.err = true;
    }
    if(contrC > 100 || contrC == undefined || Number.isNaN(contrC)) {
      this.msg.push("Errore di Qualita C");
      this.err = true;
    }
    //(Qualita D)
    if(contrD > form.item1_SP9 || contrD == undefined || Number.isNaN(contrD)) {
      this.msg.push("Il Totale Crediti è inferiore ai Crediti Esigibili");
      this.err = true;
    }
    if(contrE > 100 || contrE == undefined || Number.isNaN(contrE)) {
      this.msg.push("Errore di Qualita E");
      this.err = true;
    }
    if(contrF > 100 || contrF == undefined || Number.isNaN(contrF)) {
      this.msg.push("Errore di Qualita F");
      this.err = true;
    } //SP21 - (SP19 + SP20) | ≤ 100
    if(contrG > 100 || contrG == undefined || Number.isNaN(contrG)) {
      this.msg.push("Errore di Qualita G");
      this.err = true;
    } //SP23 - (SP15 + SP17 + SP18 + SP21 + SP22) | ≤ 100
    if(contrH > 100 || contrH == undefined || Number.isNaN(contrH)) {
      this.msg.push("Errore di Qualita H");
      this.err = true;
    } //CE18 - (CE07 + CE08 + CE09 + CE10 + CE13 + CE14 + CE15 + CE16 + CE17) ≤ 100
    if(this.tipo_societa == 1){
      if(contrI > 100 || contrI == undefined || Number.isNaN(contrI)) {
        this.msg.push("Errore di Qualita I");
        this.err = true;
      } // CE23 - (CE06 – CE18 + CE20 + CE21 + CE22) | ≤ 100
      if(contrJ > 100 || contrJ == undefined || Number.isNaN(contrJ)) {
        this.msg.push("Errore di Qualita J");
        this.err = true;
      }//  CE25 - (CE23 – CE24) | ≤ 100
      if(contrK > 100 || contrK == undefined || Number.isNaN(contrK)) {
        this.msg.push("Errore di Qualita K");
        this.err = true;
      }
  }
    //console.log('contrTotSP -> ', contrTotSP );
    if(contrTotSP == 0 || contrTotSP == undefined || Number.isNaN(contrTotSP) ) {
      this.msg = [];
      this.msg.push("Errore tutti i campi S/P valorizzati a zero");
      this.err = true;
    }
      if(this.err)  {
      this.isAnno1  = true
      }
       this. controlli_qualita2();
    }

  controlli_qualita2(): void {
    this.err = false;
    this.msg2 = [];
    this.isAnno2 = false;

    const form = this.formSp;
    const formCe = this.formCe;
    this.annoModal2 = this.showForm.item_esercizio2;

    const contrA  =  form.item2_SP14 + form.item2_SP23;
    const contrC = Math.abs(form.item2_SP5 - (form.item2_SP2 + form.item2_SP3 + form.item2_SP4));
    const contrD = Math.abs(form.item2_SP7 + form.item2_SP8 );
    const contrE = Math.abs(form.item2_SP12 - (form.item2_SP6 + form.item2_SP9 + form.item2_SP10 + form.item2_SP11));
    const contrF = Math.abs(form.item2_SP14 - (form.item2_SP1 + form.item2_SP5 + form.item2_SP12 + form.item2_SP13));
    const contrG = Math.abs(form.item2_SP21 - (form.item2_SP19 + form.item2_SP20 ));
    const contrH = Math.abs(form.item2_SP23 - (form.item2_SP15 + form.item2_SP17 + form.item2_SP18 + form.item2_SP21
      + form.item2_SP22));
    const contrI = Math.abs(formCe.item2_CE18 - (formCe.item2_CE7 + formCe.item2_CE8 + formCe.item2_CE9 + formCe.item2_CE10 +
      formCe.item2_CE13 + formCe.item2_CE14 + formCe.item2_CE15 + formCe.item2_CE16 + formCe.item2_CE17));
    const contrJ = Math.abs(formCe.item2_CE23 - (formCe.item2_CE6 - formCe.item2_CE18 + formCe.item2_CE20 + formCe.item2_CE21
      + formCe.item2_CE22));
    const contrK = Math.abs(formCe.item2_CE25 - (formCe.item2_CE23 - formCe.item2_CE24));

    const contrTotSP = form.item2_SP1 + form.item2_SP2 + form.item2_SP3 + form.item2_SP4 + form.item2_SP5 +
    form.item2_SP6 + form.item2_SP7 + form.item2_SP8 + form.item2_SP9 + form.item2_SP10 + form.item2_SP11 +
    form.item2_SP12 + form.item2_SP13 + form.item2_SP14 + form.item2_SP15 + form.item2_SP16 + form.item2_SP17 +
    form.item2_SP18 + form.item2_SP19 + form.item2_SP20 + form.item2_SP21 + form.item2_SP22 + form.item2_SP23;

    if (contrA == 0 || contrA == undefined || Number.isNaN(contrA)) {
      this.msg2.push("Totale attivo e Totale passivo sono a zero");
      this.err = true;
    }
     else if (form.item2_SP14 != form.item2_SP23) {
      this.msg2.push("Totale attivo e Totale passivo non sono uguali");
      this.err = true;
    }
    //(Qualita B)
    if(form.item2_SP16 != formCe.item2_CE25) {
      this.msg2.push("Utile esercizio S/P diverso Utile esercizio C/E");
      this.err = true;
    }
    if(contrC > 100 || contrC == undefined || Number.isNaN(contrC)) {
      this.msg2.push("Errore di Qualita C");
      this.err = true;
    }
    //(Qualita D)
    if(contrD > form.item2_SP9 || contrD == undefined || Number.isNaN(contrD)) {
      this.msg2.push("Il Totale Crediti è inferiore ai Crediti Esigibili");
      this.err = true;
    }
    if(contrE > 100 || contrE == undefined || Number.isNaN(contrE)) {
      this.msg2.push("Errore di Qualita E");
      this.err = true;
    }
    if(contrF > 100 || contrF == undefined || Number.isNaN(contrF)) {
      this.msg2.push("Errore di Qualita F");
      this.err = true;
    } //SP21 - (SP19 + SP20) | ≤ 100
    if(contrG > 100 || contrG == undefined || Number.isNaN(contrG)) {
      this.msg2.push("Errore di Qualita G");
      this.err = true;
    } //SP23 - (SP15 + SP17 + SP18 + SP21 + SP22) | ≤ 100
    if(contrH > 100 || contrH == undefined || Number.isNaN(contrH)) {
      this.msg2.push("Errore di Qualita H");
      this.err = true;
    } //CE18 - (CE07 + CE08 + CE09 + CE10 + CE13 + CE14 + CE15 + CE16 + CE17) ≤ 100

    if(this.tipo_societa == 1){
      if(contrI > 100 || contrI == undefined || Number.isNaN(contrI)) {
        this.msg2.push("Errore di Qualita I");
        this.err = true;
      } // CE23 - (CE06 – CE18 + CE20 + CE21 + CE22) | ≤ 100
      if(contrJ > 100 || contrJ == undefined || Number.isNaN(contrJ)) {
        this.msg2.push("Errore di Qualita J");
        this.err = true;
      }//  CE25 - (CE23 – CE24) | ≤ 100
      if(contrK > 100 || contrK == undefined || Number.isNaN(contrK)) {
        this.msg2.push("Errore di Qualita K");
        this.err = true;
      }
  }
    //console.log('contrTotSP -> ', contrTotSP );
    if(contrTotSP == 0 || contrTotSP == undefined || Number.isNaN(contrTotSP) ) {
      this.msg2 = []; // faccio vedere solo questo errore
      this.msg2.push("Errore tutti i campi SP valorizzati a zero");
      this.err = true;
    }
      if(this.err) {
        this.isAnno2 = true;
        this.openModal();
      }
      else {
        this.registraBilancio();
      }
  }

  openModal() {
    this.modalService.open(this.content, this.modalOptions);
  }

  svuota(): void {
    this.azzeraForm();
  }

  leggiBilancio(): void {
    let params = {id: this.id_intestatario, data: this.data_inserimento}
    this.bilancioIntService.readBilancio(params).subscribe(
      (risultato) => {
        this.intestatario = JSON.parse(risultato).risultato;
        this.riempiForm();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  listaBilancio(): void {
    this.bilancioService.getAllDatiBilancio().subscribe(
      (data) => {
      //  console.log(data); //prima
       // this.bilancio = JSON.parse(data).risultato;
      //  this.azzeraForm();
      // fine
      if(this.tipo_societa == 1) {
        this.bilancio = [];
          for(let elem of JSON.parse(data).risultato){
            if(elem.scapitali){
              this.bilancio.push(elem);
            }
          }
        }else {
          this.bilancio = [];
          for(let elem of JSON.parse(data).risultato){
            if(elem.spdicord){
              this.bilancio.push(elem);
            }
        }

       }
       if(this.isNuovo) this.azzeraForm();
       else if(this.isNuovo === false) this.leggiBilancio();

      },
      (error) => {
        console.log(error);
      }
    );
  }

   salvaBilancio(c: any): void {
    this.modalService.dismissAll();
    this.registraBilancio();
  }

   registraBilancio(): void {
     /*
      let spAnnoInCorso: any = {};
      let spAnnoPrecedente: any = {};
      let ceAnnoInCorso: any = {};
      let ceAnnoPrecedente: any = {};
      */
      const elementi: Intestatario[] = [];
      let request: any = {};
      /*
      for(let elem of this.bilancio)
          {
          if(elem.spce == 'SP') {
            spAnnoInCorso[elem.spce.toLowerCase() + elem.formula!.toString().padStart(2,'0')] = this.formSp['item1_' + elem.spce + elem.formula];
            spAnnoPrecedente[elem.spce.toLowerCase() + elem.formula!.toString().padStart(2,'0')] = this.formSp['item2_' + elem.spce + elem.formula];
          }
          if(elem.spce == 'CE') {
            ceAnnoInCorso[elem.spce.toLowerCase() + elem.formula!.toString().padStart(2,'0')] = this.formCe['item1_' + elem.spce + elem.formula];
            ceAnnoPrecedente[elem.spce.toLowerCase() + elem.formula!.toString().padStart(2,'0')] = this.formCe['item2_' + elem.spce + elem.formula];
          }
        }
        */

          for(let elem of this.bilancio) {
              request = {};
              request.id_intestatario = this.id_intestatario;
              request.id_tipo_societa = this.tipo_societa;
              request.formula = elem.formula;
              request.spce = elem.spce;
              request.anno_ultimo_esercizio =  this.showForm.item_esercizio1;
              request.anno_penultimo_esercizio = this.showForm.item_esercizio2;
              if(!this.isNuovo){
                request.data_inserimento = this.data_inserimento;
              }else{
                request.data_inserimento =  new Date().toString();
                request.data_ultima_modifica =  new Date().toString();
              }
           if(elem.spce == 'SP') {
             request.valore_anno_ultimo = this.formSp['item1_' + elem.spce + elem.formula];
             request.valore_anno_penultimo =  this.formSp['item2_' + elem.spce + elem.formula];
           }
            if(elem.spce == 'CE') {
             request.valore_anno_ultimo = this.formCe['item1_' + elem.spce + elem.formula];
             request.valore_anno_penultimo =  this.formCe['item2_' + elem.spce + elem.formula];
            }
            elementi.push(request);
        }

        if(!this.isNuovo)
        {//modifica Bilancio
        this.bilancioIntService.insertDatiBilancio(elementi, this.data_inserimento).subscribe(
          (data) => {
           // let ris: Bilancio[];
            //ris = JSON.parse(data).risultato;
            this.msgErr = '';
            //this.msgErr = JSON.parse(data).message;
            this.msgErr = "Bilancio registrato con successo!";
            this.isSuccessful = true;
            this.modalService.open(this.messaggio, { centered: true,  backdrop:'static',
            backdropClass:'customBackdrop'});
            
          },
          (error) => {
            console.log(error);
          }
        );
      }
      else
      { //Nuovo inserimento non si puo moidificare uno pre esistente
        this.bilancioIntService.insertNewDatiBilancio(elementi).subscribe(
          (data) => {
            let ris: Bilancio[];
            ris = JSON.parse(data).risultato;
            this.data_inserimento =  ris[0][0].data_inserimento;
            this.msgErr = '';
            //this.msgErr = JSON.parse(data).message;
            this.msgErr = "Bilancio registrato con successo!";
            this.isSuccessful = true;
            this.modalService.open(this.messaggio, { centered: true,  backdrop:'static',
            backdropClass:'customBackdrop',});
            
          },
          (error) => {
            console.log(error);
          }
        );
       }

  }

  ritornaIndietro(): void {
    this.modalService.dismissAll();
    this.bilancioIntService.changeParamBilancio({
      id_intestatario: this.id_intestatario,
      data_inserimento: this.data_inserimento.slice(0,10),
    });
    this.location.back();
  }

  riempiForm(): void {
    this.azzeraForm();
    for(let elem of this.anniBilancio)
    {
      if(elem == this.intestatario[0].anno_ultimo_esercizio)
      this.showForm.item_esercizio1 = elem;
    }
     this.showForm.item_esercizio2 = this.intestatario[0].anno_penultimo_esercizio;
    for(let elem of this.intestatario)
    {

      if(elem.spce == 'SP') {
        this.formSp['item1_' + elem.spce + elem.formula] = elem.valore_anno_ultimo;
        this.formSp['item2_' + elem.spce + elem.formula] = elem.valore_anno_penultimo;
        }

    if(elem.spce == 'CE') {
        this.formCe['item1_' + elem.spce + elem.formula] = elem.valore_anno_ultimo;
        this.formCe['item2_' + elem.spce + elem.formula] = elem.valore_anno_penultimo;
        }
   }
  }
  //
  azzeraForm(): void {
    this.arrayDisabled = [];

    for(let elem of this.bilancio)
    {

      if(elem.spce == 'SP') {
        this.formSp['item1_' + elem.spce + elem.formula] = 0;
        this.formSp['item2_' + elem.spce + elem.formula] = 0;
        if (elem.formula == 5 || elem.formula == 9 || elem.formula == 12 ||  elem.formula == 14 || elem.formula == 21 || elem.formula == 23) {
          this.arrayDisabled.push(true);
        }

        else this.arrayDisabled.push(false);
    }
    if(elem.spce == 'CE') {
        this.formCe['item1_' + elem.spce + elem.formula] = 0;
        this.formCe['item2_' + elem.spce + elem.formula] = 0;
        //Totale ammortamento: elem.formula == 13
        //totale proventi e oneri finanziari elem.formula == 20
        if(this.tipo_societa == 1 && (elem.formula == 6  || elem.formula == 18 || elem.formula == 23 || elem.formula == 25)) {
          this.arrayDisabled.push(true);
        }
          else this.arrayDisabled.push(false);
      }
   }

  }
  reloadPage(c: any): void {
    c('close modal');
    this.router.navigate(['funzioni']);
   }

   tornaNoParam(): void {
    this.location.back();
  }

}
