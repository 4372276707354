<div class="container" style="margin-top: 10px;">
  <div class="row">
    <div class="col-12">
      <div class="items-bar">
        <button class="btn btn-primary" (click)="openDialog()" [disabled]="this.DispUt <= 1">Aggiungi Utente</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-accordion [multi]="false" style="border-radius: 20;">
        <mat-expansion-panel *ngFor="let Utente of Utenti.rows" (opened)="onOpenTab(Utente.id); panelOpenState= true"
          (closed)="panelOpenState = false;" (click)="start=false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{Utente.username}}
            </mat-panel-title>
            <button class="btn btn-danger" style="margin-right: 20px;" (click)="cancUser(Utente);">Disattiva
              Utente</button>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="container" [formGroup]="UserModData" *ngIf="!start">


            <div class="row">


              <div class="col-sm-4">
                <h4>Nome Utente:</h4>
                <input placeholder="Nome Utente" class="form-control" id="nome" formControlName="UserName">
                <div class="ers" *ngIf="errname">
                  E' gia presente un utente con questo nome.
                </div>
              </div>



              <div class="col-sm-4">
                <h4>Email Utente:</h4>
                <input placeholder="Email Utente" class="form-control" id="mail" formControlName="UserEmail">
                <div class="ers" *ngIf="errmail">
                  E' gia presente un utente con questa e-mail.
                </div>
              </div>


              <div class="col-sm-4">
                <h4>Ruolo Utente:</h4>
                <div>
                  <select formControlName="UserRole" class="form-control">

                    <option *ngFor="let ruolo of Roles.rows" value="{{ruolo.id}}"> {{ruolo.nome}} </option>

                  </select>
                </div>
              </div>


            </div>

            <div class="row">

              <div class="col-sm-4" *ngIf="f.controls['UserRole'].value == 4">

                <h4>Responsabile Area Assegnato:</h4>
                <div>
                  <select formControlName="UserSuper" class="form-control ">
                    <option value="0">Admin</option>
                    <ng-container *ngFor="let Utente of Utenti.rows">

                      <option *ngIf="Utente.jruolo.id == 3 && Utente.username != f.controls['UserName'].value "
                        value="{{Utente.id}}">{{Utente.username}}</option>
                    </ng-container>
                  </select>
                </div>

              </div>

              <div>
                <button class="btn btn-primary" style="display: flex; float:left; margin-top:45px;"
                  (click)="openDialog2(Utente.id, Utente.idCliente, Utente.idCapoArea, Utente.jruolo.id)">Gestione
                  Partite
                  Iva</button>
              </div>
            </div>

            <div class="row">
              <div>
                <button class="btn btn-success" style="display: flex; float:left; margin-top:45px;"
                  (click)="submitMod(UserModData , Utente.id)">Salva Modfiche</button>
              </div>
            </div>


          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>


</div>