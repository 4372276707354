<div class="container">
    <h1 class="text-center">Elenco Business Plan</h1>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="nomeAzienda" class="mat-elevation-z8 demo-table">
        <!-- Position Column -->
        <ng-container matColumnDef="riferimentoBp">
            <th mat-header-cell *matHeaderCellDef> Riferimento </th>
            <td mat-cell *matCellDef="let element"> {{element.riferimentoBp}} </td>
        </ng-container>
        <ng-container matColumnDef="idBusinessPlan">
            <th mat-header-cell *matHeaderCellDef> -- </th>
            <td mat-cell *matCellDef="let element"> {{element.idBusinessPlan}} </td>
        </ng-container>
        <ng-container matColumnDef="idAziendaRichiedente">
            <th mat-header-cell *matHeaderCellDef> -- </th>
            <td mat-cell *matCellDef="let element"> {{element.idAziendaRichiedente}} </td>
        </ng-container>
        <ng-container matColumnDef="nomeAzienda">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Azienda
                osservata </th>
            <td mat-cell *matCellDef="let element"> {{element.nomeAzienda}} </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="dataCreazione">
            <th mat-header-cell *matHeaderCellDef> Data creazione </th>
            <td mat-cell *matCellDef="let element"> {{element.dataCreazione}} </td>
        </ng-container>
        <ng-container matColumnDef="dataModifica">
            <th mat-header-cell *matHeaderCellDef> Data ultima modifica </th>
            <td mat-cell *matCellDef="let element"> {{element.dataModifica}} </td>
        </ng-container>
        <ng-container matColumnDef="idStato">
            <th mat-header-cell *matHeaderCellDef> Stato </th>
            <td mat-cell *matCellDef="let element"> {{element.idStato== 1 ? 'In preparazione' : element.idStato ==
                2 ?'In lavorazione': element.idStato== 3? 'Definitivo':'Archiviato'}} </td>
        </ng-container>
        <ng-container matColumnDef="azioni">
            <th mat-header-cell *matHeaderCellDef> Azioni </th>
            <td mat-cell *matCellDef="let element">
                <button *ngIf="element.idStato==1 ||element.idStato==2" button type="button"
                    class="btn btn-dark margin-right-10"
                    (click)="modificaOVisualizzaBp(element.idBusinessPlan)">{{element.azioni[0]}}</button>
                <button *ngIf="element.idStato==1 || element.idStato==2" class="btn btn-danger" type="button"
                    (click)="Elimina(element)">
                    {{element.azioni[1]}}
                </button>
                <button *ngIf="element.idStato==3" type="button" class="btn btn-dark margin-right-10"
                    (click)="modificaOVisualizzaBp(element.idBusinessPlan)">{{element.azioni[0]}}</button>
                <button *ngIf="element.idStato==3" type="button" class="btn btn-dark"
                    (click)="duplicaBp(element)">{{element.azioni[1]}}</button>

                <button *ngIf="element.idStato==4" type="button" class="btn btn-dark margin-right-10"
                    (click)="modificaOVisualizzaBp(element.idBusinessPlan)">{{element.azioni[0]}}</button>
                <button *ngIf="element.idStato==4" type="button" class="btn btn-dark"
                    (click)="Elimina(element)">{{element.azioni[1]}}</button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements"
        class="mat-elevation-z8 demo-table">
    </mat-paginator>
    <div class="row margin-top-20">
        <div class="col-sm">
            <button type="button" class="btn btn-dark" (click)="nuovaPratica();"
                [disabled]="listaRichiedentiConAmbiente.length==0">
                <i class="fa fa-plus fa-fw" style="font-size: 0.8rem;" aria-hidden="true"></i>Nuova
            </button>
        </div>
    </div>
</div>