import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-double-line-bar-chart',
  templateUrl: './double-line-bar-chart.component.html',
  styleUrls: ['./double-line-bar-chart.component.css']
})
export class DoubleLineBarChartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
