<!--IF valori celle hanno bisogno di | number -->
<div *ngIf="valsTab != null && number_pipe" class={{ngClass[0]}}>
  <ng-container *ngIf="indicatore_eventBreake && indicatoreEconometrico =='Break even'; else no_indicator_BrkEvnt">
    <div *ngFor="let header of valsTab.rows.header_titles" class={{ngClass[1]}}>
      <span *ngIf="header!='Range'">{{header}}</span>
    </div>
  </ng-container>
  <ng-template #no_indicator_BrkEvnt>
    <div *ngFor="let header of valsTab.rows.header_titles" class={{ngClass[1]}}>
      <span *ngIf="header!='Anno'">{{header}}</span>
    </div>
  </ng-template>
  <div class="col-12 nopadding ">
    <div *ngFor="let value of frequenza; let i = index" class="row margin-0 text-center ">
      <div class={{ngClass[2]}} [ngClass]="[frequenza[i] == maxFrequenzeDebito && 
              coloreDebito == 'verde' ? 'bg-green': frequenza[i] == maxFrequenzeDebito && 
              coloreDebito == 'giallo' ? 'bg-light-yellow': frequenza[i] == maxFrequenzeDebito &&
              coloreDebito == 'rosso' ? 'bg-red' : '']">
        {{valsTab.rows.values[i].value[0]}} {{valsTab.rows.values[i].decoratore[0]}}
      </div>
      <div class={{ngClass[2]}} [ngClass]="[frequenza[i] == maxFrequenzeDebito && 
              coloreDebito == 'verde' ? 'bg-green': frequenza[i] == maxFrequenzeDebito && 
              coloreDebito == 'giallo' ? 'bg-light-yellow': frequenza[i] == maxFrequenzeDebito &&
              coloreDebito == 'rosso' ? 'bg-red' : '']">
        {{valsTab.rows.values[i].value[1]}} {{valsTab.rows.values[i].decoratore[1]}}
      </div>
      <div class={{ngClass[2]}} [ngClass]="[frequenza[i] == maxFrequenzeDebito && 
              coloreDebito == 'verde' ? 'bg-green': frequenza[i] == maxFrequenzeDebito && 
              coloreDebito == 'giallo' ? 'bg-light-yellow': frequenza[i] == maxFrequenzeDebito &&
              coloreDebito == 'rosso' ? 'bg-red' : '']">
        {{valsTab.rows.values[i].value[2]}} {{valsTab.rows.values[i].decoratore[2]}}
      </div>
    </div>
  </div>
</div>