import { NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Chart } from 'chart.js';// import della libreria chart js per la realizzazione di grafici dati


@Component({
  selector: 'app-potenziometro',
  templateUrl: './potenziometro.component.html',
  styleUrls: ['./potenziometro.component.css']
})
export class PotenziometroComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() rotate_hande : number;
  @Input() rotate_debitoria: number;
  @Input() rotate_mcc: number;
  @Input() rotate_garanzia: number;
  @Input() id : string;
  @Input() lancetta : string;
  @ViewChildren('pr_chart', { read: ElementRef }) chartElementRefs: QueryList<ElementRef>;
  
  chart : any = [];

  data_principale : any = {
    datasets: [{
      label: 'principale',
      data: [33, 33, 33],
      backgroundColor: [
        'rgb(217, 83, 79)',
        'rgb(240, 173, 78)',
        'rgb(92, 184, 92)'
      ],
      circumference: 180,
      rotation: -90,
      borderRadius: 5,
      hoverOffset: 4,
      percentageInnerCutout: 4,
      tickWidth: 10
    }]
  };

  config : any = {
    type: 'doughnut',
    options: {
      cutout: 150,
      tooltips: {enabled: false},
      hover: {mode: null},
      plugins:{
        tooltip: {enabled: false}
      }
    },
    data: this.data_principale,
  };

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.chart = this.chartElementRefs.map((chartElementRef, index) => {
      const config = Object.assign({}, this.config , { data: this.data_principale });
  
      return new Chart(chartElementRef.nativeElement, config);
    });

    if(document.getElementById("debitoria")!=null){
     
      let h = document.getElementById("lancetta-debitoria");
      h = document.getElementById("lancetta-debitoria");
      h.style.transform = "rotateZ("+this.rotate_debitoria+"deg)";
    
    }
    
    if(document.getElementById("lancetta-mcc")!=null){
     
      let h = document.getElementById("lancetta-mcc");
      h = document.getElementById("lancetta-mcc");
      h.style.transform = "rotateZ("+this.rotate_mcc+"deg)";
    
    }
    
    if(document.getElementById("lancetta-garanzia")!=null){
     
      let h = document.getElementById("lancetta-garanzia");
      h = document.getElementById("lancetta-garanzia");
      h.style.transform = "rotateZ("+this.rotate_garanzia+"deg)";
    
    }
  }

  ngOnChanges(): void {
    
    if(document.getElementById("principale")!=null){
     
      let h = document.getElementById("lancetta-principale");
      h = document.getElementById("lancetta-principale");
      h.style.transform = "rotateZ("+this.rotate_hande+"deg)";
    
    }
  }

}
