import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
const ApiUrl =  environment.apiUrlNode + '/api/bilancioint/';

@Injectable({
  providedIn: 'root'
})
//api/bilancioint/allBilanci/

export class BilancioIntestatarioService {


  private paramBilancio = new BehaviorSubject<any>(null);

  currentParamBilancio = this.paramBilancio.asObservable();

  constructor(private http: HttpClient) { }
  insertDatiBilancio(request: any, param: string): Observable<any> {
    return this.http.post(ApiUrl + 'addDatiBilancio/'+param, {request}, { responseType: 'text' });
  }
  insertNewDatiBilancio(request: any): Observable<any> {
    return this.http.post(ApiUrl + 'addNewDatiBilancio/', {request}, { responseType: 'text' });
  }
  readDatiBilancio(id: any): Observable<any> {
    return this.http.get(ApiUrl + 'allBilanci/'+id,{ responseType: 'text' });
  }
  readBilancio(params: any): Observable<any> {
    return this.http.post(ApiUrl + 'bilancio/', {id: params.id, data: params.data},
       {responseType: 'text' });
  }
  

  changeParamBilancio(valore: any) {
    this.paramBilancio.next(valore)
  }
//params: { params: '[object Object]' }

}
