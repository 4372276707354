import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { HttpClient } from '@angular/common/http';
import { BeanStatico } from '../condivisi/beanStatico'
import { Provincia } from '../condivisi/provincia';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, delay, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TokenStorageService } from '../_services/token-storage.service';
import { CrService } from '../_services/cr.services';
import { BilancioIntestatarioService } from '../_services/bilancio_intestatario.service';

@Component({
  selector: 'qs-anagrafica-questionario',
  templateUrl: './anagrafica-questionario.component.html',
  styleUrls: ['./anagrafica-questionario.component.css'],
})

export class AnagraficaQuestionarioComponent implements OnInit {
  @Input('formGroup') anagraficaForm!: UntypedFormGroup;
  @Output() registraBilancio = new EventEmitter();
  @Output() registraCr = new EventEmitter();
  @ViewChild('debitoBT') debitoBT!: ElementRef;
  @ViewChild('debitoMT') debitoMT!: ElementRef;
  @ViewChild('debitoLT') debitoLT!: ElementRef;
  @ViewChild('ricaviExAnte') ricaviExAnte!: ElementRef;
  @ViewChild('costiExAnte') costiExAnte!: ElementRef;
  @ViewChild('rigaVE50') rigaVE50!: ElementRef;
  @ViewChild('rigaVF14') rigaVF14!: ElementRef;
  @ViewChild('rigaVF23') rigaVF23!: ElementRef;
  @ViewChild('rigaVF27') rigaVF27!: ElementRef;

  faInfo = faInfo;
  submitted = false;
  listaTitoliStudio: BeanStatico[] = [];
  listaTipiRichiedente: BeanStatico[] = [];
  listaProvince: Provincia[] = [];
  listaCategorie: BeanStatico[] = [];
  listaProdottiCategoria: BeanStatico[] = [];
  listaVarieta: BeanStatico[] = [];
  listaCanaliDistribuzione: BeanStatico[] = [];
  listaTipiAzienda: BeanStatico[] = [];
  listaCodiciAteco: BeanStatico[] = [];
  listaBilanci: any[] = [];
  listaCr: any[] = [];
  boolVarieta = false;
  tempIdVarieta = 0;
  tempIdComune: number = -1;
  listaComuni: BeanStatico[];



  constructor(private datiQuestionarioService: DatiQuestionarioService, private httpClient: HttpClient,
    private decimalPipe: DecimalPipe, private router: Router, private tokenService: TokenStorageService,
    private crService: CrService, private bilancioIntService: BilancioIntestatarioService,) {

  }

  ngOnInit() {
    if (this.f.comune.value && this.f.comune.value.id) {
      this.tempIdComune = this.f.comune.value.id;
    }
    /* chiamate a back end */
    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTitoliStudio').subscribe(data => {
      this.listaTitoliStudio = data;
      if (this.f.titoloStudio.value != '') {
        this.listaTitoliStudio.forEach(element => {
          if (element.id == this.f.titoloStudio.value.id) this.f.titoloStudio.setValue(element);
        });
      }
    });
    this.httpClient.get<Provincia[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getProvince').subscribe(data => {
      this.listaProvince = data;
      if (this.f.provincia.value != '') {
        this.listaProvince.forEach(element => {
          if (element.id == this.f.provincia.value.id) this.f.provincia.setValue(element);
        });
      }
    })
    //ascolta il change della provincia e agisce di conseguenza e fa l chiamata
    /* devo usare il valueChanges (ascolta i cambiamenti dell'input senza usare l'event emitter) e non il controls.valid con ngValue perchè siccome richiamato nell ngOnInit non aggiornerebbe i valori.
    potrei creare una funzione sull onchange del valore. in altrernativa si puo creare un evento sull'onchange che viene richiamato ogni volta che cambia valore */
    this.f.provincia.valueChanges.subscribe(selectedValue => {
      this.listaComuni = [];
      if (this.tempIdComune == -1) {
        this.f.comune.setValue('');
      }
      if (selectedValue) {
        this.f.comune.enable();
        this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTerritoriByIdProvincia', selectedValue.id).subscribe(data => {
          data.forEach(element => {
            const elementoLista = { id: element.id, descrizione: element.comune };
            //console.log(elementoLista)
            this.listaComuni.push(elementoLista)
            //serve per settare il valore nel campo a quello scelto dopo che si ricarica la pag
            if (element.id == this.tempIdComune) {
              this.f.comune.setValue(elementoLista);
              //this.f.comune.disable();
              this.tempIdComune = -1;
            }
          });
        });
      }
      else {
        this.f.comune.disable();
      }
      this.anagraficaForm.updateValueAndValidity();
    })

    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiRichiedente').subscribe(data => {
      this.listaTipiRichiedente = data;
      if (this.f.tipoRichiedente.value != '') {
        this.listaTipiRichiedente.forEach(element => {
          if (element.id == this.f.tipoRichiedente.value.id) this.f.tipoRichiedente.setValue(element);
        });
      }
    });

    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda').subscribe(data => {
      this.listaTipiAzienda = data;
      if (this.f.tipoAzienda.value != '') {
        this.listaTipiAzienda.forEach(element => {
          if (element.id == this.f.tipoAzienda.value.id) this.f.tipoAzienda.setValue(element);
        });
      }
    });

    this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getCodiciAteco').subscribe(data => {
      this.listaCodiciAteco = [];
      data.forEach(element => {
        let elementoAteco = { id: element.id, descrizione: element.codiceAteco + " - " + element.descrizione };
        this.listaCodiciAteco.push(elementoAteco)
        if (this.f.ateco.value != '' && element.id == this.f.ateco.value.id) this.f.ateco.setValue(elementoAteco);
      });
    });


    this.datiQuestionarioService.currentSubmitted.subscribe(submitted => this.submitted = submitted)

    this.crService.currentParamCr.subscribe(cr => {
      if (cr && cr.id_intestatario) {
        this.anagraficaForm.controls.centraleRischi.setValue({ codiceIntestatario: cr.id_intestatario, data: cr.data_inserimento });
      }
    });

    this.bilancioIntService.currentParamBilancio.subscribe(bilancio => {
      if (bilancio && bilancio.id_intestatario) {
        this.anagraficaForm.controls.bilancio.setValue({ idIntestatario: bilancio.id_intestatario, data: bilancio.data_inserimento });
      }
    });

    this.onChangeCr();
    this.onChangeBilancio();

  }

  ngAfterViewInit() {

    if (this.anagraficaForm.valid && this.f.tipoRichiedente.value != '') {
      if (typeof this.debitoBT !== 'undefined' && typeof this.debitoMT !== 'undefined' && typeof this.debitoLT !== 'undefined') {
        this.f.importoDebitoBT.value !== '' ? this.debitoBT.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.importoDebitoBT.value), '1.0-0', 'it') + ' €' : '';
        this.f.importoDebitoMT.value !== '' ? this.debitoMT.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.importoDebitoMT.value), '1.0-0', 'it') + ' €' : '';
        this.f.importoDebitoLT.value !== '' ? this.debitoLT.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.importoDebitoLT.value), '1.0-0', 'it') + ' €' : '';
      }

      if (typeof this.ricaviExAnte !== 'undefined' && typeof this.costiExAnte !== 'undefined' && typeof this.debitoLT !== 'undefined') {
        this.f.ricaviAnnoPrecedente.value !== '' ? this.ricaviExAnte.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.ricaviAnnoPrecedente.value), '1.0-0', 'it') + ' €' : '';
        this.f.costiAnnoPrecedente.value !== '' ? this.costiExAnte.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.costiAnnoPrecedente.value), '1.0-0', 'it') + ' €' : '';
      }

      if (typeof this.rigaVE50 !== 'undefined' && typeof this.rigaVF14 !== 'undefined' && typeof this.rigaVF23 !== 'undefined' && typeof this.rigaVF27 !== 'undefined') {
        this.f.rigaVE50.value !== '' ? this.rigaVE50.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.rigaVE50.value), '1.0-0', 'it') + ' €' : '';
        this.f.rigaVF14.value !== '' ? this.rigaVF14.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.rigaVF14.value), '1.0-0', 'it') + ' €' : '';
        this.f.rigaVF23.value !== '' ? this.rigaVF23.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.rigaVF23.value), '1.0-0', 'it') + ' €' : '';
        this.f.rigaVF27.value !== '' ? this.rigaVF27.nativeElement.value = this.decimalPipe.transform(parseFloat(this.f.rigaVF27.value), '1.0-0', 'it') + ' €' : '';
      }
    }
  }


  // convenience getter for easy access to form fields
  get f() { return this.anagraficaForm.controls; }

  get anagraficaAziendaForm(): UntypedFormGroup { return <UntypedFormGroup> this.anagraficaForm.controls.anagraficaAzienda; }
  get aziendaArray(): UntypedFormArray { return <UntypedFormArray> this.anagraficaAziendaForm.controls.datiAzienda; }
  get aziendaFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.aziendaArray.controls }
  get arrayMutui(): UntypedFormArray { return <UntypedFormArray> this.anagraficaAziendaForm.controls.debitiAScadenza; }
  get mutuiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.arrayMutui.controls }


  onChangeTipoRichiedente() {
    if (this.f.tipoRichiedente.value.descrizione != "Start up") {
      this.f.partitaIva.setValidators(Validators.required)
      this.f.importoDebitoBT.setValidators(Validators.required);
      this.f.importoDebitoMT.setValidators(Validators.required);
      this.f.importoDebitoLT.setValidators(Validators.required);
      //this.f.dichiarazioneIVA.setValidators(Validators.required);
      this.f.tipoAzienda.setValidators(Validators.required);
    }
    else {
      this.f.tipoAzienda.setValue('');
      this.f.importoDebitoBT.setValue('');
      this.f.importoDebitoMT.setValue('');
      this.f.importoDebitoLT.setValue('');
      this.f.dichiarazioneIVA.setValue('');
      this.f.rigaVE50.setValue('');
      this.f.rigaVF14.setValue('');
      this.f.rigaVF23.setValue('');
      this.f.rigaVF27.setValue('');
      this.f.ricaviAnnoPrecedente.setValue('');
      this.f.costiAnnoPrecedente.setValue('');
      this.f.presenzaBilancio.setValue('');
      this.f.presenzaCr.setValue('');
      this.f.bilancio.setValue('');
      this.f.partitaIva.clearValidators();
      this.f.dichiarazioneIVA.clearValidators();
      this.f.rigaVE50.clearValidators();
      this.f.rigaVF14.clearValidators();
      this.f.rigaVF23.clearValidators();
      this.f.rigaVF27.clearValidators();
      this.f.ricaviAnnoPrecedente.clearValidators();
      this.f.costiAnnoPrecedente.clearValidators();
      this.f.tipoAzienda.clearValidators();
    }
    this.f.tipoAzienda.updateValueAndValidity();
    this.f.partitaIva.updateValueAndValidity;
    this.f.dichiarazioneIVA.updateValueAndValidity();
    this.f.rigaVE50.updateValueAndValidity();
    this.f.rigaVF14.updateValueAndValidity();
    this.f.rigaVF23.updateValueAndValidity();
    this.f.rigaVF27.updateValueAndValidity();
    this.f.ricaviAnnoPrecedente.updateValueAndValidity();
    this.f.costiAnnoPrecedente.updateValueAndValidity();
  }


  onChangeDichiarazioneIVA() {
    if (this.f.dichiarazioneIVA.value == true) {
      this.f.rigaVE50.setValidators(Validators.required);
      this.f.rigaVF14.setValidators(Validators.required);
      this.f.rigaVF23.setValidators(Validators.required);
      this.f.rigaVF27.setValidators(Validators.required);
      this.f.ricaviAnnoPrecedente.clearValidators();
      this.f.costiAnnoPrecedente.clearValidators();
      this.f.ricaviAnnoPrecedente.setValue('');
      this.f.costiAnnoPrecedente.setValue('');
    }
    else {
      this.f.rigaVE50.clearValidators();
      this.f.rigaVF14.clearValidators();
      this.f.rigaVF23.clearValidators();
      this.f.rigaVF27.clearValidators();
      this.f.rigaVE50.setValue('');
      this.f.rigaVF14.setValue('');
      this.f.rigaVF23.setValue('');
      this.f.rigaVF27.setValue('');
      this.f.ricaviAnnoPrecedente.setValidators(Validators.required);
      this.f.costiAnnoPrecedente.setValidators(Validators.required);
    }
    this.f.rigaVE50.updateValueAndValidity();
    this.f.rigaVF14.updateValueAndValidity();
    this.f.rigaVF23.updateValueAndValidity();
    this.f.rigaVF27.updateValueAndValidity();
    this.f.ricaviAnnoPrecedente.updateValueAndValidity();
    this.f.costiAnnoPrecedente.updateValueAndValidity();

  }

  onChangeNomeAzienda() {
    this.datiQuestionarioService.changeNomeAzienda(this.f.nomeAzienda.value)
  }

  onChangeBilancio() {
    if (this.f.presenzaBilancio.value) {
      this.f.dichiarazioneIVA.setValue('');
      this.f.rigaVE50.clearValidators();
      this.f.rigaVF14.clearValidators();
      this.f.rigaVF23.clearValidators();
      this.f.rigaVF27.clearValidators();
      this.f.rigaVE50.setValue('');
      this.f.rigaVF14.setValue('');
      this.f.rigaVF23.setValue('');
      this.f.rigaVF27.setValue('');
      this.f.ricaviAnnoPrecedente.clearValidators();
      this.f.costiAnnoPrecedente.clearValidators();
      this.f.importoDebitoBT.clearValidators();
      this.f.importoDebitoMT.clearValidators();
      this.f.importoDebitoLT.clearValidators();
      this.f.ricaviAnnoPrecedente.setValue('');
      this.f.costiAnnoPrecedente.setValue('');
      this.f.rigaVE50.updateValueAndValidity();
      this.f.rigaVF14.updateValueAndValidity();
      this.f.rigaVF23.updateValueAndValidity();
      this.f.rigaVF27.updateValueAndValidity();
      this.f.ricaviAnnoPrecedente.updateValueAndValidity();
      this.f.costiAnnoPrecedente.updateValueAndValidity();
      this.f.importoDebitoBT.updateValueAndValidity();
      this.f.importoDebitoMT.updateValueAndValidity();
      this.f.importoDebitoLT.updateValueAndValidity();

      /* pulizia dell'array e dei campi di anagarafica azienda se metto NO a presenza bilancio */
      this.aziendaArray.clear();
      this.arrayMutui.clear();
      this.anagraficaAziendaForm.controls.creditiBreveTermine.setValidators(null);
      this.anagraficaAziendaForm.controls.debitiBreveTermine.setValidators(null);
      this.anagraficaAziendaForm.controls.debitiAutoliquidanti.setValidators(null);
      this.anagraficaAziendaForm.controls.debitiARevoca.setValidators(null);
      this.anagraficaAziendaForm.controls.rimanenzaMateriePrime.setValidators(null);
      this.anagraficaAziendaForm.controls.rimanenzaProdottiIniziali.setValidators(null);
      this.anagraficaAziendaForm.controls.ammortamentoIniziale.setValidators(null);
      this.anagraficaAziendaForm.controls.immobilizzazioniIniziali.setValidators(null);
      this.anagraficaAziendaForm.controls.variazioneRimanenzeAnnoIncorso.setValidators(null);

      this.anagraficaAziendaForm.controls.creditiBreveTermine.setValue('');
      this.anagraficaAziendaForm.controls.debitiBreveTermine.setValue('');
      this.anagraficaAziendaForm.controls.debitiAutoliquidanti.setValue('');
      this.anagraficaAziendaForm.controls.debitiARevoca.setValue('');
      this.anagraficaAziendaForm.controls.rimanenzaMateriePrime.setValue('');
      this.anagraficaAziendaForm.controls.rimanenzaProdottiIniziali.setValue('');
      this.anagraficaAziendaForm.controls.ammortamentoIniziale.setValue('');
      this.anagraficaAziendaForm.controls.immobilizzazioniIniziali.setValue('');
      this.anagraficaAziendaForm.controls.variazioneRimanenzeAnnoIncorso.setValue('');

      this.anagraficaAziendaForm.controls.creditiBreveTermine.updateValueAndValidity();
      this.anagraficaAziendaForm.controls.debitiBreveTermine.updateValueAndValidity();
      this.anagraficaAziendaForm.controls.debitiAutoliquidanti.updateValueAndValidity();
      this.anagraficaAziendaForm.controls.debitiARevoca.updateValueAndValidity();
      this.anagraficaAziendaForm.controls.rimanenzaMateriePrime.updateValueAndValidity();
      this.anagraficaAziendaForm.controls.rimanenzaProdottiIniziali.updateValueAndValidity();
      this.anagraficaAziendaForm.controls.ammortamentoIniziale.updateValueAndValidity();
      this.anagraficaAziendaForm.controls.immobilizzazioniIniziali.updateValueAndValidity();
      this.anagraficaAziendaForm.controls.variazioneRimanenzeAnnoIncorso.updateValueAndValidity();


      this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getBilanciRichiedente', this.tokenService.getUser().username).subscribe(data => {
        this.listaBilanci = [];
        data.forEach(element => {
          let elementoLista = {
            idIntestatario: element.intestatario.id,
            data: element.bilancio.dataInserimento,
            descrizione: element.intestatario.ragioneSociale + " - " + element.bilancio.dataUltimaModifica
          };
          this.listaBilanci.push(elementoLista)
          if (this.f.bilancio.value != '' && elementoLista.data == this.f.bilancio.value.data && elementoLista.idIntestatario == this.f.bilancio.value.idIntestatario)
            this.f.bilancio.setValue(elementoLista);
        });
      });

    }
  }

  onChangeCr() {
    if (this.f.presenzaCr.value) {
      this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/intermediari/getListaCr', this.tokenService.getUser().username).subscribe(data => {
        this.listaCr = [];
        data.forEach(element => {
          let elementoLista = { id: element.id, data: element.data_registrazione, descrizione: element.cognome ? element.cognome + " - " + element.data_registrazione : element.nome + " - " + element.data_registrazione };
          this.listaCr.push(elementoLista)
          if (this.f.centraleRischi.value != '' && element.data_registrazione == this.f.centraleRischi.value.data && (element.id == this.f.centraleRischi.value.id || element.codice == this.f.centraleRischi.value.codiceIntestatario)) this.f.centraleRischi.setValue(elementoLista);
        });
      });
    } else {
      this.f.centraleRischi.setValue('');
    }
  }
  goToBilancio() {
    this.registraBilancio.next(null);
  }

  goToCr() {
    this.registraCr.next(null);
  }


}
