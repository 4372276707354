<div class="col-12" [formGroup]="anagraficaForm">
  <div class="card nopadding">
    <div class="card-header text-center font-big">Anagrafica richiedente</div>
    <div class="card-body">
      <div class="row">

        <div class="col-4 col-lg-4 margin-bottom-10">
          <label>Tipo richiedente</label>
          <select formControlName="tipoRichiedente" class="form-control" (change)="onChangeTipoRichiedente()"
            [ngClass]="{ 'is-invalid': submitted && f.tipoRichiedente.errors }">
            <option [ngValue]="richiedente" *ngFor="let richiedente of listaTipiRichiedente">{{richiedente.descrizione}}
            </option>
          </select>
          <div *ngIf="submitted && f.tipoRichiedente.errors" class="invalid-feedback">
            <div *ngIf="f.tipoRichiedente.errors.required">Campo obbligatorio</div>
          </div>
        </div>

        <div class="col-4 col-lg-4 margin-bottom-10">
          <label>Ragione sociale</label>
          <input type="text" formControlName="nomeAzienda" class="form-control" (change)="onChangeNomeAzienda()"
            [ngClass]="{ 'is-invalid': submitted && f.nomeAzienda.errors }" />
          <div *ngIf="submitted && f.nomeAzienda.errors" class="invalid-feedback">
            <div *ngIf="f.nomeAzienda.errors.required">Campo obbligatorio</div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="f.tipoRichiedente.value && f.tipoRichiedente.value.descrizione != 'Start up'">

        <div class="col-4 col-lg-4 margin-bottom-10">
          <label>Tipo Azienda</label>
          <select formControlName="tipoAzienda" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.tipoAzienda.errors }">
            <option [ngValue]="tipoAzienda" *ngFor="let tipoAzienda of listaTipiAzienda">{{tipoAzienda.descrizione}}
            </option>
          </select>
          <div *ngIf="submitted && f.tipoAzienda.errors" class="invalid-feedback">
            <div *ngIf="f.tipoAzienda.errors.required">Campo obbligatorio</div>
          </div>
        </div>

        <div class="col-4 col-lg-4 margin-bottom-10">
          <label>Partita IVA</label>
          <input type="text" formControlName="partitaIva" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.partitaIva.errors }" />
          <div *ngIf="submitted && f.partitaIva.errors" class="invalid-feedback">
            <div *ngIf="f.partitaIva.errors.required">Campo obbligatorio</div>
          </div>
        </div>


      </div>

      <div class="row">

        <div class="col-5 col-lg-4 margin-bottom-10">
          <label>Titolo di studio</label>
          <select formControlName="titoloStudio" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.titoloStudio.errors }">
            <option [ngValue]="titolo" *ngFor="let titolo of listaTitoliStudio">{{titolo.descrizione}}</option>
          </select>
          <div *ngIf="submitted && f.titoloStudio.errors" class="invalid-feedback">
            <div *ngIf="f.titoloStudio.errors.required">Campo obbligatorio</div>
          </div>
        </div>

        <div class="col-5 col-lg-4 margin-bottom-10">
          <label>Esperienza nel settore agricolo (anni)</label>
          <input type="number" min="0" formControlName="esperienza" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.esperienza.errors }" />
          <div *ngIf="submitted && f.esperienza.errors" class="invalid-feedback">
            <div *ngIf="f.esperienza.errors.required">Campo obbligatorio</div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="card nopadding margin-top-20"
    *ngIf="f.tipoRichiedente.value && f.tipoRichiedente.value.descrizione != 'Start up'">
    <div class="card-header text-center font-big ">Dossier amministrativi</div>
    <div class="card-body">
      <div class="row margin-0">
        <div class="margin-bottom-10 col-12 col-lg-12">
          Per produrre la valutazione preliminare di finanziabilit&agrave; del tuo progetto &egrave;
          necessario conoscere un po' la tua societ&agrave;. Puoi fornirci gli ultimi due bilanci oppure l'ultima
          dichiarazione IVA.
          In alternativa puoi inserire direttamente le informazioni necessarie.
          Fornendoci anche la tua Centrale dei Rischi saremo nelle migliori condizioni per fornirti un realistico piano
          di fattibilit&agrave;
        </div>
        <div class="col-6">

          <div class="row">


            <div class="margin-bottom-10 col-6 col-lg-10">
              <label>Vuoi eseguire l'analisi dell'ultimo bilancio?</label>
            </div>
            <div class="margin-bottom-10 col-2 col-lg-2">
              <select id="presenzaBilancio" formControlName="presenzaBilancio" class="form-control"
                (change)="onChangeBilancio()">
                <option [ngValue]="true">Si</option> <!-- setto il value di presenza bilancio -->
                <option [ngValue]="false">No</option>
              </select>
            </div>
          </div>

          <div class="row" *ngIf="f.presenzaBilancio.value">
            <!-- ================================= -->
            <div class="col-5 col-lg-4 margin-bottom-10 margin-top-20">
              <button class="btn btn-primary mr-1" type="button" (click)="goToBilancio()">Inserisci dati
                bilancio</button>
            </div>
            <div class="col-5 col-lg-6 margin-bottom-10" *ngIf="listaBilanci.length > 0">
              <label>Seleziona Bilancio</label>
              <select formControlName="bilancio" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.bilancio.errors }">
                <option [ngValue]="bilancio" *ngFor="let bilancio of listaBilanci">{{bilancio.descrizione}}</option>
              </select>
              <div *ngIf="submitted && f.bilancio.errors" class="invalid-feedback">
                <div *ngIf="f.bilancio.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>

          <div class="row" *ngIf="f.presenzaBilancio.value === false">

            <div class="margin-bottom-10 col-6 col-lg-10">
              <label>Hai a disposizione l'ultima dichiarazione IVA?</label>
            </div>
            <div class="margin-bottom-10 col-2 col-lg-2">
              <select formControlName="dichiarazioneIVA" class="form-control" (change)="onChangeDichiarazioneIVA()"
                [ngClass]="{ 'is-invalid': submitted && f.dichiarazioneIVA.errors }">
                <option [ngValue]="true">Si</option>
                <option [ngValue]="false">No</option>
              </select>
              <div *ngIf="submitted && f.dichiarazioneIVA.errors" class="invalid-feedback">
                <div *ngIf="f.dichiarazioneIVA.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>

          <div class="row" [hidden]="!(f.dichiarazioneIVA.value && f.dichiarazioneIVA.value == true)">

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Inserire quadro VE, riga VE 50</label>
              <input type="text" formControlName="rigaVE50" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rigaVE50.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #rigaVE50 />
              <div *ngIf="submitted && f.rigaVE50.errors" class="invalid-feedback">
                <div *ngIf="f.rigaVE50.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Inserire quadro VF, riga VF 14</label>
              <input type="text" formControlName="rigaVF14" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rigaVF14.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #rigaVF14 />
              <div *ngIf="submitted && f.rigaVF14.errors" class="invalid-feedback">
                <div *ngIf="f.rigaVF14.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="margin-bottom-10 offset-2 offset-lg-0">
            </div>

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Inserire quadro VF, riga VF23</label>
              <input type="text" formControlName="rigaVF23" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rigaVF23.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #rigaVF23 />
              <div *ngIf="submitted && f.rigaVF23.errors" class="invalid-feedback">
                <div *ngIf="f.rigaVF23.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Inserire quadro VF, riga VF 27</label>
              <input type="text" formControlName="rigaVF27" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rigaVF27.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #rigaVF27 />
              <div *ngIf="submitted && f.rigaVF27.errors" class="invalid-feedback">
                <div *ngIf="f.rigaVF27.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>

          <div class="row" [hidden]="!(f.dichiarazioneIVA.value === false)">

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Ricavi anno precedente</label>
              <input type="text" formControlName="ricaviAnnoPrecedente" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.ricaviAnnoPrecedente.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #ricaviExAnte />
              <div *ngIf="submitted && f.ricaviAnnoPrecedente.errors" class="invalid-feedback">
                <div *ngIf="f.ricaviAnnoPrecedente.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="margin-bottom-10 col-4 col-lg-6">
              <label>Costi anno precedente</label>
              <input type="text" formControlName="costiAnnoPrecedente" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.costiAnnoPrecedente.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
                #costiExAnte />
              <div *ngIf="submitted && f.costiAnnoPrecedente.errors" class="invalid-feedback">
                <div *ngIf="f.costiAnnoPrecedente.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>

        </div>

        <div class="col-6 borderL">

          <div class="row">

            <div class="margin-bottom-10 col-6 col-lg-10">
              <label>Hai a disposizione la Centrale dei Rischi?</label>
            </div>

            <div class="margin-bottom-10 col-2 col-lg-2">
              <select id="presenzaCr" formControlName="presenzaCr" class="form-control" (change)="onChangeCr()">
                <option [ngValue]="true">Si</option>
                <option [ngValue]="false">No</option>
              </select>
            </div>

          </div>

          <div class="row" *ngIf="f.presenzaCr.value">
            <div class="col-5 col-lg-4 margin-bottom-10 margin-top-20">
              <button class="btn btn-primary mr-1" type="button" (click)="goToCr()">Carica CR</button>
            </div>
            <div class="col-5 col-lg-6 margin-bottom-10" *ngIf="listaCr.length > 0">
              <label>Seleziona CR</label>
              <select formControlName="centraleRischi" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.centraleRischi.errors }">
                <option [ngValue]="centraleRischi" *ngFor="let centraleRischi of listaCr">{{centraleRischi.descrizione}}
                </option>
              </select>
              <div *ngIf="submitted && f.centraleRischi.errors" class="invalid-feedback">
                <div *ngIf="f.centraleRischi.errors.required">Campo obbligatorio</div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="card nopadding margin-top-20"
    *ngIf="false && f.tipoRichiedente.value && f.tipoRichiedente.value.descrizione != 'Start up'">
    <div class="card nopadding margin 0" *ngIf="f.presenzaBilancio.value">
      <div class="card-header text-center font-big">Esposizione finanziaria</div>
      <div class="card-body">

        <div class="row">
          <div class="col-12 font-bold">Durata debito a breve termine: 18 mesi</div>

          <div class="col-5 col-lg-4 margin-bottom-10">
            <label>Importo residuo debito a breve termine</label>
            <input type="text" formControlName="importoDebitoBT" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.importoDebitoBT.errors }" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
              #debitoBT />
            <div *ngIf="submitted && f.importoDebitoBT.errors" class="invalid-feedback">
              <div *ngIf="f.importoDebitoBT.errors.required">Campo obbligatorio</div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12 font-bold">Durata debito a medio termine: 4 anni</div>

          <div class="col-5 col-lg-4 margin-bottom-10">
            <label>Importo residuo debito a medio termine</label>
            <input type="text" formControlName="importoDebitoMT" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.importoDebitoMT.errors }" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
              #debitoMT />
            <div *ngIf="submitted && f.importoDebitoMT.errors" class="invalid-feedback">
              <div *ngIf="f.importoDebitoMT.errors.required">Campo obbligatorio</div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-12 font-bold">Durata debito a lungo termine: 7 anni</div>

          <div class="col-5 col-lg-4">
            <label>Importo residuo debito a lungo termine</label>
            <input type="text" formControlName="importoDebitoLT" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.importoDebitoLT.errors }" currencyMask
              [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"
              #debitoLT />
            <div *ngIf="submitted && f.importoDebitoLT.errors" class="invalid-feedback">
              <div *ngIf="f.importoDebitoLT.errors.required">Campo obbligatorio</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>