/* ------------------------------------------
 * MODULO DI CONFIGURAZIONE dell'APPLICAZIONE
 * ------------------------------------------ */
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { BoardAdminComponent } from './board-admin/board-admin.component';
import { BoardFrontofficeComponent } from './board-frontoffice/board-frontoffice.component';
import { BoardUserComponent } from './board-user/board-user.component';
import { PrezziECostiComponent } from './prezziecosti/prezziecosti.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgChartsModule } from 'ng2-charts';

import { MaterialModule } from './material/material.module';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import localeIt from '@angular/common/locales/it';
import { AnagraficaProdottiComponent } from './anagraficaprodotti/anagraficaprodotti.component';
import { FunzioniComponent } from './funzioni/funzioni.component';
import { BilancioComponent } from './bilancio/bilancio.component';
import { IntestatarioComponent } from './intestatario/intestatario.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PresaIncaricoCrComponent } from './presaincaricocr/presaincaricocr.component';
import { AnalisicrComponent } from './analisicr/analisicr.component';
import { AnalisimccComponent } from './analisimcc/analisimcc.component';
import { QuestionarioComponent } from './questionario/questionario.component';
import { AnagraficaQuestionarioComponent } from './anagrafica-questionario/anagrafica-questionario.component';
import { DatiInvestimentoComponent } from './dati-investimento/dati-investimento.component';
import { GaranzieComponent } from './garanzie/garanzie.component';
import { HttpRequestInterceptor } from './http-request-interceptor';
import { ExpertOpinionComponent } from './expert-opinion/expert-opinion.component';
import { ProdottoProgettoComponent } from './prodotto-progetto/prodotto-progetto.component';
import { currencyInputDirective } from './directives/currency-input';
import { ColtureAggiuntiveComponent } from './colture-aggiuntive/colture-aggiuntive.component';
import { RisultatiQuestionarioComponent } from './risultati-questionario/risultati-questionario.component';
import { ListaBplanComponent } from './lista-bplan/lista-bplan.component';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { OrticoliComponent } from './orticoli/orticoli.component';
import { EbaComplianceComponent } from './eba-compliance/eba-compliance.component';
import { ModelloUniversaleComponent } from './modello-universale/modello-universale.component';
import { EnergiaGasComponent } from './energia-gas/energia-gas.component';
import { PotenziometroComponent } from './potenziometro/potenziometro/potenziometro.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { TabellaSempliceComponent } from './risultati-questionario/tabelle/tabella-semplice/tabella-semplice.component';
import { TabellaWithOptionsComponent } from './risultati-questionario/tabelle/tabella-with-options/tabella-with-options.component';
import { SideLeftMenuPotenziometriComponent } from './side-left-menu-potenziometri/side-left-menu-potenziometri.component';

import { SideRightNavmenuComponent } from './side-right-navmenu/side-right-navmenu.component';
import { TimelineRisultatiQuestionarioComponent } from './timeline-risultati-questionario/timeline-risultati-questionario.component';
import { TopBarPotenziometriComponent } from './top-bar-potenziometri/top-bar-potenziometri.component';
import { CategorieComponent } from './categorie/categorie.component';
import { HeaderComponent } from './header/header.component';
import { MatIconModule } from '@angular/material/icon';
import { SplashpageRisultatiComponent } from './risultati-questionario/splashpage-risultati/splashpage-risultati.component';
import { InfoTopSectionComponent } from './info-top-section/info-top-section.component';
import { TabellaSplashpageComponent } from './risultati-questionario/tabelle//tabella-splashpage/tabella-splashpage.component';
import { IntestationPlashSectionComponent } from './intestation-plash-section/intestation-plash-section.component';
import { ModalcontentComponent } from './modalcontent/modalcontent/modalcontent.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BarChartComponent } from './bar-chart/bar-chart-indicatori-economici.component';
import { LineChartComponent } from './line-chart/line-chart.component';

import { MatTabsModule } from '@angular/material/tabs';
import { CensimentoClienteComponent } from './censimento-cliente/censimento-cliente.component';
import { ListaClientiComponent } from './lista-clienti/lista-clienti.component';
import { ClienteComponent } from './lista-clienti/cliente/cliente.component';
import { DettagliClienteComponent } from './lista-clienti/dettagli-cliente/dettagli-cliente.component';
import { CensimentoRichiedenteComponent } from './censimento-richiedente/censimento-richiedente.component';
import { ListaRichiedentiComponent } from './censimento-richiedente/lista-richiedenti/lista-richiedenti.component';
import { CensimentoAtecoSecondariComponent } from './censimento-ateco-secondari/censimento-ateco-secondari.component';
import { GestioneAnagraficaComponent } from './gestione-anagrafica/gestione-anagrafica.component';
import { ConfigurazioneAmbienteComponent } from './configurazione-ambiente/configurazione-ambiente.component';
import { ParametriGeneraliComponent } from './configurazione-ambiente/parametri-generali/parametri-generali.component';
import { LineeBusinessComponent } from './configurazione-ambiente/linee-business/linee-business.component';
import { DistintaBaseProdottiComponent } from './configurazione-ambiente/distinta-base-prodotti/distinta-base-prodotti.component';
import { BarChartIndicatoriBilancioComponent } from './bar-chart-indicatori-bilancio/bar-chart-indicatori-bilancio.component';
import { DoubleLineBarChartComponent } from './double-line-bar-chart/double-line-bar-chart.component';
import { TimelineWithButtonsComponent } from './timeline-with-buttons/timeline-with-buttons.component';
import { SintesiAsIsComponent } from './risultati-questionario/sintesi-as-is/sintesi-as-is.component';
import { SintesiEndStrokeComponent } from './risultati-questionario/sintesi-end-stroke/sintesi-end-stroke.component';
import { SintesiGraphGroupComponent } from './risultati-questionario/sintesi-graph-group/sintesi-graph-group.component';
import { SintesiPreciselyYearComponent } from './risultati-questionario/sintesi-precisely-year/sintesi-precisely-year.component';
import { DettaglioRisultatiComponent } from './risultati-questionario/dettaglio-risultati/dettaglio-risultati.component';
import { ModelloCommercialeComponent } from './modello-commerciale/modello-commerciale.component';
import { ListaBplanOldComponent } from "./lista-bplan-old/lista-bplan-old.component";
import { AnagraficaQuestionarioOldComponent } from "./anagrafica-questionario-old/anagrafica-questionario-old.component";
import { QuestionarioOldComponent } from "./questionario_old/questionario-old.component";
import { ProdottoComponent } from './configurazione-ambiente/distinta-base-prodotti/prodotto/prodotto.component';
import { LineaBusinessComponent } from './configurazione-ambiente/distinta-base-prodotti/linea-business/linea-business.component';
import { ConfigurazioneBusinessPlanComponent } from './configurazione-business-plan/configurazione-business-plan.component';
import { IndicatoriGuidaBpComponent } from './configurazione-business-plan/indicatori-guida-bp/indicatori-guida-bp.component';
import { InserimentoRicaviCostiComponent } from './configurazione-business-plan/inserimento-ricavi-costi/inserimento-ricavi-costi.component';
import { ConfiguarazioneMagazzinoComponent } from './configurazione-business-plan/configuarazione-magazzino/configuarazione-magazzino.component';
import { LineaBusinessBpComponent } from './configurazione-business-plan/inserimento-ricavi-costi/linea-business-bp/linea-business-bp.component';
import { ListaUtentiComponent } from './lista-utenti/lista-utenti.component';
import { UserDialogFormComponent } from './user-dialog-form/user-dialog-form.component';
import { GestionePivaDialogComponent } from './gestione-piva-dialog/gestione-piva-dialog.component';
import { ListaBplanNuovaComponent } from './lista-bplan-nuova/lista-bplan-nuova.component';
import { NgxCurrencyModule } from "ngx-currency";
import { TooltipComponent } from './tooltip/tooltip.component';
import { DossierAmministrativiComponent } from './configurazione-ambiente/dossier-amministrativi/dossier-amministrativi.component';
import { BilancioManualeComponent } from './bilancio-manuale/bilancio-manuale.component';
import { CreaDossierComponent } from './risultati-questionario/crea-dossier/crea-dossier.component';
import { InputBenchmarkComponent } from './benchmark/input-benchmark/input-benchmark.component';
import { BenchCellComponent } from './benchmark/bench-cell/bench-cell.component';
import { CompetitorDialogComponent } from './benchmark/competitor-dialog/competitor-dialog.component';
import { DatiMercatoBenchRowComponent } from './benchmark/dati-mercato-bench-row/dati-mercato-bench-row.component';
import { DettagliDatiMercatoComponent } from './benchmark/dettagli-dati-mercato/dettagli-dati-mercato.component';
import { InvestimentiDisinvestimentiBpComponent } from './configurazione-business-plan/investimenti-disinvestimenti-bp/investimenti-disinvestimenti-bp.component';
import { DettagliAziendaTargetComponent } from './benchmark/dettagli-azienda-target/dettagli-azienda-target.component';
import { SituazioneCreditiziaComponent } from './configurazione-business-plan/situazione-creditizia/situazione-creditizia.component';
import { ConfigurazioneCorrelazioniAmbienteComponent } from './configurazione-ambiente/configurazione-correlazioni-ambiente/configurazione-correlazioni-ambiente.component';
import { SituazioneDebitoriaNuovoComponent } from './configurazione-business-plan/situazione-debitoria-nuovo/situazione-debitoria-nuovo.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { UserIdleModule } from 'angular-user-idle';
import { CheckBoxListComponent } from './configurazione-business-plan/check-box-list/check-box-list.component';
import { ListaSmartBpComponent } from './lista-smart-bp/lista-smart-bp.component';
import { SituazioneDebitoriaAmbienteComponent } from './configurazione-ambiente/situazione-debitoria-ambiente/situazione-debitoria-ambiente.component';
import { SituazioneCreditiziaAmbienteComponent } from './configurazione-ambiente/situazione-creditizia-ambiente/situazione-creditizia-ambiente.component';
import { SmartBpComponent } from './smart-bp/smart-bp.component';
import { CensimentoSmartComponent } from './censimento-smart/censimento-smart.component';
import { SituazioneFinanziariaComponent } from './configurazione-smart-bp/situazione-finanziaria/situazione-finanziaria.component';
import { SituazioneDebitoriaSmartComponent } from './configurazione-smart-bp/situazione-finanziaria/situazione-debitoria-smart/situazione-debitoria-smart.component';
import { SituazioneCreditiziaSmartComponent } from './configurazione-smart-bp/situazione-finanziaria/situazione-creditizia-smart/situazione-creditizia-smart.component';
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    ProfileComponent,
    BoardAdminComponent,
    BoardFrontofficeComponent,
    BoardUserComponent,
    FunzioniComponent,
    BilancioComponent,
    IntestatarioComponent,
    SpinnerComponent,
    PresaIncaricoCrComponent,
    AnalisicrComponent,
    AnalisimccComponent,
    QuestionarioComponent,
    PrezziECostiComponent,
    AnagraficaProdottiComponent,
    AnagraficaQuestionarioComponent,
    DatiInvestimentoComponent,
    GaranzieComponent,
    ExpertOpinionComponent,
    ProdottoProgettoComponent,
    currencyInputDirective,
    ColtureAggiuntiveComponent,
    QuestionarioComponent,
    RisultatiQuestionarioComponent,
    ListaBplanComponent,
    OrticoliComponent,
    EbaComplianceComponent,
    ModelloUniversaleComponent,
    EnergiaGasComponent,
    PotenziometroComponent,
    DoughnutChartComponent,
    TabellaSempliceComponent,
    TabellaWithOptionsComponent,
    SideLeftMenuPotenziometriComponent,
    SideRightNavmenuComponent,
    TimelineRisultatiQuestionarioComponent,
    TopBarPotenziometriComponent,
    CategorieComponent,
    HeaderComponent,
    SplashpageRisultatiComponent,
    InfoTopSectionComponent,
    TabellaSplashpageComponent,
    IntestationPlashSectionComponent,
    ModalcontentComponent,
    BarChartComponent,
    LineChartComponent,
    CensimentoClienteComponent,
    ListaClientiComponent,
    ClienteComponent,
    DettagliClienteComponent,
    CensimentoRichiedenteComponent,
    ListaRichiedentiComponent,
    CensimentoAtecoSecondariComponent,
    GestioneAnagraficaComponent,
    ConfigurazioneAmbienteComponent,
    ParametriGeneraliComponent,
    LineeBusinessComponent,
    DistintaBaseProdottiComponent,
    BarChartIndicatoriBilancioComponent,
    DoubleLineBarChartComponent,
    TimelineWithButtonsComponent,
    SintesiAsIsComponent,
    SintesiEndStrokeComponent,
    SintesiGraphGroupComponent,
    SintesiPreciselyYearComponent,
    DettaglioRisultatiComponent,
    ModelloCommercialeComponent,
    ListaBplanOldComponent,
    AnagraficaQuestionarioOldComponent,
    QuestionarioOldComponent,
    ProdottoComponent,
    LineaBusinessComponent,
    ConfigurazioneBusinessPlanComponent,
    IndicatoriGuidaBpComponent,
    InserimentoRicaviCostiComponent,
    ConfiguarazioneMagazzinoComponent,
    LineaBusinessBpComponent,
    ListaUtentiComponent,
    UserDialogFormComponent,
    GestionePivaDialogComponent,
    ListaBplanNuovaComponent,
    TooltipComponent,
    DossierAmministrativiComponent,
    BilancioManualeComponent,
    CreaDossierComponent,
    InputBenchmarkComponent,
    BenchCellComponent,
    CompetitorDialogComponent,
    DatiMercatoBenchRowComponent,
    DettagliDatiMercatoComponent,
    DettagliAziendaTargetComponent,
    InvestimentiDisinvestimentiBpComponent,
    SituazioneCreditiziaComponent,
    ConfigurazioneCorrelazioniAmbienteComponent,
    SituazioneDebitoriaNuovoComponent,
    CheckBoxListComponent,
    ListaSmartBpComponent,
    SituazioneDebitoriaAmbienteComponent,
    SituazioneCreditiziaAmbienteComponent,
    SmartBpComponent,
    CensimentoSmartComponent,
    SituazioneFinanziariaComponent,
    SituazioneCreditiziaSmartComponent,
    SituazioneDebitoriaSmartComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgxPaginationModule,
    ShowHidePasswordModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    FontAwesomeModule,
    FilterPipeModule,
    NgChartsModule,
    BrowserAnimationsModule,
    MatIconModule,
    NoopAnimationsModule,
    MatTabsModule,
    MaterialModule,
    MatTableModule,
    MatExpansionModule,
    MatButtonModule,
    MatDialogModule,
    MatChipsModule,
    NgxCurrencyModule,
    UserIdleModule.forRoot({ idle: 900, timeout: 60, ping: 0 }),// idle tempo di inattività, timeout: countdown per prima che mando l'utente al log out, se modificato cambiare modal-content.ts
  ],
  providers: [authInterceptorProviders, CurrencyPipe, DecimalPipe, { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService, { provide: LOCALE_ID, useValue: 'it' }, { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
