<h1 mat-dialog-title disableBackdropClick>Dettagli dati mercato per codice ateco : {{this.data.tk}}</h1>
<div mat-dialog-content>
    <div class="container container-data">
        <div class="row">
            <div class="col-10">
                <div class="col-12 observed">
                  <div class="row-cols-5">
                    <div class="col Pdata">
                      <app-dettagli-azienda-target>
                      </app-dettagli-azienda-target>
                    </div>
                    <div class="col Pdata">
                      <app-dettagli-azienda-target>
                      </app-dettagli-azienda-target>
                    </div>
                    <div class="col Pdata"> 
                      <app-dettagli-azienda-target>
                    </app-dettagli-azienda-target></div>
                    <div class="col Pdata">
                      <app-dettagli-azienda-target>
                      </app-dettagli-azienda-target>
                    </div>
                    <div class="col Pdata">
                      <app-dettagli-azienda-target>
                      </app-dettagli-azienda-target>
                    </div>
                  </div>
                  
                </div>

                <div class="row-cols-5">
                  <div class="col Sdata">1</div>
                  <div class="col Sdata">2</div>
                  <div class="col Sdata">3</div>
                  <div class="col Sdata">4</div>
                  <div class="col Sdata">5</div>
                </div>
            </div>

              <div class="col-2">
              
                <div class="contDet">

                </div>

              </div>

          </div>
</div>
    <div class="container container-dettagli-mercato">
     
        <div class="chartRow">    
            <div class="row-cols-5 d-flex subGraph">
                <div class="col canv dists" *ngFor="let set of allBarChartData;index as i">
                    <div>
                        <canvas
                          height="100%"
                          width="100%"
                          baseChart
                          [data]="set"
                          [options]="barChartOptions"
                          [type]="barChartType"
                          (chartClick)="chartClicked($event)">
                        </canvas>
                      </div>
                </div>
            </div>

            <div class="row index-row-type">
                <ul class="legenda col-7">
                    <li class="alimType" *ngFor="let Tipo of TipiAlimentazione;index as i"> 
                        <section>
                           <div class="colorsquare {{'colore'+i}}"></div>
                            <label>&nbsp;&nbsp;{{Tipo.tipoAlimentazione}} </label>
                        </section>
                    </li>
                </ul>

                <div class="col-2 col-lg-2 margin-bottom-10 formReg" [formGroup]="regioneForm">
                  <label>Seleziona i dati di settore regionali:</label>
              </div>
                <div class="col-2 col-lg-2 margin-bottom-10 formReg" [formGroup]="regioneForm">
                  <select formControlName="regioneControl" class="form-control" (change)="onChangeRegione()">
                      <option  *ngFor="let regione of listaRegioni" [ngValue]="regione">
                          {{regione.regione}}
                    </option>
                  </select>
                </div>
    
            </div>


         

        </div>

    </div>

</div>
<div mat-dialog-actions class="rigaDialogDettagliMercato">
    <div class="row">
        <button class="btn btn-danger" (click)="onNoClick()">Chiudi</button>
    </div>
    
   
</div>