import { Component, Input, OnInit } from '@angular/core';
import { ValoriTabellaWithOption } from 'src/app/interface/tabelle/valori-tabella-with-option';



@Component({
  selector: 'app-tabella-with-options',
  templateUrl: './tabella-with-options.component.html',
  styleUrls: ['./tabella-with-options.component.css']
})
export class TabellaWithOptionsComponent implements OnInit {

  styleTabellaGaranzie: string[] = ["col-12",
    "row margin-0 text-center",
    "col-3 borderRBlack borderTBlack borderLBlack borderBBlack min-height-25 margin-top-10 font-bold",
    "col-3 borderRBlack borderBBlack min-height-25"];

  @Input() valsTab: ValoriTabellaWithOption;
  @Input() ngClass: string[];
  @Input() number_pipe: boolean;
  @Input() indicatore_eventBreake: boolean;
  @Input() maxFrequenzeDebito: number;
  @Input() coloreDebito: string;
  @Input() frequenza: any;
  @Input() indicatoreEconometrico: string;

  constructor() {

  }

  ngOnInit(): void {

  }

}
