import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StikyService {

  constructor() { }

  calcolaSticky = new BehaviorSubject<boolean>(false);
  _calcolaSticky: Observable<boolean> = this.calcolaSticky.asObservable();

  reloadSticky() {
    this.calcolaSticky.next(true);
  }

  setPaddingTopDebitoria = new BehaviorSubject<boolean>(false);
  _setPaddingTopDebitoria: Observable<boolean> = this.setPaddingTopDebitoria.asObservable();

  reloadPaddingTopDebitoria() {
    this.setPaddingTopDebitoria.next(true);
  }

  setPaddingTopRicaviCosti = new BehaviorSubject<boolean>(false);
  _setPaddingTopRicaviCosti: Observable<boolean> = this.setPaddingTopRicaviCosti.asObservable();

  reloadPaddingTopRicaviCosti() {
    this.setPaddingTopDebitoria.next(true);
  }

  reloadPaddingToRivcaviCosti() {
    this.setPaddingTopRicaviCosti.next(true);
  }
}
