import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Richiedente } from 'src/app/interface/richiedente';
import { RichiedenteService } from 'src/app/_services/richiedente.service';
import { TokenStorageService } from './../../_services/token-storage.service';
import { ClienteService } from './../../_services/cliente.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalcontentComponent } from 'src/app/modalcontent/modalcontent/modalcontent.component';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { ToolTip } from 'src/app/interface/toolTip';
import { ToolTipService } from 'src/app/_services/tool-tip.service';

@Component({
  selector: 'app-lista-richiedenti',
  templateUrl: './lista-richiedenti.component.html',
  styleUrls: ['./lista-richiedenti.component.css']
})
export class ListaRichiedentiComponent implements OnInit {

  @Output() selectedAzienda = new EventEmitter<Richiedente>();
  @Output() mostraAziende = new EventEmitter<boolean>();

  numeroPivaUtilizzate: number;
  numeroPivaUtilizzateTemp: number;
  listaRichiedenti: Richiedente[] = [];
  faInfo = faInfo;
  bpIsPresent: boolean[]; /* query count a be */
  page = 1;
  userFilter: any = { riferimento: '' };
  idRichiedenti: number[] = [];
  listaTips: ToolTip[] = [];
  constructor(
    private richiedenteService: RichiedenteService,
    private clienteService: ClienteService,
    private tokenStorageService: TokenStorageService,
    private modalService: NgbModal,
    private toolTipService: ToolTipService,
  ) { }

  ngOnInit(): void {
    /* popola la tabella dei richiedenti */
    this.richiedenteService.getAnagraficaRichiedenteByidCliente().subscribe((richiedenti) => {
      this.listaRichiedenti = richiedenti;
      this.listaRichiedenti.forEach(richiedente => {
        /* vettore per di id per il check dei BP per ogni cliente del commercialista (per il delete) */
        this.idRichiedenti.push(richiedente.id);
      })
      this.bpIsPresent = []
      this.richiedenteService.bpIsPresent(this.idRichiedenti).subscribe(resp => {
        resp.forEach(par => {
          this.bpIsPresent[par.idRichiedente] = par.bpIsPresent
        })

      })
    })
    this.toolTipService.getTipsContent('ANA').subscribe(risultato => {
      if (risultato) {
        risultato.forEach(el => {
          this.listaTips.push(el)
        })
        this.listaTips.sort((n1, n2) => n1.numero - n2.numero)
      }
    })

    this.clienteService.getNumeroPivaUtilizzate().subscribe((numeroPivaUtilizzate) => {
      this.numeroPivaUtilizzate = numeroPivaUtilizzate;
    })

  }
  modificaRichiedente(richiedente: Richiedente) {
    this.selectedAzienda.emit(richiedente)
  }
  eliminaRichiedente(i: number, richiedente: Richiedente) {
    if (!this.bpIsPresent[richiedente.id]) {
      if (this.modalService.hasOpenModals) {
        this.modalService.dismissAll();
      }
      const modalRef = this.modalService.open(ModalcontentComponent, {
        backdrop: 'static',
        backdropClass: 'customBackdrop',
      });
      modalRef.componentInstance.modalPar = {
        name: "eliminarichiedente",
        description: "Sei sicuro di voler eliminare l'azienda " + richiedente.ragioneSociale + "?",
        undoButtonText: "Annulla",
        actionButtonText: "Elimina",
      }
      modalRef.result.then(r => {
        if (r) {
          /* salvo nel service */
          this.numeroPivaUtilizzateTemp = (this.numeroPivaUtilizzate - 1);
          this.clienteService.updateNumeroPivaUtilizzate(this.numeroPivaUtilizzateTemp)
          let request = {
            idRichiedente: richiedente.id,
            idCliente: this.tokenStorageService.getUser().cliente.idCliente,
            numeroPivaUtilizzate: this.numeroPivaUtilizzateTemp,
          }
          this.richiedenteService.DeleteRichiedente(request).subscribe(response => {
            if (this.modalService.hasOpenModals) {
              this.modalService.dismissAll();
            }
            const modalRef = this.modalService.open(ModalcontentComponent, {
              backdrop: 'static',
              backdropClass: 'customBackdrop',
            });
            modalRef.componentInstance.modalPar = {
              name: "messaggioelimina",
              description: response.response,
              actionButtonText: "",
              undoButtonText: "Ok",
            }
            modalRef.result.then(r => {
              this.mostraAziende.emit(false);
            })
          })
        }
      })
    }
  }

}
