import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable } from 'rxjs';
import { environment } from '../../environments/environment';
const ApiUrl =  environment.apiUrlNode + '/api/anagraficaInt/';
@Injectable({
  providedIn: 'root'
})

export class AnagraficaIntestatarioService {

  constructor(private http: HttpClient) { }

  getAllUsers(utente: string): Observable<any> {
    return this.http.get(ApiUrl + 'allAnagrafica/'+utente, { responseType: 'text' });
  }

  insertUsers(form: any): Observable<any> {
    return this.http.post(ApiUrl + 'addAnagrafica', {
      ragione_sociale: form.ragione_sociale.trim(),
      id_tipo_azienda_mcc: form.id_tipo_azienda_mcc,
      partita_iva: form.partita_iva,
      sede_legale: form.sede_legale.trim(),
      utente: form.utente
    },
    { responseType: 'text' });
  }

  updateUsers(form: any): Observable<any> {
    return this.http.put(ApiUrl + 'updateAnagrafica', {
      id: form.id,
      ragione_sociale: form.ragione_sociale.trim(),
      id_tipo_azienda_mcc: form.id_tipo_azienda_mcc,
      partita_iva: form.partita_iva,
      sede_legale: form.sede_legale.trim()
    },
    { responseType: 'text' });
  }
}
