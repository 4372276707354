import { AfterContentChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { InterazioneRisultatiQuestionarioService } from '../../../interazione-risultati-questionario.service';
import { ValoriTabellaSemplice } from '../../../interface/tabelle/valori-tabella-semplice';

@Component({
  selector: 'app-tabella-semplice',
  templateUrl: './tabella-semplice.component.html',
  styleUrls: ['./tabella-semplice.component.css']
})

export class TabellaSempliceComponent implements OnInit, OnDestroy {

  @Input() valsTab: ValoriTabellaSemplice;
  @Input() ngClass: string[];
  @Input() number_pipe: boolean;

  @Input() focusDSRC: boolean = false;
  @Input() focusDsrcStyle: boolean = false;

  @Input() vertical: boolean = false;

  subscriptionDSRC: Subscription;

  constructor(private _interazioneRisultatiQuestionarioService: InterazioneRisultatiQuestionarioService) { }

  ngOnInit(): void {
    this.subscriptionDSRC = this._interazioneRisultatiQuestionarioService.DSCRFocus$.subscribe(focusClass => {
      this.focusOnDSCR(focusClass);
    })
  }


  ngOnDestroy() {
    this.subscriptionDSRC.unsubscribe();
  }

  focusOnDSCR(className: string) {
    let DSCR_DomElement = document.getElementsByClassName(className);//
    if (typeof DSCR_DomElement[0] !== "undefined") {
      console.log('event focus dscr element dom : ' + DSCR_DomElement[0]);
      DSCR_DomElement[0].classList.add('DSCRfocusChange');
      DSCR_DomElement[1].classList.add('DSCRfocusChange');
    }
  }

}
