<h1 mat-dialog-title disableBackdropClick>Selezione Competitors di mercato</h1>
<div mat-dialog-content>
  <div class="container">
    <div class="row" style="overflow: hidden;">
      <div class="col-sm-4">
        <h3> Competitor assegnabili:</h3>
        <mat-chip-list class="mat-chip-list-stacked">
          <mat-chip *ngFor="let Aric of listaCompetitors">
            {{Aric.descrizione}} <button matChipRemove (click)="changeSide(this.Aric,'sx')">
              <mat-icon>add_circle</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="col-sm-4">
        <h3> Competitor assegnati:</h3>
        <mat-chip-list class="mat-chip-list-stacked">
          <mat-chip *ngFor="let Aric of listaCompetitorsSelezionati">
            {{Aric.descrizione}} <button matChipRemove (click)="changeSide(this.Aric,'dx')">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="col-sm-4">
        <h4>Seleziona indicatori:</h4>
        <ul>
          <li *ngFor="let var of listaVariabili; index as i;">
            <section class="sezioneVariabili">
              <mat-checkbox value="{{i}}" (change)="checkCheckBoxvalue($event)"
                [disabled]="this.listaCheckedVars.length == 7 && getNotActiveCheckbox(this.var.variabileLabel)"
                [checked]="!getNotActiveCheckbox(this.var.variabileLabel)">{{this.var.variabileLabel}}</mat-checkbox>
            </section>
          </li>
        </ul>

      </div>
    </div>

  </div>
  <div class="row rigaAteco">
    <h4>Codice ATECO prevalente*</h4>
   
    <div  class="col-7 col-lg-7 col-xl-7 margin-bottom-10">
        <label>Divisione</label>
   

        <ng-select
        [items]="listaAtecoPrimarioDivisione"
        [multiple]="true"
        [selectableGroupAsModel]="false"
        bindLabel="descrizioneCompleta"
        [closeOnSelect]="false"
        bindValue="id"
        [disabled]="true"
        [(ngModel)]="selectedAtecos1"> 
          
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.descrizioneCompleta}}
                  </ng-template>
                 
      </ng-select>
       
    </div>
    <div    class="col-7 col-lg-7 col-xl-7 margin-bottom-10">
        <label>Gruppo</label>
        <ng-select
          [items]="listaAtecoPrimarioGruppo"
          [multiple]="true"
          [selectableGroupAsModel]="false"
          bindLabel="descrizione"
          [closeOnSelect]="false"
          bindValue="id"
          [(ngModel)]="selectedAtecos2" (change)="onChange()">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.descrizioneCompleta}}
                    </ng-template>
                    
                    
        </ng-select>
      
    </div>

    <div  class="col-4 col-lg-4 col-xl-4 margin-bottom-10">
      <label>Classe</label>
      <ng-select
      [items]="listaAtecoPrimarioClasse"
      [multiple]="true"
      [selectableGroupAsModel]="false"
      bindLabel="descrizione"
      [closeOnSelect]="false"
      bindValue="id"
      [disabled]="selectedAtecos2.length != 1"
      [(ngModel)]="selectedAtecos3" >
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.descrizioneCompleta}}
                </ng-template>
     </ng-select>
    
  </div>

    
</div>

</div>
<div mat-dialog-actions class="rigaDialog">
  <button class="btn btn-danger" (click)="onNoClick()">Annulla</button>
  <button class="btn btn-primary" (click)="saveComp()" [disabled]="!controllo" style="margin-left:10px;">Salva</button>
</div>