import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { Subscription } from 'rxjs';
import { LineaBusinessComponent } from './linea-business/linea-business.component';
import { LineaBusiness } from 'src/app/condivisi/linea-business';

@Component({
  selector: 'app-distinta-base-prodotti',
  templateUrl: './distinta-base-prodotti.component.html',
  styleUrls: ['./distinta-base-prodotti.component.css']
})
export class DistintaBaseProdottiComponent implements OnInit {
  /* prendo in input  idRichiedenteTemp*/
  @Input() isDirtyProdotti: boolean[];
  @Input() get tabIndex(): number { return this.currentIndex; };
  @Input() get idRichiedenteTemp(): number { return this.idRichiedenteTemp1; }

  @ViewChildren(LineaBusinessComponent) lineaBusinessBpComponent!: QueryList<LineaBusinessComponent>;
  @ViewChild('firstTab') firstTab!: ElementRef;
  /* le sezioni del mat-tab child cambiano al variare dell'id Azienda e si caricano quando mi trovo su distinta base (index=2) */
  set tabIndex(tabIndex: number) {
    this.currentIndex = tabIndex;
  }
  set idRichiedenteTemp(idRichiedenteTemp: number) {
    this.idRichiedenteTemp1 = idRichiedenteTemp;
    if (this.idRichiedenteTemp1 != undefined) {
      this.ngOnInit();//forse
    }
  }
  lineeBusiness: LineaBusiness[] = [];
  currentIndex: number;
  private idRichiedenteTemp1: number;
  distintaBaseProdottiArray: any[] = [{}];
  distintaBaseProdottiForm: UntypedFormGroup;
  currentLineeSavedSub: Subscription
  constructor(
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private tokeStorageService: TokenStorageService,
  ) { }
  ngOnInit(): void {
    /* sia se salva che elimina devo ricaricare le linee bp */
    this.currentLineeSavedSub = this.configurazioneAmbienteService.currentLineedSaved.subscribe(lineeAreSaved => {
      this.getLineeBusiness()
    })

  }
  getLineeBusiness() {
    if (this.idRichiedenteTemp1 != undefined) {
      this.currentIndex = 1;
      this.configurazioneAmbienteService.getLineeBusiness(this.idRichiedenteTemp, this.tokeStorageService.getUser().cliente.idCliente).subscribe(response => {
        this.lineeBusiness = []
        this.isDirtyProdotti = []
        //console.log(this.lineeBusiness)
        if (response.lineeBusiness) {
          for (let i = 0; i < response.lineeBusiness.length; i++) {
            const lineaBusiness: LineaBusiness = {
              id: response.lineeBusiness[i].id,
              nomeLineaBusiness: response.lineeBusiness[i].nomeLineaBusiness,
              idTipologiaAtt: response.lineeBusiness[i].idTipologiaAtt,
              idCategoriaProd: response.lineeBusiness[i].idCategoriaProd,
              idRegione: response.lineeBusiness[i].idRegione,
            };
            this.lineeBusiness.push(lineaBusiness);
            this.isDirtyProdotti.push(false);
          }
        }
        /* this.currentIndex=0; */
      })
    }
  }

  /*   resetScrollingSize() {
      this.configurazioneAmbienteService.resetDistintaBase.next(true);
    } */
  ngOnDestroy() {
    this.currentLineeSavedSub.unsubscribe()
  }
}
