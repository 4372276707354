import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { ToolTip } from 'src/app/interface/toolTip';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, Subject, Subscription } from 'rxjs';
import { LineaBusiness } from 'src/app/condivisi/linea-business';
import { VarietaProdotto } from 'src/app/condivisi/prodotto';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { BeanStatico } from 'src/app/condivisi/beanStatico';

import { Provincia } from 'src/app/condivisi/provincia';
import { ProdottiDropdown } from 'src/app/condivisi/prodotti-dropdown';

@Component({
  selector: 'app-prodotto',
  templateUrl: './prodotto.component.html',
  styleUrls: ['./prodotto.component.css']
})
export class ProdottoComponent implements OnInit, OnDestroy {
  @Input() valoreMax: any;
  @Input('formGroup') prodottoForm: UntypedFormGroup;
  @Input() i: number;
  @Input() submitted: boolean = true;
  @Input() lineaBusiness: LineaBusiness
  @Input() dropsDown: ProdottiDropdown


  @Output() selectedProdotto = new EventEmitter<number>();
  //@Output() presenzaFidelizzazione = new EventEmitter<any>();

  idProdottoTemp: number = -1
  listaTips: ToolTip[] = [];
  tipsSubscription: Subscription
  listaVarietaPerIdCategoria: VarietaProdotto[] = []
  constructor(
    private toolTipService: ToolTipService,
  ) {

  }

  ngOnDestroy(): void {
    this.tipsSubscription.unsubscribe()
  }
  ngOnInit(): void {
    /* carixare i dati del prodotto */
    if (this.prodottoForm.controls.coltura.value) {
      this.listaVarietaPerIdCategoria = []
      this.dropsDown.listaVarieta.map(varieta => {
        if (varieta.idProdotto == this.prodottoForm.controls.coltura.value.id) {
          this.listaVarietaPerIdCategoria.push(varieta)
        }
      })
    }

    this.tipsSubscription = this.toolTipService.tipsArray.subscribe(arr => {
      if (arr != null) {
        this.listaTips = arr
      }
    })

    this.prodottoForm.controls.coltura.valueChanges.subscribe(categoria => {
      if (categoria === undefined || (categoria && this.idProdottoTemp != categoria.id)) {
        this.listaVarietaPerIdCategoria = []
        if (categoria) {
          this.idProdottoTemp = this.prodottoForm.controls.coltura.value.id
          this.prodottoForm.controls.varieta.enable()
          this.prodottoForm.controls.varieta.setValue('');

          this.dropsDown.listaVarieta.map(varieta => {
            if (varieta.idProdotto == categoria.id) {
              this.listaVarietaPerIdCategoria.push(varieta)
            }
          })
          if (this.listaVarietaPerIdCategoria.length == 1) {
            this.prodottoForm.controls.varieta.setValue(this.listaVarietaPerIdCategoria[0])
            this.onChangeVarieta()
          }

        } else {
          this.prodottoForm.controls.varieta.setValue('');
          this.prodottoForm.controls.varieta.disable()
          this.idProdottoTemp = -1
        }
        this.prodottoForm.controls.varieta.updateValueAndValidity()
      }
    })

  }


  onChangeVarieta() {
    if (this.prodottoForm.controls.varieta.value) {
      if (this.prodottoForm.controls.varieta.value.latenza > 2) {
        this.prodottoForm.controls.etaPiante.addValidators(Validators.required)
      } else {
        this.prodottoForm.controls.etaPiante.clearValidators()
      }
      this.prodottoForm.controls.etaPiante.updateValueAndValidity()
    }
  }

  /* onChangeFidelizzazione(index: number) {
    this.prodottoForm.controls.presenzaFidelizzazione.updateValueAndValidity();
    const par = {
      index: index,
      presenzaFidelizzazione: this.prodottoForm.controls.presenzaFidelizzazione.value,
    }
    this.presenzaFidelizzazione.emit(par);
  } */


  eliminaProdotto(index: number) {
    /* evenetemitter */
    this.selectedProdotto.emit(index);
  }

  //sezione del typeahead
  formatterProdotto = (prodotto: BeanStatico) => prodotto.descrizione;

  @ViewChild('instanceProdotto', { static: true }) instanceProdotto!: NgbTypeahead;
  focusProdotto$ = new Subject<string>();
  clickProdotto$ = new Subject<string>();


  searchProdotto = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickProdotto$.pipe(filter(() => this.instanceProdotto && !this.instanceProdotto.isPopupOpen()));
    const inputFocus$ = this.focusProdotto$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.dropsDown.listaProdotti
        : this.dropsDown.listaProdotti.filter(prodotto => new RegExp(term, 'mi').test(prodotto.descrizione))))
    );
  }
  //typeahead provincia
  formatter = (provincia: Provincia) => provincia.provincia;

  @ViewChild('instanceProvincia', { static: true }) instanceProvincia!: NgbTypeahead;
  focusProvincia$ = new Subject<string>();
  clickProvincia$ = new Subject<string>();


  searchProvincia = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickProvincia$.pipe(filter(() => this.instanceProvincia && !this.instanceProvincia.isPopupOpen()));
    const inputFocus$ = this.focusProvincia$;


    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.dropsDown.listaProvince
        : this.dropsDown.listaProvince.filter(provincia => new RegExp(term, 'mi').test(provincia.provincia))))
    );
  }
}
