import { Router } from '@angular/router';
import { PraticheService } from './../_services/pratiche.service';
import { TokenStorageService } from './../_services/token-storage.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Pratiche } from '../component/pratiche';
import { FilterPipe } from 'ngx-filter-pipe';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EbaComplianceService } from '../_services/ebaCompliance.service ';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
@Component({
  selector: 'app-lista-bplan',
  templateUrl: './lista-bplan.component.html',
  styleUrls: ['./lista-bplan.component.css'],


})
export class ListaBplanComponent implements OnInit {
  /* @ViewChild('modaleElimina') modaleElimina: any; */

  user: String = '';
  pratiche: Pratiche[] = [];
  p = 1;
  isSort: boolean = false;
  userFilter: any = { riferimento: '' };
  praticaModale: Pratiche = null;
  formTotale: UntypedFormGroup;

  constructor(private tokenService: TokenStorageService,
    private praticheService: PraticheService,
    private router: Router,
    private filterPipe: FilterPipe,
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private datiQuestionarioService: DatiQuestionarioService,
    private modalService: NgbModal,
    private ebaComplianceService: EbaComplianceService,
  ) {

    this.formTotale = this.formBuilder.group({

      anagrafica: this.formBuilder.group({
        nomeAzienda: ['', Validators.required],
        nomeRichiedente: [''],
        cognomeRichiedente: [''],
        emailRichiedente: [''],
        telefonoRichiedente: [''],
        codiceFiscale: [''],
        tipoRichiedente: ['', Validators.required],
        sedeLegale: [''],
        comune: [{ value: '', disabled: true }, Validators.required],
        provincia: ['', Validators.required],
        cap: [],
        titoloStudio: ['', Validators.required],
        esperienza: ['', [Validators.required, Validators.min(0)]],
        tipoAzienda: [''],
        dichiarazioneIVA: [''],
        rigaVE50: [''],
        rigaVF14: [''],
        rigaVF23: [''],
        rigaVF27: [''],
        costiAnnoPrecedente: [''],
        ricaviAnnoPrecedente: [''],
        presenzaCr: [''],
        presenzaBilancio: [''],
        importoDebitoLT: [''],
        importoDebitoMT: [''],
        importoDebitoBT: [''],
        ateco: ['', Validators.required],
        partitaIva: [''],
        bilancio: [''],
        centraleRischi: [''],
        anagraficaAzienda: this.formBuilder.group({
          creditiBreveTermine: [''],
          debitiBreveTermine: [''],
          debitiAutoliquidanti: [''],
          debitiARevoca: [''],
          debitiAScadenza: [''],
          durataMutuo: [''],
          tassoInteresse: [''],
          rimanenzaMateriePrime: [''],
          rimanenzaProdottiIniziali: [''],
          ammortamentoIniziale: [''],
          immobilizzazioniIniziali: [''],
          variazioneRimanenzeAnnoIncorso: [''],
          datiAzienda: this.formBuilder.array([]),
        }),
      }),
      investimento: this.formBuilder.group({
        descrizioneProgetto: [''],
        presentazione: [''],
        presentazioneRadio: [false],
        produzioneProgettoArray: this.formBuilder.array([
          this.formBuilder.group({
            tipoProgetto: ['', Validators.required],
            comune: [{ value: '', disabled: true }],
            provincia: [''],
            bio: [false],
            ettari: [''],
            importo: [''],
            affittoTerreno: [''],
            isPropietario: [false],
            macchinari: [''],
            mqSerra: [''],
            //Colture aggiuntive per la spartizione del terreno
            coltureDivisioneArray: this.formBuilder.array([
              this.formBuilder.group({
                bio: [false],
                categoria: [{ value: '', disabled: true }, Validators.required],
                prodotto: [{ value: '', disabled: true }, Validators.required],
                varieta: [{ value: '', disabled: true }, Validators.required],
                produzione: [''],
                ettariColtura: [{ value: '', disabled: true }, Validators.required],
                etaPiante: [''],
                contratto: [''],
                qualitaProduzione: [''],
                coltureRotazioneArray: this.formBuilder.array([])
              })
            ]),
          })
        ]),
      }),
      garanzie: this.formBuilder.group({
        contributoFinanziamento: ['', Validators.required],
        contributoPersonale: ['', Validators.required],
        durataFinanziamentoAnni: ['', Validators.required],
        durataAmmortamentoAnni: [2, Validators.required],
        presenzaGaranzie: ['', Validators.required],
        percentualeGaranzia: [0.2],
        tipologiaGaranzia: [''],

      })
    })
  }

  ngOnInit(): void {
    this.user = this.tokenService.getUser().username;
    this.leggiPratiche();
    this.datiQuestionarioService.cancellaTutto();
    this.praticheService.changeParamPratiche(null);

    //  console.log(this.filterPipe.transform(this.pratiche, { riferimento: ''}));

  }

  get anagraficaForm(): UntypedFormGroup { return <UntypedFormGroup>this.formTotale.controls.anagrafica; }

  get anagraficaAziendaForm(): UntypedFormGroup { return <UntypedFormGroup>this.anagraficaForm.controls.anagraficaAzienda; }

  get aziendaArray(): UntypedFormArray { return <UntypedFormArray>this.anagraficaAziendaForm.controls.datiAzienda; }
  get aziendaFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.aziendaArray.controls }

  get anagraficaProdottiArray(): UntypedFormArray { return <UntypedFormArray>this.anagraficaForm.controls.produzioneArray }

  get investimentoForm(): UntypedFormGroup { return <UntypedFormGroup>this.formTotale.controls.investimento; }

  get investimentoProdottiArray(): UntypedFormArray { return <UntypedFormArray>this.investimentoForm.controls.produzioneProgettoArray }

  get investimentoProdottiGroup(): UntypedFormGroup[] { return <UntypedFormGroup[]>this.investimentoProdottiArray.controls }

  get indebitamentoForm(): UntypedFormGroup { return <UntypedFormGroup>this.formTotale.controls.indebitamento; }

  get garanzieForm(): UntypedFormGroup { return <UntypedFormGroup>this.formTotale.controls.garanzie; }

  leggiPratiche(): void {
    this.praticheService.getAllPratiche(this.user).subscribe(
      (risultato) => {
        this.pratiche = JSON.parse(risultato).risultato; //console.log(risultato)
        if (this.pratiche.length === 0) {
          this.router.navigate(['/questionario'])
        };
        ;
      }, (error) => { console.log(error); }
    );
  }
  sortDate(): void {
    //let newarr = [];
    if (this.isSort) {
      this.isSort = false;
      this.pratiche.sort(function (a, b) {
        // convert date object into number to resolve issue in typescript
        return +new Date(a.data_registrazione) - +new Date(b.data_registrazione);
      })

      // newarr= this.pratiche.sort((a, b) => b.data_registrazione.valueOf() - a.data_registrazione.valueOf());

    }
    else {
      this.isSort = true;
      this.pratiche.sort(function (a, b) {
        // convert date object into number to resolve issue in typescript
        return +new Date(b.data_registrazione) - +new Date(a.data_registrazione);
      })
      // newarr= this.pratiche.sort((a, b) => a.data_registrazione.valueOf() - b.data_registrazione.valueOf());

    }
  }

  selPratica(elemento: Pratiche): void {
    this.praticheService.getBusinessPlan(elemento.id_business_plan).subscribe(data => {

      data.businessPlan.ragioneSociale ? this.anagraficaForm.controls.nomeAzienda.setValue(data.businessPlan.ragioneSociale) : '';
      data.businessPlan.idTipoRichiedente > 0 ? this.anagraficaForm.controls.tipoRichiedente.setValue({ id: data.businessPlan.idTipoRichiedente }) : '';
      data.businessPlan.idTitoloStudio > 0 ? this.anagraficaForm.controls.titoloStudio.setValue({ id: data.businessPlan.idTitoloStudio }) : '';
      data.businessPlan.esperienzaSettore > -1 ? this.anagraficaForm.controls.esperienza.setValue(data.businessPlan.esperienzaSettore) : '';
      data.businessPlan.idTipoSocieta > 0 ? this.anagraficaForm.controls.tipoAzienda.setValue({ id: data.businessPlan.idTipoSocieta }) : '';
      data.businessPlan.idIntestatarioBilancio > 0 ? '' : this.anagraficaForm.controls.dichiarazioneIVA.setValue(data.businessPlan.rigaVe50 > 0);
      this.anagraficaForm.controls.rigaVE50.setValue(data.businessPlan.rigaVe50);
      this.anagraficaForm.controls.rigaVF14.setValue(data.businessPlan.rigaVf14);
      this.anagraficaForm.controls.rigaVF23.setValue(data.businessPlan.rigaVf23);
      this.anagraficaForm.controls.rigaVF27.setValue(data.businessPlan.rigaVf27);
      this.anagraficaForm.controls.costiAnnoPrecedente.setValue(data.businessPlan.costiAnnoPrecedente);
      this.anagraficaForm.controls.ricaviAnnoPrecedente.setValue(data.businessPlan.ricaviAnnoPrecedente);
      this.anagraficaForm.controls.presenzaCr.setValue(data.businessPlan.crPresente);
      this.anagraficaForm.controls.presenzaBilancio.setValue(data.businessPlan.idIntestatarioBilancio > 0);
      this.anagraficaForm.controls.importoDebitoLT ? this.anagraficaForm.controls.importoDebitoLT.setValue(data.businessPlan.importoDebitoLt) : '';
      this.anagraficaForm.controls.importoDebitoMT ? this.anagraficaForm.controls.importoDebitoMT.setValue(data.businessPlan.importoDebitoMt) : '';
      this.anagraficaForm.controls.importoDebitoBT ? this.anagraficaForm.controls.importoDebitoBT.setValue(data.businessPlan.importoDebitoBt) : '';
      /* parte di anagrafica azienda da ristrutturare iln base a come passo i dati al BusinessPlan */
      /* this.anagraficaAziendaForm.controls.creditiBreveTermine ? this.anagraficaAziendaForm.controls.creditiBreveTermine.setValue(data.businessPlan.infoAzienda.creditiBreveTermine) : '';
      this.anagraficaAziendaForm.controls.debitiBreveTermine ? this.anagraficaAziendaForm.controls.debitiBreveTermine.setValue(data.businessPlan.infoAzienda.debitiBreveTermine) : '';
      this.anagraficaAziendaForm.controls.debitiAutoliquidanti ? this.anagraficaAziendaForm.controls.debitiAutoliquidanti.setValue(data.businessPlan.infoAzienda.debitiAutoliquidanti) : '';
      this.anagraficaAziendaForm.controls.debitiARevoca ? this.anagraficaAziendaForm.controls.debitiARevoca.setValue(data.businessPlan.infoAzienda.debitiARevoca) : '';
      this.anagraficaAziendaForm.controls.debitiAScadenza ? this.anagraficaAziendaForm.controls.debitiAScadenza.setValue(data.businessPlan.infoAzienda.debitiAScadenza) : '';
      this.anagraficaAziendaForm.controls.durataMutuo ? this.anagraficaAziendaForm.controls.durataMutuo.setValue(data.businessPlan.infoAzienda.durataMutuo) : '';
      this.anagraficaAziendaForm.controls.tassoInteresse ? this.anagraficaAziendaForm.controls.tassoInteresse.setValue(data.businessPlan.infoAzienda.tassoInteresse) : '';
      this.anagraficaAziendaForm.controls.rimanenzaMateriePrime ? this.anagraficaAziendaForm.controls.rimanenzaMateriePrime.setValue(data.businessPlan.infoAzienda.rimanenzaMateriePrime) : '';
      this.anagraficaAziendaForm.controls.rimanenzaProdottiIniziali ? this.anagraficaAziendaForm.controls.rimanenzaProdottiIniziali.setValue(data.businessPlan.infoAzienda.rimanenzaProdottiIniziali) : '';
      this.anagraficaAziendaForm.controls.ammortamentoIniziale ? this.anagraficaAziendaForm.controls.ammortamentoIniziale.setValue(data.businessPlan.infoAzienda.ammortamentoIniziale) : '';
      this.anagraficaAziendaForm.controls.immobilizzazioniIniziali ? this.anagraficaAziendaForm.controls.immobilizzazioniIniziali.setValue(data.businessPlan.infoAzienda.immobilizzazioniIniziali) : '';
      this.anagraficaAziendaForm.controls.variazioneRimanenzeAnnoIncorso ? this.anagraficaAziendaForm.controls.variazioneRimanenzeAnnoIncorso.setValue(data.businessPlan.infoAzienda.variazioneRimanenzeAnnoIncorso) : '';
      for(let i =0 ; i< this.aziendaArray.length; i++){
        this.aziendaArray[i].controls.valoreIniziale ? this.aziendaArray[i].controls.valoreIniziale.setValue(data.businessPlan.infoAzienda.aziendaArray[i].valoreIniziale) : '';
        this.aziendaArray[i].controls.valoreMax ? this.aziendaArray[i].controls.valoreMax.setValue(data.businessPlan.infoAzienda.aziendaArray[i].valoreMax) : '';
        this.aziendaArray[i].controls.valoreMin ? this.aziendaArray[i].controls.valoreMin.setValue(data.businessPlan.infoAzienda.aziendaArray[i].valoreMin) : '';
        this.aziendaArray[i].controls.drift ? this.aziendaArray[i].controls.drift.setValue(data.businessPlan.infoAzienda.aziendaArray[i].drift) : '';
        this.aziendaArray[i].controls.scarto ? this.aziendaArray[i].controls.scarto.setValue(data.businessPlan.infoAzienda.aziendaArray[i].scarto) : '';
        this.aziendaArray[i].controls.anniPrevisione ? this.aziendaArray[i].controls.anniPrevisione.setValue(data.businessPlan.infoAzienda.aziendaArray[i].anniPrevisione) : '';
      }*/
      data.businessPlan.idCodiceAteco > 0 ? this.anagraficaForm.controls.ateco.setValue({ id: data.businessPlan.idCodiceAteco }) : '';

      this.anagraficaForm.controls.partitaIva.setValue(data.businessPlan.partitaIva);
      data.businessPlan.idIntestatarioBilancio > 0 ? this.anagraficaForm.controls.bilancio.setValue({ idIntestatario: data.businessPlan.idIntestatarioBilancio, data: data.businessPlan.dataBilancio }) : '';
      data.businessPlan.idIntestatarioCr > 0 ? this.anagraficaForm.controls.centraleRischi.setValue({ id: data.businessPlan.idIntestatarioCr, data: data.businessPlan.dataCr }) : '';

      data.businessPlan.descrizioneProgetto && data.businessPlan.descrizioneProgetto != '' ? this.investimentoForm.controls.descrizioneProgetto.setValue(data.businessPlan.descrizioneProgetto) : '';
      data.businessPlan.idPresentazioneFileSys > 0 ? this.investimentoForm.controls.presentazione.setValue({ id: data.businessPlan.idPresentazioneFileSys }) : '';

      if (data.listaInvestimenti.length > 0) {
        for (let i = 0; i < data.listaInvestimenti.length; i++) {
          if (i > 0) {
            this.investimentoProdottiArray.push(this.initProdProgetto());
          }
          data.listaInvestimenti[i].investimento.idTipoInvestimento > 0 ? this.investimentoProdottiGroup[i].controls.tipoProgetto.setValue(data.listaInvestimenti[i].investimento.idTipoInvestimento) : '';
          data.listaInvestimenti[i].investimentoTerreni.idComune > 0 ? this.investimentoProdottiGroup[i].controls.comune.setValue({ id: data.listaInvestimenti[i].investimentoTerreni.idComune }) : '';
          data.listaInvestimenti[i].investimentoTerreni.idProvincia > 0 ? this.investimentoProdottiGroup[i].controls.provincia.setValue({ id: data.listaInvestimenti[i].investimentoTerreni.idProvincia, idRegione: data.listaInvestimenti[i].investimentoTerreni.idRegione }) : '';
          data.listaInvestimenti[i].investimentoTerreni.ettari > 0 ? this.investimentoProdottiGroup[i].controls.ettari.setValue(data.listaInvestimenti[i].investimentoTerreni.ettari) : '';
          data.listaInvestimenti[i].investimento.importoInvestimento > 0 ? this.investimentoProdottiGroup[i].controls.importo.setValue(data.listaInvestimenti[i].investimento.importoInvestimento) : '';
          data.listaInvestimenti[i].investimentoTerreni.affittoTerreno > 0 ? this.investimentoProdottiGroup[i].controls.affittoTerreno.setValue(data.listaInvestimenti[i].investimentoTerreni.affittoTerreno) : '';
          this.investimentoProdottiGroup[i].controls.isPropietario.setValue(data.listaInvestimenti[i].investimentoTerreni.affittoTerreno == 0 && data.listaInvestimenti[i].investimento.idTipoInvestimento == 3);
          //this.investimentoForm.controls.macchinari: [''],
          //this.investimentoForm.controls.mqSerra: [''],
          if (data.listaInvestimenti[i].listaOccurs.length > 0 && data.listaInvestimenti[i].investimento.idTipoInvestimento > 0) {
            let coltureDivisioneArray = <UntypedFormGroup[]>(<UntypedFormArray>this.investimentoProdottiGroup[i].controls.coltureDivisioneArray).controls;
            for (let j = 0; j < data.listaInvestimenti[i].listaOccurs.length; j++) {
              if (data.listaInvestimenti[i].listaOccurs[j].idOccursRiferimento > 0) {
                for (let k = 0; k < coltureDivisioneArray.length; k++) {
                  if (data.listaInvestimenti[i].listaOccurs[k].id == data.listaInvestimenti[i].listaOccurs[j].idOccursRiferimento) {
                    let coltureRotazioneArray = <UntypedFormGroup[]>(<UntypedFormArray>coltureDivisioneArray[k].controls.coltureRotazioneArray).controls;
                    coltureRotazioneArray.push(this.initColturaRotazione());
                    data.listaInvestimenti[i].listaOccurs[j].idCategoria > 0 ? coltureRotazioneArray[coltureRotazioneArray.length - 1].controls.categoria.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idCategoria }) : '';
                    data.listaInvestimenti[i].listaOccurs[j].idProdotto > 0 ? coltureRotazioneArray[coltureRotazioneArray.length - 1].controls.prodotto.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idProdotto }) : '';
                    data.listaInvestimenti[i].listaOccurs[j].idProdottoVarieta > 0 ? coltureRotazioneArray[coltureRotazioneArray.length - 1].controls.varieta.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idProdottoVarieta }) : '';
                    data.listaInvestimenti[i].listaOccurs[j].idCanaleDistribuzione > 0 ? coltureRotazioneArray[coltureRotazioneArray.length - 1].controls.contratto.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idCanaleDistribuzione }) : '';
                    data.listaInvestimenti[i].listaOccurs[j].idQualitaProduzione > 0 ? coltureRotazioneArray[coltureRotazioneArray.length - 1].controls.qualitaProduzione.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idQualitaProduzione }) : '';
                  }
                }

              } else {
                if (j > 0) {
                  coltureDivisioneArray.push(this.initColtura())
                }
                data.listaInvestimenti[i].listaOccurs[j].idCategoria > 0 ? coltureDivisioneArray[coltureDivisioneArray.length - 1].controls.categoria.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idCategoria }) : '';
                data.listaInvestimenti[i].listaOccurs[j].idProdotto > 0 ? coltureDivisioneArray[coltureDivisioneArray.length - 1].controls.prodotto.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idProdotto }) : '';
                data.listaInvestimenti[i].listaOccurs[j].idProdottoVarieta > 0 ? coltureDivisioneArray[coltureDivisioneArray.length - 1].controls.varieta.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idProdottoVarieta }) : '';
                coltureDivisioneArray[coltureDivisioneArray.length - 1].controls.produzione.setValue(data.listaInvestimenti[i].listaOccurs[j].idQualitaProduzione == 3 ? 'serra' : 'campo');
                data.listaInvestimenti[i].listaOccurs[j].ettari > 0 ? coltureDivisioneArray[coltureDivisioneArray.length - 1].controls.ettariColtura.setValue(data.listaInvestimenti[i].listaOccurs[j].ettari) : '';
                coltureDivisioneArray[coltureDivisioneArray.length - 1].controls.etaPiante.setValue(data.listaInvestimenti[i].listaOccurs[j].etaPiante);
                data.listaInvestimenti[i].listaOccurs[j].idCanaleDistribuzione > 0 ? coltureDivisioneArray[coltureDivisioneArray.length - 1].controls.contratto.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idCanaleDistribuzione }) : '';
                data.listaInvestimenti[i].listaOccurs[j].idQualitaProduzione > 0 ? coltureDivisioneArray[coltureDivisioneArray.length - 1].controls.qualitaProduzione.setValue({ id: data.listaInvestimenti[i].listaOccurs[j].idQualitaProduzione }) : '';
              }

            }
            this.validatoriInvestimento(this.investimentoProdottiGroup[i].controls, coltureDivisioneArray)

          }
          //if(this.investimentoProdottiGroup[i].valid) this.investimentoProdottiGroup[i].disable();
        }

      }

      data.businessPlan.contributoFinanziamento > 0 ? this.garanzieForm.controls.contributoFinanziamento.setValue(data.businessPlan.contributoFinanziamento) : '';
      data.businessPlan.contributoPersonale > 0 ? this.garanzieForm.controls.contributoPersonale.setValue(data.businessPlan.contributoPersonale) : '';
      data.businessPlan.durataFinanziamentoAnni > 0 ? this.garanzieForm.controls.durataFinanziamentoAnni.setValue(data.businessPlan.durataFinanziamentoAnni) : '';
      data.businessPlan.durataPreammortamento > 0 ? this.garanzieForm.controls.durataAmmortamentoAnni.setValue(data.businessPlan.durataPreammortamento) : '';
      this.garanzieForm.controls.presenzaGaranzie.setValue(data.businessPlan.tipoGaranzia != '');
      data.businessPlan.percGaranzia > 0 ? this.garanzieForm.controls.percentualeGaranzia.setValue(data.businessPlan.percGaranzia) : '';
      data.businessPlan.tipoGaranzia != '' ? this.garanzieForm.controls.tipologiaGaranzia.setValue(data.businessPlan.tipoGaranzia) : '';
      data.businessPlan.importoInvestimento > 0 ? this.datiQuestionarioService.changeImportoInvestimento(data.businessPlan.importoInvestimento) : '';
      this.formTotale.updateValueAndValidity();
      this.datiQuestionarioService.changeDatiQuestionario({
        questionario: this.formTotale,
        dataBusinessPlan: elemento.definitivo ? elemento.data_registrazione : new Date(),
      });
      this.praticheService.changeParamPratiche({
        idBusinessPlan: data.businessPlan.id,
        dataBusinessPlan: elemento.definitivo ? elemento.data_registrazione : new Date(),
        definitivo: elemento.definitivo,
        riferimentoOld: elemento.riferimento
      });
      if (elemento.definitivo) {
        this.router.navigate(['/preview']);
      } else {
        this.router.navigate(['/questionario']);
      }
    });

  }

  nuovaPratica(): void {
    //this.praticheService.savePratica('0')
    this.datiQuestionarioService.changeDatiQuestionario(null);
    this.router.navigate(['/questionario']);
  }

  eliminaPratica(idBusinessPlan): void {
    //this.praticheService.savePratica('0')
    this.praticheService.eliminaPratica(idBusinessPlan).subscribe(data => {
      this.leggiPratiche();
    });

  }

  goToEba(elemento: Pratiche): void {
    this.ebaComplianceService.changeDatiBp(
      {
        idBusinessPlan: elemento.id_business_plan,
        annoBusinessPlan: new Date(elemento.data_registrazione).getFullYear()
      }
    );
    this.router.navigate(['/eba']);

  }

  initProdProgetto() {
    return this.formBuilder.group({
      tipoProgetto: [''],
      comune: [{ value: '', disabled: true }],
      provincia: [''],
      bio: [false],
      ettari: [''],
      importo: [''],
      affittoTerreno: [''],
      isPropietario: [false],
      macchinari: [''],
      mqSerra: [''],
      coltureDivisioneArray: this.formBuilder.array([
        this.formBuilder.group({
          bio: [false],
          categoria: [{ value: '', disabled: true }, Validators.required],
          prodotto: [{ value: '', disabled: true }, Validators.required],
          varieta: [{ value: '', disabled: true }, Validators.required],
          produzione: [''],
          ettariColtura: [{ value: '', disabled: true }, Validators.required],
          etaPiante: [''],
          contratto: [''],
          qualitaProduzione: [''],
          coltureRotazioneArray: this.formBuilder.array([])
        })
      ]),
    })

  }

  initColtura() {
    return this.formBuilder.group({
      bio: [false],
      categoria: ['', Validators.required],
      prodotto: [{ value: '', disabled: true }, Validators.required],
      varieta: [{ value: '', disabled: true }, Validators.required],
      produzione: [''],
      ettariColtura: [''],
      etaPiante: [''],
      contratto: [''],
      qualitaProduzione: [''],
      coltureRotazioneArray: this.formBuilder.array([])
    })

  }

  initColturaRotazione() {
    return this.formBuilder.group({
      bio: [false],
      categoria: ['', Validators.required],
      prodotto: [{ value: '', disabled: true }, Validators.required],
      varieta: [{ value: '', disabled: true }, Validators.required],
      contratto: [''],
      qualitaProduzione: [''],
    })

  }

  openModalElimina(pratica): void {
    this.praticaModale = pratica;
    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll(); /*chiude tutto per sicurezza? */
    }
    const modalref = this.modalService.open(ModalcontentComponent, { centered: true });
    modalref.componentInstance.modalPar = {
      name: "cancellapratica",
      title: "Elimina pratica",
      description: "Sei sicuro di voler eliminare la pratica " + this.praticaModale.riferimento + "?",
      actionButtonText: "Elimina",
      undoButtonText: "Indietro",
    }
    modalref.result.then(r => {
      if (r) {
        this.eliminaPratica(this.praticaModale.id_business_plan);
      }
    })
  }

  /* chiudiModaleEdElimina(c: any){
    c('close modal');
    this.eliminaPratica(this.praticaModale.id_business_plan);

  } */

  validatoriInvestimento(formInvestimento, coltureDivisioneArray) {

    if (formInvestimento.tipoProgetto.value == 1) {
      //formInvestimento.etaPiante.setValidators(Validators.required);
    }
    else {
      coltureDivisioneArray[0].controls.etaPiante.setValue('');
      coltureDivisioneArray[0].controls.etaPiante.clearValidators();
    }

    if (formInvestimento.tipoProgetto.value == 3) {
      formInvestimento.affittoTerreno.setValidators(Validators.required);
    }
    else {
      formInvestimento.affittoTerreno.setValue('');
      formInvestimento.affittoTerreno.clearValidators();
    }

    if (formInvestimento.tipoProgetto.value != 4) {
      formInvestimento.provincia.setValidators(Validators.required);
      formInvestimento.comune.setValidators(Validators.required);
      formInvestimento.ettari.setValidators(Validators.required);
      coltureDivisioneArray[0].controls.categoria.setValidators(Validators.required);
      coltureDivisioneArray[0].controls.prodotto.setValidators(Validators.required);
      coltureDivisioneArray[0].controls.varieta.setValidators(Validators.required);
      //formInvestimento.contratto.setValidators(Validators.required);
      formInvestimento.macchinari.setValue('');
      formInvestimento.macchinari.clearValidators();
    }
    else {
      coltureDivisioneArray[0].controls.contratto.setValue('');
      coltureDivisioneArray[0].controls.qualitaProduzione.setValue('');
      formInvestimento.provincia.setValue('');
      formInvestimento.comune.setValue('');
      formInvestimento.ettari.setValue('');
      coltureDivisioneArray[0].controls.categoria.setValue('');
      coltureDivisioneArray[0].controls.prodotto.setValue('');
      coltureDivisioneArray[0].controls.varieta.setValue('');
      coltureDivisioneArray[0].controls.contratto.clearValidators();
      coltureDivisioneArray[0].controls.qualitaProduzione.clearValidators();
      formInvestimento.provincia.clearValidators();
      formInvestimento.comune.clearValidators();
      formInvestimento.ettari.clearValidators();
      coltureDivisioneArray[0].controls.categoria.clearValidators();
      coltureDivisioneArray[0].controls.prodotto.clearValidators();
      coltureDivisioneArray[0].controls.varieta.clearValidators();
      formInvestimento.macchinari.setValidators(Validators.required);
    }

    formInvestimento.importo.setValidators(Validators.required);
    formInvestimento.macchinari.updateValueAndValidity();
    coltureDivisioneArray[0].controls.contratto.updateValueAndValidity();
    coltureDivisioneArray[0].controls.qualitaProduzione.updateValueAndValidity();
    formInvestimento.importo.updateValueAndValidity();
    coltureDivisioneArray[0].controls.etaPiante.updateValueAndValidity();
    formInvestimento.affittoTerreno.updateValueAndValidity();
    formInvestimento.provincia.updateValueAndValidity();
    formInvestimento.comune.updateValueAndValidity();
    formInvestimento.ettari.updateValueAndValidity();
    coltureDivisioneArray[0].controls.categoria.updateValueAndValidity();
    coltureDivisioneArray[0].controls.prodotto.updateValueAndValidity();
    coltureDivisioneArray[0].controls.varieta.updateValueAndValidity();
  }

}
