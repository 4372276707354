<div style="margin: 0 auto;">
    <div class="mat-body-text-2"><span style="color: var(--main-black);">INDICI AL 31.12.</span>{{this.select_year}}
    </div>
    <!-- INDICI -->
    <table mat-table [dataSource]="dataSourceMWB_first" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="More Likely Case">
            <th mat-header-cell class="mlc" *matHeaderCellDef> Expected case </th>
            <td mat-cell *matCellDef="let element"> {{element.mlc}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_ml">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_mlc | number:'1.0-2':'it'}} % </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Worst Case">
            <th mat-header-cell *matHeaderCellDef class="wc"> Worst Case </th>
            <td mat-cell *matCellDef="let element"> {{element.wc}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_wc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_wc | number:'1.0-2':'it'}} %</td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Best Case">
            <th mat-header-cell *matHeaderCellDef class="bc"> Best Case </th>
            <td mat-cell *matCellDef="let element"> {{element.bc }} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_bc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_bc | number:'1.0-2':'it'}} %</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <div class="mat-body-text-2"><span style="color: var(--main-black);">VALORI PROSPETTICI {{this.select_year}}</span>
    </div>
    <!-- CONTO ECONOMICO -->
    <table mat-table [dataSource]="dataSourceMWB_second" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="More Likely Case">
            <th mat-header-cell *matHeaderCellDef class="mlc"> Expected Case </th>
            <td mat-cell *matCellDef="let element"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') + ' €' :
                '-'}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_ml">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_mlc | number:'1.0-2':'it'}} %</td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Worst Case">
            <th mat-header-cell *matHeaderCellDef class="wc"> Worst Case </th>
            <td mat-cell *matCellDef="let element"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') + ' €' :
                '-'}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_wc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_wc | number:'1.0-2':'it'}}% </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Best Case">
            <th mat-header-cell *matHeaderCellDef class="bc"> Best Case </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') + ' €' :
                '-'}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_bc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_bc | number:'1.0-2':'it'}} %</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <!-- CASH FLOW -->
    <table mat-table [dataSource]="dataSourceMWB_thirt" class="mat-elevation-z8 md-tab-center">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="More Likely Case">
            <th mat-header-cell *matHeaderCellDef class="mlc"> Expected Case </th>
            <td mat-cell *matCellDef="let element"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') + ' €' :
                '-'}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_ml">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_mlc | number:'1.0-2':'it'}}% </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Worst Case">
            <th mat-header-cell *matHeaderCellDef class="wc"> Worst Case </th>
            <td mat-cell *matCellDef="let element"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') + ' €' :
                '-'}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_wc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_wc | number:'1.0-2':'it'}}% </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Best Case">
            <th mat-header-cell *matHeaderCellDef class="bc"> Best Case </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') + ' €' :
                '-'}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_bc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_bc | number:'1.0-2':'it'}} %</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
    <div class="mat-body-text-2" style="color: rgba(0,0,0,0);">STATO PATRIMONIALE {{this.select_year}}</div>
    <!-- STATO PATRIMONIALE -->
    <table mat-table [dataSource]="dataSourceMWB_fourt" class="mat-elevation-z8 md-tab-center ">

        <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="More Likely Case">
            <th mat-header-cell *matHeaderCellDef class="mlc"> Expected Case </th>
            <td mat-cell *matCellDef="let element"> {{element.mlc != 0 ? (element.mlc | number:'1.0-0':'it') + ' €' :
                '-'}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_ml">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_mlc | number:'1.0-2':'it'}}% </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Worst Case">
            <th mat-header-cell *matHeaderCellDef class="wc"> Worst Case </th>
            <td mat-cell *matCellDef="let element"> {{element.wc != 0 ? (element.wc | number:'1.0-0':'it') + ' €' :
                '-'}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_wc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_wc | number:'1.0-2':'it'}} %</td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="Best Case">
            <th mat-header-cell *matHeaderCellDef class="bc"> Best Case </th>
            <td mat-cell *matCellDef="let element"> {{element.bc != 0 ? (element.bc | number:'1.0-0':'it') + ' €' :
                '-'}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="%PROB_bc">
            <th mat-header-cell *matHeaderCellDef> %PROB </th>
            <td mat-cell *matCellDef="let element"> {{element.prob_bc | number:'1.0-2':'it'}} %</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef=" displayedColumnsCenter"></tr>
        <tr mat-row *matRowDef="let row; columns:  displayedColumnsCenter;"></tr>
    </table>
</div>