import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { viewport } from '@popperjs/core';
import { ChartData, ChartType, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { StringLiteralLike } from 'typescript';
import { InterazioneRisultatiQuestionarioService } from '../interazione-risultati-questionario.service';

enum Device {
  desktop = 1,
  ipad
}

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})
export class DoughnutChartComponent {

  @Input() name: String;

  @Input() id: string;
  @Input() lancetta: string;

  @Input() rotate_principale: number;
  @Input() rotate_debitoria: number;
  @Input() rotate_mcc: number;
  @Input() rotate_garanzia: number;

  @Input() valore_tip_dscr: any;
  @Input() valore_tip_debitoria: any;
  @Input() valore_tip_mcc: any;
  @Input() valore_tip_garanzia: any;

  @Input() zoomActive: boolean = false;
  @Input() zoomActiveLeft: boolean = false;

  @Input() tips_type: string = ''

  @Input() viewportDevice: Device;

  @Input() labelBlack: boolean = false;

  @Output() onChartClick = new EventEmitter<string>();

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  // Doughnut
  public doughnutChartLabels: string[] = ['Download Sales', 'In-Store Sales', 'Mail-Order Sales'];
  public doughnutChartData: ChartData<'doughnut'> = {
    datasets: [{
      label: 'principale',
      data: [33, 33, 33],
      backgroundColor: [
        'rgb(217, 83, 79)',
        'rgb(240, 173, 78)',
        'rgb(92, 184, 92)'
      ],
      borderColor: 'rgba(0,0,0,0)',
      circumference: 180,
      rotation: -90,
      borderRadius: 0,
      hoverOffset: 0
    }]
  };

  public percentageInnerCutout: ChartOptions<'doughnut'> = {
    backgroundColor: 'rgba(0,0,0,0)',
    maintainAspectRatio: false,
    cutout: "80%",
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false
      }
    },
    elements: {
      arc: {
        borderWidth: 2,
        offset: 0
      },
    },
    hover: { mode: null }
  }

  public doughnutChartType: ChartType = 'doughnut';

  constructor(private _interazioneRisultatiQuestionarioService: InterazioneRisultatiQuestionarioService) { }

  focusDscr() {
    this._interazioneRisultatiQuestionarioService.focusOnDSCR();
  }

  ngOnInit(): void {
    this.ruotaLancette();
  }

  ngAfterViewInit() {
    this.ruotaLancette();
  }

  ngOnChanges(): void {
    this.ruotaLancette();
  }

  // events
  @HostListener('window:resize', ['$event'])
  public reportWindowSize(event): void {

    var ci = this.chart;
    //ci.chart.options.;
    ci.update();
  }

  ruotaLancette() {

    /*ROTAZIONE DELLE LANCETTE DEI POTENZIOMETRI SU VIEWPORT DESKTOP*/
    if (document.getElementById("lancetta-principale") != null) {
      let h = document.getElementById("lancetta-principale");
      h = document.getElementById("lancetta-principale");
      h.style.transform = "rotateZ(" + this.rotate_principale + "deg)";
    }

    if (document.getElementById("lancetta-debitoria") != null) {
      let h = document.getElementById("lancetta-debitoria");
      h = document.getElementById("lancetta-debitoria");
      h.style.transform = "rotateZ(" + this.rotate_debitoria + "deg)";
    }

    if (document.getElementById("lancetta-mcc") != null) {
      let h = document.getElementById("lancetta-mcc");
      h = document.getElementById("lancetta-mcc");
      h.style.transform = "rotateZ(" + this.rotate_mcc + "deg)";
    }

    if (document.getElementById("lancetta-garanzia") != null) {
      let h = document.getElementById("lancetta-garanzia");
      h = document.getElementById("lancetta-garanzia");
      h.style.transform = "rotateZ(" + this.rotate_garanzia + "deg)";
    }

    /*ROTAZIONE DELLE LANCETTE DEI POTENZIOMETRI SU VIEWPORT IPAD*/
    if (document.getElementById("lancetta-principale-ipad") != null) {
      let h = document.getElementById("lancetta-principale-ipad");
      h = document.getElementById("lancetta-principale-ipad");
      h.style.transform = "rotateZ(" + this.rotate_principale + "deg)";
    }

    if (document.getElementById("lancetta-debitoria-ipad") != null) {
      let h = document.getElementById("lancetta-debitoria-ipad");
      h = document.getElementById("lancetta-debitoria-ipad");
      h.style.transform = "rotateZ(" + this.rotate_debitoria + "deg)";
    }

    if (document.getElementById("lancetta-mcc-ipad") != null) {
      let h = document.getElementById("lancetta-mcc-ipad");
      h = document.getElementById("lancetta-mcc-ipad");
      h.style.transform = "rotateZ(" + this.rotate_mcc + "deg)";
    }

    if (document.getElementById("lancetta-garanzia-ipad") != null) {
      let h = document.getElementById("lancetta-garanzia-ipad");
      h = document.getElementById("lancetta-garanzia-ipad");
      h.style.transform = "rotateZ(" + this.rotate_garanzia + "deg)";
    }
  }

  showTips(): void {
    let tip = document.getElementsByClassName('tips-potenziometro');
    tip[0].classList.add("move-tip");
  }
}