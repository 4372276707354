import { TokenStorageService } from '../_services/token-storage.service';
import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { delay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { BeanStatico } from "../condivisi/beanStatico";
import { DatiQuestionarioService } from "../_services/dati-questionario.service";
import { faTrashAlt, faUpload, faFileAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'qs-dati-investimento',
  template: `
    <div [formGroup]="investimentoForm">
      <div class="row margin-0">

        <div class="col-12">
          <div class="card margin-bottom-10" style="border-bottom: none">
              <div class="card-header text-center font-very-big">Informazioni sul progetto di sviluppo dell'impresa</div>
          </div>

          <textarea formControlName="descrizioneProgetto" class="form-control" rows="3"
          [ngClass]="[ submitted && f.descrizioneProgetto.errors ? 'is-invalid' : '']"
          placeholder="Inserire in questo spazio una nota di sintesi del progetto per farci conoscere le caratteristiche salienti della tua iniziativa. Puoi anche inviarci la presentazione del progetto effettuando il caricamento nel box di seguito."></textarea>
          <div *ngIf="submitted && f.descrizioneProgetto.errors" class="invalid-feedback">
            <div *ngIf="f.descrizioneProgetto.required">Campo obbligatorio</div>
          </div>


          <div class="col-12">
            <div class="row margin-0" *ngIf="listaPresentazioni.length > 0">
              <div class="col-5 margin-bottom-10">Carica la presentazione del progetto o selezionane una già salvata </div>
              <div class="col-3 margin-bottom-10">
                <div class="form-check">
                  <input class="form-check-input" formControlName="presentazioneRadio" type="radio" name="presentazioneRadio" id="radioPresentazione1" checked [value]="true" (click)="currentFileUpload = null">
                  <label class="form-check-label" for="radioPresentazione1">
                    Seleziona presentazione
                  </label>
                </div>
              </div>

              <div class="col-3 margin-bottom-10">

                <div class="form-check">
                  <input class="form-check-input" formControlName="presentazioneRadio" type="radio" name="presentazioneRadio" id="radioPresentazione2"  [value]="false" (click)="f.presentazione.setValue('')">
                  <label class="form-check-label" for="radioPresentazione2">
                  Carica presentazione
                  </label>
                </div>
              </div>
            </div>
            <div class="row margin-0" *ngIf="f.presentazioneRadio.value && listaPresentazioni.length > 0">
              <div class="col-3">
                <label>Presentazione</label>
                <select formControlName="presentazione" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.presentazione.errors }">
                  <option [ngValue]="presentazione" *ngFor="let presentazione of listaPresentazioni">{{presentazione.descrizione}}</option>
                </select>
                <div *ngIf="submitted && f.presentazione.errors" class="invalid-feedback">
                  <div *ngIf="f.presentazione.errors.required">Campo obbligatorio</div>
                </div>
              </div>

              <div class="col-1 flex-bottom-left" *ngIf="f.presentazione.value">
                <fa-icon class="pointer" style="font-size: 30px" [icon]="faFileAlt" (click)="visualizza()"></fa-icon>
              </div>
            </div>

            <div class="row margin-0" *ngIf="!f.presentazioneRadio.value || listaPresentazioni.length == 0">
              <div class="col-6">
                <div class="col-12" *ngIf="listaPresentazioni.length == 0">Carica la presentazione del progetto</div>
                <input type="file" style="display: none" #fileUpload (change)="testSave($event)" accept="application/pdf">
                <fa-icon class="pointer margin-right-20" style="font-size: 30px" [icon]="faUpload" (click)="fileUpload.click()"></fa-icon>
                {{currentFileUpload ? currentFileUpload.name : 'Nessun file selezionato'}}
                <fa-icon class="pointer margin-left-20" style="font-size: 30px" [icon]="faTrashAlt" (click)="svuotaFile()"></fa-icon>
              </div>
            </div>
          </div>

          <div class="card margin-bottom-10 margin-top-10">
            <div class="card-header text-center font-big">Piano degli investimenti</div>
            <div class="card-body">

              <div class="col-12 margin-bottom-20" [ngClass]="ultimo ? '' : 'borderBThin'"  *ngFor="let item of investimentoProdottiGroup; let i = index; let ultimo = last; let primo = first">
                <qs-prodotto-progetto [formGroup]="investimentoProdottiGroup[i]" [booleanLast]="ultimo" [booleanFirst]="primo" (addProdottoProgetto)="aggiungiProdotto()" (removeProdottoProgetto)="rimuoviProdotto(i)"></qs-prodotto-progetto>
              </div>

            </div>
          </div>

          <div class="card margin-bottom-10" *ngIf="castFormArray(investimentoProdottiGroup[0].controls.coltureDivisioneArray).length > 1 || castFormArray(investimentoProdottiGroup[0].controls.coltureDivisioneArray)[0].controls.varieta.value">
            <div class="card-header text-center font-big">Ulteriori informazioni</div>
            <div class="card-body">

              <div class="col-12 margin-bottom-20">
                <!--<div class="col-12 font-bold margin-top-10 nopadding">Inserisci i canali di distribuzione dei prodotti</div>-->

                <div class="col-12 " *ngFor="let elem of investimentoProdottiGroup; let i = index; let ultimo = last; let primo = first">

                  <div *ngFor="let item of castFormArray(investimentoProdottiGroup[i].controls.coltureDivisioneArray); let j = index; let ultimo1 = last; let primo1 = first">
                    <div class="row margin-0" [ngClass]="primo1 && primo ? '' : 'borderTThin'" [formGroup]="item" *ngIf="item.controls.varieta.value">

                      <div class="margin-bottom-10 font-bold col-3 flex-center-left margin-top-10">
                        {{item.controls.prodotto.value.descrizione + ' ' + item.controls.varieta.value.descrizione}}
                      </div>

                      <div class="margin-bottom-10 col-3 margin-top-10">
                        <label>Metodo di coltivazione</label>
                        <select formControlName="qualitaProduzione" class="form-control" [ngClass]="{ 'is-invalid': submitted && item.controls.qualitaProduzione.errors }">
                          <option [ngValue]="qualita" *ngFor="let qualita of listaQualitaProduzione">{{qualita.descrizione}}</option>
                        </select>
                        <div *ngIf="submitted && item.controls.qualitaProduzione.errors" class="invalid-feedback">
                          <div *ngIf="item.controls.qualitaProduzione.errors.required">Campo obbligatorio</div>
                        </div>
                      </div>

                      <div class="margin-bottom-10 col-3 margin-top-10">
                        <label>Modalit&agrave; di vendita</label>
                        <select formControlName="contratto" class="form-control" [ngClass]="{ 'is-invalid': submitted && item.controls.contratto.errors }">
                          <option [ngValue]="canale" *ngFor="let canale of listaCanaliDistribuzione">{{canale.descrizione}}</option>
                        </select>
                        <div *ngIf="submitted && item.controls.contratto.errors" class="invalid-feedback">
                          <div *ngIf="item.controls.contratto.errors.required">Campo obbligatorio</div>
                        </div>
                      </div>
                    </div>

                    <div  *ngFor="let item1 of castFormArray(item.controls.coltureRotazioneArray); let ultimo2 = last">
                      <div class="row margin-0 borderTThin" [formGroup]="item1" *ngIf="item1.controls.varieta.value">

                        <div class="margin-bottom-10 font-bold col-3 margin-top-10">
                          {{item1.controls.prodotto.value.descrizione + ' ' + item1.controls.varieta.value.descrizione}}
                        </div>

                        <div class="margin-bottom-10 col-3 margin-top-10">
                          <label>Metodo di coltivazione</label>
                          <select formControlName="qualitaProduzione" class="form-control" [ngClass]="{ 'is-invalid': submitted && item1.controls.qualitaProduzione.errors }">
                            <option [ngValue]="qualita" *ngFor="let qualita of listaQualitaProduzione">{{qualita.descrizione}}</option>
                          </select>
                          <div *ngIf="submitted && item.controls.qualitaProduzione.errors" class="invalid-feedback">
                            <div *ngIf="item.controls.qualitaProduzione.errors.required">Campo obbligatorio</div>
                          </div>
                        </div>

                        <div class="margin-bottom-10 col-3 margin-top-10">
                          <label>Modalit&agrave; di vendita</label>
                          <select formControlName="contratto" class="form-control" [ngClass]="{ 'is-invalid': submitted && item1.controls.contratto.errors }">
                            <option [ngValue]="canale" *ngFor="let canale of listaCanaliDistribuzione">{{canale.descrizione}}</option>
                          </select>
                          <div *ngIf="submitted && item1.controls.contratto.errors" class="invalid-feedback">
                            <div *ngIf="item1.controls.contratto.errors.required">Campo obbligatorio</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>



  `,
  styles: [],
})
export class DatiInvestimentoComponent implements OnInit {
  @Input('formGroup') public investimentoForm!: UntypedFormGroup;
  @Output() addProdotto = new EventEmitter();
  @Output() removeProdotto = new EventEmitter<number>();

  submitted = false;
  listaCanaliDistribuzione: BeanStatico[] = [];
  listaQualitaProduzione: BeanStatico[] = [];
  faUpload = faUpload;
  faTrashAlt = faTrashAlt;
  faFileAlt = faFileAlt;
  currentFileUpload: File;
  listaPresentazioni: any[] = [];
  constructor(private datiQuestionarioService: DatiQuestionarioService, private httpClient: HttpClient, private tokenStorageService: TokenStorageService) { }

  ngOnInit() {

    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getCanaliDistribuzione').subscribe(data => {
      this.listaCanaliDistribuzione = data;
      if (this.castFormArray(this.investimentoProdottiGroup[0].controls.coltureDivisioneArray)[0].controls.contratto.value != '') {
        let investimentoProdottiGroup = this.castFormArray(this.investimentoForm.controls.produzioneProgettoArray);
        this.listaCanaliDistribuzione.forEach(element => {

          for (let i = 0; i < investimentoProdottiGroup.length; i++) {
            let coltureDivisioneArray = this.castFormArray(this.investimentoProdottiGroup[i].controls.coltureDivisioneArray);
            for (let j = 0; j < coltureDivisioneArray.length; j++) {
              if (element.id == coltureDivisioneArray[j].controls.contratto.value.id) coltureDivisioneArray[j].controls.contratto.setValue(element);
              let coltureRotazioneArray = this.castFormArray(coltureDivisioneArray[j].controls.coltureRotazioneArray);
              for (let k = 0; k < coltureRotazioneArray.length; k++) {
                if (element.id == coltureRotazioneArray[k].controls.contratto.value.id) coltureRotazioneArray[k].controls.contratto.setValue(element);
              }
            }
          }

        });
      }
    });

    this.getListaPresentazioni();

    this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getQualitaProduzione').subscribe(data => {
      this.listaQualitaProduzione = data;
      if (this.castFormArray(this.investimentoProdottiGroup[0].controls.coltureDivisioneArray)[0].controls.qualitaProduzione.value != '') {
        let investimentoProdottiGroup = this.castFormArray(this.investimentoForm.controls.produzioneProgettoArray);
        this.listaQualitaProduzione.forEach(element => {

          for (let i = 0; i < investimentoProdottiGroup.length; i++) {
            let coltureDivisioneArray = this.castFormArray(this.investimentoProdottiGroup[i].controls.coltureDivisioneArray);
            for (let j = 0; j < coltureDivisioneArray.length; j++) {
              if (element.id == coltureDivisioneArray[j].controls.qualitaProduzione.value.id) coltureDivisioneArray[j].controls.qualitaProduzione.setValue(element);
              let coltureRotazioneArray = this.castFormArray(coltureDivisioneArray[j].controls.coltureRotazioneArray);
              for (let k = 0; k < coltureRotazioneArray.length; k++) {
                if (element.id == coltureRotazioneArray[k].controls.qualitaProduzione.value.id) coltureRotazioneArray[k].controls.qualitaProduzione.setValue(element);
              }
            }
          }

        });
      }
    });

    this.datiQuestionarioService.currentSubmitted.subscribe(submitted => {
      this.submitted = submitted

    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.investimentoForm.controls; }

  get investimentoProdottiArray(): UntypedFormArray { return <UntypedFormArray> this.investimentoForm.controls.produzioneProgettoArray }

  get investimentoProdottiGroup(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.investimentoProdottiArray.controls }

  castFormArray(input: AbstractControl) {
    return <UntypedFormGroup[]>(<UntypedFormArray> input).controls;
  }

  aggiungiProdotto() {
    this.addProdotto.next(null);
  }

  rimuoviProdotto(indice: number) {
    this.removeProdotto.next(indice);
  }

  getListaPresentazioni() {
    this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getListaPresentazione', this.tokenStorageService.getUser().username).subscribe(data => {
      this.listaPresentazioni = [];
      data.forEach(element => {
        let elementoLista = { id: parseInt(element.id), descrizione: element.descrizione };
        this.listaPresentazioni.push(elementoLista)
        if (element.id == this.f.presentazione.value.id) this.f.presentazione.setValue(elementoLista);
      });
      if (this.listaPresentazioni.length > 0) this.f.presentazioneRadio.setValue(true);
    });
  }

  testSave(event) {
    let fileList: FileList = event.target.files;
    let file: File = fileList[0];
    const selectedFiles = event.target.files;
    this.currentFileUpload = selectedFiles.item(0);
    this.datiQuestionarioService.changeFilePresentazione(this.currentFileUpload);
    /*
    const formdata: FormData = new FormData();
    formdata.append('file', this.currentFileUpload);
    formdata.append('nomeAzienda', "pippo");
    formdata.append('nomeUtente', this.tokenStorageService.getUser().username);




    this.httpClient
      .post<any>("http://212.237.62.40:8080/upload/file-system/image", formdata
      )
      .subscribe(
        data =>{
          this.f.presentazione.setValue({id: data});
          this.getListaPresentazioni();
        }
      )
      */
  }

  elimina() {
    this.httpClient
      .post(environment.apiUrlQuestionario + "/file-system/delete", 13,).subscribe(data => { })
  }


  visualizza() {
    this.httpClient
      .post(environment.apiUrlQuestionario + "/file-system/getImage", this.f.presentazione.value.id, { responseType: 'blob' as 'json' }).subscribe(
        (data: Blob) => {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style.visibility = 'hidden';
          let url = URL.createObjectURL(data);
          a.href = url;
          a.target = "_blank";
          a.click();
          URL.revokeObjectURL(a.href)
          a.remove();
        }
      )

  }

  svuotaFile() {
    this.currentFileUpload = null;
    this.datiQuestionarioService.changeFilePresentazione(null);
  }

}

