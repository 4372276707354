import { ThisReceiver } from '@angular/compiler';
import { Component, ElementRef, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InterazioneRisultatiQuestionarioService } from '../interazione-risultati-questionario.service';
import { indiciTimeShift } from '../risultati-questionario/interface/indici-time-shift';
import { TimelineButtonsNavigatorService } from '../risultati-questionario/services/timeline-buttons-navigator.service';
import { SplashpageRisultatiComponent } from '../risultati-questionario/splashpage-risultati/splashpage-risultati.component';
import { ConfigurazioneBusinessPlanService } from '../_services/configurazione-business-plan.service';
import { DatiQuestionarioService } from '../_services/dati-questionario.service';
import { SplashpageServiceService } from '../_services/splashpage-service.service';

@Component({
  selector: 'app-timeline-risultati-questionario',
  templateUrl: './timeline-risultati-questionario.component.html',
  styleUrls: ['./timeline-risultati-questionario.component.css']
})
export class TimelineRisultatiQuestionarioComponent implements OnInit, OnDestroy {

  first_five_year: boolean = true;
  count: number = 1;
  count_year: number = 1;
  isPersistent: boolean = false;

  yearStart !: number;
  durataYears !: number;
  yearLista: number[] = [];
  listaData: { lista: [indici: indiciTimeShift[]] } = null;

  risultatiVolatileSubscription: Subscription
  listaSplashpageAnni: Subscription
  durataBpSuscription: Subscription

  time_line: number = 1;

  date = [
    {
      value: 1,
      date: [{
        anno: '2023',
        position: 'first_year'
      },
      {
        anno: '2024',
        position: 'second_year'
      },
      {
        anno: '2025',
        position: 'third_year'
      },
      {
        anno: '2026',
        position: 'fourth_year'
      },
      {
        anno: '2027',
        position: 'last_year'
      }]
    },
    {
      value: 2,
      date: [{
        anno: '2028',
        position: 'first_year'
      },
      {
        anno: '2029',
        position: 'second_year'
      },
      {
        anno: '2030',
        position: 'third_year'
      },
      {
        anno: '2031',
        position: 'fourth_year'
      },
      {
        anno: '2032',
        position: 'last_year'
      }]
    }
  ]

  constructor(private _interazioneRisultatiQuestionarioService: InterazioneRisultatiQuestionarioService,
    public timelineButtonsNavigatorService: TimelineButtonsNavigatorService, public splashpageVolatile: SplashpageServiceService,
    public datiQuestionarioService: DatiQuestionarioService, private serviceBp: ConfigurazioneBusinessPlanService) { }

  ngOnInit(): void {

    /*this.datiQuestionarioService.checkFormTouched.subscribe(isVolatile => {

      this.isPersistent = !isVolatile;

    })*/

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isPersistent = !isVolatile;
    })

    this.listaSplashpageAnni = this.splashpageVolatile.listaSplashpageAnni$.subscribe(
      listaData => {
        if (listaData != null) {
          this.listaData = listaData;
        }
      })

    this.durataBpSuscription = this.splashpageVolatile.durataBP$.subscribe(
      durata => {
        if (durata != null) {
          this.yearStart = durata.annoStart;
          this.durataYears = durata.durataAnni;
          this.yearLista = [];
          for (let i = 0; i < this.durataYears; i++) {
            this.yearLista.push(this.yearStart + i);
          }
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    this.listaSplashpageAnni.unsubscribe()
    this.durataBpSuscription.unsubscribe()


  }
  FocusOnClickYear(position_year: string) {
    let ceck = document.getElementsByClassName('click-year')

    if (ceck.length != 0) {
      let click_yet = document.getElementsByClassName('click-year');
      click_yet[0].classList.remove('click-year');
    }

    this._interazioneRisultatiQuestionarioService.clickOnYear(position_year);
    let anno_clicato = document.getElementById(position_year);
    anno_clicato.classList.add('click-year');
  }

  prevTime() {
    if (this.time_line != 1) { this.time_line--; }
    this._interazioneRisultatiQuestionarioService.clickOnLeftArrowYear((this.time_line - 1) * 5);
  }

  nextTime() {
    if (this.time_line != this.date.length) { this.time_line++; }
    this._interazioneRisultatiQuestionarioService.clickOnRightArrowYear((this.time_line - 1) * 5);
  }

}
