import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Richiedente } from '../interface/richiedente';
import { TokenStorageService } from './token-storage.service';
import { BeanStatico } from '../condivisi/beanStatico';

@Injectable({
  providedIn: 'root'
})
export class RichiedenteService {

  /*   private richiedenteList = new BehaviorSubject(null);
    currentRichiedenteList: Observable<Richiedente[]> = this.richiedenteList.asObservable(); */



  constructor(
    private httpClient: HttpClient,
    private token: TokenStorageService,
  ) { }

  /*   updateRichiedenteList(richiedenti: Richiedente[]) {
      this.richiedenteList.next(richiedenti)
    }
   */
  getRichiedenteByPiva(partitaIva: string): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getRichiedenteByPiva', { partitaIva: partitaIva })
  }

  /* prende tutte le aziende richiedenti in funzione dell'id cliente. funziona sia per aienda singola che per studio commercialistico */
  getAnagraficaRichiedenteByidCliente(): Observable<Richiedente[]> {
    return this.httpClient.post<Richiedente[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAnagraficaRichiedenteByidCliente',
      { idCliente: this.token.getUser().cliente.idCliente })
  }

  getCodiceAtecoPrimario(idRichiedente: number): Observable<string> {
    return this.httpClient.get<string>(environment.apiUrlQuestionario + '/fasGoAlgo/getCodiceAtecoPrimario/' + idRichiedente, { responseType: 'text' as 'json' })
  }

  bpIsPresent(idRichiedenteList: number[]): Observable<any[]> {
    return this.httpClient.post<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/bpIsPresent', idRichiedenteList)
  }

  getAnagraficaRichiedenteByIdUtente(): Observable<Richiedente[]> {
    return this.httpClient.post<Richiedente[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getAnagraficaRichiedenteByIdUtente',
      { idCliente: this.token.getUser().cliente.idCliente, idUtente: this.token.getUser().idUtente }) //
  }
  /* salvataggio cliente azienda singola */
  salvaAnagraficaRichiedenteSingolo(richiedente: Richiedente): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/saveAnagraficaRichiedenteSingolo', richiedente)
  }
  /* salva richiedente nel caso di commercialista. manda a BE anche l'idCliente e il numero di p iva scalato di 1 */
  saveAnagraficaRichiedente(richiedente: any): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/saveAnagraficaRichiedente', richiedente)
  }
  /* cancella richiedente inserito dal commercialista. solo se non ha bp attivi */
  DeleteRichiedente(request: any): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/deleteRichiedente', request)
  }

  checkPresenzaBpRichiedente(idRichiedente: number[]): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/checkPresenzaBpRichiedente', idRichiedente)
  }

  getListaTipiAzienda(): Observable<BeanStatico[]> {
    return this.httpClient.get<BeanStatico[]>(environment.apiUrlQuestionario + '/fasGoAlgo/getTipiAzienda')
  }

  getListaClassiAddetti(): Observable<any[]> {
    return this.httpClient.get<any[]>(environment.apiUrlQuestionario + '/fasGoAlgo/smartBp/getClassiNumeroAddetti')
  }



}

