<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<div class="container-fluid" style="margin: 2vh 0;">
    <div class="row justify-content-center">
        <div class="col-12 d-flex justify-content-center text-center" class="external-container-dossier">
            <span style="font-weight: bold;">Configura Dossier</span>
            <!-- Check super -->
            <div class="check-super-box">
                <div class="select-all-text-super" style='width: 80px;
                       display: flex;
                       align-items: center;'>
                    Tutto
                    <input *ngIf="super_check==true; else statoSuperFalso" type="checkbox" class="group-check"
                        id="check_super" (change)="checkAll()" checked>
                    <ng-template #statoSuperFalso>
                        <input type="checkbox" class="group-check" id="check_super" (change)="checkAll()">
                    </ng-template>
                </div>
            </div>
            <!-- / Check super -->
            <div class="row justify-content-center">
                <div class="col-8 d-flex justify-content-center text-center" class="internal-container-dossier">
                    <div class="row justify-content-center">
                        <div class="col-4" class="first-dossier-section">
                            <div class="row justify-content-center">
                                <div #elRef (click)="this.focusSection(elRef,i)"
                                    *ngFor="let elemento of lista_cat; let i = index" class="col-12"
                                    class="section-ceck section-ceck-{{i}}">
                                    {{elemento.nome}}
                                    <div *ngIf="main_check_list[i]; else statoGroupFalso"
                                        class="conteiner-select-input">
                                        <input type="checkbox" class="group-check" id="{{'group_'+i}}"
                                            (change)="this.gestisciCheck(i)" checked>
                                    </div>
                                    <ng-template #statoGroupFalso>
                                        <div class="conteiner-select-input">
                                            <input type="checkbox" class="group-check" id="{{'group_'+i}}"
                                                (change)="this.gestisciCheck(i)">
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="col-4" class="second-dossier-section">
                            <div class="row"
                                style="padding: 10px; border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                                <div class="col-12"
                                    style="background-color: white; border-radius: 20px; height: 42.5vh; padding: 10px;">
                                    <div class="row justify-content-center">
                                        <div *ngFor="let elemento of lista_cat; let i = index">
                                            <div *ngIf="this.optionFocus == i">
                                                <div *ngFor="let jasop of elemento.jasOpt; let j = index "
                                                    class="col-12 section-ceck-option"
                                                    style="border-radius: 15px; padding: 0 10px;"
                                                    [ngStyle]="{'background-color' : this.check_list[i][j] ? '#b3b1af' : 'white'}">
                                                    <ng-container *ngIf="this.check_list[i][j]; else statoFalso">
                                                        <label *ngIf="this.optionFocus == i"
                                                            for="{{'ceck_name_'+i+'_'+j}}"
                                                            style="background-color:  #b3b1af;"
                                                            class=" {{'label_check_'+i}}">{{this.jasop.nome}}</label>
                                                        <div class="conteiner-select-input"
                                                            style="background-color: #b3b1af ">
                                                            <input *ngIf="this.optionFocus == i" type="checkbox"
                                                                name="{{'ceck_name_'+i+'_'+j}}"
                                                                class=" {{'check_'+i}}  singleCheck"
                                                                id="{{ 'check_id'+jasop.id}}"
                                                                (change)="this.gestisciSottoCheck(i,j)" checked>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #statoFalso>
                                                        <label *ngIf="this.optionFocus == i"
                                                            for="{{'ceck_name_'+i+'_'+j}}"
                                                            class=" {{'label_check_'+i}}">{{this.jasop.nome}}</label>
                                                        <div class="conteiner-select-input">
                                                            <input *ngIf="this.optionFocus == i" type="checkbox"
                                                                name="{{'ceck_name_'+i+'_'+j}}"
                                                                class=" {{'check_'+i}}  singleCheck"
                                                                id="{{ 'check_id'+jasop.id}}"
                                                                (change)="this.gestisciSottoCheck(i,j)">
                                                        </div>
                                                    </ng-template>
                                                </div>
                                                <div *ngIf="elemento.jasOpt.length == 0">
                                                    La sezione selezionata non presenta nessuna sottosezione
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-12" class="third-dossier-section third-dossier-section-top">
                                    <div class="row">
                                        <div class="col-6 d-flex justify-content-center">
                                            Nome Azienda:
                                            <br>
                                            {{nomeAzienda}}
                                        </div>
                                        <div class="col-6 d-flex justify-content-center">
                                            Acquisizione Business plan:
                                            {{this.bp_data.dataAcquisizioneQuestionario | date : 'shortDate'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 d-flex justify-content-center text-center"
                                    style="background-color: rgba(0,0,0,0) !important; padding: 0 !important;"
                                    class="third-dossier-section">
                                    <div class="row">
                                        <div class="col-12">
                                            <p
                                                style="color: white; text-align:left; background-color: #2F2F37; border-radius: 10px; margin:5px; padding:15px;">
                                                Hai la possibilità di selezionare
                                                una
                                                configurazione preimpostata :</p>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center text-center">
                                        <input type="button" class="preselet" value="PRESET 1"
                                            (click)="preset(1)">&nbsp;
                                        <input type="button" class="preselet" value="PRESET 2" (click)="preset(2)">
                                        <!-- <input type="button" name="" id="" (click)="save()"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>