//businessplanjasper.mode.ts
export class Businessplanjasper {

    id!: number;
  
    idTipoRichiedente!: number;
  
    idTitoloStudio!: number;
  
    ragioneSociale!: string;
  
    esperienzaSettore!: number;
  
    importoInvestimento!: number;
  
    contributoFinanziamento!: number;
  
    contributoPersonale!: number;
  
    durataFinanziamentoAnni!: number;
  
    durataPreammortamentoAnni!: number;
  
    immobilizzazioni!: number;
  
    ricaviAnnoPrecedente!: number;
  
    costiFissi!: number;
  
    costiProduzione!: number;
  
    costiFatturato!: number;
  
    costiAnnoPrecedente!: number;
  
    rigaVe50!: number;
  
    rigaVf14!: number;
  
    rigaVf23!: number;
  
    rigaVf27!: number;
  
    importoDebitoBt!: number;
  
    importoDebitoMt!: number;
  
    importoDebitoLt!: number;
  
    percGaranzia!: number;
  
    tipoGaranzia!: string;
  
    crPresente!: boolean;
  
    idIntestatarioCr!: number;
  
    dataCr!: Date;
  
    idIntestatarioBilancio!: number;
  
    idTipoSocieta!: number;
  
    dataBilancio!: Date;
  
    idCodiceAteco!: number;
  
    partitaIva!: string;
  
    dataAcquisizioneQuestionario!: Date;
  
    utente!: string;
  
    divisa!: string;
  
    descrizioneProgetto!: string;
  
    idPresentazioneFileSys!: number;
  }
  