<div class="row justify-content-center" style="margin-top: 10vh;">
  <div class="col-4">
    <div class="login">

      <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

        <div class="row">
          <div class="col-md-12">
            <img src="./assets/img/key.png">&nbsp;
            <strong>LOGIN</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <div class="row form-group ombra">
              <label for="username" class="sr-only">Username</label>

              <input type="text" class="form-control" name="username" [(ngModel)]="form.username" required minlength="4"
                autofocus #username="ngModel" placeholder="Username" />

              <div class="alert alert-danger" role="alert" *ngIf="username.errors && f.submitted">
                È richiesto il nome utente!
              </div>
            </div>

            <div class="row form-group ombra">
              <label for="password" class="sr-only">Password</label>
              <show-hide-password BtnStyle="primary" [btnOutline]='true'>
                <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required
                  minlength="6" #password="ngModel" placeholder="Password" />
              </show-hide-password>
              <div class="alert alert-danger" role="alert" *ngIf="password.errors && f.submitted">
                <div *ngIf="password.errors.required">È richiesta la password</div>
                <div *ngIf="password.errors.minlength">La password deve essere di almeno 6 caratteri</div>
              </div>
            </div>

            <div class="row form-group">
              <button class="btn btn-primary btn-block ombra">Login</button>
            </div>

            <div class="row form-group">
              <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isLoginFailed">
                Accesso fallito: {{ errorMess }}
                <!-- {{errorMessage}} -->
              </div>
            </div>

          </div>
          <div class="col-md-1"></div>
        </div>

        <div class="row mt-0">
          <div class="col-md-12">
            <hr>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <img src="./assets/img/goalgo_Black.jpg" alt="" width="50%">
          </div>
        </div>

      </form>

      <div class="row alert alert-success mb-0 ml-2 mr-2" *ngIf="isLoggedIn">
        <div class="col-md-12">
          Ti sei collegato come {{ roles.descrizione }}.
        </div>
      </div>
    </div>
  </div>
</div>