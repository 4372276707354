<div class="row" [formGroup]="atecoForm">
    <h4>Codice ATECO Secondario-{{i+1}}</h4>
    <div class="col-2 col-lg-2 col-xl-2 margin-bottom-10">
        <label>Sezione</label>
        <select formControlName="atecoSecondarioSezione" class="form-control form-select"
            [ngClass]="{ 'is-invalid': submitted && f.atecoSecondarioSezione.errors }">
            <option [ngValue]="atecoSecondarioSezione"
                *ngFor="let atecoSecondarioSezione of listaAtecoSecondarioSezione">
                {{atecoSecondarioSezione.descrizione}}
            </option>
        </select>
        <div *ngIf="submitted && f.atecoSecondarioSezione.errors" class="invalid-feedback">
            <div *ngIf="f.atecoSecondarioSezione.errors.required">Campo obbligatorio</div>
        </div>

    </div>
    <div class="col-3 col-lg-3 col-xl-3 margin-bottom-10">
        <label>Divisione</label>
        <select formControlName="atecoSecondarioDivisione" class="form-control form-select"
            [ngClass]="{ 'is-invalid': submitted && f.atecoSecondarioDivisione.errors}">
            <option [ngValue]="atecoSecondarioDivisione"
                *ngFor="let atecoSecondarioDivisione of listaAtecoSecondarioDivisione">
                {{atecoSecondarioDivisione.descrizione}}
            </option>
        </select>
        <div *ngIf="submitted && f.atecoSecondarioDivisione.errors" class="invalid-feedback">
            <div *ngIf="f.atecoSecondarioDivisione.errors.required">Campo obbligatorio</div>
        </div>
    </div>
    <div class="col-3 col-lg-3 col-xl-3 margin-bottom-10">
        <label>Classe</label>
        <select formControlName="atecoSecondarioClasse" class="form-control form-select"
            [ngClass]="{ 'is-invalid': submitted && f.atecoSecondarioClasse.errors }">
            <option [ngValue]="atecoSecondarioClasse" *ngFor="let atecoSecondarioClasse of listaAtecoSecondarioClasse">
                {{atecoSecondarioClasse.descrizione}}
            </option>
        </select>
        <div *ngIf="submitted && f.atecoSecondarioClasse.errors" class="invalid-feedback">
            <div *ngIf="f.atecoSecondarioClasse.errors.required">Campo obbligatorio</div>
        </div>
    </div>
    <div class="col-3 col-lg-3 col-xl-3 margin-bottom-10">
        <label>Sottocategoria</label>
        <select formControlName="atecoSecondarioSottocategoria" class="form-control form-select"
            [ngClass]="{ 'is-invalid': submitted && f.atecoSecondarioSottocategoria.errors }">
            <option [ngValue]="atecoSecondarioSottocategoria"
                *ngFor="let atecoSecondarioSottocategoria of listaAtecoSecondarioSottocategoria">
                {{atecoSecondarioSottocategoria.descrizione}}
            </option>
        </select>
    </div>
    <div class="col-1 margin-bottom-10 d-flex justify-content-center align-items-end">
        <button class="btn fa fa-trash fa-lg pointer" aria-hidden="true" (click)="eliminaAteco(i)"></button>
    </div>

</div>