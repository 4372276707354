import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BeanStatico } from 'src/app/condivisi/beanStatico';
import { LineaBusinessBpCompleto } from 'src/app/interface/LineaBusinessBpCompleto';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ToolTipService } from 'src/app/_services/tool-tip.service';
import { LineaBusiness } from './../../condivisi/linea-business';
import { LineaBusinessBp } from './../../condivisi/linea-business-bp';
import { PraticheNuova } from './../../interface/Pratiche-nuova';
import { LineaBusinessBpComponent } from './linea-business-bp/linea-business-bp.component';
import { BooleanLiteral } from 'typescript';
import { StikyService } from 'src/app/_services/stiky.service';
import { BeanStaticoCorrelazioni } from 'src/app/condivisi/beanStaticoCorrelazioni';


@Component({
  selector: 'app-inserimento-ricavi-costi',
  templateUrl: './inserimento-ricavi-costi.component.html',
  styleUrls: ['./inserimento-ricavi-costi.component.css']
})
export class InserimentoRicaviCostiComponent implements OnInit {
  @ViewChildren(LineaBusinessBpComponent) lineaBusinessBpComponent!: QueryList<LineaBusinessBpComponent>;
  @ViewChild('firstTab') firstTab!: ElementRef;

  /* prendo in input  idRichiedenteTemp*/
  @Input() get tabIndex(): number { return this.currentIndex; };//serve per lo scroll
  @Input() businessPlanTemp: PraticheNuova
  @Input() ricaviEcostiForm: UntypedFormGroup;
  @Input() ricaviCostiVolatile: LineaBusinessBpCompleto[]
  @Input() lineaBpSaved: boolean[]
  @Input() lineeBpDirty: boolean[]
  @Input() lineaBusinessArrayValid: boolean[]
  @Input() ricaviCostiDirty: boolean
  @Output() emitRicaviCostiValid = new EventEmitter<any>()


  /* le sezioni del mat-tab child cambiano al variare dell'id Azienda e si caricano quando mi trovo su distinta base (index=2) */
  set tabIndex(tabIndex: number) {
    this.currentIndex = tabIndex;
    /*     if (this.currentIndex == 3) {
      this.ngOnInit();
    } */
  }

  //lineeBpDirty: boolean[]

  lineeBusiness: LineaBusinessBp[] = [];
  lineePerCorrelazioni: BeanStaticoCorrelazioni[] = [];
  currentIndex: number;
  ricaviCostiFormValid: boolean = true;
  ricaviCostiDisabled: boolean = false

  elaboraLineeBp: LineaBusinessBpCompleto[] = [];
  salvaLineeBp: LineaBusinessBpCompleto[] = [];


  constructor(
    private stikyService: StikyService,
    private configurazioneBpService: ConfigurazioneBusinessPlanService,
    private tokeStorageService: TokenStorageService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.ricaviEcostiForm = this.formBuilder.group({
      ricaviCosti: this.formBuilder.array([])
    })
  }
  get f() { return this.ricaviEcostiForm.controls }
  get ricaviCostiArray(): UntypedFormArray { return <UntypedFormArray> this.f.ricaviCosti }
  get ricaviCostiFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.ricaviCostiArray.controls }

  ngOnInit(): void {

    this.ricaviEcostiForm.addControl('ricaviCosti', this.formBuilder.array([]))
    this.lineaBusinessArrayValid = []
    this.lineeBpDirty = []
    this.lineaBpSaved = []
    this.lineePerCorrelazioni = []
    if (this.businessPlanTemp) {
      this.ricaviCostiArray.clear()
      this.configurazioneBpService.getLineeBusiness(this.businessPlanTemp.id, this.businessPlanTemp.idAziendaRichiedente).subscribe(response => {
        this.lineeBusiness = []
        response.forEach(lineaBusiness => {
          if (lineaBusiness.error == null) {
            this.lineaBusinessArrayValid.push(true)
            this.lineeBpDirty.push(false);
            this.lineaBpSaved.push(false)
            this.lineeBusiness.push(lineaBusiness);
            this.ricaviCostiArray.push(this.formBuilder.group({}))
            this.lineePerCorrelazioni.push({ id: lineaBusiness.id, idAmbiente: lineaBusiness.idLineaBusinessAmbiente, descrizione: lineaBusiness.nome })
          }
        });
        this.configurazioneBpService.lineeCorrelazioniBp(this.lineePerCorrelazioni);

      })
    }
  }
  getLineeBp() {
    if (this.businessPlanTemp) {
      this.configurazioneBpService.getLineeBusiness(this.businessPlanTemp.id, this.businessPlanTemp.idAziendaRichiedente).subscribe(response => {
        response.forEach((lineaBusiness, i) => {
          if (lineaBusiness.error == null && this.lineaBpSaved[i]) {
            this.lineeBusiness.splice(i, 1, lineaBusiness);
          }
        });
      })
    }
  }

  saveRicaviCostiValid(i: number, lineaFormValid: any) {
    this.lineaBusinessArrayValid.splice(i, 1, lineaFormValid.lineaBusinessFormValid)
    this.lineeBpDirty.splice(i, 1, lineaFormValid.lineaBpDirty)

    this.emitRicaviCostiValid.emit({ lineaBusinessArrayValid: this.lineaBusinessArrayValid, lineeBpDirty: this.lineeBpDirty })
  }

  RicaviCostiValidSubmit() {
    /* linee non toccate risultano avere valid = true */
    this.ricaviCostiFormValid = true;
    this.lineaBusinessBpComponent.toArray().forEach((child, i) => {
      this.lineaBusinessArrayValid.splice(i, 1, child.lineaBpValid()) //decommentare se si vuole verificare la validità della linea e di tutte insieme
      if (this.businessPlanTemp.idStato == 1) {
        /* per bp in prep basta una linea di bp valida per attivare il salva */
        this.ricaviCostiFormValid = this.ricaviCostiFormValid || this.lineaBusinessArrayValid[i]
      } else if (this.businessPlanTemp.idStato == 2) {
        /* per bp in lavorazione devono essere tutte valide per salvare */
        this.ricaviCostiFormValid = this.ricaviCostiFormValid && this.lineaBusinessArrayValid[i]
      }
    })
    return { ricaviCostiFormValid: this.ricaviCostiFormValid, lineaBusinessArrayValid: this.lineaBusinessArrayValid }
  }
  RicaviCostiValidElabora() {
    this.ricaviCostiDisabled = true;
    /* uso la condizione sul lineadisabled */
    /* se la linea non è toccata il valid di linea è true. ma se la linea è toccata ma incompleta il valid complessivo deve essere false quindi uso la & */
    this.ricaviCostiFormValid = true;
    this.lineaBusinessBpComponent.toArray().forEach((child, i) => {
      this.ricaviCostiDisabled = this.ricaviCostiDisabled && child.lineaDisabled
      this.lineaBusinessArrayValid.splice(i, 1, child.lineaBpValid())// se nessuna linea è stata valorizzata (lineaDisabled=true) allora deve dare errore.
      // se ce ne è almeno una allora può andare avannti
      this.ricaviCostiFormValid = this.ricaviCostiFormValid && this.lineaBusinessArrayValid[i]
    })
    /* se le linee sono tutte disabilitate non si puo procedere */
    if (this.ricaviCostiDisabled) {
      for (let i = 0; i < this.lineaBusinessArrayValid.length; i++) {
        this.lineaBusinessArrayValid.splice(i, 1, false)
      }
      this.ricaviCostiFormValid = false
    }
    return { ricaviCostiFormValid: this.ricaviCostiFormValid, lineaBusinessArrayValid: this.lineaBusinessArrayValid }//le linee dirty devono essere TUTTE valide o da errore (&&)
  }
  RicaviCostiDirtySubmit() {
    for (let i = 0; i < this.ricaviCostiFormArray.length; i++) {
      this.lineaBusinessBpComponent.toArray().forEach((child, i) => {
        this.lineeBpDirty.splice(i, 1, child.lineaBpDirty())
        this.ricaviCostiDirty = this.ricaviCostiDirty || this.lineeBpDirty[i]
        // se stato= lavorazione mi servono tutte le linee dirty anche se non le ha toccate 
        if (this.businessPlanTemp.idStato == 2) {
          this.lineeBpDirty.splice(i, 1, true)
        }
      })
      return { ricaviCostiDirty: this.ricaviCostiDirty, lineeBpDirty: this.lineeBpDirty };
    }
  }


  onSubmitLinee() {
    this.salvaLineeBp = [];
    this.lineaBusinessBpComponent.toArray().forEach(child => {
      this.salvaLineeBp.push(child.onSubmit())
    })
    return this.salvaLineeBp
  }

  getRicaviComplessivi() {
    let ricaviCostiarray = []
    this.lineaBusinessBpComponent.toArray().forEach(child => {
      //console.log(child)
      if (child.lineabusiness.presenzaMagazzino) {
        ricaviCostiarray.push(child.getRicaviComplessiviLb())
      }
    })
    return ricaviCostiarray
  }


  elaboraRicaviCosti() {
    this.elaboraLineeBp = [];
    this.lineaBusinessBpComponent.toArray().forEach(child => {
      //console.log(child)
      this.elaboraLineeBp.push(child.elaboraLineaBusiness())
    })
    return this.elaboraLineeBp
  }



  resetPaddingBodyNoSticky($event) {
    this.stikyService.reloadPaddingToRivcaviCosti();
  }


}

