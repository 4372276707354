<div class=" container-fluid text-start" *ngIf="currentUser;else loggedOut">
  <div class="card card-info-cliente" *ngIf="currentUser.roles.id!=1">
    <!-- passare nel profilo utente -->
    <div class="card-body" [ngSwitch]="currentUser.cliente.idTipoCliente">
      <div class="row d-flex justify-content-center info-cliente" *ngSwitchCase="1">
        <div class="col-xs-12 div-info">
          <h4 class="card-title"><strong>Azienda : </strong>{{anagraficaCliente.ragioneSociale}}</h4>
          <h4 class="card-title"><strong>Partita IVA : </strong>{{anagraficaCliente.partitaIva}}</h4>
          <h4 class="card-title"><strong>Forma giuridica :
            </strong>{{anagraficaCliente.formaGiuridica.descrizione}}</h4>
          <h4 class="card-title"><strong>Sede legale : </strong>{{anagraficaCliente.sedeLegale}},
            {{anagraficaCliente.cap}} {{anagraficaCliente.comune.descrizione}}
            ({{anagraficaCliente.provincia.sigla}})</h4>
          <h4 class="card-title"><strong>Telefono : </strong>{{anagraficaCliente.telefonoCliente}}</h4>
          <h4 class="card-title"><strong>E-mail : </strong>{{anagraficaCliente.emailCliente}}</h4>
        </div>
      </div>

      <div class="row d-flex justify-content-center info-cliente" *ngSwitchCase="2">
        <div class="col-xs-12 div-info">
          <h4 class="card-title"><strong>Azienda : </strong>{{anagraficaCliente.ragioneSociale}}</h4>
          <h4 class="card-title"><strong>Partita IVA : </strong>{{anagraficaCliente.partitaIva}}</h4>
          <h4 class="card-title"><strong>Sede legale : </strong>{{anagraficaCliente.sedeLegale}},
            {{anagraficaCliente.cap}} {{anagraficaCliente.comune.descrizione}}
            ({{anagraficaCliente.provincia.sigla}})</h4>
          <h4 class="card-title"><strong>Telefono : </strong>{{anagraficaCliente.telefonoCliente}}</h4>
          <h4 class="card-title"><strong>E-mail : </strong>{{anagraficaCliente.emailCliente}}</h4>
          <h4 class="card-title"><strong>Partite Iva attive/totali :
            </strong> {{anagraficaCliente.numeroPivaUtilizzate}}/{{anagraficaCliente.maxPiva}}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container text-start" *ngIf="currentUser; else loggedOut">
  <header>
    <h3>
      Profilo : <strong>{{ currentUser.username }}</strong>
    </h3>
  </header>
  <p>
    <strong>Token:</strong>
    {{ currentUser.accessToken.substring(0, 20) }} ...
    {{ currentUser.accessToken.substr(currentUser.accessToken.length - 20) }}
  </p>
  <p>
    <strong>Email:</strong>
    {{ currentUser.email }}
  </p>
  <strong>Ruoli:</strong>
  <ul class="list-group list-unstyled">
    <li *ngFor="let role of currentUser.roles">
      {{ role | slice: 5}}
    </li>
  </ul>
</div> -->

<ng-template #loggedOut>
  Accedete, per favore.
</ng-template>