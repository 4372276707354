import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BeanStaticoEsteso } from 'src/app/condivisi/beanStaticoEsteso';
import { BloccoVariabile, UserObject } from 'src/app/interfaces/user-key';
import { PivaDataService } from 'src/app/_services/piva-data.service';
import { DettagliDatiMercatoComponent } from '../dettagli-dati-mercato/dettagli-dati-mercato.component';


@Component({
  selector: 'app-dati-mercato-bench-row',
  templateUrl: './dati-mercato-bench-row.component.html',
  styleUrls: ['./dati-mercato-bench-row.component.css']
})
export class DatiMercatoBenchRowComponent implements OnInit {

  @Input() listaCodiciAteco: BeanStaticoEsteso[] = [];
  @Input() idAzienda: string = '';
  @Input() TokenAzienda: UserObject;


  parteSx: BloccoVariabile[] = [];

  fatturatoMercato: number[] = [];
  occupatiMercato: number[] = [];
  ebitdaMercato: number[] = [];
  costiPersonaleMercato: number[] = [];
  numeroAziendeMercato: number[] = [];

  immatricolazioni: number[] = [];
  autoUsate: number[] = [];

  anniDisplay: number[] = [];
  trends: number[][] = [[],[],[],[],[],[],[]];


  constructor(private PivaService: PivaDataService,  public dialog: MatDialog) { }

  ngOnInit(): void {
    this.PivaService.getMarketDataByAteco(this.listaCodiciAteco).subscribe((data) => {
      let Temp: BloccoVariabile = { valoreAnno1: data.fatturato[1], valoreAnno2: data.fatturato[2], valoreUltimoAnno: data.fatturato[0], anni: data.anno }
      this.parteSx.push(Temp)
      let Temp2: BloccoVariabile = { valoreAnno1: data.ebitda[1], valoreAnno2: data.ebitda[2], valoreUltimoAnno: data.ebitda[0], anni: data.anno }
      this.parteSx.push(Temp2)
      
      this.fatturatoMercato = data.fatturato;
      this.occupatiMercato = data.occupati;
      this.ebitdaMercato = data.ebitda;
      this.numeroAziendeMercato = data.numeroAziende;
      this.costiPersonaleMercato = data.costiPersonale;
      this.anniDisplay = data.anno;

      this.trends[0][0] = this.fatturatoMercato[0]/this.fatturatoMercato[1]*100 - 100
      this.trends[0][1] = this.fatturatoMercato[1]/this.fatturatoMercato[2]*100 - 100
      this.trends[0][2] = this.fatturatoMercato[2]/this.fatturatoMercato[3]*100 - 100
      this.trends[0][3] = this.fatturatoMercato[3]/this.fatturatoMercato[4]*100 - 100

      this.trends[1][0] = this.occupatiMercato[0]/this.occupatiMercato[1]*100 - 100
      this.trends[1][1] = this.occupatiMercato[1]/this.occupatiMercato[2]*100 - 100
      this.trends[1][2] = this.occupatiMercato[2]/this.occupatiMercato[3]*100 - 100
      this.trends[1][3] = this.occupatiMercato[3]/this.occupatiMercato[4]*100 - 100

      this.trends[2][0] = this.ebitdaMercato[0]/this.ebitdaMercato[1]*100 - 100
      this.trends[2][1] = this.ebitdaMercato[1]/this.ebitdaMercato[2]*100 - 100
      this.trends[2][2] = this.ebitdaMercato[2]/this.ebitdaMercato[3]*100 - 100
      this.trends[2][3] = this.ebitdaMercato[3]/this.ebitdaMercato[4]*100 - 100

      this.trends[3][0] = this.costiPersonaleMercato[0]/this.costiPersonaleMercato[1]*100 - 100
      this.trends[3][1] = this.costiPersonaleMercato[1]/this.costiPersonaleMercato[2]*100 - 100
      this.trends[3][2] = this.costiPersonaleMercato[2]/this.costiPersonaleMercato[3]*100 - 100
      this.trends[3][3] = this.costiPersonaleMercato[3]/this.costiPersonaleMercato[4]*100 - 100

      this.trends[4][0] = this.numeroAziendeMercato[0]/this.numeroAziendeMercato[1]*100 - 100
      this.trends[4][1] = this.numeroAziendeMercato[1]/this.numeroAziendeMercato[2]*100 - 100
      this.trends[4][2] = this.numeroAziendeMercato[2]/this.numeroAziendeMercato[3]*100 - 100
      this.trends[4][3] = this.numeroAziendeMercato[3]/this.numeroAziendeMercato[4]*100 - 100

      if(this.listaCodiciAteco[0].descrizione == '45.11.01'){
          this.immatricolazioni = data.immatricolazioni;
         this.autoUsate = data.passaggiProprieta;
          this.trends[5][0] = this.immatricolazioni[0]/this.immatricolazioni[1]*100 - 100
            this.trends[5][1] = this.immatricolazioni[1]/this.immatricolazioni[2]*100 - 100
            this.trends[6][0] = this.autoUsate[0]/this.autoUsate[1]*100 - 100
            this.trends[6][1] = this.autoUsate[1]/this.autoUsate[2]*100 - 100
      }
          


    });
  }



        openDialog(): void {

          const dialogRef = this.dialog.open(DettagliDatiMercatoComponent, {
            width: '100vw',
            height: '100vh',
            panelClass: 'full-screen-modal',
            data: { tk: this.listaCodiciAteco, idOsservato: this.idAzienda, token: this.TokenAzienda}

          });

        }

        getType(obj){
         
          return typeof obj;
        }

}
