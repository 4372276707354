import { Component, OnInit, Inject } from '@angular/core';
import { User, Users, DialogData } from '../interfaces/user';
import { GestioneUtentiService } from '../_services/gestioneUtenti.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-dialog-form',
  templateUrl: './user-dialog-form.component.html',
  styleUrls: ['./user-dialog-form.component.css']
})
export class UserDialogFormComponent implements OnInit {


  public Utente: User = { id: 0, username: '', email: '', jruolo: { id: 0, nome: '' }, idCapoArea: 0, Piva: '', idCliente: 0, isCa: false };
  public Utenti: Users = { rows: [] };

  nome!: string;
  email!: string;
  ruolo!: string;
  idclie!: number;
  ActUt!: number;


  UserForm!: UntypedFormGroup;
  NomeUtente!: UntypedFormControl;
  EmailUtente!: UntypedFormControl;
  RuoloUtente!: UntypedFormControl;
  panelOpenState = false;
  copyname = false;
  copymail = false;

  constructor(
    public dialogRef: MatDialogRef<UserDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private serviceUser: GestioneUtentiService,
    public dialog: MatDialog,
  ) { }


  ngOnInit(): void {
    this.UserForm = new UntypedFormGroup({

      NomeUtente: new UntypedFormControl('', Validators.required),
      EmailUtente: new UntypedFormControl('', [Validators.required, Validators.email]),
      RuoloUtente: new UntypedFormControl('', Validators.required)

    });

  }


  onNoClick(): void {
    this.dialog.closeAll();
  }

  onInsert() {
    (document.querySelector('.mail') as HTMLElement).style.border = '1px solid black';
    this.copymail = false;
    this.copyname = false;
    (document.querySelector('.nome') as HTMLElement).style.border = '1px solid black';

    this.Utente.username = this.UserForm.controls['NomeUtente'].value;
    this.Utente.email = this.UserForm.controls['EmailUtente'].value;
    this.Utente.jruolo.id = this.UserForm.controls['RuoloUtente'].value;
    this.Utente.idCliente = this.data.idclie;

    if (this.UserForm.valid) {
      this.serviceUser.takeByNomeUtente(this.Utente.idCliente, this.Utente.username).subscribe(ris => {
        if (ris != null) { (document.querySelector('.nome') as HTMLElement).style.border = '1px solid red'; this.copyname = true }
        if (ris == null) {
          (document.querySelector('.nome') as HTMLElement).style.border = '1px solid black';
          this.copyname = false;
          this.serviceUser.takeByEmailUtente(this.Utente.idCliente, this.Utente.email).subscribe(ris2 => {
            if (ris2 != null) { (document.querySelector('.mail') as HTMLElement).style.border = '1px solid red'; this.copymail = true }
            if (ris2 == null) {
              this.copymail = false;
              this.serviceUser._submitUser(this.Utente).subscribe(() => {

              });
              this.dialog.closeAll();
            }
          });

        }
      });



    }



  }


}
