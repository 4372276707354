import { AfterViewInit, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { TimelineButtonsNavigatorService } from '../services/timeline-buttons-navigator.service';
import { SplashpageService } from '../services/splashpage.service';
import { SintesiTopSection } from '../interface/sintesi-top-section';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { DettaglioChartService } from '../services/dettaglio-chart.service';
import { SplashpageServiceService } from 'src/app/_services/splashpage-service.service';
import { DatiQuestionarioService } from 'src/app/_services/dati-questionario.service';
import { ConfigurazioneBusinessPlanService } from 'src/app/_services/configurazione-business-plan.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigurazioneAmbienteService } from 'src/app/_services/configurazione-ambiente.service';
import { Subscription } from 'rxjs';
import { DettaglioRisultatiService } from 'src/app/_services/dettaglio-risultati.service';


@Component({
  selector: 'app-splashpage-risultati',
  templateUrl: './splashpage-risultati.component.html',
  styleUrls: ['./splashpage-risultati.component.css'],
  animations: [
    trigger('zoomChartDettaglioState', [
      state('unzoom', style({
        opacity: 0
      })),
      state('zoom', style({
        opacity: 1
      })),
      transition('zoom => unzoom', [
        animate('1s'),
      ]),
      transition('unzoom => zoom', [
        animate('1s'),
      ])
    ])]
})

export class SplashpageRisultatiComponent implements OnInit, OnDestroy {

  @Input() valueOrdinataNegativo: number[] = [];
  @Input() valueOrdinataMedia: number[] = [];
  @Input() valueOrdinataPositivo: number[] = [];
  @Input() nomeAzienda: string = "nome azienda";

  isPersistent: boolean = false;
  idBplan: number;
  annoInizioBilancio = 0;

  ELEMENT_AS_IS_QUADROSINTETICO: SintesiTopSection[] = [
    { tipoQuadroSintetico: "Trend Ricavi", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "Ebitda", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "Leverage", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "Capitale Circolante", ytd: '', plusone_yd: '', mt: '', lt: '' },
    { tipoQuadroSintetico: "DSCR", ytd: '', plusone_yd: '', mt: '', lt: '' }
  ];

  displayedColumns: string[] = ['Quadro sintetico', 'YTD', '+1YD', 'MT', 'LT'];
  dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;

  is_as: boolean = true;
  select_year: number = 0;
  stateZoom = 'unzoom';
  stateZoomInverse = 'zoom';
  valoreMax: any = {};
  risultatiVolatileSubscription: Subscription
  zoomSuscription: Subscription
  unzoomSuscription: Subscription
  asisSuscription: Subscription
  yearSuscription: Subscription
  topSectionSubscription: Subscription
  idBpSubscription: Subscription
  annoBilancioSubscription: Subscription
  commentoSubscription: Subscription;
  commentoExp:string = '';

  constructor(private datiQuestionarioService: DatiQuestionarioService, private timelineButtonsNavigatorService: TimelineButtonsNavigatorService,
    private splashpageService: SplashpageService, private dettaglioChartService: DettaglioChartService, private splash_service: SplashpageServiceService,
    private serviceBp: ConfigurazioneBusinessPlanService,
    private configurazioneAmbienteService: ConfigurazioneAmbienteService,
    private dettagliRisultati: DettaglioRisultatiService) { }

  ngOnDestroy(): void {
    this.risultatiVolatileSubscription.unsubscribe()
    this.idBpSubscription.unsubscribe()
    this.zoomSuscription.unsubscribe()
    this.unzoomSuscription.unsubscribe()
    this.asisSuscription.unsubscribe()
    this.yearSuscription.unsubscribe()
    this.commentoSubscription.unsubscribe();
    if (!this.isPersistent) {
      this.topSectionSubscription.unsubscribe()
      this.annoBilancioSubscription.unsubscribe()
    }
  }
  ngOnInit(): void {

   this.commentoSubscription =  this.dettagliRisultati.risultatiCommento$.subscribe(comm => {
      if(comm != null ) this.commentoExp = comm;
    })

    this.idBpSubscription = this.splash_service.BpId$.subscribe(
      id => {
        this.idBplan = id;

      }
    )
    /*
        this.datiQuestionarioService.checkFormTouched.subscribe(isVolatile => {
    
          this.isPersistent = !isVolatile;
    
        })
        */

    this.risultatiVolatileSubscription = this.serviceBp.currentRisultatiVolatile.subscribe(isVolatile => {
      this.isPersistent = !isVolatile;
    })



    this.unzoomSuscription = this.dettaglioChartService.UnZoom$.subscribe(
      unzoom => {
        this.stateZoom = unzoom;
        this.stateZoomInverse = 'zoom'
      }
    )

    this.zoomSuscription = this.dettaglioChartService.Zoom$.subscribe(
      zoom => {
        this.stateZoom = zoom;
        this.stateZoomInverse = 'unzoom'
      }
    )

    this.asisSuscription = this.timelineButtonsNavigatorService.AS_IS$.subscribe(is_as => {
      this.is_as = is_as;
    })

    this.yearSuscription = this.timelineButtonsNavigatorService.YEARS$.subscribe(year => {
      this.select_year = year;
    })

    if (this.isPersistent) {

      if (this.idBplan != 0) {

        this.splash_service.postGetAnnoBilancio(this.idBplan).subscribe(startInizioBilancio => {
          if (startInizioBilancio != null) {
            this.annoInizioBilancio = startInizioBilancio;
          }
        });

        this.splashpageService.postAsIsData(this.idBplan).subscribe(sintesiTopSection => {
          let valoriTopSection = sintesiTopSection;

          this.ELEMENT_AS_IS_QUADROSINTETICO = [];
          for (let i = 0; i < valoriTopSection.topTable.rtt.length; i++) {
            this.ELEMENT_AS_IS_QUADROSINTETICO.push({
              tipoQuadroSintetico: valoriTopSection.topTable.rtt[i].tipoQuadroSintetico,
              ytd: valoriTopSection.topTable.rtt[i].ytdString,
              plusone_yd: valoriTopSection.topTable.rtt[i].plusone_ydString,
              mt: valoriTopSection.topTable.rtt[i].mtString,
              lt: valoriTopSection.topTable.rtt[i].ltString
            });
          }


          this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
            valMax => {
              valMax.forEach(element => {
                if (element.error == null) {
                  this.valoreMax[element.nomeCampo] = element.valoreMax;
                }
              });
              if (valoriTopSection.topTable.rtt[0].ytd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].ytd = '> ' + this.valoreMax['dscr'];
              }
              else if (valoriTopSection.topTable.rtt[0].ytd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].ytd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (valoriTopSection.topTable.rtt[0].plusone_yd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].plusone_yd = '> ' + this.valoreMax['dscr'];
              }
              else if (valoriTopSection.topTable.rtt[0].plusone_yd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].plusone_yd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (valoriTopSection.topTable.rtt[0].mt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].mt = '> ' + this.valoreMax['dscr'];
              }
              else if (valoriTopSection.topTable.rtt[0].mt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].mt = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (valoriTopSection.topTable.rtt[0].lt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].lt = '> ' + this.valoreMax['dscr'];
              }
              else if (valoriTopSection.topTable.rtt[0].lt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].lt = '< ' + this.valoreMax['dscr'] * (-1);
              }

              this.dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;
            }
          );



        })
      }
    } else {
      this.topSectionSubscription = this.splash_service.topSection$.subscribe(dataTopSection => {
        if (dataTopSection != null) {
          this.ELEMENT_AS_IS_QUADROSINTETICO = [
            {
              tipoQuadroSintetico: dataTopSection[0].tipoQuadroSintetico,
              ytd: dataTopSection[0].ytdString,
              plusone_yd: dataTopSection[0].plusone_ydString,
              mt: dataTopSection[0].mtString,
              lt: dataTopSection[0].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[1].tipoQuadroSintetico,
              ytd: dataTopSection[1].ytdString,
              plusone_yd: dataTopSection[1].plusone_ydString,
              mt: dataTopSection[1].mtString,
              lt: dataTopSection[1].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[2].tipoQuadroSintetico,
              ytd: dataTopSection[2].ytdString,
              plusone_yd: dataTopSection[2].plusone_ydString,
              mt: dataTopSection[2].mtString,
              lt: dataTopSection[2].ltString
            },
            {
              tipoQuadroSintetico: dataTopSection[3].tipoQuadroSintetico,
              ytd: dataTopSection[3].ytdString,
              plusone_yd: dataTopSection[3].plusone_ydString,
              mt: dataTopSection[3].mtString,
              lt: dataTopSection[3].ltString
            },
            /*{
              tipoQuadroSintetico: dataTopSection[4].tipoQuadroSintetico,
              ytd: dataTopSection[4].ytd,
              plusone_yd: dataTopSection[4].plusone_yd,
              mt: dataTopSection[4].mt,
              lt: dataTopSection[4].lt
            }*/
          ];

          this.configurazioneAmbienteService.getValoriMax('risultati').subscribe(
            valMax => {
              valMax.forEach(element => {
                if (element.error == null) {
                  this.valoreMax[element.nomeCampo] = element.valoreMax;
                }
              });
              if (dataTopSection[0].ytd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].ytd = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[0].ytd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].ytd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[0].plusone_yd > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].plusone_yd = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[0].plusone_yd < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].plusone_yd = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[0].mt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].mt = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[0].mt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].mt = '< ' + this.valoreMax['dscr'] * (-1);
              }
              if (dataTopSection[0].lt > this.valoreMax['dscr']) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].lt = '> ' + this.valoreMax['dscr'];
              }
              else if (dataTopSection[0].lt < this.valoreMax['dscr'] * (-1)) {
                this.ELEMENT_AS_IS_QUADROSINTETICO[0].lt = '< ' + this.valoreMax['dscr'] * (-1);
              }

              this.dataSourcePerditaSortino = this.ELEMENT_AS_IS_QUADROSINTETICO;
            }
          );
        }
      })

      this.annoBilancioSubscription = this.serviceBp._annoBilancio$.subscribe(anno => {
        if (anno != 0) this.annoInizioBilancio = anno;
      })
    }

  }

  ngAfterContentInit() {

  }

  activateDettaglioChart() {
    this.stateZoom = this.stateZoom == 'zoom' ? 'zoom' : 'unzoom';
  }

  unZoomClick() {
    this.dettaglioChartService.noShowDettaglioChart();
  }

}
