import {TokenStorageService} from './_services/token-storage.service'
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginUserGuard implements CanActivate , CanActivateChild {
  constructor(private router: Router, private tokenStorageService: TokenStorageService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean>  |  Promise<boolean> | boolean  {
    return this.ceckLogin();
  }
  private ceckLogin(): boolean {

    if (!!this.tokenStorageService.getToken()) {
      return true;
    }
      this.router.navigate(['/login']); // se non ho credenziali reinderizzo in pagina login
      return false;
  }
  canActivateChild(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean|UrlTree {
      if (!!this.tokenStorageService.getToken())
            return true;
      this.router.navigate(['/login']); // se non ho credenziali reinderizzo in pagina login
      return false;
  }
}
