import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TabelleSecondarieService {

  baseUrl = environment.apiUrlQuestionario + '/fasGoAlgo';

  constructor(private http: HttpClient) { }

  getSortinoDataTable(idBplan: number): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}` + `/risultatiSplashPageSortino`, { idBusinessPlan: idBplan });
  }

  getSortinoDataPicchi(idBplan: number): Observable<any> {
    return this.http.post<string>(`${this.baseUrl}` + `/risultatiSplashPagePicchi`, { idBusinessPlan: idBplan });
  }
}
