<div class="row">
    <div class="col-1 d-flex justify-content-center align-middle">
        <h3 class="align-middle">1</h3>
    </div>
    <div class="col-4 d-flex justify-content-center align-middle">
        <h3 class="align-middle">nome cliente</h3>
    </div>
    <div class="col-4 d-flex justify-content-center align-middle">
        <h3 class="align-middle">partit iva</h3>
    </div>
    <div class="col-2 d-flex justify-content-center">
        <button class="btn btn-primary" (click)="onSelected()">
            <mat-icon class="material-icons-outlined action-icon">search</mat-icon> Dettagli
        </button>
    </div>

</div>