<div class="container-fluid">
    <div class="row">
        <div class="col-1 d-flex justify-content-start" id="return-div">

            <mat-icon style="z-index: 3;" *ngIf="ceckIfShowBackArrow()" class="material-icons-outlined return pointer"
                (click)="goBack()">
                keyboard_return</mat-icon>
        </div>
        <div class="col-10 d-flex justify-content-center">
            <!--     <div class="card">
                <div class="card-body info-cliente">
                    <div class="row d-flex justify-content-center">
                        <div class="col-4">
                            <h4 class="card-title"><strong>Azienda: </strong>{{anagraficaCliente.ragioneSociale}}</h4>
                            <h4 class="card-title"><strong>Partita IVA: </strong>{{anagraficaCliente.partitaIva}}</h4>
                            <h4 class="card-title"><strong>Forma giuridica:
                                </strong>{{anagraficaCliente.formaGiuridica.descrizione}}</h4>
                        </div>
                        <div class="col-4">
                            <h4 class="card-title"><strong>Sede legale: </strong>{{anagraficaCliente.sedeLegale}},
                                {{anagraficaCliente.cap}}
                                ({{anagraficaCliente.provincia.sigla}}) {{anagraficaCliente.comune.descrizione}}</h4>
                            <h4 class="card-title"><strong>E-mail: </strong>{{anagraficaCliente.emailCliente}}</h4>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div *ngIf="this.href == '/preview'" class="col-1 d-flex justify-content-end">
            <div style="min-width: 65%;" class="container-action">
                <button *ngIf="!this.dossier && !this.smartBp" class="action-button-fas dossier-fas" (click)="this.clickOnDossier()" 
                [disabled]="isRisultatiVolatile"
                [ngClass]="{ 'disabled-style': isRisultatiVolatile}">
                    <mat-icon class="material-icons-outlined action-icon">print</mat-icon>
                    <span>&nbsp;Dossier pdf</span>
                </button>
                <button *ngIf="this.dossier && !this.smartBp" class="action-button-fas dossier-fas" (click)="this.clickOnGenera()">
                    <mat-icon class="material-icons-outlined action-icon">print</mat-icon>
                    <span>&nbsp;Genera pdf</span>
                </button>
                <br>
                <button *ngIf="riferimentoBpOld  != null && (riferimentoBpOld.idStato==2||riferimentoBpOld.idStato==1)"
                    class="action-button-fas save-fas" (click)="this.ClickSave()">
                    <mat-icon class="material-icons-outlined action-icon">save</mat-icon>
                    <span>Salva</span>
                </button>
                <button *ngIf="!this.dettaglio" class="action-button-fas dettail-fas" (click)="this.clickOnDettaglio()">
                    <mat-icon class="material-icons-outlined action-icon">search</mat-icon>
                    <span>Dettaglio</span>
                </button>

                <button *ngIf="this.dettaglio" class="action-button-fas dettail-fas" (click)="this.clickOnSintesi()">
                    <mat-icon class="material-icons-outlined action-icon">search</mat-icon>
                    <span>Sintesi</span>
                </button>
                <button *ngIf="!this.smartBp" class="action-button-fas mod-fas" (click)="clickOnModifica()">
                    <mat-icon class="material-icons-outlined action-icon">edit</mat-icon>
                    <span>{{riferimentoBpOld != null &&
                        (riferimentoBpOld.idStato==3||riferimentoBpOld.idStato==4)?'Input':'Modifica'}}</span>
                </button>
                <button class="action-button-fas " (click)="this.clickOnExcel()"
                [disabled]="isRisultatiVolatile && !this.smartBp"
                [ngClass]="{ 'disabled-style': isRisultatiVolatile && !this.smartBp, 'excel-fas': !this.smartBp, 'mod-fas': this.smartBp }">
                    <mat-icon class="material-icons-outlined action-icon">bar_chart</mat-icon>
                    <span>&nbsp;Report xlsx</span>
                </button>
            </div>


            <!-- pulsantiera amministratore -->
            <!-- <div style="min-width: 80%;" class="container-action"
                *ngIf="this.currentUser.roles == 'ROLE_EXPERT-OPINION'">
                <button class="action-button-fas nuovoCliente-fas">
                    Nuovo cliente
                </button>
                <br>
                <button class="action-button-fas modificaCliente-fas">
                    Modifica
                </button>
                <button class="action-button-fas dettail-fas">
                    indietro
                </button>
            </div> -->

        </div>
    </div>
</div>