<mat-toolbar>
    <mat-icon *ngIf="this.yearLista.length >= 6" class="material-icons-outlined return pointer"
        (click)="first_five_year=true;prevTime()">
        keyboard_arrow_left
    </mat-icon>
    <ng-container class="mat-raised-button-container" *ngFor="let year of this.yearLista; let i = index">

        <button *ngIf="first_five_year && i == 0" mat-raised-button
            [ngStyle]="{'display': !first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('first_year')">{{year}}</button>
        <button *ngIf="first_five_year && i == 1" mat-raised-button
            [ngStyle]="{'display': !first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('second_year')">{{year}}</button>
        <button *ngIf="first_five_year && i == 2" mat-raised-button
            [ngStyle]="{'display': !first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('third_year')">{{year}}</button>
        <button *ngIf="first_five_year && i == 3" mat-raised-button
            [ngStyle]="{'display': !first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('fourth_year')">{{year}}</button>
        <button *ngIf="first_five_year && i == 4" mat-raised-button
            [ngStyle]="{'display': !first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('last_year')">{{year}}</button>

        <button *ngIf="!first_five_year && i == 5" mat-raised-button
            [ngStyle]="{'display': first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('first_year')">{{year}}</button>
        <button *ngIf="!first_five_year && i == 6" mat-raised-button
            [ngStyle]="{'display': first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('second_year')">{{year}}</button>
        <button *ngIf="!first_five_year && i == 7" mat-raised-button
            [ngStyle]="{'display': first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('third_year')">{{year}}</button>
        <button *ngIf="!first_five_year && i == 8" mat-raised-button
            [ngStyle]="{'display': first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('fourth_year')">{{year}}</button>
        <button *ngIf="!first_five_year && i == 9" mat-raised-button
            [ngStyle]="{'display': first_five_year ? 'none' : 'block'}"
            (click)="FocusOnClickYear('last_year')">{{year}}</button>
    </ng-container>
    <mat-icon *ngIf="this.yearLista.length >= 6" class="material-icons-outlined return pointer"
        (click)="first_five_year=false;nextTime()">
        keyboard_arrow_right
    </mat-icon>
</mat-toolbar>