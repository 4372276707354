import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { indicAsIs } from '../risultati-questionario/interface/indici-as-is';
import { indiciTimeShift } from '../risultati-questionario/interface/indici-time-shift';
import { SintesiTopSection } from '../risultati-questionario/interface/sintesi-top-section';
import { DurataBP } from '../risultati-questionario/interface/durata-bp';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface vanEcashFlowEquity {
  van: number;
  cfEquity: number;
}

export interface indiciSortino {
  label: string;
  value: number;
}

export interface picchi {
  piccoDebiti: { label: string, value: number, anno: number };
  piccoCrediti: { label: string, value: number, anno: number };
  piccoPFN: { label: string, value: number, anno: number };
}

export interface sortino {
  perditaAttesa: { label: string, value: number };
  IndiceSortino: { label: string, value: number };
}

@Injectable({
  providedIn: 'root'
})
export class SplashpageServiceService {

  private baseUrl = environment.apiUrlQuestionario + '/fasGoAlgo';

  asIsPersistence: indicAsIs[] = [];
  endStrokePersistence: indiciTimeShift[] = [];

  constructor(private http: HttpClient) { }

  private asIs: BehaviorSubject<indicAsIs[]> = new BehaviorSubject<indicAsIs[]>(null);
  public asIs$: Observable<indicAsIs[]> = this.asIs.asObservable();

  private endStroke: BehaviorSubject<indiciTimeShift[]> = new BehaviorSubject<indiciTimeShift[]>(null);
  endStroke$: Observable<indiciTimeShift[]> = this.endStroke.asObservable();

  public asIsendStroke: BehaviorSubject<indiciTimeShift[]> = new BehaviorSubject<indiciTimeShift[]>(null);
  public asIsendStroke$: Observable<indiciTimeShift[]> = this.asIsendStroke.asObservable();

  private topSection: BehaviorSubject<SintesiTopSection> = new BehaviorSubject<SintesiTopSection>(null);
  topSection$: Observable<SintesiTopSection> = this.topSection.asObservable();

  private picchi: BehaviorSubject<picchi> = new BehaviorSubject<picchi>(null);
  picchi$: Observable<picchi> = this.picchi.asObservable();

  private sortino: BehaviorSubject<sortino> = new BehaviorSubject<sortino>(null);
  public sortino$: Observable<sortino> = this.sortino.asObservable();

  private durataBP: BehaviorSubject<DurataBP> = new BehaviorSubject<DurataBP>(null);
  public durataBP$: Observable<DurataBP> = this.durataBP.asObservable();

  private listaSplashpageAnni: BehaviorSubject<{ lista: [indici: indiciTimeShift[]] }> = new BehaviorSubject<{ lista: [indici: indiciTimeShift[]] }>(null);
  public listaSplashpageAnni$: Observable<{ lista: [indici: indiciTimeShift[]] }> = this.listaSplashpageAnni.asObservable();

  private selezionatoSplashpageAnni: BehaviorSubject<indiciTimeShift[]> = new BehaviorSubject<indiciTimeShift[]>(null);
  public selezionatoSplashpageAnni$: Observable<indiciTimeShift[]> = this.selezionatoSplashpageAnni.asObservable();

  private BpId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public BpId$: Observable<number> = this.BpId.asObservable();

  sendBpId(id: number) {
    this.BpId.next(id);
  }

  takeDataYear(yearData: indiciTimeShift[]) {
    this.selezionatoSplashpageAnni.next(yearData);
  }

  sendDurataBP(durataBP: DurataBP) {
    this.durataBP.next(durataBP);
  }

  takeSplashYearsListData(listadatiAnni: { lista: [indici: indiciTimeShift[]] }) {
    this.listaSplashpageAnni.next(listadatiAnni);
    console.log(listadatiAnni);
  }

  takeValueSplashpage(indiciAsIs: indicAsIs[], indiciTimeShift: indiciTimeShift[], topSection: SintesiTopSection,
    sortino: sortino, picchi: picchi, listaSplashpageAnni: { lista: [indici: indiciTimeShift[]] }) {

    this.asIs.next(indiciAsIs);
    this.asIsPersistence = indiciAsIs;

    this.endStrokePersistence = indiciTimeShift;
    this.endStroke.next(indiciTimeShift);

    this.topSection.next(topSection);
    this.sortino.next(sortino);

    this.picchi.next(picchi);
  }

  takeValueSplashpageASIs() {
    this.asIs.next(this.asIsPersistence);
  }

  takeValueSplashpageEndStroke() {
    this.asIsendStroke.next(this.endStrokePersistence);
  }

  cancellaTutto() {
    this.asIsendStroke.next(null);
    this.asIs.next(null);
    this.endStroke.next(null);
    this.topSection.next(null);
    this.sortino.next(null);
    this.picchi.next(null);
    this.listaSplashpageAnni.next(null);
    this.durataBP.next(null);
    this.selezionatoSplashpageAnni.next(null);
    this.BpId.next(0);



  }

  postDurataBP(idBP: number): Observable<DurataBP> {

    return this.http.post<DurataBP>(this.baseUrl + '/durataBp', { idBusinessPlan: idBP });

  }

  postGetVanECashFLowEquity(): Observable<vanEcashFlowEquity> {
    return this.http.post<vanEcashFlowEquity>(this.baseUrl + '/CashFlowEquityAndVan', {});
  }

  postGetAnnoPreciso(idBP: number, year: number): Observable<string> {

    return this.http.post<string>(this.baseUrl + '/risultatiSplashpageDataYear', { idBusinessPlan: idBP, anno: year });

  }

  postGetAnnoBilancio(idBP: number): Observable<number> {
    return this.http.post<number>(this.baseUrl + '/getAnnoBilancio', idBP);
  }

}
