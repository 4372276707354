import { Component, HostListener, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Intestatario } from '../component/intestatario';
import { Location, ViewportScroller } from '@angular/common';
import { Bilancio } from '../component/bilancio';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BilancioService } from '../_services/bilancio.service';
import { BilancioIntestatarioService } from '../_services/bilancio_intestatario.service';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToolTipService } from './../_services/tool-tip.service';
import { Richiedente } from '../interface/richiedente';
import { Subscription } from 'rxjs';
import { CampoBilancio } from '../interface/campiBilancio';
import { ToolTip } from '../interface/toolTip';
import { ModalcontentComponent } from '../modalcontent/modalcontent/modalcontent.component';
import { BilancioManuale, BilancioManualeTot } from '../interface/BilancioManuale';

@Component({
  selector: 'app-bilancio-manuale',
  templateUrl: './bilancio-manuale.component.html',
  styleUrls: ['./bilancio-manuale.component.css'],

})
export class BilancioManualeComponent implements OnInit, OnDestroy {
  anagraficaSub: Subscription
  selectedIndex = 0;
  isNuovo: boolean = true
  contoSubmitted: boolean;
  patrimonialeSubmitted: boolean

  listaTips: ToolTip[] = [];
  bilancioManualeForm: UntypedFormGroup
  patrimonialeAttivo: CampoBilancio[];
  patrimonialePassivo: CampoBilancio[];
  contoEcon: CampoBilancio[];
  listaAnniBilancio: number[]
  annoBilancio: number
  annoBilancioPrec: number
  aziendaRichiedente: Richiedente;
  initialValues: any

  attivoPassivoCheck: boolean = false
  utilePerditaCheck: boolean = false
  immobilizzazioneCheck: boolean = false
  creditiCheck: boolean = false
  attivoCheck: boolean = false
  debitiCheck: boolean = false
  passivoCheck: boolean = false
  contoEconomicoCheck: boolean = false
  imposteCheck: boolean = false
  totaleCostiCheck: boolean = false
  attivoCircCheck: boolean = false
  erroriCE: boolean = false
  erroriSP: boolean = false


  currentAnno = new Date().getFullYear()
  constructor(
    private formBuilder: UntypedFormBuilder,
    private toolTipService: ToolTipService,
    private bilancioService: BilancioService,
    private modalService: NgbModal,
    private router: Router,
  ) {

  }

  ngOnDestroy() {
    this.anagraficaSub.unsubscribe();
  }
  ngOnInit() {
    this.annoBilancio = this.currentAnno - 1
    this.annoBilancioPrec = this.currentAnno - 2
    this.toolTipService.getTipsContent('BIL').subscribe(risultato => {
      if (risultato) {
        risultato.forEach(el => {
          this.listaTips.push(el)
        })
        this.listaTips.sort((n1, n2) => n1.numero - n2.numero)
      }
    })
    this.anagraficaSub = this.bilancioService.currentAnagraficaRichiedente.subscribe(azienda => {
      if (azienda != null) {
        this.aziendaRichiedente = azienda
      } else {
        this.router.navigate(['/configurazioneambiente'])
      }
    })
    this.bilancioManualeForm = this.formBuilder.group({

      statoPatrimoniale: this.formBuilder.group({
        attivo: this.formBuilder.array([]),
        passivo: this.formBuilder.array([]),
      }),
      contoEconomico: this.formBuilder.group({
        contoEconomicoarray: this.formBuilder.array([]),
      }),
    })
    this.getCampiBilancio()
  }

  get f() { return this.bilancioManualeForm.controls };
  get contoEconomico(): UntypedFormGroup { return <UntypedFormGroup> this.f.contoEconomico }
  get contoEconomicoArray(): UntypedFormArray { return <UntypedFormArray> this.contoEconomico.controls.contoEconomicoarray }
  get contoEconomicoFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.contoEconomicoArray.controls }

  get statoPatrimonialeForm(): UntypedFormGroup { return <UntypedFormGroup> this.f.statoPatrimoniale }
  get attivoArray(): UntypedFormArray { return <UntypedFormArray> this.statoPatrimonialeForm.controls.attivo }
  get attivoFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.attivoArray.controls }
  get passivoArray(): UntypedFormArray { return <UntypedFormArray> this.statoPatrimonialeForm.controls.passivo }
  get passivoFormArray(): UntypedFormGroup[] { return <UntypedFormGroup[]> this.passivoArray.controls }


  getCampiBilancio() {
    this.bilancioService.getCampiBilancio().subscribe(response => {
      this.patrimonialeAttivo = []
      this.patrimonialePassivo = [];
      this.contoEcon = [];
      response.attivo.forEach((attivo, i) => {
        attivo.codice = attivo.codice.charAt(0).toLowerCase() + attivo.codice.substring(1)
        this.patrimonialeAttivo.push(attivo)
        this.attivoArray.push(this.formBuilder.group({
          inputAnnoScorso: [{ value: '0', disabled: i == 4 || i == 8 || i == 13 || i == 11 }, Validators.required],
          inputAnnoPrec: [{ value: '0', disabled: true }, Validators.required]
        }))
      })
      response.passivo.forEach((passivo, i) => {
        passivo.codice = passivo.codice.charAt(0).toLowerCase() + passivo.codice.substring(1)
        this.patrimonialePassivo.push(passivo)
        this.passivoArray.push(this.formBuilder.group({
          inputAnnoScorso: [{ value: '0', disabled: i == 6 || i == 8 }, Validators.required],
          inputAnnoPrec: [{ value: '0', disabled: true }, Validators.required]
        }))
      })
      response.contoEconomico.forEach((contoEconomico, i) => {
        contoEconomico.codice = contoEconomico.codice.charAt(0).toLowerCase() + contoEconomico.codice.substring(1)
        this.contoEcon.push(contoEconomico)
        this.contoEconomicoArray.push(this.formBuilder.group({
          inputAnnoScorso: [{ value: '0', disabled: i == 17 || i == 21 || i == 23 }, Validators.required],
          inputAnnoPrec: [{ value: '0', disabled: true }, Validators.required]
        }))
      })
      this.initialValues = this.bilancioManualeForm.getRawValue()

      this.getDatiBilancio()
    })


  }
  getDatiBilancio() {
    if (this.aziendaRichiedente != null) {
      this.bilancioService.getBilancioManuale(this.aziendaRichiedente.id).subscribe(response => {
        if (response != null) {
          this.annoBilancio = response.bilancioManuale.passivo.anno
          this.annoBilancioPrec = response.bilancioManualeAnnoPrec.attivo.anno
          let codiceAttivo = Object.keys(response.bilancioManuale.attivo)
          for (let i = 0; i < this.attivoFormArray.length; i++) {
            for (let j = 0; j < codiceAttivo.length; j++) {
              if (this.patrimonialeAttivo[i].codice == codiceAttivo[j]) {
                if (response.bilancioManuale != null) this.attivoFormArray[i].controls.inputAnnoScorso.setValue(response.bilancioManuale.attivo[codiceAttivo[j]])
                if (response.bilancioManualeAnnoPrec != null) this.attivoFormArray[i].controls.inputAnnoPrec.setValue(response.bilancioManualeAnnoPrec.attivo[codiceAttivo[j]])
              }
            }

          }
          let codicePassivo = Object.keys(response.bilancioManuale.passivo)
          for (let i = 0; i < this.passivoFormArray.length; i++) {
            for (let j = 0; j < codicePassivo.length; j++) {
              if (this.patrimonialePassivo[i].codice == codicePassivo[j]) {
                if (response.bilancioManuale != null) this.passivoFormArray[i].controls.inputAnnoScorso.setValue(response.bilancioManuale.passivo[codicePassivo[j]])
                if (response.bilancioManualeAnnoPrec != null) this.passivoFormArray[i].controls.inputAnnoPrec.setValue(response.bilancioManualeAnnoPrec.passivo[codicePassivo[j]])
              }
            }
          }
          let codiceConto = Object.keys(response.bilancioManuale.contoEconomico)
          for (let i = 0; i < this.contoEconomicoFormArray.length; i++) {
            for (let j = 0; j < codiceConto.length; j++) {
              if (this.contoEcon[i].codice == codiceConto[j]) {
                if (response.bilancioManuale != null) this.contoEconomicoFormArray[i].controls.inputAnnoScorso.setValue(response.bilancioManuale.contoEconomico[codiceConto[j]])
                if (response.bilancioManualeAnnoPrec != null) this.contoEconomicoFormArray[i].controls.inputAnnoPrec.setValue(response.bilancioManualeAnnoPrec.contoEconomico[codiceConto[j]])
              }
            }
          }
        }
      })
    }
  }
  totaliAttivo() {
    this.attivoFormArray[4].controls.inputAnnoScorso.setValue((parseInt(this.attivoFormArray[3].controls.inputAnnoScorso.value) +
      parseInt(this.attivoFormArray[2].controls.inputAnnoScorso.value) + parseInt(this.attivoFormArray[1].controls.inputAnnoScorso.value)))
    this.attivoFormArray[4].controls.inputAnnoScorso.updateValueAndValidity

    this.attivoFormArray[8].controls.inputAnnoScorso.setValue((parseInt(this.attivoFormArray[7].controls.inputAnnoScorso.value) +
      parseInt(this.attivoFormArray[6].controls.inputAnnoScorso.value)))
    this.attivoFormArray[8].controls.inputAnnoScorso.updateValueAndValidity

    this.attivoFormArray[11].controls.inputAnnoScorso.setValue((parseInt(this.attivoFormArray[5].controls.inputAnnoScorso.value) +
      parseInt(this.attivoFormArray[8].controls.inputAnnoScorso.value) + parseInt(this.attivoFormArray[10].controls.inputAnnoScorso.value)
      + parseInt(this.attivoFormArray[9].controls.inputAnnoScorso.value)))
    this.attivoFormArray[11].controls.inputAnnoScorso.updateValueAndValidity

    this.attivoFormArray[13].controls.inputAnnoScorso.setValue((parseInt(this.attivoFormArray[0].controls.inputAnnoScorso.value) +
      parseInt(this.attivoFormArray[4].controls.inputAnnoScorso.value) + parseInt(this.attivoFormArray[11].controls.inputAnnoScorso.value)
      + parseInt(this.attivoFormArray[12].controls.inputAnnoScorso.value)))

  }

  totaliPassivo() {
    this.passivoFormArray[6].controls.inputAnnoScorso.setValue((parseInt(this.passivoFormArray[4].controls.inputAnnoScorso.value) + parseInt(this.passivoFormArray[5].controls.inputAnnoScorso.value)))
    this.passivoFormArray[8].controls.inputAnnoScorso.setValue((parseInt(this.passivoFormArray[6].controls.inputAnnoScorso.value) +
      parseInt(this.passivoFormArray[0].controls.inputAnnoScorso.value) + parseInt(this.passivoFormArray[2].controls.inputAnnoScorso.value) + parseInt(this.passivoFormArray[3].controls.inputAnnoScorso.value)
      + parseInt(this.passivoFormArray[7].controls.inputAnnoScorso.value)))
  }

  totaliContoEconomico() {
    this.contoEconomicoFormArray[23].controls.inputAnnoScorso.setValue((parseInt(this.contoEconomicoFormArray[21].controls.inputAnnoScorso.value) - parseInt(this.contoEconomicoFormArray[22].controls.inputAnnoScorso.value)))
    this.contoEconomicoFormArray[21].controls.inputAnnoScorso.setValue((parseInt(this.contoEconomicoFormArray[5].controls.inputAnnoScorso.value) -
      parseInt(this.contoEconomicoFormArray[17].controls.inputAnnoScorso.value) + parseInt(this.contoEconomicoFormArray[19].controls.inputAnnoScorso.value) +
      parseInt(this.contoEconomicoFormArray[20].controls.inputAnnoScorso.value)))
    this.contoEconomicoFormArray[17].controls.inputAnnoScorso.setValue((parseInt(this.contoEconomicoFormArray[6].controls.inputAnnoScorso.value) +
      parseInt(this.contoEconomicoFormArray[7].controls.inputAnnoScorso.value) + parseInt(this.contoEconomicoFormArray[8].controls.inputAnnoScorso.value) + parseInt(this.contoEconomicoFormArray[9].controls.inputAnnoScorso.value) +
      parseInt(this.contoEconomicoFormArray[12].controls.inputAnnoScorso.value) + parseInt(this.contoEconomicoFormArray[13].controls.inputAnnoScorso.value) +
      parseInt(this.contoEconomicoFormArray[14].controls.inputAnnoScorso.value) + parseInt(this.contoEconomicoFormArray[15].controls.inputAnnoScorso.value) +
      parseInt(this.contoEconomicoFormArray[16].controls.inputAnnoScorso.value)))

  }



  svuota() {
    this.bilancioManualeForm.reset(this.initialValues);
    /* richiamare la funzione di get dei valori */
    this.contoSubmitted = false;
    this.patrimonialeSubmitted = false
    this.attivoPassivoCheck = false
    this.utilePerditaCheck = false
    this.immobilizzazioneCheck = false
    this.creditiCheck = false
    this.attivoCheck = false
    this.debitiCheck = false
    this.passivoCheck = false
    this.contoEconomicoCheck = false
    this.imposteCheck = false
    this.totaleCostiCheck = false
    this.attivoCircCheck = false
    this.erroriCE = false
    this.erroriSP = false
    this.getDatiBilancio()
  }

  isBilancioFormValid() {
    for (let i = 0; i < this.attivoFormArray.length; i++) {
      this.attivoFormArray[i].updateValueAndValidity()
    }
    for (let i = 0; i < this.passivoFormArray.length; i++) {
      this.passivoFormArray[i].updateValueAndValidity()
    }
    for (let i = 0; i < this.contoEconomicoFormArray.length; i++) {
      this.contoEconomicoFormArray[i].updateValueAndValidity()
    }
    this.attivoArray.updateValueAndValidity()
    this.passivoArray.updateValueAndValidity()
    this.contoEconomicoArray.updateValueAndValidity()
    this.bilancioManualeForm.updateValueAndValidity()

    if (this.bilancioManualeForm.valid) {

      /* stato patrimoniale */
      /* attivo */
      this.attivoPassivoCheck = this.attivoFormArray[this.attivoFormArray.length - 1].controls.inputAnnoScorso.value == this.passivoFormArray[this.passivoFormArray.length - 1].controls.inputAnnoScorso.value

      this.immobilizzazioneCheck = Math.abs(this.attivoFormArray[4].controls.inputAnnoScorso.value - (this.attivoFormArray[3].controls.inputAnnoScorso.value +
        this.attivoFormArray[2].controls.inputAnnoScorso.value + this.attivoFormArray[1].controls.inputAnnoScorso.value)) <= 100

      this.creditiCheck = this.attivoFormArray[8].controls.inputAnnoScorso.value - Math.abs(this.attivoFormArray[7].controls.inputAnnoScorso.value + this.attivoFormArray[6].controls.inputAnnoScorso.value) >= 0

      this.attivoCheck = Math.abs(this.attivoFormArray[this.attivoFormArray.length - 1].controls.inputAnnoScorso.value -
        (this.attivoFormArray[4].controls.inputAnnoScorso.value + this.attivoFormArray[0].controls.inputAnnoScorso.value + this.attivoFormArray[12].controls.inputAnnoScorso.value + this.attivoFormArray[11].controls.inputAnnoScorso.value)) <= 100

      this.attivoCircCheck = Math.abs(this.attivoFormArray[11].controls.inputAnnoScorso.value - (this.attivoFormArray[5].controls.inputAnnoScorso.value +
        this.attivoFormArray[8].controls.inputAnnoScorso.value + this.attivoFormArray[10].controls.inputAnnoScorso.value
        + this.attivoFormArray[9].controls.inputAnnoScorso.value)) <= 100

      /* passivo */
      this.debitiCheck = Math.abs(this.passivoFormArray[6].controls.inputAnnoScorso.value - (this.passivoFormArray[4].controls.inputAnnoScorso.value + this.passivoFormArray[5].controls.inputAnnoScorso.value)) <= 100
      this.passivoCheck = Math.abs(this.passivoFormArray[8].controls.inputAnnoScorso.value - (this.passivoFormArray[6].controls.inputAnnoScorso.value +
        this.passivoFormArray[0].controls.inputAnnoScorso.value + this.passivoFormArray[2].controls.inputAnnoScorso.value + this.passivoFormArray[3].controls.inputAnnoScorso.value
        + this.passivoFormArray[7].controls.inputAnnoScorso.value)) <= 100

      this.utilePerditaCheck = this.passivoFormArray[1].controls.inputAnnoScorso.value == this.contoEconomicoFormArray[this.contoEconomicoFormArray.length - 1].controls.inputAnnoScorso.value

      /* conto economico */

      this.contoEconomicoCheck = Math.abs(this.contoEconomicoFormArray[this.contoEconomicoFormArray.length - 1].controls.inputAnnoScorso.value -
        (this.contoEconomicoFormArray[21].controls.inputAnnoScorso.value - this.contoEconomicoFormArray[22].controls.inputAnnoScorso.value)) <= 100

      /*imposteCheck manca una voce per totale delle partite straordinarie */
      this.imposteCheck = Math.abs(this.contoEconomicoFormArray[21].controls.inputAnnoScorso.value - (this.contoEconomicoFormArray[5].controls.inputAnnoScorso.value -
        this.contoEconomicoFormArray[17].controls.inputAnnoScorso.value + this.contoEconomicoFormArray[19].controls.inputAnnoScorso.value +
        this.contoEconomicoFormArray[20].controls.inputAnnoScorso.value)) <= 100

      this.totaleCostiCheck = Math.abs(this.contoEconomicoFormArray[17].controls.inputAnnoScorso.value - (this.contoEconomicoFormArray[6].controls.inputAnnoScorso.value +
        this.contoEconomicoFormArray[7].controls.inputAnnoScorso.value + this.contoEconomicoFormArray[8].controls.inputAnnoScorso.value + this.contoEconomicoFormArray[9].controls.inputAnnoScorso.value +
        this.contoEconomicoFormArray[12].controls.inputAnnoScorso.value + this.contoEconomicoFormArray[13].controls.inputAnnoScorso.value +
        this.contoEconomicoFormArray[14].controls.inputAnnoScorso.value + this.contoEconomicoFormArray[15].controls.inputAnnoScorso.value +
        this.contoEconomicoFormArray[16].controls.inputAnnoScorso.value)) <= 100

      /* this.utilePerditaCheck lo metto in entrambi perchè riguarda entrambe le sezioni */
      this.erroriSP = !(this.attivoPassivoCheck && this.utilePerditaCheck && this.immobilizzazioneCheck && this.creditiCheck
        && this.attivoCheck && this.debitiCheck && this.passivoCheck && this.attivoCircCheck)

      this.erroriCE = !(this.contoEconomicoCheck && this.imposteCheck && this.totaleCostiCheck && this.utilePerditaCheck)

      return this.erroriSP || this.erroriCE;

    } else {
      return this.bilancioManualeForm.valid
    }

  }

  onSubmit() {
    this.contoSubmitted = true;
    this.patrimonialeSubmitted = true

    if (!this.isBilancioFormValid()) {
      const modalRef = this.modalService.open(ModalcontentComponent);
      modalRef.componentInstance.modalPar = {
        name: "salvamodifiche",
        description: "Salvare il bilancio abbreviato inserito?",
        actionButtonText: "Salva",
        undoButtonText: "Indietro",
      }
      modalRef.result.then((r) => {
        if (r) {
          let bilancioManuale: any = {
            attivo: {},
            passivo: {},
            contoEconomico: {}
          }; /* BilancioManuale */
          bilancioManuale.attivo.idRichiedente = this.aziendaRichiedente.id
          bilancioManuale.attivo.anno = this.annoBilancio
          bilancioManuale.passivo.idRichiedente = this.aziendaRichiedente.id
          bilancioManuale.passivo.anno = this.annoBilancio
          bilancioManuale.contoEconomico.idRichiedente = this.aziendaRichiedente.id
          bilancioManuale.contoEconomico.anno = this.annoBilancio
          for (let i = 0; i < this.attivoFormArray.length; i++) {
            bilancioManuale.attivo[this.patrimonialeAttivo[i].codice] = parseInt(this.attivoFormArray[i].controls.inputAnnoScorso.value)
          }
          for (let i = 0; i < this.passivoFormArray.length; i++) {
            bilancioManuale.passivo[this.patrimonialePassivo[i].codice] = parseInt(this.passivoFormArray[i].controls.inputAnnoScorso.value)
          }
          for (let i = 0; i < this.contoEconomicoFormArray.length; i++) {
            bilancioManuale.contoEconomico[this.contoEcon[i].codice] = parseInt(this.contoEconomicoFormArray[i].controls.inputAnnoScorso.value)
          }
          this.bilancioService.saveBilancioManuale(bilancioManuale).subscribe(resp => {
            if (resp) {
              const modalRef = this.modalService.open(ModalcontentComponent);
              modalRef.componentInstance.modalPar = {
                name: "messaggiosalva",
                description: "Salvato con successo",
                actionButtonText: "",
                undoButtonText: "Ok",
              }
              modalRef.result.then(r => {
              })
            } else {
              const modalRef = this.modalService.open(ModalcontentComponent);
              modalRef.componentInstance.modalPar = {
                name: "messaggioerrore",
                description: "Errore nel salvataggio. Reinserire i dati",
                actionButtonText: "",
                undoButtonText: "Ok",
              }
              modalRef.result.then(r => {
                this.svuota()
              })
            }
          })
        }

      })
    }
  }
}
