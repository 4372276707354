<!--IF valori celle hanno bisogno di | number e visulizazione vertical is false-->
<div *ngIf="valsTab != null && number_pipe && !vertical" class={{ngClass[0]}}>
  <div class={{ngClass[1]}}>
    <div *ngFor="let header of valsTab.rows.header_titles" class={{ngClass[2]}}>
      {{header}}
    </div>
    <div *ngFor="let value of valsTab.rows.values" class={{ngClass[3]}}>

      {{value.value | number: value.precisione : 'it'}} {{value.decoratore}}

    </div>
  </div>
</div>
<!--ELSE-->
<div *ngIf="valsTab != null && !number_pipe && !vertical" class={{ngClass[0]}}>
  <div class={{ngClass[1]}}>
    <div *ngFor="let header of valsTab.rows.header_titles" class={{ngClass[2]}}>
      {{header}}
    </div>
    <div *ngFor="let value of valsTab.rows.values" class={{ngClass[3]}}>

      {{value.value}} {{value.decoratore}}

    </div>
  </div>
</div>

<!--visulizazione vertical is true-->

<!--IF valori celle hanno bisogno di | number e visulizazione vertical is true-->
<div *ngIf="valsTab != null && number_pipe && vertical" class={{ngClass[0]}}>
  <div class={{ngClass[1]}}>
    <div *ngFor="let header of valsTab.rows.header_titles; let i = index" class={{ngClass[2]}}>
      {{header}}
      <div class={{ngClass[3]}}>
        {{valsTab.rows.values[i].value | number: valsTab.rows.values[i].precisione : 'it'}}
        {{valsTab.rows.values[i].decoratore}}
      </div>
    </div>
  </div>
</div>
<!--ELSE-->
<div *ngIf="valsTab != null && !number_pipe && vertical" class={{ngClass[0]}}>
  <div class={{ngClass[1]}}>
    <div *ngFor="let header of valsTab.rows.header_titles; let i = index" class={{ngClass[2]}}>
      {{header}}
      <div class={{ngClass[3]}}>
        {{valsTab.rows.values[i].value}}
        {{valsTab.rows.values[i].decoratore}}
      </div>
    </div>
  </div>
</div>