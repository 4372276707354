<div class="container-fluid" style="margin-top: 20px">
  <div class="loading-container" *ngIf="isLoading">
    <div class="spinner-border text-primary" style="width: 50px; height: 50px" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <form [formGroup]="formModelloUniversale" (ngSubmit)="onSubmit()">
    <div class="col-12 nopadding">
      <div class="card nopadding">
        <div class="card-header text-center font-big">Modello universale</div>
        <div class="card-body">

          <div class="row">

            <div class="col-2 margin-bottom-10">
              <label>Anni di simulazione</label>
              <input type="number" formControlName="anniSimulazione" class="form-control"
                (change)="onChangeAnniSimulazione()"
                [ngClass]="{ 'is-invalid': submitted && f.anniSimulazione.errors}" />
              <div class="invalid-feedback" *ngIf="submitted && f.anniSimulazione.invalid">
                <div *ngIf="f.anniSimulazione.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>

            <div class="col-2 margin-bottom-10">
              <label>Numero prodotti</label>
              <input type="number" formControlName="numProdotti" class="form-control "
                (change)="onChangeNumeroProdotti()"
                [ngClass]="{ 'is-invalid': (submitted && f.numProdotti.errors) || (f.numProdotti.errors && f.numProdotti.errors.min)}" />
              <!-- (f.numProdotti.errors && f.numProdotti.errors.min) controlla prima se l'errore è  nullo se lo è non considera il secondo addendo. senza il primo addendo
                f.numProdotti.errors.min darebbe errore se l'errore è nullo -->
              <div class="invalid-feedback" *ngIf="submitted && f.numProdotti.invalid">
                <div *ngIf="f.numProdotti.errors.required">
                  Campo obbligatorio
                </div>
              </div>
              <!-- 
              <div class="invalid-feedback" *ngIf="f.numProdotti.errors && f.numProdotti.errors.min">
                  Bisogna inserire almeno un prodotto
              </div>
              -->
            </div>

          </div>

          <div formArrayName="datiProdotti" class="border-top">
            <div *ngFor="let prodotto of prodottiArray; let i = index" [formGroupName]="i"
              class="margin-bottom-10 margin-top-10 border-bottom">
              <h4>Prodotto {{i+1}}</h4>
              <div class="col-2 margin-bottom-10">
                <label class="font-bold">Nome prodotto</label>
                <input type="text" formControlName="nomeProdotto" class="form-control " />
              </div>
              <div class="row">

                <div class="font-bold">Prezzo</div>
                <div class="col-2 margin-bottom-10">
                  <label>Prezzo Iniziale</label>
                  <input type="text" formControlName="prezzoIniziale" class="form-control" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 2 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.prezzoIniziale.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.prezzoIniziale.invalid">
                    <div *ngIf="prodotto.controls.prezzoIniziale.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Prezzo Massimo</label>
                  <input type="text" formControlName="prezzoMax" class="form-control" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 2 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.prezzoMax.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.prezzoMax.invalid">
                    <div *ngIf="prodotto.controls.prezzoMax.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Prezzo Minimo</label>
                  <input type="text" formControlName="prezzoMin" class="form-control" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 2 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.prezzoMin.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.prezzoMin.invalid">
                    <div *ngIf="prodotto.controls.prezzoMin.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-2 margin-bottom-10">
                  <label>Drift (%)</label>
                  <input type="number" formControlName="driftPrezzo" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.driftPrezzo.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.driftPrezzo.invalid">
                    <div *ngIf="prodotto.controls.driftPrezzo.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-2 margin-bottom-10">
                  <label>Scarto (%)</label>
                  <input type="number" formControlName="scartoPrezzo" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.scartoPrezzo.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.scartoPrezzo.invalid">
                    <div *ngIf="prodotto.controls.scartoPrezzo.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Anni Previsione Prezzo</label>
                  <input type="number" formControlName="anniPrezzo" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.anniPrezzo.errors }" required />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.anniPrezzo.invalid">
                    <div *ngIf="prodotto.controls.anniPrezzo.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="font-bold">Costo di produzione</div>
                <div class="col-2 margin-bottom-10">
                  <label>Costo Iniziale</label>
                  <input type="text" formControlName="costoIniziale" class="form-control" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 2 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.costoIniziale.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.costoIniziale.invalid">
                    <div *ngIf="prodotto.controls.costoIniziale.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Costo Massimo</label>
                  <input type="text" formControlName="costoMax" class="form-control" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 2 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.costoMax.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.costoMax.invalid">
                    <div *ngIf="prodotto.controls.costoMax.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>

                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Costo Minimo</label>
                  <input type="text" formControlName="costoMin" class="form-control" currencyMask
                    [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 2 , suffix: ' €', allowNegative: false }"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.costoMin.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.costoMin.invalid">
                    <div *ngIf="prodotto.controls.costoMin.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-2 margin-bottom-10">
                  <label>Drift (%)</label>
                  <input type="number" formControlName="driftCosto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.driftCosto.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.driftCosto.invalid">
                    <div *ngIf="prodotto.controls.driftCosto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-2 margin-bottom-10">
                  <label>Scarto (%)</label>
                  <input type="number" formControlName="scartoCosto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.scartoCosto.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.scartoCosto.invalid">
                    <div *ngIf="prodotto.controls.scartoCosto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Anni Previsione Costo</label>
                  <input type="number" formControlName="anniCosto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.anniCosto.errors }" required />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.anniCosto.invalid">
                    <div *ngIf="prodotto.controls.anniCosto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">

                <div class="col-2 margin-bottom-10">
                  <label>Capacit&agrave; produttiva totale</label>
                  <input type="number" formControlName="capacitaProduttiva" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.capacitaProduttiva.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.capacitaProduttiva.invalid">
                    <div *ngIf="prodotto.controls.capacitaProduttiva.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <!--
                <div class="col-2 margin-bottom-10">
                  <label>Reimpiego dell'invenduto (%)</label>
                  <input type="number" formControlName="percMagazzino" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.percMagazzino.errors }" />
                  <div class="alert-danger" *ngIf="submitted && prodotto.controls.percMagazzino.invalid">
                    <div *ngIf="prodotto.controls.percMagazzino.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              -->
              </div>

              <div
                *ngIf="f.anniSimulazione.valid && f.anniSimulazione.value > 0 && prodottiArray[i].controls.percAnnuali.length == f.anniSimulazione.value"
                formArrayName="percAnnuali">
                <div class="row">
                  <div class="font-bold">Quantit&agrave; venduta (%)</div>

                  <div class="col-2 margin-bottom-10"
                    *ngFor="let item of  [].constructor(f.anniSimulazione.value); let j = index" [formGroupName]="j">
                    <label>{{j+1}}° anno </label>
                    <input type="number" formControlName="percVenduto" class="form-control" />
                    <!--
                      [ngClass]="{ 'is-invalid': submitted && prodotto.controls.percAnnuali.controls[j].controls.quantitaVenduta.errors }" />
                    <div class="invalid-feedback"
                      *ngIf="submitted && prodotto.controls.percAnnuali.controls[j].controls.quantitaVenduta.invalid">
                      <div *ngIf="prodotto.controls.percAnnuali.controls[j].controls.quantitaVenduta.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
                -->
                  </div>
                </div>

                <div class="row">
                  <div class="font-bold">Quantit&agrave; prodotta (%)</div>

                  <div class="col-2 margin-bottom-10"
                    *ngFor="let item of [].constructor(f.anniSimulazione.value); let j = index" [formGroupName]="j">
                    <label>{{j+1}}° anno </label>
                    <input type="number" formControlName="percProdotto" class="form-control" />
                    <!--
                      [ngClass]="{ 'is-invalid': submitted && prodotto.controls.percAnnuali.controls[j].controls.quantitaProdotta.errors }" />
                    <div class="alert-danger"
                      *ngIf="submitted && prodotto.controls.percAnnuali.controls[j].controls.quantitaProdotta.invalid">
                      <div *ngIf="prodotto.controls.percAnnuali.controls[j].controls.quantitaProdotta.errors.required">
                        Campo obbligatorio
                      </div>
                    </div>
      -->
                  </div>

                </div>
              </div>
              <!--
        Forse va messa una variabilità delle percentuali di vendita o produzione, oppure lo impostiamo fisso 
      -->
              <div class="row" *ngIf="false">
                <div class="font-bold">Quantit&agrave; venduta (% del prodotto)</div>
                <div class="col-2 margin-bottom-10">
                  <label>Venduto Iniziale</label>
                  <input type="number" formControlName="vendutoIniziale" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.vendutoIniziale.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.vendutoIniziale.invalid">
                    <div *ngIf="prodotto.controls.vendutoIniziale.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Venduto Massimo</label>
                  <input type="number" formControlName="vendutoMassimo" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.vendutoMassimo.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.vendutoMassimo.invalid">
                    <div *ngIf="prodotto.controls.vendutoMassimo.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>

                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Venduto Minimo</label>
                  <input type="number" formControlName="vendutoMinimo" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.vendutoMinimo.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.vendutoMinimo.invalid">
                    <div *ngIf="prodotto.controls.vendutoMinimo.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Drift (%)</label>
                  <input type="number" formControlName="driftVenduto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.driftVenduto.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.driftVenduto.invalid">
                    <div *ngIf="prodotto.controls.driftVenduto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Scarto (%)</label>
                  <input type="number" formControlName="scartoVenduto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.scartoVenduto.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.scartoVenduto.invalid">
                    <div *ngIf="prodotto.controls.scartoVenduto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Anni Previsione Vendita</label>
                  <input type="number" formControlName="anniVenduto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.anniVenduto.errors }" required />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.anniVenduto.invalid">
                    <div *ngIf="prodotto.controls.anniVenduto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="false">
                <!--Da rivedere un attimo questa cosa della quantità prodotta e le percentuali, forse conviene farlo direttamente come il FAS
    con valori assoluti e non relativi-->
                <div class="font-bold">Quantit&agrave; prodotta</div>
                <div class="col-2 margin-bottom-10">
                  <label>Valore Iniziale</label>
                  <input type="number" formControlName="prodottoIniziale" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.prodottoIniziale.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.prodottoIniziale.invalid">
                    <div *ngIf="prodotto.controls.prodottoIniziale.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Valore Massimo</label>
                  <input type="number" formControlName="prodottoMassimo" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.prodottoMassimo.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.prodottoMassimo.invalid">
                    <div *ngIf="prodotto.controls.prodottoMassimo.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>

                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Valore Minimo</label>
                  <input type="number" formControlName="prodottoMinimo" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.prodottoMinimo.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.prodottoMinimo.invalid">
                    <div *ngIf="prodotto.controls.prodottoMinimo.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-2 margin-bottom-10">
                  <label>Drift (%)</label>
                  <input type="number" formControlName="driftProdotto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.driftProdotto.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.driftProdotto.invalid">
                    <div *ngIf="prodotto.controls.driftProdotto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
                <div class="col-2 margin-bottom-10">
                  <label>Scarto (%)</label>
                  <input type="number" formControlName="scartoProdotto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.scartoProdotto.errors }" />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.scartoProdotto.invalid">
                    <div *ngIf="prodotto.controls.scartoProdotto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>

                <div class="col-2 margin-bottom-10">
                  <label>Anni Previsione Produzione</label>
                  <input type="number" formControlName="anniProdotto" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && prodotto.controls.anniProdotto.errors }" required />
                  <div class="invalid-feedback" *ngIf="submitted && prodotto.controls.anniProdotto.invalid">
                    <div *ngIf="prodotto.controls.anniProdotto.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="f.anniSimulazione.valid && f.anniSimulazione.value > 0 && f.numProdotti.value && f.numProdotti.value > 0 && costiArray.length == f.anniSimulazione.value"
            formArrayName="costiFissi">

            <div class="row">
              <div class="font-bold">Costi fissi annuali </div>

              <div class="col-2 margin-bottom-10"
                *ngFor="let item of  [].constructor(f.anniSimulazione.value); let j = index" [formGroupName]="j">
                <label>{{j+1}}° anno </label>
                <input type="text" formControlName="valoreAnnuo" class="form-control" currencyMask
                  [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />
              </div>
            </div>

          </div>

          <div class="row">
            <!--
            <div class="col-3 margin-bottom-10">
              <label>Costi fissi annuali</label>
              <input type="text" formControlName="costoFisso" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.costoFisso.errors }" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }"/>
              <div class="invalid-feedback" *ngIf="submitted && f.costoFisso.invalid">
                <div *ngIf="f.costoFisso.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
-->
            <div class="col-3 margin-bottom-10" *ngIf="f.numProdotti.value && f.numProdotti.value > 0">
              <label>Costi sul fatturato (%)</label>
              <input type="number" formControlName="costoFatturato" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.costoFatturato.errors }" />
              <div class="invalid-feedback" *ngIf="submitted && f.costoFatturato.invalid">
                <div *ngIf="f.costoFatturato.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>

            <div class="col-3 margin-bottom-10">
              <label>Giorni pagamento clienti</label>
              <input type="number" formControlName="giorniCrediti" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.giorniCrediti.errors }" />
              <div class="invalid-feedback" *ngIf="submitted && f.giorniCrediti.invalid">
                <div *ngIf="f.giorniCrediti.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>

            <div class="col-3 margin-bottom-10">
              <label>Giorni pagamenti fornitori</label>
              <input type="number" formControlName="giorniDebiti" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.giorniDebiti.errors }" />
              <div class="invalid-feedback" *ngIf="submitted && f.giorniDebiti.invalid">
                <div *ngIf="f.giorniDebiti.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="f.numProdotti.value && f.numProdotti.value > 0">
            <div class="col-3 margin-bottom-10">
              <label>Immobilizzazioni investimento</label>
              <input type="text" formControlName="capex" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.capex.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />
              <div class="invalid-feedback" *ngIf="submitted && f.capex.invalid">
                <div *ngIf="f.capex.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>

            <div class="col-3 margin-bottom-10">
              <label>Costi capitalizzati</label>
              <input type="text" formControlName="rimanenzeIn" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.rimanenzeIn.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />
              <div class="invalid-feedback" *ngIf="submitted && f.capex.invalid">
                <div *ngIf="f.rimanenzeIn.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3 margin-bottom-10">
              <label>Apporto azionista</label>
              <input type="text" formControlName="capexEquity" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.capexEquity.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />
              <div class="invalid-feedback" *ngIf="submitted && f.capexEquity.invalid">
                <div *ngIf="f.capexEquity.errors.required">
                  Campo obbligatorio
                </div>
              </div>
            </div>

            <div class="col-3 margin-bottom-10">
              <label>Importo finanziamento</label>
              <input type="text" formControlName="importoFinanziamento" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.importoFinanziamento.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />
              <div *ngIf="submitted && f.importoFinanziamento.errors" class="invalid-feedback">
                <div *ngIf="f.importoFinanziamento.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="col-3 margin-bottom-10">
              <label>Durata del finanziamento</label>
              <input required min="0" type="number" formControlName="durataFinanziamento" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.durataFinanziamento.errors }" />
              <div *ngIf="submitted && f.durataFinanziamento.errors" class="invalid-feedback">
                <div *ngIf="f.durataFinanziamento.errors.required">Campo obbligatorio</div>
              </div>
            </div>

            <div class="col-3 margin-bottom-10">
              <label>Anni di preammortamento</label>
              <input type="number" formControlName="durataAmmortamentoAnni" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.durataAmmortamentoAnni.errors }" />
              <div *ngIf="submitted && f.durataAmmortamentoAnni.errors" class="invalid-feedback">
                <div *ngIf="f.durataAmmortamentoAnni.errors.required">Campo obbligatorio</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-3 margin-bottom-10">
              <label>Valore garanzie</label>
              <input formControlName="valoreGaranzie" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.valoreGaranzie.errors }" currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',' , precision: 0 , suffix: ' €', allowNegative: false }" />
              <div *ngIf="submitted && f.valoreGaranzie.errors" class="invalid-feedback">
                <div *ngIf="f.valoreGaranzie.errors.required">Campo obbligatorio</div>
              </div>
            </div>
          </div>
          <!-- Valutare una situazione con importo azionista e importo finanziamento, oppure così aggiungendo la cassa-->
          <!-- inserimento bilancio -->
          <div class="row margin-top-20">
            <div class="margin-bottom-10 col-4 col-lg-4">
              <label>Vuoi eseguire l'analisi dell'ultimo bilancio? </label>
            </div>
            <div class="margin-bottom-10 col-1 col-lg-1">
              <select id="presenzaBilancio" formControlName="presenzaBilancio" class="form-control"
                (change)="onChangeBilancio()" [ngClass]="{ 'is-invalid': submitted && f.presenzaBilancio.errors }">
                <option [ngValue]="true">Si</option> <!-- setto il value di presenza bilancio -->
                <option [ngValue]="false">No</option>
                <div *ngIf="submitted && f.presenzaBilancio.errors" class="invalid-feedback">
                  <div *ngIf="f.presenzaBilancio.errors.required">Campo obbligatorio</div>
                </div>
              </select>
            </div>
          </div>
          <div *ngIf="f.presenzaBilancio.value">
            <div class="row">
              <div class="col-4 col-lg-4 margin-bottom-10">
                <label>Ragione sociale</label>
                <input type="text" formControlName="nomeAzienda" class="form-control"
                  [ngClass]="{ 'is-invalid': (submitParBilancio && f.nomeAzienda.errors) }" />
                <div *ngIf="submitParBilancio && f.nomeAzienda.errors " class="invalid-feedback">
                  <div *ngIf="f.nomeAzienda.errors.required">Campo obbligatorio</div>
                </div>
              </div>

              <div class="col-4 col-lg-4 margin-bottom-10">
                <label>Tipo Azienda</label>
                <select formControlName="tipoAzienda" class="form-control"
                  [ngClass]="{ 'is-invalid': (submitParBilancio && f.tipoAzienda.errors)}">
                  <option [ngValue]="elem" *ngFor="let elem of listaTipiAzienda">{{elem.descrizione}}</option>
                </select>
                <div *ngIf="submitParBilancio && f.tipoAzienda.errors" class="invalid-feedback">
                  <div *ngIf="f.tipoAzienda.errors.required">Campo obbligatorio</div>
                </div>
              </div>

              <div class="col-4 col-lg-4 margin-bottom-10">
                <label>Partita IVA</label>
                <input type="text" formControlName="partitaIva" class="form-control"
                  [ngClass]="{ 'is-invalid': (submitParBilancio && f.partitaIva.errors) }" />
                <div *ngIf="submitParBilancio && f.partitaIva.errors" class="invalid-feedback">
                  <div *ngIf="f.partitaIva.errors.required">Campo obbligatorio</div>
                </div>
              </div>

              <div class="col-3 col-lg-4 margin-bottom-10" *ngIf="listaBilanci.length > 0">
                <label>Seleziona Bilancio</label>
                <select formControlName="bilancio" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.bilancio.errors }">
                  <option [ngValue]="bilancio" *ngFor="let bilancio of listaBilanci">{{bilancio.descrizione}}</option>
                </select>
                <div *ngIf="submitted && f.bilancio.errors" class="invalid-feedback">
                  <div *ngIf="f.bilancio.errors.required">Campo obbligatorio</div>
                </div>
              </div>
              <div class="col-5 col-lg-4 margin-bottom-10 margin-top-20">
                <button class="btn btn-primary mr-1" type="button" (click)="goToBilancio()">Inserisci nuovo
                  bilancio</button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-12 text-center" style="padding-top:20px;padding-bottom:20px;">
        <button class="btn btn-primary margin-right-10">Calcola</button>
        <!-- nei form il primo bottone è un onSubmit di default -->
        <button class="btn btn-secondary" type="button" (click)="onReset()">Annulla</button>
      </div>

    </div>
  </form>

  <div *ngIf="boolRisultati">
    <div class="col-12 padding-left-right-15 padding-top-bottom-10 pointer borderAll text-center font-big margin-top-20"
      (click)="boolBP=!boolBP" [ngStyle]="{'background-color': coloreProgetto}">
      Sintesi Business Plan
      <span class="pull-right pointer" style="margin-top: -15px">
        <fa-icon style="font-size: 45px" [icon]="boolBP? faCaretUp : faCaretDown"></fa-icon>
      </span>
    </div>

    <div class="col-12  margin-bottom-20 padding-left-right-15 padding-top-bottom-10 borderL borderR borderB"
      style="background-color: white" *ngIf="boolBP">

      <div class="row margin-0">
        <div class="col-2 margin-bottom-10" style="padding-left: 0px" *ngIf="anniRisultati.length > 1">
          <label>Anni previsione</label>
          <select id="anniBP" [(ngModel)]="anniBP" [ngModelOptions]="{standalone: true}" class="form-control"
            (change)="onChangeAnniBP()">
            <option [ngValue]="anno.valore" *ngFor="let anno of anniRisultati">{{anno.label}}</option>
          </select>
        </div>

        <div class="col-2 margin-bottom-10" style="padding-left: 0px">
          <label>Profilo prospettico</label>
          <select id="profiloBP" [ngModelOptions]="{standalone: true}" [(ngModel)]="profiloBP" class="form-control">
            <option [ngValue]="0">1° percentile</option>
            <option [ngValue]="1">20° percentile</option>
            <option [ngValue]="2">30° percentile</option>
            <option [ngValue]="3">40° percentile</option>
            <option [ngValue]="4">50° percentile</option>
            <option [ngValue]="5">60° percentile</option>
            <option [ngValue]="6">70° percentile</option>
            <option [ngValue]="7">80° percentile</option>
            <option [ngValue]="8">100° percentile</option>
          </select>
        </div>

      </div>


      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack min-height-25"></div>
        <div class="col-2 borderTBlack borderRBlack min-height-25 font-bold flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{annoCorrente + i + anniBP}}
        </div>
      </div>
      <div class="row margin-0 text-center">
        <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
          Ricavi
        </div>
        <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{i + anniBP >=
          risultati.annoDefaultPositions[profiloBP] && risultati.annoDefaultPositions[profiloBP] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.ricaviComplessivi[profiloBP][i + anniBP] | number: '1.0-0':'it')}} €
        </div>
      </div>
      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Costi
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{i + anniBP >=
          risultati.annoDefaultPositions[profiloBP] && risultati.annoDefaultPositions[profiloBP] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.costiComplessivi[profiloBP][i + anniBP] | number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          EBITDA
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index"> {{risultati.ebitdaTot[profiloBP][i +
          anniBP]
          |
          number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Ammortamenti
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{i + anniBP >=
          risultati.annoDefaultPositions[profiloBP] && risultati.annoDefaultPositions[profiloBP]
          >
          0 ? (0 | number: '1.0-0':'it') : (risultati.ammortamentoTot[i + anniBP] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          EBIT

        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index"> {{risultati.ebitTot[profiloBP][i +
          anniBP] |
          number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Interessi passivi
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{risultati.interessiTot[profiloBP][i +
          anniBP] | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Tasse
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{risultati.tasseTot[profiloBP][i +
          anniBP]
          | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Net profit

        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{risultati.netProfitTot[profiloBP][i +
          anniBP]
          | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Debito residuo
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{i + anniBP >=
          risultati.annoDefaultPositions[profiloBP] && risultati.annoDefaultPositions[profiloBP]
          >
          0 ? (risultati.debitoResiduo[risultati.annoDefaultPositions[profiloBP] - 1] | number:
          '1.0-0':'it') : (risultati.debitoResiduo[i + anniBP] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Debito pregresso residuo
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{i + anniBP >=
          risultati.annoDefaultPositions[profiloBP] && risultati.annoDefaultPositions[profiloBP]
          >
          0 ? (risultati.debitoResiduoAz[risultati.annoDefaultPositions[profiloBP] - 1] | number:
          '1.0-0':'it') : (risultati.debitoResiduoAz[i + anniBP] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Finanziamento per cassa
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{risultati.finanzCassaTot[profiloBP][i
          + anniBP] | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Cash flow equity
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index">{{risultati.cashFlowEqTot[profiloBP][i
          + anniBP]
          | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Cash flow progetto
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index"> {{risultati.cashFlowPrTot[profiloBP][i
          + anniBP] | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          DSCR annuo
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiBP); let i = index"> {{risultati.dscrPositions[profiloBP][i
          + anniBP]*100 | number: '1.0-2':'it'}} %
        </div>

      </div>

      <div class="col-12 nopadding margin-top-10">
        <div class="row row-cols-4 margin-0 text-center">
          <div class="col borderAllBlack flex-justify-center font-bold">
            IRR equity
          </div>
          <!--
          <div class="col borderRBlack borderBBlack borderTBlack flex-justify-center font-bold">
            IRR progetto
          </div>
-->
          <div class="col borderRBlack borderBBlack borderTBlack flex-justify-center font-bold">
            Anno di break even
          </div>
          <div class="col borderRBlack borderBBlack borderTBlack flex-justify-center font-bold">
            Somma surplus cash flow equity
          </div>
          <div class="col borderRBlack borderBBlack borderTBlack flex-justify-center font-bold">
            DSCR totale
          </div>
          <div class="col borderRBlack borderBBlack borderLBlack  flex-justify-center">
            {{risultati.irrEqPositions[profiloBP]*100 | number: '1.2-2':'it'}} %
          </div>
          <!--
          <div class="col borderRBlack borderBBlack flex-justify-center">
            {{risultati.irrPrPositions[profiloBP]*100 | number: '1.2-2':'it'}} %
          </div>
-->
          <div class="col borderRBlack borderBBlack  flex-justify-center">
            {{risultati.breakEvenPositions[profiloBP] > f.anniSimulazione.value ? 'N/A' :
            (risultati.breakEvenPositions[profiloBP] | number: '1.0-0':'it')}}
          </div>
          <div class="col borderRBlack borderBBlack flex-justify-center">
            {{risultati.valCompanyPositions[profiloBP] | number: '1.0-0':'it'}} €
          </div>
          <div class="col borderRBlack borderBBlack flex-justify-center">
            {{risultati.dscrPositionsTot[profiloBP]*100 | number: '1.0-2':'it'}} %
          </div>
        </div>
      </div>

      <div class="col-12 nopadding margin-top-10">
        <div class="row margin-0 text-center">

          <div class="col-2 borderAllBlack min-height-25 margin-top-10 font-bold">Probabilit&agrave; di default a
            scadenza piano</div>
          <div class="col-2 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">LGD a scadenza
            piano </div>
          <div class="col-2 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Perdita attesa
            a scadenza piano</div>
          <div class="col-2 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Perdita attesa
            annuale</div>
          <div class="col-2 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Perdita
            inattesa a scadenza piano</div>
          <div class="col-2 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Perdita
            inattesa annuale</div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25">{{risultati.probDefaultAnnuale |
            number: '1.4-4':'it'}} %</div>
          <div class="col-2 borderRBlack borderBBlack min-height-25">{{risultati.mediaLossGivenDefault | number:
            '1.0-0':'it'}} €</div>
          <div class="col-2 borderRBlack borderBBlack min-height-25">{{risultati.perditaAttesaAnnuale | number:
            '1.4-4':'it'}} %</div>
          <div class="col-2 borderRBlack borderBBlack min-height-25">{{perditaAttesaAnnuaRadice | number: '1.4-4':'it'}}
            %</div>
          <div class="col-2 borderRBlack borderBBlack min-height-25">{{risultati.perditaInattesa | number:
            '1.4-4':'it'}} %</div>
          <div class="col-2 borderRBlack borderBBlack min-height-25">{{perditaInattesaAnnuaRadice | number:
            '1.4-4':'it'}} %</div>
        </div>
      </div>
    </div>

    <div class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20"
      (click)="boolIndicatori=!boolIndicatori" [ngStyle]="{'background-color': coloreProgetto}">
      Indicatori economici-finanziari
      <span class="pull-right pointer" style="margin-top: -15px">
        <fa-icon style="font-size: 45px" [icon]="boolIndicatori? faCaretUp : faCaretDown"></fa-icon>
      </span>
    </div>
    <div class="col-12 padding-top-bottom-10 padding-left-right-15 borderR borderL borderB"
      style="background-color: white" *ngIf="boolIndicatori">
      <div class="row margin-0">
        <div class="col-12 margin-bottom-20">
          <div class="col-3">
            <label>Indicatore</label>
            <select id="indicatore" [(ngModel)]="indicatoreEconometrico" (change)="onChangeIndicatore()"
              class="form-control">
              <option [ngValue]="'IRR Equity'" selected>IRR Equity</option>
              <!--
              <option [ngValue]="'IRR Progetto'">IRR Progetto</option>
-->
              <option [ngValue]="'Break even'">Anno di break even</option>
              <option [ngValue]="'Dscr'">DSCR</option>
              <option [ngValue]="'Valore compagnia'">Somma surplus cash flow equity</option>
            </select>
          </div>
        </div>

        <div class="col-8" style="margin:auto">
          <div class="row margin-0 text-center">
            <div class="col-4 borderAllBlack min-height-25 font-bold" *ngIf="indicatoreEconometrico != 'Break even'">
              Range</div>
            <div class="col-4 borderAllBlack min-height-25 font-bold" *ngIf="indicatoreEconometrico == 'Break even'">
              Anno</div>
            <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Frequenza</div>
            <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Probabilit&agrave;</div>
            <div class="col-12 nopadding">
              <div class="row margin-0 text-center" *ngFor="let item of frequenze; let i = index">
                <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{range[i]}}</div>
                <div class="col-4 borderRBlack borderBBlack min-height-25">{{frequenze[i]}}</div>
                <div class="col-4 borderRBlack borderBBlack min-height-25">{{(frequenze[i]/risultati.numSimulazioni)*100
                  | number: '1.0-2':'it'}} %</div>
              </div>
            </div>
            <div class="col-4 borderAllBlack min-height-25 margin-top-10 font-bold">Minimo</div>
            <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Massimo
            </div>
            <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 margin-top-10 font-bold">Media</div>

            <div class="col-12 nopadding"
              *ngIf="indicatoreEconometrico == 'IRR Equity' || indicatoreEconometrico == 'IRR Progetto' || indicatoreEconometrico == 'Dscr'">
              <div class="row margin-0">
                <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatore >= 0 ?
                  (minimoIndicatore | number: '1.0-2':'it') : 'Negativo'}} {{minimoIndicatore >=0 ? '%' : ''}}</div>
                <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatore >= 0 ? (massimoIndicatore
                  | number: '1.0-2':'it') : 'Negativo'}} {{massimoIndicatore >=0 ? '%' : ''}}</div>
                <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatore >=0 ? (mediaIndicatore |
                  number: '1.0-2':'it') : 'Negativo'}} {{mediaIndicatore >=0 ? '%' : ''}}</div>
              </div>
            </div>

            <div class="col-12 nopadding" *ngIf="indicatoreEconometrico == 'Break even'">
              <div class="row margin-0">
                <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatore >= 0 ?
                  (minimoIndicatore | number: '1.0-0':'it') : 'Negativo'}}</div>
                <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatore >= 0 ? (massimoIndicatore
                  | number: '1.0-0':'it') : 'Negativo'}}</div>
                <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatore >=0 ? (mediaIndicatore |
                  number: '1.0-0':'it') : 'Negativo'}}</div>
              </div>
            </div>

            <div class="col-12 nopadding" *ngIf="indicatoreEconometrico == 'Valore compagnia'">
              <div class="row margin-0">
                <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">{{minimoIndicatore >= 0 ?
                  (minimoIndicatore | number: '1.0-0':'it') : 'Negativo'}} {{minimoIndicatore >=0 ? '€' : ''}}</div>
                <div class="col-4 borderRBlack borderBBlack min-height-25">{{massimoIndicatore >= 0 ? (massimoIndicatore
                  | number: '1.0-0':'it') : 'Negativo'}} {{massimoIndicatore >=0 ? '€' : ''}}</div>
                <div class="col-4 borderRBlack borderBBlack min-height-25">{{mediaIndicatore >=0 ? (mediaIndicatore |
                  number: '1.0-0':'it') : 'Negativo'}} {{mediaIndicatore >=0 ? '€' : ''}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20"
      (click)="boolIndicatori=!boolIndicatori" [ngStyle]="{'background-color': coloreProgetto}">
      DSCR per anno
      <span class="pull-right pointer" style="margin-top: -15px">
        <fa-icon style="font-size: 45px" [icon]="boolIndicatori? faCaretUp : faCaretDown"></fa-icon>
      </span>
    </div>
    <div class="col-12 padding-top-bottom-10 padding-left-right-15 borderR borderL borderB"
      style="background-color: white" *ngIf="boolIndicatori">
      <div class="row margin-0">

        <div class="col-3 margin-bottom-10">
          <label>Indicatore</label>
          <select id="indiceDscrAnnuo" [(ngModel)]="indiceDscrAnnuo" (change)="onChangeIndiceDscrAnnuo()"
            class="form-control">
            <option [ngValue]="'annuo'">Dscr annuo</option>
            <option [ngValue]="'cumul'" selected>Dscr cumulato</option>
          </select>
        </div>
        <div class="col-2 margin-bottom-10">
          <label>Anni previsione</label>
          <select id="anniDscrAnnuo" [(ngModel)]="anniDscrAnnuo" (change)="onChangeAnniDscr()" class="form-control">
            <option [ngValue]="anno.valore" *ngFor="let anno of anniRisultati">{{anno.label}}</option>
          </select>
        </div>


        <div class="col-12 margin-bottom-10">
          <div class="row margin-0  text-center">
            <div class="col-2 borderBBlack borderRBlack min-height-25"></div>
            <div class="borderBBlack borderRBlack borderTBlack min-height-25 font-bold flex-justify-center"
              [ngClass]="'col-'+colonneRisultatiDscr*2">Probabilit&agrave;</div>
          </div>
          <div class="row margin-0  text-center">
            <div class="col-2 borderBBlack borderLBlack borderRBlack min-height-25 font-bold flex-justify-center">Range
            </div>
            <div class="col-2 borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
              *ngFor="let item of [].constructor(colonneRisultatiDscr); let i = index">{{annoCorrente + i +
              anniDscrAnnuo}}</div>
          </div>
          <div class="row margin-0 text-center"
            *ngFor="let item of [].constructor(freqDscrAnnuo[anniDscrAnnuo].length); let i = index">
            <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">
              {{risultati.bordiDscrString[i]}}</div>
            <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
              *ngFor="let item of [].constructor(colonneRisultatiDscr); let j = index">{{(freqDscrAnnuo[j +
              anniDscrAnnuo][i]/risultati.numSimulazioni)*100| number: '1.0-2':'it'}} %</div>
          </div>
        </div>

      </div>
    </div>

    <div class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20"
      (click)="boolMcc=!boolMcc" [ngStyle]="{'background-color': coloreProgetto}">
      Valutazione MCC
      <span class="pull-right pointer" style="margin-top: -15px">
        <fa-icon style="font-size: 45px" [icon]="boolMcc? faCaretUp : faCaretDown"></fa-icon>
      </span>
    </div>
    <div class="col-12 padding-top-bottom-10 borderR borderL borderB" style="background-color: white" *ngIf="boolMcc">
      <div class="row margin-0">
        <div class="col-3 margin-bottom-10">
          <label>Valutazione</label>
          <select id="valutazioneMcc" [(ngModel)]="valutazioneMcc" (change)="onChangeValutazioneMcc()"
            class="form-control">
            <option [ngValue]="'Tot'" selected>Complessiva</option>
            <option [ngValue]="'Bil'">Bilancio</option>
            <option [ngValue]="'Cr'">CR</option>
          </select>
        </div>
        <div class="col-3 margin-bottom-10">
          <label>Anno</label>
          <select id="anniMcc" [(ngModel)]="anniMcc" (change)="onChangeAnniMcc()" class="form-control">
            <option [ngValue]="anno.valore" *ngFor="let anno of anniRisultati">{{anno.label}}</option>
          </select>
        </div>

        <div class="col-12 margin-bottom-10">
          <div class="row margin-0  text-center">
            <div class="col-2 borderBBlack borderRBlack min-height-25"></div>
            <div class="borderBBlack borderRBlack borderTBlack min-height-25 font-bold flex-justify-center"
              [ngClass]="'col-'+colonneRisultatiMcc*2">Probabilit&agrave;</div>
          </div>
          <div class="row margin-0  text-center">
            <div class="col-2 borderBBlack borderLBlack borderRBlack min-height-25 font-bold flex-justify-center">Fascia
            </div>
            <div class="col-2 borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
              *ngFor="let item of [].constructor(colonneRisultatiMcc); let i = index">{{annoCorrente + i + anniMcc}}
            </div>
          </div>
          <div class="row margin-0 text-center"
            *ngFor="let item of [].constructor(frequenzeMcc[anniMcc].length); let i = index">
            <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">{{i + 1 ==
              frequenzeMcc[anniMcc].length ? 'UN' : prefissoMcc + (i+1)}}</div>
            <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
              *ngFor="let item of [].constructor(colonneRisultatiMcc); let j = index">{{(frequenzeMcc[j +
              anniMcc][i]/risultati.numSimulazioni)*100| number: '1.0-2':'it'}} %</div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20"
      (click)="boolCE=!boolCE" [ngStyle]="{'background-color': coloreProgetto}">
      Conto economico
      <span class="pull-right pointer" style="margin-top: -15px">
        <fa-icon style="font-size: 45px" [icon]="boolCE? faCaretUp : faCaretDown"></fa-icon>
      </span>
    </div>

    <div class="col-12  margin-bottom-20 padding-top-bottom-10 padding-left-right-15 borderL borderR borderB"
      style="background-color: white" *ngIf="boolCE">

      <div class="row margin-0">
        <div class="col-2 margin-bottom-10" style="padding-left: 0px" *ngIf="anniRisultati.length > 1">
          <label>Anni previsione</label>
          <select id="anniCE" [(ngModel)]="anniCE" [ngModelOptions]="{standalone: true}" class="form-control"
            (change)="onChangeAnniCE()">
            <option [ngValue]="anno.valore" *ngFor="let anno of anniRisultati">{{anno.label}}</option>
          </select>
        </div>

        <div class="col-2 margin-bottom-10" style="padding-left: 0px">
          <label>Profilo prospettico</label>
          <select id="profiloCE" [ngModelOptions]="{standalone: true}" [(ngModel)]="profiloCE" class="form-control">
            <option [ngValue]="0">1° percentile</option>
            <option [ngValue]="1">20° percentile</option>
            <option [ngValue]="2">30° percentile</option>
            <option [ngValue]="3">40° percentile</option>
            <option [ngValue]="4">50° percentile</option>
            <option [ngValue]="5">60° percentile</option>
            <option [ngValue]="6">70° percentile</option>
            <option [ngValue]="7">80° percentile</option>
            <option [ngValue]="8">100° percentile</option>
          </select>
        </div>

      </div>


      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack min-height-25"></div>
        <div class="col-2 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{annoCorrente + i + anniCE}}
        </div>
      </div>

      <div *ngFor="let it of [].constructor(numProdottiRisultati); let j = index">

        <div class="row margin-0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            {{j+1}}° Prodotto: {{arrayNomeProdotti[j]}}
          </div>
          <div class="borderRBlack borderBBlack min-height-25" [ngClass]="'col-'+colonneRisultatiCE*2"></div>
        </div>
        <div class="row margin-0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-center font-bold">
            Ricavi
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">
            {{i + anniCE >= risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0 ?
            (0 | number: '1.0-0':'it') : (risultati.prezzoProd[profiloCE][j][i + anniCE] *
            risultati.vendutoProd[profiloCE][j][i + anniCE] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-center">
            Prezzo unitario
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">
            {{i + anniCE >= risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0 ?
            (0 | number: '1.0-0':'it') : (risultati.prezzoProd[profiloCE][j][i + anniCE] | number: '1.0-2':'it')}} €
          </div>
        </div>

        <div class="row margin-0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-center">
            Quantit&agrave; venduta
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">
            {{i + anniCE >= risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0 ?
            (0 | number: '1.0-0':'it') : (risultati.vendutoProd[profiloCE][j][i + anniCE] | number: '1.0-0':'it')}}
          </div>
        </div>


      </div>

      <div class="row margin-0" *ngIf="boolAziendaEsistente">
        <div
          class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between flex-justify-center font-bold">
          Ricavi attivit&agrave; preesistente
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0 ? (0 | number:
          '1.0-0':'it') : (risultati.ricaviAz[profiloCE][i + anniCE] | number: '1.0-0':'it')}} €</div>

      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold"
          [ngStyle]="{'border-top':'1px solid black'}">
          Ricavi da vendite
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          [ngStyle]="{'border-top':'1px solid black'}"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.ricaviComplessivi[profiloCE][i + anniCE] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Rimanenze
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.rimanenzeRicavi[profiloCE][i + anniCE] +
          risultati.varRimProdAzienda[profiloCE][i + anniCE] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderRBBlack borderLBlack min-height-25 font-bold text-left"
          [ngStyle]="{'border-bottom':'4px double black','text-align':'left'}">
          Ricavi da vendite contabili
        </div>
        <div class="col-2 borderRBlack  borderRBBlack min-height-25 flex-justify-center"
          [ngStyle]="{'border-bottom':'4px double black'}"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.ricaviComplessivi[profiloCE][i + anniCE] +
          risultati.rimanenzeRicavi[profiloCE][i + anniCE] + risultati.varRimProdAzienda[profiloCE][i + anniCE] |
          number: '1.0-0':'it')}} €
        </div>
      </div>

      <div *ngFor="let it of [].constructor(numProdottiRisultati); let j = index">
        <div class="row margin-0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-center font-bold">
            {{j+1}}° Prodotto: {{arrayNomeProdotti[j]}}
          </div>
          <div class="borderRBlack borderBBlack min-height-25" [ngClass]="'col-'+colonneRisultatiCE*2"></div>
        </div>
        <div class="row margin-0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-center font-bold">
            Costi
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
            risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0 ? (0 | number:
            '1.0-0':'it') : (risultati.costoProd[profiloCE][j][i + anniCE] * risultati.prodottoProd[profiloCE][j][i +
            anniCE] | number: '1.0-0':'it')}} € </div>
        </div>

        <div class="row margin-0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-center">
            Costo unitario
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
            risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0 ? (0 | number:
            '1.0-0':'it') : (risultati.costoProd[profiloCE][j][i + anniCE] | number: '1.0-2':'it')}} €</div>
        </div>

        <div class="row margin-0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-center">
            Quantit&agrave; prodotta
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
            risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0 ? (0 | number:
            '1.0-0':'it') : (risultati.prodottoProd[profiloCE][j][i + anniCE] | number: '1.0-0':'it')}} </div>
        </div>


      </div>

      <div class="row margin-0" *ngIf="boolAziendaEsistente">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-center font-bold">
          Costi attivit&agrave; preesistente
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0 ? (0 | number:
          '1.0-0':'it') : (risultati.costiAz[profiloCE][i + anniCE] | number: '1.0-0':'it')}} €</div>

      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold"
          [ngStyle]="{'border-top':'1px solid black'}">
          Costi di produzione
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          [ngStyle]="{'border-top':'1px solid black'}"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.costiComplessivi[profiloCE][i + anniCE] | number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Rimanenze
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.rimanenzeCosti[profiloCE][i + anniCE] -
          risultati.varRimMaterieAzienda[profiloCE][i + anniCE]| number:
          '1.0-0':'it')}} €
        </div>
      </div>


      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderRBBlack borderLBlack min-height-25 font-bold"
          [ngStyle]="{'border-bottom':'4px double black','text-align':'left'}">
          Costi di produzione contabili
        </div>
        <div class="col-2 borderRBlack  borderRBBlack min-height-25 flex-justify-center"
          [ngStyle]="{'border-bottom':'4px double black'}"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.costiComplessivi[profiloCE][i + anniCE] +
          risultati.rimanenzeCosti[profiloCE][i + anniCE] - risultati.varRimMaterieAzienda[profiloCE][i + anniCE]|
          number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Margine delle vendite
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.ricaviComplessivi[profiloCE][i + anniCE] -
          risultati.costiComplessivi[profiloCE][i + anniCE] +
          risultati.rimanenzeTot[profiloCE][i + anniCE] + risultati.varRimProdAzienda[profiloCE][i + anniCE] +
          risultati.varRimMaterieAzienda[profiloCE][i + anniCE]| number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Costi fissi
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.costiFissi[i + anniCE] | number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Costi sul fatturato
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.costiFatt[profiloCE][i + anniCE] | number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          EBITDA
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index"> {{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE] > 0
          ?
          (0 | number: '1.0-0':'it') :(risultati.ebitdaTot[profiloCE][i + anniCE] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Ammortamenti
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{i + anniCE >=
          risultati.annoDefaultPositions[profiloCE] && risultati.annoDefaultPositions[profiloCE]
          > 0 ? (0 | number: '1.0-0':'it') : (risultati.ammortamentoTot[i + anniCE] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          EBIT

        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index"> {{risultati.ebitTot[profiloCE][i +
          anniCE] |
          number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Interessi passivi
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{risultati.interessiTot[profiloCE][i +
          anniCE] | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Tasse
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{risultati.tasseTot[profiloCE][i +
          anniCE]
          | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Net profit

        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCE); let i = index">{{risultati.netProfitTot[profiloCE][i +
          anniCE]
          | number: '1.0-0':'it'}} €
        </div>
      </div>
    </div>

    <div
      class="col-12 padding-left-right-15 pointer padding-top-bottom-10 padding-left-right-15 borderAll text-center font-big margin-top-20"
      (click)="boolCF=!boolCF" [ngStyle]="{'background-color': coloreProgetto}">
      Cash flow
      <span class="pull-right pointer" style="margin-top: -15px">
        <fa-icon style="font-size: 45px" [icon]="boolCF? faCaretUp : faCaretDown"></fa-icon>
      </span>
    </div>

    <div class="col-12 padding-left-right-15 margin-bottom-20 padding-top-bottom-10 borderL borderR borderB"
      style="background-color: white" *ngIf="boolCF">


      <div class="row margin-0">
        <div class="col-2 margin-bottom-10" style="padding-left: 0px" *ngIf="anniRisultati.length > 1">
          <label>Anni previsione</label>
          <select id="anniCF" [(ngModel)]="anniCF" [ngModelOptions]="{standalone: true}" class="form-control"
            (change)="onChangeAnniCF()">
            <option [ngValue]="anno.valore" *ngFor="let anno of anniRisultati">{{anno.label}}</option>
          </select>
        </div>

        <div class="col-2 margin-bottom-10" style="padding-left: 0px">
          <label>Profilo prospettico</label>
          <select id="profiloCF" [ngModelOptions]="{standalone: true}" [(ngModel)]="profiloCF" class="form-control">
            <option [ngValue]="0">1° percentile</option>
            <option [ngValue]="1">20° percentile</option>
            <option [ngValue]="2">30° percentile</option>
            <option [ngValue]="3">40° percentile</option>
            <option [ngValue]="4">50° percentile</option>
            <option [ngValue]="5">60° percentile</option>
            <option [ngValue]="6">70° percentile</option>
            <option [ngValue]="7">80° percentile</option>
            <option [ngValue]="8">100° percentile</option>
          </select>
        </div>

      </div>

      <div class="col-12 padding-top-bottom-10 text-center">
        <span class="font-bold">Metodo indiretto</span>
      </div>


      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack min-height-25"></div>
        <div class="col-2 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{annoCorrente + i + anniCF}}
        </div>
      </div>


      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Net profit

        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{risultati.netProfitTot[profiloCF][i +
          anniCF]
          | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Ammortamenti
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF]
          >
          0 ? (0 | number: '1.0-0':'it') : (risultati.ammortamentoTot[i + anniCF] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Anticipi
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.anticipiTot[profiloCF][i + anniCF] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center" *ngIf="boolAziendaEsistente">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Cash flow debiti pregressi
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (-risultati.cfFinAz[i + anniCF] | number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Cash flow finanziamento rateizzato
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (-risultati.cfFinRate[i + anniCF] | number:
          '1.0-0':'it')}} €
        </div>
      </div>


      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Cash flow finanziamento per cassa
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.cfFinCassa[profiloCF][i + anniCF] | number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Cash flow finanziamento castelletto
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.cfCastelletto[profiloCF][i + anniCF] | number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Variazione capitale circolante
          <span class="pull-right pointer" (click)="boolCapCircCF = !boolCapCircCF">
            <fa-icon style="font-size: 30px" [icon]="boolCapCircCF? faCaretUp : faCaretDown"></fa-icon>
          </span>
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.cfCreditiDebiti[profiloCF][i + anniCF] -
          risultati.varCassa[profiloCF][i + anniCF]
          - risultati.rimanenzeTot[profiloCF][i + anniCF] - risultati.varRimProdAzienda[profiloCF][i + anniCF] -
          risultati.varRimMaterieAzienda[profiloCF][i + anniCF]| number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center" *ngIf="boolCapCircCF">
        <div class="col-2 nopadding">
          <div class="row margin-0">
            <div class="col-2"></div>
            <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
              Variazione cassa
            </div>
          </div>
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (- risultati.varCassa[profiloCF][i + anniCF] | number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center" *ngIf="boolCapCircCF">
        <div class="col-2 nopadding">
          <div class="row margin-0">
            <div class="col-2"></div>
            <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
              Variazione rimanenze
            </div>
          </div>
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (- risultati.rimanenzeTot[profiloCF][i + anniCF] -
          risultati.varRimProdAzienda[profiloCF][i + anniCF] - risultati.varRimMaterieAzienda[profiloCF][i + anniCF]|
          number:
          '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center" *ngIf="boolCapCircCF">
        <div class="col-2 nopadding">
          <div class="row margin-0">
            <div class="col-2 borderBBlack"></div>
            <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
              Variazione crediti e debiti
            </div>
          </div>
        </div>

        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : (risultati.cfCreditiDebiti[profiloCF][i + anniCF] | number: '1.0-0':'it')}} €
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Cash flow equity
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{risultati.cashFlowEqTot[profiloCF][i
          + anniCF]
          | number: '1.0-0':'it'}} €
        </div>
      </div>

      <div class="col-12 padding-top-bottom-10 text-center">
        <span class="font-bold">Metodo diretto</span>
      </div>


      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack min-height-25"></div>
        <div class="col-2 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{annoCorrente + i + anniCF}}
        </div>
      </div>

      <div class="row margin-0 text-center">
        <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
          Totale ricavi per cassa
        </div>
        <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
          *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
          risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
          ?
          (0 | number: '1.0-0':'it') : ((risultati.cfCreditiDebiti[profiloCF][i + anniCF] <= 0 ?
            risultati.ricaviComplessivi[profiloCF][i + anniCF] + risultati.cfCreditiDebiti[profiloCF][i + anniCF] +
            risultati.cfCastelletto[profiloCF][i + anniCF] : risultati.ricaviComplessivi[profiloCF][i + anniCF] +
            risultati.cfCastelletto[profiloCF][i + anniCF])| number:'1.0-0':'it')}} € </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Anticipi
          </div>
          <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.anticipiTot[profiloCF][i + anniCF] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Costi di produzione
          </div>

          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF]
            > 0 ? (0 | number: '1.0-0':'it') : ((risultati.cfCreditiDebiti[profiloCF][i + anniCF] > 0 ?
            risultati.costiComplessivi[profiloCF][i + anniCF]
            - risultati.cfCreditiDebiti[profiloCF][i + anniCF] : risultati.costiComplessivi[profiloCF][i + anniCF]) |
            number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Margine delle vendite
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.ricaviComplessivi[profiloCF][i + anniCF] -
            risultati.costiComplessivi[profiloCF][i + anniCF]
            + risultati.cfCreditiDebiti[profiloCF][i + anniCF] + risultati.cfCastelletto[profiloCF][i + anniCF] +
            risultati.anticipiTot[profiloCF][i + anniCF]
            | number:'1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Costi fissi
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.costiFissi[i + anniCF] | number:
            '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Costi sul fatturato
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.costiFatt[profiloCF][i + anniCF] | number:
            '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Margine operativo lordo
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.ricaviComplessivi[profiloCF][i + anniCF] -
            risultati.costiComplessivi[profiloCF][i + anniCF]
            + risultati.anticipiTot[profiloCF][i + anniCF]-risultati.costiFissi[i +
            anniCF]-risultati.costiFatt[profiloCF][i + anniCF]
            + risultati.cfCreditiDebiti[profiloCF][i + anniCF] + risultati.cfCastelletto[profiloCF][i + anniCF]
            | number:'1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Interessi passivi
          </div>

          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{risultati.interessiTot[profiloCF][i
            + anniCF] | number: '1.0-0':'it'}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Tasse
          </div>

          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{risultati.tasseTot[profiloCF][i +
            anniCF]
            | number: '1.0-0':'it'}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolAziendaEsistente">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Cash flow debiti pregressi
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (-risultati.cfFinAz[i + anniCF] | number:
            '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Cash flow finanziamento rateizzato
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (-risultati.cfFinRate[i + anniCF] | number:
            '1.0-0':'it')}} €
          </div>
        </div>


        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Cash flow finanziamento per cassa
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.cfFinCassa[profiloCF][i + anniCF] | number:
            '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Variazione cassa
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">{{i + anniCF >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloCF] > 0
            ?
            (0 | number: '1.0-0':'it') : (-risultati.varCassa[profiloCF][i + anniCF] | number:
            '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Cash flow equity
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiCF); let i = index">
            {{risultati.cashFlowEqTot[profiloCF][i + anniCF]
            | number: '1.0-0':'it'}} €
          </div>
        </div>

      </div>

      <div
        class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20"
        (click)="boolSP=!boolSP" [ngStyle]="{'background-color': coloreProgetto}">
        Stato patrimoniale
        <span class="pull-right pointer" style="margin-top: -15px">
          <fa-icon style="font-size: 45px" [icon]="boolSP? faCaretUp : faCaretDown"></fa-icon>
        </span>
      </div>

      <div class="col-12  margin-bottom-20 padding-top-bottom-10 padding-left-right-15 borderL borderR borderB"
        style="background-color: white" *ngIf="boolSP">

        <div class="row margin-0">
          <div class="col-2 margin-bottom-10" style="padding-left: 0px" *ngIf="anniRisultati.length > 1">
            <label>Anni previsione</label>
            <select id="anniSP" [(ngModel)]="anniSP" [ngModelOptions]="{standalone: true}" class="form-control"
              (change)="onChangeAnniSP()">
              <option [ngValue]="anno.valore" *ngFor="let anno of anniRisultati">{{anno.label}}</option>
            </select>
          </div>

          <div class="col-2 margin-bottom-10" style="padding-left: 0px">
            <label>Profilo prospettico</label>
            <select id="profiloSP" [ngModelOptions]="{standalone: true}" [(ngModel)]="profiloSP" class="form-control">
              <option [ngValue]="0">1° percentile</option>
              <option [ngValue]="1">20° percentile</option>
              <option [ngValue]="2">30° percentile</option>
              <option [ngValue]="3">40° percentile</option>
              <option [ngValue]="4">50° percentile</option>
              <option [ngValue]="5">60° percentile</option>
              <option [ngValue]="6">70° percentile</option>
              <option [ngValue]="7">80° percentile</option>
              <option [ngValue]="8">100° percentile</option>
            </select>
          </div>

        </div>

        <div class="col-12 padding-top-bottom-10 text-center">
          <span class="font-bold">Attivo</span>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack min-height-25"></div>
          <div class="col-2 borderTBlack borderRBlack borderBBlack min-height-25 font-bold flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{annoCorrente + i + anniSP}}
          </div>
        </div>

        <div class="row margin-0 text-center"
          *ngIf="boolAziendaEsistente && risultati.creditiSoci && risultati.creditiSoci != 0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Crediti verso soci per versamenti ancora dovuti
          </div>
          <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.creditiSoci | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Attivo immobilizzato
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloCF] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.attivoImmob[i + anniSP] + risultati.attivoImmobAzienda[i + anniSP] |
            number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Attivo circolante
            <span class="pull-right pointer" (click)="boolAttCirc = !boolAttCirc">
              <fa-icon style="font-size: 30px" [icon]="boolAttCirc? faCaretUp : faCaretDown"></fa-icon>
            </span>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.iva[profiloSP][i + anniSP] + risultati.cassa[profiloSP][i + anniSP]
            + risultati.attivoRimanenze[profiloSP][i + anniSP] + risultati.creditiDebitiBt[profiloSP][i + anniSP] +
            risultati.rimProdAzienda[profiloSP][i + anniSP] + risultati.rimMaterieAzienda[profiloSP][i + anniSP] +
            risultati.attFinAzienda | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolAttCirc">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Cassa
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.cassa[profiloSP][i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolAttCirc">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Rimanenze
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.attivoRimanenze[profiloSP][i + anniSP] +
            risultati.rimProdAzienda[profiloSP][i + anniSP] + risultati.rimMaterieAzienda[profiloSP][i + anniSP]|
            number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolAttCirc">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Credito Iva
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.iva[profiloSP][i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolAttCirc">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2 "></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Crediti e debiti a breve termine
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.creditiDebitiBt[profiloSP][i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>


        <div class="row margin-0 text-center"
          *ngIf="boolAttCirc && boolAziendaEsistente && risultati.attFinAzienda && risultati.attFinAzienda != 0">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2 borderBBlack"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Attivit&agrave; finanziarie che non costituiscono immobilizzazioni
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.attFinAzienda | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center"
          *ngIf="boolAziendaEsistente && risultati.rateiAttivo && risultati.rateiAttivo != 0">

          <div class="col-2 borderRBlack borderBBlack borderLBlack font-bold min-height-25 flex-justify-space-between">
            Totale ratei e risconti
          </div>

          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.rateiAttivo | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Totale attivo
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.iva[profiloSP][i + anniSP] + risultati.cassa[profiloSP][i + anniSP]
            + risultati.attivoRimanenze[profiloSP][i + anniSP] + risultati.attivoImmob[i + anniSP]
            + risultati.creditiDebitiBt[profiloSP][i + anniSP] + risultati.attivoImmobAzienda[i + anniSP] +
            risultati.rimProdAzienda[profiloSP][i + anniSP] + risultati.rimMaterieAzienda[profiloSP][i + anniSP]
            + risultati.rateiAttivo + risultati.creditiSoci + risultati.attFinAzienda | number:
            '1.0-0':'it')}} €
          </div>
        </div>

        <div class="col-12 padding-top-bottom-10 margin-top-20 text-center">
          <span class="font-bold">Passivo</span>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack min-height-25"></div>
          <div class="col-2 borderTBlack borderRBlack min-height-25 font-bold flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{annoCorrente + i + anniSP}}
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderAllBlack min-height-25 flex-justify-space-between font-bold">
            Patrimonio netto
            <span class="pull-right pointer" (click)="boolPatrNet= !boolPatrNet">
              <fa-icon style="font-size: 30px" [icon]="boolPatrNet? faCaretUp : faCaretDown"></fa-icon>
            </span>
          </div>
          <div class="col-2 borderRBlack borderBBlack borderTBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.capexEquity + risultati.capexEquityAzienda -
            risultati.dividendoCumulato[profiloSP][i + anniSP]
            + risultati.netProfitCumulato[profiloSP][i + anniSP] + risultati.netProfitTot[profiloSP][i + anniSP] |
            number:
            '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolPatrNet">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Apporto azionista
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.capexEquity | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center"
          *ngIf="boolPatrNet && boolAziendaEsistente && risultati.capexEquityAzienda != 0">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10  min-height-25 flex-justify-space-between">
                Patrimonio azienda esistente
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.capexEquityAzienda | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolPatrNet">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Utili e perdite anni precedenti
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.netProfitCumulato[profiloSP][i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolPatrNet">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Utili e perdite anno in corso
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.netProfitTot[profiloSP][i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolPatrNet">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2 borderBBlack"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Dividendi cumulati
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (- risultati.dividendoCumulato[profiloSP][i + anniSP] | number: '1.0-0':'it')}}
            €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Totale debiti bancari
            <span class="pull-right pointer" (click)="boolDebTot = !boolDebTot">
              <fa-icon style="font-size: 30px" [icon]="boolDebTot? faCaretUp : faCaretDown"></fa-icon>
            </span>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.finanzCassaTot[profiloSP][i + anniSP] + risultati.debitoResiduo[i +
            anniSP] +
            risultati.iva[profiloSP][i + anniSP] + risultati.stockFinCastel[profiloSP][i + anniSP] +
            risultati.debitoResiduoAz[i + anniSP] | number:
            '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolDebTot">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Debiti per finanziamento rateizzato
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.debitoResiduo[i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolDebTot">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Debiti per finanziamento per cassa
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.finanzCassaTot[profiloSP][i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center"
          *ngIf="boolDebTot && boolAziendaEsistente && risultati.debitoResiduoAz[0] != 0">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Debiti pregressi
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.debitoResiduoAz[i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolDebTot">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Debiti per finanziamento castelletto
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.stockFinCastel[profiloSP][i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>


        <div class="row margin-0 text-center" *ngIf="boolDebTot">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2 borderBBlack"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Linea Iva
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.iva[profiloSP][i + anniSP] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center"
          *ngIf="boolAziendaEsistente && risultati.rischiOneri && risultati.rischiOneri != 0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Totale fondi per rischi e oneri
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.rischiOneri | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="boolAziendaEsistente && risultati.tfrAz && risultati.tfrAz != 0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Trattamento di fine rapporto di lavoro subordinato
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.tfrAz | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center"
          *ngIf="boolAziendaEsistente && risultati.rateiPassivo && risultati.rateiPassivo != 0">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Totale ratei e risconti
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.rateiPassivo | number: '1.0-0':'it')}} €
          </div>
        </div>
        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Totale passivo e netto
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiSP); let i = index">{{i + anniSP >=
            risultati.annoDefaultPositions[profiloSP] && risultati.annoDefaultPositions[profiloSP] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.capexEquity - risultati.dividendoCumulato[profiloSP][i + anniSP]
            + risultati.netProfitCumulato[profiloSP][i + anniSP] + risultati.netProfitTot[profiloSP][i + anniSP]
            + risultati.finanzCassaTot[profiloSP][i + anniSP] + risultati.debitoResiduo[i + anniSP] +
            risultati.iva[profiloSP][i + anniSP] + risultati.stockFinCastel[profiloSP][i + anniSP] +
            risultati.debitoResiduoAz[i + anniSP] + risultati.rateiPassivo
            + risultati.tfrAz + risultati.rischiOneri + risultati.capexEquityAzienda | number:
            '1.0-0':'it')}} €
          </div>
        </div>
      </div>

      <div
        class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20"
        (click)="boolDeb=!boolDeb" [ngStyle]="{'background-color': coloreProgetto}">
        Debito finanziario
        <span class="pull-right pointer" style="margin-top: -15px">
          <fa-icon style="font-size: 45px" [icon]="boolDeb? faCaretUp : faCaretDown"></fa-icon>
        </span>
      </div>

      <div class="col-12  margin-bottom-20 padding-top-bottom-10 padding-left-right-15 borderL borderR borderB"
        style="background-color: white" *ngIf="boolDeb">

        <div class="row margin-0">
          <div class="col-2 margin-bottom-10" style="padding-left: 0px" *ngIf="anniRisultati.length > 1">
            <label>Anni previsione</label>
            <select id="anniDeb" [(ngModel)]="anniDeb" [ngModelOptions]="{standalone: true}" class="form-control"
              (change)="onChangeAnniDeb()">
              <option [ngValue]="anno.valore" *ngFor="let anno of anniRisultati">{{anno.label}}</option>
            </select>
          </div>

          <div class="col-2 margin-bottom-10" style="padding-left: 0px">
            <label>Profilo prospettico</label>
            <select id="profiloDeb" [ngModelOptions]="{standalone: true}" [(ngModel)]="profiloDeb" class="form-control">
              <option [ngValue]="0">1° percentile</option>
              <option [ngValue]="1">20° percentile</option>
              <option [ngValue]="2">30° percentile</option>
              <option [ngValue]="3">40° percentile</option>
              <option [ngValue]="4">50° percentile</option>
              <option [ngValue]="5">60° percentile</option>
              <option [ngValue]="6">70° percentile</option>
              <option [ngValue]="7">80° percentile</option>
              <option [ngValue]="8">100° percentile</option>
            </select>
          </div>

        </div>


        <div class="row margin-0 text-center">
          <div class="col-2 borderRBlack min-height-25"></div>
          <div class="col-2 borderTBlack borderRBlack min-height-25 font-bold flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{annoCorrente + i + anniDeb}}
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderLBlack borderBBlack borderTBlack min-height-25 flex-justify-space-between font-bold">
            Finanziamento rateizzato
          </div>
          <div class="col-2 borderBBlack borderTBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index"
            [ngClass]="{ 'borderRBlack': i == colonneRisultatiDeb -1 }">
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderBBlack borderRBlack borderLBlack min-height-25 flex-justify-space-between">
                Debito residuo
              </div>
            </div>
          </div>

          <div class="col-2 borderBBlack borderRBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.debitoResiduo[i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Interessi
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.interesseRate[i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2 borderBBlack"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Tasso d'interesse
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i == 0 ?
            (risultati.tassoInteresseRate*100. | number:'1.0-2':'it') + ' %' : ''}}
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Finanziamento per cassa
          </div>
          <div class="col-2 borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index"
            [ngClass]="{ 'borderRBlack': i == colonneRisultatiDeb -1 }">
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Stock finanziamento
              </div>
            </div>
          </div>

          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.finanzCassaTot[profiloDeb][i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Interessi
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.interesseCassa[profiloDeb][i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2 borderBBlack"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Tasso d'interesse
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i == 0 ?
            (risultati.tassoInteresseCassa*100. | number:'1.0-2':'it') + ' %' : ''}}
          </div>
        </div>

        <div class="row margin-0 text-center" *ngIf="risultati.debitoResiduoAz[0] > 0">
          <div class="col-2 borderLBlack borderBBlack  min-height-25 flex-justify-space-between font-bold">
            Finanziamenti pregressi
          </div>
          <div class="col-2 borderBBlack  min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index"
            [ngClass]="{ 'borderRBlack': i == colonneRisultatiDeb -1 }">
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderBBlack borderRBlack borderLBlack min-height-25 flex-justify-space-between">
                Debito residuo
              </div>
            </div>
          </div>

          <div class="col-2 borderBBlack borderRBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.debitoResiduoAz[i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Interessi
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.interesseRateAz[i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2 borderBBlack"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Tasso d'interesse
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i == 0 ?
            (risultati.tassoInteresseAz*100. | number:'1.0-2':'it') + ' %' : ''}}
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Finanziamento castelletto
          </div>
          <div class="col-2 borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index"
            [ngClass]="{ 'borderRBlack': i == colonneRisultatiDeb -1 }">
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Stock finanziamento
              </div>
            </div>
          </div>

          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.stockFinCastel[profiloDeb][i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Interessi
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.interesseFinCastel[profiloDeb][i + anniDeb] | number:
            '1.0-0':'it')}}
            €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2 borderBBlack"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Tasso d'interesse
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i == 0 ?
            (risultati.tassoInteresseCastelletto*100. | number:'1.0-2':'it') + ' %' : ''}}
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 borderBBlack borderLBlack min-height-25 flex-justify-space-between font-bold">
            Linea Iva
          </div>
          <div class="col-2 borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index"
            [ngClass]="{ 'borderRBlack': i == colonneRisultatiDeb -1 }">
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Stock finanziamento
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.iva[profiloDeb][i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Interessi
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index">{{i + anniDeb >=
            risultati.annoDefaultPositions[profiloDeb] && risultati.annoDefaultPositions[profiloDeb] > 0
            ?
            (0 | number: '1.0-0':'it') : (risultati.interesseIva[profiloDeb][i + anniDeb] | number: '1.0-0':'it')}} €
          </div>
        </div>

        <div class="row margin-0 text-center">
          <div class="col-2 nopadding">
            <div class="row margin-0">
              <div class="col-2"></div>
              <div class="col-10 borderRBlack borderBBlack borderLBlack min-height-25 flex-justify-space-between">
                Tasso d'interesse
              </div>
            </div>
          </div>
          <div class="col-2 borderRBlack borderBBlack min-height-25 flex-justify-center"
            *ngFor="let item of [].constructor(colonneRisultatiDeb); let i = index"> {{i == 0 ?
            (risultati.tassoInteresseIva*100. | number:'1.0-2':'it') + ' %' : ''}}
          </div>
        </div>
      </div>

      <div
        class="col-12 padding-left-right-15 pointer padding-top-bottom-10 borderAll text-center font-big margin-top-20"
        (click)="boolIndiceBil=!boolIndiceBil" [ngStyle]="{'background-color': coloreProgetto}">
        Indici di bilancio
        <span class="pull-right pointer" style="margin-top: -15px">
          <fa-icon style="font-size: 45px" [icon]="boolIndiceBil? faCaretUp : faCaretDown"></fa-icon>
        </span>
      </div>
      <div class="col-12 padding-top-bottom-10 padding-left-right-15 borderR borderL borderB"
        style="background-color: white" *ngIf="boolIndiceBil">
        <div class="row margin-0">
          <div class="col-2 margin-bottom-10" style="padding-left: 0px">
            <label>Profilo prospettico</label>
            <select id="profiloIndici" [ngModelOptions]="{standalone: true}" [(ngModel)]="profiloIndici"
              class="form-control">
              <option [ngValue]="0">1° percentile</option>
              <option [ngValue]="1">20° percentile</option>
              <option [ngValue]="2">30° percentile</option>
              <option [ngValue]="3">40° percentile</option>
              <option [ngValue]="4">50° percentile</option>
              <option [ngValue]="5">60° percentile</option>
              <option [ngValue]="6">70° percentile</option>
              <option [ngValue]="7">80° percentile</option>
              <option [ngValue]="8">100° percentile</option>
            </select>
          </div>

          <div class="col-2 margin-bottom-10">
            <label>Anno</label>
            <select id="anniIndici" [(ngModel)]="anniIndici" (change)="onChangeAnniIndici()" class="form-control">
              <option [ngValue]="anno.valore" *ngFor="let anno of anniRisultati">{{anno.label}}</option>
            </select>
          </div>

          <div class="col-12 margin-bottom-10">

            <div class="row margin-0  text-center">
              <div class="col-2 borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"></div>
              <div class="col-2 borderBBlack borderRBlack borderTBlack min-height-25 font-bold flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{annoCorrente + i + anniMcc}}</div>
            </div>
            <div class="row margin-0  text-center">
              <div class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold">
                Indicatori redditivit&agrave;</div>
              <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                [ngClass]="'col-'+colonneIndici*2"></div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">Rotazione
                capitale investito</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{risultati.indiceRot[profiloIndici][i
                + anniIndici]*100 | number: '1.0-1':'it'}} %</div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">Return on
                sales</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{risultati.indiceRos[profiloIndici][i
                + anniIndici]*100 | number: '1.0-1':'it'}} %</div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">Return on
                investment</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{risultati.indiceRoi[profiloIndici][i
                + anniIndici]*100 | number: '1.0-1':'it'}} %</div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">Return on
                equity</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{risultati.indiceRoe[profiloIndici][i
                + anniIndici]*100 | number: '1.0-1':'it'}} %</div>
            </div>
            <div class="row margin-0  text-center">
              <div class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold">
                Indicatori liquidit&agrave;</div>
              <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                [ngClass]="'col-'+colonneIndici*2"></div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">Margine di
                struttura</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">
                {{risultati.margStrutt[profiloIndici][i + anniIndici] | number: '1.0-0':'it'}} €</div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">Margine di
                tesoreria</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{risultati.attivCirc[profiloIndici][i
                + anniIndici] | number: '1.0-0':'it'}} €</div>
            </div>
            <div class="row margin-0  text-center">
              <div class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold">
                Indicatori di copertura finanziaria</div>
              <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                [ngClass]="'col-'+colonneIndici*2"></div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">PFN/EBITDA
              </div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{risultati.pfnEbitda[profiloIndici][i
                + anniIndici]*100 | number: '1.0-1':'it'}} %</div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">Interest
                coverage ratio</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{risultati.indiceIcs[profiloIndici][i
                + anniIndici]*100 | number: '1.0-1':'it'}} %</div>
            </div>
            <div class="row margin-0  text-center">
              <div class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold">
                Indicatori di solidit&agrave;</div>
              <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                [ngClass]="'col-'+colonneIndici*2"></div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">Capitale
                proprio/attivo</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">
                {{risultati.ratioCapAtt[profiloIndici][i + anniIndici]*100 | number: '1.0-1':'it'}} %</div>
            </div>
            <div class="row margin-0  text-center">
              <div class="col-2 borderBBlack borderLBlack min-height-25 font-bold flex-justify-center font-bold">
                Indicatori di bancabilit&agrave;</div>
              <div class="borderBBlack borderRBlack min-height-25 font-bold flex-justify-center"
                [ngClass]="'col-'+colonneIndici*2"></div>
            </div>
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">LLCR</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center"
                *ngFor="let item of [].constructor(colonneIndici); let i = index">{{risultati.llcr[profiloIndici][i +
                anniIndici] == 0 ? '' : (risultati.llcr[profiloIndici][i + anniIndici]*100 | number: '1.0-1':'it')+
                '%'}} </div>
            </div>
            <!--
          <div class="row margin-0 text-center">
            <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center" >IRR progetto</div>
            <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center" >{{risultati.irrPrPositions[profiloIndici]*100 | number: '1.0-1':'it'}} %</div>
          </div>
-->
            <div class="row margin-0 text-center">
              <div class="col-2 borderLBlack borderRBlack borderBBlack min-height-25 flex-justify-center">VAN</div>
              <div class="col-2 borderRBlack borderBBlack  min-height-25 flex-justify-center">
                {{risultati.van[profiloIndici]| number: '1.0-0':'it'}} €</div>
            </div>
          </div>
        </div>

        <div class="row margin-0">
          <div class="col-3 margin-bottom-10">
            <label>Indice</label>
            <select id="indiceBil" [(ngModel)]="indiceBil" (change)="onChangeIndiceBil()" class="form-control">
              <option [ngValue]="'capCirc'" selected>Capitale circolante netto</option>
              <option [ngValue]="'netProfit'">Net profit</option>
              <option [ngValue]="'patrDeb'">Ratio patrimonio netto / debiti</option>
              <option [ngValue]="'patrImmob'">Ratio patrimonio netto / attivo immobilizzato</option>

            </select>
          </div>
          <div class="col-3 margin-bottom-10">
            <label>Anno</label>
            <select id="anniIndiceBil" [(ngModel)]="anniIndice" class="form-control">
              <option [ngValue]="i" *ngFor="let anno of [].constructor(freqIndiceBil.length); let i = index">
                {{annoCorrente + i}}</option>
            </select>
          </div>
        </div>
        <div class="row margin-0">
          <div class="col-8" style="margin:auto">
            <div class="row margin-0 text-center">
              <div class="col-4 borderAllBlack min-height-25 font-bold">Range</div>
              <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Frequenza</div>
              <div class="col-4 borderRBlack borderTBlack borderBBlack min-height-25 font-bold">Probabilit&agrave;</div>
              <div class="col-12 nopadding">
                <div class="row margin-0 text-center"
                  *ngFor="let item of [].constructor(freqIndiceBil[anniIndice].length); let i = index">
                  <div class="col-4 borderLBlack borderRBlack borderBBlack min-height-25">
                    {{bordiIndiceBil[anniIndice][i]}}</div>
                  <div class="col-4 borderRBlack borderBBlack min-height-25">{{freqIndiceBil[anniIndice][i]}}</div>
                  <div class="col-4 borderRBlack borderBBlack min-height-25">
                    {{(freqIndiceBil[anniIndice][i]/risultati.numSimulazioni)*100 | number: '1.0-2':'it'}} %</div>
                </div>
              </div>

            </div>

          </div>


        </div>
      </div>

    </div>
  </div>