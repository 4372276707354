<div class="container" *ngIf="pratiche.length">

  <div class="card mt-4">
    <div class="sfondo-header ">
      <div class="title margin-bottom-20">
        <h3 class="text-left mb-1">Pratiche Business Plan</h3>
      </div>
      <div class="d-flex justify-content-end align-items-end">
        <span style="margin-left: 40px; font-family: FontAwesome;">
          <input type="text" [(ngModel)]="userFilter.riferimento" autofocus
            placeholder="&#xf002; Filtro per riferimento">
        </span>
      </div>
    </div>

  </div>
  <mv-spinner *ngIf="!pratiche"></mv-spinner>
  <ng-container>
    <table class="table table-hover table-sm table-responsive table-bordered" *ngIf="pratiche.length">
      <thead>
        <tr>
          <th class="text-center" scope="col"></th>
          <th class="text-center" scope="col"></th>
          <th class="text-center align-middle" scope="col">Riferimento</th>
          <th class="text-center" scope="col">Data
            <button class="btn shadow-none" (click)="sortDate()" ngbTooltip="Ordine per data">
              <i class="fa fa-sort" aria-hidden="true"></i></button>
          </th>
          <th class="text-center align-middle" scope="col">Stato</th>
          <th class="text-center" scope="col"></th>
        </tr>
      </thead>
      <tbody
        *ngFor="let record of pratiche | paginate: {itemsPerPage: 10, currentPage: p} | filterBy: userFilter; let i = index"
        style="height: 10px !important; overflow: scroll;">
        <tr style="vertical-align: middle;">
          <td style="text-align: center;">
            <i *ngIf="!record.definitivo" class="fa fa-trash fa-lg pointer" aria-hidden="true"
              (click)="openModalElimina(record)"></i>
          </td>
          <td style="text-align: center;">
            <i class="fa fa-newspaper-o fa-fw" aria-hidden="true"></i>
            - {{ i + 1 }}
          </td>
          <td>{{ record.riferimento }}</td>
          <td>{{ record.data_registrazione | date: 'dd/MM/yyyy' }}</td>
          <td>
            {{record.definitivo ? 'Definitivo' : 'In lavorazione'}}
          </td>
          <td>
            <button type="button" class="btn btn-dark margin-right-10" (click)="goToEba(record)"
              *ngIf="record.definitivo">
              <i class="fa fa-clipboard" style="font-size: 0.8rem;" aria-hidden="true"></i>&nbsp;Backtest
            </button>
            <button type="button" class="btn btn-dark" (click)="selPratica(record)" *ngIf="record.definitivo">
              <i class="fa fa-eye fa-fw" style="font-size: 0.8rem;" aria-hidden="true"></i>&nbsp;Visualizza
            </button>
            <button type="button" class="btn btn-dark" (click)="selPratica(record)" *ngIf="!record.definitivo">
              <i class="fa fa-edit fa-fw" style="font-size: 0.8rem;" aria-hidden="true"></i>&nbsp;Modifica&nbsp;
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <hr>
    <div class="row d-flex justify-content-between ">
      <div class="col-4 no-margin d-flex justify-content-start">
        <pagination-controls nextLabel="Prossima" previousLabel="Precedente" (pageChange)="p=$event">
        </pagination-controls> <!-- navigatore di pag per piu pratiche salvate -->
      </div>
      <div class="col-2 no-margin d-flex justify-content-end">

        <span *ngIf="tokenService.getUser().cliente.idTipoCliente==1" class="text-secondary"> Totale Pratiche : {{
          pratiche.length }}</span>
        <!-- <span  *ngIf="tokenService.getUser().tipoCliente=='società commercialista'" class="text-secondary"> partite IVA utilizzate : {{tokenService.getUser().maxIVA}}/{{ pratiche.length }}</span> -->
      </div>
    </div>
    <div class="row margin-top-20">
      <div class="col-sm">
        <button *ngIf="pratiche.length" type="button" class="btn btn-dark" (click)="nuovaPratica();">
          <i class="fa fa-plus fa-fw" style="font-size: 0.8rem;" aria-hidden="true"></i>Nuova
        </button>
      </div>
    </div>
  </ng-container>
</div>