/* ----------------------------------------------------------------------------------------------
 * HTTP INTERCEPTOR
 * Metodo per ispezionare e trasformare le richieste HTTP prima che vengano inviate al server.
 * Aggiunge all'intestazione di autorizzazione il prefisso "Bearer" al token.
 * intercept() ottiene l'oggetto HTTPRequest, cambialo e passa HttpHandler al handle() metodo
 * dell'oggetto. Trasforma l'oggetto HTTPRequest in un file Observable<HttpEvents>.
 * next: HttpHandler object rappresenta il prossimo intercettore nella catena degli intercettori.
 * L'ultimo "successivo" della catena è Angular HttpClient.
 * ---------------------------------------------------------------------------------------------- */
import { HTTP_INTERCEPTORS, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from '../_services/token-storage.service';
import { Observable } from 'rxjs';

// const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end
const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token: TokenStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      // authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) });
    }
    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
