import { Bilancio } from '../component/bilancio';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Richiedente } from '../interface/richiedente';
import { ListaCampiBilancio } from '../interface/campiBilancio';
import { BilancioManuale, BilancioManualeTot } from '../interface/BilancioManuale';
const ApiUrl = environment.apiUrlNode + '/api/bilancio/';
const ApiUrlXbrl = environment.apiUrlQuestionario;

@Injectable({
  providedIn: 'root'
})

export class BilancioService {

  constructor(private http: HttpClient) { }

  private anagraficaRichiedente = new BehaviorSubject<Richiedente>(null);
  currentAnagraficaRichiedente = this.anagraficaRichiedente.asObservable();

  private debitoFornitoriDebitoria = new BehaviorSubject<number>(0);
  currentDebitoFornitoriDebitoria = this.debitoFornitoriDebitoria.asObservable();

  private dataBilObj = new BehaviorSubject<any>(null);
  currentDataBilObj = this.dataBilObj.asObservable();

  /*   private dataCrObj = new BehaviorSubject<any>(null);
    currentDataCrObj = this.dataCrObj.asObservable();
   */
  getDataLastBilancio(idRichiedente: number): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getDataLastBilancio', { idRichiedente: idRichiedente });
  }
  getDataLastCr(idRichiedente: number): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/configurazioneAmbiente/getDataLastCr', { idRichiedente: idRichiedente });
  }
  loadDataBilObj(dataBilObj: any) {
    this.dataBilObj.next(dataBilObj)
  }

  /*   loadDataCrObj(dataCrObj: any) {
      this.dataCrObj.next(dataCrObj)
    } */
  checkDebitoFornitoriDebitoria(nuovoDebito: number) {
    this.debitoFornitoriDebitoria.next(nuovoDebito);
  }

  /* dati azienda per il bilancio manuale */
  getAziendaRichiedente(richiedente: Richiedente) {
    this.anagraficaRichiedente.next(richiedente)
  }

  getCampiBilancio(): Observable<ListaCampiBilancio> {
    return this.http.get<ListaCampiBilancio>(environment.apiUrlQuestionario + '/fasGoAlgo/getCampiBilancio');
  }

  caricaXbrl(fileXbrl: any): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/file-system/saveXbrl', fileXbrl);
  }

  callPython(): Observable<any> {
    return this.http.get<any>(environment.apiUrlQuestionario + '/file-system/callPython');
  }

  saveBilancioManuale(bilancioManuale: BilancioManuale): Observable<boolean> {
    return this.http.post<boolean>(environment.apiUrlQuestionario + '/fasGoAlgo/saveBilancioManuale', bilancioManuale);
  }
  getBilancioManuale(idRichiedente: number): Observable<BilancioManualeTot> {
    return this.http.post<BilancioManualeTot>(environment.apiUrlQuestionario + '/fasGoAlgo/getBilancioManuale', idRichiedente);
  }
  getVociBilancioCheckInv(idAttivo: number, idPassivo: number, idContoEconomico: number): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/fasGoAlgo/getVociBilancioCheckInv', { idAttivo: idAttivo, idPassivo: idPassivo, idContoEconomico: idContoEconomico });
  }
  //----------- CR ----------
  caricaCr(fileCr: any): Observable<any> {
    return this.http.post<any>(environment.apiUrlQuestionario + '/file-system/caricaCr', fileCr);
  }
  /* /////////////vecchio bilancio /////////////////*/
  getAllDatiBilancio(): Observable<any> {
    return this.http.get(ApiUrl + 'allDatiBilancio', { responseType: 'text' });
  }

  insertDatiBilancio(bilancio: Bilancio): Observable<any> {
    return this.http.post(ApiUrl + 'addDatiBilancio', {

    },
      { responseType: 'text' });
  }

  updateDatiBilancio(bilacio: Bilancio): Observable<any> {
    return this.http.put(ApiUrl + 'updateDatiBilancio/', {

    }, { responseType: 'text' });
  }


  /////////////////////////////////////////////



}
