import { Component, Input, OnInit, Pipe,PipeTransform } from '@angular/core';
import { BloccoVariabileNew } from 'src/app/interfaces/user-key';

@Component({
  selector: 'app-bench-cell',
  templateUrl: './bench-cell.component.html',
  styleUrls: ['./bench-cell.component.css']
})
export class BenchCellComponent implements OnInit {

  @Input() listaVariabili : string[] = [];
  @Input() isFirst: boolean = true;
  @Input() isLast: boolean = true;
  @Input() valoriCella:BloccoVariabileNew = { anno:0,
    fatturato:' ',
    ebitda:' ',
    pfnEbitda:' ',
    roi:' ',
    ros:' ',
    dso:' ',
    dpo:' ',
    trendfatturato:' ',
    trendebitda:' ',
    trendpfnEbitda:' ',
    trendroi:' ',
    trendros:' ',
    trenddso:' ',
    trenddpo:' '
    };
    
  constructor() { }

  ngOnInit(): void {
  }

  getType(obj){
    return typeof obj;
  }


}
